.faq {
  border: 0;
  padding:0;
}
.accordian__title  {
  background: #f9f9f9;
  border: 2px solid #29225c;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px 45px 15px 25px;
  position: relative;
  transition: all 0.3s;
  font: 1.5rem 'Pathway Gothic One', sans-serif;
  color: #29225c;
  .accordion__title__cross {
    background: url(../images/icons/down-arrow-icon--black.svg);
    background-size: cover;
    display: block;
    height: 12px;
    position: absolute;
    right: 25px;
    top: 23px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 20px;
  }
  &.active {
    .accordion__title__cross {
      transform: rotate(180deg);
    }
  }
}
.accordion-content.active {
  color: #29225c;
  padding: 15px 25px;
}
.cms-faq h2.subtitle {
    margin-top: 2rem;
}
.faq-section--2 {
  @include tablet {
      padding-bottom: 5rem;
  }
}
