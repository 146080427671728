//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-product-downloadable {
        .product-options-wrapper {
            float: none;
            width: 100%;
        }

        .product-options-bottom {
            float: none;
            width: 100%;

            .field.qty {
                & + .actions {
                    padding-top: 0;
                }
            }
        }
    }
}
