.lowtestosterone-section--1 {
  background: #28235d;
  background-repeat: no-repeat;
  .container {
    @include tablet {
      background: #28235d url(../images/lowtestosterone/lowtestosterone-banner.jpg);
      background-position: -57% 113%;
      background-repeat: no-repeat;
      background-size: 118%;
    }
    @include desktop {
      background: #28235d url(../images/lowtestosterone/lowtestosterone-banner.jpg);
      background-position: 115% 100%;
      background-repeat: no-repeat;
      background-size: 80%;
    }
  }
}
.lowtestosterone-section--5 {
  overflow:hidden;
  @include tablet {
    overflow: initial;
  }
  h3 {
    span {
      display: block;
    }
  }
  .male {
    bottom:0;
    bottom:-30px;
    position: relative;
    vertical-align: bottom;
    @include tablet {
      bottom:0;
      left:-115px;
      position: absolute;
    }
  }
}
.lowtestosterone-section--7 {
  .container {
    @include desktop {
      padding: 2rem 1rem;
    }
  }
}

/**COUNTRY SPECIFIC ADJUSTMENTS**/
.de-lang {
  .lowtestosterone-section--1 {
    .container {
      @include tablet {
        background-position: 18% 107%;
        background-repeat: no-repeat;
        background-size: 138%;
      }
      @include desktop {
        background: #28235d url(../images/lowtestosterone/lowtestosterone-banner.jpg);
        background-position: 246% 100%;
        background-repeat: no-repeat;
        background-size: 95%;
      }
    }
  }
}

.es-lang .lowtestosterone-section--12, .fr-lang .lowtestosterone-section--12, .it-lang .lowtestosterone-section--12 {
  @include desktop {
    margin-top: 50px;
  }
}
