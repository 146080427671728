
.hero {
  min-height: calc(100vh - 100px);
  color: white;
  justify-content: center;
  align-items: center;
  position: relative;
  @include bg-cover;

  @include tablet {
    background-position: top right;
  }
}

.hero-content {
  width: 100%;
  max-width: 1152px;
  padding: 1rem .75rem;
  z-index: 20;
}

.hero__title {
  font-weight: 800;
  @include xxl-text;
}

.hero__subtitle {
  font-weight: 700;
  @include sm-text;
}

.hero__product {
  width: 200px;
  margin: -20px auto 0;

  @include tablet {
    width: 300px;
  }

  @include desktop {
    max-height: 440px;
    width: auto;
  }
}
