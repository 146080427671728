.category-page-products {
  .product {
    padding-bottom: 130px;
    font-family: $font1;
    p {
      margin-bottom: 0;
    }
  }
}

.product__image-wrapper {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include desktop {
    margin-bottom: 0;
  }
}

.product__image-inner-wrapper {
  width: 100%;
  position: relative;
  img {
    max-height: 300px;
  }
}

.product__image {
  width: 100%;
  margin-bottom: -40px;

  @media (min-width: 400px) {
    margin-bottom: -50px;
  }

  @media (min-width: 500px) {
    margin-bottom: -70px;
  }

  @media (min-width: 600px) {
    margin-bottom: -100px;
  }

  @include tablet {
    margin-bottom: -60px
  }

  @include desktop {
    margin-bottom: 0;
  }
}

.product-info__top {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    padding: 1rem 0;
    @include font-size(1.75,2);
    margin: 0;

    @include desktop {
      @include font-size(1.5,1.75);
      margin: 0;
    }
  }

  &:after {
    content: '';
    width: 100%;
    max-width: 240px;
    position: relative;
    border-bottom: 1px dashed white;
    display: block;
    margin: 0 auto;
  }

  span {
    display: block;
    font-size: 1rem;
  }
}

.product-info__middle {
  margin-top: -70px;
  position: relative;

  p {
    margin-bottom: 0;
  }
}

.product-info__bottom {
  margin-top: 40px;

  span {
    margin-left: 5px;
  }
}

.product-1 {
  .product__image-wrapper {

    body[class*="hunter-test"] &{
      &:before {
        background-image: linear-gradient(to bottom, #5d1c0c, $hunter-orange);
      }
    }

    body[class*="hunter-burn"] & {
      &:before {
        background-image: linear-gradient(to bottom, #5a3d0b, $hunter-yellow);
      }
    }

    body[class*="hunter-focus"] & {
      &:before {
        background-image: linear-gradient(to bottom, #064354, $hunter-blue);
      }
    }
  }

  .product__image {
    // margin-bottom: 0;
  }
}

.product-info__delivery {
  display: inline-block;
  border: 2px solid;
  @include font-size(0.8, 1);
  padding: 6px 10px;
  margin-top: 20px;
  border-radius: 10px;
  display: inline-flex;

  span {
    margin: 0 3px 0 5px;
  }

  &:before {
    content: '';
    width: 19px;
    height: 16px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
  }

  body[class*="hunter-test"] & {
    border-color: $hunter-orange;

    &:before {
      background-image: url(../images/cms/icon-truck-test.svg);
    }
  }

  body[class*="hunter-burn"] & {
    border-color: $hunter-yellow;

    &:before {
      background-image: url(../images/cms/icon-truck-burn.svg);
    }
  }

  body[class*="hunter-focus"] & {
    border-color: $hunter-blue;

    &:before {
      background-image: url(../images/cms/icon-truck-focus.svg);
    }
  }
}

.product__price {
  padding-bottom: 10px;

  .price {
    @include l-text;
    font-weight: 700
  }
}

.product-info__order-info {
  position: absolute;
  bottom: 20px;
  height: 100px;
  left: 0;
  right: 0;
  width:100%;
  .product__price {
    float: left;
    width: 30%;
    @include tablet {
      float: left;
      width: 50%;
    }
  }
  .product__buttons {
    float: left;
    width: 70%;
    .btn--add-to-cart {
      margin-left: 0;
    }
    @include tablet {
      float: left;
      width: 50%;
    }
  }
}

.product-benefit-image {
  max-width: 100px;
}

body[class*="hunter-test"]  {
  .category-page-products {
    background-image: linear-gradient(to bottom, #431b0f, $hunter-orange);
  }
}

body[class*="hunter-burn"]  {
  .category-page-products {
    background-image: linear-gradient(to bottom, #442e07, $hunter-yellow);
  }
}

body[class*="hunter-focus"]  {
  .category-page-products {
    background-image: linear-gradient(to bottom, #066a83, $hunter-blue);
  }
}
