/* ===========================
   Margin and Padding mixin
  =========================== */

  // Adjust this to include the pixel amounts you need.
  $spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 120, 140, 150, 200, 250, 300, 350, 400, 450);

  $sides: (top, bottom, left, right);

  @each $space in $spaceamounts {
    .m#{$space} {
      margin: #{$space}px !important;
    }

    .p#{$space} {
      padding: #{$space}px !important;
    }
  }

  @each $space in $spaceamounts {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
