.home-section--1 {
  background: #28235d;
  @include tablet {
    background: #28235d url(../images/homepage/home-banner.jpg);
    background-position: 52% 86%;
    background-repeat: no-repeat;
    background-size: 150%;
  }
  @include desktop {
    background-position: -69% 86%;
    background-repeat: no-repeat;
    background-size: 105%;
  }
  @include fullhd {
    background-position: 81% 86%;
    background-repeat: no-repeat;
    background-size: 70%;
  }
}
.home-section--3, .howitworks-section--14 {
  overflow: hidden;
  .container {
    max-width: 960px;
    .reasons {
      counter-reset: li;
      position: relative;
      &-1, &-2, &-3, &-4, &-5 {
        display: none;
        @include tablet {
          display: block;
        }
      }
      &-1 {
        left: -167px;
        position: absolute;
        top: 20px;
      }
      &-2 {
        left:-130px;
        position: absolute;
        top: 23px;
      }
      &-3 {
        left: -51px;
        position: absolute;
        top: 25px;
      }
      &-4 {
        left:-172px;
        position: absolute;
        top: 24px;
      }
      &-5 {
        left:-173px;
        position: absolute;
        top: -56px;
      }
      p {
        margin-bottom:0;
      }
      & >li {
        list-style: none;
        position: relative;
        padding: 0 0 0 73px;
        @include tablet {
          padding: 0 0 0 73px;
        }
        &:nth-child(3) {
          margin-bottom: 1rem;
          @include tablet {
            margin-bottom: 40px;
          }
        }
        span {
          display: block;
          margin-bottom:0;
        }
        &:before {
          border: 5px solid #ff4f41;
          border-radius: 29px;
          color: #ff4f41;
          content: counter(li);
          counter-increment: li;
          font-family: 'Open Sans', sans-serif;
          font-size: 2em;
          font-weight: 700;
          left: 0;
          height: 50px;
          line-height: 1em;
          padding-top: 3px;
          position: absolute;
          text-align: center;
          top: 0px;
          width: 50px;
          @include tablet {
            font-size: 2.35em;
            height: 56px;
            width: 56px;
          }
        }
      }
    }
    .male {
      margin: 0 auto;
      position: inherit;
      width: 100%;
      @include tablet {
        bottom:2px;
        left: -8%;
        margin-bottom: -5px;
        position: absolute;
        vertical-align: bottom;
        width: 54%;
      }
      @include desktop {
        margin-bottom: -50px;
        position: relative;
        left:30px;
        width: auto;
      }
    }
  }
}
.home-section--4, .testimonials-section--2 {
  background: #f4f3f6;
  ul {
    list-style-type: disc;
    padding-left: 20px;
    li {
      margin-bottom: 0;
    }
  }
}
.home-section--5 {
  .bottle {
    margin-left: -8%;
    @include tablet{
      margin-left: 0;
    }
  }
}
.home-section--6, .ingredients-section--9 , .testimonials-section--4, .lowtestosterone-section--4 {
  .btn--green {
    align-items:center;
    border-width: 5px;
    display: flex;
    font-size: 2rem;
    height: 100%;
    justify-content:center;
    margin-bottom:0;
    margin-top:0;
    padding: 5px 9px;
    width:100%;
    &:hover {
      border: 5px solid #5cb85c;
      color: $white;

    }
  }
}
.home-section--7 {
  .container {
    @include tablet {
      padding: 4rem 1rem;
    }
  }
  .male {
    bottom: 0;
    left: -20%;
    position: absolute;
    @include desktop {
      left: -4%;
    }
  }
}
.home-section--9 {
  .tablet-img {
    margin-bottom: 20px;
    @include desktop {
      margin-bottom: 0;
    }
  }
}
.home-section--11 {
  background: #29225c url(../images/homepage/barbell-blue-mobile.jpg);
  background-position: 20%;
  background-repeat: no-repeat;
  background-size:180%;
  color: $white;
  @include tablet {
    background: #29225c url(../images/homepage/barbell-blue.jpg);
    background-position: 165%;
    background-size: 127%;
  }
  @include desktop {
    background-position: -165%;
    background-repeat: no-repeat;
    background-size: 88%;
  }
  @include widescreen {
    background-position: 12%;
    background-size: 53%;
  }
}
.home-section--12 {
  .blue-bg {
    position: relative;
    width: 100%;
    @include tablet {
      width: 50%;
    }
  }
  .container {
    padding:0;
    @include tablet {

    }
    @include desktop {
      padding: 0 1rem;
    }
    .column {
      padding: 1rem 1.25rem;
      @include tablet {
        padding: 2.5rem 3rem;
      }
      @include desktop {
        padding: 5rem 2rem;
      }
      .bottle {
        @include tablet {
          bottom: 33px;
          position: absolute;
          right: 0;
        }
        @include desktop {
          bottom:0;
          margin: 0 auto;
          margin-bottom: -130px;
          margin-left: -11px;
          position: relative;
        }
      }
    }
  }
}
.home-section--13 {
  .container {
    padding: 0.5rem 1rem;
    @include tablet {
      padding: 1rem 2.25rem
    }
    @include desktop {
      padding: 4rem 1rem;
    }
  }
}
.home-section--15 {
  .graph {
    float: right;
  }
}
.home-section--16, .howitworks-section--16, .testimonials-section--6 {
  .bottle {
    @include desktop {
      left: 0;
      margin-bottom: -90px;
      margin-top: -70px;
      position: relative;
      top:0;
      width: auto;
    }
  }
  .cta {
    align-content: center;
    display: flex;
    .btn {
      margin-bottom:0;
      margin-top:0;
    }
    .encryption {
      align-self: center;
      margin-left: 14px;
    }
  }
}
.home-section--17 {
  .container {
    @include desktop {
      padding: 5rem 1rem;
    }
  }
  .home-section--17__ingredient {
    border: 3px solid #29215c;
    margin-bottom: 20px;
    @include desktop {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom:0;
    }
    .blue-bg {
      align-items:center;
      display: flex;
      justify-content:center;
      text-align: center;
      p {
        margin-bottom:0;
      }
    }
  }
  .home-section--17__links {
    .blue-bg {
      padding: 20px;
      p {
        width: 100%;
        @include tablet {
          width: 50%;
        }
        @include desktop {
          width: 100%;
        }
      }
      @include tablet {
        align-items:center;
        display: flex;
        justify-content: space-around;
      }
      @include desktop {
        display: block;
        padding: 40px;
      }
    }
  }
  .qualitystatement {
    .badge {
      display:block;
      margin: 0 auto;
      padding: 10px;
      @include tablet {
        padding: 20px;
      }
    }
    align-items: center;
    display: flex;
  }
}

.home-section--18 {
  .testimonials {
    width: 100%;
  }
}

.home-section--19 {
  .testimonials {

  }
  .testimonial-img {
    margin: 0 auto;
  }
}
.home-section--20 {
  .bottle {
    @include tablet {
      bottom: -48px;
      position: absolute;
      right: -98px;
      width: 60%;
    }
    @include desktop {
      bottom: -55px;
      position: absolute;
      right: 15px;
      width: 40%;
    }
  }
  .cta {
    align-content: center;
    display: flex;
    .btn {
      margin-top:0;
    }
    .encryption {
      align-self: center;
      margin-left: 14px;
    }
  }
}
.home-section--21 {
  padding-bottom: 4rem;
}

.home-section--23, .howitworks-section--15, .ingredients-section--17 {
  .container {
    padding-bottom: 1rem;
    @include desktop {
      padding-bottom: 7rem;
    }
  }
  .infobox-orange {
    background: #ff4f41;
    border-radius: 8px;
    color: $white;
    margin-top:30px;
    padding: 1rem;
    p {
      margin-bottom:0;
    }
    .btn {
      margin-bottom:0;
      width: 100%;
    }
  }
  .badge {
    display: block;
    margin: 0 auto;
    width:70%;
    @include tablet {
      display: inherit;
      width: auto;
    }
  }
}
.home-section--24, .howitworks-section--16, .ingredients-section--18, .testimonials-section--6 {
  .bottle {
    @include tablet {
      margin-bottom: -50px;
      margin-top: -80px;
      position: relative;
    }
    @include desktop {
      left: 0;
      position: absolute;
      margin:0;
      top: -84px;
      left: 14%;
      width: 34%;
      z-index: 1;
    }
  }
  .cta {
    align-content: center;
    display: flex;
    .btn {
      margin-bottom:0;
      margin-top:0;
    }
    .encryption {
      align-self: center;
      margin-left: 14px;
    }
  }
}

/**COUNTRY ADJUSTMENTS**/
.es-lang .home-section--3 .container .male, .es-lang .howitworks-section--14 .container .male,  .fr-lang .home-section--3 .container .male, .fr-lang .howitworks-section--14 .container .male {
  margin-bottom: -83px;
}
.es-lang .home-section--16 {
  margin-top: 1.65rem;
}

// Flating Telegram Icon
#telegram {
    display: none;
    position: fixed;
    bottom: 120px;
    left: 20px;
    z-index: 99;
    font-size: 1rem;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #333;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    @include desktop {
      bottom: 70px;
      left: 26px;
    }
}
