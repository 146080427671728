.firecheckout .navbar {
  display: none;
}
body[class*="firecheckout"] {
  .logo {
    margin-top: 10px;
    img {
      max-width: 150px;
    }
  }
}
.firecheckout {
  .header {
    .logo {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .navbar {
    display: none;
  }
}
.page-header {
  margin-bottom: 0;
  position: relative;
  z-index: 30;
  border: 0;
  background-color: #28235d;
  .flashmessage__outer {
    border: 2px solid #fff;
    padding: 12px;
    a {
      color: $white;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      &:hover {
        text-decoration: none;
      }
      p {
        font-size:16px;
        line-height: 24px;
        margin:0;
        padding:0;
      }
    }
  }
}

.primary-header {
  background-color: #000;
  padding: 0px;
  z-index: 100;
  width: 100%;
  position: fixed;

  &__cards {
    float: left;
    padding-left: 10px;
    padding-top: 16px;
    @media screen and (max-width: 768px) {
      display: none;
    }
    img {
      width: 35px;
    }
  }

  @include tablet {
    position: relative;
  }

  &__top {
    background: $instant-red;
    color: $white;
    font: 14px 'Roboto Condensed', sans-serif;
    .xxs-text {
      line-height: 24px;
    }
    #country-select-trigger {
      color: $white;
      height: 30px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #212121;
      box-shadow: 1px 1px 4px #383838;
      min-width:160px;
      &:hover {
        text-decoration: none;
      }
      img {
        margin-left: 10px;
      }
      p {
        font: 14px 'Roboto Condensed', sans-serif;
        font-weight: 300;
        margin:0;
        margin-right: 10px;
        span {
          display:inline-block;
        }
      }
    }
    text-transform: uppercase;
    .primary-header__top__shipicon {
      vertical-align: middle;
      width: 29px !important;
    }
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 .75rem;
    position: relative;
    height: 100%;
    &__navigation {
      position: absolute;
      @media screen and (min-width: 768px){
        background: #FFF;
        background: -moz-linear-gradient(top, #fff 50%, #bfbfc3 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #fff), color-stop(100%, #bfbfc3));
        background: -webkit-linear-gradient(top, #fff 50%, #bfbfc3 100%);
        background: -o-linear-gradient(top, #fff 50%, #bfbfc3 100%);
        background: -ms-linear-gradient(top, #fff 50%, #bfbfc3 100%);
        background: linear-gradient(to bottom, #fff 50%, #bfbfc3 100%);
        width: auto;
        float: right;
        margin-top: 30px;
        font-size: 13px;
        clear: right;
        z-index: 900;
        text-transform: uppercase;
        -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.5);
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.5);
        font-family: 'Roboto Condensed';
        margin-right: -3000px;
        padding-right: 3000px;
      }
      .primary-header__button {
        left:33.8rem;
        position: absolute;
        top:3px;
        @include tablet {
          top:7px;
          left: 29.8rem;
        }
        @media screen and (min-width:900px){
          top: 4px;
          left: 33.8rem;
        }
        .btn {
          float: left;
          margin-bottom: 0;
          margin-top: 0;
        }
        .cards {
          float: left;
          margin: 13px 0 0 14px;
          .payment-icon {
            height: auto;
            width: 35px;
          }
        }
      }
      .primary-header__nav {
        li {
          border-bottom: 1px solid $black;
          display: inline-block;
          text-align: center;
          font-size: 13px;
          vertical-align: middle;
          margin: 0;
          border-collapse: inline-table;
          float: left;
          width: 100%;
          @media screen and (min-width:768px){
            border-bottom:0;
            border-left: 1px solid #fff;
            border-right: 1px solid #555;
            width: auto;
          }
          &:hover {
            background: #FFF;
            color: #DA0F1A;
            text-decoration: none;
            a {
              text-decoration: none;
            }
          }
          &.double-line a {
            padding: 8px 15px;
            br {
              display: none;
              @media screen and (min-width: 768px){
                display: block;
              }
            }
            @media screen and (min-width:768px){
              padding: 13px 12px;
            }
            @media screen and (min-width: 900px){
              padding: 12px 20px;
            }
          }
          &.last-nav {
            border-right: 0 none !important;
            padding-right: 0;
            cursor: auto;
            .btn--redgradient {
              color: $white;
            }
            .cards {
              padding-left: 14px;
              padding-top: 15px;
            }
            .payment-icon {
              height: auto;
              width: 35px;
            }
            &:hover {
              background: none;
            }
            a {
                font-size: 14px;
                margin: 10px 0 0 3px;
                padding: 10px 20px 10px 20px;
                @media screen and (min-width: 1100px) {
                  font-size: 20px;
                  margin: 5px 0 0 12px;
                  padding: 10px 20px 10px 20px;
                }
            }
          }
        a {
          color: $black;
          display: block;
          font: 18px "Roboto Condensed", sans-serif;
          font-weight: 100;
          padding: 8px 15px;
          text-align: left;
          text-transform:uppercase;
          @media screen and (min-width: 768px){
            font-size: 13px;
            padding: 20px 12px;
            text-align: center;
          }
          @media screen and (min-width: 900px){
            padding: 20px 14px;
          }
          &.active {
            color: #DA0F1A;
            background-color: #FFF;
            @media screen and (min-width: 768px){
              background-position: bottom center;
              background-repeat: no-repeat;
              background-image: url(../images/cms/active-arrow.png);
              }
            }
          }
        }
      }
    }
    &__mobileButton {
      display: block;
      position: absolute;
      right: 10px;
      top: 36px;
      width: 43%;
      .btn--redgradient {
        display: inline-block;
        float: right;
        font-size: 15px;
        margin: 0;
        width: auto;
      }
      .primary-header__cards {
        display:block;
        padding:10px 0;
        text-align: right;
        width: 100%;
        .payment-icon {
          width: 25px;
        }
      }
      @media screen and (min-width: 768px){
        display: none;
      }
    }
    &__logo {
      display: block;
      margin-left: 20px;
      padding-top: 40px;
      width: 140px;
      @media screen and (min-width: 768px){
        margin-left:0;
        padding-left: 0;
        padding-top: 30px;
        width: 170px;
      }
      @media screen and (min-width: 900px){
        width: 200px;
      }
    }
    &__navigation {
      @media screen and (min-width: 768px) {
        margin-left: 20px;
        position: relative;
      }
    }

    @include tablet {
      max-width: $breakpoint-lg;
      margin: 0px auto;
      height: 100%;
    }
  }

  &__logo {
    z-index: 30;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 120px;
    margin: auto;
    display: flex;

    img {
      text-align: center;
      margin: 0 auto;
    }

    @include tablet {
      left: .75rem;
      right: auto;
      margin: 0;
      margin-top: 20px;
      max-width: 200px;
    }
  }

  &__nav-wrapper {
    float: left;
    &.is-open {
      border-bottom: 2px solid $instant-red;
      transform: translateX(0);
    }

    .is-hidden-tablet {
      background: $instant-red;
      a {
        color: $white!important;
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      height: calc(100% - 53px);
      top: 53px;
      width: 100%;
      transition: transform .3s cubic-bezier(0.860, 0.000, 0.070, 1.000);
      transform: translateX(-100%);
      background: #fff;
      z-index: 40;
    }

    @include tablet {
    }
  }

  &__nav, &__top-links {
    @include tablet {
      display: flex;
      align-items: center;
    }
  }

  &__cart-mobile {
    @include tablet {
      display: none;
      visibility: hidden;
    }
  }
}

.primary-header__top-links {

  @include tablet {
    border-top: 0;
    float: right;
    padding: 0;
  }
  p {
    margin:0;
    margin-right: 10px;
    padding:0;
    span {
      display: inline-block;
    }
  }

  .primary-nav__link {
    padding: 1rem 1rem 1rem 0;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    position: relative;

    @include tablet {
      &:after {
        content: '';
        height: 50%;
        width: 1px;
        background: $mid-light-grey;
        display: inline-block;
        position: absolute;
        right: 0;
      }
    }
  }
}

.primary-header__top-links-item {
  align-items:center;
  display:flex;
  font-weight: normal;
  justify-content:center;
  > a {
    color: #ffffff;
    transition: color 0.5s;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    font-weight: 700;
    padding: 5px 0;
    display: flex;
    align-items: center;
    margin-right: 5px;
    @include tablet {
      height: 44px;
      justify-content: center;
      padding: 10px 0;
    }
  }

  &--switcher{
    span {
      font-weight: 700;
      margin-left: 2px;

      @include tablet {
        display: none;
      }
    }
  }

  &--my-account,
  &--cart {
    > a {
      position: relative;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &--my-account {
    a {
      span {
        color: $white;
        margin-left: 14px;

        @include tablet {
          display: none;
        }
      }

      &:before {
        background-image: url(../images/cms/account-icon.svg);
      }
    }
  }

  &--cart {

    position: absolute;
    right: 0;
    top: 0;

    > a {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;

      &:before {
        background-image: url(../images/cms/cart-icon.svg);
      }
    }

    .counter {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 5;
      display: block;
      font-size: 10px;
      background: $blue;
      color: #fff;
      padding: 1px 5px;
      border-radius: 20px;
      display: inline-block;
      font-weight: bold;
    }
  }
}

.category-title-link {
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    background-image: url(../images/hunter_bullet.svg);
    width: 22px;
    height: 23px;
    position: relative;
    background-size: cover;
    margin: 0 17px 0 2px;;
    transform: translateY(-50px);
    transition: all ease-in-out .2s;
    display: none;
    overflow: hidden;
    pointer-events: none;

    @include tablet {
      display: block;
      opacity: 0;
    }
  }

  &.in-view {
    &:before {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
    }
  }
}

/* Secondary Header */

body[class*="cms-index"] .primary-header__nav-wrapper li:nth-child(1) a, body[class*="cms-how-it-works"] .primary-header__nav-wrapper li:nth-child(2) a,
body[class*="cms-ingredients"] .primary-header__nav-wrapper li:nth-child(3) a, body[class*="cms-for-women"] .primary-header__nav-wrapper li:nth-child(4) a,
body[class*="cms-pro-athletes"] .primary-header__nav-wrapper li:nth-child(5) a, body[class*="cms-our-testimonials"] .primary-header__nav-wrapper li:nth-child(6) a {
  color: $instant-red;
  @media screen and (min-width: 768px){
    background: $white url(../images/cms/active-arrow.png) no-repeat bottom center;
  }
}

.secondary-header {
  padding-top: 30px;
  width: 100%;
  @media screen and (min-width: 768px){
    padding-top:0;
  }
  &__inner {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    position: relative;
    @media screen and (min-width: 768px){
      height: 100%;
      margin: 0 auto;
      max-width: 1152px;
      padding: 0 .75rem;
    }
  }

  @include tablet {
    position: relative;
    top: 0;

  }
}


/* Modile Top Nav */

@include max-screen($screen__s) {
  .navigation-top-links .my-cart {
    margin: 0px;
  }
}

/* Cart Overlay */
#mini-mob {
  padding: 20px;
  position: relative;
}
#mini-desk {
  position: relative;
}
.minicart-block {
  // position: relative;

  .block-minicart {
    margin-bottom: 10px;
  }
  .mage-dropdown-dialog {
    background: white;
    z-index: 99;
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    padding: 1rem;
    z-index:100;

    @include tablet {
      left: 7%;
      position: absolute;
      width: 350px;
      top: 49px;
      border-radius: 0 0 8px 8px;
    }
    @include desktop {
      position: absolute;
      left: initial;
      right: 0;
      top: 40px;
    }

    &:after {
      bottom: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(38, 38, 38, 0);
      border-bottom-color: white;
      border-width: 8px;
      left: 48%;

      @include tablet {
        left: initial;
        right: 13px
      }
    }

    #btn-minicart-close {
      background: none;
      position: absolute;
      top: 0;
      right: 0px;
      border: none;
      color: black;
      box-shadow: none;

      &:before {
        font: normal normal normal 18px/1 FontAwesome;
        content: "\f00d";
        margin-left: 10px;
        margin-top: 5px;
        transition: transform 0.5s;
      }

      span {
        display: none;
      }

    }

    .block-title span {
      font-size: 0.9rem;

      &.qty {
        display:none;
      }
    }

    .block-content {
    }

    .minicart-items-wrapper {
      border: 1px solid #efefef;
      border-left: 0;
      border-right: 0;
      overflow-x: auto;
      padding: 0;
      border-top: none;
      margin: 0px;
      height: auto !important;
      max-height: 210px;
      overflow: auto;

      .product {
        padding: 0;
        color: black;
        display: flex;
        padding: 10px 0 0;
      }

      .price-container {
        span {
          margin: 0;
        }
      }

      .details-qty {
        margin: 0;

        .label {
          display: none;
        }
      }
    }

    .product-item {
      position: relative;
      padding: 0px;

      a {
        color: black;

        &.action.edit {
          display: none;
        }
      }
    }

    .product-item-details {
      font-size: 0.8rem;
      padding: 0 0 0 20px;
      flex: 1;

      .product-item-name {
        width: 100%;
        font-size: .9rem;

        a {
          font-size: .9rem;
        }
      }

      .product-item-pricing {
        display: flex;
        justify-content: space-between;
      }
    }

    .update-cart-item {
      font-size: 0.85rem;
      padding: 3px 10px;
      background-color: $btn-blue;
      border: none;
      text-transform: uppercase;

      &:hover {
        background-color: #0e11d9;
        text-decoration: none;
      }
    }

    .product.options {
      display: none;
    }

    .product.actions {

      .primary {
        display: none;
      }

      .secondary {
        position: absolute;
        top: 0px;
        right: 0px;
      }

    }

    .details-qty {
      font-size: 0.8rem;

      .label {
        font-size: 0.8rem;
        width: initial;

        font-weight: 400;
        margin-right: 10px;
      }
    }

    .items-total {
      color: $black;
      font-size: 0.8rem;
      margin-top: 12px;
    }


    .subtotal {
      color: $black;
      margin-top: 10px;

      .label {
        display:none;
      }
    }

    .paypal.after:before {
      content: attr(data-label);
      display: block;
      margin: 5px 5px 10px;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: bold;
    }
  }
}

.popup-authentication {
  .block-authentication {
      margin-bottom: 10px;
  }
}


.mini-cart-buttons {
  a:before {
    display: none;
  }
}

.navigation-top-links {
  .action:not(.close) {
    padding: 0;
  }
}

/**Language overides**/
@media screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  .es-lang .primary-header__inner__navigation .primary-header__nav li {
    a {
        padding: 20px 7px;
    }
    &.double-line {
      a {
        padding: 12px 8px;
      }
    }
    .btn--redgradient {
      padding: 10px 7px;
    }
  }
  .fr-lang .primary-header__top .white-text .is-hidden-mobile {
    display: none;
  }
  .fr-lang .primary-header__inner__navigation .primary-header__nav li {
    a {
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .it-lang .primary-header__inner__navigation .primary-header__nav li {
    a {
      font-size: 12px;
      padding-left: 9px;
      padding-right: 9px;
    }
    .btn--redgradient {
      font-size: 13px;
    }
  }
  .de-lang .primary-header__inner__navigation .primary-header__nav li {
    a {
      font-size: 12px;
      padding-left: 6px;
      padding-right: 6px;
    }
    .btn--redgradient {
      font-size: 13px;
    }
    &.double-line a {
      padding-left: 6px;
      padding-right: 6px;
    }
    .btn--redgradient {
      padding: 10px 8px!important;
    }
  }
}


/*French Language Styles*/

.fr-lang .navbar-menu .btn--green {
  max-width: 200px;
}
.de-lang .navbar-menu .btn--green {
  max-width: 156px;
}
@include desktop {
  .de-lang {
    .navbar-end {
      max-width: 916px;
    }
    .navbar .navbar-menu .buttons__cards {
      position: absolute;
      right: 11px;
      top: 68px;
    }
  }
}

@media screen and (min-device-width: 1100px) {
  .fr-lang .primary-header__inner__navigation .primary-header__nav li.last-nav a {
    font-size: 16px;
    margin: 8px 0 0 10px;
    padding: 10px 12px 10px 12px;
  }
  .it-lang .primary-header__inner__navigation .primary-header__nav li.last-nav a {
    font-size: 16px;
    margin: 8px 0 0 10px;
    padding: 10px 12px 10px 12px;
  }
  .it-lang .footer__top ul li a {
    padding-right: 20px;
  }
  .de-lang .primary-header__inner__navigation .primary-header__nav li.last-nav a {
    font-size: 16px;
    margin: 8px 0 0 10px;
    padding: 10px 12px 10px 12px;
  }
  .de-lang .footer__top ul li a {
    padding-right: 20px;
  }
}

/**Fixing minicart z-index issue*/
.page-header {
    z-index:auto;
}
