.full-width-cta {

  .columns {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .column:first-child {
    position: relative;
  }

  +.cms-full-wrapper {
    padding-top: 2rem;
  }
}

.full-width-cta__image {
  width: 80%;
  max-width: 400px;
  margin: auto;
}

.full-width-cta__content {
  padding: 0 1rem 1rem;
  text-align: center;

  @include tablet {
    text-align: left;
    padding: 3rem 1rem;
  }

  h3 {
    color: white;
  }
}
