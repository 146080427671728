.accordion-trigger {
  cursor: pointer;
}

.accordion-content {
  display: none;

  &.active {
    display: block;
  }
}

// FAQs accordion

.faq-block {
  border: 3px solid currentColor;
  border-radius: 10px;
  padding: 1rem;
  position: relative;
  margin-bottom: 4rem;
}

.faq-block-content {
  position: relative;
}

.faq-block__title {
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  margin: auto;
  color: white;

  h2 {
    display: inline-block;
    background-color: $light-black;
    padding: 0 2rem;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      width: 15px;
      height: 25px;
      position: relative;
      display: inline-block;
      background-image: url(../images/cms/faqs-box-icon.svg);
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 10px;
    }

    &.orders-title {
      &:before {
        background-image: url(../images/cms/faqs-delivery-icon.svg);
        width: 25px;
        height: 25px;
      }
    }
  }
}

.faq-block-content {
  border-radius: 10px;
  background-color: #0c0e12;
  padding: 0 1.5rem;
}

.faq {
  border-bottom: 3px solid $light-black;
  padding: 1.5rem 0;
  font-family: $font1;
  color: $mid-light-grey;

  &.active {
    color: white
  }

  &:last-child {
    border: none;
  }
}

.faq__question {
  @include sm-text;
  margin: 0 !important;
  position: relative;
  transition: all ease .3s;
  padding-right: 40px;

  &:after {
    content: '\f078';
    font-family: 'fontawesome';
    font-size: 20px;
    color: $mid-light-grey;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    transition: all ease .3s;
  }

  &:hover {
    color: white;

    &:after {
      color: white;
    }
  }

  &.active {
    font-weight: 700;

    &:after {
      transform: rotate(180deg);
      color: white;
    }
  }
}

.faq__answer {
  margin-bottom: 0;
  padding: 10px 0 0;
  opacity: 0;
  transition: opacity ease .5s;

  &.active {
    opacity: 1;
  }

  * {
    margin-bottom: 10px;
  }
}
