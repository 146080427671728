.category-section--1 {
  z-index:0;
  .male {
    margin-bottom: -47px;

    margin-right: -150px;
    vertical-align: bottom;
    @include tablet {
      margin-bottom: 0px;
      vertical-align: bottom;
    }
    @include desktop {
      margin-top: -98px;
    }
  }
}
.category-section--2 {
  .bottle {
    @include desktop {
      margin-bottom: -96px;
      margin-top: -82px;
    }
  }
}
.products {
  .mobile-reverse {
    flex-flow: column-reverse;
    @include tablet {
      flex-flow: row wrap;
    }
  }
}
.category-section--4 {
  overflow: hidden;
  .container {
    max-width: 960px;
    .reasons {
      counter-reset: li;
      position: relative;
      &-1, &-2, &-3, &-4, &-5 {
        display: none;
        @include tablet {
          display: block;
        }
      }
      &-1 {
        left: -167px;
        position: absolute;
        top: 20px;
      }
      &-2 {
        left:-130px;
        position: absolute;
        top: 23px;
      }
      &-3 {
        left: -51px;
        position: absolute;
        top: 25px;
      }
      &-4 {
        left:-172px;
        position: absolute;
        top: 24px;
      }
      &-5 {
        left:-173px;
        position: absolute;
        top: -56px;
      }
      p {
        margin-bottom:0;
      }
      & >li {
        list-style: none;
        position: relative;
        padding: 0 0 0 73px;
        @include tablet {
          padding: 0 0 0 73px;
        }
        &:nth-child(3) {
          margin-bottom: 1rem;
          @include tablet {
            margin-bottom: 40px;
          }
        }
        span {
          display: block;
          margin-bottom:0;
        }
        &:before {
          border: 5px solid #ff4f41;
          border-radius: 29px;
          color: #ff4f41;
          content: counter(li);
          counter-increment: li;
          font-family: 'Open Sans', sans-serif;
          font-size: 2em;
          font-weight: 700;
          left: 0;
          height: 50px;
          line-height: 1em;
          padding-top: 3px;
          position: absolute;
          text-align: center;
          top: 0px;
          width: 50px;
          @include tablet {
            font-size: 2.35em;
            height: 56px;
            width: 56px;
          }
        }
      }
    }
    .male {
      margin: 0 auto -20px;
      position: inherit;
      vertical-align: bottom;
      width: 100%;
      @include tablet {
        margin-bottom: -18px;
        max-width: 400px;
        position: absolute;
        top:0;
        width: 54%;
      }
      @include desktop {
        margin-bottom: -35px;
        margin-top: -55px;
        max-width: none;
        position: relative;
        left:0;
        vertical-align: bottom;
        width: auto;
      }
    }
  }
}
.product-title {
  padding: 20px;
  @include tablet {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  @include desktop {
    font-size: 1.875rem;
    line-height: 2.375rem;
  }
}
.product-item-half, .product-item-full {
  .pricing-container {
    p {
      float: left;
      @include tablet {
        color: #29215c;
      }
    }
  }
  .price-container .price {
    font-family: "Open Sans", "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.75rem;
    font-weight: 800;
    line-height: 2.5rem;
    margin-bottom:0;
    @include tablet {
      font-size: 2.375rem;
      line-height: 2.875rem;
      margin-bottom:0;
    }
  }
}

/*remove border for full width product*/
.triangle-list {
  display: flex;
  flex-flow: column;
  margin-bottom:0;
  margin-left: 0px!important;
  padding: 0!important;
  overflow: hidden;
  &.flex-row-wrap {
    li {
      width: 100%;
    }
  }
  li {
    margin-bottom: 0.5rem;
    padding-left: 35px;
    position: relative;
    width: 100%;
    @include tablet {
      width: 100%;
    }
    &:before {
      content: "";
      border-color: transparent #ff4f41;
      border-style: solid;
      border-width: 0.35em 0 0.35em 0.45em;
      display: block;
      height: 0;
      width: 0;
      left: 3px;
      top: 8px;
      position: absolute;
    }
  }
}
.readmore {
  border-top: 1px dashed #29215c;
  border-radius: 2px;
  font-size: 0.875rem;
  font-weight: 700;
  margin-top: 0.5rem;
  padding: 6px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  &.active {
    p {
      &:after {
        transform: rotate(-45deg);
        transition: all 0.5s;
        top: 0px;
        right: 10px;
      }
    }
  }
  p {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    justify-content: center;
    position: relative;
    &:after {
      content: '+';
      display: flex;
      font-size: 1.5rem;
      margin-left: 8px;
      margin-top: -2px;
      right: 0px;
      transition: all 0.5s;
      width: 15px;
    }
  }

}
/**COUNTRY SPECIFICS**/
.es-lang, .fr-lang {
  .triangle-list {
    @include desktop {
      padding: 0px 11px 5px 5px;
    }
  }
}
.de-lang {
  .triangle-list {
    @include desktop {
      padding: 0px 3px 5px 0px;
    }
  }
}
.it-lang {
  .triangle-list {
    @include desktop {
      padding: 0px 12px 5px 16px;
    }
  }
}
