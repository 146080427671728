.key-point {
  position: relative;

  p:last-child {
    margin: 0;
  }

  .m-text {
    margin-bottom: 5px;
  }

  h2 {
    margin-bottom: 0;
  }
}

.key-point-with-icon {
  display: flex;
}

.key-point__icon {
  width: 40px;
  margin-right: 30px;
  text-align: center;
  flex: none;

  img {
    margin: 10px auto 0;
  }
}

.double-underline {
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
  }

  &:before {
    bottom: -1px;
    height: 3px;
    width: 45%;
  }

  &--orange {
    &:before,
    &:after {
      background-color: $hunter-orange;
    }
  }

  &--yellow {
    &:before,
    &:after {
      background-color: $hunter-yellow;
    }
  }

  &--blue {
    &:before,
    &:after {
      background-color: $hunter-blue;
    }
  }

  &--green {
    &:before,
    &:after {
      background-color: $hunter-green;
    }
  }

  &--white {
    &:before,
    &:after {
      background-color: white;
    }
  }
  &--black {
    &:before,
    &:after {
      background-color: black;
    }
  }

  &--reverse {
    &:before {
      right: 0;
    }
  }

  &--standalone {
    margin-bottom: 20px;
    padding-bottom: 0;
    height: 3px;
    display: block;
  }
}
