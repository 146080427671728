
/*Flex Adjustments outside of Bulma*/

.is-hcentered {
  justify-content: center;
}
.flex-reversed {
  flex-flow: row wrap;
  @include tablet {
    flex-flow: row-reverse;
  }
}

.flex-none {
  display:block!important;
  flex:none;
}
.flex-column-wrap {
  flex-flow: column wrap;
  flex-grow: 1 0 auto!important;
}
.flex-row-wrap {
  flex-flow: row wrap!important;
}
.flex-grow {
  flex-grow: 2;
}
// Colour helper classes
.grey {
  color: $light-black;
}

.red {
  color: $red;
}

.off-black {
  color: #0c0e12
}

.orange {
  color: #ff4f41;
  &-bg {
    background: #ff4f41;
    color: $white;
  }
}
.blue {
  color: #29215c;
  &-bg {
    background: #29215c;
    color: $white;
    &--mobile {
      background: #29215c;
      color: $white;
      @include tablet {
        background: $white;
        color: #29215c;
      }
    }
  }
}

.white {
  color: white !important;
  &-bg {
    background: $white;
  }
}

.light-grey {
  color: $mid-light-grey
}

.text-spaced {
  letter-spacing: 2px;
}

.is-underlined {
  text-decoration: underline;
}

// Layout

@mixin bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  @include desktop {
    align-items: flex-end;
  }
}

.bg-cover {
  @include bg-cover;
}

.bg-dark-grey {
  background-color: $light-black;
}

.bg-light-grey {
  background-color: $light-grey;
}

.bg-lightest-grey {
  background-color: $lightest-grey;
}

.bg-black {
  background-color: #000;
}

.bg-orange {
  background-color: $hunter-orange
}

.bg-offblack {
  background-color: $off-black;
}

.flex-vertical-center {
  @include flex-center;
}

.column--extrapadding {
  @include tablet {
    padding: 3rem 0.75rem;
  }
}
.border {
  border-bottom: 1px solid #28235d;
}
.img-block {
  display: block;
  position: relative;
  z-index: 10;
}

.img-center {
  margin: 0 auto;
}

.max-width-image {
  width: 100%;
  max-width: 300px;
  margin: auto;
}

.margin-zero {
  margin: 0
}

.zero-padding {
  padding: 0px !important;
}
.zero-padding-top {
  padding-top: 0px !important;
}
.zero-padding-right {
  padding-right: 0px !important;
}
.zero-padding-left {
  padding-left: 0px !important;
}
.zero-padding-bottom {
  padding-bottom: 0px !important;
}
.zero-padding-tablet {
  padding: 0px !important;
  @include tablet {
    padding: 0.5em !important;
  }
}
.zero-margin {
  margin: 0px !important;
}
.zero-margin-top {
  margin-top: 0px !important;
}
.zero-margin-right {
  margin-right: 0px !important;
}
.zero-margin-left {
  margin-left: 0px !important;
}
.zero-margin-bottom {
  margin-bottom: 0px !important;
}
// Font helper classes

.s-text {
  @include s-text;
}

.sm-text {
  @include sm-text;
}

.reg-text {
  @include reg-text;
}

.m-text {
  @include m-text;
}

.ml-text {
  @include ml-text;
}

.l-text {
  @include l-text;
}

.xl-text {
  @include xl-text;
}

.xxl-text {
  @include xxl-text;
}

.title-font {
  font-family: $font1
}

.reg-font {
  font-family: $font2;
}
.special-font {
  font-family: $font3;
}

.light {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}
.extra-bold {
  font-weight: 800!important;
}


.italic {
  font-style: oblique;
}

.text-shadow {
  text-shadow: 1px 1px rgba(0,0,0, .3);
}

.block-mobile {
  display: block;

  @include tablet {
    display: inline-block;
  }
}

.block{
  display: block
}

.text-center-mobile {
  text-align: center;

  @include desktop {
    text-align: left;
  }
}

.zero-bottom {
  margin-bottom: 0;
}



/**INFOBOXES**/

.infobox {
  border-radius: 2px;
  font-family: $font3;
  letter-spacing: 0.0625em;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  &--blue {
    border: 5px solid #29225c;
    color: #29225c;
  }
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  color: #29225c;
  font-family: 'Roboto',sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 1.5em 10px;
  padding: 1.0em 10px;
  quotes: "“" "”" "‘" "’";
  p {
    display: inline;
  }
  &:before {
    color: #ff4f41;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  &:after {
    color: #ff4f41;
    content: close-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-left: 0.25em;
    vertical-align: -0.7em;
  }
}
/**Styled ULS**/
.ticks {
  padding-left: 0;
  @include desktop {
    padding-left: 40px;
  }
  li {
    background: url(../images/icons/ticklist.png);
    background-repeat:no-repeat;
    background-size: 41px 37px;
    padding-left: 54px;
    @include tablet {
      background-size: 38px 35px;
      padding-left: 50px;
    }
    @include desktop {
      background-size: 44px 39px;
      padding-left: 80px;
    }
  }
}
.ticks--orange {
  margin-bottom:0;
  padding-left: 50px;
  @include tablet {
    padding-left: 0;
  }
  li {
    background: url(../images/icons/orange-ticklist.png);
    background-repeat:no-repeat;
    background-size: 30px 28px;
    list-style-type:none!important;
    margin-bottom:10px;
    padding-left: 50px;
    @include tablet {
      background-size: 36px 32px;
      margin-bottom: 5px;
      padding-left: 50px;
    }
  }
}
// Section padding helpers

.image-bg-padded {
  padding: 50px 0 300px;

  @include tablet {
    padding: 50px 0 400px;
  }
}

.image-bg-padded-equal--small {
  padding: 50px 0;

  @include tablet {
    padding: 100px 0;
  }
}

.image-bg-padded-equal {
  padding: 100px 0;

  @include tablet {
    padding: 250px 0;
  }
}

.image-bg-padded-equal--large {
  padding: 100px 0 250px;

  @include tablet {
    padding: 200px 0 300px;
  }
}

.v-align {
  vertical-align: middle;
  margin-right: 5px;
}

.bdb {
  border-bottom: 2px solid $light-grey;
  padding-bottom: 1rem;

  @include tablet {
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
}

.bdt {
  padding-top: 1rem;

}

/**Icon helpers**/
img.icon {
  margin: 0 auto;
}
