.modal-slide,
.modal-popup {

   .modal-content,
   .modal-header {
     padding: 1rem;

     .modal-title {
       border-bottom: 0px solid #c1c1c1;
       padding-bottom: 0px;
       color: #ff4f41;
       font-weight: 600;
       text-align: center;
     }

     .action-close {
       padding: 0.5rem;

       &:before {
         color: $black;
         margin: 10px;
         font-weight: 700;
       }
     }
   }

 }

 .modal-popup, .modal-popup._show {
   background: rgba(256,256,256,0.6);

   .modal-inner-wrap {
     background-color: $white!important;
     color: $black!important;
     max-width: 500px;

     .modal-content {
       color: $black;
       .close-btn {
         background: $black;
       }
     }

     .modal-footer {
       padding-top: 0.5rem;
       padding-right: 1.5rem;
       padding-bottom: 1.5rem;
       padding-left: 1.5rem;

       .action-secondary.action-dismiss, .action-primary.action-accept {
         padding: 5px 10px;
         font-size: 1rem;
       }
     }
   }
 }

 .modal-popup.confirm._show {
   text-align: center;

   .modal-inner-wrap {
     background-color: $white;
     max-width: 500px;

     .modal-content {
       padding-top: 1.5rem;
       padding-bottom: 1rem;
       padding-left: 1rem;
       padding-right: 1rem;
       font-family: 'Roboto', sans-serif;
       font-weight: 400;
       letter-spacing: 0.055em;
     }

     .modal-footer {

       .action-secondary.action-dismiss {
         font-family: 'Pathway Gothic One', sans-serif;
         padding: 8px 25px;
         font-weight: 700;
         border-radius: 4px;
         text-transform: uppercase;
         background-color: #484848;
         border: 1px solid #484848;
         color: #fff;
         margin-right: 10px;
         font-size: 1.2rem;
       }

       .action-primary.action-accept {
         padding: 8px 25px;
         font-size: 1.2rem;
         font-weight: 700;
         border-radius: 4px;
         text-transform: uppercase;
       }
     }
   }
 }

.modal-popup.modal-slide {
  background: rgba(256,256,256,0.6);
  left: 0;

  .modal-inner-wrap[class] {
    background-color: $white;
  }
}

.modal-popup {
 .modal-header {
   padding-top: 1rem;
   padding-bottom: 1rem;
 }
}

.shipping-country-modal {

 .modal-inner-wrap {
   background-color: $light-black;

   .modal-content {
     padding: 0 5px;
     margin: 0;
     min-height: 480px;

     img {
       max-width: 80px;
       height: auto;
     }
   }

   .action-close {
     padding: 0.5rem;
     display: none;

     &:before {
       font-size: 35px;
       line-height: 25px;
       color: $light-black;
       background: #fff;
       margin: 10px;
       font-weight: 700;
     }
   }
 }
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 7.6rem; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: linear-gradient(-22deg, #000 55%, #000 55%, #000 55%, transparent 55%);
  opacity: 0.5;
}

/* Modal Content */
.modal-content {
  background-color: transparent;
  margin: auto;
  width: 100%;
}

.firecheckout .modal-popup.modal-slide._inner-scroll .modal-inner-wrap[class], .firecheckout .modal-custom:not(.opc-sidebar).modal-slide._inner-scroll .modal-inner-wrap[class] {
  color: black !important;
}

.modal-popup.agreements-modal.modal-slide {
  .modal-inner-wrap {

    background-color: white;

    .modal-content {
      color: black;
    }

    .action-hide-popup {
      color: white !important;
      @extend .btn--black
    }
  }
}
.modal-popup .modal-inner-wrap {
  top: 25%;
}
.modal-popup .modal-title {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}
