.footer-links,
.footer-copyright {
  background-color: #ff4f41;
  color: #29215c;

  a {
    color: white
  }
}
.footer-copyright {
  position: relative;
  .no-padding-tablet {
    @include tablet {
      padding-right:0;
    }
  }
  .title {
    color: #29215c;
    font-size: 11px;
    text-align: center;
    width: 100%;
    @include tablet {
      text-align: center;
    }
    br {
      display: none;
    }
  }
  .logo {
    display:none;
    float: right;
    margin:0;
    max-width: 100%;
    img {
      width: 55px;
    }
    @include tablet {
      display: block;
    }
  }
}
.footer-links {
  .container {
    // padding-top: 0;
  }

  a {
    font-size: 1rem;
  }
}

.footer__top {
  border-top: 2px solid #29215c;
  position: relative;
  background: $white;
  @media screen and (min-width: 768px) {
    background: $white;
  }
  .container {
    padding: 0 1rem;
  }
  ul {
    list-style-type: none;
    margin:0;
    @include tablet {
      padding: 0;
    }
    @media screen and (min-width: 768px){
      padding: 0;
    }
    li {
      display: inline-block;
      margin:0;
      padding:0;
      width: 100%;
      @media screen and (min-width: 768px){
        width: auto;
      }
      a {
        color: $black;
        display:block;
        font: 14px 'Roboto Condensed', sans-serif;
        font-weight: 400;
        padding-top: 20px;
        text-decoration: none;
        text-transform: uppercase;
        @include tablet {
          font-size: 11px;
          padding-bottom: 38px;
          padding-right: 16px;
          padding-top: 40px;
        }
        @include desktop {
          font-size: 14px;
          padding-bottom: 38px;
          padding-right: 26px;
          padding-top: 40px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &__security {
    height: 100%;
    @media screen and (min-width: 768px){
      border-left: 2px solid #29215c;
    }
    &__text {
      align-items:flex-end;
      display: flex;
      font: 12px 'Roboto', sans-serif;
      font-weight: 400;
      padding: 30px 0 0 0;
      @include tablet {
        padding: 12px 0 0 20px;
      }
      .lock-icon {
        float:left;
        margin-right: 5px;
        width: 12px;
      }
    }
    &__icons {
       padding: 10px 0;
       @include tablet {
         padding: 0 0 0 16px;
       }
      .payment-icon {
        display: inline;
        width: 45px;
        @include tablet {
          width: 37px;
        }
        @include widescreen {
          width: 57px;
        }
      }
    }
  }
}

.footer__middle {
  background: #29215c;
  color: white;
  .container {
    padding: 1rem .5rem;
    @include tablet {
      padding: 2rem 1rem;
    }
    .column {
      h2 {
        border-bottom: 2px solid #a8a8a8;
        color: $white;
        font: 24px $font3;
        font-weight: 400;
        padding-bottom: 10px;
        text-align: center;
        text-transform: uppercase;
      }
      p {
        color: $white;
        font-size: 14px;
        font-weight: 400;
      }
      .worldwide-img, .guarantee-img {
        display: block;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        max-width: 138px;
        padding-bottom: 9px;
      }
      .worldwide-img {
        max-width: 232px;
      }
      .social-img {
        margin-bottom: 15px;
        padding-top: 16px;
      }
    }
  }
}

.footer__bottom {
  background: #29215c;
  color: #ffffff;
  .container {
    padding: 1rem 0.5rem;
    @include tablet {
      padding: 0.5rem 1rem;
    }
    .footer__bottom__nav {
      ul {
        list-style-type: none;
        margin:0;
        padding:0;
        text-align:center;
        @include tablet {
          text-align: center;
        }
        @include desktop {
          text-align: center;
        }
        li {
          display: inline-block;
          padding: 10px 0;
          font-size: 14px;
          margin:0;
          @include tablet {
            font-size: 12px;
            padding: 10px 0 10px 4px;
          }
          a {
            color: #FFFFFF;
            font: 14px 'Roboto Condensed', 'Roboto', sans-serif;
            font-weight: 400;
            padding-right: 8px;
            text-decoration: none;
            transition: all 0.3s;
            @media screen and (min-width: 768px){
              border-right: 1px solid $white;
              font-size: 12px;
            }
            &.last {
              border-right: 0;
            }
            &:hover {
              color: #979796;
            }
          }
        }
      }
    }
  }
}
.footer-links__payments-list {
  li {
    display: inline-block;
  }

  img {
    width: 75px;
  }
}

.footer-copyright {
  @include s-text;
  margin-bottom: 0;

  .container {
    padding: 0rem 1rem;
  }

  .columns {
    margin-bottom: 0
  }

}
