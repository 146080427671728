.guarantee {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(0,0,0, .8);
  flex-flow: column;
  justify-content: center;

  &.no-bg {
    background-color: transparent;
  }

  body.cms-home & {
    background-color: transparent;
  }

  @include tablet {
    flex-flow: row;
    padding: 3rem;
  }

  p {
    position: relative;
  }
}

body[class*="hunter-test"] {
  .guarantee a {
    color: $hunter-orange
  }
}

body[class*="hunter-burn"] {
  .guarantee a {
    color: $hunter-yellow
  }
}

body[class*="hunter-focus"] {
  .guarantee a {
    color: $hunter-blue
  }
}

.guarantee__badge-wrapper {
  margin: 0 0 2rem 0;

  @include tablet {
    margin: 0 3rem 0 0;
  }
}

.guarantee__badge {
  padding: 0;
  width: 240px;
  height: 240px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid white;
  font-family: $font1;

  .guarantee.no-bg & {
    border-color: $light-black
  }
}

.guarantee__badge-inner {
  width: 222px;
  height: 222px;
  padding: 20px;
  border-radius: 50%;
}

.guarantee__badge-top {
  height: 60px;
  position: relative;
  @include flex-center;

  p {
    font-size: 0.8rem;
    @include flex-center;
    margin: 0;

    &:before {
      width: 20px;
      height: 20px;
      content: '';
      display: block;
      background-image: url(../images/cms/icon-check-white.svg);
      background-size: contain;
      margin-right: 10px;
    }
  }

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, $hunter-orange, $hunter-yellow, $blue);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.guarantee__badge-bottom {
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 110px;

  p {
    margin-bottom: 0;

    &:first-child {
      margin-bottom: 15px;
      position: relative;
      @include flex-center;
      font-size: 1.5rem;

      span {
        margin-right: 5px;
      }

      &:before {
        width: 24px;
        height: 25px;
        content: '';
        display: block;
        background-image: url(../images/hunter_bullet_inverted.svg);
        background-size: contain;
        margin-right: 10px;
      }
    }

    &:last-child {
      color: $light-grey
    }
  }
}

.guarantee__wording {
  text-align: center;

  p {
    margin-bottom: 24px
  }

  @include tablet {
    text-align: left;
  }
}
