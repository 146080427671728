.customer-account-login, .customer-account-create, .customer-account-forgotpassword, .customer-account-index {
  #create-account, #send2, #create-account.action.submit.primary, #forgot-password.action.submit.primary {
    // font-size: 1rem;
    // padding: 5px 20px;
    // background-color: #f1f1f1;
    // color: $light-black;
  }
  .btn--blue--standard {
    margin-top: 0;
  }

  .text {
    color: $light-black !important;
  }

  .text:not(:last-child) {
    margin-bottom: 0;
  }

  .field-error, div.mage-error[generated] {
    margin-top: 0px;
    font-size: 1rem;
  }

  .message.error > *:first-child:before {
    margin: -16px 0 0;
  }

  .page.messages {
    margin-top: 55px;
    display: block;

    @include tablet {
      margin-top: 0;
    }

  }

  a.action.remind, a.action.back {
    color: $light-black;
    cursor: pointer;
    text-decoration: none;
  }

  a.action.remind:hover, a.action.back:hover {
    color: $instant-red;
    cursor: pointer;
    text-decoration: none;
  }

  .login-container .fieldset:after {
    margin: 20px 0 0 0;
    color: $instant-red;
    font-size: 1rem;
  }
}

.account-nav {
  .content {
    background: $white;
    padding: 0;

    .nav.items{
      list-style: none;
      margin-left: 0;
      padding: 20px;

      .item {
        margin:0;
        padding: 4px 4px 4px 21px;
        display: block;
        font-size: 14px;
        &:nth-child(1){
          background: #ffffff url(../images/cms/customer-dashboard-icon.png) no-repeat 0 6px;
        }
        &:nth-child(2){
          background: #ffffff url(../images/cms/customer-info-icon.png) no-repeat 0 6px;
        }
        &:nth-child(3){
          background: #ffffff url(../images/cms/customer-address-icon.png) no-repeat 0 6px;
        }
        &.current {
          strong {
            border: none;
            color: $black;
            background: white;
            padding: 5px 10px;
          }
        }

        a {
          border: none;
          color: $black;
          cursor: pointer;
          text-decoration: none;
          padding: 5px 10px;
          font-size: 14px;
          font-weight: 300;

          &:hover, &:focus {
            color: #ff4f41;
            cursor: pointer;
            text-decoration: none;
            background-color: white;
          }
        }
      }
    }
  }

}
.container-fullwidth {
  padding-top: 0;
  width: 100%;
}
.accountdetail {
  background: #F7F7F7;
  border: 1px solid #E4E4E4;
  color: #888;
  text-shadow: 1px 1px 1px #FFF;
  .container {
    padding: 1rem;
    h3 {
      margin-bottom:0.5rem;
    }
    .border-bottom {
      border-bottom: 1px solid #E4E4E4;
    }
  }
  .nmb {
    margin-bottom:0;
  }
}

.customer-account-edit .pbpt {
  padding-bottom: 2rem;
  padding-top: 1rem;
}
.block-dashboard-info, .block-dashboard-addresses, .sales-order-history, .sales-order-view {
  a.action.edit, a.action.change-password, a.action.back {
    color: $light-black;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  a.action.edit:hover, a.action.edit:focus,
  a.action.change-password:hover, a.action.change-password:focus,
  a.action.back:hover, a.action.back:focus {
    color: $instant-red;
    cursor: pointer;
    text-decoration: none;
  }
}
.block-dashboard-orders, #my-orders-table {
  a.action.view {
    color: $instant-red;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
  }
  a.action.view:hover, a.action.view:focus {
    color: $light-black;
    cursor: pointer;
    text-decoration: none;
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .btn--link {
    margin: 20px 0 0 0;
    color: $black;
    font-weight: 400;
    text-transform: initial;

    @include tablet {
      margin: 0 0 0 30px
    }
  }

  @include tablet {
    flex-direction: row;
  }
}

.forgot-password-form, .form-edit-account {
  label:not(.floated-label) {
    display: block !important;
    float: none !important;
    padding: 0 !important;
    text-align: left !important
  }
}
dt:nth-of-type(1) {
    background: $instant-red;
}
.customer-account-login #send2 {
  float: right;
  margin-bottom:0;
}

/**ORDER PAGE**/
.orders-history table > tbody > tr > td, .orders-recent table > tbody > tr > td {
 vertical-align: middle;
}
