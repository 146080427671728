body {
  color: #29225c!important;
  font-family: $font1;
  font-size: 1rem;
}

h1, h2, h3, h4, h5 {
  font-family: $font1;
  font-weight: 400;
}

h1 {
  @include xxl-text;
  &.h1__tan {
    color: $instant-tan;
  }
  &.h1__red {
    color: $instant-red;
  }
}

h2 {
  @include xl-text;
  .caps {
    text-transform: uppercase;
  }
  &.normal {
    font-weight: 400;
  }
  &.h2__red {
    color: $instant-red;

    font-weight: 500;
  }
  span.h2__red {
    color: $instant-red;
  }
  /*&.large {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }*/
  &.bold {
    font-weight: 700;
  }
  /*&.xl-large {
    font-size: 1.75rem;
    line-height: 2rem;
  }*/
  &.h2__tan {
    color: $instant-tan;
  }
  &.arvo {
    font-family: 'Arvo', serif;
  }
  &.dark-grey {
    color: $instant-darkgrey;
  }
}

h3 {
  @include l-text;
  font-weight: 400;
  &.arvo {
    font-family: 'Arvo', serif;
  }
  &.h3__red {
    color: $instant-red;
  }
  &.h3__tan {
    color: $instant-tan;
    &--nomargin {
      margin: 0;
    }
  }
  &.arvo {
    font-family: 'Arvo', serif;
  }
  span.red {
    color: $instant-red;
  }
  span {
    font-weight: 700;
  }
}

h4 {
  @include m-text;
  &.h4__red {
    color: $instant-red;
    font-weight: 400;
  }
  &.arvo {
    font-family: 'Arvo', serif;
  }
}

h5 {
  @include reg-text;
}

p {
  @include reg-text;
  span.red-text {
    color: $instant-red;
  }
  strong {
    font-weight: 900;
  }
  &.align-right {
    text-align: right;
    @include tablet {
      text-align: right;
    }
  }
  &.medium {
    font-weight: 500;
  }
  &.bold {
    font-weight: 700;
  }
  &.arvo {
    font-family: 'Arvo', serif;
  }
  &.tan-text, span.tan-text {
    color: $instant-tan;
  }
  &.light-tan-text, span.light-tan-text {
    color: $instant-gold;
    strong {
      color: $instant-gold;
    }
  }
  &.grey-text {
    color: $instant-grey!important;
  }
  &.dark-grey-text {
    color: #575756;
  }
  &.red-text {
      color: $instant-red;
      .light-tan-text {
        color: $instant-gold;
      }
  }
  &.italic {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.15rem;
    padding: 20px 0;
  }
}
a {
  font-size: 1rem;
  border: none;
  color: #ff4f41;


  &:visited, &:focus {
    color: #ff4f41;
  }

  &.category-title-link {
    color: black;
    text-decoration: none;
  }
}

button {
  background-color: $instant-red;
  border: none;
  outline: none;

  &:visited, &:focus, &:hover {
    background-color: $instant-red;
    border: none;
    outline: none;
    color: white;
  }
}
