.cms-ingredients {
  ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
    }
  }
}
.ingredients-section--1 {
  background: #28235D;
  color: $white;
  position: relative;
  .bottle {
    margin: -32px auto -70px;
    position: absolute;
    @include desktop {
      margin: -83px auto -70px;
      position: relative;
    }
  }
}
.ingredients-section--2 {
  .container {
    max-width: 960px;
    .reasons {
      li {
        list-style-type:none;
        position: relative;
      }
      &-1, &-2, &-3 {
        display: none;
        @include tablet {
          display: block;
        }
      }
      &-1 {
        left: -176px;
        position: absolute;
        top: 20px;
      }
      &-2 {
        left:-135px;
        position: absolute;
        top: 20px;
      }
      &-3 {
        left: -162px;
        position: absolute;
        top: 21px;
      }
    }
  }
}
.ingredients-section--5 {
  padding-top: 2rem;
}
.ingredients-section--9, .testimonials-section--4 {
  .bottle {
    left: 46px;
    position: absolute;
    top: -37px;
    width: 22%;
    z-index: 1;
  }
}
.ingredients-section--14 {
  .male {
    bottom:0;
    margin-bottom: -57px;
    position: relative;
    vertical-align: bottom;
    @include tablet {
      bottom: 0;
      position: absolute;
      vertical-align: bottom;
      left: -151px;
      width: 72%;
    }
    @include desktop {
      left:0;
      margin-bottom: -90px;
      position: relative;
      width: auto;
    }
  }
  .ingredients-table {
    border-top: 3px solid #ff4f41;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #29215c;
    font-size: 0.875rem;
    font-weight: 300;
    margin-bottom: 0;
    th {
      border-bottom: 3px solid #ff4f41;
      color: #29225c;
    }
    tr {
      border: none;
      &.blue-table-bottom {
        border-bottom: 1px solid #29225c;
      }
      &.orange-table-bottom {
        border-bottom: 3px solid #ff4f41;
      }
    }
  }
  .footnote {
    margin-top: 20px!important;
  }
}

.ingredients-section--16 {
  @include tablet {
      padding-bottom: 4rem;
  }
}

/**COUNTRY SPECIFIC ADJUSTMENTS**/

.es-lang .ingredients-section--14 .male, .fr-lang .ingredients-section--14 .male, .it-lang .ingredients-section--14 .male, .de-lang .ingredients-section--14 .male  {
  margin-bottom: -110px;
}
