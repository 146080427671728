/*
	 CSS-Tricks Example
	 by Chris Coyier
	 http://css-tricks.com
*/

.info-col {
	float: left;
	width: 200px;
	/* height: 100%; */
	/* padding: 50px 0 0 0; */
}

a .superman:hover{
	background-color: #000;
}

.batman {background: url(../img/batman.jpg) center center no-repeat; }

.spiderman { background: url(../img/spiderman.jpg) center center no-repeat;}

.aquaman { background: url(../img/aquaman.jpg) center center no-repeat;}

.ironman { background: url(../img/ironman.jpg) center center no-repeat;}

.superman { background: url(../img/superman.jpg) center center no-repeat;}

dt {
	padding: 5px;
	background: #900;
	color: white;
}
dd {
	position: absolute;
	left: -9999px;
	top: -9999px;
	width: 299px;
	background: #900;
	padding: 10px;
	color: white;
}

dt:nth-of-type(1) { background: #b44835; }
dd:nth-of-type(1) { background: #b44835; }

dt:nth-of-type(2) { background: #ff7d3e; }
dd:nth-of-type(2) { background: #ff7d3e; }

dt:nth-of-type(3) { background: #ffb03b; }
dd:nth-of-type(3) { background: #ffb03b; }

dt:nth-of-type(4) { background: #c2a25c; }
dd:nth-of-type(4) { background: #c2a25c; }

dt:nth-of-type(5) { background: #4c443c; }
dd:nth-of-type(5) { background: #4c443c; }

dt:nth-of-type(6) { background: #656b60; }
dd:nth-of-type(6) { background: #656b60; }

.curCol { -moz-box-shadow: 0 0 10px rgba(0,0,0,0.2); -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.2); z-index: 1; position: relative; }
