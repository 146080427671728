.btn {
  display: inline-block;
  transition: all ease .3s;
  padding: 8px 15px;
  border-radius: 2px;
  display: inline-block;
  font-family: $font3;
  margin-bottom: 10px;
  margin-top: 14px;
  text-transform: uppercase;
  font-size: 1.4rem;

  /**core buttons**/
  &--blue {
    background: #29215c url(../images/icons/blue-button-arrow.png) no-repeat 96% 12px;
    border: 2px solid #29215c;
    color: $white;
    font-weight: 400;
    padding: 5px 53px 5px 9px;
    &:hover {
      background: #29215c url(../images/icons/blue-button-arrow.png) no-repeat 96% 12px;
      border: 2px solid #ff4f41;
      border-color: #ff4f41;
    }
    &:visited, &:hover {
      color: $white;
    }
    &--standard {
      border: 2px solid #29215c;
      background: #29215c;
      color: $white;
      font-size: 1.625rem;
      font-weight: 400;
      line-height:2rem;
      padding: 10px 15px;
      &:hover, &:focus, &:active {
        border: 2px solid #ff4f41;
        background: #29215c;
        border-color: #ff4f41;
        color: $white;
      }
      &:visited {
        color: $white;
      }
    }
    &--smallstandard {
      border: 2px solid #29215c;
      background: #29215c;
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 12px 25px;
      &:hover {
        background: #29215c;
        border: 2px solid #ff4f41;
      }
    }
    &--bordered {
      background: $white;
      border: 2px solid #29215c;
      color: #29215c;
      &:hover {
        background: #29215c;
        border: 2px solid #ff4f41;
        color: $white;
        transition: all 0.3s;
      }
    }
  }
  &--green {
    background: #5cb85c;
    border: 2px solid #5cb85c;
    color: $white!important;
    font-size: 1.325rem;
    font-weight: 400;
    line-height:2rem;
    padding: 10px 15px;
    text-align:center;
    @include tablet {
      font-size: 1.625rem;
    }
    &:hover {
      background: #5cb85c!important;
      border: 2px solid #5cb85c;
      color: #29235c!important;
    }
    &:focus {
      background: #4da54d;
      border: 2px solid #5cb85c;
    }
    &:visited {
      color: $white;
    }
  }
  &--white {
    background: $white url(../images/icons/white-button-arrow.png) no-repeat 96% 12px;
    border: 2px solid $white;
    color: #ff4f41;
    padding: 5px 53px 5px 9px;
    &:hover {
      border-color: #ff4f41;
    }
    &:visited, &:visited {
      color: #ff4f41;
    }
    &--orangeback {
      color: #29225c;
      &:hover {
        border-color: #29225c;
        color: #29225c;
      }
      &:visited, &:focus {
        color: #29225c;
      }
    }
  }
  &--fullwidth {
    width: 100%;
  }

  &.showcart {
    padding: 0
  }

  &:hover {
    text-decoration: none;
  }

  &--small {
    padding: 5px 8px;
    font-size: 1rem;
    line-height: 2rem;
    @include tablet {
      font-size: 1.1rem;
    }
  }


  &--black {
    background-color: $light-black;
    color: white;
    /*border: 2px solid $light-black;*/
    margin-right: 10px;

    &:hover {
      color: white;
      background-color: lighten($light-black, 10%);
    }
  }

  &--account {
    font-size: 12px!important;
    margin:0;
    padding: 10px!important;
  }
  &--bordered {
    border: 2px solid;
    background: none;

    &-white {
      border-color: white;
      color: white;

      &:hover {
        background-color: white;
        border-color: white;
        color: black;
      }
    }
    &-red {
      border: 2px solid #ff4f41;
      &:hover {
        background: #ff4f41;
        color: $white;
        transition: all 0.3s;
      }
    }

    &-black {
      color: $black!important;
      border-color: $black;
      font-weight: 300;
      padding: 10px 25px;
      font-size: 1.625rem;
      font-weight: 400;
      line-height: 2rem;

      &.btn--small {
        font-size: .8rem;
        line-height: 0.975rem;
        margin-bottom:10px;
        padding: 8px 15px;
      }

      &:hover {
        color: $white!important;
        background-color: $black;
        border: 2px solid $black;
      }
      &:focus {
        background: $black;
        border: 2px solid $black;
        color: $white!important;
      }
    }

  }

  &--link {
    font-weight: 500;
    padding: 0;
  }

  /*&--add-to-cart {
    position: relative;
    height: auto;
    border-radius: 5px;
    margin-left: 40px;

    &:hover {
      border: none;
      color: white;
    }

    &:visited {
      background-color: $instant-red;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
    }
  }

  &--cart {
    &:before {
      position: relative;
      display: inline-block;
      font-family: 'fontawesome';
      margin-right: 5px;
    }

    &.clear-cart {
      &:before {
        content: '\f00d';
      }
    }

    &.update-cart {
      &:before {
        content: '\f021';
      }
    }
  }*/
}


#to-top {
  display: none;
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 99;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: $light-black;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;

  &:hover {
    background-color: $light-grey;

  }

  @include tablet {
    right: 26px;
  }
}

/**modal overrides**/
.action-accept {
    background: #29215c;
    border-radius: 2px;
    border: 2px solid #29215c;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 0;
    margin-top: 0px;
    padding: 10px 25px 10px 25px;
    text-align: center;
    &:hover, &:focus {
      background: #29215c;
      border: 2px solid #ff4f41;
    }
}
.action-dismiss {
  background: none;
  border: 2px solid #da0f1a;
  color: #da0f1a;
  transition: all 0.2s;
  &:hover {
    background: #da0f1a;
    color: #ffffff;
    transition: all 0.2s;
  }
}
