.references {
  color: white;

  ol {
    margin: 0 0 0 14px;
    padding: 0;
    list-style-position: outside;
  }

  li {
    font-size: 0.8rem;
    word-break: break-word;
    margin-bottom: .25rem;
  }
}
