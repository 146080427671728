.icon--check {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: cover;
  top: 2px;
  margin-right: 10px;

  body.category-hunter-test & {
    background-image: url(../images/cms/icon-check-test.svg);
  }

  body.category-hunter-burn & {
    background-image: url(../images/cms/icon-check-burn.svg);
  }

  body.category-hunter-focus & {
    background-image: url(../images/cms/icon-check-focus.svg);
  }
}
