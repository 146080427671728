.ingredients-block {
  &--test-orange {
    color: $test-orange;
  }
  &--test-blue {
    color: $test-blue;
  }
  &--test-peach {
    color: $test-peach;
  }
  &--burn-yellow {
    color: $burn-yellow;
  }
  &--burn-green {
    color: $burn-green;
  }
  &--burn-peach {
    color: $burn-peach;
  }
  &--focus-blue {
    color: $focus-blue;
  }
  &--focus-green {
    color: $focus-green;
  }
  &--focus-light-blue {
    color: $focus-light-blue;
  }
}

.ingredients-block__title {
  @include sm-text;
  font-weight: 700;
  margin: 30px 0 10px !important;
  font-family: $font1;
  color: currentColor
}

.ingredients-block__images {
  display: flex;
  justify-content: center;
}

.ingredients-image {
  border-radius: 50%;
  border: 3px solid currentColor;
  margin: 10px;
  width: 84px;
  height: 84px;
}

.category-ingredients {
  position: relative;
  padding-top: 3rem;

  &:before {
    background-attachment: top
  }
}

.category-ingredients-block {
  position: relative;
  margin-bottom: 3rem;
  border-radius: 20px;
  border: 3px solid;
  border-top: none;

  &:not(:first-child) {
    padding: 16px;
  }

  &:first-child {
    border: none;

    @include desktop {
      padding-right: 120px;
    }
  }

  @include desktop {
    width: 48%;

    &:first-child {
      float: left;
    }

    &:nth-child(2) {
      float: right;
    }

    &:nth-child(3) {
      clear: left;
      float: left
    }

    &:nth-child(4) {
      float: right;
    }
  }
}

.category-ingredients-block__content {
  padding: 1.5rem;
  background-color: $light-black;
  border-radius: 10px;
}

.category-ingredients-block__wording {
  p {
    color: white
  }
}

.category-ingredients-block__title {
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:before, &:after {
    display: block;
    content: "";
    border-top: 3px solid currentColor;
  }

  &:before {
    border-radius: 20px 0 0 0;
    flex: 1 0 0;
    margin: -1px 0 0 -1px;

    @include desktop {
      flex: 0.3 0 0;
    }
  }

  &:after {
    border-radius: 0 20px 0 0;
    flex: 1 0 0;
    margin: -1px -1px 0 0;

    @include desktop {
      flex: 2 0 0;
    }
  }

  p {
    margin-top: -16px;
    text-align: center;
    font-weight: 700;

    .category-ingredients-block:nth-child(2) & {
      flex: 0 0 230px;
    }

    .category-ingredients-block:nth-child(3) & {
      flex: 0 0 155px;
    }

    .category-ingredients-block:nth-child(4) & {
      flex: 0 0 185px;
    }
  }
}

.category-ingredients-block__ingredients {
  margin-bottom: 16px;

  @include tablet {
    display: flex;
    justify-content: center;
  }

  li {
    text-align: center;
    font-family: $font1;
    font-weight: 700;
    margin: 10px auto 20px;
    width: 110px;

    @include tablet {
      margin: 10px
    }
  }
}

.category-ingredients-block__ingredient-title {
  margin-bottom: 8px;

  @include tablet {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  p {
    line-height: 1.5rem;
    color: white;
  }
}

.cms-page__ingredients {
  padding-top: 50px;
}

.category-card-title-offset  {
  margin-top: -90px;
  position: relative;
  z-index: 10;
  margin-bottom: 20px;
  text-align: center;

  @include desktop {
    display: flex;
    align-items: center;
    margin-top: -90px;
  }

  h2, p {
    margin-bottom: 0;

    span {
      margin-right: 10px;
    }
  }
}

.ingredient-single {
  margin-top: 40px;

  @include tablet {
    padding: 2rem 3rem 3rem;
    margin-bottom: 2rem;
  }
}

.ingredients-single__divider {
  height: 3px;
  width: 100%;
  background-color: #000;
  margin: 1.5rem 0;
}

.ingredient-benefit-wrapper {
  h2 {
    position: relative;

    @media (max-width: 1023px) {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include desktop {
        text-align: left;

        &:after {
          display: none;
        }
      }

      &:before, &:after {
        content: '';
        border-top: 3px solid currentColor;
        margin: 0 20px 0 0;
        flex: 1 0 20px;
      }

      &:after {
        margin: 0 0 0 20px;
      }
    }

    @include desktop {
      &:before {
        content: '';
        width: 300%;
        height: 3px;
        background-color: currentColor;
        position: absolute;
        left: calc(-300% - 40px);
        top: 50%;
      }
    }
  }
}

.ingredients-references {
  margin: 40px 0 140px;

  &:last-child {
    margin-bottom: 0;
  }

  h5 {
    font-weight: 700;
    @include s-text;
    letter-spacing: 2px;
  }
}

.ingredients-waves {
  top: 0 !important;
  position: absolute;
  opacity: 1;
}
