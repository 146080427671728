#red-bar {
  background: #ff4f41;
  color: #ffffff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  font: 14px 'Roboto Condensed', sans-serif;

  .xxs-text {
    line-height: 24px;
  }

  .primary-header__top-links-item--my-account a span {
    font-size: 12px;
    margin-left: 4px;
    @include tablet {
      font-size: 14px;
      margin-left: 12px;
    }
  }

  img.globe {
    float: left;
    vertical-align: middle;
    width: 29px;
  }

  p {
    float: left;
    font-size: 14px;
    margin-bottom: 0;
  }

  .container {
    padding: 0;

    .minicart-block {
      float: right;
      position: relative;
      width: 50px;
      @include tablet {
        width: 35px;
      }
    }
  }

  #country-select-trigger {
    align-items: center;
    background: $white;
    color: $black;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    min-width: 160px;
    justify-content: center;

    &:hover {
      text-decoration: none;
    }

    img {
      margin-left: 10px;
    }

    p {
      font: 14px 'Roboto Condensed', sans-serif;
      font-weight: 300;
      margin: 0 10px 0 0;

      span {
        display: inline-block;
      }
    }
  }
}

#flashmessage {
  background: #28235d;
  position: relative;
  z-index: 0;
  padding-bottom: 10px;

  @include desktop {
    padding-bottom: 20px;
    margin-top: -30px;
  }
}

header.page-header .covid-accordion {
  display: block;
  margin: auto;

  .accordion-trigger {
    .fa {
      margin-left: 10px;
    }
  }

  .accordion-trigger:not(.active) {
    .fa.fa-chevron-up {
      display: none;
    }
  }

  .accordion-trigger {
    display: block;

    &.active {
      .fa.fa-chevron-down {
        display: none;
      }
    }

    @include tablet {
      display: none;
    }
  }

  .accordion-content {
    text-align: left;

    &.active {
      padding: 10px 0 0 0;
    }

    @include tablet {
      display: block;

      &.active {
        padding: 0;
      }
    }

    @include desktop {
      text-align: center;
    }
  }

  @include tablet {
    display: inline-block;
  }

  @include mobile {
    padding: 3px;
  }
}

.navbar-burger {
  margin-left: 0.5em;
  color: #FFF;

  &.is-active,
  &:focus,
  &:hover {
    background: #28235d!important;
    color: $white!important;
  }

  span {
    height: 2px;

    &:nth-child(1) {
      top: calc(50% - 7px) !important;
    }

    &:nth-child(2) {
      top: calc(50% - 1px) !important;
    }

    &:nth-child(3) {
      top: calc(50% + 5px) !important;
    }
  }

  &.is-active {
    span:nth-child(3) {
      top: calc(50% + 3px) !important;
    }
  }
}

#nav-menu {
  .box {
    padding: 0.10rem 0.40rem;

    .navbar-item,
    .navbar-link {
      padding: 0.5rem;

      &:focus {
        background: none;
      }
    }
  }
}

.minicart-block {
  margin: 0 auto;
  width: 30px;
  @include tablet {
    width: 35px;
  }

  .showcart {
    background: none!important;
    padding: 0!important;

    &:focus,
    &:hover {
      background: none!important;
    }

    .counter {
      background: #000;
      border-radius: 20px;
      color: #fff!important;
      display: inline-block;
      font-size: 12px;
      font-weight: 700;
      padding: 0 6px;
      position: absolute;
      right: -3px;
      top: -5px;
      z-index: 5;
    }
  }
}
/* NAVBAR */
.navbar-brand {
  #logo {
    img {
      height: auto;
      max-height: inherit;
      width: 100px;
      @include desktop {
        width: 150px;
      }
      @include widescreen {
        width: 200px;
      }
    }
  }
  @include tablet {
    align-items: center;
  }
}

.navbar {
  background-color: #28235d;
  z-index: 5;
  @include desktop {
    z-index: 0;
  }

  .container {
    margin: 0 auto;
    padding: 10px;

    .navbar-brand {
      align-items: center;
      justify-content: space-between;
    }

    .navbar-end {
      @include desktop {
        padding-right: 2px;
      }

      hr {
        &.navbar-divider {
          display: block;
          margin: 0;
        }
      }

      a.navbar-item {
        background: $white;
        color: #28235d;
        font-size: 0.9375rem;
        font-weight: 400;
        padding: 21px 20px;
        text-transform: capitalize;
        transition: all 0.5s;

        &:hover {
          background: #ff4f41;
          color: $white;
          text-decoration: none;
          transition: all 0.5s;
        }

        .counter {
          position: absolute;
        }
        @include desktop {
          height: 56px;
          margin-right: 10px;
        }

        &.is-two-lines {
          padding: 21px 20px;

          br {
            display: none;
            @include desktop {
              display: block;
            }
          }
          @include desktop {
            line-height: 15px;
            padding: 20px;
          }
          @include desktop {
            padding: 14px 20px;
          }
        }
      }
    }

    .navbar-menu {
      background: none;
      box-shadow: none;
      clear: right;
      height: auto;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      width: auto;
      z-index: 100;

      &.is-active {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
      }
      @include desktop {
        width: auto;
        float: right;
        clear: right;
        margin-top: 15px;
        font-size: 15px;
        font-family: 'Roboto';
        text-transform: uppercase;
        z-index: 100;
      }

      .buttons {
        display: flex;
        flex-flow: column;
        padding: 14px;
        @include tablet {
          .primary-header__top-links-item--my-account a span {
            display: block;
          }
        }
        @include desktop {
          background: none;
          padding: 0 10px 0 0;
        }

        a.button {
          margin-top: 0;
          width: 166px;
          @include widescreen {
            width: auto;
          }
        }

        &__cards {
          display: flex;
          padding: 0 0 17px;

          img {
            align-self: center;
            margin-right: 10px;
            width: 35px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &.mobile-account {
          .primary-header__top-links-item--my-account a {
            span {
              color: #ff4f41;
            }

            &:before {
              background-image: url("../images/cms/account-icon-orange.svg");
            }
          }
        }
      }
    }
  }
}

a {
  &#logo {
    padding: 8px 20px 0;
  }
}
/** ACTIVE LINK STYLING FOR NAVIGATION**/
body[class*="cms-faq"] #nav-menu .navbar-end a.navbar-item:nth-child(11),
body[class*="cms-for-women"] #nav-menu .navbar-end a.navbar-item:nth-child(7),
body[class*="cms-how-it-works"] #nav-menu .navbar-end a.navbar-item:nth-child(3),
body[class*="cms-index"] #nav-menu .navbar-end a.navbar-item:nth-child(1),
body[class*="cms-ingredients"] #nav-menu .navbar-end a.navbar-item:nth-child(5),
body[class*="cms-low-testosterone"] #nav-menu .navbar-end a.navbar-item:nth-child(9),
body[class*="cms-our-testimonials"] #nav-menu .navbar-end a.navbar-item:nth-child(7),
body[class*="cms-pro-athletes"] #nav-menu .navbar-end a.navbar-item:nth-child(9) {
  background: #ff4f41;
  color: $white;
}
/**ODD LANGUAGE SPECIFIC ELEMENT HIDING**/
@media screen and (max-width: 768px) {
  .fr-lang #red-bar p span.is-hidden-mobile {
    display: none;
  }

  .es-lang #red-bar p span.is-hidden-mobile {
    display: none;
  }
}
