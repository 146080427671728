.robbyrobinson-section--1 {
  .bottle {
    margin: -83px auto -70px;
  }
}
.robbyrobinson-section--2, .robbyrobinson-section--4, .robbyrobinson-section--5, .robbyrobinson-section--7 {
  .img {
    display: block;
    margin-bottom: 30px;
    width: 100%;
  }
}
.robbyrobinson-section--7 {
  padding-bottom: 4rem;
  ul {
    padding-left: 3rem;
    li {
      list-style-type:none;
      margin-bottom: 0.25rem;
      &:before {
        content: "\2022";
        color: #ff4f41;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }

  }
}
