/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'variables';

@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-duotone-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-duotone-900.woff') format('woff'),
    url('#{$fa-font-path}/fa-duotone-900.ttf') format('truetype');
}

.fad,
.fa-duotone {
  position: relative;
  font-family: 'Font Awesome 6 Duotone';
  font-weight: 900;
}

.fad:before,
.fa-duotone:before {
  position: absolute;
  color: var(--#{$fa-css-prefix}-primary-color, inherit);
  opacity: var(--#{$fa-css-prefix}-primary-opacity, #{$fa-primary-opacity});
}

.fad:after,
.fa-duotone:after {
  color: var(--#{$fa-css-prefix}-secondary-color, inherit);
  opacity: var(--#{$fa-css-prefix}-secondary-opacity, #{$fa-secondary-opacity});
}

.#{$fa-css-prefix}-swap-opacity .fad:before,
.#{$fa-css-prefix}-swap-opacity .fa-duotone:before,
.fad.#{$fa-css-prefix}-swap-opacity:before,
.fa-duotone.#{$fa-css-prefix}-swap-opacity:before {
  opacity: var(--#{$fa-css-prefix}-secondary-opacity, #{$fa-secondary-opacity});
}

.#{$fa-css-prefix}-swap-opacity .fad:after,
.#{$fa-css-prefix}-swap-opacity .fa-duotone:after,
.fad.#{$fa-css-prefix}-swap-opacity:after,
.fa-duotone.#{$fa-css-prefix}-swap-opacity:after {
  opacity: var(--#{$fa-css-prefix}-primary-opacity, #{$fa-primary-opacity});
}

.fad.#{$fa-css-prefix}-inverse,
.fa-duotone.#{$fa-css-prefix}-inverse {
  color: var(--#{$fa-css-prefix}-inverse, $fa-inverse);
}

.fad.#{$fa-css-prefix}-stack-1x, .fad.#{$fa-css-prefix}-stack-2x,
.fa-duotone.#{$fa-css-prefix}-stack-1x, .fa-duotone.#{$fa-css-prefix}-stack-2x {
  position: absolute;
}

.fad.#{$fa-css-prefix}-stack-1x:before,
.fa-duotone.#{$fa-css-prefix}-stack-1x:before,
.fad.#{$fa-css-prefix}-stack-2x:before,
.fa-duotone.#{$fa-css-prefix}-stack-2x:before,
.fad.#{$fa-css-prefix}-fw:before,
.fa-duotone.#{$fa-css-prefix}-fw:before {
  left: 50%;
  transform: translateX(-50%);
}

.fad.#{$fa-css-prefix}-0:before, .fa-duotone.#{$fa-css-prefix}-0:before { content: fa-content(\30\fe01); }
.fad.#{$fa-css-prefix}-0:after, .fa-duotone.#{$fa-css-prefix}-0:after { content: fa-content(\30\fe02); }
.fad.#{$fa-css-prefix}-1:before, .fa-duotone.#{$fa-css-prefix}-1:before { content: fa-content(\31\fe01); }
.fad.#{$fa-css-prefix}-1:after, .fa-duotone.#{$fa-css-prefix}-1:after { content: fa-content(\31\fe02); }
.fad.#{$fa-css-prefix}-2:before, .fa-duotone.#{$fa-css-prefix}-2:before { content: fa-content(\32\fe01); }
.fad.#{$fa-css-prefix}-2:after, .fa-duotone.#{$fa-css-prefix}-2:after { content: fa-content(\32\fe02); }
.fad.#{$fa-css-prefix}-3:before, .fa-duotone.#{$fa-css-prefix}-3:before { content: fa-content(\33\fe01); }
.fad.#{$fa-css-prefix}-3:after, .fa-duotone.#{$fa-css-prefix}-3:after { content: fa-content(\33\fe02); }
.fad.#{$fa-css-prefix}-4:before, .fa-duotone.#{$fa-css-prefix}-4:before { content: fa-content(\34\fe01); }
.fad.#{$fa-css-prefix}-4:after, .fa-duotone.#{$fa-css-prefix}-4:after { content: fa-content(\34\fe02); }
.fad.#{$fa-css-prefix}-5:before, .fa-duotone.#{$fa-css-prefix}-5:before { content: fa-content(\35\fe01); }
.fad.#{$fa-css-prefix}-5:after, .fa-duotone.#{$fa-css-prefix}-5:after { content: fa-content(\35\fe02); }
.fad.#{$fa-css-prefix}-6:before, .fa-duotone.#{$fa-css-prefix}-6:before { content: fa-content(\36\fe01); }
.fad.#{$fa-css-prefix}-6:after, .fa-duotone.#{$fa-css-prefix}-6:after { content: fa-content(\36\fe02); }
.fad.#{$fa-css-prefix}-7:before, .fa-duotone.#{$fa-css-prefix}-7:before { content: fa-content(\37\fe01); }
.fad.#{$fa-css-prefix}-7:after, .fa-duotone.#{$fa-css-prefix}-7:after { content: fa-content(\37\fe02); }
.fad.#{$fa-css-prefix}-8:before, .fa-duotone.#{$fa-css-prefix}-8:before { content: fa-content(\38\fe01); }
.fad.#{$fa-css-prefix}-8:after, .fa-duotone.#{$fa-css-prefix}-8:after { content: fa-content(\38\fe02); }
.fad.#{$fa-css-prefix}-9:before, .fa-duotone.#{$fa-css-prefix}-9:before { content: fa-content(\39\fe01); }
.fad.#{$fa-css-prefix}-9:after, .fa-duotone.#{$fa-css-prefix}-9:after { content: fa-content(\39\fe02); }
.fad.#{$fa-css-prefix}-360-degrees:before, .fa-duotone.#{$fa-css-prefix}-360-degrees:before { content: fa-content(\e2dc\fe01); }
.fad.#{$fa-css-prefix}-360-degrees:after, .fa-duotone.#{$fa-css-prefix}-360-degrees:after { content: fa-content(\e2dc\fe02); }
.fad.#{$fa-css-prefix}-a:before, .fa-duotone.#{$fa-css-prefix}-a:before { content: fa-content(\41\fe01); }
.fad.#{$fa-css-prefix}-a:after, .fa-duotone.#{$fa-css-prefix}-a:after { content: fa-content(\41\fe02); }
.fad.#{$fa-css-prefix}-abacus:before, .fa-duotone.#{$fa-css-prefix}-abacus:before { content: fa-content(\f640); }
.fad.#{$fa-css-prefix}-abacus:after, .fa-duotone.#{$fa-css-prefix}-abacus:after { content: fa-content(\10f640); }
.fad.#{$fa-css-prefix}-accent-grave:before, .fa-duotone.#{$fa-css-prefix}-accent-grave:before { content: fa-content(\60\fe01); }
.fad.#{$fa-css-prefix}-accent-grave:after, .fa-duotone.#{$fa-css-prefix}-accent-grave:after { content: fa-content(\60\fe02); }
.fad.#{$fa-css-prefix}-acorn:before, .fa-duotone.#{$fa-css-prefix}-acorn:before { content: fa-content(\f6ae\fe01); }
.fad.#{$fa-css-prefix}-acorn:after, .fa-duotone.#{$fa-css-prefix}-acorn:after { content: fa-content(\f6ae\fe02); }
.fad.#{$fa-css-prefix}-address-book:before, .fa-duotone.#{$fa-css-prefix}-address-book:before { content: fa-content(\f2b9\fe01); }
.fad.#{$fa-css-prefix}-address-book:after, .fa-duotone.#{$fa-css-prefix}-address-book:after { content: fa-content(\f2b9\fe02); }
.fad.#{$fa-css-prefix}-contact-book:before, .fa-duotone.#{$fa-css-prefix}-contact-book:before { content: fa-content(\f2b9\fe01); }
.fad.#{$fa-css-prefix}-contact-book:after, .fa-duotone.#{$fa-css-prefix}-contact-book:after { content: fa-content(\f2b9\fe02); }
.fad.#{$fa-css-prefix}-address-card:before, .fa-duotone.#{$fa-css-prefix}-address-card:before { content: fa-content(\f2bb\fe01); }
.fad.#{$fa-css-prefix}-address-card:after, .fa-duotone.#{$fa-css-prefix}-address-card:after { content: fa-content(\f2bb\fe02); }
.fad.#{$fa-css-prefix}-contact-card:before, .fa-duotone.#{$fa-css-prefix}-contact-card:before { content: fa-content(\f2bb\fe01); }
.fad.#{$fa-css-prefix}-contact-card:after, .fa-duotone.#{$fa-css-prefix}-contact-card:after { content: fa-content(\f2bb\fe02); }
.fad.#{$fa-css-prefix}-vcard:before, .fa-duotone.#{$fa-css-prefix}-vcard:before { content: fa-content(\f2bb\fe01); }
.fad.#{$fa-css-prefix}-vcard:after, .fa-duotone.#{$fa-css-prefix}-vcard:after { content: fa-content(\f2bb\fe02); }
.fad.#{$fa-css-prefix}-air-conditioner:before, .fa-duotone.#{$fa-css-prefix}-air-conditioner:before { content: fa-content(\f8f4\fe01); }
.fad.#{$fa-css-prefix}-air-conditioner:after, .fa-duotone.#{$fa-css-prefix}-air-conditioner:after { content: fa-content(\f8f4\fe02); }
.fad.#{$fa-css-prefix}-airplay:before, .fa-duotone.#{$fa-css-prefix}-airplay:before { content: fa-content(\e089\fe01); }
.fad.#{$fa-css-prefix}-airplay:after, .fa-duotone.#{$fa-css-prefix}-airplay:after { content: fa-content(\e089\fe02); }
.fad.#{$fa-css-prefix}-alarm-clock:before, .fa-duotone.#{$fa-css-prefix}-alarm-clock:before { content: fa-content(\23f0\fe01); }
.fad.#{$fa-css-prefix}-alarm-clock:after, .fa-duotone.#{$fa-css-prefix}-alarm-clock:after { content: fa-content(\23f0\fe02); }
.fad.#{$fa-css-prefix}-alarm-exclamation:before, .fa-duotone.#{$fa-css-prefix}-alarm-exclamation:before { content: fa-content(\f843\fe01); }
.fad.#{$fa-css-prefix}-alarm-exclamation:after, .fa-duotone.#{$fa-css-prefix}-alarm-exclamation:after { content: fa-content(\f843\fe02); }
.fad.#{$fa-css-prefix}-alarm-plus:before, .fa-duotone.#{$fa-css-prefix}-alarm-plus:before { content: fa-content(\f844\fe01); }
.fad.#{$fa-css-prefix}-alarm-plus:after, .fa-duotone.#{$fa-css-prefix}-alarm-plus:after { content: fa-content(\f844\fe02); }
.fad.#{$fa-css-prefix}-alarm-snooze:before, .fa-duotone.#{$fa-css-prefix}-alarm-snooze:before { content: fa-content(\f845\fe01); }
.fad.#{$fa-css-prefix}-alarm-snooze:after, .fa-duotone.#{$fa-css-prefix}-alarm-snooze:after { content: fa-content(\f845\fe02); }
.fad.#{$fa-css-prefix}-album:before, .fa-duotone.#{$fa-css-prefix}-album:before { content: fa-content(\f89f); }
.fad.#{$fa-css-prefix}-album:after, .fa-duotone.#{$fa-css-prefix}-album:after { content: fa-content(\10f89f); }
.fad.#{$fa-css-prefix}-album-collection:before, .fa-duotone.#{$fa-css-prefix}-album-collection:before { content: fa-content(\f8a0\fe01); }
.fad.#{$fa-css-prefix}-album-collection:after, .fa-duotone.#{$fa-css-prefix}-album-collection:after { content: fa-content(\f8a0\fe02); }
.fad.#{$fa-css-prefix}-alicorn:before, .fa-duotone.#{$fa-css-prefix}-alicorn:before { content: fa-content(\f6b0\fe01); }
.fad.#{$fa-css-prefix}-alicorn:after, .fa-duotone.#{$fa-css-prefix}-alicorn:after { content: fa-content(\f6b0\fe02); }
.fad.#{$fa-css-prefix}-alien:before, .fa-duotone.#{$fa-css-prefix}-alien:before { content: fa-content(\f8f5); }
.fad.#{$fa-css-prefix}-alien:after, .fa-duotone.#{$fa-css-prefix}-alien:after { content: fa-content(\10f8f5); }
.fad.#{$fa-css-prefix}-alien-8bit:before, .fa-duotone.#{$fa-css-prefix}-alien-8bit:before { content: fa-content(\f8f6); }
.fad.#{$fa-css-prefix}-alien-8bit:after, .fa-duotone.#{$fa-css-prefix}-alien-8bit:after { content: fa-content(\10f8f6); }
.fad.#{$fa-css-prefix}-alien-monster:before, .fa-duotone.#{$fa-css-prefix}-alien-monster:before { content: fa-content(\f8f6); }
.fad.#{$fa-css-prefix}-alien-monster:after, .fa-duotone.#{$fa-css-prefix}-alien-monster:after { content: fa-content(\10f8f6); }
.fad.#{$fa-css-prefix}-align-center:before, .fa-duotone.#{$fa-css-prefix}-align-center:before { content: fa-content(\f037\fe01); }
.fad.#{$fa-css-prefix}-align-center:after, .fa-duotone.#{$fa-css-prefix}-align-center:after { content: fa-content(\f037\fe02); }
.fad.#{$fa-css-prefix}-align-justify:before, .fa-duotone.#{$fa-css-prefix}-align-justify:before { content: fa-content(\f039\fe01); }
.fad.#{$fa-css-prefix}-align-justify:after, .fa-duotone.#{$fa-css-prefix}-align-justify:after { content: fa-content(\f039\fe02); }
.fad.#{$fa-css-prefix}-align-left:before, .fa-duotone.#{$fa-css-prefix}-align-left:before { content: fa-content(\f036\fe01); }
.fad.#{$fa-css-prefix}-align-left:after, .fa-duotone.#{$fa-css-prefix}-align-left:after { content: fa-content(\f036\fe02); }
.fad.#{$fa-css-prefix}-align-right:before, .fa-duotone.#{$fa-css-prefix}-align-right:before { content: fa-content(\f038\fe01); }
.fad.#{$fa-css-prefix}-align-right:after, .fa-duotone.#{$fa-css-prefix}-align-right:after { content: fa-content(\f038\fe02); }
.fad.#{$fa-css-prefix}-align-slash:before, .fa-duotone.#{$fa-css-prefix}-align-slash:before { content: fa-content(\f846\fe01); }
.fad.#{$fa-css-prefix}-align-slash:after, .fa-duotone.#{$fa-css-prefix}-align-slash:after { content: fa-content(\f846\fe02); }
.fad.#{$fa-css-prefix}-alt:before, .fa-duotone.#{$fa-css-prefix}-alt:before { content: fa-content(\e08a\fe01); }
.fad.#{$fa-css-prefix}-alt:after, .fa-duotone.#{$fa-css-prefix}-alt:after { content: fa-content(\e08a\fe02); }
.fad.#{$fa-css-prefix}-amp-guitar:before, .fa-duotone.#{$fa-css-prefix}-amp-guitar:before { content: fa-content(\f8a1\fe01); }
.fad.#{$fa-css-prefix}-amp-guitar:after, .fa-duotone.#{$fa-css-prefix}-amp-guitar:after { content: fa-content(\f8a1\fe02); }
.fad.#{$fa-css-prefix}-ampersand:before, .fa-duotone.#{$fa-css-prefix}-ampersand:before { content: fa-content(\26\fe01); }
.fad.#{$fa-css-prefix}-ampersand:after, .fa-duotone.#{$fa-css-prefix}-ampersand:after { content: fa-content(\26\fe02); }
.fad.#{$fa-css-prefix}-anchor:before, .fa-duotone.#{$fa-css-prefix}-anchor:before { content: fa-content(\2693\fe01); }
.fad.#{$fa-css-prefix}-anchor:after, .fa-duotone.#{$fa-css-prefix}-anchor:after { content: fa-content(\2693\fe02); }
.fad.#{$fa-css-prefix}-angel:before, .fa-duotone.#{$fa-css-prefix}-angel:before { content: fa-content(\f779\fe01); }
.fad.#{$fa-css-prefix}-angel:after, .fa-duotone.#{$fa-css-prefix}-angel:after { content: fa-content(\f779\fe02); }
.fad.#{$fa-css-prefix}-angle:before, .fa-duotone.#{$fa-css-prefix}-angle:before { content: fa-content(\e08c\fe01); }
.fad.#{$fa-css-prefix}-angle:after, .fa-duotone.#{$fa-css-prefix}-angle:after { content: fa-content(\e08c\fe02); }
.fad.#{$fa-css-prefix}-angle-90:before, .fa-duotone.#{$fa-css-prefix}-angle-90:before { content: fa-content(\e08d\fe01); }
.fad.#{$fa-css-prefix}-angle-90:after, .fa-duotone.#{$fa-css-prefix}-angle-90:after { content: fa-content(\e08d\fe02); }
.fad.#{$fa-css-prefix}-angle-down:before, .fa-duotone.#{$fa-css-prefix}-angle-down:before { content: fa-content(\2304\fe01); }
.fad.#{$fa-css-prefix}-angle-down:after, .fa-duotone.#{$fa-css-prefix}-angle-down:after { content: fa-content(\2304\fe02); }
.fad.#{$fa-css-prefix}-angle-left:before, .fa-duotone.#{$fa-css-prefix}-angle-left:before { content: fa-content(\2039\fe01); }
.fad.#{$fa-css-prefix}-angle-left:after, .fa-duotone.#{$fa-css-prefix}-angle-left:after { content: fa-content(\2039\fe02); }
.fad.#{$fa-css-prefix}-angle-right:before, .fa-duotone.#{$fa-css-prefix}-angle-right:before { content: fa-content(\203a\fe01); }
.fad.#{$fa-css-prefix}-angle-right:after, .fa-duotone.#{$fa-css-prefix}-angle-right:after { content: fa-content(\203a\fe02); }
.fad.#{$fa-css-prefix}-angle-up:before, .fa-duotone.#{$fa-css-prefix}-angle-up:before { content: fa-content(\2303\fe01); }
.fad.#{$fa-css-prefix}-angle-up:after, .fa-duotone.#{$fa-css-prefix}-angle-up:after { content: fa-content(\2303\fe02); }
.fad.#{$fa-css-prefix}-angles-down:before, .fa-duotone.#{$fa-css-prefix}-angles-down:before { content: fa-content(\f103\fe01); }
.fad.#{$fa-css-prefix}-angles-down:after, .fa-duotone.#{$fa-css-prefix}-angles-down:after { content: fa-content(\f103\fe02); }
.fad.#{$fa-css-prefix}-angle-double-down:before, .fa-duotone.#{$fa-css-prefix}-angle-double-down:before { content: fa-content(\f103\fe01); }
.fad.#{$fa-css-prefix}-angle-double-down:after, .fa-duotone.#{$fa-css-prefix}-angle-double-down:after { content: fa-content(\f103\fe02); }
.fad.#{$fa-css-prefix}-angles-left:before, .fa-duotone.#{$fa-css-prefix}-angles-left:before { content: fa-content(\ab\fe01); }
.fad.#{$fa-css-prefix}-angles-left:after, .fa-duotone.#{$fa-css-prefix}-angles-left:after { content: fa-content(\ab\fe02); }
.fad.#{$fa-css-prefix}-angle-double-left:before, .fa-duotone.#{$fa-css-prefix}-angle-double-left:before { content: fa-content(\ab\fe01); }
.fad.#{$fa-css-prefix}-angle-double-left:after, .fa-duotone.#{$fa-css-prefix}-angle-double-left:after { content: fa-content(\ab\fe02); }
.fad.#{$fa-css-prefix}-angles-right:before, .fa-duotone.#{$fa-css-prefix}-angles-right:before { content: fa-content(\bb\fe01); }
.fad.#{$fa-css-prefix}-angles-right:after, .fa-duotone.#{$fa-css-prefix}-angles-right:after { content: fa-content(\bb\fe02); }
.fad.#{$fa-css-prefix}-angle-double-right:before, .fa-duotone.#{$fa-css-prefix}-angle-double-right:before { content: fa-content(\bb\fe01); }
.fad.#{$fa-css-prefix}-angle-double-right:after, .fa-duotone.#{$fa-css-prefix}-angle-double-right:after { content: fa-content(\bb\fe02); }
.fad.#{$fa-css-prefix}-angles-up:before, .fa-duotone.#{$fa-css-prefix}-angles-up:before { content: fa-content(\f102\fe01); }
.fad.#{$fa-css-prefix}-angles-up:after, .fa-duotone.#{$fa-css-prefix}-angles-up:after { content: fa-content(\f102\fe02); }
.fad.#{$fa-css-prefix}-angle-double-up:before, .fa-duotone.#{$fa-css-prefix}-angle-double-up:before { content: fa-content(\f102\fe01); }
.fad.#{$fa-css-prefix}-angle-double-up:after, .fa-duotone.#{$fa-css-prefix}-angle-double-up:after { content: fa-content(\f102\fe02); }
.fad.#{$fa-css-prefix}-ankh:before, .fa-duotone.#{$fa-css-prefix}-ankh:before { content: fa-content(\2625\fe01); }
.fad.#{$fa-css-prefix}-ankh:after, .fa-duotone.#{$fa-css-prefix}-ankh:after { content: fa-content(\2625\fe02); }
.fad.#{$fa-css-prefix}-aperture:before, .fa-duotone.#{$fa-css-prefix}-aperture:before { content: fa-content(\e2df\fe01); }
.fad.#{$fa-css-prefix}-aperture:after, .fa-duotone.#{$fa-css-prefix}-aperture:after { content: fa-content(\e2df\fe02); }
.fad.#{$fa-css-prefix}-apostrophe:before, .fa-duotone.#{$fa-css-prefix}-apostrophe:before { content: fa-content(\27\fe01); }
.fad.#{$fa-css-prefix}-apostrophe:after, .fa-duotone.#{$fa-css-prefix}-apostrophe:after { content: fa-content(\27\fe02); }
.fad.#{$fa-css-prefix}-apple-core:before, .fa-duotone.#{$fa-css-prefix}-apple-core:before { content: fa-content(\e08f\fe01); }
.fad.#{$fa-css-prefix}-apple-core:after, .fa-duotone.#{$fa-css-prefix}-apple-core:after { content: fa-content(\e08f\fe02); }
.fad.#{$fa-css-prefix}-apple-whole:before, .fa-duotone.#{$fa-css-prefix}-apple-whole:before { content: fa-content(\f5d1); }
.fad.#{$fa-css-prefix}-apple-whole:after, .fa-duotone.#{$fa-css-prefix}-apple-whole:after { content: fa-content(\10f5d1); }
.fad.#{$fa-css-prefix}-apple-alt:before, .fa-duotone.#{$fa-css-prefix}-apple-alt:before { content: fa-content(\f5d1); }
.fad.#{$fa-css-prefix}-apple-alt:after, .fa-duotone.#{$fa-css-prefix}-apple-alt:after { content: fa-content(\10f5d1); }
.fad.#{$fa-css-prefix}-archway:before, .fa-duotone.#{$fa-css-prefix}-archway:before { content: fa-content(\f557\fe01); }
.fad.#{$fa-css-prefix}-archway:after, .fa-duotone.#{$fa-css-prefix}-archway:after { content: fa-content(\f557\fe02); }
.fad.#{$fa-css-prefix}-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-down:before { content: fa-content(\2193\fe01); }
.fad.#{$fa-css-prefix}-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-down:after { content: fa-content(\2193\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-1-9:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-1-9:before { content: fa-content(\f162\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-1-9:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-1-9:after { content: fa-content(\f162\fe02); }
.fad.#{$fa-css-prefix}-sort-numeric-asc:before, .fa-duotone.#{$fa-css-prefix}-sort-numeric-asc:before { content: fa-content(\f162\fe01); }
.fad.#{$fa-css-prefix}-sort-numeric-asc:after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-asc:after { content: fa-content(\f162\fe02); }
.fad.#{$fa-css-prefix}-sort-numeric-down:before, .fa-duotone.#{$fa-css-prefix}-sort-numeric-down:before { content: fa-content(\f162\fe01); }
.fad.#{$fa-css-prefix}-sort-numeric-down:after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-down:after { content: fa-content(\f162\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-9-1:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-9-1:before { content: fa-content(\f886\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-9-1:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-9-1:after { content: fa-content(\f886\fe02); }
.fad.#{$fa-css-prefix}-sort-numeric-desc:before, .fa-duotone.#{$fa-css-prefix}-sort-numeric-desc:before { content: fa-content(\f886\fe01); }
.fad.#{$fa-css-prefix}-sort-numeric-desc:after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-desc:after { content: fa-content(\f886\fe02); }
.fad.#{$fa-css-prefix}-sort-numeric-down-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-numeric-down-alt:before { content: fa-content(\f886\fe01); }
.fad.#{$fa-css-prefix}-sort-numeric-down-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-down-alt:after { content: fa-content(\f886\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-a-z:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-a-z:before { content: fa-content(\f15d\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-a-z:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-a-z:after { content: fa-content(\f15d\fe02); }
.fad.#{$fa-css-prefix}-sort-alpha-asc:before, .fa-duotone.#{$fa-css-prefix}-sort-alpha-asc:before { content: fa-content(\f15d\fe01); }
.fad.#{$fa-css-prefix}-sort-alpha-asc:after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-asc:after { content: fa-content(\f15d\fe02); }
.fad.#{$fa-css-prefix}-sort-alpha-down:before, .fa-duotone.#{$fa-css-prefix}-sort-alpha-down:before { content: fa-content(\f15d\fe01); }
.fad.#{$fa-css-prefix}-sort-alpha-down:after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-down:after { content: fa-content(\f15d\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-arrow-up:before { content: fa-content(\f883\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-arrow-up:after { content: fa-content(\f883\fe02); }
.fad.#{$fa-css-prefix}-sort-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-alt:before { content: fa-content(\f883\fe01); }
.fad.#{$fa-css-prefix}-sort-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-alt:after { content: fa-content(\f883\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-big-small:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-big-small:before { content: fa-content(\f88c\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-big-small:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-big-small:after { content: fa-content(\f88c\fe02); }
.fad.#{$fa-css-prefix}-sort-size-down:before, .fa-duotone.#{$fa-css-prefix}-sort-size-down:before { content: fa-content(\f88c\fe01); }
.fad.#{$fa-css-prefix}-sort-size-down:after, .fa-duotone.#{$fa-css-prefix}-sort-size-down:after { content: fa-content(\f88c\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-from-dotted-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-from-dotted-line:before { content: fa-content(\e090\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-from-dotted-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-from-dotted-line:after { content: fa-content(\e090\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-from-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-from-line:before { content: fa-content(\21a7\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-from-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-from-line:after { content: fa-content(\21a7\fe02); }
.fad.#{$fa-css-prefix}-arrow-from-top:before, .fa-duotone.#{$fa-css-prefix}-arrow-from-top:before { content: fa-content(\21a7\fe01); }
.fad.#{$fa-css-prefix}-arrow-from-top:after, .fa-duotone.#{$fa-css-prefix}-arrow-from-top:after { content: fa-content(\21a7\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-left:before { content: fa-content(\e091\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-left:after { content: fa-content(\e091\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-left-and-arrow-up-right-to-center:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-left-and-arrow-up-right-to-center:before { content: fa-content(\e092\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-left-and-arrow-up-right-to-center:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-left-and-arrow-up-right-to-center:after { content: fa-content(\e092\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-long:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-long:before { content: fa-content(\f175\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-long:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-long:after { content: fa-content(\f175\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-down:before { content: fa-content(\f175\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-down:after { content: fa-content(\f175\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-right:before { content: fa-content(\e093\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-right:after { content: fa-content(\e093\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-short-wide:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-short-wide:before { content: fa-content(\f884\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-short-wide:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-short-wide:after { content: fa-content(\f884\fe02); }
.fad.#{$fa-css-prefix}-sort-amount-desc:before, .fa-duotone.#{$fa-css-prefix}-sort-amount-desc:before { content: fa-content(\f884\fe01); }
.fad.#{$fa-css-prefix}-sort-amount-desc:after, .fa-duotone.#{$fa-css-prefix}-sort-amount-desc:after { content: fa-content(\f884\fe02); }
.fad.#{$fa-css-prefix}-sort-amount-down-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-amount-down-alt:before { content: fa-content(\f884\fe01); }
.fad.#{$fa-css-prefix}-sort-amount-down-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-amount-down-alt:after { content: fa-content(\f884\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-small-big:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-small-big:before { content: fa-content(\f88d\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-small-big:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-small-big:after { content: fa-content(\f88d\fe02); }
.fad.#{$fa-css-prefix}-sort-size-down-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-size-down-alt:before { content: fa-content(\f88d\fe01); }
.fad.#{$fa-css-prefix}-sort-size-down-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-size-down-alt:after { content: fa-content(\f88d\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-square-triangle:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-square-triangle:before { content: fa-content(\f889\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-square-triangle:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-square-triangle:after { content: fa-content(\f889\fe02); }
.fad.#{$fa-css-prefix}-sort-shapes-down-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-shapes-down-alt:before { content: fa-content(\f889\fe01); }
.fad.#{$fa-css-prefix}-sort-shapes-down-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-down-alt:after { content: fa-content(\f889\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-to-bracket:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-bracket:before { content: fa-content(\e094\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-to-bracket:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-bracket:after { content: fa-content(\e094\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-to-dotted-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-dotted-line:before { content: fa-content(\e095\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-to-dotted-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-dotted-line:after { content: fa-content(\e095\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-to-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-line:before { content: fa-content(\f33d\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-to-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-line:after { content: fa-content(\f33d\fe02); }
.fad.#{$fa-css-prefix}-arrow-to-bottom:before, .fa-duotone.#{$fa-css-prefix}-arrow-to-bottom:before { content: fa-content(\f33d\fe01); }
.fad.#{$fa-css-prefix}-arrow-to-bottom:after, .fa-duotone.#{$fa-css-prefix}-arrow-to-bottom:after { content: fa-content(\f33d\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-to-square:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-square:before { content: fa-content(\e096\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-to-square:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-square:after { content: fa-content(\e096\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-triangle-square:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-triangle-square:before { content: fa-content(\f888\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-triangle-square:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-triangle-square:after { content: fa-content(\f888\fe02); }
.fad.#{$fa-css-prefix}-sort-shapes-down:before, .fa-duotone.#{$fa-css-prefix}-sort-shapes-down:before { content: fa-content(\f888\fe01); }
.fad.#{$fa-css-prefix}-sort-shapes-down:after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-down:after { content: fa-content(\f888\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-wide-short:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-wide-short:before { content: fa-content(\f160\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-wide-short:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-wide-short:after { content: fa-content(\f160\fe02); }
.fad.#{$fa-css-prefix}-sort-amount-asc:before, .fa-duotone.#{$fa-css-prefix}-sort-amount-asc:before { content: fa-content(\f160\fe01); }
.fad.#{$fa-css-prefix}-sort-amount-asc:after, .fa-duotone.#{$fa-css-prefix}-sort-amount-asc:after { content: fa-content(\f160\fe02); }
.fad.#{$fa-css-prefix}-sort-amount-down:before, .fa-duotone.#{$fa-css-prefix}-sort-amount-down:before { content: fa-content(\f160\fe01); }
.fad.#{$fa-css-prefix}-sort-amount-down:after, .fa-duotone.#{$fa-css-prefix}-sort-amount-down:after { content: fa-content(\f160\fe02); }
.fad.#{$fa-css-prefix}-arrow-down-z-a:before, .fa-duotone.#{$fa-css-prefix}-arrow-down-z-a:before { content: fa-content(\f881\fe01); }
.fad.#{$fa-css-prefix}-arrow-down-z-a:after, .fa-duotone.#{$fa-css-prefix}-arrow-down-z-a:after { content: fa-content(\f881\fe02); }
.fad.#{$fa-css-prefix}-sort-alpha-desc:before, .fa-duotone.#{$fa-css-prefix}-sort-alpha-desc:before { content: fa-content(\f881\fe01); }
.fad.#{$fa-css-prefix}-sort-alpha-desc:after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-desc:after { content: fa-content(\f881\fe02); }
.fad.#{$fa-css-prefix}-sort-alpha-down-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-alpha-down-alt:before { content: fa-content(\f881\fe01); }
.fad.#{$fa-css-prefix}-sort-alpha-down-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-down-alt:after { content: fa-content(\f881\fe02); }
.fad.#{$fa-css-prefix}-arrow-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-left:before { content: fa-content(\2190\fe01); }
.fad.#{$fa-css-prefix}-arrow-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-left:after { content: fa-content(\2190\fe02); }
.fad.#{$fa-css-prefix}-arrow-left-from-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-left-from-line:before { content: fa-content(\21a4\fe01); }
.fad.#{$fa-css-prefix}-arrow-left-from-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-left-from-line:after { content: fa-content(\21a4\fe02); }
.fad.#{$fa-css-prefix}-arrow-from-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-from-right:before { content: fa-content(\21a4\fe01); }
.fad.#{$fa-css-prefix}-arrow-from-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-from-right:after { content: fa-content(\21a4\fe02); }
.fad.#{$fa-css-prefix}-arrow-left-long:before, .fa-duotone.#{$fa-css-prefix}-arrow-left-long:before { content: fa-content(\f177\fe01); }
.fad.#{$fa-css-prefix}-arrow-left-long:after, .fa-duotone.#{$fa-css-prefix}-arrow-left-long:after { content: fa-content(\f177\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-left:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-left:before { content: fa-content(\f177\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-left:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-left:after { content: fa-content(\f177\fe02); }
.fad.#{$fa-css-prefix}-arrow-left-to-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-left-to-line:before { content: fa-content(\21e4\fe01); }
.fad.#{$fa-css-prefix}-arrow-left-to-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-left-to-line:after { content: fa-content(\21e4\fe02); }
.fad.#{$fa-css-prefix}-arrow-to-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-to-left:before { content: fa-content(\21e4\fe01); }
.fad.#{$fa-css-prefix}-arrow-to-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-to-left:after { content: fa-content(\21e4\fe02); }
.fad.#{$fa-css-prefix}-arrow-pointer:before, .fa-duotone.#{$fa-css-prefix}-arrow-pointer:before { content: fa-content(\f245\fe01); }
.fad.#{$fa-css-prefix}-arrow-pointer:after, .fa-duotone.#{$fa-css-prefix}-arrow-pointer:after { content: fa-content(\f245\fe02); }
.fad.#{$fa-css-prefix}-mouse-pointer:before, .fa-duotone.#{$fa-css-prefix}-mouse-pointer:before { content: fa-content(\f245\fe01); }
.fad.#{$fa-css-prefix}-mouse-pointer:after, .fa-duotone.#{$fa-css-prefix}-mouse-pointer:after { content: fa-content(\f245\fe02); }
.fad.#{$fa-css-prefix}-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-right:before { content: fa-content(\2192\fe01); }
.fad.#{$fa-css-prefix}-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-right:after { content: fa-content(\2192\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-arrow-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-arrow-left:before { content: fa-content(\21c4\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-arrow-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-arrow-left:after { content: fa-content(\21c4\fe02); }
.fad.#{$fa-css-prefix}-exchange:before, .fa-duotone.#{$fa-css-prefix}-exchange:before { content: fa-content(\21c4\fe01); }
.fad.#{$fa-css-prefix}-exchange:after, .fa-duotone.#{$fa-css-prefix}-exchange:after { content: fa-content(\21c4\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-from-bracket:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-bracket:before { content: fa-content(\f08b\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-from-bracket:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-bracket:after { content: fa-content(\f08b\fe02); }
.fad.#{$fa-css-prefix}-sign-out:before, .fa-duotone.#{$fa-css-prefix}-sign-out:before { content: fa-content(\f08b\fe01); }
.fad.#{$fa-css-prefix}-sign-out:after, .fa-duotone.#{$fa-css-prefix}-sign-out:after { content: fa-content(\f08b\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-from-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-line:before { content: fa-content(\21a6\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-from-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-line:after { content: fa-content(\21a6\fe02); }
.fad.#{$fa-css-prefix}-arrow-from-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-from-left:before { content: fa-content(\21a6\fe01); }
.fad.#{$fa-css-prefix}-arrow-from-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-from-left:after { content: fa-content(\21a6\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-long:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-long:before { content: fa-content(\f178\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-long:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-long:after { content: fa-content(\f178\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-right:before { content: fa-content(\f178\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-right:after { content: fa-content(\f178\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-to-bracket:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-bracket:before { content: fa-content(\f090\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-to-bracket:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-bracket:after { content: fa-content(\f090\fe02); }
.fad.#{$fa-css-prefix}-sign-in:before, .fa-duotone.#{$fa-css-prefix}-sign-in:before { content: fa-content(\f090\fe01); }
.fad.#{$fa-css-prefix}-sign-in:after, .fa-duotone.#{$fa-css-prefix}-sign-in:after { content: fa-content(\f090\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-to-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-line:before { content: fa-content(\21e5\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-to-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-line:after { content: fa-content(\21e5\fe02); }
.fad.#{$fa-css-prefix}-arrow-to-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-to-right:before { content: fa-content(\21e5\fe01); }
.fad.#{$fa-css-prefix}-arrow-to-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-to-right:after { content: fa-content(\21e5\fe02); }
.fad.#{$fa-css-prefix}-arrow-rotate-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-left:before { content: fa-content(\21ba\fe01); }
.fad.#{$fa-css-prefix}-arrow-rotate-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-left:after { content: fa-content(\21ba\fe02); }
.fad.#{$fa-css-prefix}-arrow-left-rotate:before, .fa-duotone.#{$fa-css-prefix}-arrow-left-rotate:before { content: fa-content(\21ba\fe01); }
.fad.#{$fa-css-prefix}-arrow-left-rotate:after, .fa-duotone.#{$fa-css-prefix}-arrow-left-rotate:after { content: fa-content(\21ba\fe02); }
.fad.#{$fa-css-prefix}-arrow-rotate-back:before, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-back:before { content: fa-content(\21ba\fe01); }
.fad.#{$fa-css-prefix}-arrow-rotate-back:after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-back:after { content: fa-content(\21ba\fe02); }
.fad.#{$fa-css-prefix}-arrow-rotate-backward:before, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-backward:before { content: fa-content(\21ba\fe01); }
.fad.#{$fa-css-prefix}-arrow-rotate-backward:after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-backward:after { content: fa-content(\21ba\fe02); }
.fad.#{$fa-css-prefix}-undo:before, .fa-duotone.#{$fa-css-prefix}-undo:before { content: fa-content(\21ba\fe01); }
.fad.#{$fa-css-prefix}-undo:after, .fa-duotone.#{$fa-css-prefix}-undo:after { content: fa-content(\21ba\fe02); }
.fad.#{$fa-css-prefix}-arrow-rotate-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-right:before { content: fa-content(\21bb\fe01); }
.fad.#{$fa-css-prefix}-arrow-rotate-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-right:after { content: fa-content(\21bb\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-rotate:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-rotate:before { content: fa-content(\21bb\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-rotate:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-rotate:after { content: fa-content(\21bb\fe02); }
.fad.#{$fa-css-prefix}-arrow-rotate-forward:before, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-forward:before { content: fa-content(\21bb\fe01); }
.fad.#{$fa-css-prefix}-arrow-rotate-forward:after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-forward:after { content: fa-content(\21bb\fe02); }
.fad.#{$fa-css-prefix}-redo:before, .fa-duotone.#{$fa-css-prefix}-redo:before { content: fa-content(\21bb\fe01); }
.fad.#{$fa-css-prefix}-redo:after, .fa-duotone.#{$fa-css-prefix}-redo:after { content: fa-content(\21bb\fe02); }
.fad.#{$fa-css-prefix}-arrow-trend-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-trend-down:before { content: fa-content(\e097\fe01); }
.fad.#{$fa-css-prefix}-arrow-trend-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-trend-down:after { content: fa-content(\e097\fe02); }
.fad.#{$fa-css-prefix}-arrow-trend-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-trend-up:before { content: fa-content(\e098\fe01); }
.fad.#{$fa-css-prefix}-arrow-trend-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-trend-up:after { content: fa-content(\e098\fe02); }
.fad.#{$fa-css-prefix}-arrow-turn-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-turn-down:before { content: fa-content(\f149\fe01); }
.fad.#{$fa-css-prefix}-arrow-turn-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-down:after { content: fa-content(\f149\fe02); }
.fad.#{$fa-css-prefix}-level-down:before, .fa-duotone.#{$fa-css-prefix}-level-down:before { content: fa-content(\f149\fe01); }
.fad.#{$fa-css-prefix}-level-down:after, .fa-duotone.#{$fa-css-prefix}-level-down:after { content: fa-content(\f149\fe02); }
.fad.#{$fa-css-prefix}-arrow-turn-down-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-turn-down-left:before { content: fa-content(\e2e1\fe01); }
.fad.#{$fa-css-prefix}-arrow-turn-down-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-down-left:after { content: fa-content(\e2e1\fe02); }
.fad.#{$fa-css-prefix}-arrow-turn-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-turn-up:before { content: fa-content(\f148\fe01); }
.fad.#{$fa-css-prefix}-arrow-turn-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-up:after { content: fa-content(\f148\fe02); }
.fad.#{$fa-css-prefix}-level-up:before, .fa-duotone.#{$fa-css-prefix}-level-up:before { content: fa-content(\f148\fe01); }
.fad.#{$fa-css-prefix}-level-up:after, .fa-duotone.#{$fa-css-prefix}-level-up:after { content: fa-content(\f148\fe02); }
.fad.#{$fa-css-prefix}-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-up:before { content: fa-content(\2191\fe01); }
.fad.#{$fa-css-prefix}-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-up:after { content: fa-content(\2191\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-1-9:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-1-9:before { content: fa-content(\f163\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-1-9:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-1-9:after { content: fa-content(\f163\fe02); }
.fad.#{$fa-css-prefix}-sort-numeric-up:before, .fa-duotone.#{$fa-css-prefix}-sort-numeric-up:before { content: fa-content(\f163\fe01); }
.fad.#{$fa-css-prefix}-sort-numeric-up:after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-up:after { content: fa-content(\f163\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-9-1:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-9-1:before { content: fa-content(\f887\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-9-1:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-9-1:after { content: fa-content(\f887\fe02); }
.fad.#{$fa-css-prefix}-sort-numeric-up-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-numeric-up-alt:before { content: fa-content(\f887\fe01); }
.fad.#{$fa-css-prefix}-sort-numeric-up-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-up-alt:after { content: fa-content(\f887\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-a-z:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-a-z:before { content: fa-content(\f15e\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-a-z:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-a-z:after { content: fa-content(\f15e\fe02); }
.fad.#{$fa-css-prefix}-sort-alpha-up:before, .fa-duotone.#{$fa-css-prefix}-sort-alpha-up:before { content: fa-content(\f15e\fe01); }
.fad.#{$fa-css-prefix}-sort-alpha-up:after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-up:after { content: fa-content(\f15e\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-arrow-down:before { content: fa-content(\e099\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-arrow-down:after { content: fa-content(\e099\fe02); }
.fad.#{$fa-css-prefix}-sort-up-down:before, .fa-duotone.#{$fa-css-prefix}-sort-up-down:before { content: fa-content(\e099\fe01); }
.fad.#{$fa-css-prefix}-sort-up-down:after, .fa-duotone.#{$fa-css-prefix}-sort-up-down:after { content: fa-content(\e099\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-big-small:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-big-small:before { content: fa-content(\f88e\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-big-small:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-big-small:after { content: fa-content(\f88e\fe02); }
.fad.#{$fa-css-prefix}-sort-size-up:before, .fa-duotone.#{$fa-css-prefix}-sort-size-up:before { content: fa-content(\f88e\fe01); }
.fad.#{$fa-css-prefix}-sort-size-up:after, .fa-duotone.#{$fa-css-prefix}-sort-size-up:after { content: fa-content(\f88e\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-from-bracket:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-bracket:before { content: fa-content(\e09a\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-from-bracket:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-bracket:after { content: fa-content(\e09a\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-from-dotted-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-dotted-line:before { content: fa-content(\e09b\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-from-dotted-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-dotted-line:after { content: fa-content(\e09b\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-from-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-line:before { content: fa-content(\21a5\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-from-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-line:after { content: fa-content(\21a5\fe02); }
.fad.#{$fa-css-prefix}-arrow-from-bottom:before, .fa-duotone.#{$fa-css-prefix}-arrow-from-bottom:before { content: fa-content(\21a5\fe01); }
.fad.#{$fa-css-prefix}-arrow-from-bottom:after, .fa-duotone.#{$fa-css-prefix}-arrow-from-bottom:after { content: fa-content(\21a5\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-from-square:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-square:before { content: fa-content(\e09c\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-from-square:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-square:after { content: fa-content(\e09c\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-left:before { content: fa-content(\e09d\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-left:after { content: fa-content(\e09d\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-left-from-circle:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-left-from-circle:before { content: fa-content(\e09e\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-left-from-circle:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-left-from-circle:after { content: fa-content(\e09e\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-long:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-long:before { content: fa-content(\f176\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-long:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-long:after { content: fa-content(\f176\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-up:before { content: fa-content(\f176\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-up:after { content: fa-content(\f176\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-right:before { content: fa-content(\e09f\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-right:after { content: fa-content(\e09f\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-right-and-arrow-down-left-from-center:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-right-and-arrow-down-left-from-center:before { content: fa-content(\e0a0\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-right-and-arrow-down-left-from-center:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-right-and-arrow-down-left-from-center:after { content: fa-content(\e0a0\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-right-from-square:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-right-from-square:before { content: fa-content(\f08e\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-right-from-square:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-right-from-square:after { content: fa-content(\f08e\fe02); }
.fad.#{$fa-css-prefix}-external-link:before, .fa-duotone.#{$fa-css-prefix}-external-link:before { content: fa-content(\f08e\fe01); }
.fad.#{$fa-css-prefix}-external-link:after, .fa-duotone.#{$fa-css-prefix}-external-link:after { content: fa-content(\f08e\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-short-wide:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-short-wide:before { content: fa-content(\f885\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-short-wide:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-short-wide:after { content: fa-content(\f885\fe02); }
.fad.#{$fa-css-prefix}-sort-amount-up-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-amount-up-alt:before { content: fa-content(\f885\fe01); }
.fad.#{$fa-css-prefix}-sort-amount-up-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-amount-up-alt:after { content: fa-content(\f885\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-small-big:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-small-big:before { content: fa-content(\f88f\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-small-big:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-small-big:after { content: fa-content(\f88f\fe02); }
.fad.#{$fa-css-prefix}-sort-size-up-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-size-up-alt:before { content: fa-content(\f88f\fe01); }
.fad.#{$fa-css-prefix}-sort-size-up-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-size-up-alt:after { content: fa-content(\f88f\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-square-triangle:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-square-triangle:before { content: fa-content(\f88b\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-square-triangle:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-square-triangle:after { content: fa-content(\f88b\fe02); }
.fad.#{$fa-css-prefix}-sort-shapes-up-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-shapes-up-alt:before { content: fa-content(\f88b\fe01); }
.fad.#{$fa-css-prefix}-sort-shapes-up-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-up-alt:after { content: fa-content(\f88b\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-to-dotted-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-to-dotted-line:before { content: fa-content(\e0a1\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-to-dotted-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-to-dotted-line:after { content: fa-content(\e0a1\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-to-line:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-to-line:before { content: fa-content(\f341\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-to-line:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-to-line:after { content: fa-content(\f341\fe02); }
.fad.#{$fa-css-prefix}-arrow-to-top:before, .fa-duotone.#{$fa-css-prefix}-arrow-to-top:before { content: fa-content(\f341\fe01); }
.fad.#{$fa-css-prefix}-arrow-to-top:after, .fa-duotone.#{$fa-css-prefix}-arrow-to-top:after { content: fa-content(\f341\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-triangle-square:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-triangle-square:before { content: fa-content(\f88a\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-triangle-square:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-triangle-square:after { content: fa-content(\f88a\fe02); }
.fad.#{$fa-css-prefix}-sort-shapes-up:before, .fa-duotone.#{$fa-css-prefix}-sort-shapes-up:before { content: fa-content(\f88a\fe01); }
.fad.#{$fa-css-prefix}-sort-shapes-up:after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-up:after { content: fa-content(\f88a\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-wide-short:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-wide-short:before { content: fa-content(\f161\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-wide-short:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-wide-short:after { content: fa-content(\f161\fe02); }
.fad.#{$fa-css-prefix}-sort-amount-up:before, .fa-duotone.#{$fa-css-prefix}-sort-amount-up:before { content: fa-content(\f161\fe01); }
.fad.#{$fa-css-prefix}-sort-amount-up:after, .fa-duotone.#{$fa-css-prefix}-sort-amount-up:after { content: fa-content(\f161\fe02); }
.fad.#{$fa-css-prefix}-arrow-up-z-a:before, .fa-duotone.#{$fa-css-prefix}-arrow-up-z-a:before { content: fa-content(\f882\fe01); }
.fad.#{$fa-css-prefix}-arrow-up-z-a:after, .fa-duotone.#{$fa-css-prefix}-arrow-up-z-a:after { content: fa-content(\f882\fe02); }
.fad.#{$fa-css-prefix}-sort-alpha-up-alt:before, .fa-duotone.#{$fa-css-prefix}-sort-alpha-up-alt:before { content: fa-content(\f882\fe01); }
.fad.#{$fa-css-prefix}-sort-alpha-up-alt:after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-up-alt:after { content: fa-content(\f882\fe02); }
.fad.#{$fa-css-prefix}-arrows-cross:before, .fa-duotone.#{$fa-css-prefix}-arrows-cross:before { content: fa-content(\e0a2\fe01); }
.fad.#{$fa-css-prefix}-arrows-cross:after, .fa-duotone.#{$fa-css-prefix}-arrows-cross:after { content: fa-content(\e0a2\fe02); }
.fad.#{$fa-css-prefix}-arrows-from-dotted-line:before, .fa-duotone.#{$fa-css-prefix}-arrows-from-dotted-line:before { content: fa-content(\e0a3\fe01); }
.fad.#{$fa-css-prefix}-arrows-from-dotted-line:after, .fa-duotone.#{$fa-css-prefix}-arrows-from-dotted-line:after { content: fa-content(\e0a3\fe02); }
.fad.#{$fa-css-prefix}-arrows-from-line:before, .fa-duotone.#{$fa-css-prefix}-arrows-from-line:before { content: fa-content(\e0a4\fe01); }
.fad.#{$fa-css-prefix}-arrows-from-line:after, .fa-duotone.#{$fa-css-prefix}-arrows-from-line:after { content: fa-content(\e0a4\fe02); }
.fad.#{$fa-css-prefix}-arrows-left-right:before, .fa-duotone.#{$fa-css-prefix}-arrows-left-right:before { content: fa-content(\f07e\fe01); }
.fad.#{$fa-css-prefix}-arrows-left-right:after, .fa-duotone.#{$fa-css-prefix}-arrows-left-right:after { content: fa-content(\f07e\fe02); }
.fad.#{$fa-css-prefix}-arrows-h:before, .fa-duotone.#{$fa-css-prefix}-arrows-h:before { content: fa-content(\f07e\fe01); }
.fad.#{$fa-css-prefix}-arrows-h:after, .fa-duotone.#{$fa-css-prefix}-arrows-h:after { content: fa-content(\f07e\fe02); }
.fad.#{$fa-css-prefix}-arrows-maximize:before, .fa-duotone.#{$fa-css-prefix}-arrows-maximize:before { content: fa-content(\f31d\fe01); }
.fad.#{$fa-css-prefix}-arrows-maximize:after, .fa-duotone.#{$fa-css-prefix}-arrows-maximize:after { content: fa-content(\f31d\fe02); }
.fad.#{$fa-css-prefix}-expand-arrows:before, .fa-duotone.#{$fa-css-prefix}-expand-arrows:before { content: fa-content(\f31d\fe01); }
.fad.#{$fa-css-prefix}-expand-arrows:after, .fa-duotone.#{$fa-css-prefix}-expand-arrows:after { content: fa-content(\f31d\fe02); }
.fad.#{$fa-css-prefix}-arrows-minimize:before, .fa-duotone.#{$fa-css-prefix}-arrows-minimize:before { content: fa-content(\e0a5\fe01); }
.fad.#{$fa-css-prefix}-arrows-minimize:after, .fa-duotone.#{$fa-css-prefix}-arrows-minimize:after { content: fa-content(\e0a5\fe02); }
.fad.#{$fa-css-prefix}-compress-arrows:before, .fa-duotone.#{$fa-css-prefix}-compress-arrows:before { content: fa-content(\e0a5\fe01); }
.fad.#{$fa-css-prefix}-compress-arrows:after, .fa-duotone.#{$fa-css-prefix}-compress-arrows:after { content: fa-content(\e0a5\fe02); }
.fad.#{$fa-css-prefix}-arrows-repeat:before, .fa-duotone.#{$fa-css-prefix}-arrows-repeat:before { content: fa-content(\f364\fe01); }
.fad.#{$fa-css-prefix}-arrows-repeat:after, .fa-duotone.#{$fa-css-prefix}-arrows-repeat:after { content: fa-content(\f364\fe02); }
.fad.#{$fa-css-prefix}-repeat-alt:before, .fa-duotone.#{$fa-css-prefix}-repeat-alt:before { content: fa-content(\f364\fe01); }
.fad.#{$fa-css-prefix}-repeat-alt:after, .fa-duotone.#{$fa-css-prefix}-repeat-alt:after { content: fa-content(\f364\fe02); }
.fad.#{$fa-css-prefix}-arrows-repeat-1:before, .fa-duotone.#{$fa-css-prefix}-arrows-repeat-1:before { content: fa-content(\f366\fe01); }
.fad.#{$fa-css-prefix}-arrows-repeat-1:after, .fa-duotone.#{$fa-css-prefix}-arrows-repeat-1:after { content: fa-content(\f366\fe02); }
.fad.#{$fa-css-prefix}-repeat-1-alt:before, .fa-duotone.#{$fa-css-prefix}-repeat-1-alt:before { content: fa-content(\f366\fe01); }
.fad.#{$fa-css-prefix}-repeat-1-alt:after, .fa-duotone.#{$fa-css-prefix}-repeat-1-alt:after { content: fa-content(\f366\fe02); }
.fad.#{$fa-css-prefix}-arrows-retweet:before, .fa-duotone.#{$fa-css-prefix}-arrows-retweet:before { content: fa-content(\f361\fe01); }
.fad.#{$fa-css-prefix}-arrows-retweet:after, .fa-duotone.#{$fa-css-prefix}-arrows-retweet:after { content: fa-content(\f361\fe02); }
.fad.#{$fa-css-prefix}-retweet-alt:before, .fa-duotone.#{$fa-css-prefix}-retweet-alt:before { content: fa-content(\f361\fe01); }
.fad.#{$fa-css-prefix}-retweet-alt:after, .fa-duotone.#{$fa-css-prefix}-retweet-alt:after { content: fa-content(\f361\fe02); }
.fad.#{$fa-css-prefix}-arrows-rotate:before, .fa-duotone.#{$fa-css-prefix}-arrows-rotate:before { content: fa-content(\f021); }
.fad.#{$fa-css-prefix}-arrows-rotate:after, .fa-duotone.#{$fa-css-prefix}-arrows-rotate:after { content: fa-content(\10f021); }
.fad.#{$fa-css-prefix}-refresh:before, .fa-duotone.#{$fa-css-prefix}-refresh:before { content: fa-content(\f021); }
.fad.#{$fa-css-prefix}-refresh:after, .fa-duotone.#{$fa-css-prefix}-refresh:after { content: fa-content(\10f021); }
.fad.#{$fa-css-prefix}-sync:before, .fa-duotone.#{$fa-css-prefix}-sync:before { content: fa-content(\f021); }
.fad.#{$fa-css-prefix}-sync:after, .fa-duotone.#{$fa-css-prefix}-sync:after { content: fa-content(\10f021); }
.fad.#{$fa-css-prefix}-arrows-to-dotted-line:before, .fa-duotone.#{$fa-css-prefix}-arrows-to-dotted-line:before { content: fa-content(\e0a6\fe01); }
.fad.#{$fa-css-prefix}-arrows-to-dotted-line:after, .fa-duotone.#{$fa-css-prefix}-arrows-to-dotted-line:after { content: fa-content(\e0a6\fe02); }
.fad.#{$fa-css-prefix}-arrows-to-line:before, .fa-duotone.#{$fa-css-prefix}-arrows-to-line:before { content: fa-content(\e0a7\fe01); }
.fad.#{$fa-css-prefix}-arrows-to-line:after, .fa-duotone.#{$fa-css-prefix}-arrows-to-line:after { content: fa-content(\e0a7\fe02); }
.fad.#{$fa-css-prefix}-arrows-up-down:before, .fa-duotone.#{$fa-css-prefix}-arrows-up-down:before { content: fa-content(\f07d\fe01); }
.fad.#{$fa-css-prefix}-arrows-up-down:after, .fa-duotone.#{$fa-css-prefix}-arrows-up-down:after { content: fa-content(\f07d\fe02); }
.fad.#{$fa-css-prefix}-arrows-v:before, .fa-duotone.#{$fa-css-prefix}-arrows-v:before { content: fa-content(\f07d\fe01); }
.fad.#{$fa-css-prefix}-arrows-v:after, .fa-duotone.#{$fa-css-prefix}-arrows-v:after { content: fa-content(\f07d\fe02); }
.fad.#{$fa-css-prefix}-arrows-up-down-left-right:before, .fa-duotone.#{$fa-css-prefix}-arrows-up-down-left-right:before { content: fa-content(\f047\fe01); }
.fad.#{$fa-css-prefix}-arrows-up-down-left-right:after, .fa-duotone.#{$fa-css-prefix}-arrows-up-down-left-right:after { content: fa-content(\f047\fe02); }
.fad.#{$fa-css-prefix}-arrows:before, .fa-duotone.#{$fa-css-prefix}-arrows:before { content: fa-content(\f047\fe01); }
.fad.#{$fa-css-prefix}-arrows:after, .fa-duotone.#{$fa-css-prefix}-arrows:after { content: fa-content(\f047\fe02); }
.fad.#{$fa-css-prefix}-asterisk:before, .fa-duotone.#{$fa-css-prefix}-asterisk:before { content: fa-content(\2a\fe01); }
.fad.#{$fa-css-prefix}-asterisk:after, .fa-duotone.#{$fa-css-prefix}-asterisk:after { content: fa-content(\2a\fe02); }
.fad.#{$fa-css-prefix}-at:before, .fa-duotone.#{$fa-css-prefix}-at:before { content: fa-content(\40\fe01); }
.fad.#{$fa-css-prefix}-at:after, .fa-duotone.#{$fa-css-prefix}-at:after { content: fa-content(\40\fe02); }
.fad.#{$fa-css-prefix}-atom:before, .fa-duotone.#{$fa-css-prefix}-atom:before { content: fa-content(\269b\fe01); }
.fad.#{$fa-css-prefix}-atom:after, .fa-duotone.#{$fa-css-prefix}-atom:after { content: fa-content(\269b\fe02); }
.fad.#{$fa-css-prefix}-atom-simple:before, .fa-duotone.#{$fa-css-prefix}-atom-simple:before { content: fa-content(\f5d3\fe01); }
.fad.#{$fa-css-prefix}-atom-simple:after, .fa-duotone.#{$fa-css-prefix}-atom-simple:after { content: fa-content(\f5d3\fe02); }
.fad.#{$fa-css-prefix}-atom-alt:before, .fa-duotone.#{$fa-css-prefix}-atom-alt:before { content: fa-content(\f5d3\fe01); }
.fad.#{$fa-css-prefix}-atom-alt:after, .fa-duotone.#{$fa-css-prefix}-atom-alt:after { content: fa-content(\f5d3\fe02); }
.fad.#{$fa-css-prefix}-audio-description:before, .fa-duotone.#{$fa-css-prefix}-audio-description:before { content: fa-content(\f29e\fe01); }
.fad.#{$fa-css-prefix}-audio-description:after, .fa-duotone.#{$fa-css-prefix}-audio-description:after { content: fa-content(\f29e\fe02); }
.fad.#{$fa-css-prefix}-audio-description-slash:before, .fa-duotone.#{$fa-css-prefix}-audio-description-slash:before { content: fa-content(\e0a8\fe01); }
.fad.#{$fa-css-prefix}-audio-description-slash:after, .fa-duotone.#{$fa-css-prefix}-audio-description-slash:after { content: fa-content(\e0a8\fe02); }
.fad.#{$fa-css-prefix}-austral-sign:before, .fa-duotone.#{$fa-css-prefix}-austral-sign:before { content: fa-content(\e0a9\fe01); }
.fad.#{$fa-css-prefix}-austral-sign:after, .fa-duotone.#{$fa-css-prefix}-austral-sign:after { content: fa-content(\e0a9\fe02); }
.fad.#{$fa-css-prefix}-avocado:before, .fa-duotone.#{$fa-css-prefix}-avocado:before { content: fa-content(\e0aa\fe01); }
.fad.#{$fa-css-prefix}-avocado:after, .fa-duotone.#{$fa-css-prefix}-avocado:after { content: fa-content(\e0aa\fe02); }
.fad.#{$fa-css-prefix}-award:before, .fa-duotone.#{$fa-css-prefix}-award:before { content: fa-content(\f559\fe01); }
.fad.#{$fa-css-prefix}-award:after, .fa-duotone.#{$fa-css-prefix}-award:after { content: fa-content(\f559\fe02); }
.fad.#{$fa-css-prefix}-award-simple:before, .fa-duotone.#{$fa-css-prefix}-award-simple:before { content: fa-content(\e0ab\fe01); }
.fad.#{$fa-css-prefix}-award-simple:after, .fa-duotone.#{$fa-css-prefix}-award-simple:after { content: fa-content(\e0ab\fe02); }
.fad.#{$fa-css-prefix}-axe:before, .fa-duotone.#{$fa-css-prefix}-axe:before { content: fa-content(\f6b2); }
.fad.#{$fa-css-prefix}-axe:after, .fa-duotone.#{$fa-css-prefix}-axe:after { content: fa-content(\10f6b2); }
.fad.#{$fa-css-prefix}-axe-battle:before, .fa-duotone.#{$fa-css-prefix}-axe-battle:before { content: fa-content(\f6b3\fe01); }
.fad.#{$fa-css-prefix}-axe-battle:after, .fa-duotone.#{$fa-css-prefix}-axe-battle:after { content: fa-content(\f6b3\fe02); }
.fad.#{$fa-css-prefix}-b:before, .fa-duotone.#{$fa-css-prefix}-b:before { content: fa-content(\42\fe01); }
.fad.#{$fa-css-prefix}-b:after, .fa-duotone.#{$fa-css-prefix}-b:after { content: fa-content(\42\fe02); }
.fad.#{$fa-css-prefix}-baby:before, .fa-duotone.#{$fa-css-prefix}-baby:before { content: fa-content(\f77c\fe01); }
.fad.#{$fa-css-prefix}-baby:after, .fa-duotone.#{$fa-css-prefix}-baby:after { content: fa-content(\f77c\fe02); }
.fad.#{$fa-css-prefix}-baby-carriage:before, .fa-duotone.#{$fa-css-prefix}-baby-carriage:before { content: fa-content(\f77d\fe01); }
.fad.#{$fa-css-prefix}-baby-carriage:after, .fa-duotone.#{$fa-css-prefix}-baby-carriage:after { content: fa-content(\f77d\fe02); }
.fad.#{$fa-css-prefix}-carriage-baby:before, .fa-duotone.#{$fa-css-prefix}-carriage-baby:before { content: fa-content(\f77d\fe01); }
.fad.#{$fa-css-prefix}-carriage-baby:after, .fa-duotone.#{$fa-css-prefix}-carriage-baby:after { content: fa-content(\f77d\fe02); }
.fad.#{$fa-css-prefix}-backpack:before, .fa-duotone.#{$fa-css-prefix}-backpack:before { content: fa-content(\f5d4); }
.fad.#{$fa-css-prefix}-backpack:after, .fa-duotone.#{$fa-css-prefix}-backpack:after { content: fa-content(\10f5d4); }
.fad.#{$fa-css-prefix}-backward:before, .fa-duotone.#{$fa-css-prefix}-backward:before { content: fa-content(\23ea\fe01); }
.fad.#{$fa-css-prefix}-backward:after, .fa-duotone.#{$fa-css-prefix}-backward:after { content: fa-content(\23ea\fe02); }
.fad.#{$fa-css-prefix}-backward-fast:before, .fa-duotone.#{$fa-css-prefix}-backward-fast:before { content: fa-content(\23ee\fe01); }
.fad.#{$fa-css-prefix}-backward-fast:after, .fa-duotone.#{$fa-css-prefix}-backward-fast:after { content: fa-content(\23ee\fe02); }
.fad.#{$fa-css-prefix}-fast-backward:before, .fa-duotone.#{$fa-css-prefix}-fast-backward:before { content: fa-content(\23ee\fe01); }
.fad.#{$fa-css-prefix}-fast-backward:after, .fa-duotone.#{$fa-css-prefix}-fast-backward:after { content: fa-content(\23ee\fe02); }
.fad.#{$fa-css-prefix}-backward-step:before, .fa-duotone.#{$fa-css-prefix}-backward-step:before { content: fa-content(\f048\fe01); }
.fad.#{$fa-css-prefix}-backward-step:after, .fa-duotone.#{$fa-css-prefix}-backward-step:after { content: fa-content(\f048\fe02); }
.fad.#{$fa-css-prefix}-step-backward:before, .fa-duotone.#{$fa-css-prefix}-step-backward:before { content: fa-content(\f048\fe01); }
.fad.#{$fa-css-prefix}-step-backward:after, .fa-duotone.#{$fa-css-prefix}-step-backward:after { content: fa-content(\f048\fe02); }
.fad.#{$fa-css-prefix}-bacon:before, .fa-duotone.#{$fa-css-prefix}-bacon:before { content: fa-content(\f7e5); }
.fad.#{$fa-css-prefix}-bacon:after, .fa-duotone.#{$fa-css-prefix}-bacon:after { content: fa-content(\10f7e5); }
.fad.#{$fa-css-prefix}-bacteria:before, .fa-duotone.#{$fa-css-prefix}-bacteria:before { content: fa-content(\e059\fe01); }
.fad.#{$fa-css-prefix}-bacteria:after, .fa-duotone.#{$fa-css-prefix}-bacteria:after { content: fa-content(\e059\fe02); }
.fad.#{$fa-css-prefix}-bacterium:before, .fa-duotone.#{$fa-css-prefix}-bacterium:before { content: fa-content(\e05a\fe01); }
.fad.#{$fa-css-prefix}-bacterium:after, .fa-duotone.#{$fa-css-prefix}-bacterium:after { content: fa-content(\e05a\fe02); }
.fad.#{$fa-css-prefix}-badge:before, .fa-duotone.#{$fa-css-prefix}-badge:before { content: fa-content(\f335\fe01); }
.fad.#{$fa-css-prefix}-badge:after, .fa-duotone.#{$fa-css-prefix}-badge:after { content: fa-content(\f335\fe02); }
.fad.#{$fa-css-prefix}-badge-check:before, .fa-duotone.#{$fa-css-prefix}-badge-check:before { content: fa-content(\f336\fe01); }
.fad.#{$fa-css-prefix}-badge-check:after, .fa-duotone.#{$fa-css-prefix}-badge-check:after { content: fa-content(\f336\fe02); }
.fad.#{$fa-css-prefix}-badge-dollar:before, .fa-duotone.#{$fa-css-prefix}-badge-dollar:before { content: fa-content(\f645\fe01); }
.fad.#{$fa-css-prefix}-badge-dollar:after, .fa-duotone.#{$fa-css-prefix}-badge-dollar:after { content: fa-content(\f645\fe02); }
.fad.#{$fa-css-prefix}-badge-percent:before, .fa-duotone.#{$fa-css-prefix}-badge-percent:before { content: fa-content(\f646\fe01); }
.fad.#{$fa-css-prefix}-badge-percent:after, .fa-duotone.#{$fa-css-prefix}-badge-percent:after { content: fa-content(\f646\fe02); }
.fad.#{$fa-css-prefix}-badge-sheriff:before, .fa-duotone.#{$fa-css-prefix}-badge-sheriff:before { content: fa-content(\f8a2\fe01); }
.fad.#{$fa-css-prefix}-badge-sheriff:after, .fa-duotone.#{$fa-css-prefix}-badge-sheriff:after { content: fa-content(\f8a2\fe02); }
.fad.#{$fa-css-prefix}-badger-honey:before, .fa-duotone.#{$fa-css-prefix}-badger-honey:before { content: fa-content(\f6b4); }
.fad.#{$fa-css-prefix}-badger-honey:after, .fa-duotone.#{$fa-css-prefix}-badger-honey:after { content: fa-content(\10f6b4); }
.fad.#{$fa-css-prefix}-badminton:before, .fa-duotone.#{$fa-css-prefix}-badminton:before { content: fa-content(\e33a\fe01); }
.fad.#{$fa-css-prefix}-badminton:after, .fa-duotone.#{$fa-css-prefix}-badminton:after { content: fa-content(\e33a\fe02); }
.fad.#{$fa-css-prefix}-bag-shopping:before, .fa-duotone.#{$fa-css-prefix}-bag-shopping:before { content: fa-content(\f290\fe01); }
.fad.#{$fa-css-prefix}-bag-shopping:after, .fa-duotone.#{$fa-css-prefix}-bag-shopping:after { content: fa-content(\f290\fe02); }
.fad.#{$fa-css-prefix}-shopping-bag:before, .fa-duotone.#{$fa-css-prefix}-shopping-bag:before { content: fa-content(\f290\fe01); }
.fad.#{$fa-css-prefix}-shopping-bag:after, .fa-duotone.#{$fa-css-prefix}-shopping-bag:after { content: fa-content(\f290\fe02); }
.fad.#{$fa-css-prefix}-bags-shopping:before, .fa-duotone.#{$fa-css-prefix}-bags-shopping:before { content: fa-content(\f847); }
.fad.#{$fa-css-prefix}-bags-shopping:after, .fa-duotone.#{$fa-css-prefix}-bags-shopping:after { content: fa-content(\10f847); }
.fad.#{$fa-css-prefix}-bahai:before, .fa-duotone.#{$fa-css-prefix}-bahai:before { content: fa-content(\f666\fe01); }
.fad.#{$fa-css-prefix}-bahai:after, .fa-duotone.#{$fa-css-prefix}-bahai:after { content: fa-content(\f666\fe02); }
.fad.#{$fa-css-prefix}-baht-sign:before, .fa-duotone.#{$fa-css-prefix}-baht-sign:before { content: fa-content(\e0ac\fe01); }
.fad.#{$fa-css-prefix}-baht-sign:after, .fa-duotone.#{$fa-css-prefix}-baht-sign:after { content: fa-content(\e0ac\fe02); }
.fad.#{$fa-css-prefix}-ball-pile:before, .fa-duotone.#{$fa-css-prefix}-ball-pile:before { content: fa-content(\f77e\fe01); }
.fad.#{$fa-css-prefix}-ball-pile:after, .fa-duotone.#{$fa-css-prefix}-ball-pile:after { content: fa-content(\f77e\fe02); }
.fad.#{$fa-css-prefix}-balloon:before, .fa-duotone.#{$fa-css-prefix}-balloon:before { content: fa-content(\e2e3\fe01); }
.fad.#{$fa-css-prefix}-balloon:after, .fa-duotone.#{$fa-css-prefix}-balloon:after { content: fa-content(\e2e3\fe02); }
.fad.#{$fa-css-prefix}-balloons:before, .fa-duotone.#{$fa-css-prefix}-balloons:before { content: fa-content(\e2e4\fe01); }
.fad.#{$fa-css-prefix}-balloons:after, .fa-duotone.#{$fa-css-prefix}-balloons:after { content: fa-content(\e2e4\fe02); }
.fad.#{$fa-css-prefix}-ballot:before, .fa-duotone.#{$fa-css-prefix}-ballot:before { content: fa-content(\f732\fe01); }
.fad.#{$fa-css-prefix}-ballot:after, .fa-duotone.#{$fa-css-prefix}-ballot:after { content: fa-content(\f732\fe02); }
.fad.#{$fa-css-prefix}-ballot-check:before, .fa-duotone.#{$fa-css-prefix}-ballot-check:before { content: fa-content(\f733\fe01); }
.fad.#{$fa-css-prefix}-ballot-check:after, .fa-duotone.#{$fa-css-prefix}-ballot-check:after { content: fa-content(\f733\fe02); }
.fad.#{$fa-css-prefix}-ban:before, .fa-duotone.#{$fa-css-prefix}-ban:before { content: fa-content(\f05e); }
.fad.#{$fa-css-prefix}-ban:after, .fa-duotone.#{$fa-css-prefix}-ban:after { content: fa-content(\10f05e); }
.fad.#{$fa-css-prefix}-cancel:before, .fa-duotone.#{$fa-css-prefix}-cancel:before { content: fa-content(\f05e); }
.fad.#{$fa-css-prefix}-cancel:after, .fa-duotone.#{$fa-css-prefix}-cancel:after { content: fa-content(\10f05e); }
.fad.#{$fa-css-prefix}-ban-bug:before, .fa-duotone.#{$fa-css-prefix}-ban-bug:before { content: fa-content(\f7f9\fe01); }
.fad.#{$fa-css-prefix}-ban-bug:after, .fa-duotone.#{$fa-css-prefix}-ban-bug:after { content: fa-content(\f7f9\fe02); }
.fad.#{$fa-css-prefix}-debug:before, .fa-duotone.#{$fa-css-prefix}-debug:before { content: fa-content(\f7f9\fe01); }
.fad.#{$fa-css-prefix}-debug:after, .fa-duotone.#{$fa-css-prefix}-debug:after { content: fa-content(\f7f9\fe02); }
.fad.#{$fa-css-prefix}-ban-parking:before, .fa-duotone.#{$fa-css-prefix}-ban-parking:before { content: fa-content(\f616\fe01); }
.fad.#{$fa-css-prefix}-ban-parking:after, .fa-duotone.#{$fa-css-prefix}-ban-parking:after { content: fa-content(\f616\fe02); }
.fad.#{$fa-css-prefix}-parking-circle-slash:before, .fa-duotone.#{$fa-css-prefix}-parking-circle-slash:before { content: fa-content(\f616\fe01); }
.fad.#{$fa-css-prefix}-parking-circle-slash:after, .fa-duotone.#{$fa-css-prefix}-parking-circle-slash:after { content: fa-content(\f616\fe02); }
.fad.#{$fa-css-prefix}-ban-smoking:before, .fa-duotone.#{$fa-css-prefix}-ban-smoking:before { content: fa-content(\f54d); }
.fad.#{$fa-css-prefix}-ban-smoking:after, .fa-duotone.#{$fa-css-prefix}-ban-smoking:after { content: fa-content(\10f54d); }
.fad.#{$fa-css-prefix}-smoking-ban:before, .fa-duotone.#{$fa-css-prefix}-smoking-ban:before { content: fa-content(\f54d); }
.fad.#{$fa-css-prefix}-smoking-ban:after, .fa-duotone.#{$fa-css-prefix}-smoking-ban:after { content: fa-content(\10f54d); }
.fad.#{$fa-css-prefix}-banana:before, .fa-duotone.#{$fa-css-prefix}-banana:before { content: fa-content(\e2e5\fe01); }
.fad.#{$fa-css-prefix}-banana:after, .fa-duotone.#{$fa-css-prefix}-banana:after { content: fa-content(\e2e5\fe02); }
.fad.#{$fa-css-prefix}-bandage:before, .fa-duotone.#{$fa-css-prefix}-bandage:before { content: fa-content(\f462); }
.fad.#{$fa-css-prefix}-bandage:after, .fa-duotone.#{$fa-css-prefix}-bandage:after { content: fa-content(\10f462); }
.fad.#{$fa-css-prefix}-band-aid:before, .fa-duotone.#{$fa-css-prefix}-band-aid:before { content: fa-content(\f462); }
.fad.#{$fa-css-prefix}-band-aid:after, .fa-duotone.#{$fa-css-prefix}-band-aid:after { content: fa-content(\10f462); }
.fad.#{$fa-css-prefix}-bangladeshi-taka-sign:before, .fa-duotone.#{$fa-css-prefix}-bangladeshi-taka-sign:before { content: fa-content(\e2e6\fe01); }
.fad.#{$fa-css-prefix}-bangladeshi-taka-sign:after, .fa-duotone.#{$fa-css-prefix}-bangladeshi-taka-sign:after { content: fa-content(\e2e6\fe02); }
.fad.#{$fa-css-prefix}-banjo:before, .fa-duotone.#{$fa-css-prefix}-banjo:before { content: fa-content(\f8a3); }
.fad.#{$fa-css-prefix}-banjo:after, .fa-duotone.#{$fa-css-prefix}-banjo:after { content: fa-content(\10f8a3); }
.fad.#{$fa-css-prefix}-bank:before, .fa-duotone.#{$fa-css-prefix}-bank:before { content: fa-content(\f19c\fe01); }
.fad.#{$fa-css-prefix}-bank:after, .fa-duotone.#{$fa-css-prefix}-bank:after { content: fa-content(\f19c\fe02); }
.fad.#{$fa-css-prefix}-institution:before, .fa-duotone.#{$fa-css-prefix}-institution:before { content: fa-content(\f19c\fe01); }
.fad.#{$fa-css-prefix}-institution:after, .fa-duotone.#{$fa-css-prefix}-institution:after { content: fa-content(\f19c\fe02); }
.fad.#{$fa-css-prefix}-university:before, .fa-duotone.#{$fa-css-prefix}-university:before { content: fa-content(\f19c\fe01); }
.fad.#{$fa-css-prefix}-university:after, .fa-duotone.#{$fa-css-prefix}-university:after { content: fa-content(\f19c\fe02); }
.fad.#{$fa-css-prefix}-barcode:before, .fa-duotone.#{$fa-css-prefix}-barcode:before { content: fa-content(\f02a\fe01); }
.fad.#{$fa-css-prefix}-barcode:after, .fa-duotone.#{$fa-css-prefix}-barcode:after { content: fa-content(\f02a\fe02); }
.fad.#{$fa-css-prefix}-barcode-read:before, .fa-duotone.#{$fa-css-prefix}-barcode-read:before { content: fa-content(\f464\fe01); }
.fad.#{$fa-css-prefix}-barcode-read:after, .fa-duotone.#{$fa-css-prefix}-barcode-read:after { content: fa-content(\f464\fe02); }
.fad.#{$fa-css-prefix}-barcode-scan:before, .fa-duotone.#{$fa-css-prefix}-barcode-scan:before { content: fa-content(\f465\fe01); }
.fad.#{$fa-css-prefix}-barcode-scan:after, .fa-duotone.#{$fa-css-prefix}-barcode-scan:after { content: fa-content(\f465\fe02); }
.fad.#{$fa-css-prefix}-bars:before, .fa-duotone.#{$fa-css-prefix}-bars:before { content: fa-content(\f0c9\fe01); }
.fad.#{$fa-css-prefix}-bars:after, .fa-duotone.#{$fa-css-prefix}-bars:after { content: fa-content(\f0c9\fe02); }
.fad.#{$fa-css-prefix}-navicon:before, .fa-duotone.#{$fa-css-prefix}-navicon:before { content: fa-content(\f0c9\fe01); }
.fad.#{$fa-css-prefix}-navicon:after, .fa-duotone.#{$fa-css-prefix}-navicon:after { content: fa-content(\f0c9\fe02); }
.fad.#{$fa-css-prefix}-bars-filter:before, .fa-duotone.#{$fa-css-prefix}-bars-filter:before { content: fa-content(\e0ad\fe01); }
.fad.#{$fa-css-prefix}-bars-filter:after, .fa-duotone.#{$fa-css-prefix}-bars-filter:after { content: fa-content(\e0ad\fe02); }
.fad.#{$fa-css-prefix}-bars-progress:before, .fa-duotone.#{$fa-css-prefix}-bars-progress:before { content: fa-content(\f828\fe01); }
.fad.#{$fa-css-prefix}-bars-progress:after, .fa-duotone.#{$fa-css-prefix}-bars-progress:after { content: fa-content(\f828\fe02); }
.fad.#{$fa-css-prefix}-tasks-alt:before, .fa-duotone.#{$fa-css-prefix}-tasks-alt:before { content: fa-content(\f828\fe01); }
.fad.#{$fa-css-prefix}-tasks-alt:after, .fa-duotone.#{$fa-css-prefix}-tasks-alt:after { content: fa-content(\f828\fe02); }
.fad.#{$fa-css-prefix}-bars-sort:before, .fa-duotone.#{$fa-css-prefix}-bars-sort:before { content: fa-content(\e0ae\fe01); }
.fad.#{$fa-css-prefix}-bars-sort:after, .fa-duotone.#{$fa-css-prefix}-bars-sort:after { content: fa-content(\e0ae\fe02); }
.fad.#{$fa-css-prefix}-bars-staggered:before, .fa-duotone.#{$fa-css-prefix}-bars-staggered:before { content: fa-content(\f550\fe01); }
.fad.#{$fa-css-prefix}-bars-staggered:after, .fa-duotone.#{$fa-css-prefix}-bars-staggered:after { content: fa-content(\f550\fe02); }
.fad.#{$fa-css-prefix}-reorder:before, .fa-duotone.#{$fa-css-prefix}-reorder:before { content: fa-content(\f550\fe01); }
.fad.#{$fa-css-prefix}-reorder:after, .fa-duotone.#{$fa-css-prefix}-reorder:after { content: fa-content(\f550\fe02); }
.fad.#{$fa-css-prefix}-stream:before, .fa-duotone.#{$fa-css-prefix}-stream:before { content: fa-content(\f550\fe01); }
.fad.#{$fa-css-prefix}-stream:after, .fa-duotone.#{$fa-css-prefix}-stream:after { content: fa-content(\f550\fe02); }
.fad.#{$fa-css-prefix}-baseball:before, .fa-duotone.#{$fa-css-prefix}-baseball:before { content: fa-content(\26be\fe01); }
.fad.#{$fa-css-prefix}-baseball:after, .fa-duotone.#{$fa-css-prefix}-baseball:after { content: fa-content(\26be\fe02); }
.fad.#{$fa-css-prefix}-baseball-ball:before, .fa-duotone.#{$fa-css-prefix}-baseball-ball:before { content: fa-content(\26be\fe01); }
.fad.#{$fa-css-prefix}-baseball-ball:after, .fa-duotone.#{$fa-css-prefix}-baseball-ball:after { content: fa-content(\26be\fe02); }
.fad.#{$fa-css-prefix}-baseball-bat-ball:before, .fa-duotone.#{$fa-css-prefix}-baseball-bat-ball:before { content: fa-content(\f432\fe01); }
.fad.#{$fa-css-prefix}-baseball-bat-ball:after, .fa-duotone.#{$fa-css-prefix}-baseball-bat-ball:after { content: fa-content(\f432\fe02); }
.fad.#{$fa-css-prefix}-basket-shopping:before, .fa-duotone.#{$fa-css-prefix}-basket-shopping:before { content: fa-content(\f291\fe01); }
.fad.#{$fa-css-prefix}-basket-shopping:after, .fa-duotone.#{$fa-css-prefix}-basket-shopping:after { content: fa-content(\f291\fe02); }
.fad.#{$fa-css-prefix}-shopping-basket:before, .fa-duotone.#{$fa-css-prefix}-shopping-basket:before { content: fa-content(\f291\fe01); }
.fad.#{$fa-css-prefix}-shopping-basket:after, .fa-duotone.#{$fa-css-prefix}-shopping-basket:after { content: fa-content(\f291\fe02); }
.fad.#{$fa-css-prefix}-basket-shopping-simple:before, .fa-duotone.#{$fa-css-prefix}-basket-shopping-simple:before { content: fa-content(\e0af\fe01); }
.fad.#{$fa-css-prefix}-basket-shopping-simple:after, .fa-duotone.#{$fa-css-prefix}-basket-shopping-simple:after { content: fa-content(\e0af\fe02); }
.fad.#{$fa-css-prefix}-shopping-basket-alt:before, .fa-duotone.#{$fa-css-prefix}-shopping-basket-alt:before { content: fa-content(\e0af\fe01); }
.fad.#{$fa-css-prefix}-shopping-basket-alt:after, .fa-duotone.#{$fa-css-prefix}-shopping-basket-alt:after { content: fa-content(\e0af\fe02); }
.fad.#{$fa-css-prefix}-basketball:before, .fa-duotone.#{$fa-css-prefix}-basketball:before { content: fa-content(\f434); }
.fad.#{$fa-css-prefix}-basketball:after, .fa-duotone.#{$fa-css-prefix}-basketball:after { content: fa-content(\10f434); }
.fad.#{$fa-css-prefix}-basketball-ball:before, .fa-duotone.#{$fa-css-prefix}-basketball-ball:before { content: fa-content(\f434); }
.fad.#{$fa-css-prefix}-basketball-ball:after, .fa-duotone.#{$fa-css-prefix}-basketball-ball:after { content: fa-content(\10f434); }
.fad.#{$fa-css-prefix}-basketball-hoop:before, .fa-duotone.#{$fa-css-prefix}-basketball-hoop:before { content: fa-content(\f435\fe01); }
.fad.#{$fa-css-prefix}-basketball-hoop:after, .fa-duotone.#{$fa-css-prefix}-basketball-hoop:after { content: fa-content(\f435\fe02); }
.fad.#{$fa-css-prefix}-bat:before, .fa-duotone.#{$fa-css-prefix}-bat:before { content: fa-content(\f6b5\fe01); }
.fad.#{$fa-css-prefix}-bat:after, .fa-duotone.#{$fa-css-prefix}-bat:after { content: fa-content(\f6b5\fe02); }
.fad.#{$fa-css-prefix}-bath:before, .fa-duotone.#{$fa-css-prefix}-bath:before { content: fa-content(\f2cd); }
.fad.#{$fa-css-prefix}-bath:after, .fa-duotone.#{$fa-css-prefix}-bath:after { content: fa-content(\10f2cd); }
.fad.#{$fa-css-prefix}-bathtub:before, .fa-duotone.#{$fa-css-prefix}-bathtub:before { content: fa-content(\f2cd); }
.fad.#{$fa-css-prefix}-bathtub:after, .fa-duotone.#{$fa-css-prefix}-bathtub:after { content: fa-content(\10f2cd); }
.fad.#{$fa-css-prefix}-battery-bolt:before, .fa-duotone.#{$fa-css-prefix}-battery-bolt:before { content: fa-content(\f376\fe01); }
.fad.#{$fa-css-prefix}-battery-bolt:after, .fa-duotone.#{$fa-css-prefix}-battery-bolt:after { content: fa-content(\f376\fe02); }
.fad.#{$fa-css-prefix}-battery-empty:before, .fa-duotone.#{$fa-css-prefix}-battery-empty:before { content: fa-content(\f244\fe01); }
.fad.#{$fa-css-prefix}-battery-empty:after, .fa-duotone.#{$fa-css-prefix}-battery-empty:after { content: fa-content(\f244\fe02); }
.fad.#{$fa-css-prefix}-battery-0:before, .fa-duotone.#{$fa-css-prefix}-battery-0:before { content: fa-content(\f244\fe01); }
.fad.#{$fa-css-prefix}-battery-0:after, .fa-duotone.#{$fa-css-prefix}-battery-0:after { content: fa-content(\f244\fe02); }
.fad.#{$fa-css-prefix}-battery-exclamation:before, .fa-duotone.#{$fa-css-prefix}-battery-exclamation:before { content: fa-content(\e0b0\fe01); }
.fad.#{$fa-css-prefix}-battery-exclamation:after, .fa-duotone.#{$fa-css-prefix}-battery-exclamation:after { content: fa-content(\e0b0\fe02); }
.fad.#{$fa-css-prefix}-battery-full:before, .fa-duotone.#{$fa-css-prefix}-battery-full:before { content: fa-content(\f240); }
.fad.#{$fa-css-prefix}-battery-full:after, .fa-duotone.#{$fa-css-prefix}-battery-full:after { content: fa-content(\10f240); }
.fad.#{$fa-css-prefix}-battery:before, .fa-duotone.#{$fa-css-prefix}-battery:before { content: fa-content(\f240); }
.fad.#{$fa-css-prefix}-battery:after, .fa-duotone.#{$fa-css-prefix}-battery:after { content: fa-content(\10f240); }
.fad.#{$fa-css-prefix}-battery-5:before, .fa-duotone.#{$fa-css-prefix}-battery-5:before { content: fa-content(\f240); }
.fad.#{$fa-css-prefix}-battery-5:after, .fa-duotone.#{$fa-css-prefix}-battery-5:after { content: fa-content(\10f240); }
.fad.#{$fa-css-prefix}-battery-half:before, .fa-duotone.#{$fa-css-prefix}-battery-half:before { content: fa-content(\f242\fe01); }
.fad.#{$fa-css-prefix}-battery-half:after, .fa-duotone.#{$fa-css-prefix}-battery-half:after { content: fa-content(\f242\fe02); }
.fad.#{$fa-css-prefix}-battery-3:before, .fa-duotone.#{$fa-css-prefix}-battery-3:before { content: fa-content(\f242\fe01); }
.fad.#{$fa-css-prefix}-battery-3:after, .fa-duotone.#{$fa-css-prefix}-battery-3:after { content: fa-content(\f242\fe02); }
.fad.#{$fa-css-prefix}-battery-low:before, .fa-duotone.#{$fa-css-prefix}-battery-low:before { content: fa-content(\e0b1\fe01); }
.fad.#{$fa-css-prefix}-battery-low:after, .fa-duotone.#{$fa-css-prefix}-battery-low:after { content: fa-content(\e0b1\fe02); }
.fad.#{$fa-css-prefix}-battery-1:before, .fa-duotone.#{$fa-css-prefix}-battery-1:before { content: fa-content(\e0b1\fe01); }
.fad.#{$fa-css-prefix}-battery-1:after, .fa-duotone.#{$fa-css-prefix}-battery-1:after { content: fa-content(\e0b1\fe02); }
.fad.#{$fa-css-prefix}-battery-quarter:before, .fa-duotone.#{$fa-css-prefix}-battery-quarter:before { content: fa-content(\f243\fe01); }
.fad.#{$fa-css-prefix}-battery-quarter:after, .fa-duotone.#{$fa-css-prefix}-battery-quarter:after { content: fa-content(\f243\fe02); }
.fad.#{$fa-css-prefix}-battery-2:before, .fa-duotone.#{$fa-css-prefix}-battery-2:before { content: fa-content(\f243\fe01); }
.fad.#{$fa-css-prefix}-battery-2:after, .fa-duotone.#{$fa-css-prefix}-battery-2:after { content: fa-content(\f243\fe02); }
.fad.#{$fa-css-prefix}-battery-slash:before, .fa-duotone.#{$fa-css-prefix}-battery-slash:before { content: fa-content(\f377\fe01); }
.fad.#{$fa-css-prefix}-battery-slash:after, .fa-duotone.#{$fa-css-prefix}-battery-slash:after { content: fa-content(\f377\fe02); }
.fad.#{$fa-css-prefix}-battery-three-quarters:before, .fa-duotone.#{$fa-css-prefix}-battery-three-quarters:before { content: fa-content(\f241\fe01); }
.fad.#{$fa-css-prefix}-battery-three-quarters:after, .fa-duotone.#{$fa-css-prefix}-battery-three-quarters:after { content: fa-content(\f241\fe02); }
.fad.#{$fa-css-prefix}-battery-4:before, .fa-duotone.#{$fa-css-prefix}-battery-4:before { content: fa-content(\f241\fe01); }
.fad.#{$fa-css-prefix}-battery-4:after, .fa-duotone.#{$fa-css-prefix}-battery-4:after { content: fa-content(\f241\fe02); }
.fad.#{$fa-css-prefix}-bed:before, .fa-duotone.#{$fa-css-prefix}-bed:before { content: fa-content(\f236); }
.fad.#{$fa-css-prefix}-bed:after, .fa-duotone.#{$fa-css-prefix}-bed:after { content: fa-content(\10f236); }
.fad.#{$fa-css-prefix}-bed-bunk:before, .fa-duotone.#{$fa-css-prefix}-bed-bunk:before { content: fa-content(\f8f8\fe01); }
.fad.#{$fa-css-prefix}-bed-bunk:after, .fa-duotone.#{$fa-css-prefix}-bed-bunk:after { content: fa-content(\f8f8\fe02); }
.fad.#{$fa-css-prefix}-bed-empty:before, .fa-duotone.#{$fa-css-prefix}-bed-empty:before { content: fa-content(\f8f9); }
.fad.#{$fa-css-prefix}-bed-empty:after, .fa-duotone.#{$fa-css-prefix}-bed-empty:after { content: fa-content(\10f8f9); }
.fad.#{$fa-css-prefix}-bed-front:before, .fa-duotone.#{$fa-css-prefix}-bed-front:before { content: fa-content(\f8f7\fe01); }
.fad.#{$fa-css-prefix}-bed-front:after, .fa-duotone.#{$fa-css-prefix}-bed-front:after { content: fa-content(\f8f7\fe02); }
.fad.#{$fa-css-prefix}-bed-alt:before, .fa-duotone.#{$fa-css-prefix}-bed-alt:before { content: fa-content(\f8f7\fe01); }
.fad.#{$fa-css-prefix}-bed-alt:after, .fa-duotone.#{$fa-css-prefix}-bed-alt:after { content: fa-content(\f8f7\fe02); }
.fad.#{$fa-css-prefix}-bed-pulse:before, .fa-duotone.#{$fa-css-prefix}-bed-pulse:before { content: fa-content(\f487\fe01); }
.fad.#{$fa-css-prefix}-bed-pulse:after, .fa-duotone.#{$fa-css-prefix}-bed-pulse:after { content: fa-content(\f487\fe02); }
.fad.#{$fa-css-prefix}-procedures:before, .fa-duotone.#{$fa-css-prefix}-procedures:before { content: fa-content(\f487\fe01); }
.fad.#{$fa-css-prefix}-procedures:after, .fa-duotone.#{$fa-css-prefix}-procedures:after { content: fa-content(\f487\fe02); }
.fad.#{$fa-css-prefix}-bee:before, .fa-duotone.#{$fa-css-prefix}-bee:before { content: fa-content(\e0b2\fe01); }
.fad.#{$fa-css-prefix}-bee:after, .fa-duotone.#{$fa-css-prefix}-bee:after { content: fa-content(\e0b2\fe02); }
.fad.#{$fa-css-prefix}-beer-mug:before, .fa-duotone.#{$fa-css-prefix}-beer-mug:before { content: fa-content(\e0b3\fe01); }
.fad.#{$fa-css-prefix}-beer-mug:after, .fa-duotone.#{$fa-css-prefix}-beer-mug:after { content: fa-content(\e0b3\fe02); }
.fad.#{$fa-css-prefix}-beer-foam:before, .fa-duotone.#{$fa-css-prefix}-beer-foam:before { content: fa-content(\e0b3\fe01); }
.fad.#{$fa-css-prefix}-beer-foam:after, .fa-duotone.#{$fa-css-prefix}-beer-foam:after { content: fa-content(\e0b3\fe02); }
.fad.#{$fa-css-prefix}-beer-mug-empty:before, .fa-duotone.#{$fa-css-prefix}-beer-mug-empty:before { content: fa-content(\f0fc\fe01); }
.fad.#{$fa-css-prefix}-beer-mug-empty:after, .fa-duotone.#{$fa-css-prefix}-beer-mug-empty:after { content: fa-content(\f0fc\fe02); }
.fad.#{$fa-css-prefix}-beer:before, .fa-duotone.#{$fa-css-prefix}-beer:before { content: fa-content(\f0fc\fe01); }
.fad.#{$fa-css-prefix}-beer:after, .fa-duotone.#{$fa-css-prefix}-beer:after { content: fa-content(\f0fc\fe02); }
.fad.#{$fa-css-prefix}-bell:before, .fa-duotone.#{$fa-css-prefix}-bell:before { content: fa-content(\f0f3); }
.fad.#{$fa-css-prefix}-bell:after, .fa-duotone.#{$fa-css-prefix}-bell:after { content: fa-content(\10f0f3); }
.fad.#{$fa-css-prefix}-bell-concierge:before, .fa-duotone.#{$fa-css-prefix}-bell-concierge:before { content: fa-content(\f562); }
.fad.#{$fa-css-prefix}-bell-concierge:after, .fa-duotone.#{$fa-css-prefix}-bell-concierge:after { content: fa-content(\10f562); }
.fad.#{$fa-css-prefix}-concierge-bell:before, .fa-duotone.#{$fa-css-prefix}-concierge-bell:before { content: fa-content(\f562); }
.fad.#{$fa-css-prefix}-concierge-bell:after, .fa-duotone.#{$fa-css-prefix}-concierge-bell:after { content: fa-content(\10f562); }
.fad.#{$fa-css-prefix}-bell-exclamation:before, .fa-duotone.#{$fa-css-prefix}-bell-exclamation:before { content: fa-content(\f848\fe01); }
.fad.#{$fa-css-prefix}-bell-exclamation:after, .fa-duotone.#{$fa-css-prefix}-bell-exclamation:after { content: fa-content(\f848\fe02); }
.fad.#{$fa-css-prefix}-bell-on:before, .fa-duotone.#{$fa-css-prefix}-bell-on:before { content: fa-content(\f8fa); }
.fad.#{$fa-css-prefix}-bell-on:after, .fa-duotone.#{$fa-css-prefix}-bell-on:after { content: fa-content(\10f8fa); }
.fad.#{$fa-css-prefix}-bell-plus:before, .fa-duotone.#{$fa-css-prefix}-bell-plus:before { content: fa-content(\f849\fe01); }
.fad.#{$fa-css-prefix}-bell-plus:after, .fa-duotone.#{$fa-css-prefix}-bell-plus:after { content: fa-content(\f849\fe02); }
.fad.#{$fa-css-prefix}-bell-school:before, .fa-duotone.#{$fa-css-prefix}-bell-school:before { content: fa-content(\f5d5\fe01); }
.fad.#{$fa-css-prefix}-bell-school:after, .fa-duotone.#{$fa-css-prefix}-bell-school:after { content: fa-content(\f5d5\fe02); }
.fad.#{$fa-css-prefix}-bell-school-slash:before, .fa-duotone.#{$fa-css-prefix}-bell-school-slash:before { content: fa-content(\f5d6\fe01); }
.fad.#{$fa-css-prefix}-bell-school-slash:after, .fa-duotone.#{$fa-css-prefix}-bell-school-slash:after { content: fa-content(\f5d6\fe02); }
.fad.#{$fa-css-prefix}-bell-slash:before, .fa-duotone.#{$fa-css-prefix}-bell-slash:before { content: fa-content(\f1f6); }
.fad.#{$fa-css-prefix}-bell-slash:after, .fa-duotone.#{$fa-css-prefix}-bell-slash:after { content: fa-content(\10f1f6); }
.fad.#{$fa-css-prefix}-bells:before, .fa-duotone.#{$fa-css-prefix}-bells:before { content: fa-content(\f77f\fe01); }
.fad.#{$fa-css-prefix}-bells:after, .fa-duotone.#{$fa-css-prefix}-bells:after { content: fa-content(\f77f\fe02); }
.fad.#{$fa-css-prefix}-bench-tree:before, .fa-duotone.#{$fa-css-prefix}-bench-tree:before { content: fa-content(\e2e7\fe01); }
.fad.#{$fa-css-prefix}-bench-tree:after, .fa-duotone.#{$fa-css-prefix}-bench-tree:after { content: fa-content(\e2e7\fe02); }
.fad.#{$fa-css-prefix}-bezier-curve:before, .fa-duotone.#{$fa-css-prefix}-bezier-curve:before { content: fa-content(\f55b\fe01); }
.fad.#{$fa-css-prefix}-bezier-curve:after, .fa-duotone.#{$fa-css-prefix}-bezier-curve:after { content: fa-content(\f55b\fe02); }
.fad.#{$fa-css-prefix}-bicycle:before, .fa-duotone.#{$fa-css-prefix}-bicycle:before { content: fa-content(\f206); }
.fad.#{$fa-css-prefix}-bicycle:after, .fa-duotone.#{$fa-css-prefix}-bicycle:after { content: fa-content(\10f206); }
.fad.#{$fa-css-prefix}-binary:before, .fa-duotone.#{$fa-css-prefix}-binary:before { content: fa-content(\e33b\fe01); }
.fad.#{$fa-css-prefix}-binary:after, .fa-duotone.#{$fa-css-prefix}-binary:after { content: fa-content(\e33b\fe02); }
.fad.#{$fa-css-prefix}-binary-circle-check:before, .fa-duotone.#{$fa-css-prefix}-binary-circle-check:before { content: fa-content(\e33c\fe01); }
.fad.#{$fa-css-prefix}-binary-circle-check:after, .fa-duotone.#{$fa-css-prefix}-binary-circle-check:after { content: fa-content(\e33c\fe02); }
.fad.#{$fa-css-prefix}-binary-lock:before, .fa-duotone.#{$fa-css-prefix}-binary-lock:before { content: fa-content(\e33d\fe01); }
.fad.#{$fa-css-prefix}-binary-lock:after, .fa-duotone.#{$fa-css-prefix}-binary-lock:after { content: fa-content(\e33d\fe02); }
.fad.#{$fa-css-prefix}-binary-slash:before, .fa-duotone.#{$fa-css-prefix}-binary-slash:before { content: fa-content(\e33e\fe01); }
.fad.#{$fa-css-prefix}-binary-slash:after, .fa-duotone.#{$fa-css-prefix}-binary-slash:after { content: fa-content(\e33e\fe02); }
.fad.#{$fa-css-prefix}-binoculars:before, .fa-duotone.#{$fa-css-prefix}-binoculars:before { content: fa-content(\f1e5\fe01); }
.fad.#{$fa-css-prefix}-binoculars:after, .fa-duotone.#{$fa-css-prefix}-binoculars:after { content: fa-content(\f1e5\fe02); }
.fad.#{$fa-css-prefix}-biohazard:before, .fa-duotone.#{$fa-css-prefix}-biohazard:before { content: fa-content(\2623\fe01); }
.fad.#{$fa-css-prefix}-biohazard:after, .fa-duotone.#{$fa-css-prefix}-biohazard:after { content: fa-content(\2623\fe02); }
.fad.#{$fa-css-prefix}-bitcoin-sign:before, .fa-duotone.#{$fa-css-prefix}-bitcoin-sign:before { content: fa-content(\e0b4\fe01); }
.fad.#{$fa-css-prefix}-bitcoin-sign:after, .fa-duotone.#{$fa-css-prefix}-bitcoin-sign:after { content: fa-content(\e0b4\fe02); }
.fad.#{$fa-css-prefix}-blanket:before, .fa-duotone.#{$fa-css-prefix}-blanket:before { content: fa-content(\f498\fe01); }
.fad.#{$fa-css-prefix}-blanket:after, .fa-duotone.#{$fa-css-prefix}-blanket:after { content: fa-content(\f498\fe02); }
.fad.#{$fa-css-prefix}-blender:before, .fa-duotone.#{$fa-css-prefix}-blender:before { content: fa-content(\f517\fe01); }
.fad.#{$fa-css-prefix}-blender:after, .fa-duotone.#{$fa-css-prefix}-blender:after { content: fa-content(\f517\fe02); }
.fad.#{$fa-css-prefix}-blender-phone:before, .fa-duotone.#{$fa-css-prefix}-blender-phone:before { content: fa-content(\f6b6\fe01); }
.fad.#{$fa-css-prefix}-blender-phone:after, .fa-duotone.#{$fa-css-prefix}-blender-phone:after { content: fa-content(\f6b6\fe02); }
.fad.#{$fa-css-prefix}-blinds:before, .fa-duotone.#{$fa-css-prefix}-blinds:before { content: fa-content(\f8fb\fe01); }
.fad.#{$fa-css-prefix}-blinds:after, .fa-duotone.#{$fa-css-prefix}-blinds:after { content: fa-content(\f8fb\fe02); }
.fad.#{$fa-css-prefix}-blinds-open:before, .fa-duotone.#{$fa-css-prefix}-blinds-open:before { content: fa-content(\f8fc\fe01); }
.fad.#{$fa-css-prefix}-blinds-open:after, .fa-duotone.#{$fa-css-prefix}-blinds-open:after { content: fa-content(\f8fc\fe02); }
.fad.#{$fa-css-prefix}-blinds-raised:before, .fa-duotone.#{$fa-css-prefix}-blinds-raised:before { content: fa-content(\f8fd\fe01); }
.fad.#{$fa-css-prefix}-blinds-raised:after, .fa-duotone.#{$fa-css-prefix}-blinds-raised:after { content: fa-content(\f8fd\fe02); }
.fad.#{$fa-css-prefix}-block-quote:before, .fa-duotone.#{$fa-css-prefix}-block-quote:before { content: fa-content(\e0b5\fe01); }
.fad.#{$fa-css-prefix}-block-quote:after, .fa-duotone.#{$fa-css-prefix}-block-quote:after { content: fa-content(\e0b5\fe02); }
.fad.#{$fa-css-prefix}-blog:before, .fa-duotone.#{$fa-css-prefix}-blog:before { content: fa-content(\f781\fe01); }
.fad.#{$fa-css-prefix}-blog:after, .fa-duotone.#{$fa-css-prefix}-blog:after { content: fa-content(\f781\fe02); }
.fad.#{$fa-css-prefix}-blueberries:before, .fa-duotone.#{$fa-css-prefix}-blueberries:before { content: fa-content(\e2e8\fe01); }
.fad.#{$fa-css-prefix}-blueberries:after, .fa-duotone.#{$fa-css-prefix}-blueberries:after { content: fa-content(\e2e8\fe02); }
.fad.#{$fa-css-prefix}-bluetooth:before, .fa-duotone.#{$fa-css-prefix}-bluetooth:before { content: fa-content(\f293\fe01); }
.fad.#{$fa-css-prefix}-bluetooth:after, .fa-duotone.#{$fa-css-prefix}-bluetooth:after { content: fa-content(\f293\fe02); }
.fad.#{$fa-css-prefix}-bold:before, .fa-duotone.#{$fa-css-prefix}-bold:before { content: fa-content(\f032\fe01); }
.fad.#{$fa-css-prefix}-bold:after, .fa-duotone.#{$fa-css-prefix}-bold:after { content: fa-content(\f032\fe02); }
.fad.#{$fa-css-prefix}-bolt:before, .fa-duotone.#{$fa-css-prefix}-bolt:before { content: fa-content(\26a1\fe01); }
.fad.#{$fa-css-prefix}-bolt:after, .fa-duotone.#{$fa-css-prefix}-bolt:after { content: fa-content(\26a1\fe02); }
.fad.#{$fa-css-prefix}-flash:before, .fa-duotone.#{$fa-css-prefix}-flash:before { content: fa-content(\26a1\fe01); }
.fad.#{$fa-css-prefix}-flash:after, .fa-duotone.#{$fa-css-prefix}-flash:after { content: fa-content(\26a1\fe02); }
.fad.#{$fa-css-prefix}-bolt-auto:before, .fa-duotone.#{$fa-css-prefix}-bolt-auto:before { content: fa-content(\e0b6\fe01); }
.fad.#{$fa-css-prefix}-bolt-auto:after, .fa-duotone.#{$fa-css-prefix}-bolt-auto:after { content: fa-content(\e0b6\fe02); }
.fad.#{$fa-css-prefix}-bolt-lightning:before, .fa-duotone.#{$fa-css-prefix}-bolt-lightning:before { content: fa-content(\e0b7\fe01); }
.fad.#{$fa-css-prefix}-bolt-lightning:after, .fa-duotone.#{$fa-css-prefix}-bolt-lightning:after { content: fa-content(\e0b7\fe02); }
.fad.#{$fa-css-prefix}-bolt-slash:before, .fa-duotone.#{$fa-css-prefix}-bolt-slash:before { content: fa-content(\e0b8\fe01); }
.fad.#{$fa-css-prefix}-bolt-slash:after, .fa-duotone.#{$fa-css-prefix}-bolt-slash:after { content: fa-content(\e0b8\fe02); }
.fad.#{$fa-css-prefix}-bomb:before, .fa-duotone.#{$fa-css-prefix}-bomb:before { content: fa-content(\f1e2); }
.fad.#{$fa-css-prefix}-bomb:after, .fa-duotone.#{$fa-css-prefix}-bomb:after { content: fa-content(\10f1e2); }
.fad.#{$fa-css-prefix}-bone:before, .fa-duotone.#{$fa-css-prefix}-bone:before { content: fa-content(\f5d7); }
.fad.#{$fa-css-prefix}-bone:after, .fa-duotone.#{$fa-css-prefix}-bone:after { content: fa-content(\10f5d7); }
.fad.#{$fa-css-prefix}-bone-break:before, .fa-duotone.#{$fa-css-prefix}-bone-break:before { content: fa-content(\f5d8\fe01); }
.fad.#{$fa-css-prefix}-bone-break:after, .fa-duotone.#{$fa-css-prefix}-bone-break:after { content: fa-content(\f5d8\fe02); }
.fad.#{$fa-css-prefix}-bong:before, .fa-duotone.#{$fa-css-prefix}-bong:before { content: fa-content(\f55c\fe01); }
.fad.#{$fa-css-prefix}-bong:after, .fa-duotone.#{$fa-css-prefix}-bong:after { content: fa-content(\f55c\fe02); }
.fad.#{$fa-css-prefix}-book:before, .fa-duotone.#{$fa-css-prefix}-book:before { content: fa-content(\f02d); }
.fad.#{$fa-css-prefix}-book:after, .fa-duotone.#{$fa-css-prefix}-book:after { content: fa-content(\10f02d); }
.fad.#{$fa-css-prefix}-book-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-book-arrow-right:before { content: fa-content(\e0b9\fe01); }
.fad.#{$fa-css-prefix}-book-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-book-arrow-right:after { content: fa-content(\e0b9\fe02); }
.fad.#{$fa-css-prefix}-book-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-book-arrow-up:before { content: fa-content(\e0ba\fe01); }
.fad.#{$fa-css-prefix}-book-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-book-arrow-up:after { content: fa-content(\e0ba\fe02); }
.fad.#{$fa-css-prefix}-book-atlas:before, .fa-duotone.#{$fa-css-prefix}-book-atlas:before { content: fa-content(\f558\fe01); }
.fad.#{$fa-css-prefix}-book-atlas:after, .fa-duotone.#{$fa-css-prefix}-book-atlas:after { content: fa-content(\f558\fe02); }
.fad.#{$fa-css-prefix}-atlas:before, .fa-duotone.#{$fa-css-prefix}-atlas:before { content: fa-content(\f558\fe01); }
.fad.#{$fa-css-prefix}-atlas:after, .fa-duotone.#{$fa-css-prefix}-atlas:after { content: fa-content(\f558\fe02); }
.fad.#{$fa-css-prefix}-book-bible:before, .fa-duotone.#{$fa-css-prefix}-book-bible:before { content: fa-content(\f647\fe01); }
.fad.#{$fa-css-prefix}-book-bible:after, .fa-duotone.#{$fa-css-prefix}-book-bible:after { content: fa-content(\f647\fe02); }
.fad.#{$fa-css-prefix}-bible:before, .fa-duotone.#{$fa-css-prefix}-bible:before { content: fa-content(\f647\fe01); }
.fad.#{$fa-css-prefix}-bible:after, .fa-duotone.#{$fa-css-prefix}-bible:after { content: fa-content(\f647\fe02); }
.fad.#{$fa-css-prefix}-book-blank:before, .fa-duotone.#{$fa-css-prefix}-book-blank:before { content: fa-content(\f5d9); }
.fad.#{$fa-css-prefix}-book-blank:after, .fa-duotone.#{$fa-css-prefix}-book-blank:after { content: fa-content(\10f5d9); }
.fad.#{$fa-css-prefix}-book-alt:before, .fa-duotone.#{$fa-css-prefix}-book-alt:before { content: fa-content(\f5d9); }
.fad.#{$fa-css-prefix}-book-alt:after, .fa-duotone.#{$fa-css-prefix}-book-alt:after { content: fa-content(\10f5d9); }
.fad.#{$fa-css-prefix}-book-bookmark:before, .fa-duotone.#{$fa-css-prefix}-book-bookmark:before { content: fa-content(\e0bb\fe01); }
.fad.#{$fa-css-prefix}-book-bookmark:after, .fa-duotone.#{$fa-css-prefix}-book-bookmark:after { content: fa-content(\e0bb\fe02); }
.fad.#{$fa-css-prefix}-book-circle-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-book-circle-arrow-right:before { content: fa-content(\e0bc\fe01); }
.fad.#{$fa-css-prefix}-book-circle-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-book-circle-arrow-right:after { content: fa-content(\e0bc\fe02); }
.fad.#{$fa-css-prefix}-book-circle-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-book-circle-arrow-up:before { content: fa-content(\e0bd\fe01); }
.fad.#{$fa-css-prefix}-book-circle-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-book-circle-arrow-up:after { content: fa-content(\e0bd\fe02); }
.fad.#{$fa-css-prefix}-book-copy:before, .fa-duotone.#{$fa-css-prefix}-book-copy:before { content: fa-content(\e0be\fe01); }
.fad.#{$fa-css-prefix}-book-copy:after, .fa-duotone.#{$fa-css-prefix}-book-copy:after { content: fa-content(\e0be\fe02); }
.fad.#{$fa-css-prefix}-book-font:before, .fa-duotone.#{$fa-css-prefix}-book-font:before { content: fa-content(\e0bf\fe01); }
.fad.#{$fa-css-prefix}-book-font:after, .fa-duotone.#{$fa-css-prefix}-book-font:after { content: fa-content(\e0bf\fe02); }
.fad.#{$fa-css-prefix}-book-heart:before, .fa-duotone.#{$fa-css-prefix}-book-heart:before { content: fa-content(\f499\fe01); }
.fad.#{$fa-css-prefix}-book-heart:after, .fa-duotone.#{$fa-css-prefix}-book-heart:after { content: fa-content(\f499\fe02); }
.fad.#{$fa-css-prefix}-book-journal-whills:before, .fa-duotone.#{$fa-css-prefix}-book-journal-whills:before { content: fa-content(\f66a\fe01); }
.fad.#{$fa-css-prefix}-book-journal-whills:after, .fa-duotone.#{$fa-css-prefix}-book-journal-whills:after { content: fa-content(\f66a\fe02); }
.fad.#{$fa-css-prefix}-journal-whills:before, .fa-duotone.#{$fa-css-prefix}-journal-whills:before { content: fa-content(\f66a\fe01); }
.fad.#{$fa-css-prefix}-journal-whills:after, .fa-duotone.#{$fa-css-prefix}-journal-whills:after { content: fa-content(\f66a\fe02); }
.fad.#{$fa-css-prefix}-book-medical:before, .fa-duotone.#{$fa-css-prefix}-book-medical:before { content: fa-content(\f7e6\fe01); }
.fad.#{$fa-css-prefix}-book-medical:after, .fa-duotone.#{$fa-css-prefix}-book-medical:after { content: fa-content(\f7e6\fe02); }
.fad.#{$fa-css-prefix}-book-open:before, .fa-duotone.#{$fa-css-prefix}-book-open:before { content: fa-content(\f518); }
.fad.#{$fa-css-prefix}-book-open:after, .fa-duotone.#{$fa-css-prefix}-book-open:after { content: fa-content(\10f518); }
.fad.#{$fa-css-prefix}-book-open-cover:before, .fa-duotone.#{$fa-css-prefix}-book-open-cover:before { content: fa-content(\e0c0\fe01); }
.fad.#{$fa-css-prefix}-book-open-cover:after, .fa-duotone.#{$fa-css-prefix}-book-open-cover:after { content: fa-content(\e0c0\fe02); }
.fad.#{$fa-css-prefix}-book-open-alt:before, .fa-duotone.#{$fa-css-prefix}-book-open-alt:before { content: fa-content(\e0c0\fe01); }
.fad.#{$fa-css-prefix}-book-open-alt:after, .fa-duotone.#{$fa-css-prefix}-book-open-alt:after { content: fa-content(\e0c0\fe02); }
.fad.#{$fa-css-prefix}-book-open-reader:before, .fa-duotone.#{$fa-css-prefix}-book-open-reader:before { content: fa-content(\f5da\fe01); }
.fad.#{$fa-css-prefix}-book-open-reader:after, .fa-duotone.#{$fa-css-prefix}-book-open-reader:after { content: fa-content(\f5da\fe02); }
.fad.#{$fa-css-prefix}-book-reader:before, .fa-duotone.#{$fa-css-prefix}-book-reader:before { content: fa-content(\f5da\fe01); }
.fad.#{$fa-css-prefix}-book-reader:after, .fa-duotone.#{$fa-css-prefix}-book-reader:after { content: fa-content(\f5da\fe02); }
.fad.#{$fa-css-prefix}-book-quran:before, .fa-duotone.#{$fa-css-prefix}-book-quran:before { content: fa-content(\f687\fe01); }
.fad.#{$fa-css-prefix}-book-quran:after, .fa-duotone.#{$fa-css-prefix}-book-quran:after { content: fa-content(\f687\fe02); }
.fad.#{$fa-css-prefix}-quran:before, .fa-duotone.#{$fa-css-prefix}-quran:before { content: fa-content(\f687\fe01); }
.fad.#{$fa-css-prefix}-quran:after, .fa-duotone.#{$fa-css-prefix}-quran:after { content: fa-content(\f687\fe02); }
.fad.#{$fa-css-prefix}-book-section:before, .fa-duotone.#{$fa-css-prefix}-book-section:before { content: fa-content(\e0c1\fe01); }
.fad.#{$fa-css-prefix}-book-section:after, .fa-duotone.#{$fa-css-prefix}-book-section:after { content: fa-content(\e0c1\fe02); }
.fad.#{$fa-css-prefix}-book-law:before, .fa-duotone.#{$fa-css-prefix}-book-law:before { content: fa-content(\e0c1\fe01); }
.fad.#{$fa-css-prefix}-book-law:after, .fa-duotone.#{$fa-css-prefix}-book-law:after { content: fa-content(\e0c1\fe02); }
.fad.#{$fa-css-prefix}-book-skull:before, .fa-duotone.#{$fa-css-prefix}-book-skull:before { content: fa-content(\f6b7\fe01); }
.fad.#{$fa-css-prefix}-book-skull:after, .fa-duotone.#{$fa-css-prefix}-book-skull:after { content: fa-content(\f6b7\fe02); }
.fad.#{$fa-css-prefix}-book-dead:before, .fa-duotone.#{$fa-css-prefix}-book-dead:before { content: fa-content(\f6b7\fe01); }
.fad.#{$fa-css-prefix}-book-dead:after, .fa-duotone.#{$fa-css-prefix}-book-dead:after { content: fa-content(\f6b7\fe02); }
.fad.#{$fa-css-prefix}-book-sparkles:before, .fa-duotone.#{$fa-css-prefix}-book-sparkles:before { content: fa-content(\f6b8\fe01); }
.fad.#{$fa-css-prefix}-book-sparkles:after, .fa-duotone.#{$fa-css-prefix}-book-sparkles:after { content: fa-content(\f6b8\fe02); }
.fad.#{$fa-css-prefix}-book-spells:before, .fa-duotone.#{$fa-css-prefix}-book-spells:before { content: fa-content(\f6b8\fe01); }
.fad.#{$fa-css-prefix}-book-spells:after, .fa-duotone.#{$fa-css-prefix}-book-spells:after { content: fa-content(\f6b8\fe02); }
.fad.#{$fa-css-prefix}-book-tanakh:before, .fa-duotone.#{$fa-css-prefix}-book-tanakh:before { content: fa-content(\f827\fe01); }
.fad.#{$fa-css-prefix}-book-tanakh:after, .fa-duotone.#{$fa-css-prefix}-book-tanakh:after { content: fa-content(\f827\fe02); }
.fad.#{$fa-css-prefix}-tanakh:before, .fa-duotone.#{$fa-css-prefix}-tanakh:before { content: fa-content(\f827\fe01); }
.fad.#{$fa-css-prefix}-tanakh:after, .fa-duotone.#{$fa-css-prefix}-tanakh:after { content: fa-content(\f827\fe02); }
.fad.#{$fa-css-prefix}-book-user:before, .fa-duotone.#{$fa-css-prefix}-book-user:before { content: fa-content(\f7e7\fe01); }
.fad.#{$fa-css-prefix}-book-user:after, .fa-duotone.#{$fa-css-prefix}-book-user:after { content: fa-content(\f7e7\fe02); }
.fad.#{$fa-css-prefix}-bookmark:before, .fa-duotone.#{$fa-css-prefix}-bookmark:before { content: fa-content(\f02e); }
.fad.#{$fa-css-prefix}-bookmark:after, .fa-duotone.#{$fa-css-prefix}-bookmark:after { content: fa-content(\10f02e); }
.fad.#{$fa-css-prefix}-bookmark-slash:before, .fa-duotone.#{$fa-css-prefix}-bookmark-slash:before { content: fa-content(\e0c2\fe01); }
.fad.#{$fa-css-prefix}-bookmark-slash:after, .fa-duotone.#{$fa-css-prefix}-bookmark-slash:after { content: fa-content(\e0c2\fe02); }
.fad.#{$fa-css-prefix}-books:before, .fa-duotone.#{$fa-css-prefix}-books:before { content: fa-content(\f5db); }
.fad.#{$fa-css-prefix}-books:after, .fa-duotone.#{$fa-css-prefix}-books:after { content: fa-content(\10f5db); }
.fad.#{$fa-css-prefix}-books-medical:before, .fa-duotone.#{$fa-css-prefix}-books-medical:before { content: fa-content(\f7e8\fe01); }
.fad.#{$fa-css-prefix}-books-medical:after, .fa-duotone.#{$fa-css-prefix}-books-medical:after { content: fa-content(\f7e8\fe02); }
.fad.#{$fa-css-prefix}-boombox:before, .fa-duotone.#{$fa-css-prefix}-boombox:before { content: fa-content(\f8a5); }
.fad.#{$fa-css-prefix}-boombox:after, .fa-duotone.#{$fa-css-prefix}-boombox:after { content: fa-content(\10f8a5); }
.fad.#{$fa-css-prefix}-boot:before, .fa-duotone.#{$fa-css-prefix}-boot:before { content: fa-content(\f782); }
.fad.#{$fa-css-prefix}-boot:after, .fa-duotone.#{$fa-css-prefix}-boot:after { content: fa-content(\10f782); }
.fad.#{$fa-css-prefix}-boot-heeled:before, .fa-duotone.#{$fa-css-prefix}-boot-heeled:before { content: fa-content(\e33f\fe01); }
.fad.#{$fa-css-prefix}-boot-heeled:after, .fa-duotone.#{$fa-css-prefix}-boot-heeled:after { content: fa-content(\e33f\fe02); }
.fad.#{$fa-css-prefix}-booth-curtain:before, .fa-duotone.#{$fa-css-prefix}-booth-curtain:before { content: fa-content(\f734\fe01); }
.fad.#{$fa-css-prefix}-booth-curtain:after, .fa-duotone.#{$fa-css-prefix}-booth-curtain:after { content: fa-content(\f734\fe02); }
.fad.#{$fa-css-prefix}-border-all:before, .fa-duotone.#{$fa-css-prefix}-border-all:before { content: fa-content(\f84c\fe01); }
.fad.#{$fa-css-prefix}-border-all:after, .fa-duotone.#{$fa-css-prefix}-border-all:after { content: fa-content(\f84c\fe02); }
.fad.#{$fa-css-prefix}-border-bottom:before, .fa-duotone.#{$fa-css-prefix}-border-bottom:before { content: fa-content(\f84d\fe01); }
.fad.#{$fa-css-prefix}-border-bottom:after, .fa-duotone.#{$fa-css-prefix}-border-bottom:after { content: fa-content(\f84d\fe02); }
.fad.#{$fa-css-prefix}-border-bottom-right:before, .fa-duotone.#{$fa-css-prefix}-border-bottom-right:before { content: fa-content(\f854\fe01); }
.fad.#{$fa-css-prefix}-border-bottom-right:after, .fa-duotone.#{$fa-css-prefix}-border-bottom-right:after { content: fa-content(\f854\fe02); }
.fad.#{$fa-css-prefix}-border-style-alt:before, .fa-duotone.#{$fa-css-prefix}-border-style-alt:before { content: fa-content(\f854\fe01); }
.fad.#{$fa-css-prefix}-border-style-alt:after, .fa-duotone.#{$fa-css-prefix}-border-style-alt:after { content: fa-content(\f854\fe02); }
.fad.#{$fa-css-prefix}-border-center-h:before, .fa-duotone.#{$fa-css-prefix}-border-center-h:before { content: fa-content(\f89c\fe01); }
.fad.#{$fa-css-prefix}-border-center-h:after, .fa-duotone.#{$fa-css-prefix}-border-center-h:after { content: fa-content(\f89c\fe02); }
.fad.#{$fa-css-prefix}-border-center-v:before, .fa-duotone.#{$fa-css-prefix}-border-center-v:before { content: fa-content(\f89d\fe01); }
.fad.#{$fa-css-prefix}-border-center-v:after, .fa-duotone.#{$fa-css-prefix}-border-center-v:after { content: fa-content(\f89d\fe02); }
.fad.#{$fa-css-prefix}-border-inner:before, .fa-duotone.#{$fa-css-prefix}-border-inner:before { content: fa-content(\f84e\fe01); }
.fad.#{$fa-css-prefix}-border-inner:after, .fa-duotone.#{$fa-css-prefix}-border-inner:after { content: fa-content(\f84e\fe02); }
.fad.#{$fa-css-prefix}-border-left:before, .fa-duotone.#{$fa-css-prefix}-border-left:before { content: fa-content(\f84f\fe01); }
.fad.#{$fa-css-prefix}-border-left:after, .fa-duotone.#{$fa-css-prefix}-border-left:after { content: fa-content(\f84f\fe02); }
.fad.#{$fa-css-prefix}-border-none:before, .fa-duotone.#{$fa-css-prefix}-border-none:before { content: fa-content(\f850\fe01); }
.fad.#{$fa-css-prefix}-border-none:after, .fa-duotone.#{$fa-css-prefix}-border-none:after { content: fa-content(\f850\fe02); }
.fad.#{$fa-css-prefix}-border-outer:before, .fa-duotone.#{$fa-css-prefix}-border-outer:before { content: fa-content(\f851\fe01); }
.fad.#{$fa-css-prefix}-border-outer:after, .fa-duotone.#{$fa-css-prefix}-border-outer:after { content: fa-content(\f851\fe02); }
.fad.#{$fa-css-prefix}-border-right:before, .fa-duotone.#{$fa-css-prefix}-border-right:before { content: fa-content(\f852\fe01); }
.fad.#{$fa-css-prefix}-border-right:after, .fa-duotone.#{$fa-css-prefix}-border-right:after { content: fa-content(\f852\fe02); }
.fad.#{$fa-css-prefix}-border-top:before, .fa-duotone.#{$fa-css-prefix}-border-top:before { content: fa-content(\f855\fe01); }
.fad.#{$fa-css-prefix}-border-top:after, .fa-duotone.#{$fa-css-prefix}-border-top:after { content: fa-content(\f855\fe02); }
.fad.#{$fa-css-prefix}-border-top-left:before, .fa-duotone.#{$fa-css-prefix}-border-top-left:before { content: fa-content(\f853\fe01); }
.fad.#{$fa-css-prefix}-border-top-left:after, .fa-duotone.#{$fa-css-prefix}-border-top-left:after { content: fa-content(\f853\fe02); }
.fad.#{$fa-css-prefix}-border-style:before, .fa-duotone.#{$fa-css-prefix}-border-style:before { content: fa-content(\f853\fe01); }
.fad.#{$fa-css-prefix}-border-style:after, .fa-duotone.#{$fa-css-prefix}-border-style:after { content: fa-content(\f853\fe02); }
.fad.#{$fa-css-prefix}-bow-arrow:before, .fa-duotone.#{$fa-css-prefix}-bow-arrow:before { content: fa-content(\f6b9); }
.fad.#{$fa-css-prefix}-bow-arrow:after, .fa-duotone.#{$fa-css-prefix}-bow-arrow:after { content: fa-content(\10f6b9); }
.fad.#{$fa-css-prefix}-bowl-chopsticks:before, .fa-duotone.#{$fa-css-prefix}-bowl-chopsticks:before { content: fa-content(\e2e9\fe01); }
.fad.#{$fa-css-prefix}-bowl-chopsticks:after, .fa-duotone.#{$fa-css-prefix}-bowl-chopsticks:after { content: fa-content(\e2e9\fe02); }
.fad.#{$fa-css-prefix}-bowl-chopsticks-noodles:before, .fa-duotone.#{$fa-css-prefix}-bowl-chopsticks-noodles:before { content: fa-content(\e2ea\fe01); }
.fad.#{$fa-css-prefix}-bowl-chopsticks-noodles:after, .fa-duotone.#{$fa-css-prefix}-bowl-chopsticks-noodles:after { content: fa-content(\e2ea\fe02); }
.fad.#{$fa-css-prefix}-bowl-hot:before, .fa-duotone.#{$fa-css-prefix}-bowl-hot:before { content: fa-content(\f823\fe01); }
.fad.#{$fa-css-prefix}-bowl-hot:after, .fa-duotone.#{$fa-css-prefix}-bowl-hot:after { content: fa-content(\f823\fe02); }
.fad.#{$fa-css-prefix}-soup:before, .fa-duotone.#{$fa-css-prefix}-soup:before { content: fa-content(\f823\fe01); }
.fad.#{$fa-css-prefix}-soup:after, .fa-duotone.#{$fa-css-prefix}-soup:after { content: fa-content(\f823\fe02); }
.fad.#{$fa-css-prefix}-bowl-rice:before, .fa-duotone.#{$fa-css-prefix}-bowl-rice:before { content: fa-content(\e2eb\fe01); }
.fad.#{$fa-css-prefix}-bowl-rice:after, .fa-duotone.#{$fa-css-prefix}-bowl-rice:after { content: fa-content(\e2eb\fe02); }
.fad.#{$fa-css-prefix}-bowling-ball:before, .fa-duotone.#{$fa-css-prefix}-bowling-ball:before { content: fa-content(\f436\fe01); }
.fad.#{$fa-css-prefix}-bowling-ball:after, .fa-duotone.#{$fa-css-prefix}-bowling-ball:after { content: fa-content(\f436\fe02); }
.fad.#{$fa-css-prefix}-bowling-ball-pin:before, .fa-duotone.#{$fa-css-prefix}-bowling-ball-pin:before { content: fa-content(\e0c3\fe01); }
.fad.#{$fa-css-prefix}-bowling-ball-pin:after, .fa-duotone.#{$fa-css-prefix}-bowling-ball-pin:after { content: fa-content(\e0c3\fe02); }
.fad.#{$fa-css-prefix}-bowling-pins:before, .fa-duotone.#{$fa-css-prefix}-bowling-pins:before { content: fa-content(\f437\fe01); }
.fad.#{$fa-css-prefix}-bowling-pins:after, .fa-duotone.#{$fa-css-prefix}-bowling-pins:after { content: fa-content(\f437\fe02); }
.fad.#{$fa-css-prefix}-box:before, .fa-duotone.#{$fa-css-prefix}-box:before { content: fa-content(\f466); }
.fad.#{$fa-css-prefix}-box:after, .fa-duotone.#{$fa-css-prefix}-box:after { content: fa-content(\10f466); }
.fad.#{$fa-css-prefix}-box-archive:before, .fa-duotone.#{$fa-css-prefix}-box-archive:before { content: fa-content(\f187\fe01); }
.fad.#{$fa-css-prefix}-box-archive:after, .fa-duotone.#{$fa-css-prefix}-box-archive:after { content: fa-content(\f187\fe02); }
.fad.#{$fa-css-prefix}-archive:before, .fa-duotone.#{$fa-css-prefix}-archive:before { content: fa-content(\f187\fe01); }
.fad.#{$fa-css-prefix}-archive:after, .fa-duotone.#{$fa-css-prefix}-archive:after { content: fa-content(\f187\fe02); }
.fad.#{$fa-css-prefix}-box-ballot:before, .fa-duotone.#{$fa-css-prefix}-box-ballot:before { content: fa-content(\f735); }
.fad.#{$fa-css-prefix}-box-ballot:after, .fa-duotone.#{$fa-css-prefix}-box-ballot:after { content: fa-content(\10f735); }
.fad.#{$fa-css-prefix}-box-check:before, .fa-duotone.#{$fa-css-prefix}-box-check:before { content: fa-content(\f467\fe01); }
.fad.#{$fa-css-prefix}-box-check:after, .fa-duotone.#{$fa-css-prefix}-box-check:after { content: fa-content(\f467\fe02); }
.fad.#{$fa-css-prefix}-box-circle-check:before, .fa-duotone.#{$fa-css-prefix}-box-circle-check:before { content: fa-content(\e0c4\fe01); }
.fad.#{$fa-css-prefix}-box-circle-check:after, .fa-duotone.#{$fa-css-prefix}-box-circle-check:after { content: fa-content(\e0c4\fe02); }
.fad.#{$fa-css-prefix}-box-dollar:before, .fa-duotone.#{$fa-css-prefix}-box-dollar:before { content: fa-content(\f4a0\fe01); }
.fad.#{$fa-css-prefix}-box-dollar:after, .fa-duotone.#{$fa-css-prefix}-box-dollar:after { content: fa-content(\f4a0\fe02); }
.fad.#{$fa-css-prefix}-box-usd:before, .fa-duotone.#{$fa-css-prefix}-box-usd:before { content: fa-content(\f4a0\fe01); }
.fad.#{$fa-css-prefix}-box-usd:after, .fa-duotone.#{$fa-css-prefix}-box-usd:after { content: fa-content(\f4a0\fe02); }
.fad.#{$fa-css-prefix}-box-heart:before, .fa-duotone.#{$fa-css-prefix}-box-heart:before { content: fa-content(\f49d\fe01); }
.fad.#{$fa-css-prefix}-box-heart:after, .fa-duotone.#{$fa-css-prefix}-box-heart:after { content: fa-content(\f49d\fe02); }
.fad.#{$fa-css-prefix}-box-open:before, .fa-duotone.#{$fa-css-prefix}-box-open:before { content: fa-content(\f49e\fe01); }
.fad.#{$fa-css-prefix}-box-open:after, .fa-duotone.#{$fa-css-prefix}-box-open:after { content: fa-content(\f49e\fe02); }
.fad.#{$fa-css-prefix}-box-open-full:before, .fa-duotone.#{$fa-css-prefix}-box-open-full:before { content: fa-content(\f49c\fe01); }
.fad.#{$fa-css-prefix}-box-open-full:after, .fa-duotone.#{$fa-css-prefix}-box-open-full:after { content: fa-content(\f49c\fe02); }
.fad.#{$fa-css-prefix}-box-full:before, .fa-duotone.#{$fa-css-prefix}-box-full:before { content: fa-content(\f49c\fe01); }
.fad.#{$fa-css-prefix}-box-full:after, .fa-duotone.#{$fa-css-prefix}-box-full:after { content: fa-content(\f49c\fe02); }
.fad.#{$fa-css-prefix}-box-taped:before, .fa-duotone.#{$fa-css-prefix}-box-taped:before { content: fa-content(\f49a\fe01); }
.fad.#{$fa-css-prefix}-box-taped:after, .fa-duotone.#{$fa-css-prefix}-box-taped:after { content: fa-content(\f49a\fe02); }
.fad.#{$fa-css-prefix}-box-alt:before, .fa-duotone.#{$fa-css-prefix}-box-alt:before { content: fa-content(\f49a\fe01); }
.fad.#{$fa-css-prefix}-box-alt:after, .fa-duotone.#{$fa-css-prefix}-box-alt:after { content: fa-content(\f49a\fe02); }
.fad.#{$fa-css-prefix}-box-tissue:before, .fa-duotone.#{$fa-css-prefix}-box-tissue:before { content: fa-content(\e05b\fe01); }
.fad.#{$fa-css-prefix}-box-tissue:after, .fa-duotone.#{$fa-css-prefix}-box-tissue:after { content: fa-content(\e05b\fe02); }
.fad.#{$fa-css-prefix}-boxes-stacked:before, .fa-duotone.#{$fa-css-prefix}-boxes-stacked:before { content: fa-content(\f468\fe01); }
.fad.#{$fa-css-prefix}-boxes-stacked:after, .fa-duotone.#{$fa-css-prefix}-boxes-stacked:after { content: fa-content(\f468\fe02); }
.fad.#{$fa-css-prefix}-boxes:before, .fa-duotone.#{$fa-css-prefix}-boxes:before { content: fa-content(\f468\fe01); }
.fad.#{$fa-css-prefix}-boxes:after, .fa-duotone.#{$fa-css-prefix}-boxes:after { content: fa-content(\f468\fe02); }
.fad.#{$fa-css-prefix}-boxes-alt:before, .fa-duotone.#{$fa-css-prefix}-boxes-alt:before { content: fa-content(\f468\fe01); }
.fad.#{$fa-css-prefix}-boxes-alt:after, .fa-duotone.#{$fa-css-prefix}-boxes-alt:after { content: fa-content(\f468\fe02); }
.fad.#{$fa-css-prefix}-boxing-glove:before, .fa-duotone.#{$fa-css-prefix}-boxing-glove:before { content: fa-content(\f438); }
.fad.#{$fa-css-prefix}-boxing-glove:after, .fa-duotone.#{$fa-css-prefix}-boxing-glove:after { content: fa-content(\10f438); }
.fad.#{$fa-css-prefix}-glove-boxing:before, .fa-duotone.#{$fa-css-prefix}-glove-boxing:before { content: fa-content(\f438); }
.fad.#{$fa-css-prefix}-glove-boxing:after, .fa-duotone.#{$fa-css-prefix}-glove-boxing:after { content: fa-content(\10f438); }
.fad.#{$fa-css-prefix}-bracket-curly:before, .fa-duotone.#{$fa-css-prefix}-bracket-curly:before { content: fa-content(\7b\fe01); }
.fad.#{$fa-css-prefix}-bracket-curly:after, .fa-duotone.#{$fa-css-prefix}-bracket-curly:after { content: fa-content(\7b\fe02); }
.fad.#{$fa-css-prefix}-bracket-curly-left:before, .fa-duotone.#{$fa-css-prefix}-bracket-curly-left:before { content: fa-content(\7b\fe01); }
.fad.#{$fa-css-prefix}-bracket-curly-left:after, .fa-duotone.#{$fa-css-prefix}-bracket-curly-left:after { content: fa-content(\7b\fe02); }
.fad.#{$fa-css-prefix}-bracket-curly-right:before, .fa-duotone.#{$fa-css-prefix}-bracket-curly-right:before { content: fa-content(\7d\fe01); }
.fad.#{$fa-css-prefix}-bracket-curly-right:after, .fa-duotone.#{$fa-css-prefix}-bracket-curly-right:after { content: fa-content(\7d\fe02); }
.fad.#{$fa-css-prefix}-bracket-round:before, .fa-duotone.#{$fa-css-prefix}-bracket-round:before { content: fa-content(\28\fe01); }
.fad.#{$fa-css-prefix}-bracket-round:after, .fa-duotone.#{$fa-css-prefix}-bracket-round:after { content: fa-content(\28\fe02); }
.fad.#{$fa-css-prefix}-parenthesis:before, .fa-duotone.#{$fa-css-prefix}-parenthesis:before { content: fa-content(\28\fe01); }
.fad.#{$fa-css-prefix}-parenthesis:after, .fa-duotone.#{$fa-css-prefix}-parenthesis:after { content: fa-content(\28\fe02); }
.fad.#{$fa-css-prefix}-bracket-round-right:before, .fa-duotone.#{$fa-css-prefix}-bracket-round-right:before { content: fa-content(\29\fe01); }
.fad.#{$fa-css-prefix}-bracket-round-right:after, .fa-duotone.#{$fa-css-prefix}-bracket-round-right:after { content: fa-content(\29\fe02); }
.fad.#{$fa-css-prefix}-bracket-square:before, .fa-duotone.#{$fa-css-prefix}-bracket-square:before { content: fa-content(\5b\fe01); }
.fad.#{$fa-css-prefix}-bracket-square:after, .fa-duotone.#{$fa-css-prefix}-bracket-square:after { content: fa-content(\5b\fe02); }
.fad.#{$fa-css-prefix}-bracket:before, .fa-duotone.#{$fa-css-prefix}-bracket:before { content: fa-content(\5b\fe01); }
.fad.#{$fa-css-prefix}-bracket:after, .fa-duotone.#{$fa-css-prefix}-bracket:after { content: fa-content(\5b\fe02); }
.fad.#{$fa-css-prefix}-bracket-left:before, .fa-duotone.#{$fa-css-prefix}-bracket-left:before { content: fa-content(\5b\fe01); }
.fad.#{$fa-css-prefix}-bracket-left:after, .fa-duotone.#{$fa-css-prefix}-bracket-left:after { content: fa-content(\5b\fe02); }
.fad.#{$fa-css-prefix}-bracket-square-right:before, .fa-duotone.#{$fa-css-prefix}-bracket-square-right:before { content: fa-content(\5d\fe01); }
.fad.#{$fa-css-prefix}-bracket-square-right:after, .fa-duotone.#{$fa-css-prefix}-bracket-square-right:after { content: fa-content(\5d\fe02); }
.fad.#{$fa-css-prefix}-brackets-curly:before, .fa-duotone.#{$fa-css-prefix}-brackets-curly:before { content: fa-content(\f7ea\fe01); }
.fad.#{$fa-css-prefix}-brackets-curly:after, .fa-duotone.#{$fa-css-prefix}-brackets-curly:after { content: fa-content(\f7ea\fe02); }
.fad.#{$fa-css-prefix}-brackets-round:before, .fa-duotone.#{$fa-css-prefix}-brackets-round:before { content: fa-content(\e0c5\fe01); }
.fad.#{$fa-css-prefix}-brackets-round:after, .fa-duotone.#{$fa-css-prefix}-brackets-round:after { content: fa-content(\e0c5\fe02); }
.fad.#{$fa-css-prefix}-parentheses:before, .fa-duotone.#{$fa-css-prefix}-parentheses:before { content: fa-content(\e0c5\fe01); }
.fad.#{$fa-css-prefix}-parentheses:after, .fa-duotone.#{$fa-css-prefix}-parentheses:after { content: fa-content(\e0c5\fe02); }
.fad.#{$fa-css-prefix}-brackets-square:before, .fa-duotone.#{$fa-css-prefix}-brackets-square:before { content: fa-content(\f7e9\fe01); }
.fad.#{$fa-css-prefix}-brackets-square:after, .fa-duotone.#{$fa-css-prefix}-brackets-square:after { content: fa-content(\f7e9\fe02); }
.fad.#{$fa-css-prefix}-brackets:before, .fa-duotone.#{$fa-css-prefix}-brackets:before { content: fa-content(\f7e9\fe01); }
.fad.#{$fa-css-prefix}-brackets:after, .fa-duotone.#{$fa-css-prefix}-brackets:after { content: fa-content(\f7e9\fe02); }
.fad.#{$fa-css-prefix}-braille:before, .fa-duotone.#{$fa-css-prefix}-braille:before { content: fa-content(\f2a1\fe01); }
.fad.#{$fa-css-prefix}-braille:after, .fa-duotone.#{$fa-css-prefix}-braille:after { content: fa-content(\f2a1\fe02); }
.fad.#{$fa-css-prefix}-brain:before, .fa-duotone.#{$fa-css-prefix}-brain:before { content: fa-content(\f5dc); }
.fad.#{$fa-css-prefix}-brain:after, .fa-duotone.#{$fa-css-prefix}-brain:after { content: fa-content(\10f5dc); }
.fad.#{$fa-css-prefix}-brain-arrow-curved-right:before, .fa-duotone.#{$fa-css-prefix}-brain-arrow-curved-right:before { content: fa-content(\f677\fe01); }
.fad.#{$fa-css-prefix}-brain-arrow-curved-right:after, .fa-duotone.#{$fa-css-prefix}-brain-arrow-curved-right:after { content: fa-content(\f677\fe02); }
.fad.#{$fa-css-prefix}-mind-share:before, .fa-duotone.#{$fa-css-prefix}-mind-share:before { content: fa-content(\f677\fe01); }
.fad.#{$fa-css-prefix}-mind-share:after, .fa-duotone.#{$fa-css-prefix}-mind-share:after { content: fa-content(\f677\fe02); }
.fad.#{$fa-css-prefix}-brain-circuit:before, .fa-duotone.#{$fa-css-prefix}-brain-circuit:before { content: fa-content(\e0c6\fe01); }
.fad.#{$fa-css-prefix}-brain-circuit:after, .fa-duotone.#{$fa-css-prefix}-brain-circuit:after { content: fa-content(\e0c6\fe02); }
.fad.#{$fa-css-prefix}-brake-warning:before, .fa-duotone.#{$fa-css-prefix}-brake-warning:before { content: fa-content(\e0c7\fe01); }
.fad.#{$fa-css-prefix}-brake-warning:after, .fa-duotone.#{$fa-css-prefix}-brake-warning:after { content: fa-content(\e0c7\fe02); }
.fad.#{$fa-css-prefix}-bread-loaf:before, .fa-duotone.#{$fa-css-prefix}-bread-loaf:before { content: fa-content(\f7eb); }
.fad.#{$fa-css-prefix}-bread-loaf:after, .fa-duotone.#{$fa-css-prefix}-bread-loaf:after { content: fa-content(\10f7eb); }
.fad.#{$fa-css-prefix}-bread-slice:before, .fa-duotone.#{$fa-css-prefix}-bread-slice:before { content: fa-content(\f7ec\fe01); }
.fad.#{$fa-css-prefix}-bread-slice:after, .fa-duotone.#{$fa-css-prefix}-bread-slice:after { content: fa-content(\f7ec\fe02); }
.fad.#{$fa-css-prefix}-briefcase:before, .fa-duotone.#{$fa-css-prefix}-briefcase:before { content: fa-content(\f0b1); }
.fad.#{$fa-css-prefix}-briefcase:after, .fa-duotone.#{$fa-css-prefix}-briefcase:after { content: fa-content(\10f0b1); }
.fad.#{$fa-css-prefix}-briefcase-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-briefcase-arrow-right:before { content: fa-content(\e2f2\fe01); }
.fad.#{$fa-css-prefix}-briefcase-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-briefcase-arrow-right:after { content: fa-content(\e2f2\fe02); }
.fad.#{$fa-css-prefix}-briefcase-blank:before, .fa-duotone.#{$fa-css-prefix}-briefcase-blank:before { content: fa-content(\e0c8\fe01); }
.fad.#{$fa-css-prefix}-briefcase-blank:after, .fa-duotone.#{$fa-css-prefix}-briefcase-blank:after { content: fa-content(\e0c8\fe02); }
.fad.#{$fa-css-prefix}-briefcase-clock:before, .fa-duotone.#{$fa-css-prefix}-briefcase-clock:before { content: fa-content(\f64a\fe01); }
.fad.#{$fa-css-prefix}-briefcase-clock:after, .fa-duotone.#{$fa-css-prefix}-briefcase-clock:after { content: fa-content(\f64a\fe02); }
.fad.#{$fa-css-prefix}-business-time:before, .fa-duotone.#{$fa-css-prefix}-business-time:before { content: fa-content(\f64a\fe01); }
.fad.#{$fa-css-prefix}-business-time:after, .fa-duotone.#{$fa-css-prefix}-business-time:after { content: fa-content(\f64a\fe02); }
.fad.#{$fa-css-prefix}-briefcase-medical:before, .fa-duotone.#{$fa-css-prefix}-briefcase-medical:before { content: fa-content(\f469\fe01); }
.fad.#{$fa-css-prefix}-briefcase-medical:after, .fa-duotone.#{$fa-css-prefix}-briefcase-medical:after { content: fa-content(\f469\fe02); }
.fad.#{$fa-css-prefix}-brightness:before, .fa-duotone.#{$fa-css-prefix}-brightness:before { content: fa-content(\e0c9\fe01); }
.fad.#{$fa-css-prefix}-brightness:after, .fa-duotone.#{$fa-css-prefix}-brightness:after { content: fa-content(\e0c9\fe02); }
.fad.#{$fa-css-prefix}-brightness-low:before, .fa-duotone.#{$fa-css-prefix}-brightness-low:before { content: fa-content(\e0ca\fe01); }
.fad.#{$fa-css-prefix}-brightness-low:after, .fa-duotone.#{$fa-css-prefix}-brightness-low:after { content: fa-content(\e0ca\fe02); }
.fad.#{$fa-css-prefix}-bring-forward:before, .fa-duotone.#{$fa-css-prefix}-bring-forward:before { content: fa-content(\f856\fe01); }
.fad.#{$fa-css-prefix}-bring-forward:after, .fa-duotone.#{$fa-css-prefix}-bring-forward:after { content: fa-content(\f856\fe02); }
.fad.#{$fa-css-prefix}-bring-front:before, .fa-duotone.#{$fa-css-prefix}-bring-front:before { content: fa-content(\f857\fe01); }
.fad.#{$fa-css-prefix}-bring-front:after, .fa-duotone.#{$fa-css-prefix}-bring-front:after { content: fa-content(\f857\fe02); }
.fad.#{$fa-css-prefix}-broom:before, .fa-duotone.#{$fa-css-prefix}-broom:before { content: fa-content(\f51a); }
.fad.#{$fa-css-prefix}-broom:after, .fa-duotone.#{$fa-css-prefix}-broom:after { content: fa-content(\10f51a); }
.fad.#{$fa-css-prefix}-browser:before, .fa-duotone.#{$fa-css-prefix}-browser:before { content: fa-content(\f37e); }
.fad.#{$fa-css-prefix}-browser:after, .fa-duotone.#{$fa-css-prefix}-browser:after { content: fa-content(\10f37e); }
.fad.#{$fa-css-prefix}-browsers:before, .fa-duotone.#{$fa-css-prefix}-browsers:before { content: fa-content(\e0cb\fe01); }
.fad.#{$fa-css-prefix}-browsers:after, .fa-duotone.#{$fa-css-prefix}-browsers:after { content: fa-content(\e0cb\fe02); }
.fad.#{$fa-css-prefix}-brush:before, .fa-duotone.#{$fa-css-prefix}-brush:before { content: fa-content(\f55d\fe01); }
.fad.#{$fa-css-prefix}-brush:after, .fa-duotone.#{$fa-css-prefix}-brush:after { content: fa-content(\f55d\fe02); }
.fad.#{$fa-css-prefix}-bug:before, .fa-duotone.#{$fa-css-prefix}-bug:before { content: fa-content(\f188\fe01); }
.fad.#{$fa-css-prefix}-bug:after, .fa-duotone.#{$fa-css-prefix}-bug:after { content: fa-content(\f188\fe02); }
.fad.#{$fa-css-prefix}-building:before, .fa-duotone.#{$fa-css-prefix}-building:before { content: fa-content(\f1ad); }
.fad.#{$fa-css-prefix}-building:after, .fa-duotone.#{$fa-css-prefix}-building:after { content: fa-content(\10f1ad); }
.fad.#{$fa-css-prefix}-buildings:before, .fa-duotone.#{$fa-css-prefix}-buildings:before { content: fa-content(\e0cc\fe01); }
.fad.#{$fa-css-prefix}-buildings:after, .fa-duotone.#{$fa-css-prefix}-buildings:after { content: fa-content(\e0cc\fe02); }
.fad.#{$fa-css-prefix}-bullhorn:before, .fa-duotone.#{$fa-css-prefix}-bullhorn:before { content: fa-content(\f0a1); }
.fad.#{$fa-css-prefix}-bullhorn:after, .fa-duotone.#{$fa-css-prefix}-bullhorn:after { content: fa-content(\10f0a1); }
.fad.#{$fa-css-prefix}-bullseye:before, .fa-duotone.#{$fa-css-prefix}-bullseye:before { content: fa-content(\f140\fe01); }
.fad.#{$fa-css-prefix}-bullseye:after, .fa-duotone.#{$fa-css-prefix}-bullseye:after { content: fa-content(\f140\fe02); }
.fad.#{$fa-css-prefix}-bullseye-arrow:before, .fa-duotone.#{$fa-css-prefix}-bullseye-arrow:before { content: fa-content(\f648); }
.fad.#{$fa-css-prefix}-bullseye-arrow:after, .fa-duotone.#{$fa-css-prefix}-bullseye-arrow:after { content: fa-content(\10f648); }
.fad.#{$fa-css-prefix}-bullseye-pointer:before, .fa-duotone.#{$fa-css-prefix}-bullseye-pointer:before { content: fa-content(\f649\fe01); }
.fad.#{$fa-css-prefix}-bullseye-pointer:after, .fa-duotone.#{$fa-css-prefix}-bullseye-pointer:after { content: fa-content(\f649\fe02); }
.fad.#{$fa-css-prefix}-burger:before, .fa-duotone.#{$fa-css-prefix}-burger:before { content: fa-content(\f805\fe01); }
.fad.#{$fa-css-prefix}-burger:after, .fa-duotone.#{$fa-css-prefix}-burger:after { content: fa-content(\f805\fe02); }
.fad.#{$fa-css-prefix}-hamburger:before, .fa-duotone.#{$fa-css-prefix}-hamburger:before { content: fa-content(\f805\fe01); }
.fad.#{$fa-css-prefix}-hamburger:after, .fa-duotone.#{$fa-css-prefix}-hamburger:after { content: fa-content(\f805\fe02); }
.fad.#{$fa-css-prefix}-burger-cheese:before, .fa-duotone.#{$fa-css-prefix}-burger-cheese:before { content: fa-content(\f7f1); }
.fad.#{$fa-css-prefix}-burger-cheese:after, .fa-duotone.#{$fa-css-prefix}-burger-cheese:after { content: fa-content(\10f7f1); }
.fad.#{$fa-css-prefix}-cheeseburger:before, .fa-duotone.#{$fa-css-prefix}-cheeseburger:before { content: fa-content(\f7f1); }
.fad.#{$fa-css-prefix}-cheeseburger:after, .fa-duotone.#{$fa-css-prefix}-cheeseburger:after { content: fa-content(\10f7f1); }
.fad.#{$fa-css-prefix}-burger-fries:before, .fa-duotone.#{$fa-css-prefix}-burger-fries:before { content: fa-content(\e0cd\fe01); }
.fad.#{$fa-css-prefix}-burger-fries:after, .fa-duotone.#{$fa-css-prefix}-burger-fries:after { content: fa-content(\e0cd\fe02); }
.fad.#{$fa-css-prefix}-burger-glass:before, .fa-duotone.#{$fa-css-prefix}-burger-glass:before { content: fa-content(\e0ce\fe01); }
.fad.#{$fa-css-prefix}-burger-glass:after, .fa-duotone.#{$fa-css-prefix}-burger-glass:after { content: fa-content(\e0ce\fe02); }
.fad.#{$fa-css-prefix}-burger-soda:before, .fa-duotone.#{$fa-css-prefix}-burger-soda:before { content: fa-content(\f858\fe01); }
.fad.#{$fa-css-prefix}-burger-soda:after, .fa-duotone.#{$fa-css-prefix}-burger-soda:after { content: fa-content(\f858\fe02); }
.fad.#{$fa-css-prefix}-burrito:before, .fa-duotone.#{$fa-css-prefix}-burrito:before { content: fa-content(\f7ed); }
.fad.#{$fa-css-prefix}-burrito:after, .fa-duotone.#{$fa-css-prefix}-burrito:after { content: fa-content(\10f7ed); }
.fad.#{$fa-css-prefix}-bus:before, .fa-duotone.#{$fa-css-prefix}-bus:before { content: fa-content(\f207); }
.fad.#{$fa-css-prefix}-bus:after, .fa-duotone.#{$fa-css-prefix}-bus:after { content: fa-content(\10f207); }
.fad.#{$fa-css-prefix}-bus-school:before, .fa-duotone.#{$fa-css-prefix}-bus-school:before { content: fa-content(\f5dd\fe01); }
.fad.#{$fa-css-prefix}-bus-school:after, .fa-duotone.#{$fa-css-prefix}-bus-school:after { content: fa-content(\f5dd\fe02); }
.fad.#{$fa-css-prefix}-bus-simple:before, .fa-duotone.#{$fa-css-prefix}-bus-simple:before { content: fa-content(\f55e\fe01); }
.fad.#{$fa-css-prefix}-bus-simple:after, .fa-duotone.#{$fa-css-prefix}-bus-simple:after { content: fa-content(\f55e\fe02); }
.fad.#{$fa-css-prefix}-bus-alt:before, .fa-duotone.#{$fa-css-prefix}-bus-alt:before { content: fa-content(\f55e\fe01); }
.fad.#{$fa-css-prefix}-bus-alt:after, .fa-duotone.#{$fa-css-prefix}-bus-alt:after { content: fa-content(\f55e\fe02); }
.fad.#{$fa-css-prefix}-c:before, .fa-duotone.#{$fa-css-prefix}-c:before { content: fa-content(\43\fe01); }
.fad.#{$fa-css-prefix}-c:after, .fa-duotone.#{$fa-css-prefix}-c:after { content: fa-content(\43\fe02); }
.fad.#{$fa-css-prefix}-cabinet-filing:before, .fa-duotone.#{$fa-css-prefix}-cabinet-filing:before { content: fa-content(\f64b); }
.fad.#{$fa-css-prefix}-cabinet-filing:after, .fa-duotone.#{$fa-css-prefix}-cabinet-filing:after { content: fa-content(\10f64b); }
.fad.#{$fa-css-prefix}-cable-car:before, .fa-duotone.#{$fa-css-prefix}-cable-car:before { content: fa-content(\e0cf\fe01); }
.fad.#{$fa-css-prefix}-cable-car:after, .fa-duotone.#{$fa-css-prefix}-cable-car:after { content: fa-content(\e0cf\fe02); }
.fad.#{$fa-css-prefix}-cactus:before, .fa-duotone.#{$fa-css-prefix}-cactus:before { content: fa-content(\f8a7); }
.fad.#{$fa-css-prefix}-cactus:after, .fa-duotone.#{$fa-css-prefix}-cactus:after { content: fa-content(\10f8a7); }
.fad.#{$fa-css-prefix}-cake-candles:before, .fa-duotone.#{$fa-css-prefix}-cake-candles:before { content: fa-content(\f1fd); }
.fad.#{$fa-css-prefix}-cake-candles:after, .fa-duotone.#{$fa-css-prefix}-cake-candles:after { content: fa-content(\10f1fd); }
.fad.#{$fa-css-prefix}-birthday-cake:before, .fa-duotone.#{$fa-css-prefix}-birthday-cake:before { content: fa-content(\f1fd); }
.fad.#{$fa-css-prefix}-birthday-cake:after, .fa-duotone.#{$fa-css-prefix}-birthday-cake:after { content: fa-content(\10f1fd); }
.fad.#{$fa-css-prefix}-calculator:before, .fa-duotone.#{$fa-css-prefix}-calculator:before { content: fa-content(\f1ec); }
.fad.#{$fa-css-prefix}-calculator:after, .fa-duotone.#{$fa-css-prefix}-calculator:after { content: fa-content(\10f1ec); }
.fad.#{$fa-css-prefix}-calculator-simple:before, .fa-duotone.#{$fa-css-prefix}-calculator-simple:before { content: fa-content(\f64c\fe01); }
.fad.#{$fa-css-prefix}-calculator-simple:after, .fa-duotone.#{$fa-css-prefix}-calculator-simple:after { content: fa-content(\f64c\fe02); }
.fad.#{$fa-css-prefix}-calculator-alt:before, .fa-duotone.#{$fa-css-prefix}-calculator-alt:before { content: fa-content(\f64c\fe01); }
.fad.#{$fa-css-prefix}-calculator-alt:after, .fa-duotone.#{$fa-css-prefix}-calculator-alt:after { content: fa-content(\f64c\fe02); }
.fad.#{$fa-css-prefix}-calendar:before, .fa-duotone.#{$fa-css-prefix}-calendar:before { content: fa-content(\f133); }
.fad.#{$fa-css-prefix}-calendar:after, .fa-duotone.#{$fa-css-prefix}-calendar:after { content: fa-content(\10f133); }
.fad.#{$fa-css-prefix}-calendar-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-calendar-arrow-down:before { content: fa-content(\e0d0\fe01); }
.fad.#{$fa-css-prefix}-calendar-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-calendar-arrow-down:after { content: fa-content(\e0d0\fe02); }
.fad.#{$fa-css-prefix}-calendar-download:before, .fa-duotone.#{$fa-css-prefix}-calendar-download:before { content: fa-content(\e0d0\fe01); }
.fad.#{$fa-css-prefix}-calendar-download:after, .fa-duotone.#{$fa-css-prefix}-calendar-download:after { content: fa-content(\e0d0\fe02); }
.fad.#{$fa-css-prefix}-calendar-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-calendar-arrow-up:before { content: fa-content(\e0d1\fe01); }
.fad.#{$fa-css-prefix}-calendar-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-calendar-arrow-up:after { content: fa-content(\e0d1\fe02); }
.fad.#{$fa-css-prefix}-calendar-upload:before, .fa-duotone.#{$fa-css-prefix}-calendar-upload:before { content: fa-content(\e0d1\fe01); }
.fad.#{$fa-css-prefix}-calendar-upload:after, .fa-duotone.#{$fa-css-prefix}-calendar-upload:after { content: fa-content(\e0d1\fe02); }
.fad.#{$fa-css-prefix}-calendar-check:before, .fa-duotone.#{$fa-css-prefix}-calendar-check:before { content: fa-content(\f274\fe01); }
.fad.#{$fa-css-prefix}-calendar-check:after, .fa-duotone.#{$fa-css-prefix}-calendar-check:after { content: fa-content(\f274\fe02); }
.fad.#{$fa-css-prefix}-calendar-clock:before, .fa-duotone.#{$fa-css-prefix}-calendar-clock:before { content: fa-content(\e0d2\fe01); }
.fad.#{$fa-css-prefix}-calendar-clock:after, .fa-duotone.#{$fa-css-prefix}-calendar-clock:after { content: fa-content(\e0d2\fe02); }
.fad.#{$fa-css-prefix}-calendar-time:before, .fa-duotone.#{$fa-css-prefix}-calendar-time:before { content: fa-content(\e0d2\fe01); }
.fad.#{$fa-css-prefix}-calendar-time:after, .fa-duotone.#{$fa-css-prefix}-calendar-time:after { content: fa-content(\e0d2\fe02); }
.fad.#{$fa-css-prefix}-calendar-day:before, .fa-duotone.#{$fa-css-prefix}-calendar-day:before { content: fa-content(\f783\fe01); }
.fad.#{$fa-css-prefix}-calendar-day:after, .fa-duotone.#{$fa-css-prefix}-calendar-day:after { content: fa-content(\f783\fe02); }
.fad.#{$fa-css-prefix}-calendar-days:before, .fa-duotone.#{$fa-css-prefix}-calendar-days:before { content: fa-content(\f073\fe01); }
.fad.#{$fa-css-prefix}-calendar-days:after, .fa-duotone.#{$fa-css-prefix}-calendar-days:after { content: fa-content(\f073\fe02); }
.fad.#{$fa-css-prefix}-calendar-alt:before, .fa-duotone.#{$fa-css-prefix}-calendar-alt:before { content: fa-content(\f073\fe01); }
.fad.#{$fa-css-prefix}-calendar-alt:after, .fa-duotone.#{$fa-css-prefix}-calendar-alt:after { content: fa-content(\f073\fe02); }
.fad.#{$fa-css-prefix}-calendar-exclamation:before, .fa-duotone.#{$fa-css-prefix}-calendar-exclamation:before { content: fa-content(\f334\fe01); }
.fad.#{$fa-css-prefix}-calendar-exclamation:after, .fa-duotone.#{$fa-css-prefix}-calendar-exclamation:after { content: fa-content(\f334\fe02); }
.fad.#{$fa-css-prefix}-calendar-heart:before, .fa-duotone.#{$fa-css-prefix}-calendar-heart:before { content: fa-content(\e0d3\fe01); }
.fad.#{$fa-css-prefix}-calendar-heart:after, .fa-duotone.#{$fa-css-prefix}-calendar-heart:after { content: fa-content(\e0d3\fe02); }
.fad.#{$fa-css-prefix}-calendar-image:before, .fa-duotone.#{$fa-css-prefix}-calendar-image:before { content: fa-content(\e0d4\fe01); }
.fad.#{$fa-css-prefix}-calendar-image:after, .fa-duotone.#{$fa-css-prefix}-calendar-image:after { content: fa-content(\e0d4\fe02); }
.fad.#{$fa-css-prefix}-calendar-lines:before, .fa-duotone.#{$fa-css-prefix}-calendar-lines:before { content: fa-content(\e0d5\fe01); }
.fad.#{$fa-css-prefix}-calendar-lines:after, .fa-duotone.#{$fa-css-prefix}-calendar-lines:after { content: fa-content(\e0d5\fe02); }
.fad.#{$fa-css-prefix}-calendar-note:before, .fa-duotone.#{$fa-css-prefix}-calendar-note:before { content: fa-content(\e0d5\fe01); }
.fad.#{$fa-css-prefix}-calendar-note:after, .fa-duotone.#{$fa-css-prefix}-calendar-note:after { content: fa-content(\e0d5\fe02); }
.fad.#{$fa-css-prefix}-calendar-minus:before, .fa-duotone.#{$fa-css-prefix}-calendar-minus:before { content: fa-content(\f272\fe01); }
.fad.#{$fa-css-prefix}-calendar-minus:after, .fa-duotone.#{$fa-css-prefix}-calendar-minus:after { content: fa-content(\f272\fe02); }
.fad.#{$fa-css-prefix}-calendar-pen:before, .fa-duotone.#{$fa-css-prefix}-calendar-pen:before { content: fa-content(\f333\fe01); }
.fad.#{$fa-css-prefix}-calendar-pen:after, .fa-duotone.#{$fa-css-prefix}-calendar-pen:after { content: fa-content(\f333\fe02); }
.fad.#{$fa-css-prefix}-calendar-edit:before, .fa-duotone.#{$fa-css-prefix}-calendar-edit:before { content: fa-content(\f333\fe01); }
.fad.#{$fa-css-prefix}-calendar-edit:after, .fa-duotone.#{$fa-css-prefix}-calendar-edit:after { content: fa-content(\f333\fe02); }
.fad.#{$fa-css-prefix}-calendar-plus:before, .fa-duotone.#{$fa-css-prefix}-calendar-plus:before { content: fa-content(\f271\fe01); }
.fad.#{$fa-css-prefix}-calendar-plus:after, .fa-duotone.#{$fa-css-prefix}-calendar-plus:after { content: fa-content(\f271\fe02); }
.fad.#{$fa-css-prefix}-calendar-range:before, .fa-duotone.#{$fa-css-prefix}-calendar-range:before { content: fa-content(\e0d6\fe01); }
.fad.#{$fa-css-prefix}-calendar-range:after, .fa-duotone.#{$fa-css-prefix}-calendar-range:after { content: fa-content(\e0d6\fe02); }
.fad.#{$fa-css-prefix}-calendar-star:before, .fa-duotone.#{$fa-css-prefix}-calendar-star:before { content: fa-content(\f736\fe01); }
.fad.#{$fa-css-prefix}-calendar-star:after, .fa-duotone.#{$fa-css-prefix}-calendar-star:after { content: fa-content(\f736\fe02); }
.fad.#{$fa-css-prefix}-calendar-week:before, .fa-duotone.#{$fa-css-prefix}-calendar-week:before { content: fa-content(\f784\fe01); }
.fad.#{$fa-css-prefix}-calendar-week:after, .fa-duotone.#{$fa-css-prefix}-calendar-week:after { content: fa-content(\f784\fe02); }
.fad.#{$fa-css-prefix}-calendar-xmark:before, .fa-duotone.#{$fa-css-prefix}-calendar-xmark:before { content: fa-content(\f273\fe01); }
.fad.#{$fa-css-prefix}-calendar-xmark:after, .fa-duotone.#{$fa-css-prefix}-calendar-xmark:after { content: fa-content(\f273\fe02); }
.fad.#{$fa-css-prefix}-calendar-times:before, .fa-duotone.#{$fa-css-prefix}-calendar-times:before { content: fa-content(\f273\fe01); }
.fad.#{$fa-css-prefix}-calendar-times:after, .fa-duotone.#{$fa-css-prefix}-calendar-times:after { content: fa-content(\f273\fe02); }
.fad.#{$fa-css-prefix}-calendars:before, .fa-duotone.#{$fa-css-prefix}-calendars:before { content: fa-content(\e0d7\fe01); }
.fad.#{$fa-css-prefix}-calendars:after, .fa-duotone.#{$fa-css-prefix}-calendars:after { content: fa-content(\e0d7\fe02); }
.fad.#{$fa-css-prefix}-camcorder:before, .fa-duotone.#{$fa-css-prefix}-camcorder:before { content: fa-content(\f8a8); }
.fad.#{$fa-css-prefix}-camcorder:after, .fa-duotone.#{$fa-css-prefix}-camcorder:after { content: fa-content(\10f8a8); }
.fad.#{$fa-css-prefix}-video-handheld:before, .fa-duotone.#{$fa-css-prefix}-video-handheld:before { content: fa-content(\f8a8); }
.fad.#{$fa-css-prefix}-video-handheld:after, .fa-duotone.#{$fa-css-prefix}-video-handheld:after { content: fa-content(\10f8a8); }
.fad.#{$fa-css-prefix}-camera:before, .fa-duotone.#{$fa-css-prefix}-camera:before { content: fa-content(\f030\fe01); }
.fad.#{$fa-css-prefix}-camera:after, .fa-duotone.#{$fa-css-prefix}-camera:after { content: fa-content(\f030\fe02); }
.fad.#{$fa-css-prefix}-camera-alt:before, .fa-duotone.#{$fa-css-prefix}-camera-alt:before { content: fa-content(\f030\fe01); }
.fad.#{$fa-css-prefix}-camera-alt:after, .fa-duotone.#{$fa-css-prefix}-camera-alt:after { content: fa-content(\f030\fe02); }
.fad.#{$fa-css-prefix}-camera-cctv:before, .fa-duotone.#{$fa-css-prefix}-camera-cctv:before { content: fa-content(\f8ac\fe01); }
.fad.#{$fa-css-prefix}-camera-cctv:after, .fa-duotone.#{$fa-css-prefix}-camera-cctv:after { content: fa-content(\f8ac\fe02); }
.fad.#{$fa-css-prefix}-cctv:before, .fa-duotone.#{$fa-css-prefix}-cctv:before { content: fa-content(\f8ac\fe01); }
.fad.#{$fa-css-prefix}-cctv:after, .fa-duotone.#{$fa-css-prefix}-cctv:after { content: fa-content(\f8ac\fe02); }
.fad.#{$fa-css-prefix}-camera-movie:before, .fa-duotone.#{$fa-css-prefix}-camera-movie:before { content: fa-content(\f8a9); }
.fad.#{$fa-css-prefix}-camera-movie:after, .fa-duotone.#{$fa-css-prefix}-camera-movie:after { content: fa-content(\10f8a9); }
.fad.#{$fa-css-prefix}-camera-polaroid:before, .fa-duotone.#{$fa-css-prefix}-camera-polaroid:before { content: fa-content(\f8aa\fe01); }
.fad.#{$fa-css-prefix}-camera-polaroid:after, .fa-duotone.#{$fa-css-prefix}-camera-polaroid:after { content: fa-content(\f8aa\fe02); }
.fad.#{$fa-css-prefix}-camera-retro:before, .fa-duotone.#{$fa-css-prefix}-camera-retro:before { content: fa-content(\f083); }
.fad.#{$fa-css-prefix}-camera-retro:after, .fa-duotone.#{$fa-css-prefix}-camera-retro:after { content: fa-content(\10f083); }
.fad.#{$fa-css-prefix}-camera-rotate:before, .fa-duotone.#{$fa-css-prefix}-camera-rotate:before { content: fa-content(\e0d8\fe01); }
.fad.#{$fa-css-prefix}-camera-rotate:after, .fa-duotone.#{$fa-css-prefix}-camera-rotate:after { content: fa-content(\e0d8\fe02); }
.fad.#{$fa-css-prefix}-camera-security:before, .fa-duotone.#{$fa-css-prefix}-camera-security:before { content: fa-content(\f8fe\fe01); }
.fad.#{$fa-css-prefix}-camera-security:after, .fa-duotone.#{$fa-css-prefix}-camera-security:after { content: fa-content(\f8fe\fe02); }
.fad.#{$fa-css-prefix}-camera-home:before, .fa-duotone.#{$fa-css-prefix}-camera-home:before { content: fa-content(\f8fe\fe01); }
.fad.#{$fa-css-prefix}-camera-home:after, .fa-duotone.#{$fa-css-prefix}-camera-home:after { content: fa-content(\f8fe\fe02); }
.fad.#{$fa-css-prefix}-camera-slash:before, .fa-duotone.#{$fa-css-prefix}-camera-slash:before { content: fa-content(\e0d9\fe01); }
.fad.#{$fa-css-prefix}-camera-slash:after, .fa-duotone.#{$fa-css-prefix}-camera-slash:after { content: fa-content(\e0d9\fe02); }
.fad.#{$fa-css-prefix}-camera-viewfinder:before, .fa-duotone.#{$fa-css-prefix}-camera-viewfinder:before { content: fa-content(\e0da\fe01); }
.fad.#{$fa-css-prefix}-camera-viewfinder:after, .fa-duotone.#{$fa-css-prefix}-camera-viewfinder:after { content: fa-content(\e0da\fe02); }
.fad.#{$fa-css-prefix}-camera-web:before, .fa-duotone.#{$fa-css-prefix}-camera-web:before { content: fa-content(\f832\fe01); }
.fad.#{$fa-css-prefix}-camera-web:after, .fa-duotone.#{$fa-css-prefix}-camera-web:after { content: fa-content(\f832\fe02); }
.fad.#{$fa-css-prefix}-webcam:before, .fa-duotone.#{$fa-css-prefix}-webcam:before { content: fa-content(\f832\fe01); }
.fad.#{$fa-css-prefix}-webcam:after, .fa-duotone.#{$fa-css-prefix}-webcam:after { content: fa-content(\f832\fe02); }
.fad.#{$fa-css-prefix}-camera-web-slash:before, .fa-duotone.#{$fa-css-prefix}-camera-web-slash:before { content: fa-content(\f833\fe01); }
.fad.#{$fa-css-prefix}-camera-web-slash:after, .fa-duotone.#{$fa-css-prefix}-camera-web-slash:after { content: fa-content(\f833\fe02); }
.fad.#{$fa-css-prefix}-webcam-slash:before, .fa-duotone.#{$fa-css-prefix}-webcam-slash:before { content: fa-content(\f833\fe01); }
.fad.#{$fa-css-prefix}-webcam-slash:after, .fa-duotone.#{$fa-css-prefix}-webcam-slash:after { content: fa-content(\f833\fe02); }
.fad.#{$fa-css-prefix}-campfire:before, .fa-duotone.#{$fa-css-prefix}-campfire:before { content: fa-content(\f6ba\fe01); }
.fad.#{$fa-css-prefix}-campfire:after, .fa-duotone.#{$fa-css-prefix}-campfire:after { content: fa-content(\f6ba\fe02); }
.fad.#{$fa-css-prefix}-campground:before, .fa-duotone.#{$fa-css-prefix}-campground:before { content: fa-content(\26fa\fe01); }
.fad.#{$fa-css-prefix}-campground:after, .fa-duotone.#{$fa-css-prefix}-campground:after { content: fa-content(\26fa\fe02); }
.fad.#{$fa-css-prefix}-candle-holder:before, .fa-duotone.#{$fa-css-prefix}-candle-holder:before { content: fa-content(\f6bc); }
.fad.#{$fa-css-prefix}-candle-holder:after, .fa-duotone.#{$fa-css-prefix}-candle-holder:after { content: fa-content(\10f6bc); }
.fad.#{$fa-css-prefix}-candy-cane:before, .fa-duotone.#{$fa-css-prefix}-candy-cane:before { content: fa-content(\f786\fe01); }
.fad.#{$fa-css-prefix}-candy-cane:after, .fa-duotone.#{$fa-css-prefix}-candy-cane:after { content: fa-content(\f786\fe02); }
.fad.#{$fa-css-prefix}-candy-corn:before, .fa-duotone.#{$fa-css-prefix}-candy-corn:before { content: fa-content(\f6bd\fe01); }
.fad.#{$fa-css-prefix}-candy-corn:after, .fa-duotone.#{$fa-css-prefix}-candy-corn:after { content: fa-content(\f6bd\fe02); }
.fad.#{$fa-css-prefix}-cannabis:before, .fa-duotone.#{$fa-css-prefix}-cannabis:before { content: fa-content(\f55f\fe01); }
.fad.#{$fa-css-prefix}-cannabis:after, .fa-duotone.#{$fa-css-prefix}-cannabis:after { content: fa-content(\f55f\fe02); }
.fad.#{$fa-css-prefix}-capsules:before, .fa-duotone.#{$fa-css-prefix}-capsules:before { content: fa-content(\f46b\fe01); }
.fad.#{$fa-css-prefix}-capsules:after, .fa-duotone.#{$fa-css-prefix}-capsules:after { content: fa-content(\f46b\fe02); }
.fad.#{$fa-css-prefix}-car:before, .fa-duotone.#{$fa-css-prefix}-car:before { content: fa-content(\f1b9); }
.fad.#{$fa-css-prefix}-car:after, .fa-duotone.#{$fa-css-prefix}-car:after { content: fa-content(\10f1b9); }
.fad.#{$fa-css-prefix}-automobile:before, .fa-duotone.#{$fa-css-prefix}-automobile:before { content: fa-content(\f1b9); }
.fad.#{$fa-css-prefix}-automobile:after, .fa-duotone.#{$fa-css-prefix}-automobile:after { content: fa-content(\10f1b9); }
.fad.#{$fa-css-prefix}-car-battery:before, .fa-duotone.#{$fa-css-prefix}-car-battery:before { content: fa-content(\f5df\fe01); }
.fad.#{$fa-css-prefix}-car-battery:after, .fa-duotone.#{$fa-css-prefix}-car-battery:after { content: fa-content(\f5df\fe02); }
.fad.#{$fa-css-prefix}-battery-car:before, .fa-duotone.#{$fa-css-prefix}-battery-car:before { content: fa-content(\f5df\fe01); }
.fad.#{$fa-css-prefix}-battery-car:after, .fa-duotone.#{$fa-css-prefix}-battery-car:after { content: fa-content(\f5df\fe02); }
.fad.#{$fa-css-prefix}-car-bolt:before, .fa-duotone.#{$fa-css-prefix}-car-bolt:before { content: fa-content(\e341\fe01); }
.fad.#{$fa-css-prefix}-car-bolt:after, .fa-duotone.#{$fa-css-prefix}-car-bolt:after { content: fa-content(\e341\fe02); }
.fad.#{$fa-css-prefix}-car-building:before, .fa-duotone.#{$fa-css-prefix}-car-building:before { content: fa-content(\f859\fe01); }
.fad.#{$fa-css-prefix}-car-building:after, .fa-duotone.#{$fa-css-prefix}-car-building:after { content: fa-content(\f859\fe02); }
.fad.#{$fa-css-prefix}-car-bump:before, .fa-duotone.#{$fa-css-prefix}-car-bump:before { content: fa-content(\f5e0\fe01); }
.fad.#{$fa-css-prefix}-car-bump:after, .fa-duotone.#{$fa-css-prefix}-car-bump:after { content: fa-content(\f5e0\fe02); }
.fad.#{$fa-css-prefix}-car-bus:before, .fa-duotone.#{$fa-css-prefix}-car-bus:before { content: fa-content(\f85a\fe01); }
.fad.#{$fa-css-prefix}-car-bus:after, .fa-duotone.#{$fa-css-prefix}-car-bus:after { content: fa-content(\f85a\fe02); }
.fad.#{$fa-css-prefix}-car-circle-bolt:before, .fa-duotone.#{$fa-css-prefix}-car-circle-bolt:before { content: fa-content(\e342\fe01); }
.fad.#{$fa-css-prefix}-car-circle-bolt:after, .fa-duotone.#{$fa-css-prefix}-car-circle-bolt:after { content: fa-content(\e342\fe02); }
.fad.#{$fa-css-prefix}-car-crash:before, .fa-duotone.#{$fa-css-prefix}-car-crash:before { content: fa-content(\f5e1\fe01); }
.fad.#{$fa-css-prefix}-car-crash:after, .fa-duotone.#{$fa-css-prefix}-car-crash:after { content: fa-content(\f5e1\fe02); }
.fad.#{$fa-css-prefix}-car-garage:before, .fa-duotone.#{$fa-css-prefix}-car-garage:before { content: fa-content(\f5e2\fe01); }
.fad.#{$fa-css-prefix}-car-garage:after, .fa-duotone.#{$fa-css-prefix}-car-garage:after { content: fa-content(\f5e2\fe02); }
.fad.#{$fa-css-prefix}-car-mirrors:before, .fa-duotone.#{$fa-css-prefix}-car-mirrors:before { content: fa-content(\e343\fe01); }
.fad.#{$fa-css-prefix}-car-mirrors:after, .fa-duotone.#{$fa-css-prefix}-car-mirrors:after { content: fa-content(\e343\fe02); }
.fad.#{$fa-css-prefix}-car-rear:before, .fa-duotone.#{$fa-css-prefix}-car-rear:before { content: fa-content(\f5de\fe01); }
.fad.#{$fa-css-prefix}-car-rear:after, .fa-duotone.#{$fa-css-prefix}-car-rear:after { content: fa-content(\f5de\fe02); }
.fad.#{$fa-css-prefix}-car-alt:before, .fa-duotone.#{$fa-css-prefix}-car-alt:before { content: fa-content(\f5de\fe01); }
.fad.#{$fa-css-prefix}-car-alt:after, .fa-duotone.#{$fa-css-prefix}-car-alt:after { content: fa-content(\f5de\fe02); }
.fad.#{$fa-css-prefix}-car-side:before, .fa-duotone.#{$fa-css-prefix}-car-side:before { content: fa-content(\f5e4); }
.fad.#{$fa-css-prefix}-car-side:after, .fa-duotone.#{$fa-css-prefix}-car-side:after { content: fa-content(\10f5e4); }
.fad.#{$fa-css-prefix}-car-side-bolt:before, .fa-duotone.#{$fa-css-prefix}-car-side-bolt:before { content: fa-content(\e344\fe01); }
.fad.#{$fa-css-prefix}-car-side-bolt:after, .fa-duotone.#{$fa-css-prefix}-car-side-bolt:after { content: fa-content(\e344\fe02); }
.fad.#{$fa-css-prefix}-car-tilt:before, .fa-duotone.#{$fa-css-prefix}-car-tilt:before { content: fa-content(\f5e5\fe01); }
.fad.#{$fa-css-prefix}-car-tilt:after, .fa-duotone.#{$fa-css-prefix}-car-tilt:after { content: fa-content(\f5e5\fe02); }
.fad.#{$fa-css-prefix}-car-wash:before, .fa-duotone.#{$fa-css-prefix}-car-wash:before { content: fa-content(\f5e6\fe01); }
.fad.#{$fa-css-prefix}-car-wash:after, .fa-duotone.#{$fa-css-prefix}-car-wash:after { content: fa-content(\f5e6\fe02); }
.fad.#{$fa-css-prefix}-car-wrench:before, .fa-duotone.#{$fa-css-prefix}-car-wrench:before { content: fa-content(\f5e3\fe01); }
.fad.#{$fa-css-prefix}-car-wrench:after, .fa-duotone.#{$fa-css-prefix}-car-wrench:after { content: fa-content(\f5e3\fe02); }
.fad.#{$fa-css-prefix}-car-mechanic:before, .fa-duotone.#{$fa-css-prefix}-car-mechanic:before { content: fa-content(\f5e3\fe01); }
.fad.#{$fa-css-prefix}-car-mechanic:after, .fa-duotone.#{$fa-css-prefix}-car-mechanic:after { content: fa-content(\f5e3\fe02); }
.fad.#{$fa-css-prefix}-caravan:before, .fa-duotone.#{$fa-css-prefix}-caravan:before { content: fa-content(\f8ff\fe01); }
.fad.#{$fa-css-prefix}-caravan:after, .fa-duotone.#{$fa-css-prefix}-caravan:after { content: fa-content(\f8ff\fe02); }
.fad.#{$fa-css-prefix}-caravan-simple:before, .fa-duotone.#{$fa-css-prefix}-caravan-simple:before { content: fa-content(\e000\fe01); }
.fad.#{$fa-css-prefix}-caravan-simple:after, .fa-duotone.#{$fa-css-prefix}-caravan-simple:after { content: fa-content(\e000\fe02); }
.fad.#{$fa-css-prefix}-caravan-alt:before, .fa-duotone.#{$fa-css-prefix}-caravan-alt:before { content: fa-content(\e000\fe01); }
.fad.#{$fa-css-prefix}-caravan-alt:after, .fa-duotone.#{$fa-css-prefix}-caravan-alt:after { content: fa-content(\e000\fe02); }
.fad.#{$fa-css-prefix}-caret-down:before, .fa-duotone.#{$fa-css-prefix}-caret-down:before { content: fa-content(\f0d7\fe01); }
.fad.#{$fa-css-prefix}-caret-down:after, .fa-duotone.#{$fa-css-prefix}-caret-down:after { content: fa-content(\f0d7\fe02); }
.fad.#{$fa-css-prefix}-caret-left:before, .fa-duotone.#{$fa-css-prefix}-caret-left:before { content: fa-content(\f0d9\fe01); }
.fad.#{$fa-css-prefix}-caret-left:after, .fa-duotone.#{$fa-css-prefix}-caret-left:after { content: fa-content(\f0d9\fe02); }
.fad.#{$fa-css-prefix}-caret-right:before, .fa-duotone.#{$fa-css-prefix}-caret-right:before { content: fa-content(\f0da\fe01); }
.fad.#{$fa-css-prefix}-caret-right:after, .fa-duotone.#{$fa-css-prefix}-caret-right:after { content: fa-content(\f0da\fe02); }
.fad.#{$fa-css-prefix}-caret-up:before, .fa-duotone.#{$fa-css-prefix}-caret-up:before { content: fa-content(\f0d8\fe01); }
.fad.#{$fa-css-prefix}-caret-up:after, .fa-duotone.#{$fa-css-prefix}-caret-up:after { content: fa-content(\f0d8\fe02); }
.fad.#{$fa-css-prefix}-carrot:before, .fa-duotone.#{$fa-css-prefix}-carrot:before { content: fa-content(\f787); }
.fad.#{$fa-css-prefix}-carrot:after, .fa-duotone.#{$fa-css-prefix}-carrot:after { content: fa-content(\10f787); }
.fad.#{$fa-css-prefix}-cars:before, .fa-duotone.#{$fa-css-prefix}-cars:before { content: fa-content(\f85b\fe01); }
.fad.#{$fa-css-prefix}-cars:after, .fa-duotone.#{$fa-css-prefix}-cars:after { content: fa-content(\f85b\fe02); }
.fad.#{$fa-css-prefix}-cart-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-cart-arrow-down:before { content: fa-content(\f218\fe01); }
.fad.#{$fa-css-prefix}-cart-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-cart-arrow-down:after { content: fa-content(\f218\fe02); }
.fad.#{$fa-css-prefix}-cart-flatbed:before, .fa-duotone.#{$fa-css-prefix}-cart-flatbed:before { content: fa-content(\f474\fe01); }
.fad.#{$fa-css-prefix}-cart-flatbed:after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed:after { content: fa-content(\f474\fe02); }
.fad.#{$fa-css-prefix}-dolly-flatbed:before, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed:before { content: fa-content(\f474\fe01); }
.fad.#{$fa-css-prefix}-dolly-flatbed:after, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed:after { content: fa-content(\f474\fe02); }
.fad.#{$fa-css-prefix}-cart-flatbed-boxes:before, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-boxes:before { content: fa-content(\f475\fe01); }
.fad.#{$fa-css-prefix}-cart-flatbed-boxes:after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-boxes:after { content: fa-content(\f475\fe02); }
.fad.#{$fa-css-prefix}-dolly-flatbed-alt:before, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed-alt:before { content: fa-content(\f475\fe01); }
.fad.#{$fa-css-prefix}-dolly-flatbed-alt:after, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed-alt:after { content: fa-content(\f475\fe02); }
.fad.#{$fa-css-prefix}-cart-flatbed-empty:before, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-empty:before { content: fa-content(\f476\fe01); }
.fad.#{$fa-css-prefix}-cart-flatbed-empty:after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-empty:after { content: fa-content(\f476\fe02); }
.fad.#{$fa-css-prefix}-dolly-flatbed-empty:before, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed-empty:before { content: fa-content(\f476\fe01); }
.fad.#{$fa-css-prefix}-dolly-flatbed-empty:after, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed-empty:after { content: fa-content(\f476\fe02); }
.fad.#{$fa-css-prefix}-cart-flatbed-suitcase:before, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-suitcase:before { content: fa-content(\f59d\fe01); }
.fad.#{$fa-css-prefix}-cart-flatbed-suitcase:after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-suitcase:after { content: fa-content(\f59d\fe02); }
.fad.#{$fa-css-prefix}-luggage-cart:before, .fa-duotone.#{$fa-css-prefix}-luggage-cart:before { content: fa-content(\f59d\fe01); }
.fad.#{$fa-css-prefix}-luggage-cart:after, .fa-duotone.#{$fa-css-prefix}-luggage-cart:after { content: fa-content(\f59d\fe02); }
.fad.#{$fa-css-prefix}-cart-minus:before, .fa-duotone.#{$fa-css-prefix}-cart-minus:before { content: fa-content(\e0db\fe01); }
.fad.#{$fa-css-prefix}-cart-minus:after, .fa-duotone.#{$fa-css-prefix}-cart-minus:after { content: fa-content(\e0db\fe02); }
.fad.#{$fa-css-prefix}-cart-plus:before, .fa-duotone.#{$fa-css-prefix}-cart-plus:before { content: fa-content(\f217\fe01); }
.fad.#{$fa-css-prefix}-cart-plus:after, .fa-duotone.#{$fa-css-prefix}-cart-plus:after { content: fa-content(\f217\fe02); }
.fad.#{$fa-css-prefix}-cart-shopping:before, .fa-duotone.#{$fa-css-prefix}-cart-shopping:before { content: fa-content(\f07a); }
.fad.#{$fa-css-prefix}-cart-shopping:after, .fa-duotone.#{$fa-css-prefix}-cart-shopping:after { content: fa-content(\10f07a); }
.fad.#{$fa-css-prefix}-shopping-cart:before, .fa-duotone.#{$fa-css-prefix}-shopping-cart:before { content: fa-content(\f07a); }
.fad.#{$fa-css-prefix}-shopping-cart:after, .fa-duotone.#{$fa-css-prefix}-shopping-cart:after { content: fa-content(\10f07a); }
.fad.#{$fa-css-prefix}-cart-shopping-fast:before, .fa-duotone.#{$fa-css-prefix}-cart-shopping-fast:before { content: fa-content(\e0dc\fe01); }
.fad.#{$fa-css-prefix}-cart-shopping-fast:after, .fa-duotone.#{$fa-css-prefix}-cart-shopping-fast:after { content: fa-content(\e0dc\fe02); }
.fad.#{$fa-css-prefix}-cart-xmark:before, .fa-duotone.#{$fa-css-prefix}-cart-xmark:before { content: fa-content(\e0dd\fe01); }
.fad.#{$fa-css-prefix}-cart-xmark:after, .fa-duotone.#{$fa-css-prefix}-cart-xmark:after { content: fa-content(\e0dd\fe02); }
.fad.#{$fa-css-prefix}-cash-register:before, .fa-duotone.#{$fa-css-prefix}-cash-register:before { content: fa-content(\f788\fe01); }
.fad.#{$fa-css-prefix}-cash-register:after, .fa-duotone.#{$fa-css-prefix}-cash-register:after { content: fa-content(\f788\fe02); }
.fad.#{$fa-css-prefix}-cassette-betamax:before, .fa-duotone.#{$fa-css-prefix}-cassette-betamax:before { content: fa-content(\f8a4\fe01); }
.fad.#{$fa-css-prefix}-cassette-betamax:after, .fa-duotone.#{$fa-css-prefix}-cassette-betamax:after { content: fa-content(\f8a4\fe02); }
.fad.#{$fa-css-prefix}-betamax:before, .fa-duotone.#{$fa-css-prefix}-betamax:before { content: fa-content(\f8a4\fe01); }
.fad.#{$fa-css-prefix}-betamax:after, .fa-duotone.#{$fa-css-prefix}-betamax:after { content: fa-content(\f8a4\fe02); }
.fad.#{$fa-css-prefix}-cassette-tape:before, .fa-duotone.#{$fa-css-prefix}-cassette-tape:before { content: fa-content(\f8ab); }
.fad.#{$fa-css-prefix}-cassette-tape:after, .fa-duotone.#{$fa-css-prefix}-cassette-tape:after { content: fa-content(\10f8ab); }
.fad.#{$fa-css-prefix}-cassette-vhs:before, .fa-duotone.#{$fa-css-prefix}-cassette-vhs:before { content: fa-content(\f8ec); }
.fad.#{$fa-css-prefix}-cassette-vhs:after, .fa-duotone.#{$fa-css-prefix}-cassette-vhs:after { content: fa-content(\10f8ec); }
.fad.#{$fa-css-prefix}-vhs:before, .fa-duotone.#{$fa-css-prefix}-vhs:before { content: fa-content(\f8ec); }
.fad.#{$fa-css-prefix}-vhs:after, .fa-duotone.#{$fa-css-prefix}-vhs:after { content: fa-content(\10f8ec); }
.fad.#{$fa-css-prefix}-castle:before, .fa-duotone.#{$fa-css-prefix}-castle:before { content: fa-content(\e0de\fe01); }
.fad.#{$fa-css-prefix}-castle:after, .fa-duotone.#{$fa-css-prefix}-castle:after { content: fa-content(\e0de\fe02); }
.fad.#{$fa-css-prefix}-cat:before, .fa-duotone.#{$fa-css-prefix}-cat:before { content: fa-content(\f6be); }
.fad.#{$fa-css-prefix}-cat:after, .fa-duotone.#{$fa-css-prefix}-cat:after { content: fa-content(\10f6be); }
.fad.#{$fa-css-prefix}-cat-space:before, .fa-duotone.#{$fa-css-prefix}-cat-space:before { content: fa-content(\e001\fe01); }
.fad.#{$fa-css-prefix}-cat-space:after, .fa-duotone.#{$fa-css-prefix}-cat-space:after { content: fa-content(\e001\fe02); }
.fad.#{$fa-css-prefix}-cauldron:before, .fa-duotone.#{$fa-css-prefix}-cauldron:before { content: fa-content(\f6bf\fe01); }
.fad.#{$fa-css-prefix}-cauldron:after, .fa-duotone.#{$fa-css-prefix}-cauldron:after { content: fa-content(\f6bf\fe02); }
.fad.#{$fa-css-prefix}-cedi-sign:before, .fa-duotone.#{$fa-css-prefix}-cedi-sign:before { content: fa-content(\e0df\fe01); }
.fad.#{$fa-css-prefix}-cedi-sign:after, .fa-duotone.#{$fa-css-prefix}-cedi-sign:after { content: fa-content(\e0df\fe02); }
.fad.#{$fa-css-prefix}-cent-sign:before, .fa-duotone.#{$fa-css-prefix}-cent-sign:before { content: fa-content(\a2\fe01); }
.fad.#{$fa-css-prefix}-cent-sign:after, .fa-duotone.#{$fa-css-prefix}-cent-sign:after { content: fa-content(\a2\fe02); }
.fad.#{$fa-css-prefix}-certificate:before, .fa-duotone.#{$fa-css-prefix}-certificate:before { content: fa-content(\f0a3\fe01); }
.fad.#{$fa-css-prefix}-certificate:after, .fa-duotone.#{$fa-css-prefix}-certificate:after { content: fa-content(\f0a3\fe02); }
.fad.#{$fa-css-prefix}-chair:before, .fa-duotone.#{$fa-css-prefix}-chair:before { content: fa-content(\f6c0); }
.fad.#{$fa-css-prefix}-chair:after, .fa-duotone.#{$fa-css-prefix}-chair:after { content: fa-content(\10f6c0); }
.fad.#{$fa-css-prefix}-chair-office:before, .fa-duotone.#{$fa-css-prefix}-chair-office:before { content: fa-content(\f6c1\fe01); }
.fad.#{$fa-css-prefix}-chair-office:after, .fa-duotone.#{$fa-css-prefix}-chair-office:after { content: fa-content(\f6c1\fe02); }
.fad.#{$fa-css-prefix}-chalkboard:before, .fa-duotone.#{$fa-css-prefix}-chalkboard:before { content: fa-content(\f51b\fe01); }
.fad.#{$fa-css-prefix}-chalkboard:after, .fa-duotone.#{$fa-css-prefix}-chalkboard:after { content: fa-content(\f51b\fe02); }
.fad.#{$fa-css-prefix}-blackboard:before, .fa-duotone.#{$fa-css-prefix}-blackboard:before { content: fa-content(\f51b\fe01); }
.fad.#{$fa-css-prefix}-blackboard:after, .fa-duotone.#{$fa-css-prefix}-blackboard:after { content: fa-content(\f51b\fe02); }
.fad.#{$fa-css-prefix}-chalkboard-user:before, .fa-duotone.#{$fa-css-prefix}-chalkboard-user:before { content: fa-content(\f51c\fe01); }
.fad.#{$fa-css-prefix}-chalkboard-user:after, .fa-duotone.#{$fa-css-prefix}-chalkboard-user:after { content: fa-content(\f51c\fe02); }
.fad.#{$fa-css-prefix}-chalkboard-teacher:before, .fa-duotone.#{$fa-css-prefix}-chalkboard-teacher:before { content: fa-content(\f51c\fe01); }
.fad.#{$fa-css-prefix}-chalkboard-teacher:after, .fa-duotone.#{$fa-css-prefix}-chalkboard-teacher:after { content: fa-content(\f51c\fe02); }
.fad.#{$fa-css-prefix}-champagne-glass:before, .fa-duotone.#{$fa-css-prefix}-champagne-glass:before { content: fa-content(\f79e\fe01); }
.fad.#{$fa-css-prefix}-champagne-glass:after, .fa-duotone.#{$fa-css-prefix}-champagne-glass:after { content: fa-content(\f79e\fe02); }
.fad.#{$fa-css-prefix}-glass-champagne:before, .fa-duotone.#{$fa-css-prefix}-glass-champagne:before { content: fa-content(\f79e\fe01); }
.fad.#{$fa-css-prefix}-glass-champagne:after, .fa-duotone.#{$fa-css-prefix}-glass-champagne:after { content: fa-content(\f79e\fe02); }
.fad.#{$fa-css-prefix}-champagne-glasses:before, .fa-duotone.#{$fa-css-prefix}-champagne-glasses:before { content: fa-content(\f79f); }
.fad.#{$fa-css-prefix}-champagne-glasses:after, .fa-duotone.#{$fa-css-prefix}-champagne-glasses:after { content: fa-content(\10f79f); }
.fad.#{$fa-css-prefix}-glass-cheers:before, .fa-duotone.#{$fa-css-prefix}-glass-cheers:before { content: fa-content(\f79f); }
.fad.#{$fa-css-prefix}-glass-cheers:after, .fa-duotone.#{$fa-css-prefix}-glass-cheers:after { content: fa-content(\10f79f); }
.fad.#{$fa-css-prefix}-charging-station:before, .fa-duotone.#{$fa-css-prefix}-charging-station:before { content: fa-content(\f5e7\fe01); }
.fad.#{$fa-css-prefix}-charging-station:after, .fa-duotone.#{$fa-css-prefix}-charging-station:after { content: fa-content(\f5e7\fe02); }
.fad.#{$fa-css-prefix}-chart-area:before, .fa-duotone.#{$fa-css-prefix}-chart-area:before { content: fa-content(\f1fe\fe01); }
.fad.#{$fa-css-prefix}-chart-area:after, .fa-duotone.#{$fa-css-prefix}-chart-area:after { content: fa-content(\f1fe\fe02); }
.fad.#{$fa-css-prefix}-area-chart:before, .fa-duotone.#{$fa-css-prefix}-area-chart:before { content: fa-content(\f1fe\fe01); }
.fad.#{$fa-css-prefix}-area-chart:after, .fa-duotone.#{$fa-css-prefix}-area-chart:after { content: fa-content(\f1fe\fe02); }
.fad.#{$fa-css-prefix}-chart-bar:before, .fa-duotone.#{$fa-css-prefix}-chart-bar:before { content: fa-content(\f080\fe01); }
.fad.#{$fa-css-prefix}-chart-bar:after, .fa-duotone.#{$fa-css-prefix}-chart-bar:after { content: fa-content(\f080\fe02); }
.fad.#{$fa-css-prefix}-bar-chart:before, .fa-duotone.#{$fa-css-prefix}-bar-chart:before { content: fa-content(\f080\fe01); }
.fad.#{$fa-css-prefix}-bar-chart:after, .fa-duotone.#{$fa-css-prefix}-bar-chart:after { content: fa-content(\f080\fe02); }
.fad.#{$fa-css-prefix}-chart-bullet:before, .fa-duotone.#{$fa-css-prefix}-chart-bullet:before { content: fa-content(\e0e1\fe01); }
.fad.#{$fa-css-prefix}-chart-bullet:after, .fa-duotone.#{$fa-css-prefix}-chart-bullet:after { content: fa-content(\e0e1\fe02); }
.fad.#{$fa-css-prefix}-chart-candlestick:before, .fa-duotone.#{$fa-css-prefix}-chart-candlestick:before { content: fa-content(\e0e2\fe01); }
.fad.#{$fa-css-prefix}-chart-candlestick:after, .fa-duotone.#{$fa-css-prefix}-chart-candlestick:after { content: fa-content(\e0e2\fe02); }
.fad.#{$fa-css-prefix}-chart-column:before, .fa-duotone.#{$fa-css-prefix}-chart-column:before { content: fa-content(\e0e3\fe01); }
.fad.#{$fa-css-prefix}-chart-column:after, .fa-duotone.#{$fa-css-prefix}-chart-column:after { content: fa-content(\e0e3\fe02); }
.fad.#{$fa-css-prefix}-chart-gantt:before, .fa-duotone.#{$fa-css-prefix}-chart-gantt:before { content: fa-content(\e0e4\fe01); }
.fad.#{$fa-css-prefix}-chart-gantt:after, .fa-duotone.#{$fa-css-prefix}-chart-gantt:after { content: fa-content(\e0e4\fe02); }
.fad.#{$fa-css-prefix}-chart-line:before, .fa-duotone.#{$fa-css-prefix}-chart-line:before { content: fa-content(\f201\fe01); }
.fad.#{$fa-css-prefix}-chart-line:after, .fa-duotone.#{$fa-css-prefix}-chart-line:after { content: fa-content(\f201\fe02); }
.fad.#{$fa-css-prefix}-line-chart:before, .fa-duotone.#{$fa-css-prefix}-line-chart:before { content: fa-content(\f201\fe01); }
.fad.#{$fa-css-prefix}-line-chart:after, .fa-duotone.#{$fa-css-prefix}-line-chart:after { content: fa-content(\f201\fe02); }
.fad.#{$fa-css-prefix}-chart-line-down:before, .fa-duotone.#{$fa-css-prefix}-chart-line-down:before { content: fa-content(\f64d); }
.fad.#{$fa-css-prefix}-chart-line-down:after, .fa-duotone.#{$fa-css-prefix}-chart-line-down:after { content: fa-content(\10f64d); }
.fad.#{$fa-css-prefix}-chart-line-up:before, .fa-duotone.#{$fa-css-prefix}-chart-line-up:before { content: fa-content(\e0e5\fe01); }
.fad.#{$fa-css-prefix}-chart-line-up:after, .fa-duotone.#{$fa-css-prefix}-chart-line-up:after { content: fa-content(\e0e5\fe02); }
.fad.#{$fa-css-prefix}-chart-mixed:before, .fa-duotone.#{$fa-css-prefix}-chart-mixed:before { content: fa-content(\f643\fe01); }
.fad.#{$fa-css-prefix}-chart-mixed:after, .fa-duotone.#{$fa-css-prefix}-chart-mixed:after { content: fa-content(\f643\fe02); }
.fad.#{$fa-css-prefix}-analytics:before, .fa-duotone.#{$fa-css-prefix}-analytics:before { content: fa-content(\f643\fe01); }
.fad.#{$fa-css-prefix}-analytics:after, .fa-duotone.#{$fa-css-prefix}-analytics:after { content: fa-content(\f643\fe02); }
.fad.#{$fa-css-prefix}-chart-network:before, .fa-duotone.#{$fa-css-prefix}-chart-network:before { content: fa-content(\f78a\fe01); }
.fad.#{$fa-css-prefix}-chart-network:after, .fa-duotone.#{$fa-css-prefix}-chart-network:after { content: fa-content(\f78a\fe02); }
.fad.#{$fa-css-prefix}-chart-pie:before, .fa-duotone.#{$fa-css-prefix}-chart-pie:before { content: fa-content(\f200\fe01); }
.fad.#{$fa-css-prefix}-chart-pie:after, .fa-duotone.#{$fa-css-prefix}-chart-pie:after { content: fa-content(\f200\fe02); }
.fad.#{$fa-css-prefix}-pie-chart:before, .fa-duotone.#{$fa-css-prefix}-pie-chart:before { content: fa-content(\f200\fe01); }
.fad.#{$fa-css-prefix}-pie-chart:after, .fa-duotone.#{$fa-css-prefix}-pie-chart:after { content: fa-content(\f200\fe02); }
.fad.#{$fa-css-prefix}-chart-pie-simple:before, .fa-duotone.#{$fa-css-prefix}-chart-pie-simple:before { content: fa-content(\f64e\fe01); }
.fad.#{$fa-css-prefix}-chart-pie-simple:after, .fa-duotone.#{$fa-css-prefix}-chart-pie-simple:after { content: fa-content(\f64e\fe02); }
.fad.#{$fa-css-prefix}-chart-pie-alt:before, .fa-duotone.#{$fa-css-prefix}-chart-pie-alt:before { content: fa-content(\f64e\fe01); }
.fad.#{$fa-css-prefix}-chart-pie-alt:after, .fa-duotone.#{$fa-css-prefix}-chart-pie-alt:after { content: fa-content(\f64e\fe02); }
.fad.#{$fa-css-prefix}-chart-pyramid:before, .fa-duotone.#{$fa-css-prefix}-chart-pyramid:before { content: fa-content(\e0e6\fe01); }
.fad.#{$fa-css-prefix}-chart-pyramid:after, .fa-duotone.#{$fa-css-prefix}-chart-pyramid:after { content: fa-content(\e0e6\fe02); }
.fad.#{$fa-css-prefix}-chart-radar:before, .fa-duotone.#{$fa-css-prefix}-chart-radar:before { content: fa-content(\e0e7\fe01); }
.fad.#{$fa-css-prefix}-chart-radar:after, .fa-duotone.#{$fa-css-prefix}-chart-radar:after { content: fa-content(\e0e7\fe02); }
.fad.#{$fa-css-prefix}-chart-scatter:before, .fa-duotone.#{$fa-css-prefix}-chart-scatter:before { content: fa-content(\f7ee\fe01); }
.fad.#{$fa-css-prefix}-chart-scatter:after, .fa-duotone.#{$fa-css-prefix}-chart-scatter:after { content: fa-content(\f7ee\fe02); }
.fad.#{$fa-css-prefix}-chart-scatter-3d:before, .fa-duotone.#{$fa-css-prefix}-chart-scatter-3d:before { content: fa-content(\e0e8\fe01); }
.fad.#{$fa-css-prefix}-chart-scatter-3d:after, .fa-duotone.#{$fa-css-prefix}-chart-scatter-3d:after { content: fa-content(\e0e8\fe02); }
.fad.#{$fa-css-prefix}-chart-scatter-bubble:before, .fa-duotone.#{$fa-css-prefix}-chart-scatter-bubble:before { content: fa-content(\e0e9\fe01); }
.fad.#{$fa-css-prefix}-chart-scatter-bubble:after, .fa-duotone.#{$fa-css-prefix}-chart-scatter-bubble:after { content: fa-content(\e0e9\fe02); }
.fad.#{$fa-css-prefix}-chart-tree-map:before, .fa-duotone.#{$fa-css-prefix}-chart-tree-map:before { content: fa-content(\e0ea\fe01); }
.fad.#{$fa-css-prefix}-chart-tree-map:after, .fa-duotone.#{$fa-css-prefix}-chart-tree-map:after { content: fa-content(\e0ea\fe02); }
.fad.#{$fa-css-prefix}-chart-user:before, .fa-duotone.#{$fa-css-prefix}-chart-user:before { content: fa-content(\f6a3\fe01); }
.fad.#{$fa-css-prefix}-chart-user:after, .fa-duotone.#{$fa-css-prefix}-chart-user:after { content: fa-content(\f6a3\fe02); }
.fad.#{$fa-css-prefix}-user-chart:before, .fa-duotone.#{$fa-css-prefix}-user-chart:before { content: fa-content(\f6a3\fe01); }
.fad.#{$fa-css-prefix}-user-chart:after, .fa-duotone.#{$fa-css-prefix}-user-chart:after { content: fa-content(\f6a3\fe02); }
.fad.#{$fa-css-prefix}-chart-waterfall:before, .fa-duotone.#{$fa-css-prefix}-chart-waterfall:before { content: fa-content(\e0eb\fe01); }
.fad.#{$fa-css-prefix}-chart-waterfall:after, .fa-duotone.#{$fa-css-prefix}-chart-waterfall:after { content: fa-content(\e0eb\fe02); }
.fad.#{$fa-css-prefix}-check:before, .fa-duotone.#{$fa-css-prefix}-check:before { content: fa-content(\2713\fe01); }
.fad.#{$fa-css-prefix}-check:after, .fa-duotone.#{$fa-css-prefix}-check:after { content: fa-content(\2713\fe02); }
.fad.#{$fa-css-prefix}-check-double:before, .fa-duotone.#{$fa-css-prefix}-check-double:before { content: fa-content(\f560\fe01); }
.fad.#{$fa-css-prefix}-check-double:after, .fa-duotone.#{$fa-css-prefix}-check-double:after { content: fa-content(\f560\fe02); }
.fad.#{$fa-css-prefix}-check-to-slot:before, .fa-duotone.#{$fa-css-prefix}-check-to-slot:before { content: fa-content(\f772\fe01); }
.fad.#{$fa-css-prefix}-check-to-slot:after, .fa-duotone.#{$fa-css-prefix}-check-to-slot:after { content: fa-content(\f772\fe02); }
.fad.#{$fa-css-prefix}-vote-yea:before, .fa-duotone.#{$fa-css-prefix}-vote-yea:before { content: fa-content(\f772\fe01); }
.fad.#{$fa-css-prefix}-vote-yea:after, .fa-duotone.#{$fa-css-prefix}-vote-yea:after { content: fa-content(\f772\fe02); }
.fad.#{$fa-css-prefix}-cheese:before, .fa-duotone.#{$fa-css-prefix}-cheese:before { content: fa-content(\f7ef\fe01); }
.fad.#{$fa-css-prefix}-cheese:after, .fa-duotone.#{$fa-css-prefix}-cheese:after { content: fa-content(\f7ef\fe02); }
.fad.#{$fa-css-prefix}-cheese-swiss:before, .fa-duotone.#{$fa-css-prefix}-cheese-swiss:before { content: fa-content(\f7f0); }
.fad.#{$fa-css-prefix}-cheese-swiss:after, .fa-duotone.#{$fa-css-prefix}-cheese-swiss:after { content: fa-content(\10f7f0); }
.fad.#{$fa-css-prefix}-cherries:before, .fa-duotone.#{$fa-css-prefix}-cherries:before { content: fa-content(\e0ec\fe01); }
.fad.#{$fa-css-prefix}-cherries:after, .fa-duotone.#{$fa-css-prefix}-cherries:after { content: fa-content(\e0ec\fe02); }
.fad.#{$fa-css-prefix}-chess:before, .fa-duotone.#{$fa-css-prefix}-chess:before { content: fa-content(\f439\fe01); }
.fad.#{$fa-css-prefix}-chess:after, .fa-duotone.#{$fa-css-prefix}-chess:after { content: fa-content(\f439\fe02); }
.fad.#{$fa-css-prefix}-chess-bishop:before, .fa-duotone.#{$fa-css-prefix}-chess-bishop:before { content: fa-content(\265d\fe01); }
.fad.#{$fa-css-prefix}-chess-bishop:after, .fa-duotone.#{$fa-css-prefix}-chess-bishop:after { content: fa-content(\265d\fe02); }
.fad.#{$fa-css-prefix}-chess-bishop-piece:before, .fa-duotone.#{$fa-css-prefix}-chess-bishop-piece:before { content: fa-content(\f43b\fe01); }
.fad.#{$fa-css-prefix}-chess-bishop-piece:after, .fa-duotone.#{$fa-css-prefix}-chess-bishop-piece:after { content: fa-content(\f43b\fe02); }
.fad.#{$fa-css-prefix}-chess-bishop-alt:before, .fa-duotone.#{$fa-css-prefix}-chess-bishop-alt:before { content: fa-content(\f43b\fe01); }
.fad.#{$fa-css-prefix}-chess-bishop-alt:after, .fa-duotone.#{$fa-css-prefix}-chess-bishop-alt:after { content: fa-content(\f43b\fe02); }
.fad.#{$fa-css-prefix}-chess-board:before, .fa-duotone.#{$fa-css-prefix}-chess-board:before { content: fa-content(\f43c\fe01); }
.fad.#{$fa-css-prefix}-chess-board:after, .fa-duotone.#{$fa-css-prefix}-chess-board:after { content: fa-content(\f43c\fe02); }
.fad.#{$fa-css-prefix}-chess-clock:before, .fa-duotone.#{$fa-css-prefix}-chess-clock:before { content: fa-content(\f43d\fe01); }
.fad.#{$fa-css-prefix}-chess-clock:after, .fa-duotone.#{$fa-css-prefix}-chess-clock:after { content: fa-content(\f43d\fe02); }
.fad.#{$fa-css-prefix}-chess-clock-flip:before, .fa-duotone.#{$fa-css-prefix}-chess-clock-flip:before { content: fa-content(\f43e\fe01); }
.fad.#{$fa-css-prefix}-chess-clock-flip:after, .fa-duotone.#{$fa-css-prefix}-chess-clock-flip:after { content: fa-content(\f43e\fe02); }
.fad.#{$fa-css-prefix}-chess-clock-alt:before, .fa-duotone.#{$fa-css-prefix}-chess-clock-alt:before { content: fa-content(\f43e\fe01); }
.fad.#{$fa-css-prefix}-chess-clock-alt:after, .fa-duotone.#{$fa-css-prefix}-chess-clock-alt:after { content: fa-content(\f43e\fe02); }
.fad.#{$fa-css-prefix}-chess-king:before, .fa-duotone.#{$fa-css-prefix}-chess-king:before { content: fa-content(\265a\fe01); }
.fad.#{$fa-css-prefix}-chess-king:after, .fa-duotone.#{$fa-css-prefix}-chess-king:after { content: fa-content(\265a\fe02); }
.fad.#{$fa-css-prefix}-chess-king-piece:before, .fa-duotone.#{$fa-css-prefix}-chess-king-piece:before { content: fa-content(\f440\fe01); }
.fad.#{$fa-css-prefix}-chess-king-piece:after, .fa-duotone.#{$fa-css-prefix}-chess-king-piece:after { content: fa-content(\f440\fe02); }
.fad.#{$fa-css-prefix}-chess-king-alt:before, .fa-duotone.#{$fa-css-prefix}-chess-king-alt:before { content: fa-content(\f440\fe01); }
.fad.#{$fa-css-prefix}-chess-king-alt:after, .fa-duotone.#{$fa-css-prefix}-chess-king-alt:after { content: fa-content(\f440\fe02); }
.fad.#{$fa-css-prefix}-chess-knight:before, .fa-duotone.#{$fa-css-prefix}-chess-knight:before { content: fa-content(\265e\fe01); }
.fad.#{$fa-css-prefix}-chess-knight:after, .fa-duotone.#{$fa-css-prefix}-chess-knight:after { content: fa-content(\265e\fe02); }
.fad.#{$fa-css-prefix}-chess-knight-piece:before, .fa-duotone.#{$fa-css-prefix}-chess-knight-piece:before { content: fa-content(\f442\fe01); }
.fad.#{$fa-css-prefix}-chess-knight-piece:after, .fa-duotone.#{$fa-css-prefix}-chess-knight-piece:after { content: fa-content(\f442\fe02); }
.fad.#{$fa-css-prefix}-chess-knight-alt:before, .fa-duotone.#{$fa-css-prefix}-chess-knight-alt:before { content: fa-content(\f442\fe01); }
.fad.#{$fa-css-prefix}-chess-knight-alt:after, .fa-duotone.#{$fa-css-prefix}-chess-knight-alt:after { content: fa-content(\f442\fe02); }
.fad.#{$fa-css-prefix}-chess-pawn:before, .fa-duotone.#{$fa-css-prefix}-chess-pawn:before { content: fa-content(\265f\fe01); }
.fad.#{$fa-css-prefix}-chess-pawn:after, .fa-duotone.#{$fa-css-prefix}-chess-pawn:after { content: fa-content(\265f\fe02); }
.fad.#{$fa-css-prefix}-chess-pawn-piece:before, .fa-duotone.#{$fa-css-prefix}-chess-pawn-piece:before { content: fa-content(\f444\fe01); }
.fad.#{$fa-css-prefix}-chess-pawn-piece:after, .fa-duotone.#{$fa-css-prefix}-chess-pawn-piece:after { content: fa-content(\f444\fe02); }
.fad.#{$fa-css-prefix}-chess-pawn-alt:before, .fa-duotone.#{$fa-css-prefix}-chess-pawn-alt:before { content: fa-content(\f444\fe01); }
.fad.#{$fa-css-prefix}-chess-pawn-alt:after, .fa-duotone.#{$fa-css-prefix}-chess-pawn-alt:after { content: fa-content(\f444\fe02); }
.fad.#{$fa-css-prefix}-chess-queen:before, .fa-duotone.#{$fa-css-prefix}-chess-queen:before { content: fa-content(\265b\fe01); }
.fad.#{$fa-css-prefix}-chess-queen:after, .fa-duotone.#{$fa-css-prefix}-chess-queen:after { content: fa-content(\265b\fe02); }
.fad.#{$fa-css-prefix}-chess-queen-piece:before, .fa-duotone.#{$fa-css-prefix}-chess-queen-piece:before { content: fa-content(\f446\fe01); }
.fad.#{$fa-css-prefix}-chess-queen-piece:after, .fa-duotone.#{$fa-css-prefix}-chess-queen-piece:after { content: fa-content(\f446\fe02); }
.fad.#{$fa-css-prefix}-chess-queen-alt:before, .fa-duotone.#{$fa-css-prefix}-chess-queen-alt:before { content: fa-content(\f446\fe01); }
.fad.#{$fa-css-prefix}-chess-queen-alt:after, .fa-duotone.#{$fa-css-prefix}-chess-queen-alt:after { content: fa-content(\f446\fe02); }
.fad.#{$fa-css-prefix}-chess-rook:before, .fa-duotone.#{$fa-css-prefix}-chess-rook:before { content: fa-content(\265c\fe01); }
.fad.#{$fa-css-prefix}-chess-rook:after, .fa-duotone.#{$fa-css-prefix}-chess-rook:after { content: fa-content(\265c\fe02); }
.fad.#{$fa-css-prefix}-chess-rook-piece:before, .fa-duotone.#{$fa-css-prefix}-chess-rook-piece:before { content: fa-content(\f448\fe01); }
.fad.#{$fa-css-prefix}-chess-rook-piece:after, .fa-duotone.#{$fa-css-prefix}-chess-rook-piece:after { content: fa-content(\f448\fe02); }
.fad.#{$fa-css-prefix}-chess-rook-alt:before, .fa-duotone.#{$fa-css-prefix}-chess-rook-alt:before { content: fa-content(\f448\fe01); }
.fad.#{$fa-css-prefix}-chess-rook-alt:after, .fa-duotone.#{$fa-css-prefix}-chess-rook-alt:after { content: fa-content(\f448\fe02); }
.fad.#{$fa-css-prefix}-chevron-down:before, .fa-duotone.#{$fa-css-prefix}-chevron-down:before { content: fa-content(\f078\fe01); }
.fad.#{$fa-css-prefix}-chevron-down:after, .fa-duotone.#{$fa-css-prefix}-chevron-down:after { content: fa-content(\f078\fe02); }
.fad.#{$fa-css-prefix}-chevron-left:before, .fa-duotone.#{$fa-css-prefix}-chevron-left:before { content: fa-content(\2329\fe01); }
.fad.#{$fa-css-prefix}-chevron-left:after, .fa-duotone.#{$fa-css-prefix}-chevron-left:after { content: fa-content(\2329\fe02); }
.fad.#{$fa-css-prefix}-chevron-right:before, .fa-duotone.#{$fa-css-prefix}-chevron-right:before { content: fa-content(\232a\fe01); }
.fad.#{$fa-css-prefix}-chevron-right:after, .fa-duotone.#{$fa-css-prefix}-chevron-right:after { content: fa-content(\232a\fe02); }
.fad.#{$fa-css-prefix}-chevron-up:before, .fa-duotone.#{$fa-css-prefix}-chevron-up:before { content: fa-content(\f077\fe01); }
.fad.#{$fa-css-prefix}-chevron-up:after, .fa-duotone.#{$fa-css-prefix}-chevron-up:after { content: fa-content(\f077\fe02); }
.fad.#{$fa-css-prefix}-chevrons-down:before, .fa-duotone.#{$fa-css-prefix}-chevrons-down:before { content: fa-content(\f322\fe01); }
.fad.#{$fa-css-prefix}-chevrons-down:after, .fa-duotone.#{$fa-css-prefix}-chevrons-down:after { content: fa-content(\f322\fe02); }
.fad.#{$fa-css-prefix}-chevron-double-down:before, .fa-duotone.#{$fa-css-prefix}-chevron-double-down:before { content: fa-content(\f322\fe01); }
.fad.#{$fa-css-prefix}-chevron-double-down:after, .fa-duotone.#{$fa-css-prefix}-chevron-double-down:after { content: fa-content(\f322\fe02); }
.fad.#{$fa-css-prefix}-chevrons-left:before, .fa-duotone.#{$fa-css-prefix}-chevrons-left:before { content: fa-content(\f323\fe01); }
.fad.#{$fa-css-prefix}-chevrons-left:after, .fa-duotone.#{$fa-css-prefix}-chevrons-left:after { content: fa-content(\f323\fe02); }
.fad.#{$fa-css-prefix}-chevron-double-left:before, .fa-duotone.#{$fa-css-prefix}-chevron-double-left:before { content: fa-content(\f323\fe01); }
.fad.#{$fa-css-prefix}-chevron-double-left:after, .fa-duotone.#{$fa-css-prefix}-chevron-double-left:after { content: fa-content(\f323\fe02); }
.fad.#{$fa-css-prefix}-chevrons-right:before, .fa-duotone.#{$fa-css-prefix}-chevrons-right:before { content: fa-content(\f324\fe01); }
.fad.#{$fa-css-prefix}-chevrons-right:after, .fa-duotone.#{$fa-css-prefix}-chevrons-right:after { content: fa-content(\f324\fe02); }
.fad.#{$fa-css-prefix}-chevron-double-right:before, .fa-duotone.#{$fa-css-prefix}-chevron-double-right:before { content: fa-content(\f324\fe01); }
.fad.#{$fa-css-prefix}-chevron-double-right:after, .fa-duotone.#{$fa-css-prefix}-chevron-double-right:after { content: fa-content(\f324\fe02); }
.fad.#{$fa-css-prefix}-chevrons-up:before, .fa-duotone.#{$fa-css-prefix}-chevrons-up:before { content: fa-content(\f325\fe01); }
.fad.#{$fa-css-prefix}-chevrons-up:after, .fa-duotone.#{$fa-css-prefix}-chevrons-up:after { content: fa-content(\f325\fe02); }
.fad.#{$fa-css-prefix}-chevron-double-up:before, .fa-duotone.#{$fa-css-prefix}-chevron-double-up:before { content: fa-content(\f325\fe01); }
.fad.#{$fa-css-prefix}-chevron-double-up:after, .fa-duotone.#{$fa-css-prefix}-chevron-double-up:after { content: fa-content(\f325\fe02); }
.fad.#{$fa-css-prefix}-child:before, .fa-duotone.#{$fa-css-prefix}-child:before { content: fa-content(\f1ae\fe01); }
.fad.#{$fa-css-prefix}-child:after, .fa-duotone.#{$fa-css-prefix}-child:after { content: fa-content(\f1ae\fe02); }
.fad.#{$fa-css-prefix}-chimney:before, .fa-duotone.#{$fa-css-prefix}-chimney:before { content: fa-content(\f78b\fe01); }
.fad.#{$fa-css-prefix}-chimney:after, .fa-duotone.#{$fa-css-prefix}-chimney:after { content: fa-content(\f78b\fe02); }
.fad.#{$fa-css-prefix}-church:before, .fa-duotone.#{$fa-css-prefix}-church:before { content: fa-content(\26ea\fe01); }
.fad.#{$fa-css-prefix}-church:after, .fa-duotone.#{$fa-css-prefix}-church:after { content: fa-content(\26ea\fe02); }
.fad.#{$fa-css-prefix}-circle:before, .fa-duotone.#{$fa-css-prefix}-circle:before { content: fa-content(\25cf\fe01); }
.fad.#{$fa-css-prefix}-circle:after, .fa-duotone.#{$fa-css-prefix}-circle:after { content: fa-content(\25cf\fe02); }
.fad.#{$fa-css-prefix}-circle-0:before, .fa-duotone.#{$fa-css-prefix}-circle-0:before { content: fa-content(\e0ed\fe01); }
.fad.#{$fa-css-prefix}-circle-0:after, .fa-duotone.#{$fa-css-prefix}-circle-0:after { content: fa-content(\e0ed\fe02); }
.fad.#{$fa-css-prefix}-circle-1:before, .fa-duotone.#{$fa-css-prefix}-circle-1:before { content: fa-content(\e0ee\fe01); }
.fad.#{$fa-css-prefix}-circle-1:after, .fa-duotone.#{$fa-css-prefix}-circle-1:after { content: fa-content(\e0ee\fe02); }
.fad.#{$fa-css-prefix}-circle-2:before, .fa-duotone.#{$fa-css-prefix}-circle-2:before { content: fa-content(\e0ef\fe01); }
.fad.#{$fa-css-prefix}-circle-2:after, .fa-duotone.#{$fa-css-prefix}-circle-2:after { content: fa-content(\e0ef\fe02); }
.fad.#{$fa-css-prefix}-circle-3:before, .fa-duotone.#{$fa-css-prefix}-circle-3:before { content: fa-content(\e0f0\fe01); }
.fad.#{$fa-css-prefix}-circle-3:after, .fa-duotone.#{$fa-css-prefix}-circle-3:after { content: fa-content(\e0f0\fe02); }
.fad.#{$fa-css-prefix}-circle-4:before, .fa-duotone.#{$fa-css-prefix}-circle-4:before { content: fa-content(\e0f1\fe01); }
.fad.#{$fa-css-prefix}-circle-4:after, .fa-duotone.#{$fa-css-prefix}-circle-4:after { content: fa-content(\e0f1\fe02); }
.fad.#{$fa-css-prefix}-circle-5:before, .fa-duotone.#{$fa-css-prefix}-circle-5:before { content: fa-content(\e0f2\fe01); }
.fad.#{$fa-css-prefix}-circle-5:after, .fa-duotone.#{$fa-css-prefix}-circle-5:after { content: fa-content(\e0f2\fe02); }
.fad.#{$fa-css-prefix}-circle-6:before, .fa-duotone.#{$fa-css-prefix}-circle-6:before { content: fa-content(\e0f3\fe01); }
.fad.#{$fa-css-prefix}-circle-6:after, .fa-duotone.#{$fa-css-prefix}-circle-6:after { content: fa-content(\e0f3\fe02); }
.fad.#{$fa-css-prefix}-circle-7:before, .fa-duotone.#{$fa-css-prefix}-circle-7:before { content: fa-content(\e0f4\fe01); }
.fad.#{$fa-css-prefix}-circle-7:after, .fa-duotone.#{$fa-css-prefix}-circle-7:after { content: fa-content(\e0f4\fe02); }
.fad.#{$fa-css-prefix}-circle-8:before, .fa-duotone.#{$fa-css-prefix}-circle-8:before { content: fa-content(\e0f5\fe01); }
.fad.#{$fa-css-prefix}-circle-8:after, .fa-duotone.#{$fa-css-prefix}-circle-8:after { content: fa-content(\e0f5\fe02); }
.fad.#{$fa-css-prefix}-circle-9:before, .fa-duotone.#{$fa-css-prefix}-circle-9:before { content: fa-content(\e0f6\fe01); }
.fad.#{$fa-css-prefix}-circle-9:after, .fa-duotone.#{$fa-css-prefix}-circle-9:after { content: fa-content(\e0f6\fe02); }
.fad.#{$fa-css-prefix}-circle-a:before, .fa-duotone.#{$fa-css-prefix}-circle-a:before { content: fa-content(\e0f7\fe01); }
.fad.#{$fa-css-prefix}-circle-a:after, .fa-duotone.#{$fa-css-prefix}-circle-a:after { content: fa-content(\e0f7\fe02); }
.fad.#{$fa-css-prefix}-circle-ampersand:before, .fa-duotone.#{$fa-css-prefix}-circle-ampersand:before { content: fa-content(\e0f8\fe01); }
.fad.#{$fa-css-prefix}-circle-ampersand:after, .fa-duotone.#{$fa-css-prefix}-circle-ampersand:after { content: fa-content(\e0f8\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down:before { content: fa-content(\f0ab\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down:after { content: fa-content(\f0ab\fe02); }
.fad.#{$fa-css-prefix}-arrow-circle-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-circle-down:before { content: fa-content(\f0ab\fe01); }
.fad.#{$fa-css-prefix}-arrow-circle-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-down:after { content: fa-content(\f0ab\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-down-left:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down-left:before { content: fa-content(\e0f9\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-down-left:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down-left:after { content: fa-content(\e0f9\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-down-right:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down-right:before { content: fa-content(\e0fa\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-down-right:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down-right:after { content: fa-content(\e0fa\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-left:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-left:before { content: fa-content(\f0a8\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-left:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-left:after { content: fa-content(\f0a8\fe02); }
.fad.#{$fa-css-prefix}-arrow-circle-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-circle-left:before { content: fa-content(\f0a8\fe01); }
.fad.#{$fa-css-prefix}-arrow-circle-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-left:after { content: fa-content(\f0a8\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-right:before { content: fa-content(\f0a9\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-right:after { content: fa-content(\f0a9\fe02); }
.fad.#{$fa-css-prefix}-arrow-circle-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-circle-right:before { content: fa-content(\f0a9\fe01); }
.fad.#{$fa-css-prefix}-arrow-circle-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-right:after { content: fa-content(\f0a9\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up:before { content: fa-content(\f0aa\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up:after { content: fa-content(\f0aa\fe02); }
.fad.#{$fa-css-prefix}-arrow-circle-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-circle-up:before { content: fa-content(\f0aa\fe01); }
.fad.#{$fa-css-prefix}-arrow-circle-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-up:after { content: fa-content(\f0aa\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-up-left:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up-left:before { content: fa-content(\e0fb\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-up-left:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up-left:after { content: fa-content(\e0fb\fe02); }
.fad.#{$fa-css-prefix}-circle-arrow-up-right:before, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up-right:before { content: fa-content(\e0fc\fe01); }
.fad.#{$fa-css-prefix}-circle-arrow-up-right:after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up-right:after { content: fa-content(\e0fc\fe02); }
.fad.#{$fa-css-prefix}-circle-b:before, .fa-duotone.#{$fa-css-prefix}-circle-b:before { content: fa-content(\e0fd\fe01); }
.fad.#{$fa-css-prefix}-circle-b:after, .fa-duotone.#{$fa-css-prefix}-circle-b:after { content: fa-content(\e0fd\fe02); }
.fad.#{$fa-css-prefix}-circle-bolt:before, .fa-duotone.#{$fa-css-prefix}-circle-bolt:before { content: fa-content(\e0fe\fe01); }
.fad.#{$fa-css-prefix}-circle-bolt:after, .fa-duotone.#{$fa-css-prefix}-circle-bolt:after { content: fa-content(\e0fe\fe02); }
.fad.#{$fa-css-prefix}-circle-book-open:before, .fa-duotone.#{$fa-css-prefix}-circle-book-open:before { content: fa-content(\e0ff\fe01); }
.fad.#{$fa-css-prefix}-circle-book-open:after, .fa-duotone.#{$fa-css-prefix}-circle-book-open:after { content: fa-content(\e0ff\fe02); }
.fad.#{$fa-css-prefix}-book-circle:before, .fa-duotone.#{$fa-css-prefix}-book-circle:before { content: fa-content(\e0ff\fe01); }
.fad.#{$fa-css-prefix}-book-circle:after, .fa-duotone.#{$fa-css-prefix}-book-circle:after { content: fa-content(\e0ff\fe02); }
.fad.#{$fa-css-prefix}-circle-bookmark:before, .fa-duotone.#{$fa-css-prefix}-circle-bookmark:before { content: fa-content(\e100\fe01); }
.fad.#{$fa-css-prefix}-circle-bookmark:after, .fa-duotone.#{$fa-css-prefix}-circle-bookmark:after { content: fa-content(\e100\fe02); }
.fad.#{$fa-css-prefix}-bookmark-circle:before, .fa-duotone.#{$fa-css-prefix}-bookmark-circle:before { content: fa-content(\e100\fe01); }
.fad.#{$fa-css-prefix}-bookmark-circle:after, .fa-duotone.#{$fa-css-prefix}-bookmark-circle:after { content: fa-content(\e100\fe02); }
.fad.#{$fa-css-prefix}-circle-c:before, .fa-duotone.#{$fa-css-prefix}-circle-c:before { content: fa-content(\e101\fe01); }
.fad.#{$fa-css-prefix}-circle-c:after, .fa-duotone.#{$fa-css-prefix}-circle-c:after { content: fa-content(\e101\fe02); }
.fad.#{$fa-css-prefix}-circle-calendar:before, .fa-duotone.#{$fa-css-prefix}-circle-calendar:before { content: fa-content(\e102\fe01); }
.fad.#{$fa-css-prefix}-circle-calendar:after, .fa-duotone.#{$fa-css-prefix}-circle-calendar:after { content: fa-content(\e102\fe02); }
.fad.#{$fa-css-prefix}-calendar-circle:before, .fa-duotone.#{$fa-css-prefix}-calendar-circle:before { content: fa-content(\e102\fe01); }
.fad.#{$fa-css-prefix}-calendar-circle:after, .fa-duotone.#{$fa-css-prefix}-calendar-circle:after { content: fa-content(\e102\fe02); }
.fad.#{$fa-css-prefix}-circle-camera:before, .fa-duotone.#{$fa-css-prefix}-circle-camera:before { content: fa-content(\e103\fe01); }
.fad.#{$fa-css-prefix}-circle-camera:after, .fa-duotone.#{$fa-css-prefix}-circle-camera:after { content: fa-content(\e103\fe02); }
.fad.#{$fa-css-prefix}-camera-circle:before, .fa-duotone.#{$fa-css-prefix}-camera-circle:before { content: fa-content(\e103\fe01); }
.fad.#{$fa-css-prefix}-camera-circle:after, .fa-duotone.#{$fa-css-prefix}-camera-circle:after { content: fa-content(\e103\fe02); }
.fad.#{$fa-css-prefix}-circle-caret-down:before, .fa-duotone.#{$fa-css-prefix}-circle-caret-down:before { content: fa-content(\f32d\fe01); }
.fad.#{$fa-css-prefix}-circle-caret-down:after, .fa-duotone.#{$fa-css-prefix}-circle-caret-down:after { content: fa-content(\f32d\fe02); }
.fad.#{$fa-css-prefix}-caret-circle-down:before, .fa-duotone.#{$fa-css-prefix}-caret-circle-down:before { content: fa-content(\f32d\fe01); }
.fad.#{$fa-css-prefix}-caret-circle-down:after, .fa-duotone.#{$fa-css-prefix}-caret-circle-down:after { content: fa-content(\f32d\fe02); }
.fad.#{$fa-css-prefix}-circle-caret-left:before, .fa-duotone.#{$fa-css-prefix}-circle-caret-left:before { content: fa-content(\f32e\fe01); }
.fad.#{$fa-css-prefix}-circle-caret-left:after, .fa-duotone.#{$fa-css-prefix}-circle-caret-left:after { content: fa-content(\f32e\fe02); }
.fad.#{$fa-css-prefix}-caret-circle-left:before, .fa-duotone.#{$fa-css-prefix}-caret-circle-left:before { content: fa-content(\f32e\fe01); }
.fad.#{$fa-css-prefix}-caret-circle-left:after, .fa-duotone.#{$fa-css-prefix}-caret-circle-left:after { content: fa-content(\f32e\fe02); }
.fad.#{$fa-css-prefix}-circle-caret-right:before, .fa-duotone.#{$fa-css-prefix}-circle-caret-right:before { content: fa-content(\f330\fe01); }
.fad.#{$fa-css-prefix}-circle-caret-right:after, .fa-duotone.#{$fa-css-prefix}-circle-caret-right:after { content: fa-content(\f330\fe02); }
.fad.#{$fa-css-prefix}-caret-circle-right:before, .fa-duotone.#{$fa-css-prefix}-caret-circle-right:before { content: fa-content(\f330\fe01); }
.fad.#{$fa-css-prefix}-caret-circle-right:after, .fa-duotone.#{$fa-css-prefix}-caret-circle-right:after { content: fa-content(\f330\fe02); }
.fad.#{$fa-css-prefix}-circle-caret-up:before, .fa-duotone.#{$fa-css-prefix}-circle-caret-up:before { content: fa-content(\f331\fe01); }
.fad.#{$fa-css-prefix}-circle-caret-up:after, .fa-duotone.#{$fa-css-prefix}-circle-caret-up:after { content: fa-content(\f331\fe02); }
.fad.#{$fa-css-prefix}-caret-circle-up:before, .fa-duotone.#{$fa-css-prefix}-caret-circle-up:before { content: fa-content(\f331\fe01); }
.fad.#{$fa-css-prefix}-caret-circle-up:after, .fa-duotone.#{$fa-css-prefix}-caret-circle-up:after { content: fa-content(\f331\fe02); }
.fad.#{$fa-css-prefix}-circle-check:before, .fa-duotone.#{$fa-css-prefix}-circle-check:before { content: fa-content(\f058\fe01); }
.fad.#{$fa-css-prefix}-circle-check:after, .fa-duotone.#{$fa-css-prefix}-circle-check:after { content: fa-content(\f058\fe02); }
.fad.#{$fa-css-prefix}-check-circle:before, .fa-duotone.#{$fa-css-prefix}-check-circle:before { content: fa-content(\f058\fe01); }
.fad.#{$fa-css-prefix}-check-circle:after, .fa-duotone.#{$fa-css-prefix}-check-circle:after { content: fa-content(\f058\fe02); }
.fad.#{$fa-css-prefix}-circle-chevron-down:before, .fa-duotone.#{$fa-css-prefix}-circle-chevron-down:before { content: fa-content(\f13a\fe01); }
.fad.#{$fa-css-prefix}-circle-chevron-down:after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-down:after { content: fa-content(\f13a\fe02); }
.fad.#{$fa-css-prefix}-chevron-circle-down:before, .fa-duotone.#{$fa-css-prefix}-chevron-circle-down:before { content: fa-content(\f13a\fe01); }
.fad.#{$fa-css-prefix}-chevron-circle-down:after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-down:after { content: fa-content(\f13a\fe02); }
.fad.#{$fa-css-prefix}-circle-chevron-left:before, .fa-duotone.#{$fa-css-prefix}-circle-chevron-left:before { content: fa-content(\f137\fe01); }
.fad.#{$fa-css-prefix}-circle-chevron-left:after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-left:after { content: fa-content(\f137\fe02); }
.fad.#{$fa-css-prefix}-chevron-circle-left:before, .fa-duotone.#{$fa-css-prefix}-chevron-circle-left:before { content: fa-content(\f137\fe01); }
.fad.#{$fa-css-prefix}-chevron-circle-left:after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-left:after { content: fa-content(\f137\fe02); }
.fad.#{$fa-css-prefix}-circle-chevron-right:before, .fa-duotone.#{$fa-css-prefix}-circle-chevron-right:before { content: fa-content(\f138\fe01); }
.fad.#{$fa-css-prefix}-circle-chevron-right:after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-right:after { content: fa-content(\f138\fe02); }
.fad.#{$fa-css-prefix}-chevron-circle-right:before, .fa-duotone.#{$fa-css-prefix}-chevron-circle-right:before { content: fa-content(\f138\fe01); }
.fad.#{$fa-css-prefix}-chevron-circle-right:after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-right:after { content: fa-content(\f138\fe02); }
.fad.#{$fa-css-prefix}-circle-chevron-up:before, .fa-duotone.#{$fa-css-prefix}-circle-chevron-up:before { content: fa-content(\f139\fe01); }
.fad.#{$fa-css-prefix}-circle-chevron-up:after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-up:after { content: fa-content(\f139\fe02); }
.fad.#{$fa-css-prefix}-chevron-circle-up:before, .fa-duotone.#{$fa-css-prefix}-chevron-circle-up:before { content: fa-content(\f139\fe01); }
.fad.#{$fa-css-prefix}-chevron-circle-up:after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-up:after { content: fa-content(\f139\fe02); }
.fad.#{$fa-css-prefix}-circle-d:before, .fa-duotone.#{$fa-css-prefix}-circle-d:before { content: fa-content(\e104\fe01); }
.fad.#{$fa-css-prefix}-circle-d:after, .fa-duotone.#{$fa-css-prefix}-circle-d:after { content: fa-content(\e104\fe02); }
.fad.#{$fa-css-prefix}-circle-dashed:before, .fa-duotone.#{$fa-css-prefix}-circle-dashed:before { content: fa-content(\e105\fe01); }
.fad.#{$fa-css-prefix}-circle-dashed:after, .fa-duotone.#{$fa-css-prefix}-circle-dashed:after { content: fa-content(\e105\fe02); }
.fad.#{$fa-css-prefix}-circle-divide:before, .fa-duotone.#{$fa-css-prefix}-circle-divide:before { content: fa-content(\e106\fe01); }
.fad.#{$fa-css-prefix}-circle-divide:after, .fa-duotone.#{$fa-css-prefix}-circle-divide:after { content: fa-content(\e106\fe02); }
.fad.#{$fa-css-prefix}-circle-dollar:before, .fa-duotone.#{$fa-css-prefix}-circle-dollar:before { content: fa-content(\f2e8\fe01); }
.fad.#{$fa-css-prefix}-circle-dollar:after, .fa-duotone.#{$fa-css-prefix}-circle-dollar:after { content: fa-content(\f2e8\fe02); }
.fad.#{$fa-css-prefix}-dollar-circle:before, .fa-duotone.#{$fa-css-prefix}-dollar-circle:before { content: fa-content(\f2e8\fe01); }
.fad.#{$fa-css-prefix}-dollar-circle:after, .fa-duotone.#{$fa-css-prefix}-dollar-circle:after { content: fa-content(\f2e8\fe02); }
.fad.#{$fa-css-prefix}-usd-circle:before, .fa-duotone.#{$fa-css-prefix}-usd-circle:before { content: fa-content(\f2e8\fe01); }
.fad.#{$fa-css-prefix}-usd-circle:after, .fa-duotone.#{$fa-css-prefix}-usd-circle:after { content: fa-content(\f2e8\fe02); }
.fad.#{$fa-css-prefix}-circle-dollar-to-slot:before, .fa-duotone.#{$fa-css-prefix}-circle-dollar-to-slot:before { content: fa-content(\f4b9\fe01); }
.fad.#{$fa-css-prefix}-circle-dollar-to-slot:after, .fa-duotone.#{$fa-css-prefix}-circle-dollar-to-slot:after { content: fa-content(\f4b9\fe02); }
.fad.#{$fa-css-prefix}-donate:before, .fa-duotone.#{$fa-css-prefix}-donate:before { content: fa-content(\f4b9\fe01); }
.fad.#{$fa-css-prefix}-donate:after, .fa-duotone.#{$fa-css-prefix}-donate:after { content: fa-content(\f4b9\fe02); }
.fad.#{$fa-css-prefix}-circle-dot:before, .fa-duotone.#{$fa-css-prefix}-circle-dot:before { content: fa-content(\f192); }
.fad.#{$fa-css-prefix}-circle-dot:after, .fa-duotone.#{$fa-css-prefix}-circle-dot:after { content: fa-content(\10f192); }
.fad.#{$fa-css-prefix}-dot-circle:before, .fa-duotone.#{$fa-css-prefix}-dot-circle:before { content: fa-content(\f192); }
.fad.#{$fa-css-prefix}-dot-circle:after, .fa-duotone.#{$fa-css-prefix}-dot-circle:after { content: fa-content(\10f192); }
.fad.#{$fa-css-prefix}-circle-down:before, .fa-duotone.#{$fa-css-prefix}-circle-down:before { content: fa-content(\f358\fe01); }
.fad.#{$fa-css-prefix}-circle-down:after, .fa-duotone.#{$fa-css-prefix}-circle-down:after { content: fa-content(\f358\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-down:before { content: fa-content(\f358\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-down:after { content: fa-content(\f358\fe02); }
.fad.#{$fa-css-prefix}-circle-down-left:before, .fa-duotone.#{$fa-css-prefix}-circle-down-left:before { content: fa-content(\e107\fe01); }
.fad.#{$fa-css-prefix}-circle-down-left:after, .fa-duotone.#{$fa-css-prefix}-circle-down-left:after { content: fa-content(\e107\fe02); }
.fad.#{$fa-css-prefix}-circle-down-right:before, .fa-duotone.#{$fa-css-prefix}-circle-down-right:before { content: fa-content(\e108\fe01); }
.fad.#{$fa-css-prefix}-circle-down-right:after, .fa-duotone.#{$fa-css-prefix}-circle-down-right:after { content: fa-content(\e108\fe02); }
.fad.#{$fa-css-prefix}-circle-e:before, .fa-duotone.#{$fa-css-prefix}-circle-e:before { content: fa-content(\e109\fe01); }
.fad.#{$fa-css-prefix}-circle-e:after, .fa-duotone.#{$fa-css-prefix}-circle-e:after { content: fa-content(\e109\fe02); }
.fad.#{$fa-css-prefix}-circle-ellipsis:before, .fa-duotone.#{$fa-css-prefix}-circle-ellipsis:before { content: fa-content(\e10a\fe01); }
.fad.#{$fa-css-prefix}-circle-ellipsis:after, .fa-duotone.#{$fa-css-prefix}-circle-ellipsis:after { content: fa-content(\e10a\fe02); }
.fad.#{$fa-css-prefix}-circle-ellipsis-vertical:before, .fa-duotone.#{$fa-css-prefix}-circle-ellipsis-vertical:before { content: fa-content(\e10b\fe01); }
.fad.#{$fa-css-prefix}-circle-ellipsis-vertical:after, .fa-duotone.#{$fa-css-prefix}-circle-ellipsis-vertical:after { content: fa-content(\e10b\fe02); }
.fad.#{$fa-css-prefix}-circle-envelope:before, .fa-duotone.#{$fa-css-prefix}-circle-envelope:before { content: fa-content(\e10c\fe01); }
.fad.#{$fa-css-prefix}-circle-envelope:after, .fa-duotone.#{$fa-css-prefix}-circle-envelope:after { content: fa-content(\e10c\fe02); }
.fad.#{$fa-css-prefix}-envelope-circle:before, .fa-duotone.#{$fa-css-prefix}-envelope-circle:before { content: fa-content(\e10c\fe01); }
.fad.#{$fa-css-prefix}-envelope-circle:after, .fa-duotone.#{$fa-css-prefix}-envelope-circle:after { content: fa-content(\e10c\fe02); }
.fad.#{$fa-css-prefix}-circle-exclamation:before, .fa-duotone.#{$fa-css-prefix}-circle-exclamation:before { content: fa-content(\f06a\fe01); }
.fad.#{$fa-css-prefix}-circle-exclamation:after, .fa-duotone.#{$fa-css-prefix}-circle-exclamation:after { content: fa-content(\f06a\fe02); }
.fad.#{$fa-css-prefix}-exclamation-circle:before, .fa-duotone.#{$fa-css-prefix}-exclamation-circle:before { content: fa-content(\f06a\fe01); }
.fad.#{$fa-css-prefix}-exclamation-circle:after, .fa-duotone.#{$fa-css-prefix}-exclamation-circle:after { content: fa-content(\f06a\fe02); }
.fad.#{$fa-css-prefix}-circle-exclamation-check:before, .fa-duotone.#{$fa-css-prefix}-circle-exclamation-check:before { content: fa-content(\e10d\fe01); }
.fad.#{$fa-css-prefix}-circle-exclamation-check:after, .fa-duotone.#{$fa-css-prefix}-circle-exclamation-check:after { content: fa-content(\e10d\fe02); }
.fad.#{$fa-css-prefix}-circle-f:before, .fa-duotone.#{$fa-css-prefix}-circle-f:before { content: fa-content(\e10e\fe01); }
.fad.#{$fa-css-prefix}-circle-f:after, .fa-duotone.#{$fa-css-prefix}-circle-f:after { content: fa-content(\e10e\fe02); }
.fad.#{$fa-css-prefix}-circle-g:before, .fa-duotone.#{$fa-css-prefix}-circle-g:before { content: fa-content(\e10f\fe01); }
.fad.#{$fa-css-prefix}-circle-g:after, .fa-duotone.#{$fa-css-prefix}-circle-g:after { content: fa-content(\e10f\fe02); }
.fad.#{$fa-css-prefix}-circle-h:before, .fa-duotone.#{$fa-css-prefix}-circle-h:before { content: fa-content(\24bd\fe01); }
.fad.#{$fa-css-prefix}-circle-h:after, .fa-duotone.#{$fa-css-prefix}-circle-h:after { content: fa-content(\24bd\fe02); }
.fad.#{$fa-css-prefix}-hospital-symbol:before, .fa-duotone.#{$fa-css-prefix}-hospital-symbol:before { content: fa-content(\24bd\fe01); }
.fad.#{$fa-css-prefix}-hospital-symbol:after, .fa-duotone.#{$fa-css-prefix}-hospital-symbol:after { content: fa-content(\24bd\fe02); }
.fad.#{$fa-css-prefix}-circle-half:before, .fa-duotone.#{$fa-css-prefix}-circle-half:before { content: fa-content(\e110\fe01); }
.fad.#{$fa-css-prefix}-circle-half:after, .fa-duotone.#{$fa-css-prefix}-circle-half:after { content: fa-content(\e110\fe02); }
.fad.#{$fa-css-prefix}-circle-half-stroke:before, .fa-duotone.#{$fa-css-prefix}-circle-half-stroke:before { content: fa-content(\25d0\fe01); }
.fad.#{$fa-css-prefix}-circle-half-stroke:after, .fa-duotone.#{$fa-css-prefix}-circle-half-stroke:after { content: fa-content(\25d0\fe02); }
.fad.#{$fa-css-prefix}-adjust:before, .fa-duotone.#{$fa-css-prefix}-adjust:before { content: fa-content(\25d0\fe01); }
.fad.#{$fa-css-prefix}-adjust:after, .fa-duotone.#{$fa-css-prefix}-adjust:after { content: fa-content(\25d0\fe02); }
.fad.#{$fa-css-prefix}-circle-heart:before, .fa-duotone.#{$fa-css-prefix}-circle-heart:before { content: fa-content(\f4c7\fe01); }
.fad.#{$fa-css-prefix}-circle-heart:after, .fa-duotone.#{$fa-css-prefix}-circle-heart:after { content: fa-content(\f4c7\fe02); }
.fad.#{$fa-css-prefix}-heart-circle:before, .fa-duotone.#{$fa-css-prefix}-heart-circle:before { content: fa-content(\f4c7\fe01); }
.fad.#{$fa-css-prefix}-heart-circle:after, .fa-duotone.#{$fa-css-prefix}-heart-circle:after { content: fa-content(\f4c7\fe02); }
.fad.#{$fa-css-prefix}-circle-i:before, .fa-duotone.#{$fa-css-prefix}-circle-i:before { content: fa-content(\e111\fe01); }
.fad.#{$fa-css-prefix}-circle-i:after, .fa-duotone.#{$fa-css-prefix}-circle-i:after { content: fa-content(\e111\fe02); }
.fad.#{$fa-css-prefix}-circle-info:before, .fa-duotone.#{$fa-css-prefix}-circle-info:before { content: fa-content(\f05a\fe01); }
.fad.#{$fa-css-prefix}-circle-info:after, .fa-duotone.#{$fa-css-prefix}-circle-info:after { content: fa-content(\f05a\fe02); }
.fad.#{$fa-css-prefix}-info-circle:before, .fa-duotone.#{$fa-css-prefix}-info-circle:before { content: fa-content(\f05a\fe01); }
.fad.#{$fa-css-prefix}-info-circle:after, .fa-duotone.#{$fa-css-prefix}-info-circle:after { content: fa-content(\f05a\fe02); }
.fad.#{$fa-css-prefix}-circle-j:before, .fa-duotone.#{$fa-css-prefix}-circle-j:before { content: fa-content(\e112\fe01); }
.fad.#{$fa-css-prefix}-circle-j:after, .fa-duotone.#{$fa-css-prefix}-circle-j:after { content: fa-content(\e112\fe02); }
.fad.#{$fa-css-prefix}-circle-k:before, .fa-duotone.#{$fa-css-prefix}-circle-k:before { content: fa-content(\e113\fe01); }
.fad.#{$fa-css-prefix}-circle-k:after, .fa-duotone.#{$fa-css-prefix}-circle-k:after { content: fa-content(\e113\fe02); }
.fad.#{$fa-css-prefix}-circle-l:before, .fa-duotone.#{$fa-css-prefix}-circle-l:before { content: fa-content(\e114\fe01); }
.fad.#{$fa-css-prefix}-circle-l:after, .fa-duotone.#{$fa-css-prefix}-circle-l:after { content: fa-content(\e114\fe02); }
.fad.#{$fa-css-prefix}-circle-left:before, .fa-duotone.#{$fa-css-prefix}-circle-left:before { content: fa-content(\f359\fe01); }
.fad.#{$fa-css-prefix}-circle-left:after, .fa-duotone.#{$fa-css-prefix}-circle-left:after { content: fa-content(\f359\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-left:before { content: fa-content(\f359\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-left:after { content: fa-content(\f359\fe02); }
.fad.#{$fa-css-prefix}-circle-location-arrow:before, .fa-duotone.#{$fa-css-prefix}-circle-location-arrow:before { content: fa-content(\f602\fe01); }
.fad.#{$fa-css-prefix}-circle-location-arrow:after, .fa-duotone.#{$fa-css-prefix}-circle-location-arrow:after { content: fa-content(\f602\fe02); }
.fad.#{$fa-css-prefix}-location-circle:before, .fa-duotone.#{$fa-css-prefix}-location-circle:before { content: fa-content(\f602\fe01); }
.fad.#{$fa-css-prefix}-location-circle:after, .fa-duotone.#{$fa-css-prefix}-location-circle:after { content: fa-content(\f602\fe02); }
.fad.#{$fa-css-prefix}-circle-m:before, .fa-duotone.#{$fa-css-prefix}-circle-m:before { content: fa-content(\e115\fe01); }
.fad.#{$fa-css-prefix}-circle-m:after, .fa-duotone.#{$fa-css-prefix}-circle-m:after { content: fa-content(\e115\fe02); }
.fad.#{$fa-css-prefix}-circle-microphone:before, .fa-duotone.#{$fa-css-prefix}-circle-microphone:before { content: fa-content(\e116\fe01); }
.fad.#{$fa-css-prefix}-circle-microphone:after, .fa-duotone.#{$fa-css-prefix}-circle-microphone:after { content: fa-content(\e116\fe02); }
.fad.#{$fa-css-prefix}-microphone-circle:before, .fa-duotone.#{$fa-css-prefix}-microphone-circle:before { content: fa-content(\e116\fe01); }
.fad.#{$fa-css-prefix}-microphone-circle:after, .fa-duotone.#{$fa-css-prefix}-microphone-circle:after { content: fa-content(\e116\fe02); }
.fad.#{$fa-css-prefix}-circle-microphone-lines:before, .fa-duotone.#{$fa-css-prefix}-circle-microphone-lines:before { content: fa-content(\e117\fe01); }
.fad.#{$fa-css-prefix}-circle-microphone-lines:after, .fa-duotone.#{$fa-css-prefix}-circle-microphone-lines:after { content: fa-content(\e117\fe02); }
.fad.#{$fa-css-prefix}-microphone-circle-alt:before, .fa-duotone.#{$fa-css-prefix}-microphone-circle-alt:before { content: fa-content(\e117\fe01); }
.fad.#{$fa-css-prefix}-microphone-circle-alt:after, .fa-duotone.#{$fa-css-prefix}-microphone-circle-alt:after { content: fa-content(\e117\fe02); }
.fad.#{$fa-css-prefix}-circle-minus:before, .fa-duotone.#{$fa-css-prefix}-circle-minus:before { content: fa-content(\f056\fe01); }
.fad.#{$fa-css-prefix}-circle-minus:after, .fa-duotone.#{$fa-css-prefix}-circle-minus:after { content: fa-content(\f056\fe02); }
.fad.#{$fa-css-prefix}-minus-circle:before, .fa-duotone.#{$fa-css-prefix}-minus-circle:before { content: fa-content(\f056\fe01); }
.fad.#{$fa-css-prefix}-minus-circle:after, .fa-duotone.#{$fa-css-prefix}-minus-circle:after { content: fa-content(\f056\fe02); }
.fad.#{$fa-css-prefix}-circle-n:before, .fa-duotone.#{$fa-css-prefix}-circle-n:before { content: fa-content(\e118\fe01); }
.fad.#{$fa-css-prefix}-circle-n:after, .fa-duotone.#{$fa-css-prefix}-circle-n:after { content: fa-content(\e118\fe02); }
.fad.#{$fa-css-prefix}-circle-notch:before, .fa-duotone.#{$fa-css-prefix}-circle-notch:before { content: fa-content(\f1ce\fe01); }
.fad.#{$fa-css-prefix}-circle-notch:after, .fa-duotone.#{$fa-css-prefix}-circle-notch:after { content: fa-content(\f1ce\fe02); }
.fad.#{$fa-css-prefix}-circle-o:before, .fa-duotone.#{$fa-css-prefix}-circle-o:before { content: fa-content(\e119\fe01); }
.fad.#{$fa-css-prefix}-circle-o:after, .fa-duotone.#{$fa-css-prefix}-circle-o:after { content: fa-content(\e119\fe02); }
.fad.#{$fa-css-prefix}-circle-p:before, .fa-duotone.#{$fa-css-prefix}-circle-p:before { content: fa-content(\e11a\fe01); }
.fad.#{$fa-css-prefix}-circle-p:after, .fa-duotone.#{$fa-css-prefix}-circle-p:after { content: fa-content(\e11a\fe02); }
.fad.#{$fa-css-prefix}-circle-parking:before, .fa-duotone.#{$fa-css-prefix}-circle-parking:before { content: fa-content(\f615\fe01); }
.fad.#{$fa-css-prefix}-circle-parking:after, .fa-duotone.#{$fa-css-prefix}-circle-parking:after { content: fa-content(\f615\fe02); }
.fad.#{$fa-css-prefix}-parking-circle:before, .fa-duotone.#{$fa-css-prefix}-parking-circle:before { content: fa-content(\f615\fe01); }
.fad.#{$fa-css-prefix}-parking-circle:after, .fa-duotone.#{$fa-css-prefix}-parking-circle:after { content: fa-content(\f615\fe02); }
.fad.#{$fa-css-prefix}-circle-pause:before, .fa-duotone.#{$fa-css-prefix}-circle-pause:before { content: fa-content(\f28b\fe01); }
.fad.#{$fa-css-prefix}-circle-pause:after, .fa-duotone.#{$fa-css-prefix}-circle-pause:after { content: fa-content(\f28b\fe02); }
.fad.#{$fa-css-prefix}-pause-circle:before, .fa-duotone.#{$fa-css-prefix}-pause-circle:before { content: fa-content(\f28b\fe01); }
.fad.#{$fa-css-prefix}-pause-circle:after, .fa-duotone.#{$fa-css-prefix}-pause-circle:after { content: fa-content(\f28b\fe02); }
.fad.#{$fa-css-prefix}-circle-phone:before, .fa-duotone.#{$fa-css-prefix}-circle-phone:before { content: fa-content(\e11b\fe01); }
.fad.#{$fa-css-prefix}-circle-phone:after, .fa-duotone.#{$fa-css-prefix}-circle-phone:after { content: fa-content(\e11b\fe02); }
.fad.#{$fa-css-prefix}-phone-circle:before, .fa-duotone.#{$fa-css-prefix}-phone-circle:before { content: fa-content(\e11b\fe01); }
.fad.#{$fa-css-prefix}-phone-circle:after, .fa-duotone.#{$fa-css-prefix}-phone-circle:after { content: fa-content(\e11b\fe02); }
.fad.#{$fa-css-prefix}-circle-phone-flip:before, .fa-duotone.#{$fa-css-prefix}-circle-phone-flip:before { content: fa-content(\e11c\fe01); }
.fad.#{$fa-css-prefix}-circle-phone-flip:after, .fa-duotone.#{$fa-css-prefix}-circle-phone-flip:after { content: fa-content(\e11c\fe02); }
.fad.#{$fa-css-prefix}-phone-circle-alt:before, .fa-duotone.#{$fa-css-prefix}-phone-circle-alt:before { content: fa-content(\e11c\fe01); }
.fad.#{$fa-css-prefix}-phone-circle-alt:after, .fa-duotone.#{$fa-css-prefix}-phone-circle-alt:after { content: fa-content(\e11c\fe02); }
.fad.#{$fa-css-prefix}-circle-phone-hangup:before, .fa-duotone.#{$fa-css-prefix}-circle-phone-hangup:before { content: fa-content(\e11d\fe01); }
.fad.#{$fa-css-prefix}-circle-phone-hangup:after, .fa-duotone.#{$fa-css-prefix}-circle-phone-hangup:after { content: fa-content(\e11d\fe02); }
.fad.#{$fa-css-prefix}-phone-circle-down:before, .fa-duotone.#{$fa-css-prefix}-phone-circle-down:before { content: fa-content(\e11d\fe01); }
.fad.#{$fa-css-prefix}-phone-circle-down:after, .fa-duotone.#{$fa-css-prefix}-phone-circle-down:after { content: fa-content(\e11d\fe02); }
.fad.#{$fa-css-prefix}-circle-play:before, .fa-duotone.#{$fa-css-prefix}-circle-play:before { content: fa-content(\f144\fe01); }
.fad.#{$fa-css-prefix}-circle-play:after, .fa-duotone.#{$fa-css-prefix}-circle-play:after { content: fa-content(\f144\fe02); }
.fad.#{$fa-css-prefix}-play-circle:before, .fa-duotone.#{$fa-css-prefix}-play-circle:before { content: fa-content(\f144\fe01); }
.fad.#{$fa-css-prefix}-play-circle:after, .fa-duotone.#{$fa-css-prefix}-play-circle:after { content: fa-content(\f144\fe02); }
.fad.#{$fa-css-prefix}-circle-plus:before, .fa-duotone.#{$fa-css-prefix}-circle-plus:before { content: fa-content(\f055\fe01); }
.fad.#{$fa-css-prefix}-circle-plus:after, .fa-duotone.#{$fa-css-prefix}-circle-plus:after { content: fa-content(\f055\fe02); }
.fad.#{$fa-css-prefix}-plus-circle:before, .fa-duotone.#{$fa-css-prefix}-plus-circle:before { content: fa-content(\f055\fe01); }
.fad.#{$fa-css-prefix}-plus-circle:after, .fa-duotone.#{$fa-css-prefix}-plus-circle:after { content: fa-content(\f055\fe02); }
.fad.#{$fa-css-prefix}-circle-q:before, .fa-duotone.#{$fa-css-prefix}-circle-q:before { content: fa-content(\e11e\fe01); }
.fad.#{$fa-css-prefix}-circle-q:after, .fa-duotone.#{$fa-css-prefix}-circle-q:after { content: fa-content(\e11e\fe02); }
.fad.#{$fa-css-prefix}-circle-quarter:before, .fa-duotone.#{$fa-css-prefix}-circle-quarter:before { content: fa-content(\e11f\fe01); }
.fad.#{$fa-css-prefix}-circle-quarter:after, .fa-duotone.#{$fa-css-prefix}-circle-quarter:after { content: fa-content(\e11f\fe02); }
.fad.#{$fa-css-prefix}-circle-question:before, .fa-duotone.#{$fa-css-prefix}-circle-question:before { content: fa-content(\f059\fe01); }
.fad.#{$fa-css-prefix}-circle-question:after, .fa-duotone.#{$fa-css-prefix}-circle-question:after { content: fa-content(\f059\fe02); }
.fad.#{$fa-css-prefix}-question-circle:before, .fa-duotone.#{$fa-css-prefix}-question-circle:before { content: fa-content(\f059\fe01); }
.fad.#{$fa-css-prefix}-question-circle:after, .fa-duotone.#{$fa-css-prefix}-question-circle:after { content: fa-content(\f059\fe02); }
.fad.#{$fa-css-prefix}-circle-r:before, .fa-duotone.#{$fa-css-prefix}-circle-r:before { content: fa-content(\e120\fe01); }
.fad.#{$fa-css-prefix}-circle-r:after, .fa-duotone.#{$fa-css-prefix}-circle-r:after { content: fa-content(\e120\fe02); }
.fad.#{$fa-css-prefix}-circle-radiation:before, .fa-duotone.#{$fa-css-prefix}-circle-radiation:before { content: fa-content(\2622\fe01); }
.fad.#{$fa-css-prefix}-circle-radiation:after, .fa-duotone.#{$fa-css-prefix}-circle-radiation:after { content: fa-content(\2622\fe02); }
.fad.#{$fa-css-prefix}-radiation-alt:before, .fa-duotone.#{$fa-css-prefix}-radiation-alt:before { content: fa-content(\2622\fe01); }
.fad.#{$fa-css-prefix}-radiation-alt:after, .fa-duotone.#{$fa-css-prefix}-radiation-alt:after { content: fa-content(\2622\fe02); }
.fad.#{$fa-css-prefix}-circle-right:before, .fa-duotone.#{$fa-css-prefix}-circle-right:before { content: fa-content(\f35a\fe01); }
.fad.#{$fa-css-prefix}-circle-right:after, .fa-duotone.#{$fa-css-prefix}-circle-right:after { content: fa-content(\f35a\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-right:before { content: fa-content(\f35a\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-right:after { content: fa-content(\f35a\fe02); }
.fad.#{$fa-css-prefix}-circle-s:before, .fa-duotone.#{$fa-css-prefix}-circle-s:before { content: fa-content(\e121\fe01); }
.fad.#{$fa-css-prefix}-circle-s:after, .fa-duotone.#{$fa-css-prefix}-circle-s:after { content: fa-content(\e121\fe02); }
.fad.#{$fa-css-prefix}-circle-small:before, .fa-duotone.#{$fa-css-prefix}-circle-small:before { content: fa-content(\e122\fe01); }
.fad.#{$fa-css-prefix}-circle-small:after, .fa-duotone.#{$fa-css-prefix}-circle-small:after { content: fa-content(\e122\fe02); }
.fad.#{$fa-css-prefix}-circle-sort:before, .fa-duotone.#{$fa-css-prefix}-circle-sort:before { content: fa-content(\e030\fe01); }
.fad.#{$fa-css-prefix}-circle-sort:after, .fa-duotone.#{$fa-css-prefix}-circle-sort:after { content: fa-content(\e030\fe02); }
.fad.#{$fa-css-prefix}-sort-circle:before, .fa-duotone.#{$fa-css-prefix}-sort-circle:before { content: fa-content(\e030\fe01); }
.fad.#{$fa-css-prefix}-sort-circle:after, .fa-duotone.#{$fa-css-prefix}-sort-circle:after { content: fa-content(\e030\fe02); }
.fad.#{$fa-css-prefix}-circle-sort-down:before, .fa-duotone.#{$fa-css-prefix}-circle-sort-down:before { content: fa-content(\e031\fe01); }
.fad.#{$fa-css-prefix}-circle-sort-down:after, .fa-duotone.#{$fa-css-prefix}-circle-sort-down:after { content: fa-content(\e031\fe02); }
.fad.#{$fa-css-prefix}-sort-circle-down:before, .fa-duotone.#{$fa-css-prefix}-sort-circle-down:before { content: fa-content(\e031\fe01); }
.fad.#{$fa-css-prefix}-sort-circle-down:after, .fa-duotone.#{$fa-css-prefix}-sort-circle-down:after { content: fa-content(\e031\fe02); }
.fad.#{$fa-css-prefix}-circle-sort-up:before, .fa-duotone.#{$fa-css-prefix}-circle-sort-up:before { content: fa-content(\e032\fe01); }
.fad.#{$fa-css-prefix}-circle-sort-up:after, .fa-duotone.#{$fa-css-prefix}-circle-sort-up:after { content: fa-content(\e032\fe02); }
.fad.#{$fa-css-prefix}-sort-circle-up:before, .fa-duotone.#{$fa-css-prefix}-sort-circle-up:before { content: fa-content(\e032\fe01); }
.fad.#{$fa-css-prefix}-sort-circle-up:after, .fa-duotone.#{$fa-css-prefix}-sort-circle-up:after { content: fa-content(\e032\fe02); }
.fad.#{$fa-css-prefix}-circle-star:before, .fa-duotone.#{$fa-css-prefix}-circle-star:before { content: fa-content(\e123\fe01); }
.fad.#{$fa-css-prefix}-circle-star:after, .fa-duotone.#{$fa-css-prefix}-circle-star:after { content: fa-content(\e123\fe02); }
.fad.#{$fa-css-prefix}-star-circle:before, .fa-duotone.#{$fa-css-prefix}-star-circle:before { content: fa-content(\e123\fe01); }
.fad.#{$fa-css-prefix}-star-circle:after, .fa-duotone.#{$fa-css-prefix}-star-circle:after { content: fa-content(\e123\fe02); }
.fad.#{$fa-css-prefix}-circle-stop:before, .fa-duotone.#{$fa-css-prefix}-circle-stop:before { content: fa-content(\f28d\fe01); }
.fad.#{$fa-css-prefix}-circle-stop:after, .fa-duotone.#{$fa-css-prefix}-circle-stop:after { content: fa-content(\f28d\fe02); }
.fad.#{$fa-css-prefix}-stop-circle:before, .fa-duotone.#{$fa-css-prefix}-stop-circle:before { content: fa-content(\f28d\fe01); }
.fad.#{$fa-css-prefix}-stop-circle:after, .fa-duotone.#{$fa-css-prefix}-stop-circle:after { content: fa-content(\f28d\fe02); }
.fad.#{$fa-css-prefix}-circle-t:before, .fa-duotone.#{$fa-css-prefix}-circle-t:before { content: fa-content(\e124\fe01); }
.fad.#{$fa-css-prefix}-circle-t:after, .fa-duotone.#{$fa-css-prefix}-circle-t:after { content: fa-content(\e124\fe02); }
.fad.#{$fa-css-prefix}-circle-three-quarters:before, .fa-duotone.#{$fa-css-prefix}-circle-three-quarters:before { content: fa-content(\e125\fe01); }
.fad.#{$fa-css-prefix}-circle-three-quarters:after, .fa-duotone.#{$fa-css-prefix}-circle-three-quarters:after { content: fa-content(\e125\fe02); }
.fad.#{$fa-css-prefix}-circle-trash:before, .fa-duotone.#{$fa-css-prefix}-circle-trash:before { content: fa-content(\e126\fe01); }
.fad.#{$fa-css-prefix}-circle-trash:after, .fa-duotone.#{$fa-css-prefix}-circle-trash:after { content: fa-content(\e126\fe02); }
.fad.#{$fa-css-prefix}-trash-circle:before, .fa-duotone.#{$fa-css-prefix}-trash-circle:before { content: fa-content(\e126\fe01); }
.fad.#{$fa-css-prefix}-trash-circle:after, .fa-duotone.#{$fa-css-prefix}-trash-circle:after { content: fa-content(\e126\fe02); }
.fad.#{$fa-css-prefix}-circle-u:before, .fa-duotone.#{$fa-css-prefix}-circle-u:before { content: fa-content(\e127\fe01); }
.fad.#{$fa-css-prefix}-circle-u:after, .fa-duotone.#{$fa-css-prefix}-circle-u:after { content: fa-content(\e127\fe02); }
.fad.#{$fa-css-prefix}-circle-up:before, .fa-duotone.#{$fa-css-prefix}-circle-up:before { content: fa-content(\f35b\fe01); }
.fad.#{$fa-css-prefix}-circle-up:after, .fa-duotone.#{$fa-css-prefix}-circle-up:after { content: fa-content(\f35b\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-up:before { content: fa-content(\f35b\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-up:after { content: fa-content(\f35b\fe02); }
.fad.#{$fa-css-prefix}-circle-up-left:before, .fa-duotone.#{$fa-css-prefix}-circle-up-left:before { content: fa-content(\e128\fe01); }
.fad.#{$fa-css-prefix}-circle-up-left:after, .fa-duotone.#{$fa-css-prefix}-circle-up-left:after { content: fa-content(\e128\fe02); }
.fad.#{$fa-css-prefix}-circle-up-right:before, .fa-duotone.#{$fa-css-prefix}-circle-up-right:before { content: fa-content(\e129\fe01); }
.fad.#{$fa-css-prefix}-circle-up-right:after, .fa-duotone.#{$fa-css-prefix}-circle-up-right:after { content: fa-content(\e129\fe02); }
.fad.#{$fa-css-prefix}-circle-user:before, .fa-duotone.#{$fa-css-prefix}-circle-user:before { content: fa-content(\f2bd\fe01); }
.fad.#{$fa-css-prefix}-circle-user:after, .fa-duotone.#{$fa-css-prefix}-circle-user:after { content: fa-content(\f2bd\fe02); }
.fad.#{$fa-css-prefix}-user-circle:before, .fa-duotone.#{$fa-css-prefix}-user-circle:before { content: fa-content(\f2bd\fe01); }
.fad.#{$fa-css-prefix}-user-circle:after, .fa-duotone.#{$fa-css-prefix}-user-circle:after { content: fa-content(\f2bd\fe02); }
.fad.#{$fa-css-prefix}-circle-v:before, .fa-duotone.#{$fa-css-prefix}-circle-v:before { content: fa-content(\e12a\fe01); }
.fad.#{$fa-css-prefix}-circle-v:after, .fa-duotone.#{$fa-css-prefix}-circle-v:after { content: fa-content(\e12a\fe02); }
.fad.#{$fa-css-prefix}-circle-video:before, .fa-duotone.#{$fa-css-prefix}-circle-video:before { content: fa-content(\e12b\fe01); }
.fad.#{$fa-css-prefix}-circle-video:after, .fa-duotone.#{$fa-css-prefix}-circle-video:after { content: fa-content(\e12b\fe02); }
.fad.#{$fa-css-prefix}-video-circle:before, .fa-duotone.#{$fa-css-prefix}-video-circle:before { content: fa-content(\e12b\fe01); }
.fad.#{$fa-css-prefix}-video-circle:after, .fa-duotone.#{$fa-css-prefix}-video-circle:after { content: fa-content(\e12b\fe02); }
.fad.#{$fa-css-prefix}-circle-w:before, .fa-duotone.#{$fa-css-prefix}-circle-w:before { content: fa-content(\e12c\fe01); }
.fad.#{$fa-css-prefix}-circle-w:after, .fa-duotone.#{$fa-css-prefix}-circle-w:after { content: fa-content(\e12c\fe02); }
.fad.#{$fa-css-prefix}-circle-waveform-lines:before, .fa-duotone.#{$fa-css-prefix}-circle-waveform-lines:before { content: fa-content(\e12d\fe01); }
.fad.#{$fa-css-prefix}-circle-waveform-lines:after, .fa-duotone.#{$fa-css-prefix}-circle-waveform-lines:after { content: fa-content(\e12d\fe02); }
.fad.#{$fa-css-prefix}-waveform-circle:before, .fa-duotone.#{$fa-css-prefix}-waveform-circle:before { content: fa-content(\e12d\fe01); }
.fad.#{$fa-css-prefix}-waveform-circle:after, .fa-duotone.#{$fa-css-prefix}-waveform-circle:after { content: fa-content(\e12d\fe02); }
.fad.#{$fa-css-prefix}-circle-x:before, .fa-duotone.#{$fa-css-prefix}-circle-x:before { content: fa-content(\e12e\fe01); }
.fad.#{$fa-css-prefix}-circle-x:after, .fa-duotone.#{$fa-css-prefix}-circle-x:after { content: fa-content(\e12e\fe02); }
.fad.#{$fa-css-prefix}-circle-xmark:before, .fa-duotone.#{$fa-css-prefix}-circle-xmark:before { content: fa-content(\f057\fe01); }
.fad.#{$fa-css-prefix}-circle-xmark:after, .fa-duotone.#{$fa-css-prefix}-circle-xmark:after { content: fa-content(\f057\fe02); }
.fad.#{$fa-css-prefix}-times-circle:before, .fa-duotone.#{$fa-css-prefix}-times-circle:before { content: fa-content(\f057\fe01); }
.fad.#{$fa-css-prefix}-times-circle:after, .fa-duotone.#{$fa-css-prefix}-times-circle:after { content: fa-content(\f057\fe02); }
.fad.#{$fa-css-prefix}-xmark-circle:before, .fa-duotone.#{$fa-css-prefix}-xmark-circle:before { content: fa-content(\f057\fe01); }
.fad.#{$fa-css-prefix}-xmark-circle:after, .fa-duotone.#{$fa-css-prefix}-xmark-circle:after { content: fa-content(\f057\fe02); }
.fad.#{$fa-css-prefix}-circle-y:before, .fa-duotone.#{$fa-css-prefix}-circle-y:before { content: fa-content(\e12f\fe01); }
.fad.#{$fa-css-prefix}-circle-y:after, .fa-duotone.#{$fa-css-prefix}-circle-y:after { content: fa-content(\e12f\fe02); }
.fad.#{$fa-css-prefix}-circle-z:before, .fa-duotone.#{$fa-css-prefix}-circle-z:before { content: fa-content(\e130\fe01); }
.fad.#{$fa-css-prefix}-circle-z:after, .fa-duotone.#{$fa-css-prefix}-circle-z:after { content: fa-content(\e130\fe02); }
.fad.#{$fa-css-prefix}-citrus:before, .fa-duotone.#{$fa-css-prefix}-citrus:before { content: fa-content(\e2f4\fe01); }
.fad.#{$fa-css-prefix}-citrus:after, .fa-duotone.#{$fa-css-prefix}-citrus:after { content: fa-content(\e2f4\fe02); }
.fad.#{$fa-css-prefix}-citrus-slice:before, .fa-duotone.#{$fa-css-prefix}-citrus-slice:before { content: fa-content(\e2f5\fe01); }
.fad.#{$fa-css-prefix}-citrus-slice:after, .fa-duotone.#{$fa-css-prefix}-citrus-slice:after { content: fa-content(\e2f5\fe02); }
.fad.#{$fa-css-prefix}-city:before, .fa-duotone.#{$fa-css-prefix}-city:before { content: fa-content(\f64f); }
.fad.#{$fa-css-prefix}-city:after, .fa-duotone.#{$fa-css-prefix}-city:after { content: fa-content(\10f64f); }
.fad.#{$fa-css-prefix}-clapperboard:before, .fa-duotone.#{$fa-css-prefix}-clapperboard:before { content: fa-content(\e131\fe01); }
.fad.#{$fa-css-prefix}-clapperboard:after, .fa-duotone.#{$fa-css-prefix}-clapperboard:after { content: fa-content(\e131\fe02); }
.fad.#{$fa-css-prefix}-clapperboard-play:before, .fa-duotone.#{$fa-css-prefix}-clapperboard-play:before { content: fa-content(\e132\fe01); }
.fad.#{$fa-css-prefix}-clapperboard-play:after, .fa-duotone.#{$fa-css-prefix}-clapperboard-play:after { content: fa-content(\e132\fe02); }
.fad.#{$fa-css-prefix}-clarinet:before, .fa-duotone.#{$fa-css-prefix}-clarinet:before { content: fa-content(\f8ad\fe01); }
.fad.#{$fa-css-prefix}-clarinet:after, .fa-duotone.#{$fa-css-prefix}-clarinet:after { content: fa-content(\f8ad\fe02); }
.fad.#{$fa-css-prefix}-claw-marks:before, .fa-duotone.#{$fa-css-prefix}-claw-marks:before { content: fa-content(\f6c2\fe01); }
.fad.#{$fa-css-prefix}-claw-marks:after, .fa-duotone.#{$fa-css-prefix}-claw-marks:after { content: fa-content(\f6c2\fe02); }
.fad.#{$fa-css-prefix}-clipboard:before, .fa-duotone.#{$fa-css-prefix}-clipboard:before { content: fa-content(\f328); }
.fad.#{$fa-css-prefix}-clipboard:after, .fa-duotone.#{$fa-css-prefix}-clipboard:after { content: fa-content(\10f328); }
.fad.#{$fa-css-prefix}-clipboard-check:before, .fa-duotone.#{$fa-css-prefix}-clipboard-check:before { content: fa-content(\f46c\fe01); }
.fad.#{$fa-css-prefix}-clipboard-check:after, .fa-duotone.#{$fa-css-prefix}-clipboard-check:after { content: fa-content(\f46c\fe02); }
.fad.#{$fa-css-prefix}-clipboard-list:before, .fa-duotone.#{$fa-css-prefix}-clipboard-list:before { content: fa-content(\f46d\fe01); }
.fad.#{$fa-css-prefix}-clipboard-list:after, .fa-duotone.#{$fa-css-prefix}-clipboard-list:after { content: fa-content(\f46d\fe02); }
.fad.#{$fa-css-prefix}-clipboard-list-check:before, .fa-duotone.#{$fa-css-prefix}-clipboard-list-check:before { content: fa-content(\f737\fe01); }
.fad.#{$fa-css-prefix}-clipboard-list-check:after, .fa-duotone.#{$fa-css-prefix}-clipboard-list-check:after { content: fa-content(\f737\fe02); }
.fad.#{$fa-css-prefix}-clipboard-medical:before, .fa-duotone.#{$fa-css-prefix}-clipboard-medical:before { content: fa-content(\e133\fe01); }
.fad.#{$fa-css-prefix}-clipboard-medical:after, .fa-duotone.#{$fa-css-prefix}-clipboard-medical:after { content: fa-content(\e133\fe02); }
.fad.#{$fa-css-prefix}-clipboard-prescription:before, .fa-duotone.#{$fa-css-prefix}-clipboard-prescription:before { content: fa-content(\f5e8\fe01); }
.fad.#{$fa-css-prefix}-clipboard-prescription:after, .fa-duotone.#{$fa-css-prefix}-clipboard-prescription:after { content: fa-content(\f5e8\fe02); }
.fad.#{$fa-css-prefix}-clipboard-user:before, .fa-duotone.#{$fa-css-prefix}-clipboard-user:before { content: fa-content(\f7f3\fe01); }
.fad.#{$fa-css-prefix}-clipboard-user:after, .fa-duotone.#{$fa-css-prefix}-clipboard-user:after { content: fa-content(\f7f3\fe02); }
.fad.#{$fa-css-prefix}-clock:before, .fa-duotone.#{$fa-css-prefix}-clock:before { content: fa-content(\f017); }
.fad.#{$fa-css-prefix}-clock:after, .fa-duotone.#{$fa-css-prefix}-clock:after { content: fa-content(\10f017); }
.fad.#{$fa-css-prefix}-clock-four:before, .fa-duotone.#{$fa-css-prefix}-clock-four:before { content: fa-content(\f017); }
.fad.#{$fa-css-prefix}-clock-four:after, .fa-duotone.#{$fa-css-prefix}-clock-four:after { content: fa-content(\10f017); }
.fad.#{$fa-css-prefix}-clock-desk:before, .fa-duotone.#{$fa-css-prefix}-clock-desk:before { content: fa-content(\e134\fe01); }
.fad.#{$fa-css-prefix}-clock-desk:after, .fa-duotone.#{$fa-css-prefix}-clock-desk:after { content: fa-content(\e134\fe02); }
.fad.#{$fa-css-prefix}-clock-eight:before, .fa-duotone.#{$fa-css-prefix}-clock-eight:before { content: fa-content(\e345\fe01); }
.fad.#{$fa-css-prefix}-clock-eight:after, .fa-duotone.#{$fa-css-prefix}-clock-eight:after { content: fa-content(\e345\fe02); }
.fad.#{$fa-css-prefix}-clock-eight-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-eight-thirty:before { content: fa-content(\e346\fe01); }
.fad.#{$fa-css-prefix}-clock-eight-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-eight-thirty:after { content: fa-content(\e346\fe02); }
.fad.#{$fa-css-prefix}-clock-eleven:before, .fa-duotone.#{$fa-css-prefix}-clock-eleven:before { content: fa-content(\e347\fe01); }
.fad.#{$fa-css-prefix}-clock-eleven:after, .fa-duotone.#{$fa-css-prefix}-clock-eleven:after { content: fa-content(\e347\fe02); }
.fad.#{$fa-css-prefix}-clock-eleven-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-eleven-thirty:before { content: fa-content(\e348\fe01); }
.fad.#{$fa-css-prefix}-clock-eleven-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-eleven-thirty:after { content: fa-content(\e348\fe02); }
.fad.#{$fa-css-prefix}-clock-five:before, .fa-duotone.#{$fa-css-prefix}-clock-five:before { content: fa-content(\e349\fe01); }
.fad.#{$fa-css-prefix}-clock-five:after, .fa-duotone.#{$fa-css-prefix}-clock-five:after { content: fa-content(\e349\fe02); }
.fad.#{$fa-css-prefix}-clock-five-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-five-thirty:before { content: fa-content(\e34a\fe01); }
.fad.#{$fa-css-prefix}-clock-five-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-five-thirty:after { content: fa-content(\e34a\fe02); }
.fad.#{$fa-css-prefix}-clock-four-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-four-thirty:before { content: fa-content(\e34b\fe01); }
.fad.#{$fa-css-prefix}-clock-four-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-four-thirty:after { content: fa-content(\e34b\fe02); }
.fad.#{$fa-css-prefix}-clock-nine:before, .fa-duotone.#{$fa-css-prefix}-clock-nine:before { content: fa-content(\e34c\fe01); }
.fad.#{$fa-css-prefix}-clock-nine:after, .fa-duotone.#{$fa-css-prefix}-clock-nine:after { content: fa-content(\e34c\fe02); }
.fad.#{$fa-css-prefix}-clock-nine-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-nine-thirty:before { content: fa-content(\e34d\fe01); }
.fad.#{$fa-css-prefix}-clock-nine-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-nine-thirty:after { content: fa-content(\e34d\fe02); }
.fad.#{$fa-css-prefix}-clock-one:before, .fa-duotone.#{$fa-css-prefix}-clock-one:before { content: fa-content(\e34e\fe01); }
.fad.#{$fa-css-prefix}-clock-one:after, .fa-duotone.#{$fa-css-prefix}-clock-one:after { content: fa-content(\e34e\fe02); }
.fad.#{$fa-css-prefix}-clock-one-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-one-thirty:before { content: fa-content(\e34f\fe01); }
.fad.#{$fa-css-prefix}-clock-one-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-one-thirty:after { content: fa-content(\e34f\fe02); }
.fad.#{$fa-css-prefix}-clock-rotate-left:before, .fa-duotone.#{$fa-css-prefix}-clock-rotate-left:before { content: fa-content(\f1da\fe01); }
.fad.#{$fa-css-prefix}-clock-rotate-left:after, .fa-duotone.#{$fa-css-prefix}-clock-rotate-left:after { content: fa-content(\f1da\fe02); }
.fad.#{$fa-css-prefix}-history:before, .fa-duotone.#{$fa-css-prefix}-history:before { content: fa-content(\f1da\fe01); }
.fad.#{$fa-css-prefix}-history:after, .fa-duotone.#{$fa-css-prefix}-history:after { content: fa-content(\f1da\fe02); }
.fad.#{$fa-css-prefix}-clock-seven:before, .fa-duotone.#{$fa-css-prefix}-clock-seven:before { content: fa-content(\e350\fe01); }
.fad.#{$fa-css-prefix}-clock-seven:after, .fa-duotone.#{$fa-css-prefix}-clock-seven:after { content: fa-content(\e350\fe02); }
.fad.#{$fa-css-prefix}-clock-seven-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-seven-thirty:before { content: fa-content(\e351\fe01); }
.fad.#{$fa-css-prefix}-clock-seven-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-seven-thirty:after { content: fa-content(\e351\fe02); }
.fad.#{$fa-css-prefix}-clock-six:before, .fa-duotone.#{$fa-css-prefix}-clock-six:before { content: fa-content(\e352\fe01); }
.fad.#{$fa-css-prefix}-clock-six:after, .fa-duotone.#{$fa-css-prefix}-clock-six:after { content: fa-content(\e352\fe02); }
.fad.#{$fa-css-prefix}-clock-six-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-six-thirty:before { content: fa-content(\e353\fe01); }
.fad.#{$fa-css-prefix}-clock-six-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-six-thirty:after { content: fa-content(\e353\fe02); }
.fad.#{$fa-css-prefix}-clock-ten:before, .fa-duotone.#{$fa-css-prefix}-clock-ten:before { content: fa-content(\e354\fe01); }
.fad.#{$fa-css-prefix}-clock-ten:after, .fa-duotone.#{$fa-css-prefix}-clock-ten:after { content: fa-content(\e354\fe02); }
.fad.#{$fa-css-prefix}-clock-ten-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-ten-thirty:before { content: fa-content(\e355\fe01); }
.fad.#{$fa-css-prefix}-clock-ten-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-ten-thirty:after { content: fa-content(\e355\fe02); }
.fad.#{$fa-css-prefix}-clock-three:before, .fa-duotone.#{$fa-css-prefix}-clock-three:before { content: fa-content(\e356\fe01); }
.fad.#{$fa-css-prefix}-clock-three:after, .fa-duotone.#{$fa-css-prefix}-clock-three:after { content: fa-content(\e356\fe02); }
.fad.#{$fa-css-prefix}-clock-three-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-three-thirty:before { content: fa-content(\e357\fe01); }
.fad.#{$fa-css-prefix}-clock-three-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-three-thirty:after { content: fa-content(\e357\fe02); }
.fad.#{$fa-css-prefix}-clock-twelve:before, .fa-duotone.#{$fa-css-prefix}-clock-twelve:before { content: fa-content(\e358\fe01); }
.fad.#{$fa-css-prefix}-clock-twelve:after, .fa-duotone.#{$fa-css-prefix}-clock-twelve:after { content: fa-content(\e358\fe02); }
.fad.#{$fa-css-prefix}-clock-twelve-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-twelve-thirty:before { content: fa-content(\e359\fe01); }
.fad.#{$fa-css-prefix}-clock-twelve-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-twelve-thirty:after { content: fa-content(\e359\fe02); }
.fad.#{$fa-css-prefix}-clock-two:before, .fa-duotone.#{$fa-css-prefix}-clock-two:before { content: fa-content(\e35a\fe01); }
.fad.#{$fa-css-prefix}-clock-two:after, .fa-duotone.#{$fa-css-prefix}-clock-two:after { content: fa-content(\e35a\fe02); }
.fad.#{$fa-css-prefix}-clock-two-thirty:before, .fa-duotone.#{$fa-css-prefix}-clock-two-thirty:before { content: fa-content(\e35b\fe01); }
.fad.#{$fa-css-prefix}-clock-two-thirty:after, .fa-duotone.#{$fa-css-prefix}-clock-two-thirty:after { content: fa-content(\e35b\fe02); }
.fad.#{$fa-css-prefix}-clone:before, .fa-duotone.#{$fa-css-prefix}-clone:before { content: fa-content(\f24d\fe01); }
.fad.#{$fa-css-prefix}-clone:after, .fa-duotone.#{$fa-css-prefix}-clone:after { content: fa-content(\f24d\fe02); }
.fad.#{$fa-css-prefix}-closed-captioning:before, .fa-duotone.#{$fa-css-prefix}-closed-captioning:before { content: fa-content(\f20a\fe01); }
.fad.#{$fa-css-prefix}-closed-captioning:after, .fa-duotone.#{$fa-css-prefix}-closed-captioning:after { content: fa-content(\f20a\fe02); }
.fad.#{$fa-css-prefix}-closed-captioning-slash:before, .fa-duotone.#{$fa-css-prefix}-closed-captioning-slash:before { content: fa-content(\e135\fe01); }
.fad.#{$fa-css-prefix}-closed-captioning-slash:after, .fa-duotone.#{$fa-css-prefix}-closed-captioning-slash:after { content: fa-content(\e135\fe02); }
.fad.#{$fa-css-prefix}-clothes-hanger:before, .fa-duotone.#{$fa-css-prefix}-clothes-hanger:before { content: fa-content(\e136\fe01); }
.fad.#{$fa-css-prefix}-clothes-hanger:after, .fa-duotone.#{$fa-css-prefix}-clothes-hanger:after { content: fa-content(\e136\fe02); }
.fad.#{$fa-css-prefix}-cloud:before, .fa-duotone.#{$fa-css-prefix}-cloud:before { content: fa-content(\2601\fe01); }
.fad.#{$fa-css-prefix}-cloud:after, .fa-duotone.#{$fa-css-prefix}-cloud:after { content: fa-content(\2601\fe02); }
.fad.#{$fa-css-prefix}-cloud-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-cloud-arrow-down:before { content: fa-content(\f0ed\fe01); }
.fad.#{$fa-css-prefix}-cloud-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-cloud-arrow-down:after { content: fa-content(\f0ed\fe02); }
.fad.#{$fa-css-prefix}-cloud-download:before, .fa-duotone.#{$fa-css-prefix}-cloud-download:before { content: fa-content(\f0ed\fe01); }
.fad.#{$fa-css-prefix}-cloud-download:after, .fa-duotone.#{$fa-css-prefix}-cloud-download:after { content: fa-content(\f0ed\fe02); }
.fad.#{$fa-css-prefix}-cloud-download-alt:before, .fa-duotone.#{$fa-css-prefix}-cloud-download-alt:before { content: fa-content(\f0ed\fe01); }
.fad.#{$fa-css-prefix}-cloud-download-alt:after, .fa-duotone.#{$fa-css-prefix}-cloud-download-alt:after { content: fa-content(\f0ed\fe02); }
.fad.#{$fa-css-prefix}-cloud-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-cloud-arrow-up:before { content: fa-content(\f0ee\fe01); }
.fad.#{$fa-css-prefix}-cloud-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-cloud-arrow-up:after { content: fa-content(\f0ee\fe02); }
.fad.#{$fa-css-prefix}-cloud-upload:before, .fa-duotone.#{$fa-css-prefix}-cloud-upload:before { content: fa-content(\f0ee\fe01); }
.fad.#{$fa-css-prefix}-cloud-upload:after, .fa-duotone.#{$fa-css-prefix}-cloud-upload:after { content: fa-content(\f0ee\fe02); }
.fad.#{$fa-css-prefix}-cloud-upload-alt:before, .fa-duotone.#{$fa-css-prefix}-cloud-upload-alt:before { content: fa-content(\f0ee\fe01); }
.fad.#{$fa-css-prefix}-cloud-upload-alt:after, .fa-duotone.#{$fa-css-prefix}-cloud-upload-alt:after { content: fa-content(\f0ee\fe02); }
.fad.#{$fa-css-prefix}-cloud-bolt:before, .fa-duotone.#{$fa-css-prefix}-cloud-bolt:before { content: fa-content(\f76c); }
.fad.#{$fa-css-prefix}-cloud-bolt:after, .fa-duotone.#{$fa-css-prefix}-cloud-bolt:after { content: fa-content(\10f76c); }
.fad.#{$fa-css-prefix}-thunderstorm:before, .fa-duotone.#{$fa-css-prefix}-thunderstorm:before { content: fa-content(\f76c); }
.fad.#{$fa-css-prefix}-thunderstorm:after, .fa-duotone.#{$fa-css-prefix}-thunderstorm:after { content: fa-content(\10f76c); }
.fad.#{$fa-css-prefix}-cloud-bolt-moon:before, .fa-duotone.#{$fa-css-prefix}-cloud-bolt-moon:before { content: fa-content(\f76d\fe01); }
.fad.#{$fa-css-prefix}-cloud-bolt-moon:after, .fa-duotone.#{$fa-css-prefix}-cloud-bolt-moon:after { content: fa-content(\f76d\fe02); }
.fad.#{$fa-css-prefix}-thunderstorm-moon:before, .fa-duotone.#{$fa-css-prefix}-thunderstorm-moon:before { content: fa-content(\f76d\fe01); }
.fad.#{$fa-css-prefix}-thunderstorm-moon:after, .fa-duotone.#{$fa-css-prefix}-thunderstorm-moon:after { content: fa-content(\f76d\fe02); }
.fad.#{$fa-css-prefix}-cloud-bolt-sun:before, .fa-duotone.#{$fa-css-prefix}-cloud-bolt-sun:before { content: fa-content(\f76e\fe01); }
.fad.#{$fa-css-prefix}-cloud-bolt-sun:after, .fa-duotone.#{$fa-css-prefix}-cloud-bolt-sun:after { content: fa-content(\f76e\fe02); }
.fad.#{$fa-css-prefix}-thunderstorm-sun:before, .fa-duotone.#{$fa-css-prefix}-thunderstorm-sun:before { content: fa-content(\f76e\fe01); }
.fad.#{$fa-css-prefix}-thunderstorm-sun:after, .fa-duotone.#{$fa-css-prefix}-thunderstorm-sun:after { content: fa-content(\f76e\fe02); }
.fad.#{$fa-css-prefix}-cloud-check:before, .fa-duotone.#{$fa-css-prefix}-cloud-check:before { content: fa-content(\e35c\fe01); }
.fad.#{$fa-css-prefix}-cloud-check:after, .fa-duotone.#{$fa-css-prefix}-cloud-check:after { content: fa-content(\e35c\fe02); }
.fad.#{$fa-css-prefix}-cloud-drizzle:before, .fa-duotone.#{$fa-css-prefix}-cloud-drizzle:before { content: fa-content(\f738\fe01); }
.fad.#{$fa-css-prefix}-cloud-drizzle:after, .fa-duotone.#{$fa-css-prefix}-cloud-drizzle:after { content: fa-content(\f738\fe02); }
.fad.#{$fa-css-prefix}-cloud-fog:before, .fa-duotone.#{$fa-css-prefix}-cloud-fog:before { content: fa-content(\f74e); }
.fad.#{$fa-css-prefix}-cloud-fog:after, .fa-duotone.#{$fa-css-prefix}-cloud-fog:after { content: fa-content(\10f74e); }
.fad.#{$fa-css-prefix}-fog:before, .fa-duotone.#{$fa-css-prefix}-fog:before { content: fa-content(\f74e); }
.fad.#{$fa-css-prefix}-fog:after, .fa-duotone.#{$fa-css-prefix}-fog:after { content: fa-content(\10f74e); }
.fad.#{$fa-css-prefix}-cloud-hail:before, .fa-duotone.#{$fa-css-prefix}-cloud-hail:before { content: fa-content(\f739\fe01); }
.fad.#{$fa-css-prefix}-cloud-hail:after, .fa-duotone.#{$fa-css-prefix}-cloud-hail:after { content: fa-content(\f739\fe02); }
.fad.#{$fa-css-prefix}-cloud-hail-mixed:before, .fa-duotone.#{$fa-css-prefix}-cloud-hail-mixed:before { content: fa-content(\f73a\fe01); }
.fad.#{$fa-css-prefix}-cloud-hail-mixed:after, .fa-duotone.#{$fa-css-prefix}-cloud-hail-mixed:after { content: fa-content(\f73a\fe02); }
.fad.#{$fa-css-prefix}-cloud-meatball:before, .fa-duotone.#{$fa-css-prefix}-cloud-meatball:before { content: fa-content(\f73b\fe01); }
.fad.#{$fa-css-prefix}-cloud-meatball:after, .fa-duotone.#{$fa-css-prefix}-cloud-meatball:after { content: fa-content(\f73b\fe02); }
.fad.#{$fa-css-prefix}-cloud-minus:before, .fa-duotone.#{$fa-css-prefix}-cloud-minus:before { content: fa-content(\e35d\fe01); }
.fad.#{$fa-css-prefix}-cloud-minus:after, .fa-duotone.#{$fa-css-prefix}-cloud-minus:after { content: fa-content(\e35d\fe02); }
.fad.#{$fa-css-prefix}-cloud-moon:before, .fa-duotone.#{$fa-css-prefix}-cloud-moon:before { content: fa-content(\f6c3\fe01); }
.fad.#{$fa-css-prefix}-cloud-moon:after, .fa-duotone.#{$fa-css-prefix}-cloud-moon:after { content: fa-content(\f6c3\fe02); }
.fad.#{$fa-css-prefix}-cloud-moon-rain:before, .fa-duotone.#{$fa-css-prefix}-cloud-moon-rain:before { content: fa-content(\f73c\fe01); }
.fad.#{$fa-css-prefix}-cloud-moon-rain:after, .fa-duotone.#{$fa-css-prefix}-cloud-moon-rain:after { content: fa-content(\f73c\fe02); }
.fad.#{$fa-css-prefix}-cloud-music:before, .fa-duotone.#{$fa-css-prefix}-cloud-music:before { content: fa-content(\f8ae\fe01); }
.fad.#{$fa-css-prefix}-cloud-music:after, .fa-duotone.#{$fa-css-prefix}-cloud-music:after { content: fa-content(\f8ae\fe02); }
.fad.#{$fa-css-prefix}-cloud-plus:before, .fa-duotone.#{$fa-css-prefix}-cloud-plus:before { content: fa-content(\e35e\fe01); }
.fad.#{$fa-css-prefix}-cloud-plus:after, .fa-duotone.#{$fa-css-prefix}-cloud-plus:after { content: fa-content(\e35e\fe02); }
.fad.#{$fa-css-prefix}-cloud-rain:before, .fa-duotone.#{$fa-css-prefix}-cloud-rain:before { content: fa-content(\26c6\fe01); }
.fad.#{$fa-css-prefix}-cloud-rain:after, .fa-duotone.#{$fa-css-prefix}-cloud-rain:after { content: fa-content(\26c6\fe02); }
.fad.#{$fa-css-prefix}-cloud-rainbow:before, .fa-duotone.#{$fa-css-prefix}-cloud-rainbow:before { content: fa-content(\f73e\fe01); }
.fad.#{$fa-css-prefix}-cloud-rainbow:after, .fa-duotone.#{$fa-css-prefix}-cloud-rainbow:after { content: fa-content(\f73e\fe02); }
.fad.#{$fa-css-prefix}-cloud-showers:before, .fa-duotone.#{$fa-css-prefix}-cloud-showers:before { content: fa-content(\f73f\fe01); }
.fad.#{$fa-css-prefix}-cloud-showers:after, .fa-duotone.#{$fa-css-prefix}-cloud-showers:after { content: fa-content(\f73f\fe02); }
.fad.#{$fa-css-prefix}-cloud-showers-heavy:before, .fa-duotone.#{$fa-css-prefix}-cloud-showers-heavy:before { content: fa-content(\f740\fe01); }
.fad.#{$fa-css-prefix}-cloud-showers-heavy:after, .fa-duotone.#{$fa-css-prefix}-cloud-showers-heavy:after { content: fa-content(\f740\fe02); }
.fad.#{$fa-css-prefix}-cloud-slash:before, .fa-duotone.#{$fa-css-prefix}-cloud-slash:before { content: fa-content(\e137\fe01); }
.fad.#{$fa-css-prefix}-cloud-slash:after, .fa-duotone.#{$fa-css-prefix}-cloud-slash:after { content: fa-content(\e137\fe02); }
.fad.#{$fa-css-prefix}-cloud-sleet:before, .fa-duotone.#{$fa-css-prefix}-cloud-sleet:before { content: fa-content(\f741\fe01); }
.fad.#{$fa-css-prefix}-cloud-sleet:after, .fa-duotone.#{$fa-css-prefix}-cloud-sleet:after { content: fa-content(\f741\fe02); }
.fad.#{$fa-css-prefix}-cloud-snow:before, .fa-duotone.#{$fa-css-prefix}-cloud-snow:before { content: fa-content(\f742); }
.fad.#{$fa-css-prefix}-cloud-snow:after, .fa-duotone.#{$fa-css-prefix}-cloud-snow:after { content: fa-content(\10f742); }
.fad.#{$fa-css-prefix}-cloud-sun:before, .fa-duotone.#{$fa-css-prefix}-cloud-sun:before { content: fa-content(\26c5\fe01); }
.fad.#{$fa-css-prefix}-cloud-sun:after, .fa-duotone.#{$fa-css-prefix}-cloud-sun:after { content: fa-content(\26c5\fe02); }
.fad.#{$fa-css-prefix}-cloud-sun-rain:before, .fa-duotone.#{$fa-css-prefix}-cloud-sun-rain:before { content: fa-content(\f743); }
.fad.#{$fa-css-prefix}-cloud-sun-rain:after, .fa-duotone.#{$fa-css-prefix}-cloud-sun-rain:after { content: fa-content(\10f743); }
.fad.#{$fa-css-prefix}-cloud-word:before, .fa-duotone.#{$fa-css-prefix}-cloud-word:before { content: fa-content(\e138\fe01); }
.fad.#{$fa-css-prefix}-cloud-word:after, .fa-duotone.#{$fa-css-prefix}-cloud-word:after { content: fa-content(\e138\fe02); }
.fad.#{$fa-css-prefix}-cloud-xmark:before, .fa-duotone.#{$fa-css-prefix}-cloud-xmark:before { content: fa-content(\e35f\fe01); }
.fad.#{$fa-css-prefix}-cloud-xmark:after, .fa-duotone.#{$fa-css-prefix}-cloud-xmark:after { content: fa-content(\e35f\fe02); }
.fad.#{$fa-css-prefix}-clouds:before, .fa-duotone.#{$fa-css-prefix}-clouds:before { content: fa-content(\f744\fe01); }
.fad.#{$fa-css-prefix}-clouds:after, .fa-duotone.#{$fa-css-prefix}-clouds:after { content: fa-content(\f744\fe02); }
.fad.#{$fa-css-prefix}-clouds-moon:before, .fa-duotone.#{$fa-css-prefix}-clouds-moon:before { content: fa-content(\f745\fe01); }
.fad.#{$fa-css-prefix}-clouds-moon:after, .fa-duotone.#{$fa-css-prefix}-clouds-moon:after { content: fa-content(\f745\fe02); }
.fad.#{$fa-css-prefix}-clouds-sun:before, .fa-duotone.#{$fa-css-prefix}-clouds-sun:before { content: fa-content(\f746\fe01); }
.fad.#{$fa-css-prefix}-clouds-sun:after, .fa-duotone.#{$fa-css-prefix}-clouds-sun:after { content: fa-content(\f746\fe02); }
.fad.#{$fa-css-prefix}-clover:before, .fa-duotone.#{$fa-css-prefix}-clover:before { content: fa-content(\e139\fe01); }
.fad.#{$fa-css-prefix}-clover:after, .fa-duotone.#{$fa-css-prefix}-clover:after { content: fa-content(\e139\fe02); }
.fad.#{$fa-css-prefix}-club:before, .fa-duotone.#{$fa-css-prefix}-club:before { content: fa-content(\2663\fe01); }
.fad.#{$fa-css-prefix}-club:after, .fa-duotone.#{$fa-css-prefix}-club:after { content: fa-content(\2663\fe02); }
.fad.#{$fa-css-prefix}-coconut:before, .fa-duotone.#{$fa-css-prefix}-coconut:before { content: fa-content(\e2f6\fe01); }
.fad.#{$fa-css-prefix}-coconut:after, .fa-duotone.#{$fa-css-prefix}-coconut:after { content: fa-content(\e2f6\fe02); }
.fad.#{$fa-css-prefix}-code:before, .fa-duotone.#{$fa-css-prefix}-code:before { content: fa-content(\f121\fe01); }
.fad.#{$fa-css-prefix}-code:after, .fa-duotone.#{$fa-css-prefix}-code:after { content: fa-content(\f121\fe02); }
.fad.#{$fa-css-prefix}-code-branch:before, .fa-duotone.#{$fa-css-prefix}-code-branch:before { content: fa-content(\f126\fe01); }
.fad.#{$fa-css-prefix}-code-branch:after, .fa-duotone.#{$fa-css-prefix}-code-branch:after { content: fa-content(\f126\fe02); }
.fad.#{$fa-css-prefix}-code-commit:before, .fa-duotone.#{$fa-css-prefix}-code-commit:before { content: fa-content(\f386\fe01); }
.fad.#{$fa-css-prefix}-code-commit:after, .fa-duotone.#{$fa-css-prefix}-code-commit:after { content: fa-content(\f386\fe02); }
.fad.#{$fa-css-prefix}-code-compare:before, .fa-duotone.#{$fa-css-prefix}-code-compare:before { content: fa-content(\e13a\fe01); }
.fad.#{$fa-css-prefix}-code-compare:after, .fa-duotone.#{$fa-css-prefix}-code-compare:after { content: fa-content(\e13a\fe02); }
.fad.#{$fa-css-prefix}-code-fork:before, .fa-duotone.#{$fa-css-prefix}-code-fork:before { content: fa-content(\e13b\fe01); }
.fad.#{$fa-css-prefix}-code-fork:after, .fa-duotone.#{$fa-css-prefix}-code-fork:after { content: fa-content(\e13b\fe02); }
.fad.#{$fa-css-prefix}-code-merge:before, .fa-duotone.#{$fa-css-prefix}-code-merge:before { content: fa-content(\f387\fe01); }
.fad.#{$fa-css-prefix}-code-merge:after, .fa-duotone.#{$fa-css-prefix}-code-merge:after { content: fa-content(\f387\fe02); }
.fad.#{$fa-css-prefix}-code-pull-request:before, .fa-duotone.#{$fa-css-prefix}-code-pull-request:before { content: fa-content(\e13c\fe01); }
.fad.#{$fa-css-prefix}-code-pull-request:after, .fa-duotone.#{$fa-css-prefix}-code-pull-request:after { content: fa-content(\e13c\fe02); }
.fad.#{$fa-css-prefix}-code-simple:before, .fa-duotone.#{$fa-css-prefix}-code-simple:before { content: fa-content(\e13d\fe01); }
.fad.#{$fa-css-prefix}-code-simple:after, .fa-duotone.#{$fa-css-prefix}-code-simple:after { content: fa-content(\e13d\fe02); }
.fad.#{$fa-css-prefix}-coffee-bean:before, .fa-duotone.#{$fa-css-prefix}-coffee-bean:before { content: fa-content(\e13e\fe01); }
.fad.#{$fa-css-prefix}-coffee-bean:after, .fa-duotone.#{$fa-css-prefix}-coffee-bean:after { content: fa-content(\e13e\fe02); }
.fad.#{$fa-css-prefix}-coffee-beans:before, .fa-duotone.#{$fa-css-prefix}-coffee-beans:before { content: fa-content(\e13f\fe01); }
.fad.#{$fa-css-prefix}-coffee-beans:after, .fa-duotone.#{$fa-css-prefix}-coffee-beans:after { content: fa-content(\e13f\fe02); }
.fad.#{$fa-css-prefix}-coffee-pot:before, .fa-duotone.#{$fa-css-prefix}-coffee-pot:before { content: fa-content(\e002\fe01); }
.fad.#{$fa-css-prefix}-coffee-pot:after, .fa-duotone.#{$fa-css-prefix}-coffee-pot:after { content: fa-content(\e002\fe02); }
.fad.#{$fa-css-prefix}-coffin:before, .fa-duotone.#{$fa-css-prefix}-coffin:before { content: fa-content(\26b0\fe01); }
.fad.#{$fa-css-prefix}-coffin:after, .fa-duotone.#{$fa-css-prefix}-coffin:after { content: fa-content(\26b0\fe02); }
.fad.#{$fa-css-prefix}-coffin-cross:before, .fa-duotone.#{$fa-css-prefix}-coffin-cross:before { content: fa-content(\e051\fe01); }
.fad.#{$fa-css-prefix}-coffin-cross:after, .fa-duotone.#{$fa-css-prefix}-coffin-cross:after { content: fa-content(\e051\fe02); }
.fad.#{$fa-css-prefix}-coin:before, .fa-duotone.#{$fa-css-prefix}-coin:before { content: fa-content(\f85c); }
.fad.#{$fa-css-prefix}-coin:after, .fa-duotone.#{$fa-css-prefix}-coin:after { content: fa-content(\10f85c); }
.fad.#{$fa-css-prefix}-coins:before, .fa-duotone.#{$fa-css-prefix}-coins:before { content: fa-content(\f51e\fe01); }
.fad.#{$fa-css-prefix}-coins:after, .fa-duotone.#{$fa-css-prefix}-coins:after { content: fa-content(\f51e\fe02); }
.fad.#{$fa-css-prefix}-colon:before, .fa-duotone.#{$fa-css-prefix}-colon:before { content: fa-content(\3a\fe01); }
.fad.#{$fa-css-prefix}-colon:after, .fa-duotone.#{$fa-css-prefix}-colon:after { content: fa-content(\3a\fe02); }
.fad.#{$fa-css-prefix}-colon-sign:before, .fa-duotone.#{$fa-css-prefix}-colon-sign:before { content: fa-content(\e140\fe01); }
.fad.#{$fa-css-prefix}-colon-sign:after, .fa-duotone.#{$fa-css-prefix}-colon-sign:after { content: fa-content(\e140\fe02); }
.fad.#{$fa-css-prefix}-columns-3:before, .fa-duotone.#{$fa-css-prefix}-columns-3:before { content: fa-content(\e361\fe01); }
.fad.#{$fa-css-prefix}-columns-3:after, .fa-duotone.#{$fa-css-prefix}-columns-3:after { content: fa-content(\e361\fe02); }
.fad.#{$fa-css-prefix}-comet:before, .fa-duotone.#{$fa-css-prefix}-comet:before { content: fa-content(\e003\fe01); }
.fad.#{$fa-css-prefix}-comet:after, .fa-duotone.#{$fa-css-prefix}-comet:after { content: fa-content(\e003\fe02); }
.fad.#{$fa-css-prefix}-comma:before, .fa-duotone.#{$fa-css-prefix}-comma:before { content: fa-content(\2c\fe01); }
.fad.#{$fa-css-prefix}-comma:after, .fa-duotone.#{$fa-css-prefix}-comma:after { content: fa-content(\2c\fe02); }
.fad.#{$fa-css-prefix}-command:before, .fa-duotone.#{$fa-css-prefix}-command:before { content: fa-content(\e142\fe01); }
.fad.#{$fa-css-prefix}-command:after, .fa-duotone.#{$fa-css-prefix}-command:after { content: fa-content(\e142\fe02); }
.fad.#{$fa-css-prefix}-comment:before, .fa-duotone.#{$fa-css-prefix}-comment:before { content: fa-content(\f075); }
.fad.#{$fa-css-prefix}-comment:after, .fa-duotone.#{$fa-css-prefix}-comment:after { content: fa-content(\10f075); }
.fad.#{$fa-css-prefix}-comment-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-comment-arrow-down:before { content: fa-content(\e143\fe01); }
.fad.#{$fa-css-prefix}-comment-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-comment-arrow-down:after { content: fa-content(\e143\fe02); }
.fad.#{$fa-css-prefix}-comment-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-comment-arrow-up:before { content: fa-content(\e144\fe01); }
.fad.#{$fa-css-prefix}-comment-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-comment-arrow-up:after { content: fa-content(\e144\fe02); }
.fad.#{$fa-css-prefix}-comment-arrow-up-right:before, .fa-duotone.#{$fa-css-prefix}-comment-arrow-up-right:before { content: fa-content(\e145\fe01); }
.fad.#{$fa-css-prefix}-comment-arrow-up-right:after, .fa-duotone.#{$fa-css-prefix}-comment-arrow-up-right:after { content: fa-content(\e145\fe02); }
.fad.#{$fa-css-prefix}-comment-captions:before, .fa-duotone.#{$fa-css-prefix}-comment-captions:before { content: fa-content(\e146\fe01); }
.fad.#{$fa-css-prefix}-comment-captions:after, .fa-duotone.#{$fa-css-prefix}-comment-captions:after { content: fa-content(\e146\fe02); }
.fad.#{$fa-css-prefix}-comment-check:before, .fa-duotone.#{$fa-css-prefix}-comment-check:before { content: fa-content(\f4ac\fe01); }
.fad.#{$fa-css-prefix}-comment-check:after, .fa-duotone.#{$fa-css-prefix}-comment-check:after { content: fa-content(\f4ac\fe02); }
.fad.#{$fa-css-prefix}-comment-code:before, .fa-duotone.#{$fa-css-prefix}-comment-code:before { content: fa-content(\e147\fe01); }
.fad.#{$fa-css-prefix}-comment-code:after, .fa-duotone.#{$fa-css-prefix}-comment-code:after { content: fa-content(\e147\fe02); }
.fad.#{$fa-css-prefix}-comment-dollar:before, .fa-duotone.#{$fa-css-prefix}-comment-dollar:before { content: fa-content(\f651\fe01); }
.fad.#{$fa-css-prefix}-comment-dollar:after, .fa-duotone.#{$fa-css-prefix}-comment-dollar:after { content: fa-content(\f651\fe02); }
.fad.#{$fa-css-prefix}-comment-dots:before, .fa-duotone.#{$fa-css-prefix}-comment-dots:before { content: fa-content(\f4ad); }
.fad.#{$fa-css-prefix}-comment-dots:after, .fa-duotone.#{$fa-css-prefix}-comment-dots:after { content: fa-content(\10f4ad); }
.fad.#{$fa-css-prefix}-commenting:before, .fa-duotone.#{$fa-css-prefix}-commenting:before { content: fa-content(\f4ad); }
.fad.#{$fa-css-prefix}-commenting:after, .fa-duotone.#{$fa-css-prefix}-commenting:after { content: fa-content(\10f4ad); }
.fad.#{$fa-css-prefix}-comment-exclamation:before, .fa-duotone.#{$fa-css-prefix}-comment-exclamation:before { content: fa-content(\f4af\fe01); }
.fad.#{$fa-css-prefix}-comment-exclamation:after, .fa-duotone.#{$fa-css-prefix}-comment-exclamation:after { content: fa-content(\f4af\fe02); }
.fad.#{$fa-css-prefix}-comment-image:before, .fa-duotone.#{$fa-css-prefix}-comment-image:before { content: fa-content(\e148\fe01); }
.fad.#{$fa-css-prefix}-comment-image:after, .fa-duotone.#{$fa-css-prefix}-comment-image:after { content: fa-content(\e148\fe02); }
.fad.#{$fa-css-prefix}-comment-lines:before, .fa-duotone.#{$fa-css-prefix}-comment-lines:before { content: fa-content(\f4b0\fe01); }
.fad.#{$fa-css-prefix}-comment-lines:after, .fa-duotone.#{$fa-css-prefix}-comment-lines:after { content: fa-content(\f4b0\fe02); }
.fad.#{$fa-css-prefix}-comment-medical:before, .fa-duotone.#{$fa-css-prefix}-comment-medical:before { content: fa-content(\f7f5\fe01); }
.fad.#{$fa-css-prefix}-comment-medical:after, .fa-duotone.#{$fa-css-prefix}-comment-medical:after { content: fa-content(\f7f5\fe02); }
.fad.#{$fa-css-prefix}-comment-middle:before, .fa-duotone.#{$fa-css-prefix}-comment-middle:before { content: fa-content(\e149\fe01); }
.fad.#{$fa-css-prefix}-comment-middle:after, .fa-duotone.#{$fa-css-prefix}-comment-middle:after { content: fa-content(\e149\fe02); }
.fad.#{$fa-css-prefix}-comment-middle-top:before, .fa-duotone.#{$fa-css-prefix}-comment-middle-top:before { content: fa-content(\e14a\fe01); }
.fad.#{$fa-css-prefix}-comment-middle-top:after, .fa-duotone.#{$fa-css-prefix}-comment-middle-top:after { content: fa-content(\e14a\fe02); }
.fad.#{$fa-css-prefix}-comment-minus:before, .fa-duotone.#{$fa-css-prefix}-comment-minus:before { content: fa-content(\f4b1\fe01); }
.fad.#{$fa-css-prefix}-comment-minus:after, .fa-duotone.#{$fa-css-prefix}-comment-minus:after { content: fa-content(\f4b1\fe02); }
.fad.#{$fa-css-prefix}-comment-music:before, .fa-duotone.#{$fa-css-prefix}-comment-music:before { content: fa-content(\f8b0\fe01); }
.fad.#{$fa-css-prefix}-comment-music:after, .fa-duotone.#{$fa-css-prefix}-comment-music:after { content: fa-content(\f8b0\fe02); }
.fad.#{$fa-css-prefix}-comment-pen:before, .fa-duotone.#{$fa-css-prefix}-comment-pen:before { content: fa-content(\f4ae\fe01); }
.fad.#{$fa-css-prefix}-comment-pen:after, .fa-duotone.#{$fa-css-prefix}-comment-pen:after { content: fa-content(\f4ae\fe02); }
.fad.#{$fa-css-prefix}-comment-edit:before, .fa-duotone.#{$fa-css-prefix}-comment-edit:before { content: fa-content(\f4ae\fe01); }
.fad.#{$fa-css-prefix}-comment-edit:after, .fa-duotone.#{$fa-css-prefix}-comment-edit:after { content: fa-content(\f4ae\fe02); }
.fad.#{$fa-css-prefix}-comment-plus:before, .fa-duotone.#{$fa-css-prefix}-comment-plus:before { content: fa-content(\f4b2\fe01); }
.fad.#{$fa-css-prefix}-comment-plus:after, .fa-duotone.#{$fa-css-prefix}-comment-plus:after { content: fa-content(\f4b2\fe02); }
.fad.#{$fa-css-prefix}-comment-question:before, .fa-duotone.#{$fa-css-prefix}-comment-question:before { content: fa-content(\e14b\fe01); }
.fad.#{$fa-css-prefix}-comment-question:after, .fa-duotone.#{$fa-css-prefix}-comment-question:after { content: fa-content(\e14b\fe02); }
.fad.#{$fa-css-prefix}-comment-quote:before, .fa-duotone.#{$fa-css-prefix}-comment-quote:before { content: fa-content(\e14c\fe01); }
.fad.#{$fa-css-prefix}-comment-quote:after, .fa-duotone.#{$fa-css-prefix}-comment-quote:after { content: fa-content(\e14c\fe02); }
.fad.#{$fa-css-prefix}-comment-slash:before, .fa-duotone.#{$fa-css-prefix}-comment-slash:before { content: fa-content(\f4b3\fe01); }
.fad.#{$fa-css-prefix}-comment-slash:after, .fa-duotone.#{$fa-css-prefix}-comment-slash:after { content: fa-content(\f4b3\fe02); }
.fad.#{$fa-css-prefix}-comment-smile:before, .fa-duotone.#{$fa-css-prefix}-comment-smile:before { content: fa-content(\f4b4\fe01); }
.fad.#{$fa-css-prefix}-comment-smile:after, .fa-duotone.#{$fa-css-prefix}-comment-smile:after { content: fa-content(\f4b4\fe02); }
.fad.#{$fa-css-prefix}-comment-sms:before, .fa-duotone.#{$fa-css-prefix}-comment-sms:before { content: fa-content(\f7cd\fe01); }
.fad.#{$fa-css-prefix}-comment-sms:after, .fa-duotone.#{$fa-css-prefix}-comment-sms:after { content: fa-content(\f7cd\fe02); }
.fad.#{$fa-css-prefix}-sms:before, .fa-duotone.#{$fa-css-prefix}-sms:before { content: fa-content(\f7cd\fe01); }
.fad.#{$fa-css-prefix}-sms:after, .fa-duotone.#{$fa-css-prefix}-sms:after { content: fa-content(\f7cd\fe02); }
.fad.#{$fa-css-prefix}-comment-text:before, .fa-duotone.#{$fa-css-prefix}-comment-text:before { content: fa-content(\e14d\fe01); }
.fad.#{$fa-css-prefix}-comment-text:after, .fa-duotone.#{$fa-css-prefix}-comment-text:after { content: fa-content(\e14d\fe02); }
.fad.#{$fa-css-prefix}-comment-xmark:before, .fa-duotone.#{$fa-css-prefix}-comment-xmark:before { content: fa-content(\f4b5\fe01); }
.fad.#{$fa-css-prefix}-comment-xmark:after, .fa-duotone.#{$fa-css-prefix}-comment-xmark:after { content: fa-content(\f4b5\fe02); }
.fad.#{$fa-css-prefix}-comment-times:before, .fa-duotone.#{$fa-css-prefix}-comment-times:before { content: fa-content(\f4b5\fe01); }
.fad.#{$fa-css-prefix}-comment-times:after, .fa-duotone.#{$fa-css-prefix}-comment-times:after { content: fa-content(\f4b5\fe02); }
.fad.#{$fa-css-prefix}-comments:before, .fa-duotone.#{$fa-css-prefix}-comments:before { content: fa-content(\f086); }
.fad.#{$fa-css-prefix}-comments:after, .fa-duotone.#{$fa-css-prefix}-comments:after { content: fa-content(\10f086); }
.fad.#{$fa-css-prefix}-comments-dollar:before, .fa-duotone.#{$fa-css-prefix}-comments-dollar:before { content: fa-content(\f653\fe01); }
.fad.#{$fa-css-prefix}-comments-dollar:after, .fa-duotone.#{$fa-css-prefix}-comments-dollar:after { content: fa-content(\f653\fe02); }
.fad.#{$fa-css-prefix}-comments-question:before, .fa-duotone.#{$fa-css-prefix}-comments-question:before { content: fa-content(\e14e\fe01); }
.fad.#{$fa-css-prefix}-comments-question:after, .fa-duotone.#{$fa-css-prefix}-comments-question:after { content: fa-content(\e14e\fe02); }
.fad.#{$fa-css-prefix}-comments-question-check:before, .fa-duotone.#{$fa-css-prefix}-comments-question-check:before { content: fa-content(\e14f\fe01); }
.fad.#{$fa-css-prefix}-comments-question-check:after, .fa-duotone.#{$fa-css-prefix}-comments-question-check:after { content: fa-content(\e14f\fe02); }
.fad.#{$fa-css-prefix}-compact-disc:before, .fa-duotone.#{$fa-css-prefix}-compact-disc:before { content: fa-content(\f51f); }
.fad.#{$fa-css-prefix}-compact-disc:after, .fa-duotone.#{$fa-css-prefix}-compact-disc:after { content: fa-content(\10f51f); }
.fad.#{$fa-css-prefix}-compass:before, .fa-duotone.#{$fa-css-prefix}-compass:before { content: fa-content(\f14e); }
.fad.#{$fa-css-prefix}-compass:after, .fa-duotone.#{$fa-css-prefix}-compass:after { content: fa-content(\10f14e); }
.fad.#{$fa-css-prefix}-compass-drafting:before, .fa-duotone.#{$fa-css-prefix}-compass-drafting:before { content: fa-content(\f568\fe01); }
.fad.#{$fa-css-prefix}-compass-drafting:after, .fa-duotone.#{$fa-css-prefix}-compass-drafting:after { content: fa-content(\f568\fe02); }
.fad.#{$fa-css-prefix}-drafting-compass:before, .fa-duotone.#{$fa-css-prefix}-drafting-compass:before { content: fa-content(\f568\fe01); }
.fad.#{$fa-css-prefix}-drafting-compass:after, .fa-duotone.#{$fa-css-prefix}-drafting-compass:after { content: fa-content(\f568\fe02); }
.fad.#{$fa-css-prefix}-compass-slash:before, .fa-duotone.#{$fa-css-prefix}-compass-slash:before { content: fa-content(\f5e9\fe01); }
.fad.#{$fa-css-prefix}-compass-slash:after, .fa-duotone.#{$fa-css-prefix}-compass-slash:after { content: fa-content(\f5e9\fe02); }
.fad.#{$fa-css-prefix}-compress:before, .fa-duotone.#{$fa-css-prefix}-compress:before { content: fa-content(\f066\fe01); }
.fad.#{$fa-css-prefix}-compress:after, .fa-duotone.#{$fa-css-prefix}-compress:after { content: fa-content(\f066\fe02); }
.fad.#{$fa-css-prefix}-compress-wide:before, .fa-duotone.#{$fa-css-prefix}-compress-wide:before { content: fa-content(\f326\fe01); }
.fad.#{$fa-css-prefix}-compress-wide:after, .fa-duotone.#{$fa-css-prefix}-compress-wide:after { content: fa-content(\f326\fe02); }
.fad.#{$fa-css-prefix}-computer-classic:before, .fa-duotone.#{$fa-css-prefix}-computer-classic:before { content: fa-content(\f8b1\fe01); }
.fad.#{$fa-css-prefix}-computer-classic:after, .fa-duotone.#{$fa-css-prefix}-computer-classic:after { content: fa-content(\f8b1\fe02); }
.fad.#{$fa-css-prefix}-computer-mouse:before, .fa-duotone.#{$fa-css-prefix}-computer-mouse:before { content: fa-content(\f8cc); }
.fad.#{$fa-css-prefix}-computer-mouse:after, .fa-duotone.#{$fa-css-prefix}-computer-mouse:after { content: fa-content(\10f8cc); }
.fad.#{$fa-css-prefix}-mouse:before, .fa-duotone.#{$fa-css-prefix}-mouse:before { content: fa-content(\f8cc); }
.fad.#{$fa-css-prefix}-mouse:after, .fa-duotone.#{$fa-css-prefix}-mouse:after { content: fa-content(\10f8cc); }
.fad.#{$fa-css-prefix}-computer-mouse-scrollwheel:before, .fa-duotone.#{$fa-css-prefix}-computer-mouse-scrollwheel:before { content: fa-content(\f8cd\fe01); }
.fad.#{$fa-css-prefix}-computer-mouse-scrollwheel:after, .fa-duotone.#{$fa-css-prefix}-computer-mouse-scrollwheel:after { content: fa-content(\f8cd\fe02); }
.fad.#{$fa-css-prefix}-mouse-alt:before, .fa-duotone.#{$fa-css-prefix}-mouse-alt:before { content: fa-content(\f8cd\fe01); }
.fad.#{$fa-css-prefix}-mouse-alt:after, .fa-duotone.#{$fa-css-prefix}-mouse-alt:after { content: fa-content(\f8cd\fe02); }
.fad.#{$fa-css-prefix}-computer-speaker:before, .fa-duotone.#{$fa-css-prefix}-computer-speaker:before { content: fa-content(\f8b2\fe01); }
.fad.#{$fa-css-prefix}-computer-speaker:after, .fa-duotone.#{$fa-css-prefix}-computer-speaker:after { content: fa-content(\f8b2\fe02); }
.fad.#{$fa-css-prefix}-container-storage:before, .fa-duotone.#{$fa-css-prefix}-container-storage:before { content: fa-content(\f4b7\fe01); }
.fad.#{$fa-css-prefix}-container-storage:after, .fa-duotone.#{$fa-css-prefix}-container-storage:after { content: fa-content(\f4b7\fe02); }
.fad.#{$fa-css-prefix}-conveyor-belt:before, .fa-duotone.#{$fa-css-prefix}-conveyor-belt:before { content: fa-content(\f46e\fe01); }
.fad.#{$fa-css-prefix}-conveyor-belt:after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt:after { content: fa-content(\f46e\fe02); }
.fad.#{$fa-css-prefix}-conveyor-belt-boxes:before, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-boxes:before { content: fa-content(\f46f\fe01); }
.fad.#{$fa-css-prefix}-conveyor-belt-boxes:after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-boxes:after { content: fa-content(\f46f\fe02); }
.fad.#{$fa-css-prefix}-conveyor-belt-alt:before, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-alt:before { content: fa-content(\f46f\fe01); }
.fad.#{$fa-css-prefix}-conveyor-belt-alt:after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-alt:after { content: fa-content(\f46f\fe02); }
.fad.#{$fa-css-prefix}-conveyor-belt-empty:before, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-empty:before { content: fa-content(\e150\fe01); }
.fad.#{$fa-css-prefix}-conveyor-belt-empty:after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-empty:after { content: fa-content(\e150\fe02); }
.fad.#{$fa-css-prefix}-cookie:before, .fa-duotone.#{$fa-css-prefix}-cookie:before { content: fa-content(\f563); }
.fad.#{$fa-css-prefix}-cookie:after, .fa-duotone.#{$fa-css-prefix}-cookie:after { content: fa-content(\10f563); }
.fad.#{$fa-css-prefix}-cookie-bite:before, .fa-duotone.#{$fa-css-prefix}-cookie-bite:before { content: fa-content(\f564\fe01); }
.fad.#{$fa-css-prefix}-cookie-bite:after, .fa-duotone.#{$fa-css-prefix}-cookie-bite:after { content: fa-content(\f564\fe02); }
.fad.#{$fa-css-prefix}-copy:before, .fa-duotone.#{$fa-css-prefix}-copy:before { content: fa-content(\f0c5\fe01); }
.fad.#{$fa-css-prefix}-copy:after, .fa-duotone.#{$fa-css-prefix}-copy:after { content: fa-content(\f0c5\fe02); }
.fad.#{$fa-css-prefix}-copyright:before, .fa-duotone.#{$fa-css-prefix}-copyright:before { content: fa-content(\a9\fe01); }
.fad.#{$fa-css-prefix}-copyright:after, .fa-duotone.#{$fa-css-prefix}-copyright:after { content: fa-content(\a9\fe02); }
.fad.#{$fa-css-prefix}-corn:before, .fa-duotone.#{$fa-css-prefix}-corn:before { content: fa-content(\f6c7); }
.fad.#{$fa-css-prefix}-corn:after, .fa-duotone.#{$fa-css-prefix}-corn:after { content: fa-content(\10f6c7); }
.fad.#{$fa-css-prefix}-corner:before, .fa-duotone.#{$fa-css-prefix}-corner:before { content: fa-content(\ac\fe01); }
.fad.#{$fa-css-prefix}-corner:after, .fa-duotone.#{$fa-css-prefix}-corner:after { content: fa-content(\ac\fe02); }
.fad.#{$fa-css-prefix}-couch:before, .fa-duotone.#{$fa-css-prefix}-couch:before { content: fa-content(\f4b8\fe01); }
.fad.#{$fa-css-prefix}-couch:after, .fa-duotone.#{$fa-css-prefix}-couch:after { content: fa-content(\f4b8\fe02); }
.fad.#{$fa-css-prefix}-cow:before, .fa-duotone.#{$fa-css-prefix}-cow:before { content: fa-content(\f6c8); }
.fad.#{$fa-css-prefix}-cow:after, .fa-duotone.#{$fa-css-prefix}-cow:after { content: fa-content(\10f6c8); }
.fad.#{$fa-css-prefix}-cowbell:before, .fa-duotone.#{$fa-css-prefix}-cowbell:before { content: fa-content(\f8b3\fe01); }
.fad.#{$fa-css-prefix}-cowbell:after, .fa-duotone.#{$fa-css-prefix}-cowbell:after { content: fa-content(\f8b3\fe02); }
.fad.#{$fa-css-prefix}-cowbell-circle-plus:before, .fa-duotone.#{$fa-css-prefix}-cowbell-circle-plus:before { content: fa-content(\f8b4\fe01); }
.fad.#{$fa-css-prefix}-cowbell-circle-plus:after, .fa-duotone.#{$fa-css-prefix}-cowbell-circle-plus:after { content: fa-content(\f8b4\fe02); }
.fad.#{$fa-css-prefix}-cowbell-more:before, .fa-duotone.#{$fa-css-prefix}-cowbell-more:before { content: fa-content(\f8b4\fe01); }
.fad.#{$fa-css-prefix}-cowbell-more:after, .fa-duotone.#{$fa-css-prefix}-cowbell-more:after { content: fa-content(\f8b4\fe02); }
.fad.#{$fa-css-prefix}-crate-apple:before, .fa-duotone.#{$fa-css-prefix}-crate-apple:before { content: fa-content(\f6b1\fe01); }
.fad.#{$fa-css-prefix}-crate-apple:after, .fa-duotone.#{$fa-css-prefix}-crate-apple:after { content: fa-content(\f6b1\fe02); }
.fad.#{$fa-css-prefix}-apple-crate:before, .fa-duotone.#{$fa-css-prefix}-apple-crate:before { content: fa-content(\f6b1\fe01); }
.fad.#{$fa-css-prefix}-apple-crate:after, .fa-duotone.#{$fa-css-prefix}-apple-crate:after { content: fa-content(\f6b1\fe02); }
.fad.#{$fa-css-prefix}-crate-empty:before, .fa-duotone.#{$fa-css-prefix}-crate-empty:before { content: fa-content(\e151\fe01); }
.fad.#{$fa-css-prefix}-crate-empty:after, .fa-duotone.#{$fa-css-prefix}-crate-empty:after { content: fa-content(\e151\fe02); }
.fad.#{$fa-css-prefix}-credit-card:before, .fa-duotone.#{$fa-css-prefix}-credit-card:before { content: fa-content(\f09d); }
.fad.#{$fa-css-prefix}-credit-card:after, .fa-duotone.#{$fa-css-prefix}-credit-card:after { content: fa-content(\10f09d); }
.fad.#{$fa-css-prefix}-credit-card-alt:before, .fa-duotone.#{$fa-css-prefix}-credit-card-alt:before { content: fa-content(\f09d); }
.fad.#{$fa-css-prefix}-credit-card-alt:after, .fa-duotone.#{$fa-css-prefix}-credit-card-alt:after { content: fa-content(\10f09d); }
.fad.#{$fa-css-prefix}-credit-card-blank:before, .fa-duotone.#{$fa-css-prefix}-credit-card-blank:before { content: fa-content(\f389\fe01); }
.fad.#{$fa-css-prefix}-credit-card-blank:after, .fa-duotone.#{$fa-css-prefix}-credit-card-blank:after { content: fa-content(\f389\fe02); }
.fad.#{$fa-css-prefix}-credit-card-front:before, .fa-duotone.#{$fa-css-prefix}-credit-card-front:before { content: fa-content(\f38a\fe01); }
.fad.#{$fa-css-prefix}-credit-card-front:after, .fa-duotone.#{$fa-css-prefix}-credit-card-front:after { content: fa-content(\f38a\fe02); }
.fad.#{$fa-css-prefix}-cricket-bat-ball:before, .fa-duotone.#{$fa-css-prefix}-cricket-bat-ball:before { content: fa-content(\f449); }
.fad.#{$fa-css-prefix}-cricket-bat-ball:after, .fa-duotone.#{$fa-css-prefix}-cricket-bat-ball:after { content: fa-content(\10f449); }
.fad.#{$fa-css-prefix}-cricket:before, .fa-duotone.#{$fa-css-prefix}-cricket:before { content: fa-content(\f449); }
.fad.#{$fa-css-prefix}-cricket:after, .fa-duotone.#{$fa-css-prefix}-cricket:after { content: fa-content(\10f449); }
.fad.#{$fa-css-prefix}-croissant:before, .fa-duotone.#{$fa-css-prefix}-croissant:before { content: fa-content(\f7f6); }
.fad.#{$fa-css-prefix}-croissant:after, .fa-duotone.#{$fa-css-prefix}-croissant:after { content: fa-content(\10f7f6); }
.fad.#{$fa-css-prefix}-crop:before, .fa-duotone.#{$fa-css-prefix}-crop:before { content: fa-content(\f125\fe01); }
.fad.#{$fa-css-prefix}-crop:after, .fa-duotone.#{$fa-css-prefix}-crop:after { content: fa-content(\f125\fe02); }
.fad.#{$fa-css-prefix}-crop-simple:before, .fa-duotone.#{$fa-css-prefix}-crop-simple:before { content: fa-content(\f565\fe01); }
.fad.#{$fa-css-prefix}-crop-simple:after, .fa-duotone.#{$fa-css-prefix}-crop-simple:after { content: fa-content(\f565\fe02); }
.fad.#{$fa-css-prefix}-crop-alt:before, .fa-duotone.#{$fa-css-prefix}-crop-alt:before { content: fa-content(\f565\fe01); }
.fad.#{$fa-css-prefix}-crop-alt:after, .fa-duotone.#{$fa-css-prefix}-crop-alt:after { content: fa-content(\f565\fe02); }
.fad.#{$fa-css-prefix}-cross:before, .fa-duotone.#{$fa-css-prefix}-cross:before { content: fa-content(\271d\fe01); }
.fad.#{$fa-css-prefix}-cross:after, .fa-duotone.#{$fa-css-prefix}-cross:after { content: fa-content(\271d\fe02); }
.fad.#{$fa-css-prefix}-crosshairs:before, .fa-duotone.#{$fa-css-prefix}-crosshairs:before { content: fa-content(\f05b\fe01); }
.fad.#{$fa-css-prefix}-crosshairs:after, .fa-duotone.#{$fa-css-prefix}-crosshairs:after { content: fa-content(\f05b\fe02); }
.fad.#{$fa-css-prefix}-crow:before, .fa-duotone.#{$fa-css-prefix}-crow:before { content: fa-content(\f520\fe01); }
.fad.#{$fa-css-prefix}-crow:after, .fa-duotone.#{$fa-css-prefix}-crow:after { content: fa-content(\f520\fe02); }
.fad.#{$fa-css-prefix}-crown:before, .fa-duotone.#{$fa-css-prefix}-crown:before { content: fa-content(\f521); }
.fad.#{$fa-css-prefix}-crown:after, .fa-duotone.#{$fa-css-prefix}-crown:after { content: fa-content(\10f521); }
.fad.#{$fa-css-prefix}-crutch:before, .fa-duotone.#{$fa-css-prefix}-crutch:before { content: fa-content(\f7f7\fe01); }
.fad.#{$fa-css-prefix}-crutch:after, .fa-duotone.#{$fa-css-prefix}-crutch:after { content: fa-content(\f7f7\fe02); }
.fad.#{$fa-css-prefix}-crutches:before, .fa-duotone.#{$fa-css-prefix}-crutches:before { content: fa-content(\f7f8\fe01); }
.fad.#{$fa-css-prefix}-crutches:after, .fa-duotone.#{$fa-css-prefix}-crutches:after { content: fa-content(\f7f8\fe02); }
.fad.#{$fa-css-prefix}-cruzeiro-sign:before, .fa-duotone.#{$fa-css-prefix}-cruzeiro-sign:before { content: fa-content(\e152\fe01); }
.fad.#{$fa-css-prefix}-cruzeiro-sign:after, .fa-duotone.#{$fa-css-prefix}-cruzeiro-sign:after { content: fa-content(\e152\fe02); }
.fad.#{$fa-css-prefix}-crystal-ball:before, .fa-duotone.#{$fa-css-prefix}-crystal-ball:before { content: fa-content(\e362\fe01); }
.fad.#{$fa-css-prefix}-crystal-ball:after, .fa-duotone.#{$fa-css-prefix}-crystal-ball:after { content: fa-content(\e362\fe02); }
.fad.#{$fa-css-prefix}-cube:before, .fa-duotone.#{$fa-css-prefix}-cube:before { content: fa-content(\f1b2\fe01); }
.fad.#{$fa-css-prefix}-cube:after, .fa-duotone.#{$fa-css-prefix}-cube:after { content: fa-content(\f1b2\fe02); }
.fad.#{$fa-css-prefix}-cubes:before, .fa-duotone.#{$fa-css-prefix}-cubes:before { content: fa-content(\f1b3\fe01); }
.fad.#{$fa-css-prefix}-cubes:after, .fa-duotone.#{$fa-css-prefix}-cubes:after { content: fa-content(\f1b3\fe02); }
.fad.#{$fa-css-prefix}-cup-straw:before, .fa-duotone.#{$fa-css-prefix}-cup-straw:before { content: fa-content(\e363\fe01); }
.fad.#{$fa-css-prefix}-cup-straw:after, .fa-duotone.#{$fa-css-prefix}-cup-straw:after { content: fa-content(\e363\fe02); }
.fad.#{$fa-css-prefix}-cup-straw-swoosh:before, .fa-duotone.#{$fa-css-prefix}-cup-straw-swoosh:before { content: fa-content(\e364\fe01); }
.fad.#{$fa-css-prefix}-cup-straw-swoosh:after, .fa-duotone.#{$fa-css-prefix}-cup-straw-swoosh:after { content: fa-content(\e364\fe02); }
.fad.#{$fa-css-prefix}-cup-togo:before, .fa-duotone.#{$fa-css-prefix}-cup-togo:before { content: fa-content(\f6c5\fe01); }
.fad.#{$fa-css-prefix}-cup-togo:after, .fa-duotone.#{$fa-css-prefix}-cup-togo:after { content: fa-content(\f6c5\fe02); }
.fad.#{$fa-css-prefix}-coffee-togo:before, .fa-duotone.#{$fa-css-prefix}-coffee-togo:before { content: fa-content(\f6c5\fe01); }
.fad.#{$fa-css-prefix}-coffee-togo:after, .fa-duotone.#{$fa-css-prefix}-coffee-togo:after { content: fa-content(\f6c5\fe02); }
.fad.#{$fa-css-prefix}-curling-stone:before, .fa-duotone.#{$fa-css-prefix}-curling-stone:before { content: fa-content(\f44a); }
.fad.#{$fa-css-prefix}-curling-stone:after, .fa-duotone.#{$fa-css-prefix}-curling-stone:after { content: fa-content(\10f44a); }
.fad.#{$fa-css-prefix}-curling:before, .fa-duotone.#{$fa-css-prefix}-curling:before { content: fa-content(\f44a); }
.fad.#{$fa-css-prefix}-curling:after, .fa-duotone.#{$fa-css-prefix}-curling:after { content: fa-content(\10f44a); }
.fad.#{$fa-css-prefix}-d:before, .fa-duotone.#{$fa-css-prefix}-d:before { content: fa-content(\44\fe01); }
.fad.#{$fa-css-prefix}-d:after, .fa-duotone.#{$fa-css-prefix}-d:after { content: fa-content(\44\fe02); }
.fad.#{$fa-css-prefix}-dagger:before, .fa-duotone.#{$fa-css-prefix}-dagger:before { content: fa-content(\f6cb\fe01); }
.fad.#{$fa-css-prefix}-dagger:after, .fa-duotone.#{$fa-css-prefix}-dagger:after { content: fa-content(\f6cb\fe02); }
.fad.#{$fa-css-prefix}-dash:before, .fa-duotone.#{$fa-css-prefix}-dash:before { content: fa-content(\2014\fe01); }
.fad.#{$fa-css-prefix}-dash:after, .fa-duotone.#{$fa-css-prefix}-dash:after { content: fa-content(\2014\fe02); }
.fad.#{$fa-css-prefix}-database:before, .fa-duotone.#{$fa-css-prefix}-database:before { content: fa-content(\f1c0\fe01); }
.fad.#{$fa-css-prefix}-database:after, .fa-duotone.#{$fa-css-prefix}-database:after { content: fa-content(\f1c0\fe02); }
.fad.#{$fa-css-prefix}-deer:before, .fa-duotone.#{$fa-css-prefix}-deer:before { content: fa-content(\f78e); }
.fad.#{$fa-css-prefix}-deer:after, .fa-duotone.#{$fa-css-prefix}-deer:after { content: fa-content(\10f78e); }
.fad.#{$fa-css-prefix}-deer-rudolph:before, .fa-duotone.#{$fa-css-prefix}-deer-rudolph:before { content: fa-content(\f78f\fe01); }
.fad.#{$fa-css-prefix}-deer-rudolph:after, .fa-duotone.#{$fa-css-prefix}-deer-rudolph:after { content: fa-content(\f78f\fe02); }
.fad.#{$fa-css-prefix}-delete-left:before, .fa-duotone.#{$fa-css-prefix}-delete-left:before { content: fa-content(\232b\fe01); }
.fad.#{$fa-css-prefix}-delete-left:after, .fa-duotone.#{$fa-css-prefix}-delete-left:after { content: fa-content(\232b\fe02); }
.fad.#{$fa-css-prefix}-backspace:before, .fa-duotone.#{$fa-css-prefix}-backspace:before { content: fa-content(\232b\fe01); }
.fad.#{$fa-css-prefix}-backspace:after, .fa-duotone.#{$fa-css-prefix}-backspace:after { content: fa-content(\232b\fe02); }
.fad.#{$fa-css-prefix}-delete-right:before, .fa-duotone.#{$fa-css-prefix}-delete-right:before { content: fa-content(\e154\fe01); }
.fad.#{$fa-css-prefix}-delete-right:after, .fa-duotone.#{$fa-css-prefix}-delete-right:after { content: fa-content(\e154\fe02); }
.fad.#{$fa-css-prefix}-democrat:before, .fa-duotone.#{$fa-css-prefix}-democrat:before { content: fa-content(\f747\fe01); }
.fad.#{$fa-css-prefix}-democrat:after, .fa-duotone.#{$fa-css-prefix}-democrat:after { content: fa-content(\f747\fe02); }
.fad.#{$fa-css-prefix}-desktop:before, .fa-duotone.#{$fa-css-prefix}-desktop:before { content: fa-content(\f390); }
.fad.#{$fa-css-prefix}-desktop:after, .fa-duotone.#{$fa-css-prefix}-desktop:after { content: fa-content(\10f390); }
.fad.#{$fa-css-prefix}-desktop-alt:before, .fa-duotone.#{$fa-css-prefix}-desktop-alt:before { content: fa-content(\f390); }
.fad.#{$fa-css-prefix}-desktop-alt:after, .fa-duotone.#{$fa-css-prefix}-desktop-alt:after { content: fa-content(\10f390); }
.fad.#{$fa-css-prefix}-desktop-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-desktop-arrow-down:before { content: fa-content(\e155\fe01); }
.fad.#{$fa-css-prefix}-desktop-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-desktop-arrow-down:after { content: fa-content(\e155\fe02); }
.fad.#{$fa-css-prefix}-dharmachakra:before, .fa-duotone.#{$fa-css-prefix}-dharmachakra:before { content: fa-content(\2638\fe01); }
.fad.#{$fa-css-prefix}-dharmachakra:after, .fa-duotone.#{$fa-css-prefix}-dharmachakra:after { content: fa-content(\2638\fe02); }
.fad.#{$fa-css-prefix}-diagram-lean-canvas:before, .fa-duotone.#{$fa-css-prefix}-diagram-lean-canvas:before { content: fa-content(\e156\fe01); }
.fad.#{$fa-css-prefix}-diagram-lean-canvas:after, .fa-duotone.#{$fa-css-prefix}-diagram-lean-canvas:after { content: fa-content(\e156\fe02); }
.fad.#{$fa-css-prefix}-diagram-nested:before, .fa-duotone.#{$fa-css-prefix}-diagram-nested:before { content: fa-content(\e157\fe01); }
.fad.#{$fa-css-prefix}-diagram-nested:after, .fa-duotone.#{$fa-css-prefix}-diagram-nested:after { content: fa-content(\e157\fe02); }
.fad.#{$fa-css-prefix}-diagram-project:before, .fa-duotone.#{$fa-css-prefix}-diagram-project:before { content: fa-content(\f542\fe01); }
.fad.#{$fa-css-prefix}-diagram-project:after, .fa-duotone.#{$fa-css-prefix}-diagram-project:after { content: fa-content(\f542\fe02); }
.fad.#{$fa-css-prefix}-project-diagram:before, .fa-duotone.#{$fa-css-prefix}-project-diagram:before { content: fa-content(\f542\fe01); }
.fad.#{$fa-css-prefix}-project-diagram:after, .fa-duotone.#{$fa-css-prefix}-project-diagram:after { content: fa-content(\f542\fe02); }
.fad.#{$fa-css-prefix}-diagram-sankey:before, .fa-duotone.#{$fa-css-prefix}-diagram-sankey:before { content: fa-content(\e158\fe01); }
.fad.#{$fa-css-prefix}-diagram-sankey:after, .fa-duotone.#{$fa-css-prefix}-diagram-sankey:after { content: fa-content(\e158\fe02); }
.fad.#{$fa-css-prefix}-diagram-venn:before, .fa-duotone.#{$fa-css-prefix}-diagram-venn:before { content: fa-content(\e15a\fe01); }
.fad.#{$fa-css-prefix}-diagram-venn:after, .fa-duotone.#{$fa-css-prefix}-diagram-venn:after { content: fa-content(\e15a\fe02); }
.fad.#{$fa-css-prefix}-dial:before, .fa-duotone.#{$fa-css-prefix}-dial:before { content: fa-content(\e15b\fe01); }
.fad.#{$fa-css-prefix}-dial:after, .fa-duotone.#{$fa-css-prefix}-dial:after { content: fa-content(\e15b\fe02); }
.fad.#{$fa-css-prefix}-dial-med-high:before, .fa-duotone.#{$fa-css-prefix}-dial-med-high:before { content: fa-content(\e15b\fe01); }
.fad.#{$fa-css-prefix}-dial-med-high:after, .fa-duotone.#{$fa-css-prefix}-dial-med-high:after { content: fa-content(\e15b\fe02); }
.fad.#{$fa-css-prefix}-dial-high:before, .fa-duotone.#{$fa-css-prefix}-dial-high:before { content: fa-content(\e15c\fe01); }
.fad.#{$fa-css-prefix}-dial-high:after, .fa-duotone.#{$fa-css-prefix}-dial-high:after { content: fa-content(\e15c\fe02); }
.fad.#{$fa-css-prefix}-dial-low:before, .fa-duotone.#{$fa-css-prefix}-dial-low:before { content: fa-content(\e15d\fe01); }
.fad.#{$fa-css-prefix}-dial-low:after, .fa-duotone.#{$fa-css-prefix}-dial-low:after { content: fa-content(\e15d\fe02); }
.fad.#{$fa-css-prefix}-dial-max:before, .fa-duotone.#{$fa-css-prefix}-dial-max:before { content: fa-content(\e15e\fe01); }
.fad.#{$fa-css-prefix}-dial-max:after, .fa-duotone.#{$fa-css-prefix}-dial-max:after { content: fa-content(\e15e\fe02); }
.fad.#{$fa-css-prefix}-dial-med:before, .fa-duotone.#{$fa-css-prefix}-dial-med:before { content: fa-content(\e15f\fe01); }
.fad.#{$fa-css-prefix}-dial-med:after, .fa-duotone.#{$fa-css-prefix}-dial-med:after { content: fa-content(\e15f\fe02); }
.fad.#{$fa-css-prefix}-dial-med-low:before, .fa-duotone.#{$fa-css-prefix}-dial-med-low:before { content: fa-content(\e160\fe01); }
.fad.#{$fa-css-prefix}-dial-med-low:after, .fa-duotone.#{$fa-css-prefix}-dial-med-low:after { content: fa-content(\e160\fe02); }
.fad.#{$fa-css-prefix}-dial-min:before, .fa-duotone.#{$fa-css-prefix}-dial-min:before { content: fa-content(\e161\fe01); }
.fad.#{$fa-css-prefix}-dial-min:after, .fa-duotone.#{$fa-css-prefix}-dial-min:after { content: fa-content(\e161\fe02); }
.fad.#{$fa-css-prefix}-dial-off:before, .fa-duotone.#{$fa-css-prefix}-dial-off:before { content: fa-content(\e162\fe01); }
.fad.#{$fa-css-prefix}-dial-off:after, .fa-duotone.#{$fa-css-prefix}-dial-off:after { content: fa-content(\e162\fe02); }
.fad.#{$fa-css-prefix}-diamond:before, .fa-duotone.#{$fa-css-prefix}-diamond:before { content: fa-content(\2666\fe01); }
.fad.#{$fa-css-prefix}-diamond:after, .fa-duotone.#{$fa-css-prefix}-diamond:after { content: fa-content(\2666\fe02); }
.fad.#{$fa-css-prefix}-diamond-turn-right:before, .fa-duotone.#{$fa-css-prefix}-diamond-turn-right:before { content: fa-content(\f5eb\fe01); }
.fad.#{$fa-css-prefix}-diamond-turn-right:after, .fa-duotone.#{$fa-css-prefix}-diamond-turn-right:after { content: fa-content(\f5eb\fe02); }
.fad.#{$fa-css-prefix}-directions:before, .fa-duotone.#{$fa-css-prefix}-directions:before { content: fa-content(\f5eb\fe01); }
.fad.#{$fa-css-prefix}-directions:after, .fa-duotone.#{$fa-css-prefix}-directions:after { content: fa-content(\f5eb\fe02); }
.fad.#{$fa-css-prefix}-dice:before, .fa-duotone.#{$fa-css-prefix}-dice:before { content: fa-content(\f522); }
.fad.#{$fa-css-prefix}-dice:after, .fa-duotone.#{$fa-css-prefix}-dice:after { content: fa-content(\10f522); }
.fad.#{$fa-css-prefix}-dice-d10:before, .fa-duotone.#{$fa-css-prefix}-dice-d10:before { content: fa-content(\f6cd\fe01); }
.fad.#{$fa-css-prefix}-dice-d10:after, .fa-duotone.#{$fa-css-prefix}-dice-d10:after { content: fa-content(\f6cd\fe02); }
.fad.#{$fa-css-prefix}-dice-d12:before, .fa-duotone.#{$fa-css-prefix}-dice-d12:before { content: fa-content(\f6ce\fe01); }
.fad.#{$fa-css-prefix}-dice-d12:after, .fa-duotone.#{$fa-css-prefix}-dice-d12:after { content: fa-content(\f6ce\fe02); }
.fad.#{$fa-css-prefix}-dice-d20:before, .fa-duotone.#{$fa-css-prefix}-dice-d20:before { content: fa-content(\f6cf\fe01); }
.fad.#{$fa-css-prefix}-dice-d20:after, .fa-duotone.#{$fa-css-prefix}-dice-d20:after { content: fa-content(\f6cf\fe02); }
.fad.#{$fa-css-prefix}-dice-d4:before, .fa-duotone.#{$fa-css-prefix}-dice-d4:before { content: fa-content(\f6d0\fe01); }
.fad.#{$fa-css-prefix}-dice-d4:after, .fa-duotone.#{$fa-css-prefix}-dice-d4:after { content: fa-content(\f6d0\fe02); }
.fad.#{$fa-css-prefix}-dice-d6:before, .fa-duotone.#{$fa-css-prefix}-dice-d6:before { content: fa-content(\f6d1\fe01); }
.fad.#{$fa-css-prefix}-dice-d6:after, .fa-duotone.#{$fa-css-prefix}-dice-d6:after { content: fa-content(\f6d1\fe02); }
.fad.#{$fa-css-prefix}-dice-d8:before, .fa-duotone.#{$fa-css-prefix}-dice-d8:before { content: fa-content(\f6d2\fe01); }
.fad.#{$fa-css-prefix}-dice-d8:after, .fa-duotone.#{$fa-css-prefix}-dice-d8:after { content: fa-content(\f6d2\fe02); }
.fad.#{$fa-css-prefix}-dice-five:before, .fa-duotone.#{$fa-css-prefix}-dice-five:before { content: fa-content(\2684\fe01); }
.fad.#{$fa-css-prefix}-dice-five:after, .fa-duotone.#{$fa-css-prefix}-dice-five:after { content: fa-content(\2684\fe02); }
.fad.#{$fa-css-prefix}-dice-four:before, .fa-duotone.#{$fa-css-prefix}-dice-four:before { content: fa-content(\2683\fe01); }
.fad.#{$fa-css-prefix}-dice-four:after, .fa-duotone.#{$fa-css-prefix}-dice-four:after { content: fa-content(\2683\fe02); }
.fad.#{$fa-css-prefix}-dice-one:before, .fa-duotone.#{$fa-css-prefix}-dice-one:before { content: fa-content(\2680\fe01); }
.fad.#{$fa-css-prefix}-dice-one:after, .fa-duotone.#{$fa-css-prefix}-dice-one:after { content: fa-content(\2680\fe02); }
.fad.#{$fa-css-prefix}-dice-six:before, .fa-duotone.#{$fa-css-prefix}-dice-six:before { content: fa-content(\2685\fe01); }
.fad.#{$fa-css-prefix}-dice-six:after, .fa-duotone.#{$fa-css-prefix}-dice-six:after { content: fa-content(\2685\fe02); }
.fad.#{$fa-css-prefix}-dice-three:before, .fa-duotone.#{$fa-css-prefix}-dice-three:before { content: fa-content(\2682\fe01); }
.fad.#{$fa-css-prefix}-dice-three:after, .fa-duotone.#{$fa-css-prefix}-dice-three:after { content: fa-content(\2682\fe02); }
.fad.#{$fa-css-prefix}-dice-two:before, .fa-duotone.#{$fa-css-prefix}-dice-two:before { content: fa-content(\2681\fe01); }
.fad.#{$fa-css-prefix}-dice-two:after, .fa-duotone.#{$fa-css-prefix}-dice-two:after { content: fa-content(\2681\fe02); }
.fad.#{$fa-css-prefix}-diploma:before, .fa-duotone.#{$fa-css-prefix}-diploma:before { content: fa-content(\f5ea\fe01); }
.fad.#{$fa-css-prefix}-diploma:after, .fa-duotone.#{$fa-css-prefix}-diploma:after { content: fa-content(\f5ea\fe02); }
.fad.#{$fa-css-prefix}-scroll-ribbon:before, .fa-duotone.#{$fa-css-prefix}-scroll-ribbon:before { content: fa-content(\f5ea\fe01); }
.fad.#{$fa-css-prefix}-scroll-ribbon:after, .fa-duotone.#{$fa-css-prefix}-scroll-ribbon:after { content: fa-content(\f5ea\fe02); }
.fad.#{$fa-css-prefix}-disc-drive:before, .fa-duotone.#{$fa-css-prefix}-disc-drive:before { content: fa-content(\f8b5\fe01); }
.fad.#{$fa-css-prefix}-disc-drive:after, .fa-duotone.#{$fa-css-prefix}-disc-drive:after { content: fa-content(\f8b5\fe02); }
.fad.#{$fa-css-prefix}-disease:before, .fa-duotone.#{$fa-css-prefix}-disease:before { content: fa-content(\f7fa\fe01); }
.fad.#{$fa-css-prefix}-disease:after, .fa-duotone.#{$fa-css-prefix}-disease:after { content: fa-content(\f7fa\fe02); }
.fad.#{$fa-css-prefix}-display:before, .fa-duotone.#{$fa-css-prefix}-display:before { content: fa-content(\e163\fe01); }
.fad.#{$fa-css-prefix}-display:after, .fa-duotone.#{$fa-css-prefix}-display:after { content: fa-content(\e163\fe02); }
.fad.#{$fa-css-prefix}-display-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-display-arrow-down:before { content: fa-content(\e164\fe01); }
.fad.#{$fa-css-prefix}-display-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-display-arrow-down:after { content: fa-content(\e164\fe02); }
.fad.#{$fa-css-prefix}-display-code:before, .fa-duotone.#{$fa-css-prefix}-display-code:before { content: fa-content(\e165\fe01); }
.fad.#{$fa-css-prefix}-display-code:after, .fa-duotone.#{$fa-css-prefix}-display-code:after { content: fa-content(\e165\fe02); }
.fad.#{$fa-css-prefix}-desktop-code:before, .fa-duotone.#{$fa-css-prefix}-desktop-code:before { content: fa-content(\e165\fe01); }
.fad.#{$fa-css-prefix}-desktop-code:after, .fa-duotone.#{$fa-css-prefix}-desktop-code:after { content: fa-content(\e165\fe02); }
.fad.#{$fa-css-prefix}-display-medical:before, .fa-duotone.#{$fa-css-prefix}-display-medical:before { content: fa-content(\e166\fe01); }
.fad.#{$fa-css-prefix}-display-medical:after, .fa-duotone.#{$fa-css-prefix}-display-medical:after { content: fa-content(\e166\fe02); }
.fad.#{$fa-css-prefix}-desktop-medical:before, .fa-duotone.#{$fa-css-prefix}-desktop-medical:before { content: fa-content(\e166\fe01); }
.fad.#{$fa-css-prefix}-desktop-medical:after, .fa-duotone.#{$fa-css-prefix}-desktop-medical:after { content: fa-content(\e166\fe02); }
.fad.#{$fa-css-prefix}-display-slash:before, .fa-duotone.#{$fa-css-prefix}-display-slash:before { content: fa-content(\e2fa\fe01); }
.fad.#{$fa-css-prefix}-display-slash:after, .fa-duotone.#{$fa-css-prefix}-display-slash:after { content: fa-content(\e2fa\fe02); }
.fad.#{$fa-css-prefix}-desktop-slash:before, .fa-duotone.#{$fa-css-prefix}-desktop-slash:before { content: fa-content(\e2fa\fe01); }
.fad.#{$fa-css-prefix}-desktop-slash:after, .fa-duotone.#{$fa-css-prefix}-desktop-slash:after { content: fa-content(\e2fa\fe02); }
.fad.#{$fa-css-prefix}-distribute-spacing-horizontal:before, .fa-duotone.#{$fa-css-prefix}-distribute-spacing-horizontal:before { content: fa-content(\e365\fe01); }
.fad.#{$fa-css-prefix}-distribute-spacing-horizontal:after, .fa-duotone.#{$fa-css-prefix}-distribute-spacing-horizontal:after { content: fa-content(\e365\fe02); }
.fad.#{$fa-css-prefix}-distribute-spacing-vertical:before, .fa-duotone.#{$fa-css-prefix}-distribute-spacing-vertical:before { content: fa-content(\e366\fe01); }
.fad.#{$fa-css-prefix}-distribute-spacing-vertical:after, .fa-duotone.#{$fa-css-prefix}-distribute-spacing-vertical:after { content: fa-content(\e366\fe02); }
.fad.#{$fa-css-prefix}-ditto:before, .fa-duotone.#{$fa-css-prefix}-ditto:before { content: fa-content(\22\fe01); }
.fad.#{$fa-css-prefix}-ditto:after, .fa-duotone.#{$fa-css-prefix}-ditto:after { content: fa-content(\22\fe02); }
.fad.#{$fa-css-prefix}-divide:before, .fa-duotone.#{$fa-css-prefix}-divide:before { content: fa-content(\f7\fe01); }
.fad.#{$fa-css-prefix}-divide:after, .fa-duotone.#{$fa-css-prefix}-divide:after { content: fa-content(\f7\fe02); }
.fad.#{$fa-css-prefix}-dna:before, .fa-duotone.#{$fa-css-prefix}-dna:before { content: fa-content(\f471); }
.fad.#{$fa-css-prefix}-dna:after, .fa-duotone.#{$fa-css-prefix}-dna:after { content: fa-content(\10f471); }
.fad.#{$fa-css-prefix}-do-not-enter:before, .fa-duotone.#{$fa-css-prefix}-do-not-enter:before { content: fa-content(\f5ec\fe01); }
.fad.#{$fa-css-prefix}-do-not-enter:after, .fa-duotone.#{$fa-css-prefix}-do-not-enter:after { content: fa-content(\f5ec\fe02); }
.fad.#{$fa-css-prefix}-dog:before, .fa-duotone.#{$fa-css-prefix}-dog:before { content: fa-content(\f6d3); }
.fad.#{$fa-css-prefix}-dog:after, .fa-duotone.#{$fa-css-prefix}-dog:after { content: fa-content(\10f6d3); }
.fad.#{$fa-css-prefix}-dog-leashed:before, .fa-duotone.#{$fa-css-prefix}-dog-leashed:before { content: fa-content(\f6d4); }
.fad.#{$fa-css-prefix}-dog-leashed:after, .fa-duotone.#{$fa-css-prefix}-dog-leashed:after { content: fa-content(\10f6d4); }
.fad.#{$fa-css-prefix}-dollar-sign:before, .fa-duotone.#{$fa-css-prefix}-dollar-sign:before { content: fa-content(\24\fe01); }
.fad.#{$fa-css-prefix}-dollar-sign:after, .fa-duotone.#{$fa-css-prefix}-dollar-sign:after { content: fa-content(\24\fe02); }
.fad.#{$fa-css-prefix}-dollar:before, .fa-duotone.#{$fa-css-prefix}-dollar:before { content: fa-content(\24\fe01); }
.fad.#{$fa-css-prefix}-dollar:after, .fa-duotone.#{$fa-css-prefix}-dollar:after { content: fa-content(\24\fe02); }
.fad.#{$fa-css-prefix}-usd:before, .fa-duotone.#{$fa-css-prefix}-usd:before { content: fa-content(\24\fe01); }
.fad.#{$fa-css-prefix}-usd:after, .fa-duotone.#{$fa-css-prefix}-usd:after { content: fa-content(\24\fe02); }
.fad.#{$fa-css-prefix}-dolly:before, .fa-duotone.#{$fa-css-prefix}-dolly:before { content: fa-content(\f472\fe01); }
.fad.#{$fa-css-prefix}-dolly:after, .fa-duotone.#{$fa-css-prefix}-dolly:after { content: fa-content(\f472\fe02); }
.fad.#{$fa-css-prefix}-dolly-box:before, .fa-duotone.#{$fa-css-prefix}-dolly-box:before { content: fa-content(\f472\fe01); }
.fad.#{$fa-css-prefix}-dolly-box:after, .fa-duotone.#{$fa-css-prefix}-dolly-box:after { content: fa-content(\f472\fe02); }
.fad.#{$fa-css-prefix}-dolly-empty:before, .fa-duotone.#{$fa-css-prefix}-dolly-empty:before { content: fa-content(\f473\fe01); }
.fad.#{$fa-css-prefix}-dolly-empty:after, .fa-duotone.#{$fa-css-prefix}-dolly-empty:after { content: fa-content(\f473\fe02); }
.fad.#{$fa-css-prefix}-dolphin:before, .fa-duotone.#{$fa-css-prefix}-dolphin:before { content: fa-content(\e168\fe01); }
.fad.#{$fa-css-prefix}-dolphin:after, .fa-duotone.#{$fa-css-prefix}-dolphin:after { content: fa-content(\e168\fe02); }
.fad.#{$fa-css-prefix}-dong-sign:before, .fa-duotone.#{$fa-css-prefix}-dong-sign:before { content: fa-content(\e169\fe01); }
.fad.#{$fa-css-prefix}-dong-sign:after, .fa-duotone.#{$fa-css-prefix}-dong-sign:after { content: fa-content(\e169\fe02); }
.fad.#{$fa-css-prefix}-door-closed:before, .fa-duotone.#{$fa-css-prefix}-door-closed:before { content: fa-content(\f52a); }
.fad.#{$fa-css-prefix}-door-closed:after, .fa-duotone.#{$fa-css-prefix}-door-closed:after { content: fa-content(\10f52a); }
.fad.#{$fa-css-prefix}-door-open:before, .fa-duotone.#{$fa-css-prefix}-door-open:before { content: fa-content(\f52b\fe01); }
.fad.#{$fa-css-prefix}-door-open:after, .fa-duotone.#{$fa-css-prefix}-door-open:after { content: fa-content(\f52b\fe02); }
.fad.#{$fa-css-prefix}-dove:before, .fa-duotone.#{$fa-css-prefix}-dove:before { content: fa-content(\f4ba); }
.fad.#{$fa-css-prefix}-dove:after, .fa-duotone.#{$fa-css-prefix}-dove:after { content: fa-content(\10f4ba); }
.fad.#{$fa-css-prefix}-down:before, .fa-duotone.#{$fa-css-prefix}-down:before { content: fa-content(\f354\fe01); }
.fad.#{$fa-css-prefix}-down:after, .fa-duotone.#{$fa-css-prefix}-down:after { content: fa-content(\f354\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-down:before { content: fa-content(\f354\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-down:after { content: fa-content(\f354\fe02); }
.fad.#{$fa-css-prefix}-down-from-line:before, .fa-duotone.#{$fa-css-prefix}-down-from-line:before { content: fa-content(\f349\fe01); }
.fad.#{$fa-css-prefix}-down-from-line:after, .fa-duotone.#{$fa-css-prefix}-down-from-line:after { content: fa-content(\f349\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-from-top:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-top:before { content: fa-content(\f349\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-from-top:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-top:after { content: fa-content(\f349\fe02); }
.fad.#{$fa-css-prefix}-down-left:before, .fa-duotone.#{$fa-css-prefix}-down-left:before { content: fa-content(\e16a\fe01); }
.fad.#{$fa-css-prefix}-down-left:after, .fa-duotone.#{$fa-css-prefix}-down-left:after { content: fa-content(\e16a\fe02); }
.fad.#{$fa-css-prefix}-down-left-and-up-right-to-center:before, .fa-duotone.#{$fa-css-prefix}-down-left-and-up-right-to-center:before { content: fa-content(\f422\fe01); }
.fad.#{$fa-css-prefix}-down-left-and-up-right-to-center:after, .fa-duotone.#{$fa-css-prefix}-down-left-and-up-right-to-center:after { content: fa-content(\f422\fe02); }
.fad.#{$fa-css-prefix}-compress-alt:before, .fa-duotone.#{$fa-css-prefix}-compress-alt:before { content: fa-content(\f422\fe01); }
.fad.#{$fa-css-prefix}-compress-alt:after, .fa-duotone.#{$fa-css-prefix}-compress-alt:after { content: fa-content(\f422\fe02); }
.fad.#{$fa-css-prefix}-down-long:before, .fa-duotone.#{$fa-css-prefix}-down-long:before { content: fa-content(\f309\fe01); }
.fad.#{$fa-css-prefix}-down-long:after, .fa-duotone.#{$fa-css-prefix}-down-long:after { content: fa-content(\f309\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-alt-down:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-down:before { content: fa-content(\f309\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-alt-down:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-down:after { content: fa-content(\f309\fe02); }
.fad.#{$fa-css-prefix}-down-right:before, .fa-duotone.#{$fa-css-prefix}-down-right:before { content: fa-content(\e16b\fe01); }
.fad.#{$fa-css-prefix}-down-right:after, .fa-duotone.#{$fa-css-prefix}-down-right:after { content: fa-content(\e16b\fe02); }
.fad.#{$fa-css-prefix}-down-to-line:before, .fa-duotone.#{$fa-css-prefix}-down-to-line:before { content: fa-content(\f34a\fe01); }
.fad.#{$fa-css-prefix}-down-to-line:after, .fa-duotone.#{$fa-css-prefix}-down-to-line:after { content: fa-content(\f34a\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-to-bottom:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-bottom:before { content: fa-content(\f34a\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-to-bottom:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-bottom:after { content: fa-content(\f34a\fe02); }
.fad.#{$fa-css-prefix}-download:before, .fa-duotone.#{$fa-css-prefix}-download:before { content: fa-content(\f019\fe01); }
.fad.#{$fa-css-prefix}-download:after, .fa-duotone.#{$fa-css-prefix}-download:after { content: fa-content(\f019\fe02); }
.fad.#{$fa-css-prefix}-dragon:before, .fa-duotone.#{$fa-css-prefix}-dragon:before { content: fa-content(\f6d5); }
.fad.#{$fa-css-prefix}-dragon:after, .fa-duotone.#{$fa-css-prefix}-dragon:after { content: fa-content(\10f6d5); }
.fad.#{$fa-css-prefix}-draw-circle:before, .fa-duotone.#{$fa-css-prefix}-draw-circle:before { content: fa-content(\f5ed\fe01); }
.fad.#{$fa-css-prefix}-draw-circle:after, .fa-duotone.#{$fa-css-prefix}-draw-circle:after { content: fa-content(\f5ed\fe02); }
.fad.#{$fa-css-prefix}-draw-polygon:before, .fa-duotone.#{$fa-css-prefix}-draw-polygon:before { content: fa-content(\f5ee\fe01); }
.fad.#{$fa-css-prefix}-draw-polygon:after, .fa-duotone.#{$fa-css-prefix}-draw-polygon:after { content: fa-content(\f5ee\fe02); }
.fad.#{$fa-css-prefix}-draw-square:before, .fa-duotone.#{$fa-css-prefix}-draw-square:before { content: fa-content(\f5ef\fe01); }
.fad.#{$fa-css-prefix}-draw-square:after, .fa-duotone.#{$fa-css-prefix}-draw-square:after { content: fa-content(\f5ef\fe02); }
.fad.#{$fa-css-prefix}-dreidel:before, .fa-duotone.#{$fa-css-prefix}-dreidel:before { content: fa-content(\f792\fe01); }
.fad.#{$fa-css-prefix}-dreidel:after, .fa-duotone.#{$fa-css-prefix}-dreidel:after { content: fa-content(\f792\fe02); }
.fad.#{$fa-css-prefix}-drone:before, .fa-duotone.#{$fa-css-prefix}-drone:before { content: fa-content(\f85f\fe01); }
.fad.#{$fa-css-prefix}-drone:after, .fa-duotone.#{$fa-css-prefix}-drone:after { content: fa-content(\f85f\fe02); }
.fad.#{$fa-css-prefix}-drone-front:before, .fa-duotone.#{$fa-css-prefix}-drone-front:before { content: fa-content(\f860\fe01); }
.fad.#{$fa-css-prefix}-drone-front:after, .fa-duotone.#{$fa-css-prefix}-drone-front:after { content: fa-content(\f860\fe02); }
.fad.#{$fa-css-prefix}-drone-alt:before, .fa-duotone.#{$fa-css-prefix}-drone-alt:before { content: fa-content(\f860\fe01); }
.fad.#{$fa-css-prefix}-drone-alt:after, .fa-duotone.#{$fa-css-prefix}-drone-alt:after { content: fa-content(\f860\fe02); }
.fad.#{$fa-css-prefix}-droplet:before, .fa-duotone.#{$fa-css-prefix}-droplet:before { content: fa-content(\f043); }
.fad.#{$fa-css-prefix}-droplet:after, .fa-duotone.#{$fa-css-prefix}-droplet:after { content: fa-content(\10f043); }
.fad.#{$fa-css-prefix}-tint:before, .fa-duotone.#{$fa-css-prefix}-tint:before { content: fa-content(\f043); }
.fad.#{$fa-css-prefix}-tint:after, .fa-duotone.#{$fa-css-prefix}-tint:after { content: fa-content(\10f043); }
.fad.#{$fa-css-prefix}-droplet-degree:before, .fa-duotone.#{$fa-css-prefix}-droplet-degree:before { content: fa-content(\f748\fe01); }
.fad.#{$fa-css-prefix}-droplet-degree:after, .fa-duotone.#{$fa-css-prefix}-droplet-degree:after { content: fa-content(\f748\fe02); }
.fad.#{$fa-css-prefix}-dewpoint:before, .fa-duotone.#{$fa-css-prefix}-dewpoint:before { content: fa-content(\f748\fe01); }
.fad.#{$fa-css-prefix}-dewpoint:after, .fa-duotone.#{$fa-css-prefix}-dewpoint:after { content: fa-content(\f748\fe02); }
.fad.#{$fa-css-prefix}-droplet-percent:before, .fa-duotone.#{$fa-css-prefix}-droplet-percent:before { content: fa-content(\f750\fe01); }
.fad.#{$fa-css-prefix}-droplet-percent:after, .fa-duotone.#{$fa-css-prefix}-droplet-percent:after { content: fa-content(\f750\fe02); }
.fad.#{$fa-css-prefix}-humidity:before, .fa-duotone.#{$fa-css-prefix}-humidity:before { content: fa-content(\f750\fe01); }
.fad.#{$fa-css-prefix}-humidity:after, .fa-duotone.#{$fa-css-prefix}-humidity:after { content: fa-content(\f750\fe02); }
.fad.#{$fa-css-prefix}-droplet-slash:before, .fa-duotone.#{$fa-css-prefix}-droplet-slash:before { content: fa-content(\f5c7\fe01); }
.fad.#{$fa-css-prefix}-droplet-slash:after, .fa-duotone.#{$fa-css-prefix}-droplet-slash:after { content: fa-content(\f5c7\fe02); }
.fad.#{$fa-css-prefix}-tint-slash:before, .fa-duotone.#{$fa-css-prefix}-tint-slash:before { content: fa-content(\f5c7\fe01); }
.fad.#{$fa-css-prefix}-tint-slash:after, .fa-duotone.#{$fa-css-prefix}-tint-slash:after { content: fa-content(\f5c7\fe02); }
.fad.#{$fa-css-prefix}-drum:before, .fa-duotone.#{$fa-css-prefix}-drum:before { content: fa-content(\f569); }
.fad.#{$fa-css-prefix}-drum:after, .fa-duotone.#{$fa-css-prefix}-drum:after { content: fa-content(\10f569); }
.fad.#{$fa-css-prefix}-drum-steelpan:before, .fa-duotone.#{$fa-css-prefix}-drum-steelpan:before { content: fa-content(\f56a\fe01); }
.fad.#{$fa-css-prefix}-drum-steelpan:after, .fa-duotone.#{$fa-css-prefix}-drum-steelpan:after { content: fa-content(\f56a\fe02); }
.fad.#{$fa-css-prefix}-drumstick:before, .fa-duotone.#{$fa-css-prefix}-drumstick:before { content: fa-content(\f6d6); }
.fad.#{$fa-css-prefix}-drumstick:after, .fa-duotone.#{$fa-css-prefix}-drumstick:after { content: fa-content(\10f6d6); }
.fad.#{$fa-css-prefix}-drumstick-bite:before, .fa-duotone.#{$fa-css-prefix}-drumstick-bite:before { content: fa-content(\f6d7\fe01); }
.fad.#{$fa-css-prefix}-drumstick-bite:after, .fa-duotone.#{$fa-css-prefix}-drumstick-bite:after { content: fa-content(\f6d7\fe02); }
.fad.#{$fa-css-prefix}-dryer:before, .fa-duotone.#{$fa-css-prefix}-dryer:before { content: fa-content(\f861\fe01); }
.fad.#{$fa-css-prefix}-dryer:after, .fa-duotone.#{$fa-css-prefix}-dryer:after { content: fa-content(\f861\fe02); }
.fad.#{$fa-css-prefix}-dryer-heat:before, .fa-duotone.#{$fa-css-prefix}-dryer-heat:before { content: fa-content(\f862\fe01); }
.fad.#{$fa-css-prefix}-dryer-heat:after, .fa-duotone.#{$fa-css-prefix}-dryer-heat:after { content: fa-content(\f862\fe02); }
.fad.#{$fa-css-prefix}-dryer-alt:before, .fa-duotone.#{$fa-css-prefix}-dryer-alt:before { content: fa-content(\f862\fe01); }
.fad.#{$fa-css-prefix}-dryer-alt:after, .fa-duotone.#{$fa-css-prefix}-dryer-alt:after { content: fa-content(\f862\fe02); }
.fad.#{$fa-css-prefix}-duck:before, .fa-duotone.#{$fa-css-prefix}-duck:before { content: fa-content(\f6d8); }
.fad.#{$fa-css-prefix}-duck:after, .fa-duotone.#{$fa-css-prefix}-duck:after { content: fa-content(\10f6d8); }
.fad.#{$fa-css-prefix}-dumbbell:before, .fa-duotone.#{$fa-css-prefix}-dumbbell:before { content: fa-content(\f44b\fe01); }
.fad.#{$fa-css-prefix}-dumbbell:after, .fa-duotone.#{$fa-css-prefix}-dumbbell:after { content: fa-content(\f44b\fe02); }
.fad.#{$fa-css-prefix}-dumpster:before, .fa-duotone.#{$fa-css-prefix}-dumpster:before { content: fa-content(\f793\fe01); }
.fad.#{$fa-css-prefix}-dumpster:after, .fa-duotone.#{$fa-css-prefix}-dumpster:after { content: fa-content(\f793\fe02); }
.fad.#{$fa-css-prefix}-dumpster-fire:before, .fa-duotone.#{$fa-css-prefix}-dumpster-fire:before { content: fa-content(\f794\fe01); }
.fad.#{$fa-css-prefix}-dumpster-fire:after, .fa-duotone.#{$fa-css-prefix}-dumpster-fire:after { content: fa-content(\f794\fe02); }
.fad.#{$fa-css-prefix}-dungeon:before, .fa-duotone.#{$fa-css-prefix}-dungeon:before { content: fa-content(\f6d9\fe01); }
.fad.#{$fa-css-prefix}-dungeon:after, .fa-duotone.#{$fa-css-prefix}-dungeon:after { content: fa-content(\f6d9\fe02); }
.fad.#{$fa-css-prefix}-e:before, .fa-duotone.#{$fa-css-prefix}-e:before { content: fa-content(\45\fe01); }
.fad.#{$fa-css-prefix}-e:after, .fa-duotone.#{$fa-css-prefix}-e:after { content: fa-content(\45\fe02); }
.fad.#{$fa-css-prefix}-ear:before, .fa-duotone.#{$fa-css-prefix}-ear:before { content: fa-content(\f5f0); }
.fad.#{$fa-css-prefix}-ear:after, .fa-duotone.#{$fa-css-prefix}-ear:after { content: fa-content(\10f5f0); }
.fad.#{$fa-css-prefix}-ear-deaf:before, .fa-duotone.#{$fa-css-prefix}-ear-deaf:before { content: fa-content(\f2a4\fe01); }
.fad.#{$fa-css-prefix}-ear-deaf:after, .fa-duotone.#{$fa-css-prefix}-ear-deaf:after { content: fa-content(\f2a4\fe02); }
.fad.#{$fa-css-prefix}-deaf:before, .fa-duotone.#{$fa-css-prefix}-deaf:before { content: fa-content(\f2a4\fe01); }
.fad.#{$fa-css-prefix}-deaf:after, .fa-duotone.#{$fa-css-prefix}-deaf:after { content: fa-content(\f2a4\fe02); }
.fad.#{$fa-css-prefix}-deafness:before, .fa-duotone.#{$fa-css-prefix}-deafness:before { content: fa-content(\f2a4\fe01); }
.fad.#{$fa-css-prefix}-deafness:after, .fa-duotone.#{$fa-css-prefix}-deafness:after { content: fa-content(\f2a4\fe02); }
.fad.#{$fa-css-prefix}-hard-of-hearing:before, .fa-duotone.#{$fa-css-prefix}-hard-of-hearing:before { content: fa-content(\f2a4\fe01); }
.fad.#{$fa-css-prefix}-hard-of-hearing:after, .fa-duotone.#{$fa-css-prefix}-hard-of-hearing:after { content: fa-content(\f2a4\fe02); }
.fad.#{$fa-css-prefix}-ear-listen:before, .fa-duotone.#{$fa-css-prefix}-ear-listen:before { content: fa-content(\f2a2\fe01); }
.fad.#{$fa-css-prefix}-ear-listen:after, .fa-duotone.#{$fa-css-prefix}-ear-listen:after { content: fa-content(\f2a2\fe02); }
.fad.#{$fa-css-prefix}-assistive-listening-systems:before, .fa-duotone.#{$fa-css-prefix}-assistive-listening-systems:before { content: fa-content(\f2a2\fe01); }
.fad.#{$fa-css-prefix}-assistive-listening-systems:after, .fa-duotone.#{$fa-css-prefix}-assistive-listening-systems:after { content: fa-content(\f2a2\fe02); }
.fad.#{$fa-css-prefix}-ear-muffs:before, .fa-duotone.#{$fa-css-prefix}-ear-muffs:before { content: fa-content(\f795\fe01); }
.fad.#{$fa-css-prefix}-ear-muffs:after, .fa-duotone.#{$fa-css-prefix}-ear-muffs:after { content: fa-content(\f795\fe02); }
.fad.#{$fa-css-prefix}-earth-africa:before, .fa-duotone.#{$fa-css-prefix}-earth-africa:before { content: fa-content(\f57c); }
.fad.#{$fa-css-prefix}-earth-africa:after, .fa-duotone.#{$fa-css-prefix}-earth-africa:after { content: fa-content(\10f57c); }
.fad.#{$fa-css-prefix}-globe-africa:before, .fa-duotone.#{$fa-css-prefix}-globe-africa:before { content: fa-content(\f57c); }
.fad.#{$fa-css-prefix}-globe-africa:after, .fa-duotone.#{$fa-css-prefix}-globe-africa:after { content: fa-content(\10f57c); }
.fad.#{$fa-css-prefix}-earth-americas:before, .fa-duotone.#{$fa-css-prefix}-earth-americas:before { content: fa-content(\f57d); }
.fad.#{$fa-css-prefix}-earth-americas:after, .fa-duotone.#{$fa-css-prefix}-earth-americas:after { content: fa-content(\10f57d); }
.fad.#{$fa-css-prefix}-earth:before, .fa-duotone.#{$fa-css-prefix}-earth:before { content: fa-content(\f57d); }
.fad.#{$fa-css-prefix}-earth:after, .fa-duotone.#{$fa-css-prefix}-earth:after { content: fa-content(\10f57d); }
.fad.#{$fa-css-prefix}-globe-americas:before, .fa-duotone.#{$fa-css-prefix}-globe-americas:before { content: fa-content(\f57d); }
.fad.#{$fa-css-prefix}-globe-americas:after, .fa-duotone.#{$fa-css-prefix}-globe-americas:after { content: fa-content(\10f57d); }
.fad.#{$fa-css-prefix}-earth-asia:before, .fa-duotone.#{$fa-css-prefix}-earth-asia:before { content: fa-content(\f57e); }
.fad.#{$fa-css-prefix}-earth-asia:after, .fa-duotone.#{$fa-css-prefix}-earth-asia:after { content: fa-content(\10f57e); }
.fad.#{$fa-css-prefix}-globe-asia:before, .fa-duotone.#{$fa-css-prefix}-globe-asia:before { content: fa-content(\f57e); }
.fad.#{$fa-css-prefix}-globe-asia:after, .fa-duotone.#{$fa-css-prefix}-globe-asia:after { content: fa-content(\10f57e); }
.fad.#{$fa-css-prefix}-earth-europa:before, .fa-duotone.#{$fa-css-prefix}-earth-europa:before { content: fa-content(\f7a2\fe01); }
.fad.#{$fa-css-prefix}-earth-europa:after, .fa-duotone.#{$fa-css-prefix}-earth-europa:after { content: fa-content(\f7a2\fe02); }
.fad.#{$fa-css-prefix}-globe-europe:before, .fa-duotone.#{$fa-css-prefix}-globe-europe:before { content: fa-content(\f7a2\fe01); }
.fad.#{$fa-css-prefix}-globe-europe:after, .fa-duotone.#{$fa-css-prefix}-globe-europe:after { content: fa-content(\f7a2\fe02); }
.fad.#{$fa-css-prefix}-eclipse:before, .fa-duotone.#{$fa-css-prefix}-eclipse:before { content: fa-content(\f749\fe01); }
.fad.#{$fa-css-prefix}-eclipse:after, .fa-duotone.#{$fa-css-prefix}-eclipse:after { content: fa-content(\f749\fe02); }
.fad.#{$fa-css-prefix}-egg:before, .fa-duotone.#{$fa-css-prefix}-egg:before { content: fa-content(\f7fb); }
.fad.#{$fa-css-prefix}-egg:after, .fa-duotone.#{$fa-css-prefix}-egg:after { content: fa-content(\10f7fb); }
.fad.#{$fa-css-prefix}-egg-fried:before, .fa-duotone.#{$fa-css-prefix}-egg-fried:before { content: fa-content(\f7fc\fe01); }
.fad.#{$fa-css-prefix}-egg-fried:after, .fa-duotone.#{$fa-css-prefix}-egg-fried:after { content: fa-content(\f7fc\fe02); }
.fad.#{$fa-css-prefix}-eggplant:before, .fa-duotone.#{$fa-css-prefix}-eggplant:before { content: fa-content(\e16c\fe01); }
.fad.#{$fa-css-prefix}-eggplant:after, .fa-duotone.#{$fa-css-prefix}-eggplant:after { content: fa-content(\e16c\fe02); }
.fad.#{$fa-css-prefix}-eject:before, .fa-duotone.#{$fa-css-prefix}-eject:before { content: fa-content(\23cf\fe01); }
.fad.#{$fa-css-prefix}-eject:after, .fa-duotone.#{$fa-css-prefix}-eject:after { content: fa-content(\23cf\fe02); }
.fad.#{$fa-css-prefix}-elephant:before, .fa-duotone.#{$fa-css-prefix}-elephant:before { content: fa-content(\f6da); }
.fad.#{$fa-css-prefix}-elephant:after, .fa-duotone.#{$fa-css-prefix}-elephant:after { content: fa-content(\10f6da); }
.fad.#{$fa-css-prefix}-elevator:before, .fa-duotone.#{$fa-css-prefix}-elevator:before { content: fa-content(\e16d\fe01); }
.fad.#{$fa-css-prefix}-elevator:after, .fa-duotone.#{$fa-css-prefix}-elevator:after { content: fa-content(\e16d\fe02); }
.fad.#{$fa-css-prefix}-ellipsis:before, .fa-duotone.#{$fa-css-prefix}-ellipsis:before { content: fa-content(\f141\fe01); }
.fad.#{$fa-css-prefix}-ellipsis:after, .fa-duotone.#{$fa-css-prefix}-ellipsis:after { content: fa-content(\f141\fe02); }
.fad.#{$fa-css-prefix}-ellipsis-h:before, .fa-duotone.#{$fa-css-prefix}-ellipsis-h:before { content: fa-content(\f141\fe01); }
.fad.#{$fa-css-prefix}-ellipsis-h:after, .fa-duotone.#{$fa-css-prefix}-ellipsis-h:after { content: fa-content(\f141\fe02); }
.fad.#{$fa-css-prefix}-ellipsis-stroke:before, .fa-duotone.#{$fa-css-prefix}-ellipsis-stroke:before { content: fa-content(\f39b\fe01); }
.fad.#{$fa-css-prefix}-ellipsis-stroke:after, .fa-duotone.#{$fa-css-prefix}-ellipsis-stroke:after { content: fa-content(\f39b\fe02); }
.fad.#{$fa-css-prefix}-ellipsis-h-alt:before, .fa-duotone.#{$fa-css-prefix}-ellipsis-h-alt:before { content: fa-content(\f39b\fe01); }
.fad.#{$fa-css-prefix}-ellipsis-h-alt:after, .fa-duotone.#{$fa-css-prefix}-ellipsis-h-alt:after { content: fa-content(\f39b\fe02); }
.fad.#{$fa-css-prefix}-ellipsis-stroke-vertical:before, .fa-duotone.#{$fa-css-prefix}-ellipsis-stroke-vertical:before { content: fa-content(\f39c\fe01); }
.fad.#{$fa-css-prefix}-ellipsis-stroke-vertical:after, .fa-duotone.#{$fa-css-prefix}-ellipsis-stroke-vertical:after { content: fa-content(\f39c\fe02); }
.fad.#{$fa-css-prefix}-ellipsis-v-alt:before, .fa-duotone.#{$fa-css-prefix}-ellipsis-v-alt:before { content: fa-content(\f39c\fe01); }
.fad.#{$fa-css-prefix}-ellipsis-v-alt:after, .fa-duotone.#{$fa-css-prefix}-ellipsis-v-alt:after { content: fa-content(\f39c\fe02); }
.fad.#{$fa-css-prefix}-ellipsis-vertical:before, .fa-duotone.#{$fa-css-prefix}-ellipsis-vertical:before { content: fa-content(\f142\fe01); }
.fad.#{$fa-css-prefix}-ellipsis-vertical:after, .fa-duotone.#{$fa-css-prefix}-ellipsis-vertical:after { content: fa-content(\f142\fe02); }
.fad.#{$fa-css-prefix}-ellipsis-v:before, .fa-duotone.#{$fa-css-prefix}-ellipsis-v:before { content: fa-content(\f142\fe01); }
.fad.#{$fa-css-prefix}-ellipsis-v:after, .fa-duotone.#{$fa-css-prefix}-ellipsis-v:after { content: fa-content(\f142\fe02); }
.fad.#{$fa-css-prefix}-empty-set:before, .fa-duotone.#{$fa-css-prefix}-empty-set:before { content: fa-content(\d8\fe01); }
.fad.#{$fa-css-prefix}-empty-set:after, .fa-duotone.#{$fa-css-prefix}-empty-set:after { content: fa-content(\d8\fe02); }
.fad.#{$fa-css-prefix}-engine:before, .fa-duotone.#{$fa-css-prefix}-engine:before { content: fa-content(\e16e\fe01); }
.fad.#{$fa-css-prefix}-engine:after, .fa-duotone.#{$fa-css-prefix}-engine:after { content: fa-content(\e16e\fe02); }
.fad.#{$fa-css-prefix}-engine-warning:before, .fa-duotone.#{$fa-css-prefix}-engine-warning:before { content: fa-content(\f5f2\fe01); }
.fad.#{$fa-css-prefix}-engine-warning:after, .fa-duotone.#{$fa-css-prefix}-engine-warning:after { content: fa-content(\f5f2\fe02); }
.fad.#{$fa-css-prefix}-engine-exclamation:before, .fa-duotone.#{$fa-css-prefix}-engine-exclamation:before { content: fa-content(\f5f2\fe01); }
.fad.#{$fa-css-prefix}-engine-exclamation:after, .fa-duotone.#{$fa-css-prefix}-engine-exclamation:after { content: fa-content(\f5f2\fe02); }
.fad.#{$fa-css-prefix}-envelope:before, .fa-duotone.#{$fa-css-prefix}-envelope:before { content: fa-content(\2709\fe01); }
.fad.#{$fa-css-prefix}-envelope:after, .fa-duotone.#{$fa-css-prefix}-envelope:after { content: fa-content(\2709\fe02); }
.fad.#{$fa-css-prefix}-envelope-dot:before, .fa-duotone.#{$fa-css-prefix}-envelope-dot:before { content: fa-content(\e16f\fe01); }
.fad.#{$fa-css-prefix}-envelope-dot:after, .fa-duotone.#{$fa-css-prefix}-envelope-dot:after { content: fa-content(\e16f\fe02); }
.fad.#{$fa-css-prefix}-envelope-badge:before, .fa-duotone.#{$fa-css-prefix}-envelope-badge:before { content: fa-content(\e16f\fe01); }
.fad.#{$fa-css-prefix}-envelope-badge:after, .fa-duotone.#{$fa-css-prefix}-envelope-badge:after { content: fa-content(\e16f\fe02); }
.fad.#{$fa-css-prefix}-envelope-open:before, .fa-duotone.#{$fa-css-prefix}-envelope-open:before { content: fa-content(\f2b6\fe01); }
.fad.#{$fa-css-prefix}-envelope-open:after, .fa-duotone.#{$fa-css-prefix}-envelope-open:after { content: fa-content(\f2b6\fe02); }
.fad.#{$fa-css-prefix}-envelope-open-dollar:before, .fa-duotone.#{$fa-css-prefix}-envelope-open-dollar:before { content: fa-content(\f657\fe01); }
.fad.#{$fa-css-prefix}-envelope-open-dollar:after, .fa-duotone.#{$fa-css-prefix}-envelope-open-dollar:after { content: fa-content(\f657\fe02); }
.fad.#{$fa-css-prefix}-envelope-open-text:before, .fa-duotone.#{$fa-css-prefix}-envelope-open-text:before { content: fa-content(\f658\fe01); }
.fad.#{$fa-css-prefix}-envelope-open-text:after, .fa-duotone.#{$fa-css-prefix}-envelope-open-text:after { content: fa-content(\f658\fe02); }
.fad.#{$fa-css-prefix}-envelopes:before, .fa-duotone.#{$fa-css-prefix}-envelopes:before { content: fa-content(\e170\fe01); }
.fad.#{$fa-css-prefix}-envelopes:after, .fa-duotone.#{$fa-css-prefix}-envelopes:after { content: fa-content(\e170\fe02); }
.fad.#{$fa-css-prefix}-envelopes-bulk:before, .fa-duotone.#{$fa-css-prefix}-envelopes-bulk:before { content: fa-content(\f674\fe01); }
.fad.#{$fa-css-prefix}-envelopes-bulk:after, .fa-duotone.#{$fa-css-prefix}-envelopes-bulk:after { content: fa-content(\f674\fe02); }
.fad.#{$fa-css-prefix}-mail-bulk:before, .fa-duotone.#{$fa-css-prefix}-mail-bulk:before { content: fa-content(\f674\fe01); }
.fad.#{$fa-css-prefix}-mail-bulk:after, .fa-duotone.#{$fa-css-prefix}-mail-bulk:after { content: fa-content(\f674\fe02); }
.fad.#{$fa-css-prefix}-equals:before, .fa-duotone.#{$fa-css-prefix}-equals:before { content: fa-content(\3d\fe01); }
.fad.#{$fa-css-prefix}-equals:after, .fa-duotone.#{$fa-css-prefix}-equals:after { content: fa-content(\3d\fe02); }
.fad.#{$fa-css-prefix}-eraser:before, .fa-duotone.#{$fa-css-prefix}-eraser:before { content: fa-content(\f12d\fe01); }
.fad.#{$fa-css-prefix}-eraser:after, .fa-duotone.#{$fa-css-prefix}-eraser:after { content: fa-content(\f12d\fe02); }
.fad.#{$fa-css-prefix}-escalator:before, .fa-duotone.#{$fa-css-prefix}-escalator:before { content: fa-content(\e171\fe01); }
.fad.#{$fa-css-prefix}-escalator:after, .fa-duotone.#{$fa-css-prefix}-escalator:after { content: fa-content(\e171\fe02); }
.fad.#{$fa-css-prefix}-ethernet:before, .fa-duotone.#{$fa-css-prefix}-ethernet:before { content: fa-content(\f796\fe01); }
.fad.#{$fa-css-prefix}-ethernet:after, .fa-duotone.#{$fa-css-prefix}-ethernet:after { content: fa-content(\f796\fe02); }
.fad.#{$fa-css-prefix}-euro-sign:before, .fa-duotone.#{$fa-css-prefix}-euro-sign:before { content: fa-content(\20ac\fe01); }
.fad.#{$fa-css-prefix}-euro-sign:after, .fa-duotone.#{$fa-css-prefix}-euro-sign:after { content: fa-content(\20ac\fe02); }
.fad.#{$fa-css-prefix}-eur:before, .fa-duotone.#{$fa-css-prefix}-eur:before { content: fa-content(\20ac\fe01); }
.fad.#{$fa-css-prefix}-eur:after, .fa-duotone.#{$fa-css-prefix}-eur:after { content: fa-content(\20ac\fe02); }
.fad.#{$fa-css-prefix}-euro:before, .fa-duotone.#{$fa-css-prefix}-euro:before { content: fa-content(\20ac\fe01); }
.fad.#{$fa-css-prefix}-euro:after, .fa-duotone.#{$fa-css-prefix}-euro:after { content: fa-content(\20ac\fe02); }
.fad.#{$fa-css-prefix}-exclamation:before, .fa-duotone.#{$fa-css-prefix}-exclamation:before { content: fa-content(\21\fe01); }
.fad.#{$fa-css-prefix}-exclamation:after, .fa-duotone.#{$fa-css-prefix}-exclamation:after { content: fa-content(\21\fe02); }
.fad.#{$fa-css-prefix}-expand:before, .fa-duotone.#{$fa-css-prefix}-expand:before { content: fa-content(\f065\fe01); }
.fad.#{$fa-css-prefix}-expand:after, .fa-duotone.#{$fa-css-prefix}-expand:after { content: fa-content(\f065\fe02); }
.fad.#{$fa-css-prefix}-expand-wide:before, .fa-duotone.#{$fa-css-prefix}-expand-wide:before { content: fa-content(\f320\fe01); }
.fad.#{$fa-css-prefix}-expand-wide:after, .fa-duotone.#{$fa-css-prefix}-expand-wide:after { content: fa-content(\f320\fe02); }
.fad.#{$fa-css-prefix}-eye:before, .fa-duotone.#{$fa-css-prefix}-eye:before { content: fa-content(\f06e); }
.fad.#{$fa-css-prefix}-eye:after, .fa-duotone.#{$fa-css-prefix}-eye:after { content: fa-content(\10f06e); }
.fad.#{$fa-css-prefix}-eye-dropper:before, .fa-duotone.#{$fa-css-prefix}-eye-dropper:before { content: fa-content(\f1fb\fe01); }
.fad.#{$fa-css-prefix}-eye-dropper:after, .fa-duotone.#{$fa-css-prefix}-eye-dropper:after { content: fa-content(\f1fb\fe02); }
.fad.#{$fa-css-prefix}-eye-dropper-empty:before, .fa-duotone.#{$fa-css-prefix}-eye-dropper-empty:before { content: fa-content(\f1fb\fe01); }
.fad.#{$fa-css-prefix}-eye-dropper-empty:after, .fa-duotone.#{$fa-css-prefix}-eye-dropper-empty:after { content: fa-content(\f1fb\fe02); }
.fad.#{$fa-css-prefix}-eyedropper:before, .fa-duotone.#{$fa-css-prefix}-eyedropper:before { content: fa-content(\f1fb\fe01); }
.fad.#{$fa-css-prefix}-eyedropper:after, .fa-duotone.#{$fa-css-prefix}-eyedropper:after { content: fa-content(\f1fb\fe02); }
.fad.#{$fa-css-prefix}-eye-dropper-full:before, .fa-duotone.#{$fa-css-prefix}-eye-dropper-full:before { content: fa-content(\e172\fe01); }
.fad.#{$fa-css-prefix}-eye-dropper-full:after, .fa-duotone.#{$fa-css-prefix}-eye-dropper-full:after { content: fa-content(\e172\fe02); }
.fad.#{$fa-css-prefix}-eye-dropper-half:before, .fa-duotone.#{$fa-css-prefix}-eye-dropper-half:before { content: fa-content(\e173\fe01); }
.fad.#{$fa-css-prefix}-eye-dropper-half:after, .fa-duotone.#{$fa-css-prefix}-eye-dropper-half:after { content: fa-content(\e173\fe02); }
.fad.#{$fa-css-prefix}-eye-evil:before, .fa-duotone.#{$fa-css-prefix}-eye-evil:before { content: fa-content(\f6db\fe01); }
.fad.#{$fa-css-prefix}-eye-evil:after, .fa-duotone.#{$fa-css-prefix}-eye-evil:after { content: fa-content(\f6db\fe02); }
.fad.#{$fa-css-prefix}-eye-low-vision:before, .fa-duotone.#{$fa-css-prefix}-eye-low-vision:before { content: fa-content(\f2a8\fe01); }
.fad.#{$fa-css-prefix}-eye-low-vision:after, .fa-duotone.#{$fa-css-prefix}-eye-low-vision:after { content: fa-content(\f2a8\fe02); }
.fad.#{$fa-css-prefix}-low-vision:before, .fa-duotone.#{$fa-css-prefix}-low-vision:before { content: fa-content(\f2a8\fe01); }
.fad.#{$fa-css-prefix}-low-vision:after, .fa-duotone.#{$fa-css-prefix}-low-vision:after { content: fa-content(\f2a8\fe02); }
.fad.#{$fa-css-prefix}-eye-slash:before, .fa-duotone.#{$fa-css-prefix}-eye-slash:before { content: fa-content(\f070\fe01); }
.fad.#{$fa-css-prefix}-eye-slash:after, .fa-duotone.#{$fa-css-prefix}-eye-slash:after { content: fa-content(\f070\fe02); }
.fad.#{$fa-css-prefix}-eyes:before, .fa-duotone.#{$fa-css-prefix}-eyes:before { content: fa-content(\e367\fe01); }
.fad.#{$fa-css-prefix}-eyes:after, .fa-duotone.#{$fa-css-prefix}-eyes:after { content: fa-content(\e367\fe02); }
.fad.#{$fa-css-prefix}-f:before, .fa-duotone.#{$fa-css-prefix}-f:before { content: fa-content(\46\fe01); }
.fad.#{$fa-css-prefix}-f:after, .fa-duotone.#{$fa-css-prefix}-f:after { content: fa-content(\46\fe02); }
.fad.#{$fa-css-prefix}-face-angry:before, .fa-duotone.#{$fa-css-prefix}-face-angry:before { content: fa-content(\f556); }
.fad.#{$fa-css-prefix}-face-angry:after, .fa-duotone.#{$fa-css-prefix}-face-angry:after { content: fa-content(\10f556); }
.fad.#{$fa-css-prefix}-angry:before, .fa-duotone.#{$fa-css-prefix}-angry:before { content: fa-content(\f556); }
.fad.#{$fa-css-prefix}-angry:after, .fa-duotone.#{$fa-css-prefix}-angry:after { content: fa-content(\10f556); }
.fad.#{$fa-css-prefix}-face-angry-horns:before, .fa-duotone.#{$fa-css-prefix}-face-angry-horns:before { content: fa-content(\e368\fe01); }
.fad.#{$fa-css-prefix}-face-angry-horns:after, .fa-duotone.#{$fa-css-prefix}-face-angry-horns:after { content: fa-content(\e368\fe02); }
.fad.#{$fa-css-prefix}-face-anguished:before, .fa-duotone.#{$fa-css-prefix}-face-anguished:before { content: fa-content(\e369\fe01); }
.fad.#{$fa-css-prefix}-face-anguished:after, .fa-duotone.#{$fa-css-prefix}-face-anguished:after { content: fa-content(\e369\fe02); }
.fad.#{$fa-css-prefix}-face-anxious-sweat:before, .fa-duotone.#{$fa-css-prefix}-face-anxious-sweat:before { content: fa-content(\e36a\fe01); }
.fad.#{$fa-css-prefix}-face-anxious-sweat:after, .fa-duotone.#{$fa-css-prefix}-face-anxious-sweat:after { content: fa-content(\e36a\fe02); }
.fad.#{$fa-css-prefix}-face-astonished:before, .fa-duotone.#{$fa-css-prefix}-face-astonished:before { content: fa-content(\e36b\fe01); }
.fad.#{$fa-css-prefix}-face-astonished:after, .fa-duotone.#{$fa-css-prefix}-face-astonished:after { content: fa-content(\e36b\fe02); }
.fad.#{$fa-css-prefix}-face-confounded:before, .fa-duotone.#{$fa-css-prefix}-face-confounded:before { content: fa-content(\e36c\fe01); }
.fad.#{$fa-css-prefix}-face-confounded:after, .fa-duotone.#{$fa-css-prefix}-face-confounded:after { content: fa-content(\e36c\fe02); }
.fad.#{$fa-css-prefix}-face-confused:before, .fa-duotone.#{$fa-css-prefix}-face-confused:before { content: fa-content(\e36d\fe01); }
.fad.#{$fa-css-prefix}-face-confused:after, .fa-duotone.#{$fa-css-prefix}-face-confused:after { content: fa-content(\e36d\fe02); }
.fad.#{$fa-css-prefix}-face-cowboy-hat:before, .fa-duotone.#{$fa-css-prefix}-face-cowboy-hat:before { content: fa-content(\e36e\fe01); }
.fad.#{$fa-css-prefix}-face-cowboy-hat:after, .fa-duotone.#{$fa-css-prefix}-face-cowboy-hat:after { content: fa-content(\e36e\fe02); }
.fad.#{$fa-css-prefix}-face-disappointed:before, .fa-duotone.#{$fa-css-prefix}-face-disappointed:before { content: fa-content(\e36f\fe01); }
.fad.#{$fa-css-prefix}-face-disappointed:after, .fa-duotone.#{$fa-css-prefix}-face-disappointed:after { content: fa-content(\e36f\fe02); }
.fad.#{$fa-css-prefix}-face-disguise:before, .fa-duotone.#{$fa-css-prefix}-face-disguise:before { content: fa-content(\e370\fe01); }
.fad.#{$fa-css-prefix}-face-disguise:after, .fa-duotone.#{$fa-css-prefix}-face-disguise:after { content: fa-content(\e370\fe02); }
.fad.#{$fa-css-prefix}-face-dizzy:before, .fa-duotone.#{$fa-css-prefix}-face-dizzy:before { content: fa-content(\f567\fe01); }
.fad.#{$fa-css-prefix}-face-dizzy:after, .fa-duotone.#{$fa-css-prefix}-face-dizzy:after { content: fa-content(\f567\fe02); }
.fad.#{$fa-css-prefix}-dizzy:before, .fa-duotone.#{$fa-css-prefix}-dizzy:before { content: fa-content(\f567\fe01); }
.fad.#{$fa-css-prefix}-dizzy:after, .fa-duotone.#{$fa-css-prefix}-dizzy:after { content: fa-content(\f567\fe02); }
.fad.#{$fa-css-prefix}-face-downcast-sweat:before, .fa-duotone.#{$fa-css-prefix}-face-downcast-sweat:before { content: fa-content(\e371\fe01); }
.fad.#{$fa-css-prefix}-face-downcast-sweat:after, .fa-duotone.#{$fa-css-prefix}-face-downcast-sweat:after { content: fa-content(\e371\fe02); }
.fad.#{$fa-css-prefix}-face-drooling:before, .fa-duotone.#{$fa-css-prefix}-face-drooling:before { content: fa-content(\e372\fe01); }
.fad.#{$fa-css-prefix}-face-drooling:after, .fa-duotone.#{$fa-css-prefix}-face-drooling:after { content: fa-content(\e372\fe02); }
.fad.#{$fa-css-prefix}-face-explode:before, .fa-duotone.#{$fa-css-prefix}-face-explode:before { content: fa-content(\e2fe\fe01); }
.fad.#{$fa-css-prefix}-face-explode:after, .fa-duotone.#{$fa-css-prefix}-face-explode:after { content: fa-content(\e2fe\fe02); }
.fad.#{$fa-css-prefix}-exploding-head:before, .fa-duotone.#{$fa-css-prefix}-exploding-head:before { content: fa-content(\e2fe\fe01); }
.fad.#{$fa-css-prefix}-exploding-head:after, .fa-duotone.#{$fa-css-prefix}-exploding-head:after { content: fa-content(\e2fe\fe02); }
.fad.#{$fa-css-prefix}-face-expressionless:before, .fa-duotone.#{$fa-css-prefix}-face-expressionless:before { content: fa-content(\e373\fe01); }
.fad.#{$fa-css-prefix}-face-expressionless:after, .fa-duotone.#{$fa-css-prefix}-face-expressionless:after { content: fa-content(\e373\fe02); }
.fad.#{$fa-css-prefix}-face-eyes-xmarks:before, .fa-duotone.#{$fa-css-prefix}-face-eyes-xmarks:before { content: fa-content(\e374\fe01); }
.fad.#{$fa-css-prefix}-face-eyes-xmarks:after, .fa-duotone.#{$fa-css-prefix}-face-eyes-xmarks:after { content: fa-content(\e374\fe02); }
.fad.#{$fa-css-prefix}-face-fearful:before, .fa-duotone.#{$fa-css-prefix}-face-fearful:before { content: fa-content(\e375\fe01); }
.fad.#{$fa-css-prefix}-face-fearful:after, .fa-duotone.#{$fa-css-prefix}-face-fearful:after { content: fa-content(\e375\fe02); }
.fad.#{$fa-css-prefix}-face-flushed:before, .fa-duotone.#{$fa-css-prefix}-face-flushed:before { content: fa-content(\f579); }
.fad.#{$fa-css-prefix}-face-flushed:after, .fa-duotone.#{$fa-css-prefix}-face-flushed:after { content: fa-content(\10f579); }
.fad.#{$fa-css-prefix}-flushed:before, .fa-duotone.#{$fa-css-prefix}-flushed:before { content: fa-content(\f579); }
.fad.#{$fa-css-prefix}-flushed:after, .fa-duotone.#{$fa-css-prefix}-flushed:after { content: fa-content(\10f579); }
.fad.#{$fa-css-prefix}-face-frown:before, .fa-duotone.#{$fa-css-prefix}-face-frown:before { content: fa-content(\2639\fe01); }
.fad.#{$fa-css-prefix}-face-frown:after, .fa-duotone.#{$fa-css-prefix}-face-frown:after { content: fa-content(\2639\fe02); }
.fad.#{$fa-css-prefix}-frown:before, .fa-duotone.#{$fa-css-prefix}-frown:before { content: fa-content(\2639\fe01); }
.fad.#{$fa-css-prefix}-frown:after, .fa-duotone.#{$fa-css-prefix}-frown:after { content: fa-content(\2639\fe02); }
.fad.#{$fa-css-prefix}-face-frown-open:before, .fa-duotone.#{$fa-css-prefix}-face-frown-open:before { content: fa-content(\f57a); }
.fad.#{$fa-css-prefix}-face-frown-open:after, .fa-duotone.#{$fa-css-prefix}-face-frown-open:after { content: fa-content(\10f57a); }
.fad.#{$fa-css-prefix}-frown-open:before, .fa-duotone.#{$fa-css-prefix}-frown-open:before { content: fa-content(\f57a); }
.fad.#{$fa-css-prefix}-frown-open:after, .fa-duotone.#{$fa-css-prefix}-frown-open:after { content: fa-content(\10f57a); }
.fad.#{$fa-css-prefix}-face-frown-slight:before, .fa-duotone.#{$fa-css-prefix}-face-frown-slight:before { content: fa-content(\e376\fe01); }
.fad.#{$fa-css-prefix}-face-frown-slight:after, .fa-duotone.#{$fa-css-prefix}-face-frown-slight:after { content: fa-content(\e376\fe02); }
.fad.#{$fa-css-prefix}-face-glasses:before, .fa-duotone.#{$fa-css-prefix}-face-glasses:before { content: fa-content(\e377\fe01); }
.fad.#{$fa-css-prefix}-face-glasses:after, .fa-duotone.#{$fa-css-prefix}-face-glasses:after { content: fa-content(\e377\fe02); }
.fad.#{$fa-css-prefix}-face-grimace:before, .fa-duotone.#{$fa-css-prefix}-face-grimace:before { content: fa-content(\f57f); }
.fad.#{$fa-css-prefix}-face-grimace:after, .fa-duotone.#{$fa-css-prefix}-face-grimace:after { content: fa-content(\10f57f); }
.fad.#{$fa-css-prefix}-grimace:before, .fa-duotone.#{$fa-css-prefix}-grimace:before { content: fa-content(\f57f); }
.fad.#{$fa-css-prefix}-grimace:after, .fa-duotone.#{$fa-css-prefix}-grimace:after { content: fa-content(\10f57f); }
.fad.#{$fa-css-prefix}-face-grin:before, .fa-duotone.#{$fa-css-prefix}-face-grin:before { content: fa-content(\f580); }
.fad.#{$fa-css-prefix}-face-grin:after, .fa-duotone.#{$fa-css-prefix}-face-grin:after { content: fa-content(\10f580); }
.fad.#{$fa-css-prefix}-grin:before, .fa-duotone.#{$fa-css-prefix}-grin:before { content: fa-content(\f580); }
.fad.#{$fa-css-prefix}-grin:after, .fa-duotone.#{$fa-css-prefix}-grin:after { content: fa-content(\10f580); }
.fad.#{$fa-css-prefix}-face-grin-beam:before, .fa-duotone.#{$fa-css-prefix}-face-grin-beam:before { content: fa-content(\f582); }
.fad.#{$fa-css-prefix}-face-grin-beam:after, .fa-duotone.#{$fa-css-prefix}-face-grin-beam:after { content: fa-content(\10f582); }
.fad.#{$fa-css-prefix}-grin-beam:before, .fa-duotone.#{$fa-css-prefix}-grin-beam:before { content: fa-content(\f582); }
.fad.#{$fa-css-prefix}-grin-beam:after, .fa-duotone.#{$fa-css-prefix}-grin-beam:after { content: fa-content(\10f582); }
.fad.#{$fa-css-prefix}-face-grin-beam-sweat:before, .fa-duotone.#{$fa-css-prefix}-face-grin-beam-sweat:before { content: fa-content(\f583); }
.fad.#{$fa-css-prefix}-face-grin-beam-sweat:after, .fa-duotone.#{$fa-css-prefix}-face-grin-beam-sweat:after { content: fa-content(\10f583); }
.fad.#{$fa-css-prefix}-grin-beam-sweat:before, .fa-duotone.#{$fa-css-prefix}-grin-beam-sweat:before { content: fa-content(\f583); }
.fad.#{$fa-css-prefix}-grin-beam-sweat:after, .fa-duotone.#{$fa-css-prefix}-grin-beam-sweat:after { content: fa-content(\10f583); }
.fad.#{$fa-css-prefix}-face-grin-hearts:before, .fa-duotone.#{$fa-css-prefix}-face-grin-hearts:before { content: fa-content(\f584); }
.fad.#{$fa-css-prefix}-face-grin-hearts:after, .fa-duotone.#{$fa-css-prefix}-face-grin-hearts:after { content: fa-content(\10f584); }
.fad.#{$fa-css-prefix}-grin-hearts:before, .fa-duotone.#{$fa-css-prefix}-grin-hearts:before { content: fa-content(\f584); }
.fad.#{$fa-css-prefix}-grin-hearts:after, .fa-duotone.#{$fa-css-prefix}-grin-hearts:after { content: fa-content(\10f584); }
.fad.#{$fa-css-prefix}-face-grin-squint:before, .fa-duotone.#{$fa-css-prefix}-face-grin-squint:before { content: fa-content(\f585); }
.fad.#{$fa-css-prefix}-face-grin-squint:after, .fa-duotone.#{$fa-css-prefix}-face-grin-squint:after { content: fa-content(\10f585); }
.fad.#{$fa-css-prefix}-grin-squint:before, .fa-duotone.#{$fa-css-prefix}-grin-squint:before { content: fa-content(\f585); }
.fad.#{$fa-css-prefix}-grin-squint:after, .fa-duotone.#{$fa-css-prefix}-grin-squint:after { content: fa-content(\10f585); }
.fad.#{$fa-css-prefix}-face-grin-squint-tears:before, .fa-duotone.#{$fa-css-prefix}-face-grin-squint-tears:before { content: fa-content(\f586); }
.fad.#{$fa-css-prefix}-face-grin-squint-tears:after, .fa-duotone.#{$fa-css-prefix}-face-grin-squint-tears:after { content: fa-content(\10f586); }
.fad.#{$fa-css-prefix}-grin-squint-tears:before, .fa-duotone.#{$fa-css-prefix}-grin-squint-tears:before { content: fa-content(\f586); }
.fad.#{$fa-css-prefix}-grin-squint-tears:after, .fa-duotone.#{$fa-css-prefix}-grin-squint-tears:after { content: fa-content(\10f586); }
.fad.#{$fa-css-prefix}-face-grin-stars:before, .fa-duotone.#{$fa-css-prefix}-face-grin-stars:before { content: fa-content(\f587); }
.fad.#{$fa-css-prefix}-face-grin-stars:after, .fa-duotone.#{$fa-css-prefix}-face-grin-stars:after { content: fa-content(\10f587); }
.fad.#{$fa-css-prefix}-grin-stars:before, .fa-duotone.#{$fa-css-prefix}-grin-stars:before { content: fa-content(\f587); }
.fad.#{$fa-css-prefix}-grin-stars:after, .fa-duotone.#{$fa-css-prefix}-grin-stars:after { content: fa-content(\10f587); }
.fad.#{$fa-css-prefix}-face-grin-tears:before, .fa-duotone.#{$fa-css-prefix}-face-grin-tears:before { content: fa-content(\f588); }
.fad.#{$fa-css-prefix}-face-grin-tears:after, .fa-duotone.#{$fa-css-prefix}-face-grin-tears:after { content: fa-content(\10f588); }
.fad.#{$fa-css-prefix}-grin-tears:before, .fa-duotone.#{$fa-css-prefix}-grin-tears:before { content: fa-content(\f588); }
.fad.#{$fa-css-prefix}-grin-tears:after, .fa-duotone.#{$fa-css-prefix}-grin-tears:after { content: fa-content(\10f588); }
.fad.#{$fa-css-prefix}-face-grin-tongue:before, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue:before { content: fa-content(\f589); }
.fad.#{$fa-css-prefix}-face-grin-tongue:after, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue:after { content: fa-content(\10f589); }
.fad.#{$fa-css-prefix}-grin-tongue:before, .fa-duotone.#{$fa-css-prefix}-grin-tongue:before { content: fa-content(\f589); }
.fad.#{$fa-css-prefix}-grin-tongue:after, .fa-duotone.#{$fa-css-prefix}-grin-tongue:after { content: fa-content(\10f589); }
.fad.#{$fa-css-prefix}-face-grin-tongue-squint:before, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue-squint:before { content: fa-content(\f58a); }
.fad.#{$fa-css-prefix}-face-grin-tongue-squint:after, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue-squint:after { content: fa-content(\10f58a); }
.fad.#{$fa-css-prefix}-grin-tongue-squint:before, .fa-duotone.#{$fa-css-prefix}-grin-tongue-squint:before { content: fa-content(\f58a); }
.fad.#{$fa-css-prefix}-grin-tongue-squint:after, .fa-duotone.#{$fa-css-prefix}-grin-tongue-squint:after { content: fa-content(\10f58a); }
.fad.#{$fa-css-prefix}-face-grin-tongue-wink:before, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue-wink:before { content: fa-content(\f58b); }
.fad.#{$fa-css-prefix}-face-grin-tongue-wink:after, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue-wink:after { content: fa-content(\10f58b); }
.fad.#{$fa-css-prefix}-grin-tongue-wink:before, .fa-duotone.#{$fa-css-prefix}-grin-tongue-wink:before { content: fa-content(\f58b); }
.fad.#{$fa-css-prefix}-grin-tongue-wink:after, .fa-duotone.#{$fa-css-prefix}-grin-tongue-wink:after { content: fa-content(\10f58b); }
.fad.#{$fa-css-prefix}-face-grin-wide:before, .fa-duotone.#{$fa-css-prefix}-face-grin-wide:before { content: fa-content(\f581); }
.fad.#{$fa-css-prefix}-face-grin-wide:after, .fa-duotone.#{$fa-css-prefix}-face-grin-wide:after { content: fa-content(\10f581); }
.fad.#{$fa-css-prefix}-grin-alt:before, .fa-duotone.#{$fa-css-prefix}-grin-alt:before { content: fa-content(\f581); }
.fad.#{$fa-css-prefix}-grin-alt:after, .fa-duotone.#{$fa-css-prefix}-grin-alt:after { content: fa-content(\10f581); }
.fad.#{$fa-css-prefix}-face-grin-wink:before, .fa-duotone.#{$fa-css-prefix}-face-grin-wink:before { content: fa-content(\f58c\fe01); }
.fad.#{$fa-css-prefix}-face-grin-wink:after, .fa-duotone.#{$fa-css-prefix}-face-grin-wink:after { content: fa-content(\f58c\fe02); }
.fad.#{$fa-css-prefix}-grin-wink:before, .fa-duotone.#{$fa-css-prefix}-grin-wink:before { content: fa-content(\f58c\fe01); }
.fad.#{$fa-css-prefix}-grin-wink:after, .fa-duotone.#{$fa-css-prefix}-grin-wink:after { content: fa-content(\f58c\fe02); }
.fad.#{$fa-css-prefix}-face-hand-over-mouth:before, .fa-duotone.#{$fa-css-prefix}-face-hand-over-mouth:before { content: fa-content(\e378\fe01); }
.fad.#{$fa-css-prefix}-face-hand-over-mouth:after, .fa-duotone.#{$fa-css-prefix}-face-hand-over-mouth:after { content: fa-content(\e378\fe02); }
.fad.#{$fa-css-prefix}-face-hand-yawn:before, .fa-duotone.#{$fa-css-prefix}-face-hand-yawn:before { content: fa-content(\e379\fe01); }
.fad.#{$fa-css-prefix}-face-hand-yawn:after, .fa-duotone.#{$fa-css-prefix}-face-hand-yawn:after { content: fa-content(\e379\fe02); }
.fad.#{$fa-css-prefix}-face-head-bandage:before, .fa-duotone.#{$fa-css-prefix}-face-head-bandage:before { content: fa-content(\e37a\fe01); }
.fad.#{$fa-css-prefix}-face-head-bandage:after, .fa-duotone.#{$fa-css-prefix}-face-head-bandage:after { content: fa-content(\e37a\fe02); }
.fad.#{$fa-css-prefix}-face-hushed:before, .fa-duotone.#{$fa-css-prefix}-face-hushed:before { content: fa-content(\e37b\fe01); }
.fad.#{$fa-css-prefix}-face-hushed:after, .fa-duotone.#{$fa-css-prefix}-face-hushed:after { content: fa-content(\e37b\fe02); }
.fad.#{$fa-css-prefix}-face-icicles:before, .fa-duotone.#{$fa-css-prefix}-face-icicles:before { content: fa-content(\e37c\fe01); }
.fad.#{$fa-css-prefix}-face-icicles:after, .fa-duotone.#{$fa-css-prefix}-face-icicles:after { content: fa-content(\e37c\fe02); }
.fad.#{$fa-css-prefix}-face-kiss:before, .fa-duotone.#{$fa-css-prefix}-face-kiss:before { content: fa-content(\f596); }
.fad.#{$fa-css-prefix}-face-kiss:after, .fa-duotone.#{$fa-css-prefix}-face-kiss:after { content: fa-content(\10f596); }
.fad.#{$fa-css-prefix}-kiss:before, .fa-duotone.#{$fa-css-prefix}-kiss:before { content: fa-content(\f596); }
.fad.#{$fa-css-prefix}-kiss:after, .fa-duotone.#{$fa-css-prefix}-kiss:after { content: fa-content(\10f596); }
.fad.#{$fa-css-prefix}-face-kiss-beam:before, .fa-duotone.#{$fa-css-prefix}-face-kiss-beam:before { content: fa-content(\f597); }
.fad.#{$fa-css-prefix}-face-kiss-beam:after, .fa-duotone.#{$fa-css-prefix}-face-kiss-beam:after { content: fa-content(\10f597); }
.fad.#{$fa-css-prefix}-kiss-beam:before, .fa-duotone.#{$fa-css-prefix}-kiss-beam:before { content: fa-content(\f597); }
.fad.#{$fa-css-prefix}-kiss-beam:after, .fa-duotone.#{$fa-css-prefix}-kiss-beam:after { content: fa-content(\10f597); }
.fad.#{$fa-css-prefix}-face-kiss-closed-eyes:before, .fa-duotone.#{$fa-css-prefix}-face-kiss-closed-eyes:before { content: fa-content(\e37d\fe01); }
.fad.#{$fa-css-prefix}-face-kiss-closed-eyes:after, .fa-duotone.#{$fa-css-prefix}-face-kiss-closed-eyes:after { content: fa-content(\e37d\fe02); }
.fad.#{$fa-css-prefix}-face-kiss-wink-heart:before, .fa-duotone.#{$fa-css-prefix}-face-kiss-wink-heart:before { content: fa-content(\f598); }
.fad.#{$fa-css-prefix}-face-kiss-wink-heart:after, .fa-duotone.#{$fa-css-prefix}-face-kiss-wink-heart:after { content: fa-content(\10f598); }
.fad.#{$fa-css-prefix}-kiss-wink-heart:before, .fa-duotone.#{$fa-css-prefix}-kiss-wink-heart:before { content: fa-content(\f598); }
.fad.#{$fa-css-prefix}-kiss-wink-heart:after, .fa-duotone.#{$fa-css-prefix}-kiss-wink-heart:after { content: fa-content(\10f598); }
.fad.#{$fa-css-prefix}-face-laugh:before, .fa-duotone.#{$fa-css-prefix}-face-laugh:before { content: fa-content(\f599\fe01); }
.fad.#{$fa-css-prefix}-face-laugh:after, .fa-duotone.#{$fa-css-prefix}-face-laugh:after { content: fa-content(\f599\fe02); }
.fad.#{$fa-css-prefix}-laugh:before, .fa-duotone.#{$fa-css-prefix}-laugh:before { content: fa-content(\f599\fe01); }
.fad.#{$fa-css-prefix}-laugh:after, .fa-duotone.#{$fa-css-prefix}-laugh:after { content: fa-content(\f599\fe02); }
.fad.#{$fa-css-prefix}-face-laugh-beam:before, .fa-duotone.#{$fa-css-prefix}-face-laugh-beam:before { content: fa-content(\f59a); }
.fad.#{$fa-css-prefix}-face-laugh-beam:after, .fa-duotone.#{$fa-css-prefix}-face-laugh-beam:after { content: fa-content(\10f59a); }
.fad.#{$fa-css-prefix}-laugh-beam:before, .fa-duotone.#{$fa-css-prefix}-laugh-beam:before { content: fa-content(\f59a); }
.fad.#{$fa-css-prefix}-laugh-beam:after, .fa-duotone.#{$fa-css-prefix}-laugh-beam:after { content: fa-content(\10f59a); }
.fad.#{$fa-css-prefix}-face-laugh-squint:before, .fa-duotone.#{$fa-css-prefix}-face-laugh-squint:before { content: fa-content(\f59b\fe01); }
.fad.#{$fa-css-prefix}-face-laugh-squint:after, .fa-duotone.#{$fa-css-prefix}-face-laugh-squint:after { content: fa-content(\f59b\fe02); }
.fad.#{$fa-css-prefix}-laugh-squint:before, .fa-duotone.#{$fa-css-prefix}-laugh-squint:before { content: fa-content(\f59b\fe01); }
.fad.#{$fa-css-prefix}-laugh-squint:after, .fa-duotone.#{$fa-css-prefix}-laugh-squint:after { content: fa-content(\f59b\fe02); }
.fad.#{$fa-css-prefix}-face-laugh-wink:before, .fa-duotone.#{$fa-css-prefix}-face-laugh-wink:before { content: fa-content(\f59c\fe01); }
.fad.#{$fa-css-prefix}-face-laugh-wink:after, .fa-duotone.#{$fa-css-prefix}-face-laugh-wink:after { content: fa-content(\f59c\fe02); }
.fad.#{$fa-css-prefix}-laugh-wink:before, .fa-duotone.#{$fa-css-prefix}-laugh-wink:before { content: fa-content(\f59c\fe01); }
.fad.#{$fa-css-prefix}-laugh-wink:after, .fa-duotone.#{$fa-css-prefix}-laugh-wink:after { content: fa-content(\f59c\fe02); }
.fad.#{$fa-css-prefix}-face-lying:before, .fa-duotone.#{$fa-css-prefix}-face-lying:before { content: fa-content(\e37e\fe01); }
.fad.#{$fa-css-prefix}-face-lying:after, .fa-duotone.#{$fa-css-prefix}-face-lying:after { content: fa-content(\e37e\fe02); }
.fad.#{$fa-css-prefix}-face-mask:before, .fa-duotone.#{$fa-css-prefix}-face-mask:before { content: fa-content(\e37f\fe01); }
.fad.#{$fa-css-prefix}-face-mask:after, .fa-duotone.#{$fa-css-prefix}-face-mask:after { content: fa-content(\e37f\fe02); }
.fad.#{$fa-css-prefix}-face-meh:before, .fa-duotone.#{$fa-css-prefix}-face-meh:before { content: fa-content(\f11a); }
.fad.#{$fa-css-prefix}-face-meh:after, .fa-duotone.#{$fa-css-prefix}-face-meh:after { content: fa-content(\10f11a); }
.fad.#{$fa-css-prefix}-meh:before, .fa-duotone.#{$fa-css-prefix}-meh:before { content: fa-content(\f11a); }
.fad.#{$fa-css-prefix}-meh:after, .fa-duotone.#{$fa-css-prefix}-meh:after { content: fa-content(\10f11a); }
.fad.#{$fa-css-prefix}-face-meh-blank:before, .fa-duotone.#{$fa-css-prefix}-face-meh-blank:before { content: fa-content(\f5a4); }
.fad.#{$fa-css-prefix}-face-meh-blank:after, .fa-duotone.#{$fa-css-prefix}-face-meh-blank:after { content: fa-content(\10f5a4); }
.fad.#{$fa-css-prefix}-meh-blank:before, .fa-duotone.#{$fa-css-prefix}-meh-blank:before { content: fa-content(\f5a4); }
.fad.#{$fa-css-prefix}-meh-blank:after, .fa-duotone.#{$fa-css-prefix}-meh-blank:after { content: fa-content(\10f5a4); }
.fad.#{$fa-css-prefix}-face-monocle:before, .fa-duotone.#{$fa-css-prefix}-face-monocle:before { content: fa-content(\e380\fe01); }
.fad.#{$fa-css-prefix}-face-monocle:after, .fa-duotone.#{$fa-css-prefix}-face-monocle:after { content: fa-content(\e380\fe02); }
.fad.#{$fa-css-prefix}-face-nauseated:before, .fa-duotone.#{$fa-css-prefix}-face-nauseated:before { content: fa-content(\e381\fe01); }
.fad.#{$fa-css-prefix}-face-nauseated:after, .fa-duotone.#{$fa-css-prefix}-face-nauseated:after { content: fa-content(\e381\fe02); }
.fad.#{$fa-css-prefix}-face-nose-steam:before, .fa-duotone.#{$fa-css-prefix}-face-nose-steam:before { content: fa-content(\e382\fe01); }
.fad.#{$fa-css-prefix}-face-nose-steam:after, .fa-duotone.#{$fa-css-prefix}-face-nose-steam:after { content: fa-content(\e382\fe02); }
.fad.#{$fa-css-prefix}-face-party:before, .fa-duotone.#{$fa-css-prefix}-face-party:before { content: fa-content(\e383\fe01); }
.fad.#{$fa-css-prefix}-face-party:after, .fa-duotone.#{$fa-css-prefix}-face-party:after { content: fa-content(\e383\fe02); }
.fad.#{$fa-css-prefix}-face-pensive:before, .fa-duotone.#{$fa-css-prefix}-face-pensive:before { content: fa-content(\e384\fe01); }
.fad.#{$fa-css-prefix}-face-pensive:after, .fa-duotone.#{$fa-css-prefix}-face-pensive:after { content: fa-content(\e384\fe02); }
.fad.#{$fa-css-prefix}-face-persevering:before, .fa-duotone.#{$fa-css-prefix}-face-persevering:before { content: fa-content(\e385\fe01); }
.fad.#{$fa-css-prefix}-face-persevering:after, .fa-duotone.#{$fa-css-prefix}-face-persevering:after { content: fa-content(\e385\fe02); }
.fad.#{$fa-css-prefix}-face-pleading:before, .fa-duotone.#{$fa-css-prefix}-face-pleading:before { content: fa-content(\e386\fe01); }
.fad.#{$fa-css-prefix}-face-pleading:after, .fa-duotone.#{$fa-css-prefix}-face-pleading:after { content: fa-content(\e386\fe02); }
.fad.#{$fa-css-prefix}-face-pouting:before, .fa-duotone.#{$fa-css-prefix}-face-pouting:before { content: fa-content(\e387\fe01); }
.fad.#{$fa-css-prefix}-face-pouting:after, .fa-duotone.#{$fa-css-prefix}-face-pouting:after { content: fa-content(\e387\fe02); }
.fad.#{$fa-css-prefix}-face-raised-eyebrow:before, .fa-duotone.#{$fa-css-prefix}-face-raised-eyebrow:before { content: fa-content(\e388\fe01); }
.fad.#{$fa-css-prefix}-face-raised-eyebrow:after, .fa-duotone.#{$fa-css-prefix}-face-raised-eyebrow:after { content: fa-content(\e388\fe02); }
.fad.#{$fa-css-prefix}-face-relieved:before, .fa-duotone.#{$fa-css-prefix}-face-relieved:before { content: fa-content(\e389\fe01); }
.fad.#{$fa-css-prefix}-face-relieved:after, .fa-duotone.#{$fa-css-prefix}-face-relieved:after { content: fa-content(\e389\fe02); }
.fad.#{$fa-css-prefix}-face-rolling-eyes:before, .fa-duotone.#{$fa-css-prefix}-face-rolling-eyes:before { content: fa-content(\f5a5); }
.fad.#{$fa-css-prefix}-face-rolling-eyes:after, .fa-duotone.#{$fa-css-prefix}-face-rolling-eyes:after { content: fa-content(\10f5a5); }
.fad.#{$fa-css-prefix}-meh-rolling-eyes:before, .fa-duotone.#{$fa-css-prefix}-meh-rolling-eyes:before { content: fa-content(\f5a5); }
.fad.#{$fa-css-prefix}-meh-rolling-eyes:after, .fa-duotone.#{$fa-css-prefix}-meh-rolling-eyes:after { content: fa-content(\10f5a5); }
.fad.#{$fa-css-prefix}-face-sad-cry:before, .fa-duotone.#{$fa-css-prefix}-face-sad-cry:before { content: fa-content(\f5b3); }
.fad.#{$fa-css-prefix}-face-sad-cry:after, .fa-duotone.#{$fa-css-prefix}-face-sad-cry:after { content: fa-content(\10f5b3); }
.fad.#{$fa-css-prefix}-sad-cry:before, .fa-duotone.#{$fa-css-prefix}-sad-cry:before { content: fa-content(\f5b3); }
.fad.#{$fa-css-prefix}-sad-cry:after, .fa-duotone.#{$fa-css-prefix}-sad-cry:after { content: fa-content(\10f5b3); }
.fad.#{$fa-css-prefix}-face-sad-sweat:before, .fa-duotone.#{$fa-css-prefix}-face-sad-sweat:before { content: fa-content(\e38a\fe01); }
.fad.#{$fa-css-prefix}-face-sad-sweat:after, .fa-duotone.#{$fa-css-prefix}-face-sad-sweat:after { content: fa-content(\e38a\fe02); }
.fad.#{$fa-css-prefix}-face-sad-tear:before, .fa-duotone.#{$fa-css-prefix}-face-sad-tear:before { content: fa-content(\f5b4); }
.fad.#{$fa-css-prefix}-face-sad-tear:after, .fa-duotone.#{$fa-css-prefix}-face-sad-tear:after { content: fa-content(\10f5b4); }
.fad.#{$fa-css-prefix}-sad-tear:before, .fa-duotone.#{$fa-css-prefix}-sad-tear:before { content: fa-content(\f5b4); }
.fad.#{$fa-css-prefix}-sad-tear:after, .fa-duotone.#{$fa-css-prefix}-sad-tear:after { content: fa-content(\10f5b4); }
.fad.#{$fa-css-prefix}-face-scream:before, .fa-duotone.#{$fa-css-prefix}-face-scream:before { content: fa-content(\e38b\fe01); }
.fad.#{$fa-css-prefix}-face-scream:after, .fa-duotone.#{$fa-css-prefix}-face-scream:after { content: fa-content(\e38b\fe02); }
.fad.#{$fa-css-prefix}-face-shush:before, .fa-duotone.#{$fa-css-prefix}-face-shush:before { content: fa-content(\e38c\fe01); }
.fad.#{$fa-css-prefix}-face-shush:after, .fa-duotone.#{$fa-css-prefix}-face-shush:after { content: fa-content(\e38c\fe02); }
.fad.#{$fa-css-prefix}-face-sleeping:before, .fa-duotone.#{$fa-css-prefix}-face-sleeping:before { content: fa-content(\e38d\fe01); }
.fad.#{$fa-css-prefix}-face-sleeping:after, .fa-duotone.#{$fa-css-prefix}-face-sleeping:after { content: fa-content(\e38d\fe02); }
.fad.#{$fa-css-prefix}-face-sleepy:before, .fa-duotone.#{$fa-css-prefix}-face-sleepy:before { content: fa-content(\e38e\fe01); }
.fad.#{$fa-css-prefix}-face-sleepy:after, .fa-duotone.#{$fa-css-prefix}-face-sleepy:after { content: fa-content(\e38e\fe02); }
.fad.#{$fa-css-prefix}-face-smile:before, .fa-duotone.#{$fa-css-prefix}-face-smile:before { content: fa-content(\f118); }
.fad.#{$fa-css-prefix}-face-smile:after, .fa-duotone.#{$fa-css-prefix}-face-smile:after { content: fa-content(\10f118); }
.fad.#{$fa-css-prefix}-smile:before, .fa-duotone.#{$fa-css-prefix}-smile:before { content: fa-content(\f118); }
.fad.#{$fa-css-prefix}-smile:after, .fa-duotone.#{$fa-css-prefix}-smile:after { content: fa-content(\10f118); }
.fad.#{$fa-css-prefix}-face-smile-beam:before, .fa-duotone.#{$fa-css-prefix}-face-smile-beam:before { content: fa-content(\f5b8); }
.fad.#{$fa-css-prefix}-face-smile-beam:after, .fa-duotone.#{$fa-css-prefix}-face-smile-beam:after { content: fa-content(\10f5b8); }
.fad.#{$fa-css-prefix}-smile-beam:before, .fa-duotone.#{$fa-css-prefix}-smile-beam:before { content: fa-content(\f5b8); }
.fad.#{$fa-css-prefix}-smile-beam:after, .fa-duotone.#{$fa-css-prefix}-smile-beam:after { content: fa-content(\10f5b8); }
.fad.#{$fa-css-prefix}-face-smile-halo:before, .fa-duotone.#{$fa-css-prefix}-face-smile-halo:before { content: fa-content(\e38f\fe01); }
.fad.#{$fa-css-prefix}-face-smile-halo:after, .fa-duotone.#{$fa-css-prefix}-face-smile-halo:after { content: fa-content(\e38f\fe02); }
.fad.#{$fa-css-prefix}-face-smile-hearts:before, .fa-duotone.#{$fa-css-prefix}-face-smile-hearts:before { content: fa-content(\e390\fe01); }
.fad.#{$fa-css-prefix}-face-smile-hearts:after, .fa-duotone.#{$fa-css-prefix}-face-smile-hearts:after { content: fa-content(\e390\fe02); }
.fad.#{$fa-css-prefix}-face-smile-horns:before, .fa-duotone.#{$fa-css-prefix}-face-smile-horns:before { content: fa-content(\e391\fe01); }
.fad.#{$fa-css-prefix}-face-smile-horns:after, .fa-duotone.#{$fa-css-prefix}-face-smile-horns:after { content: fa-content(\e391\fe02); }
.fad.#{$fa-css-prefix}-face-smile-plus:before, .fa-duotone.#{$fa-css-prefix}-face-smile-plus:before { content: fa-content(\f5b9\fe01); }
.fad.#{$fa-css-prefix}-face-smile-plus:after, .fa-duotone.#{$fa-css-prefix}-face-smile-plus:after { content: fa-content(\f5b9\fe02); }
.fad.#{$fa-css-prefix}-smile-plus:before, .fa-duotone.#{$fa-css-prefix}-smile-plus:before { content: fa-content(\f5b9\fe01); }
.fad.#{$fa-css-prefix}-smile-plus:after, .fa-duotone.#{$fa-css-prefix}-smile-plus:after { content: fa-content(\f5b9\fe02); }
.fad.#{$fa-css-prefix}-face-smile-relaxed:before, .fa-duotone.#{$fa-css-prefix}-face-smile-relaxed:before { content: fa-content(\e392\fe01); }
.fad.#{$fa-css-prefix}-face-smile-relaxed:after, .fa-duotone.#{$fa-css-prefix}-face-smile-relaxed:after { content: fa-content(\e392\fe02); }
.fad.#{$fa-css-prefix}-face-smile-tear:before, .fa-duotone.#{$fa-css-prefix}-face-smile-tear:before { content: fa-content(\e393\fe01); }
.fad.#{$fa-css-prefix}-face-smile-tear:after, .fa-duotone.#{$fa-css-prefix}-face-smile-tear:after { content: fa-content(\e393\fe02); }
.fad.#{$fa-css-prefix}-face-smile-tongue:before, .fa-duotone.#{$fa-css-prefix}-face-smile-tongue:before { content: fa-content(\e394\fe01); }
.fad.#{$fa-css-prefix}-face-smile-tongue:after, .fa-duotone.#{$fa-css-prefix}-face-smile-tongue:after { content: fa-content(\e394\fe02); }
.fad.#{$fa-css-prefix}-face-smile-upside-down:before, .fa-duotone.#{$fa-css-prefix}-face-smile-upside-down:before { content: fa-content(\e395\fe01); }
.fad.#{$fa-css-prefix}-face-smile-upside-down:after, .fa-duotone.#{$fa-css-prefix}-face-smile-upside-down:after { content: fa-content(\e395\fe02); }
.fad.#{$fa-css-prefix}-face-smile-wink:before, .fa-duotone.#{$fa-css-prefix}-face-smile-wink:before { content: fa-content(\f4da); }
.fad.#{$fa-css-prefix}-face-smile-wink:after, .fa-duotone.#{$fa-css-prefix}-face-smile-wink:after { content: fa-content(\10f4da); }
.fad.#{$fa-css-prefix}-smile-wink:before, .fa-duotone.#{$fa-css-prefix}-smile-wink:before { content: fa-content(\f4da); }
.fad.#{$fa-css-prefix}-smile-wink:after, .fa-duotone.#{$fa-css-prefix}-smile-wink:after { content: fa-content(\10f4da); }
.fad.#{$fa-css-prefix}-face-smiling-hands:before, .fa-duotone.#{$fa-css-prefix}-face-smiling-hands:before { content: fa-content(\e396\fe01); }
.fad.#{$fa-css-prefix}-face-smiling-hands:after, .fa-duotone.#{$fa-css-prefix}-face-smiling-hands:after { content: fa-content(\e396\fe02); }
.fad.#{$fa-css-prefix}-face-smirking:before, .fa-duotone.#{$fa-css-prefix}-face-smirking:before { content: fa-content(\e397\fe01); }
.fad.#{$fa-css-prefix}-face-smirking:after, .fa-duotone.#{$fa-css-prefix}-face-smirking:after { content: fa-content(\e397\fe02); }
.fad.#{$fa-css-prefix}-face-sunglasses:before, .fa-duotone.#{$fa-css-prefix}-face-sunglasses:before { content: fa-content(\e398\fe01); }
.fad.#{$fa-css-prefix}-face-sunglasses:after, .fa-duotone.#{$fa-css-prefix}-face-sunglasses:after { content: fa-content(\e398\fe02); }
.fad.#{$fa-css-prefix}-face-surprise:before, .fa-duotone.#{$fa-css-prefix}-face-surprise:before { content: fa-content(\f5c2); }
.fad.#{$fa-css-prefix}-face-surprise:after, .fa-duotone.#{$fa-css-prefix}-face-surprise:after { content: fa-content(\10f5c2); }
.fad.#{$fa-css-prefix}-surprise:before, .fa-duotone.#{$fa-css-prefix}-surprise:before { content: fa-content(\f5c2); }
.fad.#{$fa-css-prefix}-surprise:after, .fa-duotone.#{$fa-css-prefix}-surprise:after { content: fa-content(\10f5c2); }
.fad.#{$fa-css-prefix}-face-swear:before, .fa-duotone.#{$fa-css-prefix}-face-swear:before { content: fa-content(\e399\fe01); }
.fad.#{$fa-css-prefix}-face-swear:after, .fa-duotone.#{$fa-css-prefix}-face-swear:after { content: fa-content(\e399\fe02); }
.fad.#{$fa-css-prefix}-face-thermometer:before, .fa-duotone.#{$fa-css-prefix}-face-thermometer:before { content: fa-content(\e39a\fe01); }
.fad.#{$fa-css-prefix}-face-thermometer:after, .fa-duotone.#{$fa-css-prefix}-face-thermometer:after { content: fa-content(\e39a\fe02); }
.fad.#{$fa-css-prefix}-face-thinking:before, .fa-duotone.#{$fa-css-prefix}-face-thinking:before { content: fa-content(\e39b\fe01); }
.fad.#{$fa-css-prefix}-face-thinking:after, .fa-duotone.#{$fa-css-prefix}-face-thinking:after { content: fa-content(\e39b\fe02); }
.fad.#{$fa-css-prefix}-face-tired:before, .fa-duotone.#{$fa-css-prefix}-face-tired:before { content: fa-content(\f5c8); }
.fad.#{$fa-css-prefix}-face-tired:after, .fa-duotone.#{$fa-css-prefix}-face-tired:after { content: fa-content(\10f5c8); }
.fad.#{$fa-css-prefix}-tired:before, .fa-duotone.#{$fa-css-prefix}-tired:before { content: fa-content(\f5c8); }
.fad.#{$fa-css-prefix}-tired:after, .fa-duotone.#{$fa-css-prefix}-tired:after { content: fa-content(\10f5c8); }
.fad.#{$fa-css-prefix}-face-tissue:before, .fa-duotone.#{$fa-css-prefix}-face-tissue:before { content: fa-content(\e39c\fe01); }
.fad.#{$fa-css-prefix}-face-tissue:after, .fa-duotone.#{$fa-css-prefix}-face-tissue:after { content: fa-content(\e39c\fe02); }
.fad.#{$fa-css-prefix}-face-tongue-money:before, .fa-duotone.#{$fa-css-prefix}-face-tongue-money:before { content: fa-content(\e39d\fe01); }
.fad.#{$fa-css-prefix}-face-tongue-money:after, .fa-duotone.#{$fa-css-prefix}-face-tongue-money:after { content: fa-content(\e39d\fe02); }
.fad.#{$fa-css-prefix}-face-tongue-sweat:before, .fa-duotone.#{$fa-css-prefix}-face-tongue-sweat:before { content: fa-content(\e39e\fe01); }
.fad.#{$fa-css-prefix}-face-tongue-sweat:after, .fa-duotone.#{$fa-css-prefix}-face-tongue-sweat:after { content: fa-content(\e39e\fe02); }
.fad.#{$fa-css-prefix}-face-unamused:before, .fa-duotone.#{$fa-css-prefix}-face-unamused:before { content: fa-content(\e39f\fe01); }
.fad.#{$fa-css-prefix}-face-unamused:after, .fa-duotone.#{$fa-css-prefix}-face-unamused:after { content: fa-content(\e39f\fe02); }
.fad.#{$fa-css-prefix}-face-viewfinder:before, .fa-duotone.#{$fa-css-prefix}-face-viewfinder:before { content: fa-content(\e2ff\fe01); }
.fad.#{$fa-css-prefix}-face-viewfinder:after, .fa-duotone.#{$fa-css-prefix}-face-viewfinder:after { content: fa-content(\e2ff\fe02); }
.fad.#{$fa-css-prefix}-face-vomit:before, .fa-duotone.#{$fa-css-prefix}-face-vomit:before { content: fa-content(\e3a0\fe01); }
.fad.#{$fa-css-prefix}-face-vomit:after, .fa-duotone.#{$fa-css-prefix}-face-vomit:after { content: fa-content(\e3a0\fe02); }
.fad.#{$fa-css-prefix}-face-weary:before, .fa-duotone.#{$fa-css-prefix}-face-weary:before { content: fa-content(\e3a1\fe01); }
.fad.#{$fa-css-prefix}-face-weary:after, .fa-duotone.#{$fa-css-prefix}-face-weary:after { content: fa-content(\e3a1\fe02); }
.fad.#{$fa-css-prefix}-face-woozy:before, .fa-duotone.#{$fa-css-prefix}-face-woozy:before { content: fa-content(\e3a2\fe01); }
.fad.#{$fa-css-prefix}-face-woozy:after, .fa-duotone.#{$fa-css-prefix}-face-woozy:after { content: fa-content(\e3a2\fe02); }
.fad.#{$fa-css-prefix}-face-worried:before, .fa-duotone.#{$fa-css-prefix}-face-worried:before { content: fa-content(\e3a3\fe01); }
.fad.#{$fa-css-prefix}-face-worried:after, .fa-duotone.#{$fa-css-prefix}-face-worried:after { content: fa-content(\e3a3\fe02); }
.fad.#{$fa-css-prefix}-face-zany:before, .fa-duotone.#{$fa-css-prefix}-face-zany:before { content: fa-content(\e3a4\fe01); }
.fad.#{$fa-css-prefix}-face-zany:after, .fa-duotone.#{$fa-css-prefix}-face-zany:after { content: fa-content(\e3a4\fe02); }
.fad.#{$fa-css-prefix}-face-zipper:before, .fa-duotone.#{$fa-css-prefix}-face-zipper:before { content: fa-content(\e3a5\fe01); }
.fad.#{$fa-css-prefix}-face-zipper:after, .fa-duotone.#{$fa-css-prefix}-face-zipper:after { content: fa-content(\e3a5\fe02); }
.fad.#{$fa-css-prefix}-family:before, .fa-duotone.#{$fa-css-prefix}-family:before { content: fa-content(\e300\fe01); }
.fad.#{$fa-css-prefix}-family:after, .fa-duotone.#{$fa-css-prefix}-family:after { content: fa-content(\e300\fe02); }
.fad.#{$fa-css-prefix}-family-dress:before, .fa-duotone.#{$fa-css-prefix}-family-dress:before { content: fa-content(\e301\fe01); }
.fad.#{$fa-css-prefix}-family-dress:after, .fa-duotone.#{$fa-css-prefix}-family-dress:after { content: fa-content(\e301\fe02); }
.fad.#{$fa-css-prefix}-family-pants:before, .fa-duotone.#{$fa-css-prefix}-family-pants:before { content: fa-content(\e302\fe01); }
.fad.#{$fa-css-prefix}-family-pants:after, .fa-duotone.#{$fa-css-prefix}-family-pants:after { content: fa-content(\e302\fe02); }
.fad.#{$fa-css-prefix}-fan:before, .fa-duotone.#{$fa-css-prefix}-fan:before { content: fa-content(\f863\fe01); }
.fad.#{$fa-css-prefix}-fan:after, .fa-duotone.#{$fa-css-prefix}-fan:after { content: fa-content(\f863\fe02); }
.fad.#{$fa-css-prefix}-fan-table:before, .fa-duotone.#{$fa-css-prefix}-fan-table:before { content: fa-content(\e004\fe01); }
.fad.#{$fa-css-prefix}-fan-table:after, .fa-duotone.#{$fa-css-prefix}-fan-table:after { content: fa-content(\e004\fe02); }
.fad.#{$fa-css-prefix}-farm:before, .fa-duotone.#{$fa-css-prefix}-farm:before { content: fa-content(\f864\fe01); }
.fad.#{$fa-css-prefix}-farm:after, .fa-duotone.#{$fa-css-prefix}-farm:after { content: fa-content(\f864\fe02); }
.fad.#{$fa-css-prefix}-barn-silo:before, .fa-duotone.#{$fa-css-prefix}-barn-silo:before { content: fa-content(\f864\fe01); }
.fad.#{$fa-css-prefix}-barn-silo:after, .fa-duotone.#{$fa-css-prefix}-barn-silo:after { content: fa-content(\f864\fe02); }
.fad.#{$fa-css-prefix}-faucet:before, .fa-duotone.#{$fa-css-prefix}-faucet:before { content: fa-content(\e005\fe01); }
.fad.#{$fa-css-prefix}-faucet:after, .fa-duotone.#{$fa-css-prefix}-faucet:after { content: fa-content(\e005\fe02); }
.fad.#{$fa-css-prefix}-faucet-drip:before, .fa-duotone.#{$fa-css-prefix}-faucet-drip:before { content: fa-content(\e006); }
.fad.#{$fa-css-prefix}-faucet-drip:after, .fa-duotone.#{$fa-css-prefix}-faucet-drip:after { content: fa-content(\10e006); }
.fad.#{$fa-css-prefix}-fax:before, .fa-duotone.#{$fa-css-prefix}-fax:before { content: fa-content(\f1ac); }
.fad.#{$fa-css-prefix}-fax:after, .fa-duotone.#{$fa-css-prefix}-fax:after { content: fa-content(\10f1ac); }
.fad.#{$fa-css-prefix}-feather:before, .fa-duotone.#{$fa-css-prefix}-feather:before { content: fa-content(\f52d); }
.fad.#{$fa-css-prefix}-feather:after, .fa-duotone.#{$fa-css-prefix}-feather:after { content: fa-content(\10f52d); }
.fad.#{$fa-css-prefix}-feather-pointed:before, .fa-duotone.#{$fa-css-prefix}-feather-pointed:before { content: fa-content(\f56b\fe01); }
.fad.#{$fa-css-prefix}-feather-pointed:after, .fa-duotone.#{$fa-css-prefix}-feather-pointed:after { content: fa-content(\f56b\fe02); }
.fad.#{$fa-css-prefix}-feather-alt:before, .fa-duotone.#{$fa-css-prefix}-feather-alt:before { content: fa-content(\f56b\fe01); }
.fad.#{$fa-css-prefix}-feather-alt:after, .fa-duotone.#{$fa-css-prefix}-feather-alt:after { content: fa-content(\f56b\fe02); }
.fad.#{$fa-css-prefix}-fence:before, .fa-duotone.#{$fa-css-prefix}-fence:before { content: fa-content(\e303\fe01); }
.fad.#{$fa-css-prefix}-fence:after, .fa-duotone.#{$fa-css-prefix}-fence:after { content: fa-content(\e303\fe02); }
.fad.#{$fa-css-prefix}-ferris-wheel:before, .fa-duotone.#{$fa-css-prefix}-ferris-wheel:before { content: fa-content(\e174\fe01); }
.fad.#{$fa-css-prefix}-ferris-wheel:after, .fa-duotone.#{$fa-css-prefix}-ferris-wheel:after { content: fa-content(\e174\fe02); }
.fad.#{$fa-css-prefix}-field-hockey-stick-ball:before, .fa-duotone.#{$fa-css-prefix}-field-hockey-stick-ball:before { content: fa-content(\f44c); }
.fad.#{$fa-css-prefix}-field-hockey-stick-ball:after, .fa-duotone.#{$fa-css-prefix}-field-hockey-stick-ball:after { content: fa-content(\10f44c); }
.fad.#{$fa-css-prefix}-field-hockey:before, .fa-duotone.#{$fa-css-prefix}-field-hockey:before { content: fa-content(\f44c); }
.fad.#{$fa-css-prefix}-field-hockey:after, .fa-duotone.#{$fa-css-prefix}-field-hockey:after { content: fa-content(\10f44c); }
.fad.#{$fa-css-prefix}-file:before, .fa-duotone.#{$fa-css-prefix}-file:before { content: fa-content(\f15b); }
.fad.#{$fa-css-prefix}-file:after, .fa-duotone.#{$fa-css-prefix}-file:after { content: fa-content(\10f15b); }
.fad.#{$fa-css-prefix}-file-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-file-arrow-down:before { content: fa-content(\f56d\fe01); }
.fad.#{$fa-css-prefix}-file-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-file-arrow-down:after { content: fa-content(\f56d\fe02); }
.fad.#{$fa-css-prefix}-file-download:before, .fa-duotone.#{$fa-css-prefix}-file-download:before { content: fa-content(\f56d\fe01); }
.fad.#{$fa-css-prefix}-file-download:after, .fa-duotone.#{$fa-css-prefix}-file-download:after { content: fa-content(\f56d\fe02); }
.fad.#{$fa-css-prefix}-file-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-file-arrow-up:before { content: fa-content(\f574\fe01); }
.fad.#{$fa-css-prefix}-file-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-file-arrow-up:after { content: fa-content(\f574\fe02); }
.fad.#{$fa-css-prefix}-file-upload:before, .fa-duotone.#{$fa-css-prefix}-file-upload:before { content: fa-content(\f574\fe01); }
.fad.#{$fa-css-prefix}-file-upload:after, .fa-duotone.#{$fa-css-prefix}-file-upload:after { content: fa-content(\f574\fe02); }
.fad.#{$fa-css-prefix}-file-audio:before, .fa-duotone.#{$fa-css-prefix}-file-audio:before { content: fa-content(\f1c7\fe01); }
.fad.#{$fa-css-prefix}-file-audio:after, .fa-duotone.#{$fa-css-prefix}-file-audio:after { content: fa-content(\f1c7\fe02); }
.fad.#{$fa-css-prefix}-file-binary:before, .fa-duotone.#{$fa-css-prefix}-file-binary:before { content: fa-content(\e175\fe01); }
.fad.#{$fa-css-prefix}-file-binary:after, .fa-duotone.#{$fa-css-prefix}-file-binary:after { content: fa-content(\e175\fe02); }
.fad.#{$fa-css-prefix}-file-certificate:before, .fa-duotone.#{$fa-css-prefix}-file-certificate:before { content: fa-content(\f5f3\fe01); }
.fad.#{$fa-css-prefix}-file-certificate:after, .fa-duotone.#{$fa-css-prefix}-file-certificate:after { content: fa-content(\f5f3\fe02); }
.fad.#{$fa-css-prefix}-file-award:before, .fa-duotone.#{$fa-css-prefix}-file-award:before { content: fa-content(\f5f3\fe01); }
.fad.#{$fa-css-prefix}-file-award:after, .fa-duotone.#{$fa-css-prefix}-file-award:after { content: fa-content(\f5f3\fe02); }
.fad.#{$fa-css-prefix}-file-chart-column:before, .fa-duotone.#{$fa-css-prefix}-file-chart-column:before { content: fa-content(\f659\fe01); }
.fad.#{$fa-css-prefix}-file-chart-column:after, .fa-duotone.#{$fa-css-prefix}-file-chart-column:after { content: fa-content(\f659\fe02); }
.fad.#{$fa-css-prefix}-file-chart-line:before, .fa-duotone.#{$fa-css-prefix}-file-chart-line:before { content: fa-content(\f659\fe01); }
.fad.#{$fa-css-prefix}-file-chart-line:after, .fa-duotone.#{$fa-css-prefix}-file-chart-line:after { content: fa-content(\f659\fe02); }
.fad.#{$fa-css-prefix}-file-chart-pie:before, .fa-duotone.#{$fa-css-prefix}-file-chart-pie:before { content: fa-content(\f65a\fe01); }
.fad.#{$fa-css-prefix}-file-chart-pie:after, .fa-duotone.#{$fa-css-prefix}-file-chart-pie:after { content: fa-content(\f65a\fe02); }
.fad.#{$fa-css-prefix}-file-check:before, .fa-duotone.#{$fa-css-prefix}-file-check:before { content: fa-content(\f316\fe01); }
.fad.#{$fa-css-prefix}-file-check:after, .fa-duotone.#{$fa-css-prefix}-file-check:after { content: fa-content(\f316\fe02); }
.fad.#{$fa-css-prefix}-file-code:before, .fa-duotone.#{$fa-css-prefix}-file-code:before { content: fa-content(\f1c9\fe01); }
.fad.#{$fa-css-prefix}-file-code:after, .fa-duotone.#{$fa-css-prefix}-file-code:after { content: fa-content(\f1c9\fe02); }
.fad.#{$fa-css-prefix}-file-contract:before, .fa-duotone.#{$fa-css-prefix}-file-contract:before { content: fa-content(\f56c\fe01); }
.fad.#{$fa-css-prefix}-file-contract:after, .fa-duotone.#{$fa-css-prefix}-file-contract:after { content: fa-content(\f56c\fe02); }
.fad.#{$fa-css-prefix}-file-csv:before, .fa-duotone.#{$fa-css-prefix}-file-csv:before { content: fa-content(\f6dd\fe01); }
.fad.#{$fa-css-prefix}-file-csv:after, .fa-duotone.#{$fa-css-prefix}-file-csv:after { content: fa-content(\f6dd\fe02); }
.fad.#{$fa-css-prefix}-file-dashed-line:before, .fa-duotone.#{$fa-css-prefix}-file-dashed-line:before { content: fa-content(\f877\fe01); }
.fad.#{$fa-css-prefix}-file-dashed-line:after, .fa-duotone.#{$fa-css-prefix}-file-dashed-line:after { content: fa-content(\f877\fe02); }
.fad.#{$fa-css-prefix}-page-break:before, .fa-duotone.#{$fa-css-prefix}-page-break:before { content: fa-content(\f877\fe01); }
.fad.#{$fa-css-prefix}-page-break:after, .fa-duotone.#{$fa-css-prefix}-page-break:after { content: fa-content(\f877\fe02); }
.fad.#{$fa-css-prefix}-file-excel:before, .fa-duotone.#{$fa-css-prefix}-file-excel:before { content: fa-content(\f1c3\fe01); }
.fad.#{$fa-css-prefix}-file-excel:after, .fa-duotone.#{$fa-css-prefix}-file-excel:after { content: fa-content(\f1c3\fe02); }
.fad.#{$fa-css-prefix}-file-exclamation:before, .fa-duotone.#{$fa-css-prefix}-file-exclamation:before { content: fa-content(\f31a\fe01); }
.fad.#{$fa-css-prefix}-file-exclamation:after, .fa-duotone.#{$fa-css-prefix}-file-exclamation:after { content: fa-content(\f31a\fe02); }
.fad.#{$fa-css-prefix}-file-export:before, .fa-duotone.#{$fa-css-prefix}-file-export:before { content: fa-content(\f56e\fe01); }
.fad.#{$fa-css-prefix}-file-export:after, .fa-duotone.#{$fa-css-prefix}-file-export:after { content: fa-content(\f56e\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-from-file:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-file:before { content: fa-content(\f56e\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-from-file:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-file:after { content: fa-content(\f56e\fe02); }
.fad.#{$fa-css-prefix}-file-heart:before, .fa-duotone.#{$fa-css-prefix}-file-heart:before { content: fa-content(\e176\fe01); }
.fad.#{$fa-css-prefix}-file-heart:after, .fa-duotone.#{$fa-css-prefix}-file-heart:after { content: fa-content(\e176\fe02); }
.fad.#{$fa-css-prefix}-file-image:before, .fa-duotone.#{$fa-css-prefix}-file-image:before { content: fa-content(\f1c5); }
.fad.#{$fa-css-prefix}-file-image:after, .fa-duotone.#{$fa-css-prefix}-file-image:after { content: fa-content(\10f1c5); }
.fad.#{$fa-css-prefix}-file-import:before, .fa-duotone.#{$fa-css-prefix}-file-import:before { content: fa-content(\f56f\fe01); }
.fad.#{$fa-css-prefix}-file-import:after, .fa-duotone.#{$fa-css-prefix}-file-import:after { content: fa-content(\f56f\fe02); }
.fad.#{$fa-css-prefix}-arrow-right-to-file:before, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-file:before { content: fa-content(\f56f\fe01); }
.fad.#{$fa-css-prefix}-arrow-right-to-file:after, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-file:after { content: fa-content(\f56f\fe02); }
.fad.#{$fa-css-prefix}-file-invoice:before, .fa-duotone.#{$fa-css-prefix}-file-invoice:before { content: fa-content(\f570\fe01); }
.fad.#{$fa-css-prefix}-file-invoice:after, .fa-duotone.#{$fa-css-prefix}-file-invoice:after { content: fa-content(\f570\fe02); }
.fad.#{$fa-css-prefix}-file-invoice-dollar:before, .fa-duotone.#{$fa-css-prefix}-file-invoice-dollar:before { content: fa-content(\f571\fe01); }
.fad.#{$fa-css-prefix}-file-invoice-dollar:after, .fa-duotone.#{$fa-css-prefix}-file-invoice-dollar:after { content: fa-content(\f571\fe02); }
.fad.#{$fa-css-prefix}-file-lines:before, .fa-duotone.#{$fa-css-prefix}-file-lines:before { content: fa-content(\f15c); }
.fad.#{$fa-css-prefix}-file-lines:after, .fa-duotone.#{$fa-css-prefix}-file-lines:after { content: fa-content(\10f15c); }
.fad.#{$fa-css-prefix}-file-alt:before, .fa-duotone.#{$fa-css-prefix}-file-alt:before { content: fa-content(\f15c); }
.fad.#{$fa-css-prefix}-file-alt:after, .fa-duotone.#{$fa-css-prefix}-file-alt:after { content: fa-content(\10f15c); }
.fad.#{$fa-css-prefix}-file-text:before, .fa-duotone.#{$fa-css-prefix}-file-text:before { content: fa-content(\f15c); }
.fad.#{$fa-css-prefix}-file-text:after, .fa-duotone.#{$fa-css-prefix}-file-text:after { content: fa-content(\10f15c); }
.fad.#{$fa-css-prefix}-file-lock:before, .fa-duotone.#{$fa-css-prefix}-file-lock:before { content: fa-content(\e3a6\fe01); }
.fad.#{$fa-css-prefix}-file-lock:after, .fa-duotone.#{$fa-css-prefix}-file-lock:after { content: fa-content(\e3a6\fe02); }
.fad.#{$fa-css-prefix}-file-magnifying-glass:before, .fa-duotone.#{$fa-css-prefix}-file-magnifying-glass:before { content: fa-content(\f865\fe01); }
.fad.#{$fa-css-prefix}-file-magnifying-glass:after, .fa-duotone.#{$fa-css-prefix}-file-magnifying-glass:after { content: fa-content(\f865\fe02); }
.fad.#{$fa-css-prefix}-file-search:before, .fa-duotone.#{$fa-css-prefix}-file-search:before { content: fa-content(\f865\fe01); }
.fad.#{$fa-css-prefix}-file-search:after, .fa-duotone.#{$fa-css-prefix}-file-search:after { content: fa-content(\f865\fe02); }
.fad.#{$fa-css-prefix}-file-medical:before, .fa-duotone.#{$fa-css-prefix}-file-medical:before { content: fa-content(\f477\fe01); }
.fad.#{$fa-css-prefix}-file-medical:after, .fa-duotone.#{$fa-css-prefix}-file-medical:after { content: fa-content(\f477\fe02); }
.fad.#{$fa-css-prefix}-file-minus:before, .fa-duotone.#{$fa-css-prefix}-file-minus:before { content: fa-content(\f318\fe01); }
.fad.#{$fa-css-prefix}-file-minus:after, .fa-duotone.#{$fa-css-prefix}-file-minus:after { content: fa-content(\f318\fe02); }
.fad.#{$fa-css-prefix}-file-music:before, .fa-duotone.#{$fa-css-prefix}-file-music:before { content: fa-content(\f8b6\fe01); }
.fad.#{$fa-css-prefix}-file-music:after, .fa-duotone.#{$fa-css-prefix}-file-music:after { content: fa-content(\f8b6\fe02); }
.fad.#{$fa-css-prefix}-file-pdf:before, .fa-duotone.#{$fa-css-prefix}-file-pdf:before { content: fa-content(\f1c1\fe01); }
.fad.#{$fa-css-prefix}-file-pdf:after, .fa-duotone.#{$fa-css-prefix}-file-pdf:after { content: fa-content(\f1c1\fe02); }
.fad.#{$fa-css-prefix}-file-pen:before, .fa-duotone.#{$fa-css-prefix}-file-pen:before { content: fa-content(\f31c); }
.fad.#{$fa-css-prefix}-file-pen:after, .fa-duotone.#{$fa-css-prefix}-file-pen:after { content: fa-content(\10f31c); }
.fad.#{$fa-css-prefix}-file-edit:before, .fa-duotone.#{$fa-css-prefix}-file-edit:before { content: fa-content(\f31c); }
.fad.#{$fa-css-prefix}-file-edit:after, .fa-duotone.#{$fa-css-prefix}-file-edit:after { content: fa-content(\10f31c); }
.fad.#{$fa-css-prefix}-file-plus:before, .fa-duotone.#{$fa-css-prefix}-file-plus:before { content: fa-content(\f319\fe01); }
.fad.#{$fa-css-prefix}-file-plus:after, .fa-duotone.#{$fa-css-prefix}-file-plus:after { content: fa-content(\f319\fe02); }
.fad.#{$fa-css-prefix}-file-plus-minus:before, .fa-duotone.#{$fa-css-prefix}-file-plus-minus:before { content: fa-content(\e177\fe01); }
.fad.#{$fa-css-prefix}-file-plus-minus:after, .fa-duotone.#{$fa-css-prefix}-file-plus-minus:after { content: fa-content(\e177\fe02); }
.fad.#{$fa-css-prefix}-file-powerpoint:before, .fa-duotone.#{$fa-css-prefix}-file-powerpoint:before { content: fa-content(\f1c4\fe01); }
.fad.#{$fa-css-prefix}-file-powerpoint:after, .fa-duotone.#{$fa-css-prefix}-file-powerpoint:after { content: fa-content(\f1c4\fe02); }
.fad.#{$fa-css-prefix}-file-prescription:before, .fa-duotone.#{$fa-css-prefix}-file-prescription:before { content: fa-content(\f572\fe01); }
.fad.#{$fa-css-prefix}-file-prescription:after, .fa-duotone.#{$fa-css-prefix}-file-prescription:after { content: fa-content(\f572\fe02); }
.fad.#{$fa-css-prefix}-file-signature:before, .fa-duotone.#{$fa-css-prefix}-file-signature:before { content: fa-content(\f573\fe01); }
.fad.#{$fa-css-prefix}-file-signature:after, .fa-duotone.#{$fa-css-prefix}-file-signature:after { content: fa-content(\f573\fe02); }
.fad.#{$fa-css-prefix}-file-slash:before, .fa-duotone.#{$fa-css-prefix}-file-slash:before { content: fa-content(\e3a7\fe01); }
.fad.#{$fa-css-prefix}-file-slash:after, .fa-duotone.#{$fa-css-prefix}-file-slash:after { content: fa-content(\e3a7\fe02); }
.fad.#{$fa-css-prefix}-file-spreadsheet:before, .fa-duotone.#{$fa-css-prefix}-file-spreadsheet:before { content: fa-content(\f65b\fe01); }
.fad.#{$fa-css-prefix}-file-spreadsheet:after, .fa-duotone.#{$fa-css-prefix}-file-spreadsheet:after { content: fa-content(\f65b\fe02); }
.fad.#{$fa-css-prefix}-file-user:before, .fa-duotone.#{$fa-css-prefix}-file-user:before { content: fa-content(\f65c\fe01); }
.fad.#{$fa-css-prefix}-file-user:after, .fa-duotone.#{$fa-css-prefix}-file-user:after { content: fa-content(\f65c\fe02); }
.fad.#{$fa-css-prefix}-file-video:before, .fa-duotone.#{$fa-css-prefix}-file-video:before { content: fa-content(\f1c8\fe01); }
.fad.#{$fa-css-prefix}-file-video:after, .fa-duotone.#{$fa-css-prefix}-file-video:after { content: fa-content(\f1c8\fe02); }
.fad.#{$fa-css-prefix}-file-waveform:before, .fa-duotone.#{$fa-css-prefix}-file-waveform:before { content: fa-content(\f478\fe01); }
.fad.#{$fa-css-prefix}-file-waveform:after, .fa-duotone.#{$fa-css-prefix}-file-waveform:after { content: fa-content(\f478\fe02); }
.fad.#{$fa-css-prefix}-file-medical-alt:before, .fa-duotone.#{$fa-css-prefix}-file-medical-alt:before { content: fa-content(\f478\fe01); }
.fad.#{$fa-css-prefix}-file-medical-alt:after, .fa-duotone.#{$fa-css-prefix}-file-medical-alt:after { content: fa-content(\f478\fe02); }
.fad.#{$fa-css-prefix}-file-word:before, .fa-duotone.#{$fa-css-prefix}-file-word:before { content: fa-content(\f1c2\fe01); }
.fad.#{$fa-css-prefix}-file-word:after, .fa-duotone.#{$fa-css-prefix}-file-word:after { content: fa-content(\f1c2\fe02); }
.fad.#{$fa-css-prefix}-file-xmark:before, .fa-duotone.#{$fa-css-prefix}-file-xmark:before { content: fa-content(\f317\fe01); }
.fad.#{$fa-css-prefix}-file-xmark:after, .fa-duotone.#{$fa-css-prefix}-file-xmark:after { content: fa-content(\f317\fe02); }
.fad.#{$fa-css-prefix}-file-times:before, .fa-duotone.#{$fa-css-prefix}-file-times:before { content: fa-content(\f317\fe01); }
.fad.#{$fa-css-prefix}-file-times:after, .fa-duotone.#{$fa-css-prefix}-file-times:after { content: fa-content(\f317\fe02); }
.fad.#{$fa-css-prefix}-file-zipper:before, .fa-duotone.#{$fa-css-prefix}-file-zipper:before { content: fa-content(\f1c6\fe01); }
.fad.#{$fa-css-prefix}-file-zipper:after, .fa-duotone.#{$fa-css-prefix}-file-zipper:after { content: fa-content(\f1c6\fe02); }
.fad.#{$fa-css-prefix}-file-archive:before, .fa-duotone.#{$fa-css-prefix}-file-archive:before { content: fa-content(\f1c6\fe01); }
.fad.#{$fa-css-prefix}-file-archive:after, .fa-duotone.#{$fa-css-prefix}-file-archive:after { content: fa-content(\f1c6\fe02); }
.fad.#{$fa-css-prefix}-files:before, .fa-duotone.#{$fa-css-prefix}-files:before { content: fa-content(\e178\fe01); }
.fad.#{$fa-css-prefix}-files:after, .fa-duotone.#{$fa-css-prefix}-files:after { content: fa-content(\e178\fe02); }
.fad.#{$fa-css-prefix}-files-medical:before, .fa-duotone.#{$fa-css-prefix}-files-medical:before { content: fa-content(\f7fd\fe01); }
.fad.#{$fa-css-prefix}-files-medical:after, .fa-duotone.#{$fa-css-prefix}-files-medical:after { content: fa-content(\f7fd\fe02); }
.fad.#{$fa-css-prefix}-fill:before, .fa-duotone.#{$fa-css-prefix}-fill:before { content: fa-content(\f575\fe01); }
.fad.#{$fa-css-prefix}-fill:after, .fa-duotone.#{$fa-css-prefix}-fill:after { content: fa-content(\f575\fe02); }
.fad.#{$fa-css-prefix}-fill-drip:before, .fa-duotone.#{$fa-css-prefix}-fill-drip:before { content: fa-content(\f576\fe01); }
.fad.#{$fa-css-prefix}-fill-drip:after, .fa-duotone.#{$fa-css-prefix}-fill-drip:after { content: fa-content(\f576\fe02); }
.fad.#{$fa-css-prefix}-film:before, .fa-duotone.#{$fa-css-prefix}-film:before { content: fa-content(\f008); }
.fad.#{$fa-css-prefix}-film:after, .fa-duotone.#{$fa-css-prefix}-film:after { content: fa-content(\10f008); }
.fad.#{$fa-css-prefix}-film-canister:before, .fa-duotone.#{$fa-css-prefix}-film-canister:before { content: fa-content(\f8b7\fe01); }
.fad.#{$fa-css-prefix}-film-canister:after, .fa-duotone.#{$fa-css-prefix}-film-canister:after { content: fa-content(\f8b7\fe02); }
.fad.#{$fa-css-prefix}-film-simple:before, .fa-duotone.#{$fa-css-prefix}-film-simple:before { content: fa-content(\f3a0\fe01); }
.fad.#{$fa-css-prefix}-film-simple:after, .fa-duotone.#{$fa-css-prefix}-film-simple:after { content: fa-content(\f3a0\fe02); }
.fad.#{$fa-css-prefix}-film-alt:before, .fa-duotone.#{$fa-css-prefix}-film-alt:before { content: fa-content(\f3a0\fe01); }
.fad.#{$fa-css-prefix}-film-alt:after, .fa-duotone.#{$fa-css-prefix}-film-alt:after { content: fa-content(\f3a0\fe02); }
.fad.#{$fa-css-prefix}-film-slash:before, .fa-duotone.#{$fa-css-prefix}-film-slash:before { content: fa-content(\e179\fe01); }
.fad.#{$fa-css-prefix}-film-slash:after, .fa-duotone.#{$fa-css-prefix}-film-slash:after { content: fa-content(\e179\fe02); }
.fad.#{$fa-css-prefix}-films:before, .fa-duotone.#{$fa-css-prefix}-films:before { content: fa-content(\e17a\fe01); }
.fad.#{$fa-css-prefix}-films:after, .fa-duotone.#{$fa-css-prefix}-films:after { content: fa-content(\e17a\fe02); }
.fad.#{$fa-css-prefix}-filter:before, .fa-duotone.#{$fa-css-prefix}-filter:before { content: fa-content(\f0b0\fe01); }
.fad.#{$fa-css-prefix}-filter:after, .fa-duotone.#{$fa-css-prefix}-filter:after { content: fa-content(\f0b0\fe02); }
.fad.#{$fa-css-prefix}-filter-circle-dollar:before, .fa-duotone.#{$fa-css-prefix}-filter-circle-dollar:before { content: fa-content(\f662\fe01); }
.fad.#{$fa-css-prefix}-filter-circle-dollar:after, .fa-duotone.#{$fa-css-prefix}-filter-circle-dollar:after { content: fa-content(\f662\fe02); }
.fad.#{$fa-css-prefix}-funnel-dollar:before, .fa-duotone.#{$fa-css-prefix}-funnel-dollar:before { content: fa-content(\f662\fe01); }
.fad.#{$fa-css-prefix}-funnel-dollar:after, .fa-duotone.#{$fa-css-prefix}-funnel-dollar:after { content: fa-content(\f662\fe02); }
.fad.#{$fa-css-prefix}-filter-circle-xmark:before, .fa-duotone.#{$fa-css-prefix}-filter-circle-xmark:before { content: fa-content(\e17b\fe01); }
.fad.#{$fa-css-prefix}-filter-circle-xmark:after, .fa-duotone.#{$fa-css-prefix}-filter-circle-xmark:after { content: fa-content(\e17b\fe02); }
.fad.#{$fa-css-prefix}-filter-list:before, .fa-duotone.#{$fa-css-prefix}-filter-list:before { content: fa-content(\e17c\fe01); }
.fad.#{$fa-css-prefix}-filter-list:after, .fa-duotone.#{$fa-css-prefix}-filter-list:after { content: fa-content(\e17c\fe02); }
.fad.#{$fa-css-prefix}-filter-slash:before, .fa-duotone.#{$fa-css-prefix}-filter-slash:before { content: fa-content(\e17d\fe01); }
.fad.#{$fa-css-prefix}-filter-slash:after, .fa-duotone.#{$fa-css-prefix}-filter-slash:after { content: fa-content(\e17d\fe02); }
.fad.#{$fa-css-prefix}-filters:before, .fa-duotone.#{$fa-css-prefix}-filters:before { content: fa-content(\e17e\fe01); }
.fad.#{$fa-css-prefix}-filters:after, .fa-duotone.#{$fa-css-prefix}-filters:after { content: fa-content(\e17e\fe02); }
.fad.#{$fa-css-prefix}-fingerprint:before, .fa-duotone.#{$fa-css-prefix}-fingerprint:before { content: fa-content(\f577\fe01); }
.fad.#{$fa-css-prefix}-fingerprint:after, .fa-duotone.#{$fa-css-prefix}-fingerprint:after { content: fa-content(\f577\fe02); }
.fad.#{$fa-css-prefix}-fire:before, .fa-duotone.#{$fa-css-prefix}-fire:before { content: fa-content(\f06d); }
.fad.#{$fa-css-prefix}-fire:after, .fa-duotone.#{$fa-css-prefix}-fire:after { content: fa-content(\10f06d); }
.fad.#{$fa-css-prefix}-fire-extinguisher:before, .fa-duotone.#{$fa-css-prefix}-fire-extinguisher:before { content: fa-content(\f134); }
.fad.#{$fa-css-prefix}-fire-extinguisher:after, .fa-duotone.#{$fa-css-prefix}-fire-extinguisher:after { content: fa-content(\10f134); }
.fad.#{$fa-css-prefix}-fire-flame:before, .fa-duotone.#{$fa-css-prefix}-fire-flame:before { content: fa-content(\f6df\fe01); }
.fad.#{$fa-css-prefix}-fire-flame:after, .fa-duotone.#{$fa-css-prefix}-fire-flame:after { content: fa-content(\f6df\fe02); }
.fad.#{$fa-css-prefix}-flame:before, .fa-duotone.#{$fa-css-prefix}-flame:before { content: fa-content(\f6df\fe01); }
.fad.#{$fa-css-prefix}-flame:after, .fa-duotone.#{$fa-css-prefix}-flame:after { content: fa-content(\f6df\fe02); }
.fad.#{$fa-css-prefix}-fire-flame-curved:before, .fa-duotone.#{$fa-css-prefix}-fire-flame-curved:before { content: fa-content(\f7e4\fe01); }
.fad.#{$fa-css-prefix}-fire-flame-curved:after, .fa-duotone.#{$fa-css-prefix}-fire-flame-curved:after { content: fa-content(\f7e4\fe02); }
.fad.#{$fa-css-prefix}-fire-alt:before, .fa-duotone.#{$fa-css-prefix}-fire-alt:before { content: fa-content(\f7e4\fe01); }
.fad.#{$fa-css-prefix}-fire-alt:after, .fa-duotone.#{$fa-css-prefix}-fire-alt:after { content: fa-content(\f7e4\fe02); }
.fad.#{$fa-css-prefix}-fire-flame-simple:before, .fa-duotone.#{$fa-css-prefix}-fire-flame-simple:before { content: fa-content(\f46a\fe01); }
.fad.#{$fa-css-prefix}-fire-flame-simple:after, .fa-duotone.#{$fa-css-prefix}-fire-flame-simple:after { content: fa-content(\f46a\fe02); }
.fad.#{$fa-css-prefix}-burn:before, .fa-duotone.#{$fa-css-prefix}-burn:before { content: fa-content(\f46a\fe01); }
.fad.#{$fa-css-prefix}-burn:after, .fa-duotone.#{$fa-css-prefix}-burn:after { content: fa-content(\f46a\fe02); }
.fad.#{$fa-css-prefix}-fire-hydrant:before, .fa-duotone.#{$fa-css-prefix}-fire-hydrant:before { content: fa-content(\e17f\fe01); }
.fad.#{$fa-css-prefix}-fire-hydrant:after, .fa-duotone.#{$fa-css-prefix}-fire-hydrant:after { content: fa-content(\e17f\fe02); }
.fad.#{$fa-css-prefix}-fire-smoke:before, .fa-duotone.#{$fa-css-prefix}-fire-smoke:before { content: fa-content(\f74b\fe01); }
.fad.#{$fa-css-prefix}-fire-smoke:after, .fa-duotone.#{$fa-css-prefix}-fire-smoke:after { content: fa-content(\f74b\fe02); }
.fad.#{$fa-css-prefix}-fireplace:before, .fa-duotone.#{$fa-css-prefix}-fireplace:before { content: fa-content(\f79a\fe01); }
.fad.#{$fa-css-prefix}-fireplace:after, .fa-duotone.#{$fa-css-prefix}-fireplace:after { content: fa-content(\f79a\fe02); }
.fad.#{$fa-css-prefix}-fish:before, .fa-duotone.#{$fa-css-prefix}-fish:before { content: fa-content(\f578); }
.fad.#{$fa-css-prefix}-fish:after, .fa-duotone.#{$fa-css-prefix}-fish:after { content: fa-content(\10f578); }
.fad.#{$fa-css-prefix}-fish-bones:before, .fa-duotone.#{$fa-css-prefix}-fish-bones:before { content: fa-content(\e304\fe01); }
.fad.#{$fa-css-prefix}-fish-bones:after, .fa-duotone.#{$fa-css-prefix}-fish-bones:after { content: fa-content(\e304\fe02); }
.fad.#{$fa-css-prefix}-fish-cooked:before, .fa-duotone.#{$fa-css-prefix}-fish-cooked:before { content: fa-content(\f7fe\fe01); }
.fad.#{$fa-css-prefix}-fish-cooked:after, .fa-duotone.#{$fa-css-prefix}-fish-cooked:after { content: fa-content(\f7fe\fe02); }
.fad.#{$fa-css-prefix}-fishing-rod:before, .fa-duotone.#{$fa-css-prefix}-fishing-rod:before { content: fa-content(\e3a8\fe01); }
.fad.#{$fa-css-prefix}-fishing-rod:after, .fa-duotone.#{$fa-css-prefix}-fishing-rod:after { content: fa-content(\e3a8\fe02); }
.fad.#{$fa-css-prefix}-flag:before, .fa-duotone.#{$fa-css-prefix}-flag:before { content: fa-content(\f024); }
.fad.#{$fa-css-prefix}-flag:after, .fa-duotone.#{$fa-css-prefix}-flag:after { content: fa-content(\10f024); }
.fad.#{$fa-css-prefix}-flag-checkered:before, .fa-duotone.#{$fa-css-prefix}-flag-checkered:before { content: fa-content(\f11e); }
.fad.#{$fa-css-prefix}-flag-checkered:after, .fa-duotone.#{$fa-css-prefix}-flag-checkered:after { content: fa-content(\10f11e); }
.fad.#{$fa-css-prefix}-flag-pennant:before, .fa-duotone.#{$fa-css-prefix}-flag-pennant:before { content: fa-content(\f456); }
.fad.#{$fa-css-prefix}-flag-pennant:after, .fa-duotone.#{$fa-css-prefix}-flag-pennant:after { content: fa-content(\10f456); }
.fad.#{$fa-css-prefix}-pennant:before, .fa-duotone.#{$fa-css-prefix}-pennant:before { content: fa-content(\f456); }
.fad.#{$fa-css-prefix}-pennant:after, .fa-duotone.#{$fa-css-prefix}-pennant:after { content: fa-content(\10f456); }
.fad.#{$fa-css-prefix}-flag-swallowtail:before, .fa-duotone.#{$fa-css-prefix}-flag-swallowtail:before { content: fa-content(\f74c\fe01); }
.fad.#{$fa-css-prefix}-flag-swallowtail:after, .fa-duotone.#{$fa-css-prefix}-flag-swallowtail:after { content: fa-content(\f74c\fe02); }
.fad.#{$fa-css-prefix}-flag-alt:before, .fa-duotone.#{$fa-css-prefix}-flag-alt:before { content: fa-content(\f74c\fe01); }
.fad.#{$fa-css-prefix}-flag-alt:after, .fa-duotone.#{$fa-css-prefix}-flag-alt:after { content: fa-content(\f74c\fe02); }
.fad.#{$fa-css-prefix}-flag-usa:before, .fa-duotone.#{$fa-css-prefix}-flag-usa:before { content: fa-content(\f74d\fe01); }
.fad.#{$fa-css-prefix}-flag-usa:after, .fa-duotone.#{$fa-css-prefix}-flag-usa:after { content: fa-content(\f74d\fe02); }
.fad.#{$fa-css-prefix}-flashlight:before, .fa-duotone.#{$fa-css-prefix}-flashlight:before { content: fa-content(\f8b8); }
.fad.#{$fa-css-prefix}-flashlight:after, .fa-duotone.#{$fa-css-prefix}-flashlight:after { content: fa-content(\10f8b8); }
.fad.#{$fa-css-prefix}-flask:before, .fa-duotone.#{$fa-css-prefix}-flask:before { content: fa-content(\f0c3\fe01); }
.fad.#{$fa-css-prefix}-flask:after, .fa-duotone.#{$fa-css-prefix}-flask:after { content: fa-content(\f0c3\fe02); }
.fad.#{$fa-css-prefix}-flask-round-poison:before, .fa-duotone.#{$fa-css-prefix}-flask-round-poison:before { content: fa-content(\f6e0\fe01); }
.fad.#{$fa-css-prefix}-flask-round-poison:after, .fa-duotone.#{$fa-css-prefix}-flask-round-poison:after { content: fa-content(\f6e0\fe02); }
.fad.#{$fa-css-prefix}-flask-poison:before, .fa-duotone.#{$fa-css-prefix}-flask-poison:before { content: fa-content(\f6e0\fe01); }
.fad.#{$fa-css-prefix}-flask-poison:after, .fa-duotone.#{$fa-css-prefix}-flask-poison:after { content: fa-content(\f6e0\fe02); }
.fad.#{$fa-css-prefix}-flask-round-potion:before, .fa-duotone.#{$fa-css-prefix}-flask-round-potion:before { content: fa-content(\f6e1\fe01); }
.fad.#{$fa-css-prefix}-flask-round-potion:after, .fa-duotone.#{$fa-css-prefix}-flask-round-potion:after { content: fa-content(\f6e1\fe02); }
.fad.#{$fa-css-prefix}-flask-potion:before, .fa-duotone.#{$fa-css-prefix}-flask-potion:before { content: fa-content(\f6e1\fe01); }
.fad.#{$fa-css-prefix}-flask-potion:after, .fa-duotone.#{$fa-css-prefix}-flask-potion:after { content: fa-content(\f6e1\fe02); }
.fad.#{$fa-css-prefix}-floppy-disk:before, .fa-duotone.#{$fa-css-prefix}-floppy-disk:before { content: fa-content(\f0c7); }
.fad.#{$fa-css-prefix}-floppy-disk:after, .fa-duotone.#{$fa-css-prefix}-floppy-disk:after { content: fa-content(\10f0c7); }
.fad.#{$fa-css-prefix}-save:before, .fa-duotone.#{$fa-css-prefix}-save:before { content: fa-content(\f0c7); }
.fad.#{$fa-css-prefix}-save:after, .fa-duotone.#{$fa-css-prefix}-save:after { content: fa-content(\10f0c7); }
.fad.#{$fa-css-prefix}-floppy-disk-circle-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-floppy-disk-circle-arrow-right:before { content: fa-content(\e180\fe01); }
.fad.#{$fa-css-prefix}-floppy-disk-circle-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-circle-arrow-right:after { content: fa-content(\e180\fe02); }
.fad.#{$fa-css-prefix}-save-circle-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-save-circle-arrow-right:before { content: fa-content(\e180\fe01); }
.fad.#{$fa-css-prefix}-save-circle-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-save-circle-arrow-right:after { content: fa-content(\e180\fe02); }
.fad.#{$fa-css-prefix}-floppy-disk-circle-xmark:before, .fa-duotone.#{$fa-css-prefix}-floppy-disk-circle-xmark:before { content: fa-content(\e181\fe01); }
.fad.#{$fa-css-prefix}-floppy-disk-circle-xmark:after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-circle-xmark:after { content: fa-content(\e181\fe02); }
.fad.#{$fa-css-prefix}-floppy-disk-times:before, .fa-duotone.#{$fa-css-prefix}-floppy-disk-times:before { content: fa-content(\e181\fe01); }
.fad.#{$fa-css-prefix}-floppy-disk-times:after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-times:after { content: fa-content(\e181\fe02); }
.fad.#{$fa-css-prefix}-save-circle-xmark:before, .fa-duotone.#{$fa-css-prefix}-save-circle-xmark:before { content: fa-content(\e181\fe01); }
.fad.#{$fa-css-prefix}-save-circle-xmark:after, .fa-duotone.#{$fa-css-prefix}-save-circle-xmark:after { content: fa-content(\e181\fe02); }
.fad.#{$fa-css-prefix}-save-times:before, .fa-duotone.#{$fa-css-prefix}-save-times:before { content: fa-content(\e181\fe01); }
.fad.#{$fa-css-prefix}-save-times:after, .fa-duotone.#{$fa-css-prefix}-save-times:after { content: fa-content(\e181\fe02); }
.fad.#{$fa-css-prefix}-floppy-disk-pen:before, .fa-duotone.#{$fa-css-prefix}-floppy-disk-pen:before { content: fa-content(\e182\fe01); }
.fad.#{$fa-css-prefix}-floppy-disk-pen:after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-pen:after { content: fa-content(\e182\fe02); }
.fad.#{$fa-css-prefix}-floppy-disks:before, .fa-duotone.#{$fa-css-prefix}-floppy-disks:before { content: fa-content(\e183\fe01); }
.fad.#{$fa-css-prefix}-floppy-disks:after, .fa-duotone.#{$fa-css-prefix}-floppy-disks:after { content: fa-content(\e183\fe02); }
.fad.#{$fa-css-prefix}-florin-sign:before, .fa-duotone.#{$fa-css-prefix}-florin-sign:before { content: fa-content(\e184\fe01); }
.fad.#{$fa-css-prefix}-florin-sign:after, .fa-duotone.#{$fa-css-prefix}-florin-sign:after { content: fa-content(\e184\fe02); }
.fad.#{$fa-css-prefix}-flower:before, .fa-duotone.#{$fa-css-prefix}-flower:before { content: fa-content(\273f\fe01); }
.fad.#{$fa-css-prefix}-flower:after, .fa-duotone.#{$fa-css-prefix}-flower:after { content: fa-content(\273f\fe02); }
.fad.#{$fa-css-prefix}-flower-daffodil:before, .fa-duotone.#{$fa-css-prefix}-flower-daffodil:before { content: fa-content(\2698\fe01); }
.fad.#{$fa-css-prefix}-flower-daffodil:after, .fa-duotone.#{$fa-css-prefix}-flower-daffodil:after { content: fa-content(\2698\fe02); }
.fad.#{$fa-css-prefix}-flower-tulip:before, .fa-duotone.#{$fa-css-prefix}-flower-tulip:before { content: fa-content(\f801); }
.fad.#{$fa-css-prefix}-flower-tulip:after, .fa-duotone.#{$fa-css-prefix}-flower-tulip:after { content: fa-content(\10f801); }
.fad.#{$fa-css-prefix}-flute:before, .fa-duotone.#{$fa-css-prefix}-flute:before { content: fa-content(\f8b9\fe01); }
.fad.#{$fa-css-prefix}-flute:after, .fa-duotone.#{$fa-css-prefix}-flute:after { content: fa-content(\f8b9\fe02); }
.fad.#{$fa-css-prefix}-flux-capacitor:before, .fa-duotone.#{$fa-css-prefix}-flux-capacitor:before { content: fa-content(\f8ba\fe01); }
.fad.#{$fa-css-prefix}-flux-capacitor:after, .fa-duotone.#{$fa-css-prefix}-flux-capacitor:after { content: fa-content(\f8ba\fe02); }
.fad.#{$fa-css-prefix}-flying-disc:before, .fa-duotone.#{$fa-css-prefix}-flying-disc:before { content: fa-content(\e3a9\fe01); }
.fad.#{$fa-css-prefix}-flying-disc:after, .fa-duotone.#{$fa-css-prefix}-flying-disc:after { content: fa-content(\e3a9\fe02); }
.fad.#{$fa-css-prefix}-folder:before, .fa-duotone.#{$fa-css-prefix}-folder:before { content: fa-content(\f07b); }
.fad.#{$fa-css-prefix}-folder:after, .fa-duotone.#{$fa-css-prefix}-folder:after { content: fa-content(\10f07b); }
.fad.#{$fa-css-prefix}-folder-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-folder-arrow-down:before { content: fa-content(\e053\fe01); }
.fad.#{$fa-css-prefix}-folder-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-folder-arrow-down:after { content: fa-content(\e053\fe02); }
.fad.#{$fa-css-prefix}-folder-download:before, .fa-duotone.#{$fa-css-prefix}-folder-download:before { content: fa-content(\e053\fe01); }
.fad.#{$fa-css-prefix}-folder-download:after, .fa-duotone.#{$fa-css-prefix}-folder-download:after { content: fa-content(\e053\fe02); }
.fad.#{$fa-css-prefix}-folder-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-folder-arrow-up:before { content: fa-content(\e054\fe01); }
.fad.#{$fa-css-prefix}-folder-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-folder-arrow-up:after { content: fa-content(\e054\fe02); }
.fad.#{$fa-css-prefix}-folder-upload:before, .fa-duotone.#{$fa-css-prefix}-folder-upload:before { content: fa-content(\e054\fe01); }
.fad.#{$fa-css-prefix}-folder-upload:after, .fa-duotone.#{$fa-css-prefix}-folder-upload:after { content: fa-content(\e054\fe02); }
.fad.#{$fa-css-prefix}-folder-blank:before, .fa-duotone.#{$fa-css-prefix}-folder-blank:before { content: fa-content(\e185\fe01); }
.fad.#{$fa-css-prefix}-folder-blank:after, .fa-duotone.#{$fa-css-prefix}-folder-blank:after { content: fa-content(\e185\fe02); }
.fad.#{$fa-css-prefix}-folder-bookmark:before, .fa-duotone.#{$fa-css-prefix}-folder-bookmark:before { content: fa-content(\e186\fe01); }
.fad.#{$fa-css-prefix}-folder-bookmark:after, .fa-duotone.#{$fa-css-prefix}-folder-bookmark:after { content: fa-content(\e186\fe02); }
.fad.#{$fa-css-prefix}-folder-gear:before, .fa-duotone.#{$fa-css-prefix}-folder-gear:before { content: fa-content(\e187\fe01); }
.fad.#{$fa-css-prefix}-folder-gear:after, .fa-duotone.#{$fa-css-prefix}-folder-gear:after { content: fa-content(\e187\fe02); }
.fad.#{$fa-css-prefix}-folder-cog:before, .fa-duotone.#{$fa-css-prefix}-folder-cog:before { content: fa-content(\e187\fe01); }
.fad.#{$fa-css-prefix}-folder-cog:after, .fa-duotone.#{$fa-css-prefix}-folder-cog:after { content: fa-content(\e187\fe02); }
.fad.#{$fa-css-prefix}-folder-grid:before, .fa-duotone.#{$fa-css-prefix}-folder-grid:before { content: fa-content(\e188\fe01); }
.fad.#{$fa-css-prefix}-folder-grid:after, .fa-duotone.#{$fa-css-prefix}-folder-grid:after { content: fa-content(\e188\fe02); }
.fad.#{$fa-css-prefix}-folder-heart:before, .fa-duotone.#{$fa-css-prefix}-folder-heart:before { content: fa-content(\e189\fe01); }
.fad.#{$fa-css-prefix}-folder-heart:after, .fa-duotone.#{$fa-css-prefix}-folder-heart:after { content: fa-content(\e189\fe02); }
.fad.#{$fa-css-prefix}-folder-image:before, .fa-duotone.#{$fa-css-prefix}-folder-image:before { content: fa-content(\e18a\fe01); }
.fad.#{$fa-css-prefix}-folder-image:after, .fa-duotone.#{$fa-css-prefix}-folder-image:after { content: fa-content(\e18a\fe02); }
.fad.#{$fa-css-prefix}-folder-magnifying-glass:before, .fa-duotone.#{$fa-css-prefix}-folder-magnifying-glass:before { content: fa-content(\e18b\fe01); }
.fad.#{$fa-css-prefix}-folder-magnifying-glass:after, .fa-duotone.#{$fa-css-prefix}-folder-magnifying-glass:after { content: fa-content(\e18b\fe02); }
.fad.#{$fa-css-prefix}-folder-search:before, .fa-duotone.#{$fa-css-prefix}-folder-search:before { content: fa-content(\e18b\fe01); }
.fad.#{$fa-css-prefix}-folder-search:after, .fa-duotone.#{$fa-css-prefix}-folder-search:after { content: fa-content(\e18b\fe02); }
.fad.#{$fa-css-prefix}-folder-medical:before, .fa-duotone.#{$fa-css-prefix}-folder-medical:before { content: fa-content(\e18c\fe01); }
.fad.#{$fa-css-prefix}-folder-medical:after, .fa-duotone.#{$fa-css-prefix}-folder-medical:after { content: fa-content(\e18c\fe02); }
.fad.#{$fa-css-prefix}-folder-minus:before, .fa-duotone.#{$fa-css-prefix}-folder-minus:before { content: fa-content(\f65d\fe01); }
.fad.#{$fa-css-prefix}-folder-minus:after, .fa-duotone.#{$fa-css-prefix}-folder-minus:after { content: fa-content(\f65d\fe02); }
.fad.#{$fa-css-prefix}-folder-music:before, .fa-duotone.#{$fa-css-prefix}-folder-music:before { content: fa-content(\e18d\fe01); }
.fad.#{$fa-css-prefix}-folder-music:after, .fa-duotone.#{$fa-css-prefix}-folder-music:after { content: fa-content(\e18d\fe02); }
.fad.#{$fa-css-prefix}-folder-open:before, .fa-duotone.#{$fa-css-prefix}-folder-open:before { content: fa-content(\f07c); }
.fad.#{$fa-css-prefix}-folder-open:after, .fa-duotone.#{$fa-css-prefix}-folder-open:after { content: fa-content(\10f07c); }
.fad.#{$fa-css-prefix}-folder-plus:before, .fa-duotone.#{$fa-css-prefix}-folder-plus:before { content: fa-content(\f65e\fe01); }
.fad.#{$fa-css-prefix}-folder-plus:after, .fa-duotone.#{$fa-css-prefix}-folder-plus:after { content: fa-content(\f65e\fe02); }
.fad.#{$fa-css-prefix}-folder-tree:before, .fa-duotone.#{$fa-css-prefix}-folder-tree:before { content: fa-content(\f802\fe01); }
.fad.#{$fa-css-prefix}-folder-tree:after, .fa-duotone.#{$fa-css-prefix}-folder-tree:after { content: fa-content(\f802\fe02); }
.fad.#{$fa-css-prefix}-folder-user:before, .fa-duotone.#{$fa-css-prefix}-folder-user:before { content: fa-content(\e18e\fe01); }
.fad.#{$fa-css-prefix}-folder-user:after, .fa-duotone.#{$fa-css-prefix}-folder-user:after { content: fa-content(\e18e\fe02); }
.fad.#{$fa-css-prefix}-folder-xmark:before, .fa-duotone.#{$fa-css-prefix}-folder-xmark:before { content: fa-content(\f65f\fe01); }
.fad.#{$fa-css-prefix}-folder-xmark:after, .fa-duotone.#{$fa-css-prefix}-folder-xmark:after { content: fa-content(\f65f\fe02); }
.fad.#{$fa-css-prefix}-folder-times:before, .fa-duotone.#{$fa-css-prefix}-folder-times:before { content: fa-content(\f65f\fe01); }
.fad.#{$fa-css-prefix}-folder-times:after, .fa-duotone.#{$fa-css-prefix}-folder-times:after { content: fa-content(\f65f\fe02); }
.fad.#{$fa-css-prefix}-folders:before, .fa-duotone.#{$fa-css-prefix}-folders:before { content: fa-content(\f660\fe01); }
.fad.#{$fa-css-prefix}-folders:after, .fa-duotone.#{$fa-css-prefix}-folders:after { content: fa-content(\f660\fe02); }
.fad.#{$fa-css-prefix}-font:before, .fa-duotone.#{$fa-css-prefix}-font:before { content: fa-content(\f031\fe01); }
.fad.#{$fa-css-prefix}-font:after, .fa-duotone.#{$fa-css-prefix}-font:after { content: fa-content(\f031\fe02); }
.fad.#{$fa-css-prefix}-font-awesome:before, .fa-duotone.#{$fa-css-prefix}-font-awesome:before { content: fa-content(\f2b4\fe01); }
.fad.#{$fa-css-prefix}-font-awesome:after, .fa-duotone.#{$fa-css-prefix}-font-awesome:after { content: fa-content(\f2b4\fe02); }
.fad.#{$fa-css-prefix}-font-awesome-flag:before, .fa-duotone.#{$fa-css-prefix}-font-awesome-flag:before { content: fa-content(\f2b4\fe01); }
.fad.#{$fa-css-prefix}-font-awesome-flag:after, .fa-duotone.#{$fa-css-prefix}-font-awesome-flag:after { content: fa-content(\f2b4\fe02); }
.fad.#{$fa-css-prefix}-font-awesome-logo-full:before, .fa-duotone.#{$fa-css-prefix}-font-awesome-logo-full:before { content: fa-content(\f2b4\fe01); }
.fad.#{$fa-css-prefix}-font-awesome-logo-full:after, .fa-duotone.#{$fa-css-prefix}-font-awesome-logo-full:after { content: fa-content(\f2b4\fe02); }
.fad.#{$fa-css-prefix}-font-case:before, .fa-duotone.#{$fa-css-prefix}-font-case:before { content: fa-content(\f866\fe01); }
.fad.#{$fa-css-prefix}-font-case:after, .fa-duotone.#{$fa-css-prefix}-font-case:after { content: fa-content(\f866\fe02); }
.fad.#{$fa-css-prefix}-football:before, .fa-duotone.#{$fa-css-prefix}-football:before { content: fa-content(\f44e); }
.fad.#{$fa-css-prefix}-football:after, .fa-duotone.#{$fa-css-prefix}-football:after { content: fa-content(\10f44e); }
.fad.#{$fa-css-prefix}-football-ball:before, .fa-duotone.#{$fa-css-prefix}-football-ball:before { content: fa-content(\f44e); }
.fad.#{$fa-css-prefix}-football-ball:after, .fa-duotone.#{$fa-css-prefix}-football-ball:after { content: fa-content(\10f44e); }
.fad.#{$fa-css-prefix}-football-helmet:before, .fa-duotone.#{$fa-css-prefix}-football-helmet:before { content: fa-content(\f44f\fe01); }
.fad.#{$fa-css-prefix}-football-helmet:after, .fa-duotone.#{$fa-css-prefix}-football-helmet:after { content: fa-content(\f44f\fe02); }
.fad.#{$fa-css-prefix}-fork:before, .fa-duotone.#{$fa-css-prefix}-fork:before { content: fa-content(\f2e3\fe01); }
.fad.#{$fa-css-prefix}-fork:after, .fa-duotone.#{$fa-css-prefix}-fork:after { content: fa-content(\f2e3\fe02); }
.fad.#{$fa-css-prefix}-utensil-fork:before, .fa-duotone.#{$fa-css-prefix}-utensil-fork:before { content: fa-content(\f2e3\fe01); }
.fad.#{$fa-css-prefix}-utensil-fork:after, .fa-duotone.#{$fa-css-prefix}-utensil-fork:after { content: fa-content(\f2e3\fe02); }
.fad.#{$fa-css-prefix}-fork-knife:before, .fa-duotone.#{$fa-css-prefix}-fork-knife:before { content: fa-content(\f2e6\fe01); }
.fad.#{$fa-css-prefix}-fork-knife:after, .fa-duotone.#{$fa-css-prefix}-fork-knife:after { content: fa-content(\f2e6\fe02); }
.fad.#{$fa-css-prefix}-utensils-alt:before, .fa-duotone.#{$fa-css-prefix}-utensils-alt:before { content: fa-content(\f2e6\fe01); }
.fad.#{$fa-css-prefix}-utensils-alt:after, .fa-duotone.#{$fa-css-prefix}-utensils-alt:after { content: fa-content(\f2e6\fe02); }
.fad.#{$fa-css-prefix}-forklift:before, .fa-duotone.#{$fa-css-prefix}-forklift:before { content: fa-content(\f47a\fe01); }
.fad.#{$fa-css-prefix}-forklift:after, .fa-duotone.#{$fa-css-prefix}-forklift:after { content: fa-content(\f47a\fe02); }
.fad.#{$fa-css-prefix}-forward:before, .fa-duotone.#{$fa-css-prefix}-forward:before { content: fa-content(\23e9\fe01); }
.fad.#{$fa-css-prefix}-forward:after, .fa-duotone.#{$fa-css-prefix}-forward:after { content: fa-content(\23e9\fe02); }
.fad.#{$fa-css-prefix}-forward-fast:before, .fa-duotone.#{$fa-css-prefix}-forward-fast:before { content: fa-content(\23ed\fe01); }
.fad.#{$fa-css-prefix}-forward-fast:after, .fa-duotone.#{$fa-css-prefix}-forward-fast:after { content: fa-content(\23ed\fe02); }
.fad.#{$fa-css-prefix}-fast-forward:before, .fa-duotone.#{$fa-css-prefix}-fast-forward:before { content: fa-content(\23ed\fe01); }
.fad.#{$fa-css-prefix}-fast-forward:after, .fa-duotone.#{$fa-css-prefix}-fast-forward:after { content: fa-content(\23ed\fe02); }
.fad.#{$fa-css-prefix}-forward-step:before, .fa-duotone.#{$fa-css-prefix}-forward-step:before { content: fa-content(\f051\fe01); }
.fad.#{$fa-css-prefix}-forward-step:after, .fa-duotone.#{$fa-css-prefix}-forward-step:after { content: fa-content(\f051\fe02); }
.fad.#{$fa-css-prefix}-step-forward:before, .fa-duotone.#{$fa-css-prefix}-step-forward:before { content: fa-content(\f051\fe01); }
.fad.#{$fa-css-prefix}-step-forward:after, .fa-duotone.#{$fa-css-prefix}-step-forward:after { content: fa-content(\f051\fe02); }
.fad.#{$fa-css-prefix}-franc-sign:before, .fa-duotone.#{$fa-css-prefix}-franc-sign:before { content: fa-content(\e18f\fe01); }
.fad.#{$fa-css-prefix}-franc-sign:after, .fa-duotone.#{$fa-css-prefix}-franc-sign:after { content: fa-content(\e18f\fe02); }
.fad.#{$fa-css-prefix}-french-fries:before, .fa-duotone.#{$fa-css-prefix}-french-fries:before { content: fa-content(\f803); }
.fad.#{$fa-css-prefix}-french-fries:after, .fa-duotone.#{$fa-css-prefix}-french-fries:after { content: fa-content(\10f803); }
.fad.#{$fa-css-prefix}-frog:before, .fa-duotone.#{$fa-css-prefix}-frog:before { content: fa-content(\f52e\fe01); }
.fad.#{$fa-css-prefix}-frog:after, .fa-duotone.#{$fa-css-prefix}-frog:after { content: fa-content(\f52e\fe02); }
.fad.#{$fa-css-prefix}-function:before, .fa-duotone.#{$fa-css-prefix}-function:before { content: fa-content(\f661\fe01); }
.fad.#{$fa-css-prefix}-function:after, .fa-duotone.#{$fa-css-prefix}-function:after { content: fa-content(\f661\fe02); }
.fad.#{$fa-css-prefix}-futbol:before, .fa-duotone.#{$fa-css-prefix}-futbol:before { content: fa-content(\26bd\fe01); }
.fad.#{$fa-css-prefix}-futbol:after, .fa-duotone.#{$fa-css-prefix}-futbol:after { content: fa-content(\26bd\fe02); }
.fad.#{$fa-css-prefix}-futbol-ball:before, .fa-duotone.#{$fa-css-prefix}-futbol-ball:before { content: fa-content(\26bd\fe01); }
.fad.#{$fa-css-prefix}-futbol-ball:after, .fa-duotone.#{$fa-css-prefix}-futbol-ball:after { content: fa-content(\26bd\fe02); }
.fad.#{$fa-css-prefix}-soccer-ball:before, .fa-duotone.#{$fa-css-prefix}-soccer-ball:before { content: fa-content(\26bd\fe01); }
.fad.#{$fa-css-prefix}-soccer-ball:after, .fa-duotone.#{$fa-css-prefix}-soccer-ball:after { content: fa-content(\26bd\fe02); }
.fad.#{$fa-css-prefix}-g:before, .fa-duotone.#{$fa-css-prefix}-g:before { content: fa-content(\47\fe01); }
.fad.#{$fa-css-prefix}-g:after, .fa-duotone.#{$fa-css-prefix}-g:after { content: fa-content(\47\fe02); }
.fad.#{$fa-css-prefix}-galaxy:before, .fa-duotone.#{$fa-css-prefix}-galaxy:before { content: fa-content(\e008\fe01); }
.fad.#{$fa-css-prefix}-galaxy:after, .fa-duotone.#{$fa-css-prefix}-galaxy:after { content: fa-content(\e008\fe02); }
.fad.#{$fa-css-prefix}-gallery-thumbnails:before, .fa-duotone.#{$fa-css-prefix}-gallery-thumbnails:before { content: fa-content(\e3aa\fe01); }
.fad.#{$fa-css-prefix}-gallery-thumbnails:after, .fa-duotone.#{$fa-css-prefix}-gallery-thumbnails:after { content: fa-content(\e3aa\fe02); }
.fad.#{$fa-css-prefix}-game-board:before, .fa-duotone.#{$fa-css-prefix}-game-board:before { content: fa-content(\f867\fe01); }
.fad.#{$fa-css-prefix}-game-board:after, .fa-duotone.#{$fa-css-prefix}-game-board:after { content: fa-content(\f867\fe02); }
.fad.#{$fa-css-prefix}-game-board-simple:before, .fa-duotone.#{$fa-css-prefix}-game-board-simple:before { content: fa-content(\f868\fe01); }
.fad.#{$fa-css-prefix}-game-board-simple:after, .fa-duotone.#{$fa-css-prefix}-game-board-simple:after { content: fa-content(\f868\fe02); }
.fad.#{$fa-css-prefix}-game-board-alt:before, .fa-duotone.#{$fa-css-prefix}-game-board-alt:before { content: fa-content(\f868\fe01); }
.fad.#{$fa-css-prefix}-game-board-alt:after, .fa-duotone.#{$fa-css-prefix}-game-board-alt:after { content: fa-content(\f868\fe02); }
.fad.#{$fa-css-prefix}-game-console-handheld:before, .fa-duotone.#{$fa-css-prefix}-game-console-handheld:before { content: fa-content(\f8bb\fe01); }
.fad.#{$fa-css-prefix}-game-console-handheld:after, .fa-duotone.#{$fa-css-prefix}-game-console-handheld:after { content: fa-content(\f8bb\fe02); }
.fad.#{$fa-css-prefix}-gamepad:before, .fa-duotone.#{$fa-css-prefix}-gamepad:before { content: fa-content(\f11b\fe01); }
.fad.#{$fa-css-prefix}-gamepad:after, .fa-duotone.#{$fa-css-prefix}-gamepad:after { content: fa-content(\f11b\fe02); }
.fad.#{$fa-css-prefix}-gamepad-modern:before, .fa-duotone.#{$fa-css-prefix}-gamepad-modern:before { content: fa-content(\f8bc); }
.fad.#{$fa-css-prefix}-gamepad-modern:after, .fa-duotone.#{$fa-css-prefix}-gamepad-modern:after { content: fa-content(\10f8bc); }
.fad.#{$fa-css-prefix}-gamepad-alt:before, .fa-duotone.#{$fa-css-prefix}-gamepad-alt:before { content: fa-content(\f8bc); }
.fad.#{$fa-css-prefix}-gamepad-alt:after, .fa-duotone.#{$fa-css-prefix}-gamepad-alt:after { content: fa-content(\10f8bc); }
.fad.#{$fa-css-prefix}-garage:before, .fa-duotone.#{$fa-css-prefix}-garage:before { content: fa-content(\e009\fe01); }
.fad.#{$fa-css-prefix}-garage:after, .fa-duotone.#{$fa-css-prefix}-garage:after { content: fa-content(\e009\fe02); }
.fad.#{$fa-css-prefix}-garage-car:before, .fa-duotone.#{$fa-css-prefix}-garage-car:before { content: fa-content(\e00a\fe01); }
.fad.#{$fa-css-prefix}-garage-car:after, .fa-duotone.#{$fa-css-prefix}-garage-car:after { content: fa-content(\e00a\fe02); }
.fad.#{$fa-css-prefix}-garage-open:before, .fa-duotone.#{$fa-css-prefix}-garage-open:before { content: fa-content(\e00b\fe01); }
.fad.#{$fa-css-prefix}-garage-open:after, .fa-duotone.#{$fa-css-prefix}-garage-open:after { content: fa-content(\e00b\fe02); }
.fad.#{$fa-css-prefix}-gas-pump:before, .fa-duotone.#{$fa-css-prefix}-gas-pump:before { content: fa-content(\26fd\fe01); }
.fad.#{$fa-css-prefix}-gas-pump:after, .fa-duotone.#{$fa-css-prefix}-gas-pump:after { content: fa-content(\26fd\fe02); }
.fad.#{$fa-css-prefix}-gas-pump-slash:before, .fa-duotone.#{$fa-css-prefix}-gas-pump-slash:before { content: fa-content(\f5f4\fe01); }
.fad.#{$fa-css-prefix}-gas-pump-slash:after, .fa-duotone.#{$fa-css-prefix}-gas-pump-slash:after { content: fa-content(\f5f4\fe02); }
.fad.#{$fa-css-prefix}-gauge:before, .fa-duotone.#{$fa-css-prefix}-gauge:before { content: fa-content(\f625\fe01); }
.fad.#{$fa-css-prefix}-gauge:after, .fa-duotone.#{$fa-css-prefix}-gauge:after { content: fa-content(\f625\fe02); }
.fad.#{$fa-css-prefix}-dashboard:before, .fa-duotone.#{$fa-css-prefix}-dashboard:before { content: fa-content(\f625\fe01); }
.fad.#{$fa-css-prefix}-dashboard:after, .fa-duotone.#{$fa-css-prefix}-dashboard:after { content: fa-content(\f625\fe02); }
.fad.#{$fa-css-prefix}-gauge-high:before, .fa-duotone.#{$fa-css-prefix}-gauge-high:before { content: fa-content(\f625\fe01); }
.fad.#{$fa-css-prefix}-gauge-high:after, .fa-duotone.#{$fa-css-prefix}-gauge-high:after { content: fa-content(\f625\fe02); }
.fad.#{$fa-css-prefix}-tachometer-alt:before, .fa-duotone.#{$fa-css-prefix}-tachometer-alt:before { content: fa-content(\f625\fe01); }
.fad.#{$fa-css-prefix}-tachometer-alt:after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt:after { content: fa-content(\f625\fe02); }
.fad.#{$fa-css-prefix}-tachometer-alt-fast:before, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-fast:before { content: fa-content(\f625\fe01); }
.fad.#{$fa-css-prefix}-tachometer-alt-fast:after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-fast:after { content: fa-content(\f625\fe02); }
.fad.#{$fa-css-prefix}-gauge-low:before, .fa-duotone.#{$fa-css-prefix}-gauge-low:before { content: fa-content(\f627\fe01); }
.fad.#{$fa-css-prefix}-gauge-low:after, .fa-duotone.#{$fa-css-prefix}-gauge-low:after { content: fa-content(\f627\fe02); }
.fad.#{$fa-css-prefix}-tachometer-alt-slow:before, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-slow:before { content: fa-content(\f627\fe01); }
.fad.#{$fa-css-prefix}-tachometer-alt-slow:after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-slow:after { content: fa-content(\f627\fe02); }
.fad.#{$fa-css-prefix}-gauge-max:before, .fa-duotone.#{$fa-css-prefix}-gauge-max:before { content: fa-content(\f626\fe01); }
.fad.#{$fa-css-prefix}-gauge-max:after, .fa-duotone.#{$fa-css-prefix}-gauge-max:after { content: fa-content(\f626\fe02); }
.fad.#{$fa-css-prefix}-tachometer-alt-fastest:before, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-fastest:before { content: fa-content(\f626\fe01); }
.fad.#{$fa-css-prefix}-tachometer-alt-fastest:after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-fastest:after { content: fa-content(\f626\fe02); }
.fad.#{$fa-css-prefix}-gauge-med:before, .fa-duotone.#{$fa-css-prefix}-gauge-med:before { content: fa-content(\f624\fe01); }
.fad.#{$fa-css-prefix}-gauge-med:after, .fa-duotone.#{$fa-css-prefix}-gauge-med:after { content: fa-content(\f624\fe02); }
.fad.#{$fa-css-prefix}-tachometer-alt-average:before, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-average:before { content: fa-content(\f624\fe01); }
.fad.#{$fa-css-prefix}-tachometer-alt-average:after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-average:after { content: fa-content(\f624\fe02); }
.fad.#{$fa-css-prefix}-gauge-min:before, .fa-duotone.#{$fa-css-prefix}-gauge-min:before { content: fa-content(\f628\fe01); }
.fad.#{$fa-css-prefix}-gauge-min:after, .fa-duotone.#{$fa-css-prefix}-gauge-min:after { content: fa-content(\f628\fe02); }
.fad.#{$fa-css-prefix}-tachometer-alt-slowest:before, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-slowest:before { content: fa-content(\f628\fe01); }
.fad.#{$fa-css-prefix}-tachometer-alt-slowest:after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-slowest:after { content: fa-content(\f628\fe02); }
.fad.#{$fa-css-prefix}-gauge-simple:before, .fa-duotone.#{$fa-css-prefix}-gauge-simple:before { content: fa-content(\f62a\fe01); }
.fad.#{$fa-css-prefix}-gauge-simple:after, .fa-duotone.#{$fa-css-prefix}-gauge-simple:after { content: fa-content(\f62a\fe02); }
.fad.#{$fa-css-prefix}-gauge-simple-high:before, .fa-duotone.#{$fa-css-prefix}-gauge-simple-high:before { content: fa-content(\f62a\fe01); }
.fad.#{$fa-css-prefix}-gauge-simple-high:after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-high:after { content: fa-content(\f62a\fe02); }
.fad.#{$fa-css-prefix}-tachometer:before, .fa-duotone.#{$fa-css-prefix}-tachometer:before { content: fa-content(\f62a\fe01); }
.fad.#{$fa-css-prefix}-tachometer:after, .fa-duotone.#{$fa-css-prefix}-tachometer:after { content: fa-content(\f62a\fe02); }
.fad.#{$fa-css-prefix}-gauge-simple-low:before, .fa-duotone.#{$fa-css-prefix}-gauge-simple-low:before { content: fa-content(\f62c\fe01); }
.fad.#{$fa-css-prefix}-gauge-simple-low:after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-low:after { content: fa-content(\f62c\fe02); }
.fad.#{$fa-css-prefix}-tachometer-slow:before, .fa-duotone.#{$fa-css-prefix}-tachometer-slow:before { content: fa-content(\f62c\fe01); }
.fad.#{$fa-css-prefix}-tachometer-slow:after, .fa-duotone.#{$fa-css-prefix}-tachometer-slow:after { content: fa-content(\f62c\fe02); }
.fad.#{$fa-css-prefix}-gauge-simple-max:before, .fa-duotone.#{$fa-css-prefix}-gauge-simple-max:before { content: fa-content(\f62b\fe01); }
.fad.#{$fa-css-prefix}-gauge-simple-max:after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-max:after { content: fa-content(\f62b\fe02); }
.fad.#{$fa-css-prefix}-tachometer-fastest:before, .fa-duotone.#{$fa-css-prefix}-tachometer-fastest:before { content: fa-content(\f62b\fe01); }
.fad.#{$fa-css-prefix}-tachometer-fastest:after, .fa-duotone.#{$fa-css-prefix}-tachometer-fastest:after { content: fa-content(\f62b\fe02); }
.fad.#{$fa-css-prefix}-gauge-simple-med:before, .fa-duotone.#{$fa-css-prefix}-gauge-simple-med:before { content: fa-content(\f629\fe01); }
.fad.#{$fa-css-prefix}-gauge-simple-med:after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-med:after { content: fa-content(\f629\fe02); }
.fad.#{$fa-css-prefix}-tachometer-average:before, .fa-duotone.#{$fa-css-prefix}-tachometer-average:before { content: fa-content(\f629\fe01); }
.fad.#{$fa-css-prefix}-tachometer-average:after, .fa-duotone.#{$fa-css-prefix}-tachometer-average:after { content: fa-content(\f629\fe02); }
.fad.#{$fa-css-prefix}-gauge-simple-min:before, .fa-duotone.#{$fa-css-prefix}-gauge-simple-min:before { content: fa-content(\f62d\fe01); }
.fad.#{$fa-css-prefix}-gauge-simple-min:after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-min:after { content: fa-content(\f62d\fe02); }
.fad.#{$fa-css-prefix}-tachometer-slowest:before, .fa-duotone.#{$fa-css-prefix}-tachometer-slowest:before { content: fa-content(\f62d\fe01); }
.fad.#{$fa-css-prefix}-tachometer-slowest:after, .fa-duotone.#{$fa-css-prefix}-tachometer-slowest:after { content: fa-content(\f62d\fe02); }
.fad.#{$fa-css-prefix}-gavel:before, .fa-duotone.#{$fa-css-prefix}-gavel:before { content: fa-content(\f0e3\fe01); }
.fad.#{$fa-css-prefix}-gavel:after, .fa-duotone.#{$fa-css-prefix}-gavel:after { content: fa-content(\f0e3\fe02); }
.fad.#{$fa-css-prefix}-legal:before, .fa-duotone.#{$fa-css-prefix}-legal:before { content: fa-content(\f0e3\fe01); }
.fad.#{$fa-css-prefix}-legal:after, .fa-duotone.#{$fa-css-prefix}-legal:after { content: fa-content(\f0e3\fe02); }
.fad.#{$fa-css-prefix}-gear:before, .fa-duotone.#{$fa-css-prefix}-gear:before { content: fa-content(\2699\fe01); }
.fad.#{$fa-css-prefix}-gear:after, .fa-duotone.#{$fa-css-prefix}-gear:after { content: fa-content(\2699\fe02); }
.fad.#{$fa-css-prefix}-cog:before, .fa-duotone.#{$fa-css-prefix}-cog:before { content: fa-content(\2699\fe01); }
.fad.#{$fa-css-prefix}-cog:after, .fa-duotone.#{$fa-css-prefix}-cog:after { content: fa-content(\2699\fe02); }
.fad.#{$fa-css-prefix}-gears:before, .fa-duotone.#{$fa-css-prefix}-gears:before { content: fa-content(\f085\fe01); }
.fad.#{$fa-css-prefix}-gears:after, .fa-duotone.#{$fa-css-prefix}-gears:after { content: fa-content(\f085\fe02); }
.fad.#{$fa-css-prefix}-cogs:before, .fa-duotone.#{$fa-css-prefix}-cogs:before { content: fa-content(\f085\fe01); }
.fad.#{$fa-css-prefix}-cogs:after, .fa-duotone.#{$fa-css-prefix}-cogs:after { content: fa-content(\f085\fe02); }
.fad.#{$fa-css-prefix}-gem:before, .fa-duotone.#{$fa-css-prefix}-gem:before { content: fa-content(\f3a5); }
.fad.#{$fa-css-prefix}-gem:after, .fa-duotone.#{$fa-css-prefix}-gem:after { content: fa-content(\10f3a5); }
.fad.#{$fa-css-prefix}-genderless:before, .fa-duotone.#{$fa-css-prefix}-genderless:before { content: fa-content(\f22d\fe01); }
.fad.#{$fa-css-prefix}-genderless:after, .fa-duotone.#{$fa-css-prefix}-genderless:after { content: fa-content(\f22d\fe02); }
.fad.#{$fa-css-prefix}-ghost:before, .fa-duotone.#{$fa-css-prefix}-ghost:before { content: fa-content(\f6e2); }
.fad.#{$fa-css-prefix}-ghost:after, .fa-duotone.#{$fa-css-prefix}-ghost:after { content: fa-content(\10f6e2); }
.fad.#{$fa-css-prefix}-gif:before, .fa-duotone.#{$fa-css-prefix}-gif:before { content: fa-content(\e190\fe01); }
.fad.#{$fa-css-prefix}-gif:after, .fa-duotone.#{$fa-css-prefix}-gif:after { content: fa-content(\e190\fe02); }
.fad.#{$fa-css-prefix}-gift:before, .fa-duotone.#{$fa-css-prefix}-gift:before { content: fa-content(\f06b); }
.fad.#{$fa-css-prefix}-gift:after, .fa-duotone.#{$fa-css-prefix}-gift:after { content: fa-content(\10f06b); }
.fad.#{$fa-css-prefix}-gift-card:before, .fa-duotone.#{$fa-css-prefix}-gift-card:before { content: fa-content(\f663\fe01); }
.fad.#{$fa-css-prefix}-gift-card:after, .fa-duotone.#{$fa-css-prefix}-gift-card:after { content: fa-content(\f663\fe02); }
.fad.#{$fa-css-prefix}-gifts:before, .fa-duotone.#{$fa-css-prefix}-gifts:before { content: fa-content(\f79c\fe01); }
.fad.#{$fa-css-prefix}-gifts:after, .fa-duotone.#{$fa-css-prefix}-gifts:after { content: fa-content(\f79c\fe02); }
.fad.#{$fa-css-prefix}-gingerbread-man:before, .fa-duotone.#{$fa-css-prefix}-gingerbread-man:before { content: fa-content(\f79d\fe01); }
.fad.#{$fa-css-prefix}-gingerbread-man:after, .fa-duotone.#{$fa-css-prefix}-gingerbread-man:after { content: fa-content(\f79d\fe02); }
.fad.#{$fa-css-prefix}-glass:before, .fa-duotone.#{$fa-css-prefix}-glass:before { content: fa-content(\f804); }
.fad.#{$fa-css-prefix}-glass:after, .fa-duotone.#{$fa-css-prefix}-glass:after { content: fa-content(\10f804); }
.fad.#{$fa-css-prefix}-glass-citrus:before, .fa-duotone.#{$fa-css-prefix}-glass-citrus:before { content: fa-content(\f869\fe01); }
.fad.#{$fa-css-prefix}-glass-citrus:after, .fa-duotone.#{$fa-css-prefix}-glass-citrus:after { content: fa-content(\f869\fe02); }
.fad.#{$fa-css-prefix}-glass-empty:before, .fa-duotone.#{$fa-css-prefix}-glass-empty:before { content: fa-content(\e191\fe01); }
.fad.#{$fa-css-prefix}-glass-empty:after, .fa-duotone.#{$fa-css-prefix}-glass-empty:after { content: fa-content(\e191\fe02); }
.fad.#{$fa-css-prefix}-glass-half:before, .fa-duotone.#{$fa-css-prefix}-glass-half:before { content: fa-content(\e192\fe01); }
.fad.#{$fa-css-prefix}-glass-half:after, .fa-duotone.#{$fa-css-prefix}-glass-half:after { content: fa-content(\e192\fe02); }
.fad.#{$fa-css-prefix}-glass-half-empty:before, .fa-duotone.#{$fa-css-prefix}-glass-half-empty:before { content: fa-content(\e192\fe01); }
.fad.#{$fa-css-prefix}-glass-half-empty:after, .fa-duotone.#{$fa-css-prefix}-glass-half-empty:after { content: fa-content(\e192\fe02); }
.fad.#{$fa-css-prefix}-glass-half-full:before, .fa-duotone.#{$fa-css-prefix}-glass-half-full:before { content: fa-content(\e192\fe01); }
.fad.#{$fa-css-prefix}-glass-half-full:after, .fa-duotone.#{$fa-css-prefix}-glass-half-full:after { content: fa-content(\e192\fe02); }
.fad.#{$fa-css-prefix}-glasses:before, .fa-duotone.#{$fa-css-prefix}-glasses:before { content: fa-content(\f530\fe01); }
.fad.#{$fa-css-prefix}-glasses:after, .fa-duotone.#{$fa-css-prefix}-glasses:after { content: fa-content(\f530\fe02); }
.fad.#{$fa-css-prefix}-glasses-round:before, .fa-duotone.#{$fa-css-prefix}-glasses-round:before { content: fa-content(\f5f5); }
.fad.#{$fa-css-prefix}-glasses-round:after, .fa-duotone.#{$fa-css-prefix}-glasses-round:after { content: fa-content(\10f5f5); }
.fad.#{$fa-css-prefix}-glasses-alt:before, .fa-duotone.#{$fa-css-prefix}-glasses-alt:before { content: fa-content(\f5f5); }
.fad.#{$fa-css-prefix}-glasses-alt:after, .fa-duotone.#{$fa-css-prefix}-glasses-alt:after { content: fa-content(\10f5f5); }
.fad.#{$fa-css-prefix}-globe:before, .fa-duotone.#{$fa-css-prefix}-globe:before { content: fa-content(\f0ac); }
.fad.#{$fa-css-prefix}-globe:after, .fa-duotone.#{$fa-css-prefix}-globe:after { content: fa-content(\10f0ac); }
.fad.#{$fa-css-prefix}-globe-snow:before, .fa-duotone.#{$fa-css-prefix}-globe-snow:before { content: fa-content(\f7a3\fe01); }
.fad.#{$fa-css-prefix}-globe-snow:after, .fa-duotone.#{$fa-css-prefix}-globe-snow:after { content: fa-content(\f7a3\fe02); }
.fad.#{$fa-css-prefix}-globe-stand:before, .fa-duotone.#{$fa-css-prefix}-globe-stand:before { content: fa-content(\f5f6\fe01); }
.fad.#{$fa-css-prefix}-globe-stand:after, .fa-duotone.#{$fa-css-prefix}-globe-stand:after { content: fa-content(\f5f6\fe02); }
.fad.#{$fa-css-prefix}-goal-net:before, .fa-duotone.#{$fa-css-prefix}-goal-net:before { content: fa-content(\e3ab\fe01); }
.fad.#{$fa-css-prefix}-goal-net:after, .fa-duotone.#{$fa-css-prefix}-goal-net:after { content: fa-content(\e3ab\fe02); }
.fad.#{$fa-css-prefix}-golf-ball-tee:before, .fa-duotone.#{$fa-css-prefix}-golf-ball-tee:before { content: fa-content(\f450\fe01); }
.fad.#{$fa-css-prefix}-golf-ball-tee:after, .fa-duotone.#{$fa-css-prefix}-golf-ball-tee:after { content: fa-content(\f450\fe02); }
.fad.#{$fa-css-prefix}-golf-ball:before, .fa-duotone.#{$fa-css-prefix}-golf-ball:before { content: fa-content(\f450\fe01); }
.fad.#{$fa-css-prefix}-golf-ball:after, .fa-duotone.#{$fa-css-prefix}-golf-ball:after { content: fa-content(\f450\fe02); }
.fad.#{$fa-css-prefix}-golf-club:before, .fa-duotone.#{$fa-css-prefix}-golf-club:before { content: fa-content(\f451\fe01); }
.fad.#{$fa-css-prefix}-golf-club:after, .fa-duotone.#{$fa-css-prefix}-golf-club:after { content: fa-content(\f451\fe02); }
.fad.#{$fa-css-prefix}-golf-flag-hole:before, .fa-duotone.#{$fa-css-prefix}-golf-flag-hole:before { content: fa-content(\e3ac\fe01); }
.fad.#{$fa-css-prefix}-golf-flag-hole:after, .fa-duotone.#{$fa-css-prefix}-golf-flag-hole:after { content: fa-content(\e3ac\fe02); }
.fad.#{$fa-css-prefix}-gopuram:before, .fa-duotone.#{$fa-css-prefix}-gopuram:before { content: fa-content(\f664\fe01); }
.fad.#{$fa-css-prefix}-gopuram:after, .fa-duotone.#{$fa-css-prefix}-gopuram:after { content: fa-content(\f664\fe02); }
.fad.#{$fa-css-prefix}-graduation-cap:before, .fa-duotone.#{$fa-css-prefix}-graduation-cap:before { content: fa-content(\f19d); }
.fad.#{$fa-css-prefix}-graduation-cap:after, .fa-duotone.#{$fa-css-prefix}-graduation-cap:after { content: fa-content(\10f19d); }
.fad.#{$fa-css-prefix}-mortar-board:before, .fa-duotone.#{$fa-css-prefix}-mortar-board:before { content: fa-content(\f19d); }
.fad.#{$fa-css-prefix}-mortar-board:after, .fa-duotone.#{$fa-css-prefix}-mortar-board:after { content: fa-content(\10f19d); }
.fad.#{$fa-css-prefix}-gramophone:before, .fa-duotone.#{$fa-css-prefix}-gramophone:before { content: fa-content(\f8bd\fe01); }
.fad.#{$fa-css-prefix}-gramophone:after, .fa-duotone.#{$fa-css-prefix}-gramophone:after { content: fa-content(\f8bd\fe02); }
.fad.#{$fa-css-prefix}-grapes:before, .fa-duotone.#{$fa-css-prefix}-grapes:before { content: fa-content(\e306\fe01); }
.fad.#{$fa-css-prefix}-grapes:after, .fa-duotone.#{$fa-css-prefix}-grapes:after { content: fa-content(\e306\fe02); }
.fad.#{$fa-css-prefix}-grate:before, .fa-duotone.#{$fa-css-prefix}-grate:before { content: fa-content(\e193\fe01); }
.fad.#{$fa-css-prefix}-grate:after, .fa-duotone.#{$fa-css-prefix}-grate:after { content: fa-content(\e193\fe02); }
.fad.#{$fa-css-prefix}-grate-droplet:before, .fa-duotone.#{$fa-css-prefix}-grate-droplet:before { content: fa-content(\e194\fe01); }
.fad.#{$fa-css-prefix}-grate-droplet:after, .fa-duotone.#{$fa-css-prefix}-grate-droplet:after { content: fa-content(\e194\fe02); }
.fad.#{$fa-css-prefix}-greater-than:before, .fa-duotone.#{$fa-css-prefix}-greater-than:before { content: fa-content(\3e\fe01); }
.fad.#{$fa-css-prefix}-greater-than:after, .fa-duotone.#{$fa-css-prefix}-greater-than:after { content: fa-content(\3e\fe02); }
.fad.#{$fa-css-prefix}-greater-than-equal:before, .fa-duotone.#{$fa-css-prefix}-greater-than-equal:before { content: fa-content(\f532\fe01); }
.fad.#{$fa-css-prefix}-greater-than-equal:after, .fa-duotone.#{$fa-css-prefix}-greater-than-equal:after { content: fa-content(\f532\fe02); }
.fad.#{$fa-css-prefix}-grid:before, .fa-duotone.#{$fa-css-prefix}-grid:before { content: fa-content(\e195\fe01); }
.fad.#{$fa-css-prefix}-grid:after, .fa-duotone.#{$fa-css-prefix}-grid:after { content: fa-content(\e195\fe02); }
.fad.#{$fa-css-prefix}-grid-3:before, .fa-duotone.#{$fa-css-prefix}-grid-3:before { content: fa-content(\e195\fe01); }
.fad.#{$fa-css-prefix}-grid-3:after, .fa-duotone.#{$fa-css-prefix}-grid-3:after { content: fa-content(\e195\fe02); }
.fad.#{$fa-css-prefix}-grid-2:before, .fa-duotone.#{$fa-css-prefix}-grid-2:before { content: fa-content(\e196\fe01); }
.fad.#{$fa-css-prefix}-grid-2:after, .fa-duotone.#{$fa-css-prefix}-grid-2:after { content: fa-content(\e196\fe02); }
.fad.#{$fa-css-prefix}-grid-2-plus:before, .fa-duotone.#{$fa-css-prefix}-grid-2-plus:before { content: fa-content(\e197\fe01); }
.fad.#{$fa-css-prefix}-grid-2-plus:after, .fa-duotone.#{$fa-css-prefix}-grid-2-plus:after { content: fa-content(\e197\fe02); }
.fad.#{$fa-css-prefix}-grid-4:before, .fa-duotone.#{$fa-css-prefix}-grid-4:before { content: fa-content(\e198\fe01); }
.fad.#{$fa-css-prefix}-grid-4:after, .fa-duotone.#{$fa-css-prefix}-grid-4:after { content: fa-content(\e198\fe02); }
.fad.#{$fa-css-prefix}-grid-5:before, .fa-duotone.#{$fa-css-prefix}-grid-5:before { content: fa-content(\e199\fe01); }
.fad.#{$fa-css-prefix}-grid-5:after, .fa-duotone.#{$fa-css-prefix}-grid-5:after { content: fa-content(\e199\fe02); }
.fad.#{$fa-css-prefix}-grid-dividers:before, .fa-duotone.#{$fa-css-prefix}-grid-dividers:before { content: fa-content(\e3ad\fe01); }
.fad.#{$fa-css-prefix}-grid-dividers:after, .fa-duotone.#{$fa-css-prefix}-grid-dividers:after { content: fa-content(\e3ad\fe02); }
.fad.#{$fa-css-prefix}-grid-horizontal:before, .fa-duotone.#{$fa-css-prefix}-grid-horizontal:before { content: fa-content(\e307\fe01); }
.fad.#{$fa-css-prefix}-grid-horizontal:after, .fa-duotone.#{$fa-css-prefix}-grid-horizontal:after { content: fa-content(\e307\fe02); }
.fad.#{$fa-css-prefix}-grip:before, .fa-duotone.#{$fa-css-prefix}-grip:before { content: fa-content(\f58d\fe01); }
.fad.#{$fa-css-prefix}-grip:after, .fa-duotone.#{$fa-css-prefix}-grip:after { content: fa-content(\f58d\fe02); }
.fad.#{$fa-css-prefix}-grip-horizontal:before, .fa-duotone.#{$fa-css-prefix}-grip-horizontal:before { content: fa-content(\f58d\fe01); }
.fad.#{$fa-css-prefix}-grip-horizontal:after, .fa-duotone.#{$fa-css-prefix}-grip-horizontal:after { content: fa-content(\f58d\fe02); }
.fad.#{$fa-css-prefix}-grip-lines:before, .fa-duotone.#{$fa-css-prefix}-grip-lines:before { content: fa-content(\f7a4\fe01); }
.fad.#{$fa-css-prefix}-grip-lines:after, .fa-duotone.#{$fa-css-prefix}-grip-lines:after { content: fa-content(\f7a4\fe02); }
.fad.#{$fa-css-prefix}-grip-lines-vertical:before, .fa-duotone.#{$fa-css-prefix}-grip-lines-vertical:before { content: fa-content(\f7a5\fe01); }
.fad.#{$fa-css-prefix}-grip-lines-vertical:after, .fa-duotone.#{$fa-css-prefix}-grip-lines-vertical:after { content: fa-content(\f7a5\fe02); }
.fad.#{$fa-css-prefix}-grip-vertical:before, .fa-duotone.#{$fa-css-prefix}-grip-vertical:before { content: fa-content(\f58e\fe01); }
.fad.#{$fa-css-prefix}-grip-vertical:after, .fa-duotone.#{$fa-css-prefix}-grip-vertical:after { content: fa-content(\f58e\fe02); }
.fad.#{$fa-css-prefix}-guarani-sign:before, .fa-duotone.#{$fa-css-prefix}-guarani-sign:before { content: fa-content(\e19a\fe01); }
.fad.#{$fa-css-prefix}-guarani-sign:after, .fa-duotone.#{$fa-css-prefix}-guarani-sign:after { content: fa-content(\e19a\fe02); }
.fad.#{$fa-css-prefix}-guitar:before, .fa-duotone.#{$fa-css-prefix}-guitar:before { content: fa-content(\f7a6\fe01); }
.fad.#{$fa-css-prefix}-guitar:after, .fa-duotone.#{$fa-css-prefix}-guitar:after { content: fa-content(\f7a6\fe02); }
.fad.#{$fa-css-prefix}-guitar-electric:before, .fa-duotone.#{$fa-css-prefix}-guitar-electric:before { content: fa-content(\f8be); }
.fad.#{$fa-css-prefix}-guitar-electric:after, .fa-duotone.#{$fa-css-prefix}-guitar-electric:after { content: fa-content(\10f8be); }
.fad.#{$fa-css-prefix}-guitars:before, .fa-duotone.#{$fa-css-prefix}-guitars:before { content: fa-content(\f8bf\fe01); }
.fad.#{$fa-css-prefix}-guitars:after, .fa-duotone.#{$fa-css-prefix}-guitars:after { content: fa-content(\f8bf\fe02); }
.fad.#{$fa-css-prefix}-gun:before, .fa-duotone.#{$fa-css-prefix}-gun:before { content: fa-content(\e19b\fe01); }
.fad.#{$fa-css-prefix}-gun:after, .fa-duotone.#{$fa-css-prefix}-gun:after { content: fa-content(\e19b\fe02); }
.fad.#{$fa-css-prefix}-gun-slash:before, .fa-duotone.#{$fa-css-prefix}-gun-slash:before { content: fa-content(\e19c\fe01); }
.fad.#{$fa-css-prefix}-gun-slash:after, .fa-duotone.#{$fa-css-prefix}-gun-slash:after { content: fa-content(\e19c\fe02); }
.fad.#{$fa-css-prefix}-gun-squirt:before, .fa-duotone.#{$fa-css-prefix}-gun-squirt:before { content: fa-content(\e19d\fe01); }
.fad.#{$fa-css-prefix}-gun-squirt:after, .fa-duotone.#{$fa-css-prefix}-gun-squirt:after { content: fa-content(\e19d\fe02); }
.fad.#{$fa-css-prefix}-h:before, .fa-duotone.#{$fa-css-prefix}-h:before { content: fa-content(\48\fe01); }
.fad.#{$fa-css-prefix}-h:after, .fa-duotone.#{$fa-css-prefix}-h:after { content: fa-content(\48\fe02); }
.fad.#{$fa-css-prefix}-h1:before, .fa-duotone.#{$fa-css-prefix}-h1:before { content: fa-content(\f313\fe01); }
.fad.#{$fa-css-prefix}-h1:after, .fa-duotone.#{$fa-css-prefix}-h1:after { content: fa-content(\f313\fe02); }
.fad.#{$fa-css-prefix}-h2:before, .fa-duotone.#{$fa-css-prefix}-h2:before { content: fa-content(\f314\fe01); }
.fad.#{$fa-css-prefix}-h2:after, .fa-duotone.#{$fa-css-prefix}-h2:after { content: fa-content(\f314\fe02); }
.fad.#{$fa-css-prefix}-h3:before, .fa-duotone.#{$fa-css-prefix}-h3:before { content: fa-content(\f315\fe01); }
.fad.#{$fa-css-prefix}-h3:after, .fa-duotone.#{$fa-css-prefix}-h3:after { content: fa-content(\f315\fe02); }
.fad.#{$fa-css-prefix}-h4:before, .fa-duotone.#{$fa-css-prefix}-h4:before { content: fa-content(\f86a\fe01); }
.fad.#{$fa-css-prefix}-h4:after, .fa-duotone.#{$fa-css-prefix}-h4:after { content: fa-content(\f86a\fe02); }
.fad.#{$fa-css-prefix}-hammer:before, .fa-duotone.#{$fa-css-prefix}-hammer:before { content: fa-content(\f6e3); }
.fad.#{$fa-css-prefix}-hammer:after, .fa-duotone.#{$fa-css-prefix}-hammer:after { content: fa-content(\10f6e3); }
.fad.#{$fa-css-prefix}-hammer-war:before, .fa-duotone.#{$fa-css-prefix}-hammer-war:before { content: fa-content(\f6e4\fe01); }
.fad.#{$fa-css-prefix}-hammer-war:after, .fa-duotone.#{$fa-css-prefix}-hammer-war:after { content: fa-content(\f6e4\fe02); }
.fad.#{$fa-css-prefix}-hamsa:before, .fa-duotone.#{$fa-css-prefix}-hamsa:before { content: fa-content(\f665\fe01); }
.fad.#{$fa-css-prefix}-hamsa:after, .fa-duotone.#{$fa-css-prefix}-hamsa:after { content: fa-content(\f665\fe02); }
.fad.#{$fa-css-prefix}-hand:before, .fa-duotone.#{$fa-css-prefix}-hand:before { content: fa-content(\270b\fe01); }
.fad.#{$fa-css-prefix}-hand:after, .fa-duotone.#{$fa-css-prefix}-hand:after { content: fa-content(\270b\fe02); }
.fad.#{$fa-css-prefix}-hand-paper:before, .fa-duotone.#{$fa-css-prefix}-hand-paper:before { content: fa-content(\270b\fe01); }
.fad.#{$fa-css-prefix}-hand-paper:after, .fa-duotone.#{$fa-css-prefix}-hand-paper:after { content: fa-content(\270b\fe02); }
.fad.#{$fa-css-prefix}-hand-back-fist:before, .fa-duotone.#{$fa-css-prefix}-hand-back-fist:before { content: fa-content(\f255\fe01); }
.fad.#{$fa-css-prefix}-hand-back-fist:after, .fa-duotone.#{$fa-css-prefix}-hand-back-fist:after { content: fa-content(\f255\fe02); }
.fad.#{$fa-css-prefix}-hand-rock:before, .fa-duotone.#{$fa-css-prefix}-hand-rock:before { content: fa-content(\f255\fe01); }
.fad.#{$fa-css-prefix}-hand-rock:after, .fa-duotone.#{$fa-css-prefix}-hand-rock:after { content: fa-content(\f255\fe02); }
.fad.#{$fa-css-prefix}-hand-back-point-down:before, .fa-duotone.#{$fa-css-prefix}-hand-back-point-down:before { content: fa-content(\e19e\fe01); }
.fad.#{$fa-css-prefix}-hand-back-point-down:after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-down:after { content: fa-content(\e19e\fe02); }
.fad.#{$fa-css-prefix}-hand-back-point-left:before, .fa-duotone.#{$fa-css-prefix}-hand-back-point-left:before { content: fa-content(\e19f\fe01); }
.fad.#{$fa-css-prefix}-hand-back-point-left:after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-left:after { content: fa-content(\e19f\fe02); }
.fad.#{$fa-css-prefix}-hand-back-point-ribbon:before, .fa-duotone.#{$fa-css-prefix}-hand-back-point-ribbon:before { content: fa-content(\e1a0\fe01); }
.fad.#{$fa-css-prefix}-hand-back-point-ribbon:after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-ribbon:after { content: fa-content(\e1a0\fe02); }
.fad.#{$fa-css-prefix}-hand-back-point-right:before, .fa-duotone.#{$fa-css-prefix}-hand-back-point-right:before { content: fa-content(\e1a1\fe01); }
.fad.#{$fa-css-prefix}-hand-back-point-right:after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-right:after { content: fa-content(\e1a1\fe02); }
.fad.#{$fa-css-prefix}-hand-back-point-up:before, .fa-duotone.#{$fa-css-prefix}-hand-back-point-up:before { content: fa-content(\e1a2\fe01); }
.fad.#{$fa-css-prefix}-hand-back-point-up:after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-up:after { content: fa-content(\e1a2\fe02); }
.fad.#{$fa-css-prefix}-hand-dots:before, .fa-duotone.#{$fa-css-prefix}-hand-dots:before { content: fa-content(\f461\fe01); }
.fad.#{$fa-css-prefix}-hand-dots:after, .fa-duotone.#{$fa-css-prefix}-hand-dots:after { content: fa-content(\f461\fe02); }
.fad.#{$fa-css-prefix}-allergies:before, .fa-duotone.#{$fa-css-prefix}-allergies:before { content: fa-content(\f461\fe01); }
.fad.#{$fa-css-prefix}-allergies:after, .fa-duotone.#{$fa-css-prefix}-allergies:after { content: fa-content(\f461\fe02); }
.fad.#{$fa-css-prefix}-hand-fingers-crossed:before, .fa-duotone.#{$fa-css-prefix}-hand-fingers-crossed:before { content: fa-content(\e1a3\fe01); }
.fad.#{$fa-css-prefix}-hand-fingers-crossed:after, .fa-duotone.#{$fa-css-prefix}-hand-fingers-crossed:after { content: fa-content(\e1a3\fe02); }
.fad.#{$fa-css-prefix}-hand-fist:before, .fa-duotone.#{$fa-css-prefix}-hand-fist:before { content: fa-content(\270a\fe01); }
.fad.#{$fa-css-prefix}-hand-fist:after, .fa-duotone.#{$fa-css-prefix}-hand-fist:after { content: fa-content(\270a\fe02); }
.fad.#{$fa-css-prefix}-fist-raised:before, .fa-duotone.#{$fa-css-prefix}-fist-raised:before { content: fa-content(\270a\fe01); }
.fad.#{$fa-css-prefix}-fist-raised:after, .fa-duotone.#{$fa-css-prefix}-fist-raised:after { content: fa-content(\270a\fe02); }
.fad.#{$fa-css-prefix}-hand-heart:before, .fa-duotone.#{$fa-css-prefix}-hand-heart:before { content: fa-content(\f4bc\fe01); }
.fad.#{$fa-css-prefix}-hand-heart:after, .fa-duotone.#{$fa-css-prefix}-hand-heart:after { content: fa-content(\f4bc\fe02); }
.fad.#{$fa-css-prefix}-hand-holding:before, .fa-duotone.#{$fa-css-prefix}-hand-holding:before { content: fa-content(\f4bd\fe01); }
.fad.#{$fa-css-prefix}-hand-holding:after, .fa-duotone.#{$fa-css-prefix}-hand-holding:after { content: fa-content(\f4bd\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-box:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-box:before { content: fa-content(\f47b\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-box:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-box:after { content: fa-content(\f47b\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-dollar:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-dollar:before { content: fa-content(\f4c0\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-dollar:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-dollar:after { content: fa-content(\f4c0\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-usd:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-usd:before { content: fa-content(\f4c0\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-usd:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-usd:after { content: fa-content(\f4c0\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-droplet:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-droplet:before { content: fa-content(\f4c1\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-droplet:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-droplet:after { content: fa-content(\f4c1\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-water:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-water:before { content: fa-content(\f4c1\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-water:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-water:after { content: fa-content(\f4c1\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-heart:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-heart:before { content: fa-content(\f4be\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-heart:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-heart:after { content: fa-content(\f4be\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-magic:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-magic:before { content: fa-content(\f6e5\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-magic:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-magic:after { content: fa-content(\f6e5\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-medical:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-medical:before { content: fa-content(\e05c\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-medical:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-medical:after { content: fa-content(\e05c\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-seedling:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-seedling:before { content: fa-content(\f4bf\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-seedling:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-seedling:after { content: fa-content(\f4bf\fe02); }
.fad.#{$fa-css-prefix}-hand-holding-skull:before, .fa-duotone.#{$fa-css-prefix}-hand-holding-skull:before { content: fa-content(\e1a4\fe01); }
.fad.#{$fa-css-prefix}-hand-holding-skull:after, .fa-duotone.#{$fa-css-prefix}-hand-holding-skull:after { content: fa-content(\e1a4\fe02); }
.fad.#{$fa-css-prefix}-hand-horns:before, .fa-duotone.#{$fa-css-prefix}-hand-horns:before { content: fa-content(\e1a9\fe01); }
.fad.#{$fa-css-prefix}-hand-horns:after, .fa-duotone.#{$fa-css-prefix}-hand-horns:after { content: fa-content(\e1a9\fe02); }
.fad.#{$fa-css-prefix}-hand-lizard:before, .fa-duotone.#{$fa-css-prefix}-hand-lizard:before { content: fa-content(\f258\fe01); }
.fad.#{$fa-css-prefix}-hand-lizard:after, .fa-duotone.#{$fa-css-prefix}-hand-lizard:after { content: fa-content(\f258\fe02); }
.fad.#{$fa-css-prefix}-hand-love:before, .fa-duotone.#{$fa-css-prefix}-hand-love:before { content: fa-content(\e1a5\fe01); }
.fad.#{$fa-css-prefix}-hand-love:after, .fa-duotone.#{$fa-css-prefix}-hand-love:after { content: fa-content(\e1a5\fe02); }
.fad.#{$fa-css-prefix}-hand-middle-finger:before, .fa-duotone.#{$fa-css-prefix}-hand-middle-finger:before { content: fa-content(\f806); }
.fad.#{$fa-css-prefix}-hand-middle-finger:after, .fa-duotone.#{$fa-css-prefix}-hand-middle-finger:after { content: fa-content(\10f806); }
.fad.#{$fa-css-prefix}-hand-peace:before, .fa-duotone.#{$fa-css-prefix}-hand-peace:before { content: fa-content(\270c\fe01); }
.fad.#{$fa-css-prefix}-hand-peace:after, .fa-duotone.#{$fa-css-prefix}-hand-peace:after { content: fa-content(\270c\fe02); }
.fad.#{$fa-css-prefix}-hand-point-down:before, .fa-duotone.#{$fa-css-prefix}-hand-point-down:before { content: fa-content(\f0a7\fe01); }
.fad.#{$fa-css-prefix}-hand-point-down:after, .fa-duotone.#{$fa-css-prefix}-hand-point-down:after { content: fa-content(\f0a7\fe02); }
.fad.#{$fa-css-prefix}-hand-point-left:before, .fa-duotone.#{$fa-css-prefix}-hand-point-left:before { content: fa-content(\f0a5\fe01); }
.fad.#{$fa-css-prefix}-hand-point-left:after, .fa-duotone.#{$fa-css-prefix}-hand-point-left:after { content: fa-content(\f0a5\fe02); }
.fad.#{$fa-css-prefix}-hand-point-ribbon:before, .fa-duotone.#{$fa-css-prefix}-hand-point-ribbon:before { content: fa-content(\e1a6\fe01); }
.fad.#{$fa-css-prefix}-hand-point-ribbon:after, .fa-duotone.#{$fa-css-prefix}-hand-point-ribbon:after { content: fa-content(\e1a6\fe02); }
.fad.#{$fa-css-prefix}-hand-point-right:before, .fa-duotone.#{$fa-css-prefix}-hand-point-right:before { content: fa-content(\f0a4\fe01); }
.fad.#{$fa-css-prefix}-hand-point-right:after, .fa-duotone.#{$fa-css-prefix}-hand-point-right:after { content: fa-content(\f0a4\fe02); }
.fad.#{$fa-css-prefix}-hand-point-up:before, .fa-duotone.#{$fa-css-prefix}-hand-point-up:before { content: fa-content(\261d\fe01); }
.fad.#{$fa-css-prefix}-hand-point-up:after, .fa-duotone.#{$fa-css-prefix}-hand-point-up:after { content: fa-content(\261d\fe02); }
.fad.#{$fa-css-prefix}-hand-pointer:before, .fa-duotone.#{$fa-css-prefix}-hand-pointer:before { content: fa-content(\f25a\fe01); }
.fad.#{$fa-css-prefix}-hand-pointer:after, .fa-duotone.#{$fa-css-prefix}-hand-pointer:after { content: fa-content(\f25a\fe02); }
.fad.#{$fa-css-prefix}-hand-scissors:before, .fa-duotone.#{$fa-css-prefix}-hand-scissors:before { content: fa-content(\f257\fe01); }
.fad.#{$fa-css-prefix}-hand-scissors:after, .fa-duotone.#{$fa-css-prefix}-hand-scissors:after { content: fa-content(\f257\fe02); }
.fad.#{$fa-css-prefix}-hand-sparkles:before, .fa-duotone.#{$fa-css-prefix}-hand-sparkles:before { content: fa-content(\e05d\fe01); }
.fad.#{$fa-css-prefix}-hand-sparkles:after, .fa-duotone.#{$fa-css-prefix}-hand-sparkles:after { content: fa-content(\e05d\fe02); }
.fad.#{$fa-css-prefix}-hand-spock:before, .fa-duotone.#{$fa-css-prefix}-hand-spock:before { content: fa-content(\f259); }
.fad.#{$fa-css-prefix}-hand-spock:after, .fa-duotone.#{$fa-css-prefix}-hand-spock:after { content: fa-content(\10f259); }
.fad.#{$fa-css-prefix}-hand-wave:before, .fa-duotone.#{$fa-css-prefix}-hand-wave:before { content: fa-content(\e1a7\fe01); }
.fad.#{$fa-css-prefix}-hand-wave:after, .fa-duotone.#{$fa-css-prefix}-hand-wave:after { content: fa-content(\e1a7\fe02); }
.fad.#{$fa-css-prefix}-hands:before, .fa-duotone.#{$fa-css-prefix}-hands:before { content: fa-content(\f2a7\fe01); }
.fad.#{$fa-css-prefix}-hands:after, .fa-duotone.#{$fa-css-prefix}-hands:after { content: fa-content(\f2a7\fe02); }
.fad.#{$fa-css-prefix}-sign-language:before, .fa-duotone.#{$fa-css-prefix}-sign-language:before { content: fa-content(\f2a7\fe01); }
.fad.#{$fa-css-prefix}-sign-language:after, .fa-duotone.#{$fa-css-prefix}-sign-language:after { content: fa-content(\f2a7\fe02); }
.fad.#{$fa-css-prefix}-signing:before, .fa-duotone.#{$fa-css-prefix}-signing:before { content: fa-content(\f2a7\fe01); }
.fad.#{$fa-css-prefix}-signing:after, .fa-duotone.#{$fa-css-prefix}-signing:after { content: fa-content(\f2a7\fe02); }
.fad.#{$fa-css-prefix}-hands-asl-interpreting:before, .fa-duotone.#{$fa-css-prefix}-hands-asl-interpreting:before { content: fa-content(\f2a3\fe01); }
.fad.#{$fa-css-prefix}-hands-asl-interpreting:after, .fa-duotone.#{$fa-css-prefix}-hands-asl-interpreting:after { content: fa-content(\f2a3\fe02); }
.fad.#{$fa-css-prefix}-american-sign-language-interpreting:before, .fa-duotone.#{$fa-css-prefix}-american-sign-language-interpreting:before { content: fa-content(\f2a3\fe01); }
.fad.#{$fa-css-prefix}-american-sign-language-interpreting:after, .fa-duotone.#{$fa-css-prefix}-american-sign-language-interpreting:after { content: fa-content(\f2a3\fe02); }
.fad.#{$fa-css-prefix}-asl-interpreting:before, .fa-duotone.#{$fa-css-prefix}-asl-interpreting:before { content: fa-content(\f2a3\fe01); }
.fad.#{$fa-css-prefix}-asl-interpreting:after, .fa-duotone.#{$fa-css-prefix}-asl-interpreting:after { content: fa-content(\f2a3\fe02); }
.fad.#{$fa-css-prefix}-hands-american-sign-language-interpreting:before, .fa-duotone.#{$fa-css-prefix}-hands-american-sign-language-interpreting:before { content: fa-content(\f2a3\fe01); }
.fad.#{$fa-css-prefix}-hands-american-sign-language-interpreting:after, .fa-duotone.#{$fa-css-prefix}-hands-american-sign-language-interpreting:after { content: fa-content(\f2a3\fe02); }
.fad.#{$fa-css-prefix}-hands-bubbles:before, .fa-duotone.#{$fa-css-prefix}-hands-bubbles:before { content: fa-content(\e05e\fe01); }
.fad.#{$fa-css-prefix}-hands-bubbles:after, .fa-duotone.#{$fa-css-prefix}-hands-bubbles:after { content: fa-content(\e05e\fe02); }
.fad.#{$fa-css-prefix}-hands-wash:before, .fa-duotone.#{$fa-css-prefix}-hands-wash:before { content: fa-content(\e05e\fe01); }
.fad.#{$fa-css-prefix}-hands-wash:after, .fa-duotone.#{$fa-css-prefix}-hands-wash:after { content: fa-content(\e05e\fe02); }
.fad.#{$fa-css-prefix}-hands-clapping:before, .fa-duotone.#{$fa-css-prefix}-hands-clapping:before { content: fa-content(\e1a8\fe01); }
.fad.#{$fa-css-prefix}-hands-clapping:after, .fa-duotone.#{$fa-css-prefix}-hands-clapping:after { content: fa-content(\e1a8\fe02); }
.fad.#{$fa-css-prefix}-hands-holding:before, .fa-duotone.#{$fa-css-prefix}-hands-holding:before { content: fa-content(\f4c2\fe01); }
.fad.#{$fa-css-prefix}-hands-holding:after, .fa-duotone.#{$fa-css-prefix}-hands-holding:after { content: fa-content(\f4c2\fe02); }
.fad.#{$fa-css-prefix}-hands-holding-diamond:before, .fa-duotone.#{$fa-css-prefix}-hands-holding-diamond:before { content: fa-content(\f47c\fe01); }
.fad.#{$fa-css-prefix}-hands-holding-diamond:after, .fa-duotone.#{$fa-css-prefix}-hands-holding-diamond:after { content: fa-content(\f47c\fe02); }
.fad.#{$fa-css-prefix}-hand-receiving:before, .fa-duotone.#{$fa-css-prefix}-hand-receiving:before { content: fa-content(\f47c\fe01); }
.fad.#{$fa-css-prefix}-hand-receiving:after, .fa-duotone.#{$fa-css-prefix}-hand-receiving:after { content: fa-content(\f47c\fe02); }
.fad.#{$fa-css-prefix}-hands-holding-dollar:before, .fa-duotone.#{$fa-css-prefix}-hands-holding-dollar:before { content: fa-content(\f4c5\fe01); }
.fad.#{$fa-css-prefix}-hands-holding-dollar:after, .fa-duotone.#{$fa-css-prefix}-hands-holding-dollar:after { content: fa-content(\f4c5\fe02); }
.fad.#{$fa-css-prefix}-hands-usd:before, .fa-duotone.#{$fa-css-prefix}-hands-usd:before { content: fa-content(\f4c5\fe01); }
.fad.#{$fa-css-prefix}-hands-usd:after, .fa-duotone.#{$fa-css-prefix}-hands-usd:after { content: fa-content(\f4c5\fe02); }
.fad.#{$fa-css-prefix}-hands-holding-heart:before, .fa-duotone.#{$fa-css-prefix}-hands-holding-heart:before { content: fa-content(\f4c3\fe01); }
.fad.#{$fa-css-prefix}-hands-holding-heart:after, .fa-duotone.#{$fa-css-prefix}-hands-holding-heart:after { content: fa-content(\f4c3\fe02); }
.fad.#{$fa-css-prefix}-hands-heart:before, .fa-duotone.#{$fa-css-prefix}-hands-heart:before { content: fa-content(\f4c3\fe01); }
.fad.#{$fa-css-prefix}-hands-heart:after, .fa-duotone.#{$fa-css-prefix}-hands-heart:after { content: fa-content(\f4c3\fe02); }
.fad.#{$fa-css-prefix}-hands-praying:before, .fa-duotone.#{$fa-css-prefix}-hands-praying:before { content: fa-content(\f684\fe01); }
.fad.#{$fa-css-prefix}-hands-praying:after, .fa-duotone.#{$fa-css-prefix}-hands-praying:after { content: fa-content(\f684\fe02); }
.fad.#{$fa-css-prefix}-praying-hands:before, .fa-duotone.#{$fa-css-prefix}-praying-hands:before { content: fa-content(\f684\fe01); }
.fad.#{$fa-css-prefix}-praying-hands:after, .fa-duotone.#{$fa-css-prefix}-praying-hands:after { content: fa-content(\f684\fe02); }
.fad.#{$fa-css-prefix}-handshake:before, .fa-duotone.#{$fa-css-prefix}-handshake:before { content: fa-content(\f2b5\fe01); }
.fad.#{$fa-css-prefix}-handshake:after, .fa-duotone.#{$fa-css-prefix}-handshake:after { content: fa-content(\f2b5\fe02); }
.fad.#{$fa-css-prefix}-handshake-angle:before, .fa-duotone.#{$fa-css-prefix}-handshake-angle:before { content: fa-content(\f4c4\fe01); }
.fad.#{$fa-css-prefix}-handshake-angle:after, .fa-duotone.#{$fa-css-prefix}-handshake-angle:after { content: fa-content(\f4c4\fe02); }
.fad.#{$fa-css-prefix}-hands-helping:before, .fa-duotone.#{$fa-css-prefix}-hands-helping:before { content: fa-content(\f4c4\fe01); }
.fad.#{$fa-css-prefix}-hands-helping:after, .fa-duotone.#{$fa-css-prefix}-hands-helping:after { content: fa-content(\f4c4\fe02); }
.fad.#{$fa-css-prefix}-handshake-simple:before, .fa-duotone.#{$fa-css-prefix}-handshake-simple:before { content: fa-content(\f4c6); }
.fad.#{$fa-css-prefix}-handshake-simple:after, .fa-duotone.#{$fa-css-prefix}-handshake-simple:after { content: fa-content(\10f4c6); }
.fad.#{$fa-css-prefix}-handshake-alt:before, .fa-duotone.#{$fa-css-prefix}-handshake-alt:before { content: fa-content(\f4c6); }
.fad.#{$fa-css-prefix}-handshake-alt:after, .fa-duotone.#{$fa-css-prefix}-handshake-alt:after { content: fa-content(\10f4c6); }
.fad.#{$fa-css-prefix}-handshake-simple-slash:before, .fa-duotone.#{$fa-css-prefix}-handshake-simple-slash:before { content: fa-content(\e05f\fe01); }
.fad.#{$fa-css-prefix}-handshake-simple-slash:after, .fa-duotone.#{$fa-css-prefix}-handshake-simple-slash:after { content: fa-content(\e05f\fe02); }
.fad.#{$fa-css-prefix}-handshake-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-handshake-alt-slash:before { content: fa-content(\e05f\fe01); }
.fad.#{$fa-css-prefix}-handshake-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-handshake-alt-slash:after { content: fa-content(\e05f\fe02); }
.fad.#{$fa-css-prefix}-handshake-slash:before, .fa-duotone.#{$fa-css-prefix}-handshake-slash:before { content: fa-content(\e060\fe01); }
.fad.#{$fa-css-prefix}-handshake-slash:after, .fa-duotone.#{$fa-css-prefix}-handshake-slash:after { content: fa-content(\e060\fe02); }
.fad.#{$fa-css-prefix}-hanukiah:before, .fa-duotone.#{$fa-css-prefix}-hanukiah:before { content: fa-content(\f6e6); }
.fad.#{$fa-css-prefix}-hanukiah:after, .fa-duotone.#{$fa-css-prefix}-hanukiah:after { content: fa-content(\10f6e6); }
.fad.#{$fa-css-prefix}-hard-drive:before, .fa-duotone.#{$fa-css-prefix}-hard-drive:before { content: fa-content(\f0a0); }
.fad.#{$fa-css-prefix}-hard-drive:after, .fa-duotone.#{$fa-css-prefix}-hard-drive:after { content: fa-content(\10f0a0); }
.fad.#{$fa-css-prefix}-hdd:before, .fa-duotone.#{$fa-css-prefix}-hdd:before { content: fa-content(\f0a0); }
.fad.#{$fa-css-prefix}-hdd:after, .fa-duotone.#{$fa-css-prefix}-hdd:after { content: fa-content(\10f0a0); }
.fad.#{$fa-css-prefix}-hashtag:before, .fa-duotone.#{$fa-css-prefix}-hashtag:before { content: fa-content(\23\fe01); }
.fad.#{$fa-css-prefix}-hashtag:after, .fa-duotone.#{$fa-css-prefix}-hashtag:after { content: fa-content(\23\fe02); }
.fad.#{$fa-css-prefix}-hat-chef:before, .fa-duotone.#{$fa-css-prefix}-hat-chef:before { content: fa-content(\f86b\fe01); }
.fad.#{$fa-css-prefix}-hat-chef:after, .fa-duotone.#{$fa-css-prefix}-hat-chef:after { content: fa-content(\f86b\fe02); }
.fad.#{$fa-css-prefix}-hat-cowboy:before, .fa-duotone.#{$fa-css-prefix}-hat-cowboy:before { content: fa-content(\f8c0\fe01); }
.fad.#{$fa-css-prefix}-hat-cowboy:after, .fa-duotone.#{$fa-css-prefix}-hat-cowboy:after { content: fa-content(\f8c0\fe02); }
.fad.#{$fa-css-prefix}-hat-cowboy-side:before, .fa-duotone.#{$fa-css-prefix}-hat-cowboy-side:before { content: fa-content(\f8c1\fe01); }
.fad.#{$fa-css-prefix}-hat-cowboy-side:after, .fa-duotone.#{$fa-css-prefix}-hat-cowboy-side:after { content: fa-content(\f8c1\fe02); }
.fad.#{$fa-css-prefix}-hat-santa:before, .fa-duotone.#{$fa-css-prefix}-hat-santa:before { content: fa-content(\f7a7\fe01); }
.fad.#{$fa-css-prefix}-hat-santa:after, .fa-duotone.#{$fa-css-prefix}-hat-santa:after { content: fa-content(\f7a7\fe02); }
.fad.#{$fa-css-prefix}-hat-winter:before, .fa-duotone.#{$fa-css-prefix}-hat-winter:before { content: fa-content(\f7a8\fe01); }
.fad.#{$fa-css-prefix}-hat-winter:after, .fa-duotone.#{$fa-css-prefix}-hat-winter:after { content: fa-content(\f7a8\fe02); }
.fad.#{$fa-css-prefix}-hat-witch:before, .fa-duotone.#{$fa-css-prefix}-hat-witch:before { content: fa-content(\f6e7\fe01); }
.fad.#{$fa-css-prefix}-hat-witch:after, .fa-duotone.#{$fa-css-prefix}-hat-witch:after { content: fa-content(\f6e7\fe02); }
.fad.#{$fa-css-prefix}-hat-wizard:before, .fa-duotone.#{$fa-css-prefix}-hat-wizard:before { content: fa-content(\f6e8\fe01); }
.fad.#{$fa-css-prefix}-hat-wizard:after, .fa-duotone.#{$fa-css-prefix}-hat-wizard:after { content: fa-content(\f6e8\fe02); }
.fad.#{$fa-css-prefix}-head-side:before, .fa-duotone.#{$fa-css-prefix}-head-side:before { content: fa-content(\f6e9\fe01); }
.fad.#{$fa-css-prefix}-head-side:after, .fa-duotone.#{$fa-css-prefix}-head-side:after { content: fa-content(\f6e9\fe02); }
.fad.#{$fa-css-prefix}-head-side-brain:before, .fa-duotone.#{$fa-css-prefix}-head-side-brain:before { content: fa-content(\f808\fe01); }
.fad.#{$fa-css-prefix}-head-side-brain:after, .fa-duotone.#{$fa-css-prefix}-head-side-brain:after { content: fa-content(\f808\fe02); }
.fad.#{$fa-css-prefix}-head-side-cough:before, .fa-duotone.#{$fa-css-prefix}-head-side-cough:before { content: fa-content(\e061\fe01); }
.fad.#{$fa-css-prefix}-head-side-cough:after, .fa-duotone.#{$fa-css-prefix}-head-side-cough:after { content: fa-content(\e061\fe02); }
.fad.#{$fa-css-prefix}-head-side-cough-slash:before, .fa-duotone.#{$fa-css-prefix}-head-side-cough-slash:before { content: fa-content(\e062\fe01); }
.fad.#{$fa-css-prefix}-head-side-cough-slash:after, .fa-duotone.#{$fa-css-prefix}-head-side-cough-slash:after { content: fa-content(\e062\fe02); }
.fad.#{$fa-css-prefix}-head-side-goggles:before, .fa-duotone.#{$fa-css-prefix}-head-side-goggles:before { content: fa-content(\f6ea\fe01); }
.fad.#{$fa-css-prefix}-head-side-goggles:after, .fa-duotone.#{$fa-css-prefix}-head-side-goggles:after { content: fa-content(\f6ea\fe02); }
.fad.#{$fa-css-prefix}-head-vr:before, .fa-duotone.#{$fa-css-prefix}-head-vr:before { content: fa-content(\f6ea\fe01); }
.fad.#{$fa-css-prefix}-head-vr:after, .fa-duotone.#{$fa-css-prefix}-head-vr:after { content: fa-content(\f6ea\fe02); }
.fad.#{$fa-css-prefix}-head-side-headphones:before, .fa-duotone.#{$fa-css-prefix}-head-side-headphones:before { content: fa-content(\f8c2\fe01); }
.fad.#{$fa-css-prefix}-head-side-headphones:after, .fa-duotone.#{$fa-css-prefix}-head-side-headphones:after { content: fa-content(\f8c2\fe02); }
.fad.#{$fa-css-prefix}-head-side-heart:before, .fa-duotone.#{$fa-css-prefix}-head-side-heart:before { content: fa-content(\e1aa\fe01); }
.fad.#{$fa-css-prefix}-head-side-heart:after, .fa-duotone.#{$fa-css-prefix}-head-side-heart:after { content: fa-content(\e1aa\fe02); }
.fad.#{$fa-css-prefix}-head-side-mask:before, .fa-duotone.#{$fa-css-prefix}-head-side-mask:before { content: fa-content(\e063\fe01); }
.fad.#{$fa-css-prefix}-head-side-mask:after, .fa-duotone.#{$fa-css-prefix}-head-side-mask:after { content: fa-content(\e063\fe02); }
.fad.#{$fa-css-prefix}-head-side-medical:before, .fa-duotone.#{$fa-css-prefix}-head-side-medical:before { content: fa-content(\f809\fe01); }
.fad.#{$fa-css-prefix}-head-side-medical:after, .fa-duotone.#{$fa-css-prefix}-head-side-medical:after { content: fa-content(\f809\fe02); }
.fad.#{$fa-css-prefix}-head-side-virus:before, .fa-duotone.#{$fa-css-prefix}-head-side-virus:before { content: fa-content(\e064\fe01); }
.fad.#{$fa-css-prefix}-head-side-virus:after, .fa-duotone.#{$fa-css-prefix}-head-side-virus:after { content: fa-content(\e064\fe02); }
.fad.#{$fa-css-prefix}-heading:before, .fa-duotone.#{$fa-css-prefix}-heading:before { content: fa-content(\f1dc\fe01); }
.fad.#{$fa-css-prefix}-heading:after, .fa-duotone.#{$fa-css-prefix}-heading:after { content: fa-content(\f1dc\fe02); }
.fad.#{$fa-css-prefix}-header:before, .fa-duotone.#{$fa-css-prefix}-header:before { content: fa-content(\f1dc\fe01); }
.fad.#{$fa-css-prefix}-header:after, .fa-duotone.#{$fa-css-prefix}-header:after { content: fa-content(\f1dc\fe02); }
.fad.#{$fa-css-prefix}-headphones:before, .fa-duotone.#{$fa-css-prefix}-headphones:before { content: fa-content(\f025); }
.fad.#{$fa-css-prefix}-headphones:after, .fa-duotone.#{$fa-css-prefix}-headphones:after { content: fa-content(\10f025); }
.fad.#{$fa-css-prefix}-headphones-simple:before, .fa-duotone.#{$fa-css-prefix}-headphones-simple:before { content: fa-content(\f58f\fe01); }
.fad.#{$fa-css-prefix}-headphones-simple:after, .fa-duotone.#{$fa-css-prefix}-headphones-simple:after { content: fa-content(\f58f\fe02); }
.fad.#{$fa-css-prefix}-headphones-alt:before, .fa-duotone.#{$fa-css-prefix}-headphones-alt:before { content: fa-content(\f58f\fe01); }
.fad.#{$fa-css-prefix}-headphones-alt:after, .fa-duotone.#{$fa-css-prefix}-headphones-alt:after { content: fa-content(\f58f\fe02); }
.fad.#{$fa-css-prefix}-headset:before, .fa-duotone.#{$fa-css-prefix}-headset:before { content: fa-content(\f590\fe01); }
.fad.#{$fa-css-prefix}-headset:after, .fa-duotone.#{$fa-css-prefix}-headset:after { content: fa-content(\f590\fe02); }
.fad.#{$fa-css-prefix}-heart:before, .fa-duotone.#{$fa-css-prefix}-heart:before { content: fa-content(\2665\fe01); }
.fad.#{$fa-css-prefix}-heart:after, .fa-duotone.#{$fa-css-prefix}-heart:after { content: fa-content(\2665\fe02); }
.fad.#{$fa-css-prefix}-heart-crack:before, .fa-duotone.#{$fa-css-prefix}-heart-crack:before { content: fa-content(\f7a9); }
.fad.#{$fa-css-prefix}-heart-crack:after, .fa-duotone.#{$fa-css-prefix}-heart-crack:after { content: fa-content(\10f7a9); }
.fad.#{$fa-css-prefix}-heart-broken:before, .fa-duotone.#{$fa-css-prefix}-heart-broken:before { content: fa-content(\f7a9); }
.fad.#{$fa-css-prefix}-heart-broken:after, .fa-duotone.#{$fa-css-prefix}-heart-broken:after { content: fa-content(\10f7a9); }
.fad.#{$fa-css-prefix}-heart-half:before, .fa-duotone.#{$fa-css-prefix}-heart-half:before { content: fa-content(\e1ab\fe01); }
.fad.#{$fa-css-prefix}-heart-half:after, .fa-duotone.#{$fa-css-prefix}-heart-half:after { content: fa-content(\e1ab\fe02); }
.fad.#{$fa-css-prefix}-heart-half-stroke:before, .fa-duotone.#{$fa-css-prefix}-heart-half-stroke:before { content: fa-content(\e1ac\fe01); }
.fad.#{$fa-css-prefix}-heart-half-stroke:after, .fa-duotone.#{$fa-css-prefix}-heart-half-stroke:after { content: fa-content(\e1ac\fe02); }
.fad.#{$fa-css-prefix}-heart-half-alt:before, .fa-duotone.#{$fa-css-prefix}-heart-half-alt:before { content: fa-content(\e1ac\fe01); }
.fad.#{$fa-css-prefix}-heart-half-alt:after, .fa-duotone.#{$fa-css-prefix}-heart-half-alt:after { content: fa-content(\e1ac\fe02); }
.fad.#{$fa-css-prefix}-heart-pulse:before, .fa-duotone.#{$fa-css-prefix}-heart-pulse:before { content: fa-content(\f21e\fe01); }
.fad.#{$fa-css-prefix}-heart-pulse:after, .fa-duotone.#{$fa-css-prefix}-heart-pulse:after { content: fa-content(\f21e\fe02); }
.fad.#{$fa-css-prefix}-heartbeat:before, .fa-duotone.#{$fa-css-prefix}-heartbeat:before { content: fa-content(\f21e\fe01); }
.fad.#{$fa-css-prefix}-heartbeat:after, .fa-duotone.#{$fa-css-prefix}-heartbeat:after { content: fa-content(\f21e\fe02); }
.fad.#{$fa-css-prefix}-heat:before, .fa-duotone.#{$fa-css-prefix}-heat:before { content: fa-content(\e00c\fe01); }
.fad.#{$fa-css-prefix}-heat:after, .fa-duotone.#{$fa-css-prefix}-heat:after { content: fa-content(\e00c\fe02); }
.fad.#{$fa-css-prefix}-helicopter:before, .fa-duotone.#{$fa-css-prefix}-helicopter:before { content: fa-content(\f533); }
.fad.#{$fa-css-prefix}-helicopter:after, .fa-duotone.#{$fa-css-prefix}-helicopter:after { content: fa-content(\10f533); }
.fad.#{$fa-css-prefix}-helmet-battle:before, .fa-duotone.#{$fa-css-prefix}-helmet-battle:before { content: fa-content(\f6eb\fe01); }
.fad.#{$fa-css-prefix}-helmet-battle:after, .fa-duotone.#{$fa-css-prefix}-helmet-battle:after { content: fa-content(\f6eb\fe02); }
.fad.#{$fa-css-prefix}-helmet-safety:before, .fa-duotone.#{$fa-css-prefix}-helmet-safety:before { content: fa-content(\f807\fe01); }
.fad.#{$fa-css-prefix}-helmet-safety:after, .fa-duotone.#{$fa-css-prefix}-helmet-safety:after { content: fa-content(\f807\fe02); }
.fad.#{$fa-css-prefix}-hard-hat:before, .fa-duotone.#{$fa-css-prefix}-hard-hat:before { content: fa-content(\f807\fe01); }
.fad.#{$fa-css-prefix}-hard-hat:after, .fa-duotone.#{$fa-css-prefix}-hard-hat:after { content: fa-content(\f807\fe02); }
.fad.#{$fa-css-prefix}-hat-hard:before, .fa-duotone.#{$fa-css-prefix}-hat-hard:before { content: fa-content(\f807\fe01); }
.fad.#{$fa-css-prefix}-hat-hard:after, .fa-duotone.#{$fa-css-prefix}-hat-hard:after { content: fa-content(\f807\fe02); }
.fad.#{$fa-css-prefix}-hexagon:before, .fa-duotone.#{$fa-css-prefix}-hexagon:before { content: fa-content(\2b23\fe01); }
.fad.#{$fa-css-prefix}-hexagon:after, .fa-duotone.#{$fa-css-prefix}-hexagon:after { content: fa-content(\2b23\fe02); }
.fad.#{$fa-css-prefix}-hexagon-divide:before, .fa-duotone.#{$fa-css-prefix}-hexagon-divide:before { content: fa-content(\e1ad\fe01); }
.fad.#{$fa-css-prefix}-hexagon-divide:after, .fa-duotone.#{$fa-css-prefix}-hexagon-divide:after { content: fa-content(\e1ad\fe02); }
.fad.#{$fa-css-prefix}-hexagon-minus:before, .fa-duotone.#{$fa-css-prefix}-hexagon-minus:before { content: fa-content(\f307\fe01); }
.fad.#{$fa-css-prefix}-hexagon-minus:after, .fa-duotone.#{$fa-css-prefix}-hexagon-minus:after { content: fa-content(\f307\fe02); }
.fad.#{$fa-css-prefix}-minus-hexagon:before, .fa-duotone.#{$fa-css-prefix}-minus-hexagon:before { content: fa-content(\f307\fe01); }
.fad.#{$fa-css-prefix}-minus-hexagon:after, .fa-duotone.#{$fa-css-prefix}-minus-hexagon:after { content: fa-content(\f307\fe02); }
.fad.#{$fa-css-prefix}-hexagon-plus:before, .fa-duotone.#{$fa-css-prefix}-hexagon-plus:before { content: fa-content(\f300\fe01); }
.fad.#{$fa-css-prefix}-hexagon-plus:after, .fa-duotone.#{$fa-css-prefix}-hexagon-plus:after { content: fa-content(\f300\fe02); }
.fad.#{$fa-css-prefix}-plus-hexagon:before, .fa-duotone.#{$fa-css-prefix}-plus-hexagon:before { content: fa-content(\f300\fe01); }
.fad.#{$fa-css-prefix}-plus-hexagon:after, .fa-duotone.#{$fa-css-prefix}-plus-hexagon:after { content: fa-content(\f300\fe02); }
.fad.#{$fa-css-prefix}-hexagon-xmark:before, .fa-duotone.#{$fa-css-prefix}-hexagon-xmark:before { content: fa-content(\f2ee\fe01); }
.fad.#{$fa-css-prefix}-hexagon-xmark:after, .fa-duotone.#{$fa-css-prefix}-hexagon-xmark:after { content: fa-content(\f2ee\fe02); }
.fad.#{$fa-css-prefix}-times-hexagon:before, .fa-duotone.#{$fa-css-prefix}-times-hexagon:before { content: fa-content(\f2ee\fe01); }
.fad.#{$fa-css-prefix}-times-hexagon:after, .fa-duotone.#{$fa-css-prefix}-times-hexagon:after { content: fa-content(\f2ee\fe02); }
.fad.#{$fa-css-prefix}-xmark-hexagon:before, .fa-duotone.#{$fa-css-prefix}-xmark-hexagon:before { content: fa-content(\f2ee\fe01); }
.fad.#{$fa-css-prefix}-xmark-hexagon:after, .fa-duotone.#{$fa-css-prefix}-xmark-hexagon:after { content: fa-content(\f2ee\fe02); }
.fad.#{$fa-css-prefix}-high-definition:before, .fa-duotone.#{$fa-css-prefix}-high-definition:before { content: fa-content(\e1ae\fe01); }
.fad.#{$fa-css-prefix}-high-definition:after, .fa-duotone.#{$fa-css-prefix}-high-definition:after { content: fa-content(\e1ae\fe02); }
.fad.#{$fa-css-prefix}-rectangle-hd:before, .fa-duotone.#{$fa-css-prefix}-rectangle-hd:before { content: fa-content(\e1ae\fe01); }
.fad.#{$fa-css-prefix}-rectangle-hd:after, .fa-duotone.#{$fa-css-prefix}-rectangle-hd:after { content: fa-content(\e1ae\fe02); }
.fad.#{$fa-css-prefix}-highlighter:before, .fa-duotone.#{$fa-css-prefix}-highlighter:before { content: fa-content(\f591\fe01); }
.fad.#{$fa-css-prefix}-highlighter:after, .fa-duotone.#{$fa-css-prefix}-highlighter:after { content: fa-content(\f591\fe02); }
.fad.#{$fa-css-prefix}-highlighter-line:before, .fa-duotone.#{$fa-css-prefix}-highlighter-line:before { content: fa-content(\e1af\fe01); }
.fad.#{$fa-css-prefix}-highlighter-line:after, .fa-duotone.#{$fa-css-prefix}-highlighter-line:after { content: fa-content(\e1af\fe02); }
.fad.#{$fa-css-prefix}-hippo:before, .fa-duotone.#{$fa-css-prefix}-hippo:before { content: fa-content(\f6ed); }
.fad.#{$fa-css-prefix}-hippo:after, .fa-duotone.#{$fa-css-prefix}-hippo:after { content: fa-content(\10f6ed); }
.fad.#{$fa-css-prefix}-hockey-mask:before, .fa-duotone.#{$fa-css-prefix}-hockey-mask:before { content: fa-content(\f6ee\fe01); }
.fad.#{$fa-css-prefix}-hockey-mask:after, .fa-duotone.#{$fa-css-prefix}-hockey-mask:after { content: fa-content(\f6ee\fe02); }
.fad.#{$fa-css-prefix}-hockey-puck:before, .fa-duotone.#{$fa-css-prefix}-hockey-puck:before { content: fa-content(\f453\fe01); }
.fad.#{$fa-css-prefix}-hockey-puck:after, .fa-duotone.#{$fa-css-prefix}-hockey-puck:after { content: fa-content(\f453\fe02); }
.fad.#{$fa-css-prefix}-hockey-stick-puck:before, .fa-duotone.#{$fa-css-prefix}-hockey-stick-puck:before { content: fa-content(\e3ae\fe01); }
.fad.#{$fa-css-prefix}-hockey-stick-puck:after, .fa-duotone.#{$fa-css-prefix}-hockey-stick-puck:after { content: fa-content(\e3ae\fe02); }
.fad.#{$fa-css-prefix}-hockey-sticks:before, .fa-duotone.#{$fa-css-prefix}-hockey-sticks:before { content: fa-content(\f454\fe01); }
.fad.#{$fa-css-prefix}-hockey-sticks:after, .fa-duotone.#{$fa-css-prefix}-hockey-sticks:after { content: fa-content(\f454\fe02); }
.fad.#{$fa-css-prefix}-holly-berry:before, .fa-duotone.#{$fa-css-prefix}-holly-berry:before { content: fa-content(\f7aa\fe01); }
.fad.#{$fa-css-prefix}-holly-berry:after, .fa-duotone.#{$fa-css-prefix}-holly-berry:after { content: fa-content(\f7aa\fe02); }
.fad.#{$fa-css-prefix}-hood-cloak:before, .fa-duotone.#{$fa-css-prefix}-hood-cloak:before { content: fa-content(\f6ef\fe01); }
.fad.#{$fa-css-prefix}-hood-cloak:after, .fa-duotone.#{$fa-css-prefix}-hood-cloak:after { content: fa-content(\f6ef\fe02); }
.fad.#{$fa-css-prefix}-horizontal-rule:before, .fa-duotone.#{$fa-css-prefix}-horizontal-rule:before { content: fa-content(\2015\fe01); }
.fad.#{$fa-css-prefix}-horizontal-rule:after, .fa-duotone.#{$fa-css-prefix}-horizontal-rule:after { content: fa-content(\2015\fe02); }
.fad.#{$fa-css-prefix}-horse:before, .fa-duotone.#{$fa-css-prefix}-horse:before { content: fa-content(\f6f0); }
.fad.#{$fa-css-prefix}-horse:after, .fa-duotone.#{$fa-css-prefix}-horse:after { content: fa-content(\10f6f0); }
.fad.#{$fa-css-prefix}-horse-head:before, .fa-duotone.#{$fa-css-prefix}-horse-head:before { content: fa-content(\f7ab\fe01); }
.fad.#{$fa-css-prefix}-horse-head:after, .fa-duotone.#{$fa-css-prefix}-horse-head:after { content: fa-content(\f7ab\fe02); }
.fad.#{$fa-css-prefix}-horse-saddle:before, .fa-duotone.#{$fa-css-prefix}-horse-saddle:before { content: fa-content(\f8c3\fe01); }
.fad.#{$fa-css-prefix}-horse-saddle:after, .fa-duotone.#{$fa-css-prefix}-horse-saddle:after { content: fa-content(\f8c3\fe02); }
.fad.#{$fa-css-prefix}-hospital:before, .fa-duotone.#{$fa-css-prefix}-hospital:before { content: fa-content(\f0f8); }
.fad.#{$fa-css-prefix}-hospital:after, .fa-duotone.#{$fa-css-prefix}-hospital:after { content: fa-content(\10f0f8); }
.fad.#{$fa-css-prefix}-hospital-user:before, .fa-duotone.#{$fa-css-prefix}-hospital-user:before { content: fa-content(\f80d\fe01); }
.fad.#{$fa-css-prefix}-hospital-user:after, .fa-duotone.#{$fa-css-prefix}-hospital-user:after { content: fa-content(\f80d\fe02); }
.fad.#{$fa-css-prefix}-hospital-wide:before, .fa-duotone.#{$fa-css-prefix}-hospital-wide:before { content: fa-content(\f47d\fe01); }
.fad.#{$fa-css-prefix}-hospital-wide:after, .fa-duotone.#{$fa-css-prefix}-hospital-wide:after { content: fa-content(\f47d\fe02); }
.fad.#{$fa-css-prefix}-hospital-alt:before, .fa-duotone.#{$fa-css-prefix}-hospital-alt:before { content: fa-content(\f47d\fe01); }
.fad.#{$fa-css-prefix}-hospital-alt:after, .fa-duotone.#{$fa-css-prefix}-hospital-alt:after { content: fa-content(\f47d\fe02); }
.fad.#{$fa-css-prefix}-hospitals:before, .fa-duotone.#{$fa-css-prefix}-hospitals:before { content: fa-content(\f80e\fe01); }
.fad.#{$fa-css-prefix}-hospitals:after, .fa-duotone.#{$fa-css-prefix}-hospitals:after { content: fa-content(\f80e\fe02); }
.fad.#{$fa-css-prefix}-hot-tub-person:before, .fa-duotone.#{$fa-css-prefix}-hot-tub-person:before { content: fa-content(\f593\fe01); }
.fad.#{$fa-css-prefix}-hot-tub-person:after, .fa-duotone.#{$fa-css-prefix}-hot-tub-person:after { content: fa-content(\f593\fe02); }
.fad.#{$fa-css-prefix}-hot-tub:before, .fa-duotone.#{$fa-css-prefix}-hot-tub:before { content: fa-content(\f593\fe01); }
.fad.#{$fa-css-prefix}-hot-tub:after, .fa-duotone.#{$fa-css-prefix}-hot-tub:after { content: fa-content(\f593\fe02); }
.fad.#{$fa-css-prefix}-hotdog:before, .fa-duotone.#{$fa-css-prefix}-hotdog:before { content: fa-content(\f80f); }
.fad.#{$fa-css-prefix}-hotdog:after, .fa-duotone.#{$fa-css-prefix}-hotdog:after { content: fa-content(\10f80f); }
.fad.#{$fa-css-prefix}-hotel:before, .fa-duotone.#{$fa-css-prefix}-hotel:before { content: fa-content(\f594); }
.fad.#{$fa-css-prefix}-hotel:after, .fa-duotone.#{$fa-css-prefix}-hotel:after { content: fa-content(\10f594); }
.fad.#{$fa-css-prefix}-hourglass:before, .fa-duotone.#{$fa-css-prefix}-hourglass:before { content: fa-content(\23f3\fe01); }
.fad.#{$fa-css-prefix}-hourglass:after, .fa-duotone.#{$fa-css-prefix}-hourglass:after { content: fa-content(\23f3\fe02); }
.fad.#{$fa-css-prefix}-hourglass-2:before, .fa-duotone.#{$fa-css-prefix}-hourglass-2:before { content: fa-content(\23f3\fe01); }
.fad.#{$fa-css-prefix}-hourglass-2:after, .fa-duotone.#{$fa-css-prefix}-hourglass-2:after { content: fa-content(\23f3\fe02); }
.fad.#{$fa-css-prefix}-hourglass-half:before, .fa-duotone.#{$fa-css-prefix}-hourglass-half:before { content: fa-content(\23f3\fe01); }
.fad.#{$fa-css-prefix}-hourglass-half:after, .fa-duotone.#{$fa-css-prefix}-hourglass-half:after { content: fa-content(\23f3\fe02); }
.fad.#{$fa-css-prefix}-hourglass-empty:before, .fa-duotone.#{$fa-css-prefix}-hourglass-empty:before { content: fa-content(\f252\fe01); }
.fad.#{$fa-css-prefix}-hourglass-empty:after, .fa-duotone.#{$fa-css-prefix}-hourglass-empty:after { content: fa-content(\f252\fe02); }
.fad.#{$fa-css-prefix}-hourglass-end:before, .fa-duotone.#{$fa-css-prefix}-hourglass-end:before { content: fa-content(\231b\fe01); }
.fad.#{$fa-css-prefix}-hourglass-end:after, .fa-duotone.#{$fa-css-prefix}-hourglass-end:after { content: fa-content(\231b\fe02); }
.fad.#{$fa-css-prefix}-hourglass-3:before, .fa-duotone.#{$fa-css-prefix}-hourglass-3:before { content: fa-content(\231b\fe01); }
.fad.#{$fa-css-prefix}-hourglass-3:after, .fa-duotone.#{$fa-css-prefix}-hourglass-3:after { content: fa-content(\231b\fe02); }
.fad.#{$fa-css-prefix}-hourglass-start:before, .fa-duotone.#{$fa-css-prefix}-hourglass-start:before { content: fa-content(\f251\fe01); }
.fad.#{$fa-css-prefix}-hourglass-start:after, .fa-duotone.#{$fa-css-prefix}-hourglass-start:after { content: fa-content(\f251\fe02); }
.fad.#{$fa-css-prefix}-hourglass-1:before, .fa-duotone.#{$fa-css-prefix}-hourglass-1:before { content: fa-content(\f251\fe01); }
.fad.#{$fa-css-prefix}-hourglass-1:after, .fa-duotone.#{$fa-css-prefix}-hourglass-1:after { content: fa-content(\f251\fe02); }
.fad.#{$fa-css-prefix}-house:before, .fa-duotone.#{$fa-css-prefix}-house:before { content: fa-content(\f015); }
.fad.#{$fa-css-prefix}-house:after, .fa-duotone.#{$fa-css-prefix}-house:after { content: fa-content(\10f015); }
.fad.#{$fa-css-prefix}-home:before, .fa-duotone.#{$fa-css-prefix}-home:before { content: fa-content(\f015); }
.fad.#{$fa-css-prefix}-home:after, .fa-duotone.#{$fa-css-prefix}-home:after { content: fa-content(\10f015); }
.fad.#{$fa-css-prefix}-house-blank:before, .fa-duotone.#{$fa-css-prefix}-house-blank:before { content: fa-content(\f80a\fe01); }
.fad.#{$fa-css-prefix}-house-blank:after, .fa-duotone.#{$fa-css-prefix}-house-blank:after { content: fa-content(\f80a\fe02); }
.fad.#{$fa-css-prefix}-home-blank:before, .fa-duotone.#{$fa-css-prefix}-home-blank:before { content: fa-content(\f80a\fe01); }
.fad.#{$fa-css-prefix}-home-blank:after, .fa-duotone.#{$fa-css-prefix}-home-blank:after { content: fa-content(\f80a\fe02); }
.fad.#{$fa-css-prefix}-home-lg-alt:before, .fa-duotone.#{$fa-css-prefix}-home-lg-alt:before { content: fa-content(\f80a\fe01); }
.fad.#{$fa-css-prefix}-home-lg-alt:after, .fa-duotone.#{$fa-css-prefix}-home-lg-alt:after { content: fa-content(\f80a\fe02); }
.fad.#{$fa-css-prefix}-house-building:before, .fa-duotone.#{$fa-css-prefix}-house-building:before { content: fa-content(\e1b1\fe01); }
.fad.#{$fa-css-prefix}-house-building:after, .fa-duotone.#{$fa-css-prefix}-house-building:after { content: fa-content(\e1b1\fe02); }
.fad.#{$fa-css-prefix}-house-chimney:before, .fa-duotone.#{$fa-css-prefix}-house-chimney:before { content: fa-content(\e3af\fe01); }
.fad.#{$fa-css-prefix}-house-chimney:after, .fa-duotone.#{$fa-css-prefix}-house-chimney:after { content: fa-content(\e3af\fe02); }
.fad.#{$fa-css-prefix}-home-lg:before, .fa-duotone.#{$fa-css-prefix}-home-lg:before { content: fa-content(\e3af\fe01); }
.fad.#{$fa-css-prefix}-home-lg:after, .fa-duotone.#{$fa-css-prefix}-home-lg:after { content: fa-content(\e3af\fe02); }
.fad.#{$fa-css-prefix}-house-chimney-blank:before, .fa-duotone.#{$fa-css-prefix}-house-chimney-blank:before { content: fa-content(\e3b0\fe01); }
.fad.#{$fa-css-prefix}-house-chimney-blank:after, .fa-duotone.#{$fa-css-prefix}-house-chimney-blank:after { content: fa-content(\e3b0\fe02); }
.fad.#{$fa-css-prefix}-house-chimney-crack:before, .fa-duotone.#{$fa-css-prefix}-house-chimney-crack:before { content: fa-content(\f6f1\fe01); }
.fad.#{$fa-css-prefix}-house-chimney-crack:after, .fa-duotone.#{$fa-css-prefix}-house-chimney-crack:after { content: fa-content(\f6f1\fe02); }
.fad.#{$fa-css-prefix}-house-damage:before, .fa-duotone.#{$fa-css-prefix}-house-damage:before { content: fa-content(\f6f1\fe01); }
.fad.#{$fa-css-prefix}-house-damage:after, .fa-duotone.#{$fa-css-prefix}-house-damage:after { content: fa-content(\f6f1\fe02); }
.fad.#{$fa-css-prefix}-house-chimney-heart:before, .fa-duotone.#{$fa-css-prefix}-house-chimney-heart:before { content: fa-content(\e1b2\fe01); }
.fad.#{$fa-css-prefix}-house-chimney-heart:after, .fa-duotone.#{$fa-css-prefix}-house-chimney-heart:after { content: fa-content(\e1b2\fe02); }
.fad.#{$fa-css-prefix}-house-chimney-medical:before, .fa-duotone.#{$fa-css-prefix}-house-chimney-medical:before { content: fa-content(\f7f2\fe01); }
.fad.#{$fa-css-prefix}-house-chimney-medical:after, .fa-duotone.#{$fa-css-prefix}-house-chimney-medical:after { content: fa-content(\f7f2\fe02); }
.fad.#{$fa-css-prefix}-clinic-medical:before, .fa-duotone.#{$fa-css-prefix}-clinic-medical:before { content: fa-content(\f7f2\fe01); }
.fad.#{$fa-css-prefix}-clinic-medical:after, .fa-duotone.#{$fa-css-prefix}-clinic-medical:after { content: fa-content(\f7f2\fe02); }
.fad.#{$fa-css-prefix}-house-chimney-user:before, .fa-duotone.#{$fa-css-prefix}-house-chimney-user:before { content: fa-content(\e065\fe01); }
.fad.#{$fa-css-prefix}-house-chimney-user:after, .fa-duotone.#{$fa-css-prefix}-house-chimney-user:after { content: fa-content(\e065\fe02); }
.fad.#{$fa-css-prefix}-house-chimney-window:before, .fa-duotone.#{$fa-css-prefix}-house-chimney-window:before { content: fa-content(\e00d\fe01); }
.fad.#{$fa-css-prefix}-house-chimney-window:after, .fa-duotone.#{$fa-css-prefix}-house-chimney-window:after { content: fa-content(\e00d\fe02); }
.fad.#{$fa-css-prefix}-house-crack:before, .fa-duotone.#{$fa-css-prefix}-house-crack:before { content: fa-content(\e3b1\fe01); }
.fad.#{$fa-css-prefix}-house-crack:after, .fa-duotone.#{$fa-css-prefix}-house-crack:after { content: fa-content(\e3b1\fe02); }
.fad.#{$fa-css-prefix}-house-day:before, .fa-duotone.#{$fa-css-prefix}-house-day:before { content: fa-content(\e00e\fe01); }
.fad.#{$fa-css-prefix}-house-day:after, .fa-duotone.#{$fa-css-prefix}-house-day:after { content: fa-content(\e00e\fe02); }
.fad.#{$fa-css-prefix}-house-flood:before, .fa-duotone.#{$fa-css-prefix}-house-flood:before { content: fa-content(\f74f\fe01); }
.fad.#{$fa-css-prefix}-house-flood:after, .fa-duotone.#{$fa-css-prefix}-house-flood:after { content: fa-content(\f74f\fe02); }
.fad.#{$fa-css-prefix}-house-heart:before, .fa-duotone.#{$fa-css-prefix}-house-heart:before { content: fa-content(\f4c9\fe01); }
.fad.#{$fa-css-prefix}-house-heart:after, .fa-duotone.#{$fa-css-prefix}-house-heart:after { content: fa-content(\f4c9\fe02); }
.fad.#{$fa-css-prefix}-home-heart:before, .fa-duotone.#{$fa-css-prefix}-home-heart:before { content: fa-content(\f4c9\fe01); }
.fad.#{$fa-css-prefix}-home-heart:after, .fa-duotone.#{$fa-css-prefix}-home-heart:after { content: fa-content(\f4c9\fe02); }
.fad.#{$fa-css-prefix}-house-laptop:before, .fa-duotone.#{$fa-css-prefix}-house-laptop:before { content: fa-content(\e066\fe01); }
.fad.#{$fa-css-prefix}-house-laptop:after, .fa-duotone.#{$fa-css-prefix}-house-laptop:after { content: fa-content(\e066\fe02); }
.fad.#{$fa-css-prefix}-laptop-house:before, .fa-duotone.#{$fa-css-prefix}-laptop-house:before { content: fa-content(\e066\fe01); }
.fad.#{$fa-css-prefix}-laptop-house:after, .fa-duotone.#{$fa-css-prefix}-laptop-house:after { content: fa-content(\e066\fe02); }
.fad.#{$fa-css-prefix}-house-medical:before, .fa-duotone.#{$fa-css-prefix}-house-medical:before { content: fa-content(\e3b2\fe01); }
.fad.#{$fa-css-prefix}-house-medical:after, .fa-duotone.#{$fa-css-prefix}-house-medical:after { content: fa-content(\e3b2\fe02); }
.fad.#{$fa-css-prefix}-house-night:before, .fa-duotone.#{$fa-css-prefix}-house-night:before { content: fa-content(\e010\fe01); }
.fad.#{$fa-css-prefix}-house-night:after, .fa-duotone.#{$fa-css-prefix}-house-night:after { content: fa-content(\e010\fe02); }
.fad.#{$fa-css-prefix}-house-person-leave:before, .fa-duotone.#{$fa-css-prefix}-house-person-leave:before { content: fa-content(\e00f\fe01); }
.fad.#{$fa-css-prefix}-house-person-leave:after, .fa-duotone.#{$fa-css-prefix}-house-person-leave:after { content: fa-content(\e00f\fe02); }
.fad.#{$fa-css-prefix}-house-person-depart:before, .fa-duotone.#{$fa-css-prefix}-house-person-depart:before { content: fa-content(\e00f\fe01); }
.fad.#{$fa-css-prefix}-house-person-depart:after, .fa-duotone.#{$fa-css-prefix}-house-person-depart:after { content: fa-content(\e00f\fe02); }
.fad.#{$fa-css-prefix}-house-person-return:before, .fa-duotone.#{$fa-css-prefix}-house-person-return:before { content: fa-content(\e011\fe01); }
.fad.#{$fa-css-prefix}-house-person-return:after, .fa-duotone.#{$fa-css-prefix}-house-person-return:after { content: fa-content(\e011\fe02); }
.fad.#{$fa-css-prefix}-house-person-arrive:before, .fa-duotone.#{$fa-css-prefix}-house-person-arrive:before { content: fa-content(\e011\fe01); }
.fad.#{$fa-css-prefix}-house-person-arrive:after, .fa-duotone.#{$fa-css-prefix}-house-person-arrive:after { content: fa-content(\e011\fe02); }
.fad.#{$fa-css-prefix}-house-signal:before, .fa-duotone.#{$fa-css-prefix}-house-signal:before { content: fa-content(\e012\fe01); }
.fad.#{$fa-css-prefix}-house-signal:after, .fa-duotone.#{$fa-css-prefix}-house-signal:after { content: fa-content(\e012\fe02); }
.fad.#{$fa-css-prefix}-house-tree:before, .fa-duotone.#{$fa-css-prefix}-house-tree:before { content: fa-content(\e1b3\fe01); }
.fad.#{$fa-css-prefix}-house-tree:after, .fa-duotone.#{$fa-css-prefix}-house-tree:after { content: fa-content(\e1b3\fe02); }
.fad.#{$fa-css-prefix}-house-turret:before, .fa-duotone.#{$fa-css-prefix}-house-turret:before { content: fa-content(\e1b4\fe01); }
.fad.#{$fa-css-prefix}-house-turret:after, .fa-duotone.#{$fa-css-prefix}-house-turret:after { content: fa-content(\e1b4\fe02); }
.fad.#{$fa-css-prefix}-house-user:before, .fa-duotone.#{$fa-css-prefix}-house-user:before { content: fa-content(\e1b0\fe01); }
.fad.#{$fa-css-prefix}-house-user:after, .fa-duotone.#{$fa-css-prefix}-house-user:after { content: fa-content(\e1b0\fe02); }
.fad.#{$fa-css-prefix}-home-user:before, .fa-duotone.#{$fa-css-prefix}-home-user:before { content: fa-content(\e1b0\fe01); }
.fad.#{$fa-css-prefix}-home-user:after, .fa-duotone.#{$fa-css-prefix}-home-user:after { content: fa-content(\e1b0\fe02); }
.fad.#{$fa-css-prefix}-house-window:before, .fa-duotone.#{$fa-css-prefix}-house-window:before { content: fa-content(\e3b3\fe01); }
.fad.#{$fa-css-prefix}-house-window:after, .fa-duotone.#{$fa-css-prefix}-house-window:after { content: fa-content(\e3b3\fe02); }
.fad.#{$fa-css-prefix}-hryvnia-sign:before, .fa-duotone.#{$fa-css-prefix}-hryvnia-sign:before { content: fa-content(\20b4\fe01); }
.fad.#{$fa-css-prefix}-hryvnia-sign:after, .fa-duotone.#{$fa-css-prefix}-hryvnia-sign:after { content: fa-content(\20b4\fe02); }
.fad.#{$fa-css-prefix}-hryvnia:before, .fa-duotone.#{$fa-css-prefix}-hryvnia:before { content: fa-content(\20b4\fe01); }
.fad.#{$fa-css-prefix}-hryvnia:after, .fa-duotone.#{$fa-css-prefix}-hryvnia:after { content: fa-content(\20b4\fe02); }
.fad.#{$fa-css-prefix}-hurricane:before, .fa-duotone.#{$fa-css-prefix}-hurricane:before { content: fa-content(\f751\fe01); }
.fad.#{$fa-css-prefix}-hurricane:after, .fa-duotone.#{$fa-css-prefix}-hurricane:after { content: fa-content(\f751\fe02); }
.fad.#{$fa-css-prefix}-hyphen:before, .fa-duotone.#{$fa-css-prefix}-hyphen:before { content: fa-content(\2d\fe01); }
.fad.#{$fa-css-prefix}-hyphen:after, .fa-duotone.#{$fa-css-prefix}-hyphen:after { content: fa-content(\2d\fe02); }
.fad.#{$fa-css-prefix}-i:before, .fa-duotone.#{$fa-css-prefix}-i:before { content: fa-content(\49\fe01); }
.fad.#{$fa-css-prefix}-i:after, .fa-duotone.#{$fa-css-prefix}-i:after { content: fa-content(\49\fe02); }
.fad.#{$fa-css-prefix}-i-cursor:before, .fa-duotone.#{$fa-css-prefix}-i-cursor:before { content: fa-content(\f246\fe01); }
.fad.#{$fa-css-prefix}-i-cursor:after, .fa-duotone.#{$fa-css-prefix}-i-cursor:after { content: fa-content(\f246\fe02); }
.fad.#{$fa-css-prefix}-ice-cream:before, .fa-duotone.#{$fa-css-prefix}-ice-cream:before { content: fa-content(\f810); }
.fad.#{$fa-css-prefix}-ice-cream:after, .fa-duotone.#{$fa-css-prefix}-ice-cream:after { content: fa-content(\10f810); }
.fad.#{$fa-css-prefix}-ice-skate:before, .fa-duotone.#{$fa-css-prefix}-ice-skate:before { content: fa-content(\26f8\fe01); }
.fad.#{$fa-css-prefix}-ice-skate:after, .fa-duotone.#{$fa-css-prefix}-ice-skate:after { content: fa-content(\26f8\fe02); }
.fad.#{$fa-css-prefix}-icicles:before, .fa-duotone.#{$fa-css-prefix}-icicles:before { content: fa-content(\f7ad\fe01); }
.fad.#{$fa-css-prefix}-icicles:after, .fa-duotone.#{$fa-css-prefix}-icicles:after { content: fa-content(\f7ad\fe02); }
.fad.#{$fa-css-prefix}-icons:before, .fa-duotone.#{$fa-css-prefix}-icons:before { content: fa-content(\f86d\fe01); }
.fad.#{$fa-css-prefix}-icons:after, .fa-duotone.#{$fa-css-prefix}-icons:after { content: fa-content(\f86d\fe02); }
.fad.#{$fa-css-prefix}-heart-music-camera-bolt:before, .fa-duotone.#{$fa-css-prefix}-heart-music-camera-bolt:before { content: fa-content(\f86d\fe01); }
.fad.#{$fa-css-prefix}-heart-music-camera-bolt:after, .fa-duotone.#{$fa-css-prefix}-heart-music-camera-bolt:after { content: fa-content(\f86d\fe02); }
.fad.#{$fa-css-prefix}-id-badge:before, .fa-duotone.#{$fa-css-prefix}-id-badge:before { content: fa-content(\f2c1\fe01); }
.fad.#{$fa-css-prefix}-id-badge:after, .fa-duotone.#{$fa-css-prefix}-id-badge:after { content: fa-content(\f2c1\fe02); }
.fad.#{$fa-css-prefix}-id-card:before, .fa-duotone.#{$fa-css-prefix}-id-card:before { content: fa-content(\f2c2\fe01); }
.fad.#{$fa-css-prefix}-id-card:after, .fa-duotone.#{$fa-css-prefix}-id-card:after { content: fa-content(\f2c2\fe02); }
.fad.#{$fa-css-prefix}-drivers-license:before, .fa-duotone.#{$fa-css-prefix}-drivers-license:before { content: fa-content(\f2c2\fe01); }
.fad.#{$fa-css-prefix}-drivers-license:after, .fa-duotone.#{$fa-css-prefix}-drivers-license:after { content: fa-content(\f2c2\fe02); }
.fad.#{$fa-css-prefix}-id-card-clip:before, .fa-duotone.#{$fa-css-prefix}-id-card-clip:before { content: fa-content(\f47f\fe01); }
.fad.#{$fa-css-prefix}-id-card-clip:after, .fa-duotone.#{$fa-css-prefix}-id-card-clip:after { content: fa-content(\f47f\fe02); }
.fad.#{$fa-css-prefix}-id-card-alt:before, .fa-duotone.#{$fa-css-prefix}-id-card-alt:before { content: fa-content(\f47f\fe01); }
.fad.#{$fa-css-prefix}-id-card-alt:after, .fa-duotone.#{$fa-css-prefix}-id-card-alt:after { content: fa-content(\f47f\fe02); }
.fad.#{$fa-css-prefix}-igloo:before, .fa-duotone.#{$fa-css-prefix}-igloo:before { content: fa-content(\f7ae\fe01); }
.fad.#{$fa-css-prefix}-igloo:after, .fa-duotone.#{$fa-css-prefix}-igloo:after { content: fa-content(\f7ae\fe02); }
.fad.#{$fa-css-prefix}-image:before, .fa-duotone.#{$fa-css-prefix}-image:before { content: fa-content(\f03e\fe01); }
.fad.#{$fa-css-prefix}-image:after, .fa-duotone.#{$fa-css-prefix}-image:after { content: fa-content(\f03e\fe02); }
.fad.#{$fa-css-prefix}-image-landscape:before, .fa-duotone.#{$fa-css-prefix}-image-landscape:before { content: fa-content(\e1b5\fe01); }
.fad.#{$fa-css-prefix}-image-landscape:after, .fa-duotone.#{$fa-css-prefix}-image-landscape:after { content: fa-content(\e1b5\fe02); }
.fad.#{$fa-css-prefix}-landscape:before, .fa-duotone.#{$fa-css-prefix}-landscape:before { content: fa-content(\e1b5\fe01); }
.fad.#{$fa-css-prefix}-landscape:after, .fa-duotone.#{$fa-css-prefix}-landscape:after { content: fa-content(\e1b5\fe02); }
.fad.#{$fa-css-prefix}-image-polaroid:before, .fa-duotone.#{$fa-css-prefix}-image-polaroid:before { content: fa-content(\f8c4\fe01); }
.fad.#{$fa-css-prefix}-image-polaroid:after, .fa-duotone.#{$fa-css-prefix}-image-polaroid:after { content: fa-content(\f8c4\fe02); }
.fad.#{$fa-css-prefix}-image-polaroid-user:before, .fa-duotone.#{$fa-css-prefix}-image-polaroid-user:before { content: fa-content(\e1b6\fe01); }
.fad.#{$fa-css-prefix}-image-polaroid-user:after, .fa-duotone.#{$fa-css-prefix}-image-polaroid-user:after { content: fa-content(\e1b6\fe02); }
.fad.#{$fa-css-prefix}-image-portrait:before, .fa-duotone.#{$fa-css-prefix}-image-portrait:before { content: fa-content(\f3e0\fe01); }
.fad.#{$fa-css-prefix}-image-portrait:after, .fa-duotone.#{$fa-css-prefix}-image-portrait:after { content: fa-content(\f3e0\fe02); }
.fad.#{$fa-css-prefix}-portrait:before, .fa-duotone.#{$fa-css-prefix}-portrait:before { content: fa-content(\f3e0\fe01); }
.fad.#{$fa-css-prefix}-portrait:after, .fa-duotone.#{$fa-css-prefix}-portrait:after { content: fa-content(\f3e0\fe02); }
.fad.#{$fa-css-prefix}-image-slash:before, .fa-duotone.#{$fa-css-prefix}-image-slash:before { content: fa-content(\e1b7\fe01); }
.fad.#{$fa-css-prefix}-image-slash:after, .fa-duotone.#{$fa-css-prefix}-image-slash:after { content: fa-content(\e1b7\fe02); }
.fad.#{$fa-css-prefix}-image-user:before, .fa-duotone.#{$fa-css-prefix}-image-user:before { content: fa-content(\e1b8\fe01); }
.fad.#{$fa-css-prefix}-image-user:after, .fa-duotone.#{$fa-css-prefix}-image-user:after { content: fa-content(\e1b8\fe02); }
.fad.#{$fa-css-prefix}-images:before, .fa-duotone.#{$fa-css-prefix}-images:before { content: fa-content(\f302\fe01); }
.fad.#{$fa-css-prefix}-images:after, .fa-duotone.#{$fa-css-prefix}-images:after { content: fa-content(\f302\fe02); }
.fad.#{$fa-css-prefix}-images-user:before, .fa-duotone.#{$fa-css-prefix}-images-user:before { content: fa-content(\e1b9\fe01); }
.fad.#{$fa-css-prefix}-images-user:after, .fa-duotone.#{$fa-css-prefix}-images-user:after { content: fa-content(\e1b9\fe02); }
.fad.#{$fa-css-prefix}-inbox:before, .fa-duotone.#{$fa-css-prefix}-inbox:before { content: fa-content(\f01c\fe01); }
.fad.#{$fa-css-prefix}-inbox:after, .fa-duotone.#{$fa-css-prefix}-inbox:after { content: fa-content(\f01c\fe02); }
.fad.#{$fa-css-prefix}-inbox-full:before, .fa-duotone.#{$fa-css-prefix}-inbox-full:before { content: fa-content(\e1ba\fe01); }
.fad.#{$fa-css-prefix}-inbox-full:after, .fa-duotone.#{$fa-css-prefix}-inbox-full:after { content: fa-content(\e1ba\fe02); }
.fad.#{$fa-css-prefix}-inbox-in:before, .fa-duotone.#{$fa-css-prefix}-inbox-in:before { content: fa-content(\f310); }
.fad.#{$fa-css-prefix}-inbox-in:after, .fa-duotone.#{$fa-css-prefix}-inbox-in:after { content: fa-content(\10f310); }
.fad.#{$fa-css-prefix}-inbox-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-inbox-arrow-down:before { content: fa-content(\f310); }
.fad.#{$fa-css-prefix}-inbox-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-inbox-arrow-down:after { content: fa-content(\10f310); }
.fad.#{$fa-css-prefix}-inbox-out:before, .fa-duotone.#{$fa-css-prefix}-inbox-out:before { content: fa-content(\f311); }
.fad.#{$fa-css-prefix}-inbox-out:after, .fa-duotone.#{$fa-css-prefix}-inbox-out:after { content: fa-content(\10f311); }
.fad.#{$fa-css-prefix}-inbox-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-inbox-arrow-up:before { content: fa-content(\f311); }
.fad.#{$fa-css-prefix}-inbox-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-inbox-arrow-up:after { content: fa-content(\10f311); }
.fad.#{$fa-css-prefix}-inboxes:before, .fa-duotone.#{$fa-css-prefix}-inboxes:before { content: fa-content(\e1bb\fe01); }
.fad.#{$fa-css-prefix}-inboxes:after, .fa-duotone.#{$fa-css-prefix}-inboxes:after { content: fa-content(\e1bb\fe02); }
.fad.#{$fa-css-prefix}-indent:before, .fa-duotone.#{$fa-css-prefix}-indent:before { content: fa-content(\f03c\fe01); }
.fad.#{$fa-css-prefix}-indent:after, .fa-duotone.#{$fa-css-prefix}-indent:after { content: fa-content(\f03c\fe02); }
.fad.#{$fa-css-prefix}-indian-rupee-sign:before, .fa-duotone.#{$fa-css-prefix}-indian-rupee-sign:before { content: fa-content(\e1bc\fe01); }
.fad.#{$fa-css-prefix}-indian-rupee-sign:after, .fa-duotone.#{$fa-css-prefix}-indian-rupee-sign:after { content: fa-content(\e1bc\fe02); }
.fad.#{$fa-css-prefix}-indian-rupee:before, .fa-duotone.#{$fa-css-prefix}-indian-rupee:before { content: fa-content(\e1bc\fe01); }
.fad.#{$fa-css-prefix}-indian-rupee:after, .fa-duotone.#{$fa-css-prefix}-indian-rupee:after { content: fa-content(\e1bc\fe02); }
.fad.#{$fa-css-prefix}-inr:before, .fa-duotone.#{$fa-css-prefix}-inr:before { content: fa-content(\e1bc\fe01); }
.fad.#{$fa-css-prefix}-inr:after, .fa-duotone.#{$fa-css-prefix}-inr:after { content: fa-content(\e1bc\fe02); }
.fad.#{$fa-css-prefix}-industry:before, .fa-duotone.#{$fa-css-prefix}-industry:before { content: fa-content(\f275\fe01); }
.fad.#{$fa-css-prefix}-industry:after, .fa-duotone.#{$fa-css-prefix}-industry:after { content: fa-content(\f275\fe02); }
.fad.#{$fa-css-prefix}-industry-windows:before, .fa-duotone.#{$fa-css-prefix}-industry-windows:before { content: fa-content(\f3b3); }
.fad.#{$fa-css-prefix}-industry-windows:after, .fa-duotone.#{$fa-css-prefix}-industry-windows:after { content: fa-content(\10f3b3); }
.fad.#{$fa-css-prefix}-industry-alt:before, .fa-duotone.#{$fa-css-prefix}-industry-alt:before { content: fa-content(\f3b3); }
.fad.#{$fa-css-prefix}-industry-alt:after, .fa-duotone.#{$fa-css-prefix}-industry-alt:after { content: fa-content(\10f3b3); }
.fad.#{$fa-css-prefix}-infinity:before, .fa-duotone.#{$fa-css-prefix}-infinity:before { content: fa-content(\221e\fe01); }
.fad.#{$fa-css-prefix}-infinity:after, .fa-duotone.#{$fa-css-prefix}-infinity:after { content: fa-content(\221e\fe02); }
.fad.#{$fa-css-prefix}-info:before, .fa-duotone.#{$fa-css-prefix}-info:before { content: fa-content(\f129\fe01); }
.fad.#{$fa-css-prefix}-info:after, .fa-duotone.#{$fa-css-prefix}-info:after { content: fa-content(\f129\fe02); }
.fad.#{$fa-css-prefix}-inhaler:before, .fa-duotone.#{$fa-css-prefix}-inhaler:before { content: fa-content(\f5f9\fe01); }
.fad.#{$fa-css-prefix}-inhaler:after, .fa-duotone.#{$fa-css-prefix}-inhaler:after { content: fa-content(\f5f9\fe02); }
.fad.#{$fa-css-prefix}-input-numeric:before, .fa-duotone.#{$fa-css-prefix}-input-numeric:before { content: fa-content(\e1bd\fe01); }
.fad.#{$fa-css-prefix}-input-numeric:after, .fa-duotone.#{$fa-css-prefix}-input-numeric:after { content: fa-content(\e1bd\fe02); }
.fad.#{$fa-css-prefix}-input-pipe:before, .fa-duotone.#{$fa-css-prefix}-input-pipe:before { content: fa-content(\e1be\fe01); }
.fad.#{$fa-css-prefix}-input-pipe:after, .fa-duotone.#{$fa-css-prefix}-input-pipe:after { content: fa-content(\e1be\fe02); }
.fad.#{$fa-css-prefix}-input-text:before, .fa-duotone.#{$fa-css-prefix}-input-text:before { content: fa-content(\e1bf\fe01); }
.fad.#{$fa-css-prefix}-input-text:after, .fa-duotone.#{$fa-css-prefix}-input-text:after { content: fa-content(\e1bf\fe02); }
.fad.#{$fa-css-prefix}-integral:before, .fa-duotone.#{$fa-css-prefix}-integral:before { content: fa-content(\f667\fe01); }
.fad.#{$fa-css-prefix}-integral:after, .fa-duotone.#{$fa-css-prefix}-integral:after { content: fa-content(\f667\fe02); }
.fad.#{$fa-css-prefix}-intersection:before, .fa-duotone.#{$fa-css-prefix}-intersection:before { content: fa-content(\22c2\fe01); }
.fad.#{$fa-css-prefix}-intersection:after, .fa-duotone.#{$fa-css-prefix}-intersection:after { content: fa-content(\22c2\fe02); }
.fad.#{$fa-css-prefix}-island-tropical:before, .fa-duotone.#{$fa-css-prefix}-island-tropical:before { content: fa-content(\f811); }
.fad.#{$fa-css-prefix}-island-tropical:after, .fa-duotone.#{$fa-css-prefix}-island-tropical:after { content: fa-content(\10f811); }
.fad.#{$fa-css-prefix}-island-tree-palm:before, .fa-duotone.#{$fa-css-prefix}-island-tree-palm:before { content: fa-content(\f811); }
.fad.#{$fa-css-prefix}-island-tree-palm:after, .fa-duotone.#{$fa-css-prefix}-island-tree-palm:after { content: fa-content(\10f811); }
.fad.#{$fa-css-prefix}-italic:before, .fa-duotone.#{$fa-css-prefix}-italic:before { content: fa-content(\f033\fe01); }
.fad.#{$fa-css-prefix}-italic:after, .fa-duotone.#{$fa-css-prefix}-italic:after { content: fa-content(\f033\fe02); }
.fad.#{$fa-css-prefix}-j:before, .fa-duotone.#{$fa-css-prefix}-j:before { content: fa-content(\4a\fe01); }
.fad.#{$fa-css-prefix}-j:after, .fa-duotone.#{$fa-css-prefix}-j:after { content: fa-content(\4a\fe02); }
.fad.#{$fa-css-prefix}-jack-o-lantern:before, .fa-duotone.#{$fa-css-prefix}-jack-o-lantern:before { content: fa-content(\f30e); }
.fad.#{$fa-css-prefix}-jack-o-lantern:after, .fa-duotone.#{$fa-css-prefix}-jack-o-lantern:after { content: fa-content(\10f30e); }
.fad.#{$fa-css-prefix}-jedi:before, .fa-duotone.#{$fa-css-prefix}-jedi:before { content: fa-content(\f669\fe01); }
.fad.#{$fa-css-prefix}-jedi:after, .fa-duotone.#{$fa-css-prefix}-jedi:after { content: fa-content(\f669\fe02); }
.fad.#{$fa-css-prefix}-jet-fighter:before, .fa-duotone.#{$fa-css-prefix}-jet-fighter:before { content: fa-content(\f0fb\fe01); }
.fad.#{$fa-css-prefix}-jet-fighter:after, .fa-duotone.#{$fa-css-prefix}-jet-fighter:after { content: fa-content(\f0fb\fe02); }
.fad.#{$fa-css-prefix}-fighter-jet:before, .fa-duotone.#{$fa-css-prefix}-fighter-jet:before { content: fa-content(\f0fb\fe01); }
.fad.#{$fa-css-prefix}-fighter-jet:after, .fa-duotone.#{$fa-css-prefix}-fighter-jet:after { content: fa-content(\f0fb\fe02); }
.fad.#{$fa-css-prefix}-joint:before, .fa-duotone.#{$fa-css-prefix}-joint:before { content: fa-content(\f595\fe01); }
.fad.#{$fa-css-prefix}-joint:after, .fa-duotone.#{$fa-css-prefix}-joint:after { content: fa-content(\f595\fe02); }
.fad.#{$fa-css-prefix}-joystick:before, .fa-duotone.#{$fa-css-prefix}-joystick:before { content: fa-content(\f8c5); }
.fad.#{$fa-css-prefix}-joystick:after, .fa-duotone.#{$fa-css-prefix}-joystick:after { content: fa-content(\10f8c5); }
.fad.#{$fa-css-prefix}-jug:before, .fa-duotone.#{$fa-css-prefix}-jug:before { content: fa-content(\f8c6\fe01); }
.fad.#{$fa-css-prefix}-jug:after, .fa-duotone.#{$fa-css-prefix}-jug:after { content: fa-content(\f8c6\fe02); }
.fad.#{$fa-css-prefix}-k:before, .fa-duotone.#{$fa-css-prefix}-k:before { content: fa-content(\4b\fe01); }
.fad.#{$fa-css-prefix}-k:after, .fa-duotone.#{$fa-css-prefix}-k:after { content: fa-content(\4b\fe02); }
.fad.#{$fa-css-prefix}-kaaba:before, .fa-duotone.#{$fa-css-prefix}-kaaba:before { content: fa-content(\f66b); }
.fad.#{$fa-css-prefix}-kaaba:after, .fa-duotone.#{$fa-css-prefix}-kaaba:after { content: fa-content(\10f66b); }
.fad.#{$fa-css-prefix}-kazoo:before, .fa-duotone.#{$fa-css-prefix}-kazoo:before { content: fa-content(\f8c7\fe01); }
.fad.#{$fa-css-prefix}-kazoo:after, .fa-duotone.#{$fa-css-prefix}-kazoo:after { content: fa-content(\f8c7\fe02); }
.fad.#{$fa-css-prefix}-kerning:before, .fa-duotone.#{$fa-css-prefix}-kerning:before { content: fa-content(\f86f\fe01); }
.fad.#{$fa-css-prefix}-kerning:after, .fa-duotone.#{$fa-css-prefix}-kerning:after { content: fa-content(\f86f\fe02); }
.fad.#{$fa-css-prefix}-key:before, .fa-duotone.#{$fa-css-prefix}-key:before { content: fa-content(\f084); }
.fad.#{$fa-css-prefix}-key:after, .fa-duotone.#{$fa-css-prefix}-key:after { content: fa-content(\10f084); }
.fad.#{$fa-css-prefix}-key-skeleton:before, .fa-duotone.#{$fa-css-prefix}-key-skeleton:before { content: fa-content(\f6f3); }
.fad.#{$fa-css-prefix}-key-skeleton:after, .fa-duotone.#{$fa-css-prefix}-key-skeleton:after { content: fa-content(\10f6f3); }
.fad.#{$fa-css-prefix}-key-skeleton-left-right:before, .fa-duotone.#{$fa-css-prefix}-key-skeleton-left-right:before { content: fa-content(\e3b4\fe01); }
.fad.#{$fa-css-prefix}-key-skeleton-left-right:after, .fa-duotone.#{$fa-css-prefix}-key-skeleton-left-right:after { content: fa-content(\e3b4\fe02); }
.fad.#{$fa-css-prefix}-keyboard:before, .fa-duotone.#{$fa-css-prefix}-keyboard:before { content: fa-content(\2328\fe01); }
.fad.#{$fa-css-prefix}-keyboard:after, .fa-duotone.#{$fa-css-prefix}-keyboard:after { content: fa-content(\2328\fe02); }
.fad.#{$fa-css-prefix}-keyboard-brightness:before, .fa-duotone.#{$fa-css-prefix}-keyboard-brightness:before { content: fa-content(\e1c0\fe01); }
.fad.#{$fa-css-prefix}-keyboard-brightness:after, .fa-duotone.#{$fa-css-prefix}-keyboard-brightness:after { content: fa-content(\e1c0\fe02); }
.fad.#{$fa-css-prefix}-keyboard-brightness-low:before, .fa-duotone.#{$fa-css-prefix}-keyboard-brightness-low:before { content: fa-content(\e1c1\fe01); }
.fad.#{$fa-css-prefix}-keyboard-brightness-low:after, .fa-duotone.#{$fa-css-prefix}-keyboard-brightness-low:after { content: fa-content(\e1c1\fe02); }
.fad.#{$fa-css-prefix}-keyboard-down:before, .fa-duotone.#{$fa-css-prefix}-keyboard-down:before { content: fa-content(\e1c2\fe01); }
.fad.#{$fa-css-prefix}-keyboard-down:after, .fa-duotone.#{$fa-css-prefix}-keyboard-down:after { content: fa-content(\e1c2\fe02); }
.fad.#{$fa-css-prefix}-keyboard-left:before, .fa-duotone.#{$fa-css-prefix}-keyboard-left:before { content: fa-content(\e1c3\fe01); }
.fad.#{$fa-css-prefix}-keyboard-left:after, .fa-duotone.#{$fa-css-prefix}-keyboard-left:after { content: fa-content(\e1c3\fe02); }
.fad.#{$fa-css-prefix}-keynote:before, .fa-duotone.#{$fa-css-prefix}-keynote:before { content: fa-content(\f66c\fe01); }
.fad.#{$fa-css-prefix}-keynote:after, .fa-duotone.#{$fa-css-prefix}-keynote:after { content: fa-content(\f66c\fe02); }
.fad.#{$fa-css-prefix}-khanda:before, .fa-duotone.#{$fa-css-prefix}-khanda:before { content: fa-content(\262c\fe01); }
.fad.#{$fa-css-prefix}-khanda:after, .fa-duotone.#{$fa-css-prefix}-khanda:after { content: fa-content(\262c\fe02); }
.fad.#{$fa-css-prefix}-kidneys:before, .fa-duotone.#{$fa-css-prefix}-kidneys:before { content: fa-content(\f5fb\fe01); }
.fad.#{$fa-css-prefix}-kidneys:after, .fa-duotone.#{$fa-css-prefix}-kidneys:after { content: fa-content(\f5fb\fe02); }
.fad.#{$fa-css-prefix}-kip-sign:before, .fa-duotone.#{$fa-css-prefix}-kip-sign:before { content: fa-content(\e1c4\fe01); }
.fad.#{$fa-css-prefix}-kip-sign:after, .fa-duotone.#{$fa-css-prefix}-kip-sign:after { content: fa-content(\e1c4\fe02); }
.fad.#{$fa-css-prefix}-kit-medical:before, .fa-duotone.#{$fa-css-prefix}-kit-medical:before { content: fa-content(\f479\fe01); }
.fad.#{$fa-css-prefix}-kit-medical:after, .fa-duotone.#{$fa-css-prefix}-kit-medical:after { content: fa-content(\f479\fe02); }
.fad.#{$fa-css-prefix}-first-aid:before, .fa-duotone.#{$fa-css-prefix}-first-aid:before { content: fa-content(\f479\fe01); }
.fad.#{$fa-css-prefix}-first-aid:after, .fa-duotone.#{$fa-css-prefix}-first-aid:after { content: fa-content(\f479\fe02); }
.fad.#{$fa-css-prefix}-kite:before, .fa-duotone.#{$fa-css-prefix}-kite:before { content: fa-content(\f6f4); }
.fad.#{$fa-css-prefix}-kite:after, .fa-duotone.#{$fa-css-prefix}-kite:after { content: fa-content(\10f6f4); }
.fad.#{$fa-css-prefix}-kiwi-bird:before, .fa-duotone.#{$fa-css-prefix}-kiwi-bird:before { content: fa-content(\f535\fe01); }
.fad.#{$fa-css-prefix}-kiwi-bird:after, .fa-duotone.#{$fa-css-prefix}-kiwi-bird:after { content: fa-content(\f535\fe02); }
.fad.#{$fa-css-prefix}-kiwi-fruit:before, .fa-duotone.#{$fa-css-prefix}-kiwi-fruit:before { content: fa-content(\e30c\fe01); }
.fad.#{$fa-css-prefix}-kiwi-fruit:after, .fa-duotone.#{$fa-css-prefix}-kiwi-fruit:after { content: fa-content(\e30c\fe02); }
.fad.#{$fa-css-prefix}-knife:before, .fa-duotone.#{$fa-css-prefix}-knife:before { content: fa-content(\f2e4\fe01); }
.fad.#{$fa-css-prefix}-knife:after, .fa-duotone.#{$fa-css-prefix}-knife:after { content: fa-content(\f2e4\fe02); }
.fad.#{$fa-css-prefix}-utensil-knife:before, .fa-duotone.#{$fa-css-prefix}-utensil-knife:before { content: fa-content(\f2e4\fe01); }
.fad.#{$fa-css-prefix}-utensil-knife:after, .fa-duotone.#{$fa-css-prefix}-utensil-knife:after { content: fa-content(\f2e4\fe02); }
.fad.#{$fa-css-prefix}-knife-kitchen:before, .fa-duotone.#{$fa-css-prefix}-knife-kitchen:before { content: fa-content(\f6f5); }
.fad.#{$fa-css-prefix}-knife-kitchen:after, .fa-duotone.#{$fa-css-prefix}-knife-kitchen:after { content: fa-content(\10f6f5); }
.fad.#{$fa-css-prefix}-l:before, .fa-duotone.#{$fa-css-prefix}-l:before { content: fa-content(\4c\fe01); }
.fad.#{$fa-css-prefix}-l:after, .fa-duotone.#{$fa-css-prefix}-l:after { content: fa-content(\4c\fe02); }
.fad.#{$fa-css-prefix}-lacrosse-stick:before, .fa-duotone.#{$fa-css-prefix}-lacrosse-stick:before { content: fa-content(\e3b5\fe01); }
.fad.#{$fa-css-prefix}-lacrosse-stick:after, .fa-duotone.#{$fa-css-prefix}-lacrosse-stick:after { content: fa-content(\e3b5\fe02); }
.fad.#{$fa-css-prefix}-lacrosse-stick-ball:before, .fa-duotone.#{$fa-css-prefix}-lacrosse-stick-ball:before { content: fa-content(\e3b6\fe01); }
.fad.#{$fa-css-prefix}-lacrosse-stick-ball:after, .fa-duotone.#{$fa-css-prefix}-lacrosse-stick-ball:after { content: fa-content(\e3b6\fe02); }
.fad.#{$fa-css-prefix}-lambda:before, .fa-duotone.#{$fa-css-prefix}-lambda:before { content: fa-content(\f66e\fe01); }
.fad.#{$fa-css-prefix}-lambda:after, .fa-duotone.#{$fa-css-prefix}-lambda:after { content: fa-content(\f66e\fe02); }
.fad.#{$fa-css-prefix}-lamp:before, .fa-duotone.#{$fa-css-prefix}-lamp:before { content: fa-content(\f4ca\fe01); }
.fad.#{$fa-css-prefix}-lamp:after, .fa-duotone.#{$fa-css-prefix}-lamp:after { content: fa-content(\f4ca\fe02); }
.fad.#{$fa-css-prefix}-lamp-desk:before, .fa-duotone.#{$fa-css-prefix}-lamp-desk:before { content: fa-content(\e014\fe01); }
.fad.#{$fa-css-prefix}-lamp-desk:after, .fa-duotone.#{$fa-css-prefix}-lamp-desk:after { content: fa-content(\e014\fe02); }
.fad.#{$fa-css-prefix}-lamp-floor:before, .fa-duotone.#{$fa-css-prefix}-lamp-floor:before { content: fa-content(\e015\fe01); }
.fad.#{$fa-css-prefix}-lamp-floor:after, .fa-duotone.#{$fa-css-prefix}-lamp-floor:after { content: fa-content(\e015\fe02); }
.fad.#{$fa-css-prefix}-lamp-street:before, .fa-duotone.#{$fa-css-prefix}-lamp-street:before { content: fa-content(\e1c5\fe01); }
.fad.#{$fa-css-prefix}-lamp-street:after, .fa-duotone.#{$fa-css-prefix}-lamp-street:after { content: fa-content(\e1c5\fe02); }
.fad.#{$fa-css-prefix}-landmark:before, .fa-duotone.#{$fa-css-prefix}-landmark:before { content: fa-content(\f66f); }
.fad.#{$fa-css-prefix}-landmark:after, .fa-duotone.#{$fa-css-prefix}-landmark:after { content: fa-content(\10f66f); }
.fad.#{$fa-css-prefix}-landmark-dome:before, .fa-duotone.#{$fa-css-prefix}-landmark-dome:before { content: fa-content(\f752\fe01); }
.fad.#{$fa-css-prefix}-landmark-dome:after, .fa-duotone.#{$fa-css-prefix}-landmark-dome:after { content: fa-content(\f752\fe02); }
.fad.#{$fa-css-prefix}-landmark-alt:before, .fa-duotone.#{$fa-css-prefix}-landmark-alt:before { content: fa-content(\f752\fe01); }
.fad.#{$fa-css-prefix}-landmark-alt:after, .fa-duotone.#{$fa-css-prefix}-landmark-alt:after { content: fa-content(\f752\fe02); }
.fad.#{$fa-css-prefix}-language:before, .fa-duotone.#{$fa-css-prefix}-language:before { content: fa-content(\f1ab\fe01); }
.fad.#{$fa-css-prefix}-language:after, .fa-duotone.#{$fa-css-prefix}-language:after { content: fa-content(\f1ab\fe02); }
.fad.#{$fa-css-prefix}-laptop:before, .fa-duotone.#{$fa-css-prefix}-laptop:before { content: fa-content(\f109); }
.fad.#{$fa-css-prefix}-laptop:after, .fa-duotone.#{$fa-css-prefix}-laptop:after { content: fa-content(\10f109); }
.fad.#{$fa-css-prefix}-laptop-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-laptop-arrow-down:before { content: fa-content(\e1c6\fe01); }
.fad.#{$fa-css-prefix}-laptop-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-laptop-arrow-down:after { content: fa-content(\e1c6\fe02); }
.fad.#{$fa-css-prefix}-laptop-code:before, .fa-duotone.#{$fa-css-prefix}-laptop-code:before { content: fa-content(\f5fc\fe01); }
.fad.#{$fa-css-prefix}-laptop-code:after, .fa-duotone.#{$fa-css-prefix}-laptop-code:after { content: fa-content(\f5fc\fe02); }
.fad.#{$fa-css-prefix}-laptop-medical:before, .fa-duotone.#{$fa-css-prefix}-laptop-medical:before { content: fa-content(\f812\fe01); }
.fad.#{$fa-css-prefix}-laptop-medical:after, .fa-duotone.#{$fa-css-prefix}-laptop-medical:after { content: fa-content(\f812\fe02); }
.fad.#{$fa-css-prefix}-laptop-mobile:before, .fa-duotone.#{$fa-css-prefix}-laptop-mobile:before { content: fa-content(\f87a\fe01); }
.fad.#{$fa-css-prefix}-laptop-mobile:after, .fa-duotone.#{$fa-css-prefix}-laptop-mobile:after { content: fa-content(\f87a\fe02); }
.fad.#{$fa-css-prefix}-phone-laptop:before, .fa-duotone.#{$fa-css-prefix}-phone-laptop:before { content: fa-content(\f87a\fe01); }
.fad.#{$fa-css-prefix}-phone-laptop:after, .fa-duotone.#{$fa-css-prefix}-phone-laptop:after { content: fa-content(\f87a\fe02); }
.fad.#{$fa-css-prefix}-laptop-slash:before, .fa-duotone.#{$fa-css-prefix}-laptop-slash:before { content: fa-content(\e1c7\fe01); }
.fad.#{$fa-css-prefix}-laptop-slash:after, .fa-duotone.#{$fa-css-prefix}-laptop-slash:after { content: fa-content(\e1c7\fe02); }
.fad.#{$fa-css-prefix}-lari-sign:before, .fa-duotone.#{$fa-css-prefix}-lari-sign:before { content: fa-content(\e1c8\fe01); }
.fad.#{$fa-css-prefix}-lari-sign:after, .fa-duotone.#{$fa-css-prefix}-lari-sign:after { content: fa-content(\e1c8\fe02); }
.fad.#{$fa-css-prefix}-lasso:before, .fa-duotone.#{$fa-css-prefix}-lasso:before { content: fa-content(\f8c8\fe01); }
.fad.#{$fa-css-prefix}-lasso:after, .fa-duotone.#{$fa-css-prefix}-lasso:after { content: fa-content(\f8c8\fe02); }
.fad.#{$fa-css-prefix}-lasso-sparkles:before, .fa-duotone.#{$fa-css-prefix}-lasso-sparkles:before { content: fa-content(\e1c9\fe01); }
.fad.#{$fa-css-prefix}-lasso-sparkles:after, .fa-duotone.#{$fa-css-prefix}-lasso-sparkles:after { content: fa-content(\e1c9\fe02); }
.fad.#{$fa-css-prefix}-layer-group:before, .fa-duotone.#{$fa-css-prefix}-layer-group:before { content: fa-content(\f5fd\fe01); }
.fad.#{$fa-css-prefix}-layer-group:after, .fa-duotone.#{$fa-css-prefix}-layer-group:after { content: fa-content(\f5fd\fe02); }
.fad.#{$fa-css-prefix}-layer-minus:before, .fa-duotone.#{$fa-css-prefix}-layer-minus:before { content: fa-content(\f5fe\fe01); }
.fad.#{$fa-css-prefix}-layer-minus:after, .fa-duotone.#{$fa-css-prefix}-layer-minus:after { content: fa-content(\f5fe\fe02); }
.fad.#{$fa-css-prefix}-layer-group-minus:before, .fa-duotone.#{$fa-css-prefix}-layer-group-minus:before { content: fa-content(\f5fe\fe01); }
.fad.#{$fa-css-prefix}-layer-group-minus:after, .fa-duotone.#{$fa-css-prefix}-layer-group-minus:after { content: fa-content(\f5fe\fe02); }
.fad.#{$fa-css-prefix}-layer-plus:before, .fa-duotone.#{$fa-css-prefix}-layer-plus:before { content: fa-content(\f5ff\fe01); }
.fad.#{$fa-css-prefix}-layer-plus:after, .fa-duotone.#{$fa-css-prefix}-layer-plus:after { content: fa-content(\f5ff\fe02); }
.fad.#{$fa-css-prefix}-layer-group-plus:before, .fa-duotone.#{$fa-css-prefix}-layer-group-plus:before { content: fa-content(\f5ff\fe01); }
.fad.#{$fa-css-prefix}-layer-group-plus:after, .fa-duotone.#{$fa-css-prefix}-layer-group-plus:after { content: fa-content(\f5ff\fe02); }
.fad.#{$fa-css-prefix}-leaf:before, .fa-duotone.#{$fa-css-prefix}-leaf:before { content: fa-content(\f06c\fe01); }
.fad.#{$fa-css-prefix}-leaf:after, .fa-duotone.#{$fa-css-prefix}-leaf:after { content: fa-content(\f06c\fe02); }
.fad.#{$fa-css-prefix}-leaf-heart:before, .fa-duotone.#{$fa-css-prefix}-leaf-heart:before { content: fa-content(\f4cb\fe01); }
.fad.#{$fa-css-prefix}-leaf-heart:after, .fa-duotone.#{$fa-css-prefix}-leaf-heart:after { content: fa-content(\f4cb\fe02); }
.fad.#{$fa-css-prefix}-leaf-maple:before, .fa-duotone.#{$fa-css-prefix}-leaf-maple:before { content: fa-content(\f6f6); }
.fad.#{$fa-css-prefix}-leaf-maple:after, .fa-duotone.#{$fa-css-prefix}-leaf-maple:after { content: fa-content(\10f6f6); }
.fad.#{$fa-css-prefix}-leaf-oak:before, .fa-duotone.#{$fa-css-prefix}-leaf-oak:before { content: fa-content(\f6f7\fe01); }
.fad.#{$fa-css-prefix}-leaf-oak:after, .fa-duotone.#{$fa-css-prefix}-leaf-oak:after { content: fa-content(\f6f7\fe02); }
.fad.#{$fa-css-prefix}-left:before, .fa-duotone.#{$fa-css-prefix}-left:before { content: fa-content(\f355\fe01); }
.fad.#{$fa-css-prefix}-left:after, .fa-duotone.#{$fa-css-prefix}-left:after { content: fa-content(\f355\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-left:before { content: fa-content(\f355\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-left:after { content: fa-content(\f355\fe02); }
.fad.#{$fa-css-prefix}-left-from-line:before, .fa-duotone.#{$fa-css-prefix}-left-from-line:before { content: fa-content(\f348\fe01); }
.fad.#{$fa-css-prefix}-left-from-line:after, .fa-duotone.#{$fa-css-prefix}-left-from-line:after { content: fa-content(\f348\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-from-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-right:before { content: fa-content(\f348\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-from-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-right:after { content: fa-content(\f348\fe02); }
.fad.#{$fa-css-prefix}-left-long:before, .fa-duotone.#{$fa-css-prefix}-left-long:before { content: fa-content(\f30a\fe01); }
.fad.#{$fa-css-prefix}-left-long:after, .fa-duotone.#{$fa-css-prefix}-left-long:after { content: fa-content(\f30a\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-alt-left:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-left:before { content: fa-content(\f30a\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-alt-left:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-left:after { content: fa-content(\f30a\fe02); }
.fad.#{$fa-css-prefix}-left-right:before, .fa-duotone.#{$fa-css-prefix}-left-right:before { content: fa-content(\2194\fe01); }
.fad.#{$fa-css-prefix}-left-right:after, .fa-duotone.#{$fa-css-prefix}-left-right:after { content: fa-content(\2194\fe02); }
.fad.#{$fa-css-prefix}-arrows-alt-h:before, .fa-duotone.#{$fa-css-prefix}-arrows-alt-h:before { content: fa-content(\2194\fe01); }
.fad.#{$fa-css-prefix}-arrows-alt-h:after, .fa-duotone.#{$fa-css-prefix}-arrows-alt-h:after { content: fa-content(\2194\fe02); }
.fad.#{$fa-css-prefix}-left-to-line:before, .fa-duotone.#{$fa-css-prefix}-left-to-line:before { content: fa-content(\f34b\fe01); }
.fad.#{$fa-css-prefix}-left-to-line:after, .fa-duotone.#{$fa-css-prefix}-left-to-line:after { content: fa-content(\f34b\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-to-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-left:before { content: fa-content(\f34b\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-to-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-left:after { content: fa-content(\f34b\fe02); }
.fad.#{$fa-css-prefix}-lemon:before, .fa-duotone.#{$fa-css-prefix}-lemon:before { content: fa-content(\f094); }
.fad.#{$fa-css-prefix}-lemon:after, .fa-duotone.#{$fa-css-prefix}-lemon:after { content: fa-content(\10f094); }
.fad.#{$fa-css-prefix}-less-than:before, .fa-duotone.#{$fa-css-prefix}-less-than:before { content: fa-content(\3c\fe01); }
.fad.#{$fa-css-prefix}-less-than:after, .fa-duotone.#{$fa-css-prefix}-less-than:after { content: fa-content(\3c\fe02); }
.fad.#{$fa-css-prefix}-less-than-equal:before, .fa-duotone.#{$fa-css-prefix}-less-than-equal:before { content: fa-content(\f537\fe01); }
.fad.#{$fa-css-prefix}-less-than-equal:after, .fa-duotone.#{$fa-css-prefix}-less-than-equal:after { content: fa-content(\f537\fe02); }
.fad.#{$fa-css-prefix}-life-ring:before, .fa-duotone.#{$fa-css-prefix}-life-ring:before { content: fa-content(\f1cd\fe01); }
.fad.#{$fa-css-prefix}-life-ring:after, .fa-duotone.#{$fa-css-prefix}-life-ring:after { content: fa-content(\f1cd\fe02); }
.fad.#{$fa-css-prefix}-light-ceiling:before, .fa-duotone.#{$fa-css-prefix}-light-ceiling:before { content: fa-content(\e016\fe01); }
.fad.#{$fa-css-prefix}-light-ceiling:after, .fa-duotone.#{$fa-css-prefix}-light-ceiling:after { content: fa-content(\e016\fe02); }
.fad.#{$fa-css-prefix}-light-switch:before, .fa-duotone.#{$fa-css-prefix}-light-switch:before { content: fa-content(\e017\fe01); }
.fad.#{$fa-css-prefix}-light-switch:after, .fa-duotone.#{$fa-css-prefix}-light-switch:after { content: fa-content(\e017\fe02); }
.fad.#{$fa-css-prefix}-light-switch-off:before, .fa-duotone.#{$fa-css-prefix}-light-switch-off:before { content: fa-content(\e018\fe01); }
.fad.#{$fa-css-prefix}-light-switch-off:after, .fa-duotone.#{$fa-css-prefix}-light-switch-off:after { content: fa-content(\e018\fe02); }
.fad.#{$fa-css-prefix}-light-switch-on:before, .fa-duotone.#{$fa-css-prefix}-light-switch-on:before { content: fa-content(\e019\fe01); }
.fad.#{$fa-css-prefix}-light-switch-on:after, .fa-duotone.#{$fa-css-prefix}-light-switch-on:after { content: fa-content(\e019\fe02); }
.fad.#{$fa-css-prefix}-lightbulb:before, .fa-duotone.#{$fa-css-prefix}-lightbulb:before { content: fa-content(\f0eb); }
.fad.#{$fa-css-prefix}-lightbulb:after, .fa-duotone.#{$fa-css-prefix}-lightbulb:after { content: fa-content(\10f0eb); }
.fad.#{$fa-css-prefix}-lightbulb-dollar:before, .fa-duotone.#{$fa-css-prefix}-lightbulb-dollar:before { content: fa-content(\f670\fe01); }
.fad.#{$fa-css-prefix}-lightbulb-dollar:after, .fa-duotone.#{$fa-css-prefix}-lightbulb-dollar:after { content: fa-content(\f670\fe02); }
.fad.#{$fa-css-prefix}-lightbulb-exclamation:before, .fa-duotone.#{$fa-css-prefix}-lightbulb-exclamation:before { content: fa-content(\f671\fe01); }
.fad.#{$fa-css-prefix}-lightbulb-exclamation:after, .fa-duotone.#{$fa-css-prefix}-lightbulb-exclamation:after { content: fa-content(\f671\fe02); }
.fad.#{$fa-css-prefix}-lightbulb-exclamation-on:before, .fa-duotone.#{$fa-css-prefix}-lightbulb-exclamation-on:before { content: fa-content(\e1ca\fe01); }
.fad.#{$fa-css-prefix}-lightbulb-exclamation-on:after, .fa-duotone.#{$fa-css-prefix}-lightbulb-exclamation-on:after { content: fa-content(\e1ca\fe02); }
.fad.#{$fa-css-prefix}-lightbulb-on:before, .fa-duotone.#{$fa-css-prefix}-lightbulb-on:before { content: fa-content(\f672\fe01); }
.fad.#{$fa-css-prefix}-lightbulb-on:after, .fa-duotone.#{$fa-css-prefix}-lightbulb-on:after { content: fa-content(\f672\fe02); }
.fad.#{$fa-css-prefix}-lightbulb-slash:before, .fa-duotone.#{$fa-css-prefix}-lightbulb-slash:before { content: fa-content(\f673\fe01); }
.fad.#{$fa-css-prefix}-lightbulb-slash:after, .fa-duotone.#{$fa-css-prefix}-lightbulb-slash:after { content: fa-content(\f673\fe02); }
.fad.#{$fa-css-prefix}-lights-holiday:before, .fa-duotone.#{$fa-css-prefix}-lights-holiday:before { content: fa-content(\f7b2\fe01); }
.fad.#{$fa-css-prefix}-lights-holiday:after, .fa-duotone.#{$fa-css-prefix}-lights-holiday:after { content: fa-content(\f7b2\fe02); }
.fad.#{$fa-css-prefix}-line-columns:before, .fa-duotone.#{$fa-css-prefix}-line-columns:before { content: fa-content(\f870\fe01); }
.fad.#{$fa-css-prefix}-line-columns:after, .fa-duotone.#{$fa-css-prefix}-line-columns:after { content: fa-content(\f870\fe02); }
.fad.#{$fa-css-prefix}-line-height:before, .fa-duotone.#{$fa-css-prefix}-line-height:before { content: fa-content(\f871\fe01); }
.fad.#{$fa-css-prefix}-line-height:after, .fa-duotone.#{$fa-css-prefix}-line-height:after { content: fa-content(\f871\fe02); }
.fad.#{$fa-css-prefix}-link:before, .fa-duotone.#{$fa-css-prefix}-link:before { content: fa-content(\f0c1); }
.fad.#{$fa-css-prefix}-link:after, .fa-duotone.#{$fa-css-prefix}-link:after { content: fa-content(\10f0c1); }
.fad.#{$fa-css-prefix}-chain:before, .fa-duotone.#{$fa-css-prefix}-chain:before { content: fa-content(\f0c1); }
.fad.#{$fa-css-prefix}-chain:after, .fa-duotone.#{$fa-css-prefix}-chain:after { content: fa-content(\10f0c1); }
.fad.#{$fa-css-prefix}-link-horizontal:before, .fa-duotone.#{$fa-css-prefix}-link-horizontal:before { content: fa-content(\e1cb\fe01); }
.fad.#{$fa-css-prefix}-link-horizontal:after, .fa-duotone.#{$fa-css-prefix}-link-horizontal:after { content: fa-content(\e1cb\fe02); }
.fad.#{$fa-css-prefix}-chain-horizontal:before, .fa-duotone.#{$fa-css-prefix}-chain-horizontal:before { content: fa-content(\e1cb\fe01); }
.fad.#{$fa-css-prefix}-chain-horizontal:after, .fa-duotone.#{$fa-css-prefix}-chain-horizontal:after { content: fa-content(\e1cb\fe02); }
.fad.#{$fa-css-prefix}-link-horizontal-slash:before, .fa-duotone.#{$fa-css-prefix}-link-horizontal-slash:before { content: fa-content(\e1cc\fe01); }
.fad.#{$fa-css-prefix}-link-horizontal-slash:after, .fa-duotone.#{$fa-css-prefix}-link-horizontal-slash:after { content: fa-content(\e1cc\fe02); }
.fad.#{$fa-css-prefix}-chain-horizontal-slash:before, .fa-duotone.#{$fa-css-prefix}-chain-horizontal-slash:before { content: fa-content(\e1cc\fe01); }
.fad.#{$fa-css-prefix}-chain-horizontal-slash:after, .fa-duotone.#{$fa-css-prefix}-chain-horizontal-slash:after { content: fa-content(\e1cc\fe02); }
.fad.#{$fa-css-prefix}-link-simple:before, .fa-duotone.#{$fa-css-prefix}-link-simple:before { content: fa-content(\e1cd\fe01); }
.fad.#{$fa-css-prefix}-link-simple:after, .fa-duotone.#{$fa-css-prefix}-link-simple:after { content: fa-content(\e1cd\fe02); }
.fad.#{$fa-css-prefix}-link-simple-slash:before, .fa-duotone.#{$fa-css-prefix}-link-simple-slash:before { content: fa-content(\e1ce\fe01); }
.fad.#{$fa-css-prefix}-link-simple-slash:after, .fa-duotone.#{$fa-css-prefix}-link-simple-slash:after { content: fa-content(\e1ce\fe02); }
.fad.#{$fa-css-prefix}-link-slash:before, .fa-duotone.#{$fa-css-prefix}-link-slash:before { content: fa-content(\f127\fe01); }
.fad.#{$fa-css-prefix}-link-slash:after, .fa-duotone.#{$fa-css-prefix}-link-slash:after { content: fa-content(\f127\fe02); }
.fad.#{$fa-css-prefix}-chain-broken:before, .fa-duotone.#{$fa-css-prefix}-chain-broken:before { content: fa-content(\f127\fe01); }
.fad.#{$fa-css-prefix}-chain-broken:after, .fa-duotone.#{$fa-css-prefix}-chain-broken:after { content: fa-content(\f127\fe02); }
.fad.#{$fa-css-prefix}-chain-slash:before, .fa-duotone.#{$fa-css-prefix}-chain-slash:before { content: fa-content(\f127\fe01); }
.fad.#{$fa-css-prefix}-chain-slash:after, .fa-duotone.#{$fa-css-prefix}-chain-slash:after { content: fa-content(\f127\fe02); }
.fad.#{$fa-css-prefix}-unlink:before, .fa-duotone.#{$fa-css-prefix}-unlink:before { content: fa-content(\f127\fe01); }
.fad.#{$fa-css-prefix}-unlink:after, .fa-duotone.#{$fa-css-prefix}-unlink:after { content: fa-content(\f127\fe02); }
.fad.#{$fa-css-prefix}-lips:before, .fa-duotone.#{$fa-css-prefix}-lips:before { content: fa-content(\f600); }
.fad.#{$fa-css-prefix}-lips:after, .fa-duotone.#{$fa-css-prefix}-lips:after { content: fa-content(\10f600); }
.fad.#{$fa-css-prefix}-lira-sign:before, .fa-duotone.#{$fa-css-prefix}-lira-sign:before { content: fa-content(\20a4\fe01); }
.fad.#{$fa-css-prefix}-lira-sign:after, .fa-duotone.#{$fa-css-prefix}-lira-sign:after { content: fa-content(\20a4\fe02); }
.fad.#{$fa-css-prefix}-list:before, .fa-duotone.#{$fa-css-prefix}-list:before { content: fa-content(\f03a\fe01); }
.fad.#{$fa-css-prefix}-list:after, .fa-duotone.#{$fa-css-prefix}-list:after { content: fa-content(\f03a\fe02); }
.fad.#{$fa-css-prefix}-list-squares:before, .fa-duotone.#{$fa-css-prefix}-list-squares:before { content: fa-content(\f03a\fe01); }
.fad.#{$fa-css-prefix}-list-squares:after, .fa-duotone.#{$fa-css-prefix}-list-squares:after { content: fa-content(\f03a\fe02); }
.fad.#{$fa-css-prefix}-list-check:before, .fa-duotone.#{$fa-css-prefix}-list-check:before { content: fa-content(\f0ae\fe01); }
.fad.#{$fa-css-prefix}-list-check:after, .fa-duotone.#{$fa-css-prefix}-list-check:after { content: fa-content(\f0ae\fe02); }
.fad.#{$fa-css-prefix}-tasks:before, .fa-duotone.#{$fa-css-prefix}-tasks:before { content: fa-content(\f0ae\fe01); }
.fad.#{$fa-css-prefix}-tasks:after, .fa-duotone.#{$fa-css-prefix}-tasks:after { content: fa-content(\f0ae\fe02); }
.fad.#{$fa-css-prefix}-list-dropdown:before, .fa-duotone.#{$fa-css-prefix}-list-dropdown:before { content: fa-content(\e1cf\fe01); }
.fad.#{$fa-css-prefix}-list-dropdown:after, .fa-duotone.#{$fa-css-prefix}-list-dropdown:after { content: fa-content(\e1cf\fe02); }
.fad.#{$fa-css-prefix}-list-music:before, .fa-duotone.#{$fa-css-prefix}-list-music:before { content: fa-content(\f8c9\fe01); }
.fad.#{$fa-css-prefix}-list-music:after, .fa-duotone.#{$fa-css-prefix}-list-music:after { content: fa-content(\f8c9\fe02); }
.fad.#{$fa-css-prefix}-list-ol:before, .fa-duotone.#{$fa-css-prefix}-list-ol:before { content: fa-content(\f0cb\fe01); }
.fad.#{$fa-css-prefix}-list-ol:after, .fa-duotone.#{$fa-css-prefix}-list-ol:after { content: fa-content(\f0cb\fe02); }
.fad.#{$fa-css-prefix}-list-1-2:before, .fa-duotone.#{$fa-css-prefix}-list-1-2:before { content: fa-content(\f0cb\fe01); }
.fad.#{$fa-css-prefix}-list-1-2:after, .fa-duotone.#{$fa-css-prefix}-list-1-2:after { content: fa-content(\f0cb\fe02); }
.fad.#{$fa-css-prefix}-list-numeric:before, .fa-duotone.#{$fa-css-prefix}-list-numeric:before { content: fa-content(\f0cb\fe01); }
.fad.#{$fa-css-prefix}-list-numeric:after, .fa-duotone.#{$fa-css-prefix}-list-numeric:after { content: fa-content(\f0cb\fe02); }
.fad.#{$fa-css-prefix}-list-radio:before, .fa-duotone.#{$fa-css-prefix}-list-radio:before { content: fa-content(\e1d0\fe01); }
.fad.#{$fa-css-prefix}-list-radio:after, .fa-duotone.#{$fa-css-prefix}-list-radio:after { content: fa-content(\e1d0\fe02); }
.fad.#{$fa-css-prefix}-list-timeline:before, .fa-duotone.#{$fa-css-prefix}-list-timeline:before { content: fa-content(\e1d1\fe01); }
.fad.#{$fa-css-prefix}-list-timeline:after, .fa-duotone.#{$fa-css-prefix}-list-timeline:after { content: fa-content(\e1d1\fe02); }
.fad.#{$fa-css-prefix}-list-tree:before, .fa-duotone.#{$fa-css-prefix}-list-tree:before { content: fa-content(\e1d2\fe01); }
.fad.#{$fa-css-prefix}-list-tree:after, .fa-duotone.#{$fa-css-prefix}-list-tree:after { content: fa-content(\e1d2\fe02); }
.fad.#{$fa-css-prefix}-list-ul:before, .fa-duotone.#{$fa-css-prefix}-list-ul:before { content: fa-content(\f0ca\fe01); }
.fad.#{$fa-css-prefix}-list-ul:after, .fa-duotone.#{$fa-css-prefix}-list-ul:after { content: fa-content(\f0ca\fe02); }
.fad.#{$fa-css-prefix}-list-dots:before, .fa-duotone.#{$fa-css-prefix}-list-dots:before { content: fa-content(\f0ca\fe01); }
.fad.#{$fa-css-prefix}-list-dots:after, .fa-duotone.#{$fa-css-prefix}-list-dots:after { content: fa-content(\f0ca\fe02); }
.fad.#{$fa-css-prefix}-litecoin-sign:before, .fa-duotone.#{$fa-css-prefix}-litecoin-sign:before { content: fa-content(\e1d3\fe01); }
.fad.#{$fa-css-prefix}-litecoin-sign:after, .fa-duotone.#{$fa-css-prefix}-litecoin-sign:after { content: fa-content(\e1d3\fe02); }
.fad.#{$fa-css-prefix}-loader:before, .fa-duotone.#{$fa-css-prefix}-loader:before { content: fa-content(\e1d4\fe01); }
.fad.#{$fa-css-prefix}-loader:after, .fa-duotone.#{$fa-css-prefix}-loader:after { content: fa-content(\e1d4\fe02); }
.fad.#{$fa-css-prefix}-location:before, .fa-duotone.#{$fa-css-prefix}-location:before { content: fa-content(\f041\fe01); }
.fad.#{$fa-css-prefix}-location:after, .fa-duotone.#{$fa-css-prefix}-location:after { content: fa-content(\f041\fe02); }
.fad.#{$fa-css-prefix}-map-marker:before, .fa-duotone.#{$fa-css-prefix}-map-marker:before { content: fa-content(\f041\fe01); }
.fad.#{$fa-css-prefix}-map-marker:after, .fa-duotone.#{$fa-css-prefix}-map-marker:after { content: fa-content(\f041\fe02); }
.fad.#{$fa-css-prefix}-location-arrow:before, .fa-duotone.#{$fa-css-prefix}-location-arrow:before { content: fa-content(\f124\fe01); }
.fad.#{$fa-css-prefix}-location-arrow:after, .fa-duotone.#{$fa-css-prefix}-location-arrow:after { content: fa-content(\f124\fe02); }
.fad.#{$fa-css-prefix}-location-check:before, .fa-duotone.#{$fa-css-prefix}-location-check:before { content: fa-content(\f606\fe01); }
.fad.#{$fa-css-prefix}-location-check:after, .fa-duotone.#{$fa-css-prefix}-location-check:after { content: fa-content(\f606\fe02); }
.fad.#{$fa-css-prefix}-map-marker-check:before, .fa-duotone.#{$fa-css-prefix}-map-marker-check:before { content: fa-content(\f606\fe01); }
.fad.#{$fa-css-prefix}-map-marker-check:after, .fa-duotone.#{$fa-css-prefix}-map-marker-check:after { content: fa-content(\f606\fe02); }
.fad.#{$fa-css-prefix}-location-crosshairs:before, .fa-duotone.#{$fa-css-prefix}-location-crosshairs:before { content: fa-content(\f601\fe01); }
.fad.#{$fa-css-prefix}-location-crosshairs:after, .fa-duotone.#{$fa-css-prefix}-location-crosshairs:after { content: fa-content(\f601\fe02); }
.fad.#{$fa-css-prefix}-location-crosshairs-slash:before, .fa-duotone.#{$fa-css-prefix}-location-crosshairs-slash:before { content: fa-content(\f603\fe01); }
.fad.#{$fa-css-prefix}-location-crosshairs-slash:after, .fa-duotone.#{$fa-css-prefix}-location-crosshairs-slash:after { content: fa-content(\f603\fe02); }
.fad.#{$fa-css-prefix}-location-dot:before, .fa-duotone.#{$fa-css-prefix}-location-dot:before { content: fa-content(\f3c5\fe01); }
.fad.#{$fa-css-prefix}-location-dot:after, .fa-duotone.#{$fa-css-prefix}-location-dot:after { content: fa-content(\f3c5\fe02); }
.fad.#{$fa-css-prefix}-map-marker-alt:before, .fa-duotone.#{$fa-css-prefix}-map-marker-alt:before { content: fa-content(\f3c5\fe01); }
.fad.#{$fa-css-prefix}-map-marker-alt:after, .fa-duotone.#{$fa-css-prefix}-map-marker-alt:after { content: fa-content(\f3c5\fe02); }
.fad.#{$fa-css-prefix}-location-dot-slash:before, .fa-duotone.#{$fa-css-prefix}-location-dot-slash:before { content: fa-content(\f605\fe01); }
.fad.#{$fa-css-prefix}-location-dot-slash:after, .fa-duotone.#{$fa-css-prefix}-location-dot-slash:after { content: fa-content(\f605\fe02); }
.fad.#{$fa-css-prefix}-map-marker-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-map-marker-alt-slash:before { content: fa-content(\f605\fe01); }
.fad.#{$fa-css-prefix}-map-marker-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-map-marker-alt-slash:after { content: fa-content(\f605\fe02); }
.fad.#{$fa-css-prefix}-location-exclamation:before, .fa-duotone.#{$fa-css-prefix}-location-exclamation:before { content: fa-content(\f608\fe01); }
.fad.#{$fa-css-prefix}-location-exclamation:after, .fa-duotone.#{$fa-css-prefix}-location-exclamation:after { content: fa-content(\f608\fe02); }
.fad.#{$fa-css-prefix}-map-marker-exclamation:before, .fa-duotone.#{$fa-css-prefix}-map-marker-exclamation:before { content: fa-content(\f608\fe01); }
.fad.#{$fa-css-prefix}-map-marker-exclamation:after, .fa-duotone.#{$fa-css-prefix}-map-marker-exclamation:after { content: fa-content(\f608\fe02); }
.fad.#{$fa-css-prefix}-location-minus:before, .fa-duotone.#{$fa-css-prefix}-location-minus:before { content: fa-content(\f609\fe01); }
.fad.#{$fa-css-prefix}-location-minus:after, .fa-duotone.#{$fa-css-prefix}-location-minus:after { content: fa-content(\f609\fe02); }
.fad.#{$fa-css-prefix}-map-marker-minus:before, .fa-duotone.#{$fa-css-prefix}-map-marker-minus:before { content: fa-content(\f609\fe01); }
.fad.#{$fa-css-prefix}-map-marker-minus:after, .fa-duotone.#{$fa-css-prefix}-map-marker-minus:after { content: fa-content(\f609\fe02); }
.fad.#{$fa-css-prefix}-location-pen:before, .fa-duotone.#{$fa-css-prefix}-location-pen:before { content: fa-content(\f607\fe01); }
.fad.#{$fa-css-prefix}-location-pen:after, .fa-duotone.#{$fa-css-prefix}-location-pen:after { content: fa-content(\f607\fe02); }
.fad.#{$fa-css-prefix}-map-marker-edit:before, .fa-duotone.#{$fa-css-prefix}-map-marker-edit:before { content: fa-content(\f607\fe01); }
.fad.#{$fa-css-prefix}-map-marker-edit:after, .fa-duotone.#{$fa-css-prefix}-map-marker-edit:after { content: fa-content(\f607\fe02); }
.fad.#{$fa-css-prefix}-location-plus:before, .fa-duotone.#{$fa-css-prefix}-location-plus:before { content: fa-content(\f60a\fe01); }
.fad.#{$fa-css-prefix}-location-plus:after, .fa-duotone.#{$fa-css-prefix}-location-plus:after { content: fa-content(\f60a\fe02); }
.fad.#{$fa-css-prefix}-map-marker-plus:before, .fa-duotone.#{$fa-css-prefix}-map-marker-plus:before { content: fa-content(\f60a\fe01); }
.fad.#{$fa-css-prefix}-map-marker-plus:after, .fa-duotone.#{$fa-css-prefix}-map-marker-plus:after { content: fa-content(\f60a\fe02); }
.fad.#{$fa-css-prefix}-location-question:before, .fa-duotone.#{$fa-css-prefix}-location-question:before { content: fa-content(\f60b\fe01); }
.fad.#{$fa-css-prefix}-location-question:after, .fa-duotone.#{$fa-css-prefix}-location-question:after { content: fa-content(\f60b\fe02); }
.fad.#{$fa-css-prefix}-map-marker-question:before, .fa-duotone.#{$fa-css-prefix}-map-marker-question:before { content: fa-content(\f60b\fe01); }
.fad.#{$fa-css-prefix}-map-marker-question:after, .fa-duotone.#{$fa-css-prefix}-map-marker-question:after { content: fa-content(\f60b\fe02); }
.fad.#{$fa-css-prefix}-location-slash:before, .fa-duotone.#{$fa-css-prefix}-location-slash:before { content: fa-content(\f60c\fe01); }
.fad.#{$fa-css-prefix}-location-slash:after, .fa-duotone.#{$fa-css-prefix}-location-slash:after { content: fa-content(\f60c\fe02); }
.fad.#{$fa-css-prefix}-map-marker-slash:before, .fa-duotone.#{$fa-css-prefix}-map-marker-slash:before { content: fa-content(\f60c\fe01); }
.fad.#{$fa-css-prefix}-map-marker-slash:after, .fa-duotone.#{$fa-css-prefix}-map-marker-slash:after { content: fa-content(\f60c\fe02); }
.fad.#{$fa-css-prefix}-location-smile:before, .fa-duotone.#{$fa-css-prefix}-location-smile:before { content: fa-content(\f60d\fe01); }
.fad.#{$fa-css-prefix}-location-smile:after, .fa-duotone.#{$fa-css-prefix}-location-smile:after { content: fa-content(\f60d\fe02); }
.fad.#{$fa-css-prefix}-map-marker-smile:before, .fa-duotone.#{$fa-css-prefix}-map-marker-smile:before { content: fa-content(\f60d\fe01); }
.fad.#{$fa-css-prefix}-map-marker-smile:after, .fa-duotone.#{$fa-css-prefix}-map-marker-smile:after { content: fa-content(\f60d\fe02); }
.fad.#{$fa-css-prefix}-location-xmark:before, .fa-duotone.#{$fa-css-prefix}-location-xmark:before { content: fa-content(\f60e\fe01); }
.fad.#{$fa-css-prefix}-location-xmark:after, .fa-duotone.#{$fa-css-prefix}-location-xmark:after { content: fa-content(\f60e\fe02); }
.fad.#{$fa-css-prefix}-map-marker-times:before, .fa-duotone.#{$fa-css-prefix}-map-marker-times:before { content: fa-content(\f60e\fe01); }
.fad.#{$fa-css-prefix}-map-marker-times:after, .fa-duotone.#{$fa-css-prefix}-map-marker-times:after { content: fa-content(\f60e\fe02); }
.fad.#{$fa-css-prefix}-map-marker-xmark:before, .fa-duotone.#{$fa-css-prefix}-map-marker-xmark:before { content: fa-content(\f60e\fe01); }
.fad.#{$fa-css-prefix}-map-marker-xmark:after, .fa-duotone.#{$fa-css-prefix}-map-marker-xmark:after { content: fa-content(\f60e\fe02); }
.fad.#{$fa-css-prefix}-lock:before, .fa-duotone.#{$fa-css-prefix}-lock:before { content: fa-content(\f023); }
.fad.#{$fa-css-prefix}-lock:after, .fa-duotone.#{$fa-css-prefix}-lock:after { content: fa-content(\10f023); }
.fad.#{$fa-css-prefix}-lock-keyhole:before, .fa-duotone.#{$fa-css-prefix}-lock-keyhole:before { content: fa-content(\f30d\fe01); }
.fad.#{$fa-css-prefix}-lock-keyhole:after, .fa-duotone.#{$fa-css-prefix}-lock-keyhole:after { content: fa-content(\f30d\fe02); }
.fad.#{$fa-css-prefix}-lock-alt:before, .fa-duotone.#{$fa-css-prefix}-lock-alt:before { content: fa-content(\f30d\fe01); }
.fad.#{$fa-css-prefix}-lock-alt:after, .fa-duotone.#{$fa-css-prefix}-lock-alt:after { content: fa-content(\f30d\fe02); }
.fad.#{$fa-css-prefix}-lock-keyhole-open:before, .fa-duotone.#{$fa-css-prefix}-lock-keyhole-open:before { content: fa-content(\f3c2\fe01); }
.fad.#{$fa-css-prefix}-lock-keyhole-open:after, .fa-duotone.#{$fa-css-prefix}-lock-keyhole-open:after { content: fa-content(\f3c2\fe02); }
.fad.#{$fa-css-prefix}-lock-open-alt:before, .fa-duotone.#{$fa-css-prefix}-lock-open-alt:before { content: fa-content(\f3c2\fe01); }
.fad.#{$fa-css-prefix}-lock-open-alt:after, .fa-duotone.#{$fa-css-prefix}-lock-open-alt:after { content: fa-content(\f3c2\fe02); }
.fad.#{$fa-css-prefix}-lock-open:before, .fa-duotone.#{$fa-css-prefix}-lock-open:before { content: fa-content(\f3c1\fe01); }
.fad.#{$fa-css-prefix}-lock-open:after, .fa-duotone.#{$fa-css-prefix}-lock-open:after { content: fa-content(\f3c1\fe02); }
.fad.#{$fa-css-prefix}-loveseat:before, .fa-duotone.#{$fa-css-prefix}-loveseat:before { content: fa-content(\f4cc\fe01); }
.fad.#{$fa-css-prefix}-loveseat:after, .fa-duotone.#{$fa-css-prefix}-loveseat:after { content: fa-content(\f4cc\fe02); }
.fad.#{$fa-css-prefix}-couch-small:before, .fa-duotone.#{$fa-css-prefix}-couch-small:before { content: fa-content(\f4cc\fe01); }
.fad.#{$fa-css-prefix}-couch-small:after, .fa-duotone.#{$fa-css-prefix}-couch-small:after { content: fa-content(\f4cc\fe02); }
.fad.#{$fa-css-prefix}-luchador-mask:before, .fa-duotone.#{$fa-css-prefix}-luchador-mask:before { content: fa-content(\f455\fe01); }
.fad.#{$fa-css-prefix}-luchador-mask:after, .fa-duotone.#{$fa-css-prefix}-luchador-mask:after { content: fa-content(\f455\fe02); }
.fad.#{$fa-css-prefix}-luchador:before, .fa-duotone.#{$fa-css-prefix}-luchador:before { content: fa-content(\f455\fe01); }
.fad.#{$fa-css-prefix}-luchador:after, .fa-duotone.#{$fa-css-prefix}-luchador:after { content: fa-content(\f455\fe02); }
.fad.#{$fa-css-prefix}-mask-luchador:before, .fa-duotone.#{$fa-css-prefix}-mask-luchador:before { content: fa-content(\f455\fe01); }
.fad.#{$fa-css-prefix}-mask-luchador:after, .fa-duotone.#{$fa-css-prefix}-mask-luchador:after { content: fa-content(\f455\fe02); }
.fad.#{$fa-css-prefix}-lungs:before, .fa-duotone.#{$fa-css-prefix}-lungs:before { content: fa-content(\f604); }
.fad.#{$fa-css-prefix}-lungs:after, .fa-duotone.#{$fa-css-prefix}-lungs:after { content: fa-content(\10f604); }
.fad.#{$fa-css-prefix}-lungs-virus:before, .fa-duotone.#{$fa-css-prefix}-lungs-virus:before { content: fa-content(\e067\fe01); }
.fad.#{$fa-css-prefix}-lungs-virus:after, .fa-duotone.#{$fa-css-prefix}-lungs-virus:after { content: fa-content(\e067\fe02); }
.fad.#{$fa-css-prefix}-m:before, .fa-duotone.#{$fa-css-prefix}-m:before { content: fa-content(\4d\fe01); }
.fad.#{$fa-css-prefix}-m:after, .fa-duotone.#{$fa-css-prefix}-m:after { content: fa-content(\4d\fe02); }
.fad.#{$fa-css-prefix}-mace:before, .fa-duotone.#{$fa-css-prefix}-mace:before { content: fa-content(\f6f8\fe01); }
.fad.#{$fa-css-prefix}-mace:after, .fa-duotone.#{$fa-css-prefix}-mace:after { content: fa-content(\f6f8\fe02); }
.fad.#{$fa-css-prefix}-magnet:before, .fa-duotone.#{$fa-css-prefix}-magnet:before { content: fa-content(\f076); }
.fad.#{$fa-css-prefix}-magnet:after, .fa-duotone.#{$fa-css-prefix}-magnet:after { content: fa-content(\10f076); }
.fad.#{$fa-css-prefix}-magnifying-glass:before, .fa-duotone.#{$fa-css-prefix}-magnifying-glass:before { content: fa-content(\f002); }
.fad.#{$fa-css-prefix}-magnifying-glass:after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass:after { content: fa-content(\10f002); }
.fad.#{$fa-css-prefix}-search:before, .fa-duotone.#{$fa-css-prefix}-search:before { content: fa-content(\f002); }
.fad.#{$fa-css-prefix}-search:after, .fa-duotone.#{$fa-css-prefix}-search:after { content: fa-content(\10f002); }
.fad.#{$fa-css-prefix}-magnifying-glass-dollar:before, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-dollar:before { content: fa-content(\f688\fe01); }
.fad.#{$fa-css-prefix}-magnifying-glass-dollar:after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-dollar:after { content: fa-content(\f688\fe02); }
.fad.#{$fa-css-prefix}-search-dollar:before, .fa-duotone.#{$fa-css-prefix}-search-dollar:before { content: fa-content(\f688\fe01); }
.fad.#{$fa-css-prefix}-search-dollar:after, .fa-duotone.#{$fa-css-prefix}-search-dollar:after { content: fa-content(\f688\fe02); }
.fad.#{$fa-css-prefix}-magnifying-glass-location:before, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-location:before { content: fa-content(\f689\fe01); }
.fad.#{$fa-css-prefix}-magnifying-glass-location:after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-location:after { content: fa-content(\f689\fe02); }
.fad.#{$fa-css-prefix}-search-location:before, .fa-duotone.#{$fa-css-prefix}-search-location:before { content: fa-content(\f689\fe01); }
.fad.#{$fa-css-prefix}-search-location:after, .fa-duotone.#{$fa-css-prefix}-search-location:after { content: fa-content(\f689\fe02); }
.fad.#{$fa-css-prefix}-magnifying-glass-minus:before, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-minus:before { content: fa-content(\f010\fe01); }
.fad.#{$fa-css-prefix}-magnifying-glass-minus:after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-minus:after { content: fa-content(\f010\fe02); }
.fad.#{$fa-css-prefix}-search-minus:before, .fa-duotone.#{$fa-css-prefix}-search-minus:before { content: fa-content(\f010\fe01); }
.fad.#{$fa-css-prefix}-search-minus:after, .fa-duotone.#{$fa-css-prefix}-search-minus:after { content: fa-content(\f010\fe02); }
.fad.#{$fa-css-prefix}-magnifying-glass-plus:before, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-plus:before { content: fa-content(\f00e\fe01); }
.fad.#{$fa-css-prefix}-magnifying-glass-plus:after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-plus:after { content: fa-content(\f00e\fe02); }
.fad.#{$fa-css-prefix}-search-plus:before, .fa-duotone.#{$fa-css-prefix}-search-plus:before { content: fa-content(\f00e\fe01); }
.fad.#{$fa-css-prefix}-search-plus:after, .fa-duotone.#{$fa-css-prefix}-search-plus:after { content: fa-content(\f00e\fe02); }
.fad.#{$fa-css-prefix}-mailbox:before, .fa-duotone.#{$fa-css-prefix}-mailbox:before { content: fa-content(\f813); }
.fad.#{$fa-css-prefix}-mailbox:after, .fa-duotone.#{$fa-css-prefix}-mailbox:after { content: fa-content(\10f813); }
.fad.#{$fa-css-prefix}-manat-sign:before, .fa-duotone.#{$fa-css-prefix}-manat-sign:before { content: fa-content(\e1d5\fe01); }
.fad.#{$fa-css-prefix}-manat-sign:after, .fa-duotone.#{$fa-css-prefix}-manat-sign:after { content: fa-content(\e1d5\fe02); }
.fad.#{$fa-css-prefix}-mandolin:before, .fa-duotone.#{$fa-css-prefix}-mandolin:before { content: fa-content(\f6f9\fe01); }
.fad.#{$fa-css-prefix}-mandolin:after, .fa-duotone.#{$fa-css-prefix}-mandolin:after { content: fa-content(\f6f9\fe02); }
.fad.#{$fa-css-prefix}-mango:before, .fa-duotone.#{$fa-css-prefix}-mango:before { content: fa-content(\e30f\fe01); }
.fad.#{$fa-css-prefix}-mango:after, .fa-duotone.#{$fa-css-prefix}-mango:after { content: fa-content(\e30f\fe02); }
.fad.#{$fa-css-prefix}-manhole:before, .fa-duotone.#{$fa-css-prefix}-manhole:before { content: fa-content(\e1d6\fe01); }
.fad.#{$fa-css-prefix}-manhole:after, .fa-duotone.#{$fa-css-prefix}-manhole:after { content: fa-content(\e1d6\fe02); }
.fad.#{$fa-css-prefix}-map:before, .fa-duotone.#{$fa-css-prefix}-map:before { content: fa-content(\f279); }
.fad.#{$fa-css-prefix}-map:after, .fa-duotone.#{$fa-css-prefix}-map:after { content: fa-content(\10f279); }
.fad.#{$fa-css-prefix}-map-location:before, .fa-duotone.#{$fa-css-prefix}-map-location:before { content: fa-content(\f59f\fe01); }
.fad.#{$fa-css-prefix}-map-location:after, .fa-duotone.#{$fa-css-prefix}-map-location:after { content: fa-content(\f59f\fe02); }
.fad.#{$fa-css-prefix}-map-marked:before, .fa-duotone.#{$fa-css-prefix}-map-marked:before { content: fa-content(\f59f\fe01); }
.fad.#{$fa-css-prefix}-map-marked:after, .fa-duotone.#{$fa-css-prefix}-map-marked:after { content: fa-content(\f59f\fe02); }
.fad.#{$fa-css-prefix}-map-location-dot:before, .fa-duotone.#{$fa-css-prefix}-map-location-dot:before { content: fa-content(\f5a0\fe01); }
.fad.#{$fa-css-prefix}-map-location-dot:after, .fa-duotone.#{$fa-css-prefix}-map-location-dot:after { content: fa-content(\f5a0\fe02); }
.fad.#{$fa-css-prefix}-map-marked-alt:before, .fa-duotone.#{$fa-css-prefix}-map-marked-alt:before { content: fa-content(\f5a0\fe01); }
.fad.#{$fa-css-prefix}-map-marked-alt:after, .fa-duotone.#{$fa-css-prefix}-map-marked-alt:after { content: fa-content(\f5a0\fe02); }
.fad.#{$fa-css-prefix}-map-pin:before, .fa-duotone.#{$fa-css-prefix}-map-pin:before { content: fa-content(\f276); }
.fad.#{$fa-css-prefix}-map-pin:after, .fa-duotone.#{$fa-css-prefix}-map-pin:after { content: fa-content(\10f276); }
.fad.#{$fa-css-prefix}-marker:before, .fa-duotone.#{$fa-css-prefix}-marker:before { content: fa-content(\f5a1\fe01); }
.fad.#{$fa-css-prefix}-marker:after, .fa-duotone.#{$fa-css-prefix}-marker:after { content: fa-content(\f5a1\fe02); }
.fad.#{$fa-css-prefix}-mars:before, .fa-duotone.#{$fa-css-prefix}-mars:before { content: fa-content(\2642\fe01); }
.fad.#{$fa-css-prefix}-mars:after, .fa-duotone.#{$fa-css-prefix}-mars:after { content: fa-content(\2642\fe02); }
.fad.#{$fa-css-prefix}-mars-and-venus:before, .fa-duotone.#{$fa-css-prefix}-mars-and-venus:before { content: fa-content(\26a5\fe01); }
.fad.#{$fa-css-prefix}-mars-and-venus:after, .fa-duotone.#{$fa-css-prefix}-mars-and-venus:after { content: fa-content(\26a5\fe02); }
.fad.#{$fa-css-prefix}-mars-double:before, .fa-duotone.#{$fa-css-prefix}-mars-double:before { content: fa-content(\26a3\fe01); }
.fad.#{$fa-css-prefix}-mars-double:after, .fa-duotone.#{$fa-css-prefix}-mars-double:after { content: fa-content(\26a3\fe02); }
.fad.#{$fa-css-prefix}-mars-stroke:before, .fa-duotone.#{$fa-css-prefix}-mars-stroke:before { content: fa-content(\26a6\fe01); }
.fad.#{$fa-css-prefix}-mars-stroke:after, .fa-duotone.#{$fa-css-prefix}-mars-stroke:after { content: fa-content(\26a6\fe02); }
.fad.#{$fa-css-prefix}-mars-stroke-right:before, .fa-duotone.#{$fa-css-prefix}-mars-stroke-right:before { content: fa-content(\26a9\fe01); }
.fad.#{$fa-css-prefix}-mars-stroke-right:after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-right:after { content: fa-content(\26a9\fe02); }
.fad.#{$fa-css-prefix}-mars-stroke-h:before, .fa-duotone.#{$fa-css-prefix}-mars-stroke-h:before { content: fa-content(\26a9\fe01); }
.fad.#{$fa-css-prefix}-mars-stroke-h:after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-h:after { content: fa-content(\26a9\fe02); }
.fad.#{$fa-css-prefix}-mars-stroke-up:before, .fa-duotone.#{$fa-css-prefix}-mars-stroke-up:before { content: fa-content(\26a8\fe01); }
.fad.#{$fa-css-prefix}-mars-stroke-up:after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-up:after { content: fa-content(\26a8\fe02); }
.fad.#{$fa-css-prefix}-mars-stroke-v:before, .fa-duotone.#{$fa-css-prefix}-mars-stroke-v:before { content: fa-content(\26a8\fe01); }
.fad.#{$fa-css-prefix}-mars-stroke-v:after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-v:after { content: fa-content(\26a8\fe02); }
.fad.#{$fa-css-prefix}-martini-glass:before, .fa-duotone.#{$fa-css-prefix}-martini-glass:before { content: fa-content(\f57b); }
.fad.#{$fa-css-prefix}-martini-glass:after, .fa-duotone.#{$fa-css-prefix}-martini-glass:after { content: fa-content(\10f57b); }
.fad.#{$fa-css-prefix}-glass-martini-alt:before, .fa-duotone.#{$fa-css-prefix}-glass-martini-alt:before { content: fa-content(\f57b); }
.fad.#{$fa-css-prefix}-glass-martini-alt:after, .fa-duotone.#{$fa-css-prefix}-glass-martini-alt:after { content: fa-content(\10f57b); }
.fad.#{$fa-css-prefix}-martini-glass-citrus:before, .fa-duotone.#{$fa-css-prefix}-martini-glass-citrus:before { content: fa-content(\f561\fe01); }
.fad.#{$fa-css-prefix}-martini-glass-citrus:after, .fa-duotone.#{$fa-css-prefix}-martini-glass-citrus:after { content: fa-content(\f561\fe02); }
.fad.#{$fa-css-prefix}-cocktail:before, .fa-duotone.#{$fa-css-prefix}-cocktail:before { content: fa-content(\f561\fe01); }
.fad.#{$fa-css-prefix}-cocktail:after, .fa-duotone.#{$fa-css-prefix}-cocktail:after { content: fa-content(\f561\fe02); }
.fad.#{$fa-css-prefix}-martini-glass-empty:before, .fa-duotone.#{$fa-css-prefix}-martini-glass-empty:before { content: fa-content(\f000\fe01); }
.fad.#{$fa-css-prefix}-martini-glass-empty:after, .fa-duotone.#{$fa-css-prefix}-martini-glass-empty:after { content: fa-content(\f000\fe02); }
.fad.#{$fa-css-prefix}-glass-martini:before, .fa-duotone.#{$fa-css-prefix}-glass-martini:before { content: fa-content(\f000\fe01); }
.fad.#{$fa-css-prefix}-glass-martini:after, .fa-duotone.#{$fa-css-prefix}-glass-martini:after { content: fa-content(\f000\fe02); }
.fad.#{$fa-css-prefix}-mask:before, .fa-duotone.#{$fa-css-prefix}-mask:before { content: fa-content(\f6fa\fe01); }
.fad.#{$fa-css-prefix}-mask:after, .fa-duotone.#{$fa-css-prefix}-mask:after { content: fa-content(\f6fa\fe02); }
.fad.#{$fa-css-prefix}-mask-face:before, .fa-duotone.#{$fa-css-prefix}-mask-face:before { content: fa-content(\e1d7\fe01); }
.fad.#{$fa-css-prefix}-mask-face:after, .fa-duotone.#{$fa-css-prefix}-mask-face:after { content: fa-content(\e1d7\fe02); }
.fad.#{$fa-css-prefix}-mask-snorkel:before, .fa-duotone.#{$fa-css-prefix}-mask-snorkel:before { content: fa-content(\e3b7\fe01); }
.fad.#{$fa-css-prefix}-mask-snorkel:after, .fa-duotone.#{$fa-css-prefix}-mask-snorkel:after { content: fa-content(\e3b7\fe02); }
.fad.#{$fa-css-prefix}-masks-theater:before, .fa-duotone.#{$fa-css-prefix}-masks-theater:before { content: fa-content(\f630); }
.fad.#{$fa-css-prefix}-masks-theater:after, .fa-duotone.#{$fa-css-prefix}-masks-theater:after { content: fa-content(\10f630); }
.fad.#{$fa-css-prefix}-theater-masks:before, .fa-duotone.#{$fa-css-prefix}-theater-masks:before { content: fa-content(\f630); }
.fad.#{$fa-css-prefix}-theater-masks:after, .fa-duotone.#{$fa-css-prefix}-theater-masks:after { content: fa-content(\10f630); }
.fad.#{$fa-css-prefix}-maximize:before, .fa-duotone.#{$fa-css-prefix}-maximize:before { content: fa-content(\f31e\fe01); }
.fad.#{$fa-css-prefix}-maximize:after, .fa-duotone.#{$fa-css-prefix}-maximize:after { content: fa-content(\f31e\fe02); }
.fad.#{$fa-css-prefix}-expand-arrows-alt:before, .fa-duotone.#{$fa-css-prefix}-expand-arrows-alt:before { content: fa-content(\f31e\fe01); }
.fad.#{$fa-css-prefix}-expand-arrows-alt:after, .fa-duotone.#{$fa-css-prefix}-expand-arrows-alt:after { content: fa-content(\f31e\fe02); }
.fad.#{$fa-css-prefix}-meat:before, .fa-duotone.#{$fa-css-prefix}-meat:before { content: fa-content(\f814); }
.fad.#{$fa-css-prefix}-meat:after, .fa-duotone.#{$fa-css-prefix}-meat:after { content: fa-content(\10f814); }
.fad.#{$fa-css-prefix}-medal:before, .fa-duotone.#{$fa-css-prefix}-medal:before { content: fa-content(\f5a2); }
.fad.#{$fa-css-prefix}-medal:after, .fa-duotone.#{$fa-css-prefix}-medal:after { content: fa-content(\10f5a2); }
.fad.#{$fa-css-prefix}-megaphone:before, .fa-duotone.#{$fa-css-prefix}-megaphone:before { content: fa-content(\f675); }
.fad.#{$fa-css-prefix}-megaphone:after, .fa-duotone.#{$fa-css-prefix}-megaphone:after { content: fa-content(\10f675); }
.fad.#{$fa-css-prefix}-melon:before, .fa-duotone.#{$fa-css-prefix}-melon:before { content: fa-content(\e310\fe01); }
.fad.#{$fa-css-prefix}-melon:after, .fa-duotone.#{$fa-css-prefix}-melon:after { content: fa-content(\e310\fe02); }
.fad.#{$fa-css-prefix}-melon-slice:before, .fa-duotone.#{$fa-css-prefix}-melon-slice:before { content: fa-content(\e311\fe01); }
.fad.#{$fa-css-prefix}-melon-slice:after, .fa-duotone.#{$fa-css-prefix}-melon-slice:after { content: fa-content(\e311\fe02); }
.fad.#{$fa-css-prefix}-memo:before, .fa-duotone.#{$fa-css-prefix}-memo:before { content: fa-content(\e1d8\fe01); }
.fad.#{$fa-css-prefix}-memo:after, .fa-duotone.#{$fa-css-prefix}-memo:after { content: fa-content(\e1d8\fe02); }
.fad.#{$fa-css-prefix}-memo-circle-check:before, .fa-duotone.#{$fa-css-prefix}-memo-circle-check:before { content: fa-content(\e1d9\fe01); }
.fad.#{$fa-css-prefix}-memo-circle-check:after, .fa-duotone.#{$fa-css-prefix}-memo-circle-check:after { content: fa-content(\e1d9\fe02); }
.fad.#{$fa-css-prefix}-memo-pad:before, .fa-duotone.#{$fa-css-prefix}-memo-pad:before { content: fa-content(\e1da\fe01); }
.fad.#{$fa-css-prefix}-memo-pad:after, .fa-duotone.#{$fa-css-prefix}-memo-pad:after { content: fa-content(\e1da\fe02); }
.fad.#{$fa-css-prefix}-memory:before, .fa-duotone.#{$fa-css-prefix}-memory:before { content: fa-content(\f538\fe01); }
.fad.#{$fa-css-prefix}-memory:after, .fa-duotone.#{$fa-css-prefix}-memory:after { content: fa-content(\f538\fe02); }
.fad.#{$fa-css-prefix}-menorah:before, .fa-duotone.#{$fa-css-prefix}-menorah:before { content: fa-content(\f676\fe01); }
.fad.#{$fa-css-prefix}-menorah:after, .fa-duotone.#{$fa-css-prefix}-menorah:after { content: fa-content(\f676\fe02); }
.fad.#{$fa-css-prefix}-mercury:before, .fa-duotone.#{$fa-css-prefix}-mercury:before { content: fa-content(\263f\fe01); }
.fad.#{$fa-css-prefix}-mercury:after, .fa-duotone.#{$fa-css-prefix}-mercury:after { content: fa-content(\263f\fe02); }
.fad.#{$fa-css-prefix}-message:before, .fa-duotone.#{$fa-css-prefix}-message:before { content: fa-content(\f27a\fe01); }
.fad.#{$fa-css-prefix}-message:after, .fa-duotone.#{$fa-css-prefix}-message:after { content: fa-content(\f27a\fe02); }
.fad.#{$fa-css-prefix}-comment-alt:before, .fa-duotone.#{$fa-css-prefix}-comment-alt:before { content: fa-content(\f27a\fe01); }
.fad.#{$fa-css-prefix}-comment-alt:after, .fa-duotone.#{$fa-css-prefix}-comment-alt:after { content: fa-content(\f27a\fe02); }
.fad.#{$fa-css-prefix}-message-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-message-arrow-down:before { content: fa-content(\e1db\fe01); }
.fad.#{$fa-css-prefix}-message-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-message-arrow-down:after { content: fa-content(\e1db\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-arrow-down:before { content: fa-content(\e1db\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-arrow-down:after { content: fa-content(\e1db\fe02); }
.fad.#{$fa-css-prefix}-message-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-message-arrow-up:before { content: fa-content(\e1dc\fe01); }
.fad.#{$fa-css-prefix}-message-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-message-arrow-up:after { content: fa-content(\e1dc\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-arrow-up:before { content: fa-content(\e1dc\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-arrow-up:after { content: fa-content(\e1dc\fe02); }
.fad.#{$fa-css-prefix}-message-arrow-up-right:before, .fa-duotone.#{$fa-css-prefix}-message-arrow-up-right:before { content: fa-content(\e1dd\fe01); }
.fad.#{$fa-css-prefix}-message-arrow-up-right:after, .fa-duotone.#{$fa-css-prefix}-message-arrow-up-right:after { content: fa-content(\e1dd\fe02); }
.fad.#{$fa-css-prefix}-message-bot:before, .fa-duotone.#{$fa-css-prefix}-message-bot:before { content: fa-content(\e3b8\fe01); }
.fad.#{$fa-css-prefix}-message-bot:after, .fa-duotone.#{$fa-css-prefix}-message-bot:after { content: fa-content(\e3b8\fe02); }
.fad.#{$fa-css-prefix}-message-captions:before, .fa-duotone.#{$fa-css-prefix}-message-captions:before { content: fa-content(\e1de\fe01); }
.fad.#{$fa-css-prefix}-message-captions:after, .fa-duotone.#{$fa-css-prefix}-message-captions:after { content: fa-content(\e1de\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-captions:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-captions:before { content: fa-content(\e1de\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-captions:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-captions:after { content: fa-content(\e1de\fe02); }
.fad.#{$fa-css-prefix}-message-check:before, .fa-duotone.#{$fa-css-prefix}-message-check:before { content: fa-content(\f4a2\fe01); }
.fad.#{$fa-css-prefix}-message-check:after, .fa-duotone.#{$fa-css-prefix}-message-check:after { content: fa-content(\f4a2\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-check:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-check:before { content: fa-content(\f4a2\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-check:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-check:after { content: fa-content(\f4a2\fe02); }
.fad.#{$fa-css-prefix}-message-code:before, .fa-duotone.#{$fa-css-prefix}-message-code:before { content: fa-content(\e1df\fe01); }
.fad.#{$fa-css-prefix}-message-code:after, .fa-duotone.#{$fa-css-prefix}-message-code:after { content: fa-content(\e1df\fe02); }
.fad.#{$fa-css-prefix}-message-dollar:before, .fa-duotone.#{$fa-css-prefix}-message-dollar:before { content: fa-content(\f650\fe01); }
.fad.#{$fa-css-prefix}-message-dollar:after, .fa-duotone.#{$fa-css-prefix}-message-dollar:after { content: fa-content(\f650\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-dollar:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-dollar:before { content: fa-content(\f650\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-dollar:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-dollar:after { content: fa-content(\f650\fe02); }
.fad.#{$fa-css-prefix}-message-dots:before, .fa-duotone.#{$fa-css-prefix}-message-dots:before { content: fa-content(\f4a3\fe01); }
.fad.#{$fa-css-prefix}-message-dots:after, .fa-duotone.#{$fa-css-prefix}-message-dots:after { content: fa-content(\f4a3\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-dots:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-dots:before { content: fa-content(\f4a3\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-dots:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-dots:after { content: fa-content(\f4a3\fe02); }
.fad.#{$fa-css-prefix}-messaging:before, .fa-duotone.#{$fa-css-prefix}-messaging:before { content: fa-content(\f4a3\fe01); }
.fad.#{$fa-css-prefix}-messaging:after, .fa-duotone.#{$fa-css-prefix}-messaging:after { content: fa-content(\f4a3\fe02); }
.fad.#{$fa-css-prefix}-message-exclamation:before, .fa-duotone.#{$fa-css-prefix}-message-exclamation:before { content: fa-content(\f4a5\fe01); }
.fad.#{$fa-css-prefix}-message-exclamation:after, .fa-duotone.#{$fa-css-prefix}-message-exclamation:after { content: fa-content(\f4a5\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-exclamation:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-exclamation:before { content: fa-content(\f4a5\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-exclamation:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-exclamation:after { content: fa-content(\f4a5\fe02); }
.fad.#{$fa-css-prefix}-message-image:before, .fa-duotone.#{$fa-css-prefix}-message-image:before { content: fa-content(\e1e0\fe01); }
.fad.#{$fa-css-prefix}-message-image:after, .fa-duotone.#{$fa-css-prefix}-message-image:after { content: fa-content(\e1e0\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-image:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-image:before { content: fa-content(\e1e0\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-image:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-image:after { content: fa-content(\e1e0\fe02); }
.fad.#{$fa-css-prefix}-message-lines:before, .fa-duotone.#{$fa-css-prefix}-message-lines:before { content: fa-content(\f4a6\fe01); }
.fad.#{$fa-css-prefix}-message-lines:after, .fa-duotone.#{$fa-css-prefix}-message-lines:after { content: fa-content(\f4a6\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-lines:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-lines:before { content: fa-content(\f4a6\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-lines:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-lines:after { content: fa-content(\f4a6\fe02); }
.fad.#{$fa-css-prefix}-message-medical:before, .fa-duotone.#{$fa-css-prefix}-message-medical:before { content: fa-content(\f7f4\fe01); }
.fad.#{$fa-css-prefix}-message-medical:after, .fa-duotone.#{$fa-css-prefix}-message-medical:after { content: fa-content(\f7f4\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-medical:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-medical:before { content: fa-content(\f7f4\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-medical:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-medical:after { content: fa-content(\f7f4\fe02); }
.fad.#{$fa-css-prefix}-message-middle:before, .fa-duotone.#{$fa-css-prefix}-message-middle:before { content: fa-content(\e1e1\fe01); }
.fad.#{$fa-css-prefix}-message-middle:after, .fa-duotone.#{$fa-css-prefix}-message-middle:after { content: fa-content(\e1e1\fe02); }
.fad.#{$fa-css-prefix}-comment-middle-alt:before, .fa-duotone.#{$fa-css-prefix}-comment-middle-alt:before { content: fa-content(\e1e1\fe01); }
.fad.#{$fa-css-prefix}-comment-middle-alt:after, .fa-duotone.#{$fa-css-prefix}-comment-middle-alt:after { content: fa-content(\e1e1\fe02); }
.fad.#{$fa-css-prefix}-message-middle-top:before, .fa-duotone.#{$fa-css-prefix}-message-middle-top:before { content: fa-content(\e1e2\fe01); }
.fad.#{$fa-css-prefix}-message-middle-top:after, .fa-duotone.#{$fa-css-prefix}-message-middle-top:after { content: fa-content(\e1e2\fe02); }
.fad.#{$fa-css-prefix}-comment-middle-top-alt:before, .fa-duotone.#{$fa-css-prefix}-comment-middle-top-alt:before { content: fa-content(\e1e2\fe01); }
.fad.#{$fa-css-prefix}-comment-middle-top-alt:after, .fa-duotone.#{$fa-css-prefix}-comment-middle-top-alt:after { content: fa-content(\e1e2\fe02); }
.fad.#{$fa-css-prefix}-message-minus:before, .fa-duotone.#{$fa-css-prefix}-message-minus:before { content: fa-content(\f4a7\fe01); }
.fad.#{$fa-css-prefix}-message-minus:after, .fa-duotone.#{$fa-css-prefix}-message-minus:after { content: fa-content(\f4a7\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-minus:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-minus:before { content: fa-content(\f4a7\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-minus:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-minus:after { content: fa-content(\f4a7\fe02); }
.fad.#{$fa-css-prefix}-message-music:before, .fa-duotone.#{$fa-css-prefix}-message-music:before { content: fa-content(\f8af\fe01); }
.fad.#{$fa-css-prefix}-message-music:after, .fa-duotone.#{$fa-css-prefix}-message-music:after { content: fa-content(\f8af\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-music:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-music:before { content: fa-content(\f8af\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-music:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-music:after { content: fa-content(\f8af\fe02); }
.fad.#{$fa-css-prefix}-message-pen:before, .fa-duotone.#{$fa-css-prefix}-message-pen:before { content: fa-content(\f4a4\fe01); }
.fad.#{$fa-css-prefix}-message-pen:after, .fa-duotone.#{$fa-css-prefix}-message-pen:after { content: fa-content(\f4a4\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-edit:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-edit:before { content: fa-content(\f4a4\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-edit:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-edit:after { content: fa-content(\f4a4\fe02); }
.fad.#{$fa-css-prefix}-message-edit:before, .fa-duotone.#{$fa-css-prefix}-message-edit:before { content: fa-content(\f4a4\fe01); }
.fad.#{$fa-css-prefix}-message-edit:after, .fa-duotone.#{$fa-css-prefix}-message-edit:after { content: fa-content(\f4a4\fe02); }
.fad.#{$fa-css-prefix}-message-plus:before, .fa-duotone.#{$fa-css-prefix}-message-plus:before { content: fa-content(\f4a8\fe01); }
.fad.#{$fa-css-prefix}-message-plus:after, .fa-duotone.#{$fa-css-prefix}-message-plus:after { content: fa-content(\f4a8\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-plus:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-plus:before { content: fa-content(\f4a8\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-plus:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-plus:after { content: fa-content(\f4a8\fe02); }
.fad.#{$fa-css-prefix}-message-question:before, .fa-duotone.#{$fa-css-prefix}-message-question:before { content: fa-content(\e1e3\fe01); }
.fad.#{$fa-css-prefix}-message-question:after, .fa-duotone.#{$fa-css-prefix}-message-question:after { content: fa-content(\e1e3\fe02); }
.fad.#{$fa-css-prefix}-message-quote:before, .fa-duotone.#{$fa-css-prefix}-message-quote:before { content: fa-content(\e1e4\fe01); }
.fad.#{$fa-css-prefix}-message-quote:after, .fa-duotone.#{$fa-css-prefix}-message-quote:after { content: fa-content(\e1e4\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-quote:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-quote:before { content: fa-content(\e1e4\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-quote:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-quote:after { content: fa-content(\e1e4\fe02); }
.fad.#{$fa-css-prefix}-message-slash:before, .fa-duotone.#{$fa-css-prefix}-message-slash:before { content: fa-content(\f4a9\fe01); }
.fad.#{$fa-css-prefix}-message-slash:after, .fa-duotone.#{$fa-css-prefix}-message-slash:after { content: fa-content(\f4a9\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-slash:before { content: fa-content(\f4a9\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-slash:after { content: fa-content(\f4a9\fe02); }
.fad.#{$fa-css-prefix}-message-smile:before, .fa-duotone.#{$fa-css-prefix}-message-smile:before { content: fa-content(\f4aa\fe01); }
.fad.#{$fa-css-prefix}-message-smile:after, .fa-duotone.#{$fa-css-prefix}-message-smile:after { content: fa-content(\f4aa\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-smile:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-smile:before { content: fa-content(\f4aa\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-smile:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-smile:after { content: fa-content(\f4aa\fe02); }
.fad.#{$fa-css-prefix}-message-sms:before, .fa-duotone.#{$fa-css-prefix}-message-sms:before { content: fa-content(\e1e5\fe01); }
.fad.#{$fa-css-prefix}-message-sms:after, .fa-duotone.#{$fa-css-prefix}-message-sms:after { content: fa-content(\e1e5\fe02); }
.fad.#{$fa-css-prefix}-message-text:before, .fa-duotone.#{$fa-css-prefix}-message-text:before { content: fa-content(\e1e6\fe01); }
.fad.#{$fa-css-prefix}-message-text:after, .fa-duotone.#{$fa-css-prefix}-message-text:after { content: fa-content(\e1e6\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-text:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-text:before { content: fa-content(\e1e6\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-text:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-text:after { content: fa-content(\e1e6\fe02); }
.fad.#{$fa-css-prefix}-message-xmark:before, .fa-duotone.#{$fa-css-prefix}-message-xmark:before { content: fa-content(\f4ab\fe01); }
.fad.#{$fa-css-prefix}-message-xmark:after, .fa-duotone.#{$fa-css-prefix}-message-xmark:after { content: fa-content(\f4ab\fe02); }
.fad.#{$fa-css-prefix}-comment-alt-times:before, .fa-duotone.#{$fa-css-prefix}-comment-alt-times:before { content: fa-content(\f4ab\fe01); }
.fad.#{$fa-css-prefix}-comment-alt-times:after, .fa-duotone.#{$fa-css-prefix}-comment-alt-times:after { content: fa-content(\f4ab\fe02); }
.fad.#{$fa-css-prefix}-message-times:before, .fa-duotone.#{$fa-css-prefix}-message-times:before { content: fa-content(\f4ab\fe01); }
.fad.#{$fa-css-prefix}-message-times:after, .fa-duotone.#{$fa-css-prefix}-message-times:after { content: fa-content(\f4ab\fe02); }
.fad.#{$fa-css-prefix}-messages:before, .fa-duotone.#{$fa-css-prefix}-messages:before { content: fa-content(\f4b6\fe01); }
.fad.#{$fa-css-prefix}-messages:after, .fa-duotone.#{$fa-css-prefix}-messages:after { content: fa-content(\f4b6\fe02); }
.fad.#{$fa-css-prefix}-comments-alt:before, .fa-duotone.#{$fa-css-prefix}-comments-alt:before { content: fa-content(\f4b6\fe01); }
.fad.#{$fa-css-prefix}-comments-alt:after, .fa-duotone.#{$fa-css-prefix}-comments-alt:after { content: fa-content(\f4b6\fe02); }
.fad.#{$fa-css-prefix}-messages-dollar:before, .fa-duotone.#{$fa-css-prefix}-messages-dollar:before { content: fa-content(\f652\fe01); }
.fad.#{$fa-css-prefix}-messages-dollar:after, .fa-duotone.#{$fa-css-prefix}-messages-dollar:after { content: fa-content(\f652\fe02); }
.fad.#{$fa-css-prefix}-comments-alt-dollar:before, .fa-duotone.#{$fa-css-prefix}-comments-alt-dollar:before { content: fa-content(\f652\fe01); }
.fad.#{$fa-css-prefix}-comments-alt-dollar:after, .fa-duotone.#{$fa-css-prefix}-comments-alt-dollar:after { content: fa-content(\f652\fe02); }
.fad.#{$fa-css-prefix}-messages-question:before, .fa-duotone.#{$fa-css-prefix}-messages-question:before { content: fa-content(\e1e7\fe01); }
.fad.#{$fa-css-prefix}-messages-question:after, .fa-duotone.#{$fa-css-prefix}-messages-question:after { content: fa-content(\e1e7\fe02); }
.fad.#{$fa-css-prefix}-meteor:before, .fa-duotone.#{$fa-css-prefix}-meteor:before { content: fa-content(\2604\fe01); }
.fad.#{$fa-css-prefix}-meteor:after, .fa-duotone.#{$fa-css-prefix}-meteor:after { content: fa-content(\2604\fe02); }
.fad.#{$fa-css-prefix}-meter:before, .fa-duotone.#{$fa-css-prefix}-meter:before { content: fa-content(\e1e8\fe01); }
.fad.#{$fa-css-prefix}-meter:after, .fa-duotone.#{$fa-css-prefix}-meter:after { content: fa-content(\e1e8\fe02); }
.fad.#{$fa-css-prefix}-meter-bolt:before, .fa-duotone.#{$fa-css-prefix}-meter-bolt:before { content: fa-content(\e1e9\fe01); }
.fad.#{$fa-css-prefix}-meter-bolt:after, .fa-duotone.#{$fa-css-prefix}-meter-bolt:after { content: fa-content(\e1e9\fe02); }
.fad.#{$fa-css-prefix}-meter-droplet:before, .fa-duotone.#{$fa-css-prefix}-meter-droplet:before { content: fa-content(\e1ea\fe01); }
.fad.#{$fa-css-prefix}-meter-droplet:after, .fa-duotone.#{$fa-css-prefix}-meter-droplet:after { content: fa-content(\e1ea\fe02); }
.fad.#{$fa-css-prefix}-meter-fire:before, .fa-duotone.#{$fa-css-prefix}-meter-fire:before { content: fa-content(\e1eb\fe01); }
.fad.#{$fa-css-prefix}-meter-fire:after, .fa-duotone.#{$fa-css-prefix}-meter-fire:after { content: fa-content(\e1eb\fe02); }
.fad.#{$fa-css-prefix}-microchip:before, .fa-duotone.#{$fa-css-prefix}-microchip:before { content: fa-content(\f2db\fe01); }
.fad.#{$fa-css-prefix}-microchip:after, .fa-duotone.#{$fa-css-prefix}-microchip:after { content: fa-content(\f2db\fe02); }
.fad.#{$fa-css-prefix}-microchip-ai:before, .fa-duotone.#{$fa-css-prefix}-microchip-ai:before { content: fa-content(\e1ec\fe01); }
.fad.#{$fa-css-prefix}-microchip-ai:after, .fa-duotone.#{$fa-css-prefix}-microchip-ai:after { content: fa-content(\e1ec\fe02); }
.fad.#{$fa-css-prefix}-microphone:before, .fa-duotone.#{$fa-css-prefix}-microphone:before { content: fa-content(\f130\fe01); }
.fad.#{$fa-css-prefix}-microphone:after, .fa-duotone.#{$fa-css-prefix}-microphone:after { content: fa-content(\f130\fe02); }
.fad.#{$fa-css-prefix}-microphone-lines:before, .fa-duotone.#{$fa-css-prefix}-microphone-lines:before { content: fa-content(\f3c9); }
.fad.#{$fa-css-prefix}-microphone-lines:after, .fa-duotone.#{$fa-css-prefix}-microphone-lines:after { content: fa-content(\10f3c9); }
.fad.#{$fa-css-prefix}-microphone-alt:before, .fa-duotone.#{$fa-css-prefix}-microphone-alt:before { content: fa-content(\f3c9); }
.fad.#{$fa-css-prefix}-microphone-alt:after, .fa-duotone.#{$fa-css-prefix}-microphone-alt:after { content: fa-content(\10f3c9); }
.fad.#{$fa-css-prefix}-microphone-lines-slash:before, .fa-duotone.#{$fa-css-prefix}-microphone-lines-slash:before { content: fa-content(\f539\fe01); }
.fad.#{$fa-css-prefix}-microphone-lines-slash:after, .fa-duotone.#{$fa-css-prefix}-microphone-lines-slash:after { content: fa-content(\f539\fe02); }
.fad.#{$fa-css-prefix}-microphone-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-microphone-alt-slash:before { content: fa-content(\f539\fe01); }
.fad.#{$fa-css-prefix}-microphone-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-microphone-alt-slash:after { content: fa-content(\f539\fe02); }
.fad.#{$fa-css-prefix}-microphone-slash:before, .fa-duotone.#{$fa-css-prefix}-microphone-slash:before { content: fa-content(\f131\fe01); }
.fad.#{$fa-css-prefix}-microphone-slash:after, .fa-duotone.#{$fa-css-prefix}-microphone-slash:after { content: fa-content(\f131\fe02); }
.fad.#{$fa-css-prefix}-microphone-stand:before, .fa-duotone.#{$fa-css-prefix}-microphone-stand:before { content: fa-content(\f8cb); }
.fad.#{$fa-css-prefix}-microphone-stand:after, .fa-duotone.#{$fa-css-prefix}-microphone-stand:after { content: fa-content(\10f8cb); }
.fad.#{$fa-css-prefix}-microscope:before, .fa-duotone.#{$fa-css-prefix}-microscope:before { content: fa-content(\f610); }
.fad.#{$fa-css-prefix}-microscope:after, .fa-duotone.#{$fa-css-prefix}-microscope:after { content: fa-content(\10f610); }
.fad.#{$fa-css-prefix}-microwave:before, .fa-duotone.#{$fa-css-prefix}-microwave:before { content: fa-content(\e01b\fe01); }
.fad.#{$fa-css-prefix}-microwave:after, .fa-duotone.#{$fa-css-prefix}-microwave:after { content: fa-content(\e01b\fe02); }
.fad.#{$fa-css-prefix}-mill-sign:before, .fa-duotone.#{$fa-css-prefix}-mill-sign:before { content: fa-content(\e1ed\fe01); }
.fad.#{$fa-css-prefix}-mill-sign:after, .fa-duotone.#{$fa-css-prefix}-mill-sign:after { content: fa-content(\e1ed\fe02); }
.fad.#{$fa-css-prefix}-minimize:before, .fa-duotone.#{$fa-css-prefix}-minimize:before { content: fa-content(\f78c\fe01); }
.fad.#{$fa-css-prefix}-minimize:after, .fa-duotone.#{$fa-css-prefix}-minimize:after { content: fa-content(\f78c\fe02); }
.fad.#{$fa-css-prefix}-compress-arrows-alt:before, .fa-duotone.#{$fa-css-prefix}-compress-arrows-alt:before { content: fa-content(\f78c\fe01); }
.fad.#{$fa-css-prefix}-compress-arrows-alt:after, .fa-duotone.#{$fa-css-prefix}-compress-arrows-alt:after { content: fa-content(\f78c\fe02); }
.fad.#{$fa-css-prefix}-minus:before, .fa-duotone.#{$fa-css-prefix}-minus:before { content: fa-content(\2013\fe01); }
.fad.#{$fa-css-prefix}-minus:after, .fa-duotone.#{$fa-css-prefix}-minus:after { content: fa-content(\2013\fe02); }
.fad.#{$fa-css-prefix}-subtract:before, .fa-duotone.#{$fa-css-prefix}-subtract:before { content: fa-content(\2013\fe01); }
.fad.#{$fa-css-prefix}-subtract:after, .fa-duotone.#{$fa-css-prefix}-subtract:after { content: fa-content(\2013\fe02); }
.fad.#{$fa-css-prefix}-mistletoe:before, .fa-duotone.#{$fa-css-prefix}-mistletoe:before { content: fa-content(\f7b4\fe01); }
.fad.#{$fa-css-prefix}-mistletoe:after, .fa-duotone.#{$fa-css-prefix}-mistletoe:after { content: fa-content(\f7b4\fe02); }
.fad.#{$fa-css-prefix}-mitten:before, .fa-duotone.#{$fa-css-prefix}-mitten:before { content: fa-content(\f7b5\fe01); }
.fad.#{$fa-css-prefix}-mitten:after, .fa-duotone.#{$fa-css-prefix}-mitten:after { content: fa-content(\f7b5\fe02); }
.fad.#{$fa-css-prefix}-mobile:before, .fa-duotone.#{$fa-css-prefix}-mobile:before { content: fa-content(\f3ce); }
.fad.#{$fa-css-prefix}-mobile:after, .fa-duotone.#{$fa-css-prefix}-mobile:after { content: fa-content(\10f3ce); }
.fad.#{$fa-css-prefix}-mobile-android:before, .fa-duotone.#{$fa-css-prefix}-mobile-android:before { content: fa-content(\f3ce); }
.fad.#{$fa-css-prefix}-mobile-android:after, .fa-duotone.#{$fa-css-prefix}-mobile-android:after { content: fa-content(\10f3ce); }
.fad.#{$fa-css-prefix}-mobile-phone:before, .fa-duotone.#{$fa-css-prefix}-mobile-phone:before { content: fa-content(\f3ce); }
.fad.#{$fa-css-prefix}-mobile-phone:after, .fa-duotone.#{$fa-css-prefix}-mobile-phone:after { content: fa-content(\10f3ce); }
.fad.#{$fa-css-prefix}-mobile-button:before, .fa-duotone.#{$fa-css-prefix}-mobile-button:before { content: fa-content(\f10b\fe01); }
.fad.#{$fa-css-prefix}-mobile-button:after, .fa-duotone.#{$fa-css-prefix}-mobile-button:after { content: fa-content(\f10b\fe02); }
.fad.#{$fa-css-prefix}-mobile-notch:before, .fa-duotone.#{$fa-css-prefix}-mobile-notch:before { content: fa-content(\e1ee\fe01); }
.fad.#{$fa-css-prefix}-mobile-notch:after, .fa-duotone.#{$fa-css-prefix}-mobile-notch:after { content: fa-content(\e1ee\fe02); }
.fad.#{$fa-css-prefix}-mobile-iphone:before, .fa-duotone.#{$fa-css-prefix}-mobile-iphone:before { content: fa-content(\e1ee\fe01); }
.fad.#{$fa-css-prefix}-mobile-iphone:after, .fa-duotone.#{$fa-css-prefix}-mobile-iphone:after { content: fa-content(\e1ee\fe02); }
.fad.#{$fa-css-prefix}-mobile-screen:before, .fa-duotone.#{$fa-css-prefix}-mobile-screen:before { content: fa-content(\f3cf\fe01); }
.fad.#{$fa-css-prefix}-mobile-screen:after, .fa-duotone.#{$fa-css-prefix}-mobile-screen:after { content: fa-content(\f3cf\fe02); }
.fad.#{$fa-css-prefix}-mobile-android-alt:before, .fa-duotone.#{$fa-css-prefix}-mobile-android-alt:before { content: fa-content(\f3cf\fe01); }
.fad.#{$fa-css-prefix}-mobile-android-alt:after, .fa-duotone.#{$fa-css-prefix}-mobile-android-alt:after { content: fa-content(\f3cf\fe02); }
.fad.#{$fa-css-prefix}-mobile-screen-button:before, .fa-duotone.#{$fa-css-prefix}-mobile-screen-button:before { content: fa-content(\f3cd\fe01); }
.fad.#{$fa-css-prefix}-mobile-screen-button:after, .fa-duotone.#{$fa-css-prefix}-mobile-screen-button:after { content: fa-content(\f3cd\fe02); }
.fad.#{$fa-css-prefix}-mobile-alt:before, .fa-duotone.#{$fa-css-prefix}-mobile-alt:before { content: fa-content(\f3cd\fe01); }
.fad.#{$fa-css-prefix}-mobile-alt:after, .fa-duotone.#{$fa-css-prefix}-mobile-alt:after { content: fa-content(\f3cd\fe02); }
.fad.#{$fa-css-prefix}-mobile-signal:before, .fa-duotone.#{$fa-css-prefix}-mobile-signal:before { content: fa-content(\e1ef\fe01); }
.fad.#{$fa-css-prefix}-mobile-signal:after, .fa-duotone.#{$fa-css-prefix}-mobile-signal:after { content: fa-content(\e1ef\fe02); }
.fad.#{$fa-css-prefix}-mobile-signal-out:before, .fa-duotone.#{$fa-css-prefix}-mobile-signal-out:before { content: fa-content(\e1f0\fe01); }
.fad.#{$fa-css-prefix}-mobile-signal-out:after, .fa-duotone.#{$fa-css-prefix}-mobile-signal-out:after { content: fa-content(\e1f0\fe02); }
.fad.#{$fa-css-prefix}-money-bill:before, .fa-duotone.#{$fa-css-prefix}-money-bill:before { content: fa-content(\f0d6\fe01); }
.fad.#{$fa-css-prefix}-money-bill:after, .fa-duotone.#{$fa-css-prefix}-money-bill:after { content: fa-content(\f0d6\fe02); }
.fad.#{$fa-css-prefix}-money-bill-1:before, .fa-duotone.#{$fa-css-prefix}-money-bill-1:before { content: fa-content(\f3d1\fe01); }
.fad.#{$fa-css-prefix}-money-bill-1:after, .fa-duotone.#{$fa-css-prefix}-money-bill-1:after { content: fa-content(\f3d1\fe02); }
.fad.#{$fa-css-prefix}-money-bill-alt:before, .fa-duotone.#{$fa-css-prefix}-money-bill-alt:before { content: fa-content(\f3d1\fe01); }
.fad.#{$fa-css-prefix}-money-bill-alt:after, .fa-duotone.#{$fa-css-prefix}-money-bill-alt:after { content: fa-content(\f3d1\fe02); }
.fad.#{$fa-css-prefix}-money-bill-1-wave:before, .fa-duotone.#{$fa-css-prefix}-money-bill-1-wave:before { content: fa-content(\f53b\fe01); }
.fad.#{$fa-css-prefix}-money-bill-1-wave:after, .fa-duotone.#{$fa-css-prefix}-money-bill-1-wave:after { content: fa-content(\f53b\fe02); }
.fad.#{$fa-css-prefix}-money-bill-wave-alt:before, .fa-duotone.#{$fa-css-prefix}-money-bill-wave-alt:before { content: fa-content(\f53b\fe01); }
.fad.#{$fa-css-prefix}-money-bill-wave-alt:after, .fa-duotone.#{$fa-css-prefix}-money-bill-wave-alt:after { content: fa-content(\f53b\fe02); }
.fad.#{$fa-css-prefix}-money-bill-simple:before, .fa-duotone.#{$fa-css-prefix}-money-bill-simple:before { content: fa-content(\e1f1\fe01); }
.fad.#{$fa-css-prefix}-money-bill-simple:after, .fa-duotone.#{$fa-css-prefix}-money-bill-simple:after { content: fa-content(\e1f1\fe02); }
.fad.#{$fa-css-prefix}-money-bill-simple-wave:before, .fa-duotone.#{$fa-css-prefix}-money-bill-simple-wave:before { content: fa-content(\e1f2\fe01); }
.fad.#{$fa-css-prefix}-money-bill-simple-wave:after, .fa-duotone.#{$fa-css-prefix}-money-bill-simple-wave:after { content: fa-content(\e1f2\fe02); }
.fad.#{$fa-css-prefix}-money-bill-wave:before, .fa-duotone.#{$fa-css-prefix}-money-bill-wave:before { content: fa-content(\f53a\fe01); }
.fad.#{$fa-css-prefix}-money-bill-wave:after, .fa-duotone.#{$fa-css-prefix}-money-bill-wave:after { content: fa-content(\f53a\fe02); }
.fad.#{$fa-css-prefix}-money-bills:before, .fa-duotone.#{$fa-css-prefix}-money-bills:before { content: fa-content(\e1f3\fe01); }
.fad.#{$fa-css-prefix}-money-bills:after, .fa-duotone.#{$fa-css-prefix}-money-bills:after { content: fa-content(\e1f3\fe02); }
.fad.#{$fa-css-prefix}-money-bills-simple:before, .fa-duotone.#{$fa-css-prefix}-money-bills-simple:before { content: fa-content(\e1f4\fe01); }
.fad.#{$fa-css-prefix}-money-bills-simple:after, .fa-duotone.#{$fa-css-prefix}-money-bills-simple:after { content: fa-content(\e1f4\fe02); }
.fad.#{$fa-css-prefix}-money-bills-alt:before, .fa-duotone.#{$fa-css-prefix}-money-bills-alt:before { content: fa-content(\e1f4\fe01); }
.fad.#{$fa-css-prefix}-money-bills-alt:after, .fa-duotone.#{$fa-css-prefix}-money-bills-alt:after { content: fa-content(\e1f4\fe02); }
.fad.#{$fa-css-prefix}-money-check:before, .fa-duotone.#{$fa-css-prefix}-money-check:before { content: fa-content(\f53c\fe01); }
.fad.#{$fa-css-prefix}-money-check:after, .fa-duotone.#{$fa-css-prefix}-money-check:after { content: fa-content(\f53c\fe02); }
.fad.#{$fa-css-prefix}-money-check-dollar:before, .fa-duotone.#{$fa-css-prefix}-money-check-dollar:before { content: fa-content(\f53d\fe01); }
.fad.#{$fa-css-prefix}-money-check-dollar:after, .fa-duotone.#{$fa-css-prefix}-money-check-dollar:after { content: fa-content(\f53d\fe02); }
.fad.#{$fa-css-prefix}-money-check-alt:before, .fa-duotone.#{$fa-css-prefix}-money-check-alt:before { content: fa-content(\f53d\fe01); }
.fad.#{$fa-css-prefix}-money-check-alt:after, .fa-duotone.#{$fa-css-prefix}-money-check-alt:after { content: fa-content(\f53d\fe02); }
.fad.#{$fa-css-prefix}-money-check-dollar-pen:before, .fa-duotone.#{$fa-css-prefix}-money-check-dollar-pen:before { content: fa-content(\f873\fe01); }
.fad.#{$fa-css-prefix}-money-check-dollar-pen:after, .fa-duotone.#{$fa-css-prefix}-money-check-dollar-pen:after { content: fa-content(\f873\fe02); }
.fad.#{$fa-css-prefix}-money-check-edit-alt:before, .fa-duotone.#{$fa-css-prefix}-money-check-edit-alt:before { content: fa-content(\f873\fe01); }
.fad.#{$fa-css-prefix}-money-check-edit-alt:after, .fa-duotone.#{$fa-css-prefix}-money-check-edit-alt:after { content: fa-content(\f873\fe02); }
.fad.#{$fa-css-prefix}-money-check-pen:before, .fa-duotone.#{$fa-css-prefix}-money-check-pen:before { content: fa-content(\f872\fe01); }
.fad.#{$fa-css-prefix}-money-check-pen:after, .fa-duotone.#{$fa-css-prefix}-money-check-pen:after { content: fa-content(\f872\fe02); }
.fad.#{$fa-css-prefix}-money-check-edit:before, .fa-duotone.#{$fa-css-prefix}-money-check-edit:before { content: fa-content(\f872\fe01); }
.fad.#{$fa-css-prefix}-money-check-edit:after, .fa-duotone.#{$fa-css-prefix}-money-check-edit:after { content: fa-content(\f872\fe02); }
.fad.#{$fa-css-prefix}-money-from-bracket:before, .fa-duotone.#{$fa-css-prefix}-money-from-bracket:before { content: fa-content(\e312\fe01); }
.fad.#{$fa-css-prefix}-money-from-bracket:after, .fa-duotone.#{$fa-css-prefix}-money-from-bracket:after { content: fa-content(\e312\fe02); }
.fad.#{$fa-css-prefix}-money-simple-from-bracket:before, .fa-duotone.#{$fa-css-prefix}-money-simple-from-bracket:before { content: fa-content(\e313\fe01); }
.fad.#{$fa-css-prefix}-money-simple-from-bracket:after, .fa-duotone.#{$fa-css-prefix}-money-simple-from-bracket:after { content: fa-content(\e313\fe02); }
.fad.#{$fa-css-prefix}-monitor-waveform:before, .fa-duotone.#{$fa-css-prefix}-monitor-waveform:before { content: fa-content(\f611\fe01); }
.fad.#{$fa-css-prefix}-monitor-waveform:after, .fa-duotone.#{$fa-css-prefix}-monitor-waveform:after { content: fa-content(\f611\fe02); }
.fad.#{$fa-css-prefix}-monitor-heart-rate:before, .fa-duotone.#{$fa-css-prefix}-monitor-heart-rate:before { content: fa-content(\f611\fe01); }
.fad.#{$fa-css-prefix}-monitor-heart-rate:after, .fa-duotone.#{$fa-css-prefix}-monitor-heart-rate:after { content: fa-content(\f611\fe02); }
.fad.#{$fa-css-prefix}-monkey:before, .fa-duotone.#{$fa-css-prefix}-monkey:before { content: fa-content(\f6fb); }
.fad.#{$fa-css-prefix}-monkey:after, .fa-duotone.#{$fa-css-prefix}-monkey:after { content: fa-content(\10f6fb); }
.fad.#{$fa-css-prefix}-monument:before, .fa-duotone.#{$fa-css-prefix}-monument:before { content: fa-content(\f5a6\fe01); }
.fad.#{$fa-css-prefix}-monument:after, .fa-duotone.#{$fa-css-prefix}-monument:after { content: fa-content(\f5a6\fe02); }
.fad.#{$fa-css-prefix}-moon:before, .fa-duotone.#{$fa-css-prefix}-moon:before { content: fa-content(\23fe\fe01); }
.fad.#{$fa-css-prefix}-moon:after, .fa-duotone.#{$fa-css-prefix}-moon:after { content: fa-content(\23fe\fe02); }
.fad.#{$fa-css-prefix}-moon-cloud:before, .fa-duotone.#{$fa-css-prefix}-moon-cloud:before { content: fa-content(\f754\fe01); }
.fad.#{$fa-css-prefix}-moon-cloud:after, .fa-duotone.#{$fa-css-prefix}-moon-cloud:after { content: fa-content(\f754\fe02); }
.fad.#{$fa-css-prefix}-moon-over-sun:before, .fa-duotone.#{$fa-css-prefix}-moon-over-sun:before { content: fa-content(\f74a\fe01); }
.fad.#{$fa-css-prefix}-moon-over-sun:after, .fa-duotone.#{$fa-css-prefix}-moon-over-sun:after { content: fa-content(\f74a\fe02); }
.fad.#{$fa-css-prefix}-eclipse-alt:before, .fa-duotone.#{$fa-css-prefix}-eclipse-alt:before { content: fa-content(\f74a\fe01); }
.fad.#{$fa-css-prefix}-eclipse-alt:after, .fa-duotone.#{$fa-css-prefix}-eclipse-alt:after { content: fa-content(\f74a\fe02); }
.fad.#{$fa-css-prefix}-moon-stars:before, .fa-duotone.#{$fa-css-prefix}-moon-stars:before { content: fa-content(\f755\fe01); }
.fad.#{$fa-css-prefix}-moon-stars:after, .fa-duotone.#{$fa-css-prefix}-moon-stars:after { content: fa-content(\f755\fe02); }
.fad.#{$fa-css-prefix}-moped:before, .fa-duotone.#{$fa-css-prefix}-moped:before { content: fa-content(\e3b9\fe01); }
.fad.#{$fa-css-prefix}-moped:after, .fa-duotone.#{$fa-css-prefix}-moped:after { content: fa-content(\e3b9\fe02); }
.fad.#{$fa-css-prefix}-mortar-pestle:before, .fa-duotone.#{$fa-css-prefix}-mortar-pestle:before { content: fa-content(\f5a7\fe01); }
.fad.#{$fa-css-prefix}-mortar-pestle:after, .fa-duotone.#{$fa-css-prefix}-mortar-pestle:after { content: fa-content(\f5a7\fe02); }
.fad.#{$fa-css-prefix}-mosque:before, .fa-duotone.#{$fa-css-prefix}-mosque:before { content: fa-content(\f678); }
.fad.#{$fa-css-prefix}-mosque:after, .fa-duotone.#{$fa-css-prefix}-mosque:after { content: fa-content(\10f678); }
.fad.#{$fa-css-prefix}-motorcycle:before, .fa-duotone.#{$fa-css-prefix}-motorcycle:before { content: fa-content(\f21c); }
.fad.#{$fa-css-prefix}-motorcycle:after, .fa-duotone.#{$fa-css-prefix}-motorcycle:after { content: fa-content(\10f21c); }
.fad.#{$fa-css-prefix}-mountain:before, .fa-duotone.#{$fa-css-prefix}-mountain:before { content: fa-content(\f6fc); }
.fad.#{$fa-css-prefix}-mountain:after, .fa-duotone.#{$fa-css-prefix}-mountain:after { content: fa-content(\10f6fc); }
.fad.#{$fa-css-prefix}-mountains:before, .fa-duotone.#{$fa-css-prefix}-mountains:before { content: fa-content(\26f0\fe01); }
.fad.#{$fa-css-prefix}-mountains:after, .fa-duotone.#{$fa-css-prefix}-mountains:after { content: fa-content(\26f0\fe02); }
.fad.#{$fa-css-prefix}-mp3-player:before, .fa-duotone.#{$fa-css-prefix}-mp3-player:before { content: fa-content(\f8ce\fe01); }
.fad.#{$fa-css-prefix}-mp3-player:after, .fa-duotone.#{$fa-css-prefix}-mp3-player:after { content: fa-content(\f8ce\fe02); }
.fad.#{$fa-css-prefix}-mug:before, .fa-duotone.#{$fa-css-prefix}-mug:before { content: fa-content(\f874\fe01); }
.fad.#{$fa-css-prefix}-mug:after, .fa-duotone.#{$fa-css-prefix}-mug:after { content: fa-content(\f874\fe02); }
.fad.#{$fa-css-prefix}-mug-hot:before, .fa-duotone.#{$fa-css-prefix}-mug-hot:before { content: fa-content(\2615\fe01); }
.fad.#{$fa-css-prefix}-mug-hot:after, .fa-duotone.#{$fa-css-prefix}-mug-hot:after { content: fa-content(\2615\fe02); }
.fad.#{$fa-css-prefix}-mug-marshmallows:before, .fa-duotone.#{$fa-css-prefix}-mug-marshmallows:before { content: fa-content(\f7b7\fe01); }
.fad.#{$fa-css-prefix}-mug-marshmallows:after, .fa-duotone.#{$fa-css-prefix}-mug-marshmallows:after { content: fa-content(\f7b7\fe02); }
.fad.#{$fa-css-prefix}-mug-saucer:before, .fa-duotone.#{$fa-css-prefix}-mug-saucer:before { content: fa-content(\f0f4\fe01); }
.fad.#{$fa-css-prefix}-mug-saucer:after, .fa-duotone.#{$fa-css-prefix}-mug-saucer:after { content: fa-content(\f0f4\fe02); }
.fad.#{$fa-css-prefix}-coffee:before, .fa-duotone.#{$fa-css-prefix}-coffee:before { content: fa-content(\f0f4\fe01); }
.fad.#{$fa-css-prefix}-coffee:after, .fa-duotone.#{$fa-css-prefix}-coffee:after { content: fa-content(\f0f4\fe02); }
.fad.#{$fa-css-prefix}-mug-tea:before, .fa-duotone.#{$fa-css-prefix}-mug-tea:before { content: fa-content(\f875\fe01); }
.fad.#{$fa-css-prefix}-mug-tea:after, .fa-duotone.#{$fa-css-prefix}-mug-tea:after { content: fa-content(\f875\fe02); }
.fad.#{$fa-css-prefix}-mug-tea-saucer:before, .fa-duotone.#{$fa-css-prefix}-mug-tea-saucer:before { content: fa-content(\e1f5\fe01); }
.fad.#{$fa-css-prefix}-mug-tea-saucer:after, .fa-duotone.#{$fa-css-prefix}-mug-tea-saucer:after { content: fa-content(\e1f5\fe02); }
.fad.#{$fa-css-prefix}-music:before, .fa-duotone.#{$fa-css-prefix}-music:before { content: fa-content(\f001); }
.fad.#{$fa-css-prefix}-music:after, .fa-duotone.#{$fa-css-prefix}-music:after { content: fa-content(\10f001); }
.fad.#{$fa-css-prefix}-music-note:before, .fa-duotone.#{$fa-css-prefix}-music-note:before { content: fa-content(\f8cf\fe01); }
.fad.#{$fa-css-prefix}-music-note:after, .fa-duotone.#{$fa-css-prefix}-music-note:after { content: fa-content(\f8cf\fe02); }
.fad.#{$fa-css-prefix}-music-alt:before, .fa-duotone.#{$fa-css-prefix}-music-alt:before { content: fa-content(\f8cf\fe01); }
.fad.#{$fa-css-prefix}-music-alt:after, .fa-duotone.#{$fa-css-prefix}-music-alt:after { content: fa-content(\f8cf\fe02); }
.fad.#{$fa-css-prefix}-music-note-slash:before, .fa-duotone.#{$fa-css-prefix}-music-note-slash:before { content: fa-content(\f8d0\fe01); }
.fad.#{$fa-css-prefix}-music-note-slash:after, .fa-duotone.#{$fa-css-prefix}-music-note-slash:after { content: fa-content(\f8d0\fe02); }
.fad.#{$fa-css-prefix}-music-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-music-alt-slash:before { content: fa-content(\f8d0\fe01); }
.fad.#{$fa-css-prefix}-music-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-music-alt-slash:after { content: fa-content(\f8d0\fe02); }
.fad.#{$fa-css-prefix}-music-slash:before, .fa-duotone.#{$fa-css-prefix}-music-slash:before { content: fa-content(\f8d1\fe01); }
.fad.#{$fa-css-prefix}-music-slash:after, .fa-duotone.#{$fa-css-prefix}-music-slash:after { content: fa-content(\f8d1\fe02); }
.fad.#{$fa-css-prefix}-n:before, .fa-duotone.#{$fa-css-prefix}-n:before { content: fa-content(\4e\fe01); }
.fad.#{$fa-css-prefix}-n:after, .fa-duotone.#{$fa-css-prefix}-n:after { content: fa-content(\4e\fe02); }
.fad.#{$fa-css-prefix}-naira-sign:before, .fa-duotone.#{$fa-css-prefix}-naira-sign:before { content: fa-content(\e1f6\fe01); }
.fad.#{$fa-css-prefix}-naira-sign:after, .fa-duotone.#{$fa-css-prefix}-naira-sign:after { content: fa-content(\e1f6\fe02); }
.fad.#{$fa-css-prefix}-narwhal:before, .fa-duotone.#{$fa-css-prefix}-narwhal:before { content: fa-content(\f6fe\fe01); }
.fad.#{$fa-css-prefix}-narwhal:after, .fa-duotone.#{$fa-css-prefix}-narwhal:after { content: fa-content(\f6fe\fe02); }
.fad.#{$fa-css-prefix}-nesting-dolls:before, .fa-duotone.#{$fa-css-prefix}-nesting-dolls:before { content: fa-content(\e3ba\fe01); }
.fad.#{$fa-css-prefix}-nesting-dolls:after, .fa-duotone.#{$fa-css-prefix}-nesting-dolls:after { content: fa-content(\e3ba\fe02); }
.fad.#{$fa-css-prefix}-network-wired:before, .fa-duotone.#{$fa-css-prefix}-network-wired:before { content: fa-content(\f6ff\fe01); }
.fad.#{$fa-css-prefix}-network-wired:after, .fa-duotone.#{$fa-css-prefix}-network-wired:after { content: fa-content(\f6ff\fe02); }
.fad.#{$fa-css-prefix}-neuter:before, .fa-duotone.#{$fa-css-prefix}-neuter:before { content: fa-content(\26b2\fe01); }
.fad.#{$fa-css-prefix}-neuter:after, .fa-duotone.#{$fa-css-prefix}-neuter:after { content: fa-content(\26b2\fe02); }
.fad.#{$fa-css-prefix}-newspaper:before, .fa-duotone.#{$fa-css-prefix}-newspaper:before { content: fa-content(\f1ea); }
.fad.#{$fa-css-prefix}-newspaper:after, .fa-duotone.#{$fa-css-prefix}-newspaper:after { content: fa-content(\10f1ea); }
.fad.#{$fa-css-prefix}-nfc:before, .fa-duotone.#{$fa-css-prefix}-nfc:before { content: fa-content(\e1f7\fe01); }
.fad.#{$fa-css-prefix}-nfc:after, .fa-duotone.#{$fa-css-prefix}-nfc:after { content: fa-content(\e1f7\fe02); }
.fad.#{$fa-css-prefix}-nfc-lock:before, .fa-duotone.#{$fa-css-prefix}-nfc-lock:before { content: fa-content(\e1f8\fe01); }
.fad.#{$fa-css-prefix}-nfc-lock:after, .fa-duotone.#{$fa-css-prefix}-nfc-lock:after { content: fa-content(\e1f8\fe02); }
.fad.#{$fa-css-prefix}-nfc-magnifying-glass:before, .fa-duotone.#{$fa-css-prefix}-nfc-magnifying-glass:before { content: fa-content(\e1f9\fe01); }
.fad.#{$fa-css-prefix}-nfc-magnifying-glass:after, .fa-duotone.#{$fa-css-prefix}-nfc-magnifying-glass:after { content: fa-content(\e1f9\fe02); }
.fad.#{$fa-css-prefix}-nfc-pen:before, .fa-duotone.#{$fa-css-prefix}-nfc-pen:before { content: fa-content(\e1fa\fe01); }
.fad.#{$fa-css-prefix}-nfc-pen:after, .fa-duotone.#{$fa-css-prefix}-nfc-pen:after { content: fa-content(\e1fa\fe02); }
.fad.#{$fa-css-prefix}-nfc-signal:before, .fa-duotone.#{$fa-css-prefix}-nfc-signal:before { content: fa-content(\e1fb\fe01); }
.fad.#{$fa-css-prefix}-nfc-signal:after, .fa-duotone.#{$fa-css-prefix}-nfc-signal:after { content: fa-content(\e1fb\fe02); }
.fad.#{$fa-css-prefix}-nfc-slash:before, .fa-duotone.#{$fa-css-prefix}-nfc-slash:before { content: fa-content(\e1fc\fe01); }
.fad.#{$fa-css-prefix}-nfc-slash:after, .fa-duotone.#{$fa-css-prefix}-nfc-slash:after { content: fa-content(\e1fc\fe02); }
.fad.#{$fa-css-prefix}-nfc-trash:before, .fa-duotone.#{$fa-css-prefix}-nfc-trash:before { content: fa-content(\e1fd\fe01); }
.fad.#{$fa-css-prefix}-nfc-trash:after, .fa-duotone.#{$fa-css-prefix}-nfc-trash:after { content: fa-content(\e1fd\fe02); }
.fad.#{$fa-css-prefix}-not-equal:before, .fa-duotone.#{$fa-css-prefix}-not-equal:before { content: fa-content(\f53e\fe01); }
.fad.#{$fa-css-prefix}-not-equal:after, .fa-duotone.#{$fa-css-prefix}-not-equal:after { content: fa-content(\f53e\fe02); }
.fad.#{$fa-css-prefix}-notdef:before, .fa-duotone.#{$fa-css-prefix}-notdef:before { content: fa-content(\e1fe\fe01); }
.fad.#{$fa-css-prefix}-notdef:after, .fa-duotone.#{$fa-css-prefix}-notdef:after { content: fa-content(\e1fe\fe02); }
.fad.#{$fa-css-prefix}-note:before, .fa-duotone.#{$fa-css-prefix}-note:before { content: fa-content(\e1ff\fe01); }
.fad.#{$fa-css-prefix}-note:after, .fa-duotone.#{$fa-css-prefix}-note:after { content: fa-content(\e1ff\fe02); }
.fad.#{$fa-css-prefix}-note-medical:before, .fa-duotone.#{$fa-css-prefix}-note-medical:before { content: fa-content(\e200\fe01); }
.fad.#{$fa-css-prefix}-note-medical:after, .fa-duotone.#{$fa-css-prefix}-note-medical:after { content: fa-content(\e200\fe02); }
.fad.#{$fa-css-prefix}-note-sticky:before, .fa-duotone.#{$fa-css-prefix}-note-sticky:before { content: fa-content(\f249\fe01); }
.fad.#{$fa-css-prefix}-note-sticky:after, .fa-duotone.#{$fa-css-prefix}-note-sticky:after { content: fa-content(\f249\fe02); }
.fad.#{$fa-css-prefix}-sticky-note:before, .fa-duotone.#{$fa-css-prefix}-sticky-note:before { content: fa-content(\f249\fe01); }
.fad.#{$fa-css-prefix}-sticky-note:after, .fa-duotone.#{$fa-css-prefix}-sticky-note:after { content: fa-content(\f249\fe02); }
.fad.#{$fa-css-prefix}-notebook:before, .fa-duotone.#{$fa-css-prefix}-notebook:before { content: fa-content(\e201\fe01); }
.fad.#{$fa-css-prefix}-notebook:after, .fa-duotone.#{$fa-css-prefix}-notebook:after { content: fa-content(\e201\fe02); }
.fad.#{$fa-css-prefix}-notes:before, .fa-duotone.#{$fa-css-prefix}-notes:before { content: fa-content(\e202\fe01); }
.fad.#{$fa-css-prefix}-notes:after, .fa-duotone.#{$fa-css-prefix}-notes:after { content: fa-content(\e202\fe02); }
.fad.#{$fa-css-prefix}-notes-medical:before, .fa-duotone.#{$fa-css-prefix}-notes-medical:before { content: fa-content(\f481\fe01); }
.fad.#{$fa-css-prefix}-notes-medical:after, .fa-duotone.#{$fa-css-prefix}-notes-medical:after { content: fa-content(\f481\fe02); }
.fad.#{$fa-css-prefix}-o:before, .fa-duotone.#{$fa-css-prefix}-o:before { content: fa-content(\4f\fe01); }
.fad.#{$fa-css-prefix}-o:after, .fa-duotone.#{$fa-css-prefix}-o:after { content: fa-content(\4f\fe02); }
.fad.#{$fa-css-prefix}-object-group:before, .fa-duotone.#{$fa-css-prefix}-object-group:before { content: fa-content(\f247\fe01); }
.fad.#{$fa-css-prefix}-object-group:after, .fa-duotone.#{$fa-css-prefix}-object-group:after { content: fa-content(\f247\fe02); }
.fad.#{$fa-css-prefix}-object-ungroup:before, .fa-duotone.#{$fa-css-prefix}-object-ungroup:before { content: fa-content(\f248\fe01); }
.fad.#{$fa-css-prefix}-object-ungroup:after, .fa-duotone.#{$fa-css-prefix}-object-ungroup:after { content: fa-content(\f248\fe02); }
.fad.#{$fa-css-prefix}-objects-align-bottom:before, .fa-duotone.#{$fa-css-prefix}-objects-align-bottom:before { content: fa-content(\e3bb\fe01); }
.fad.#{$fa-css-prefix}-objects-align-bottom:after, .fa-duotone.#{$fa-css-prefix}-objects-align-bottom:after { content: fa-content(\e3bb\fe02); }
.fad.#{$fa-css-prefix}-objects-align-center-horizontal:before, .fa-duotone.#{$fa-css-prefix}-objects-align-center-horizontal:before { content: fa-content(\e3bc\fe01); }
.fad.#{$fa-css-prefix}-objects-align-center-horizontal:after, .fa-duotone.#{$fa-css-prefix}-objects-align-center-horizontal:after { content: fa-content(\e3bc\fe02); }
.fad.#{$fa-css-prefix}-objects-align-center-vertical:before, .fa-duotone.#{$fa-css-prefix}-objects-align-center-vertical:before { content: fa-content(\e3bd\fe01); }
.fad.#{$fa-css-prefix}-objects-align-center-vertical:after, .fa-duotone.#{$fa-css-prefix}-objects-align-center-vertical:after { content: fa-content(\e3bd\fe02); }
.fad.#{$fa-css-prefix}-objects-align-left:before, .fa-duotone.#{$fa-css-prefix}-objects-align-left:before { content: fa-content(\e3be\fe01); }
.fad.#{$fa-css-prefix}-objects-align-left:after, .fa-duotone.#{$fa-css-prefix}-objects-align-left:after { content: fa-content(\e3be\fe02); }
.fad.#{$fa-css-prefix}-objects-align-right:before, .fa-duotone.#{$fa-css-prefix}-objects-align-right:before { content: fa-content(\e3bf\fe01); }
.fad.#{$fa-css-prefix}-objects-align-right:after, .fa-duotone.#{$fa-css-prefix}-objects-align-right:after { content: fa-content(\e3bf\fe02); }
.fad.#{$fa-css-prefix}-objects-align-top:before, .fa-duotone.#{$fa-css-prefix}-objects-align-top:before { content: fa-content(\e3c0\fe01); }
.fad.#{$fa-css-prefix}-objects-align-top:after, .fa-duotone.#{$fa-css-prefix}-objects-align-top:after { content: fa-content(\e3c0\fe02); }
.fad.#{$fa-css-prefix}-objects-column:before, .fa-duotone.#{$fa-css-prefix}-objects-column:before { content: fa-content(\e3c1\fe01); }
.fad.#{$fa-css-prefix}-objects-column:after, .fa-duotone.#{$fa-css-prefix}-objects-column:after { content: fa-content(\e3c1\fe02); }
.fad.#{$fa-css-prefix}-octagon:before, .fa-duotone.#{$fa-css-prefix}-octagon:before { content: fa-content(\f306); }
.fad.#{$fa-css-prefix}-octagon:after, .fa-duotone.#{$fa-css-prefix}-octagon:after { content: fa-content(\10f306); }
.fad.#{$fa-css-prefix}-octagon-divide:before, .fa-duotone.#{$fa-css-prefix}-octagon-divide:before { content: fa-content(\e203\fe01); }
.fad.#{$fa-css-prefix}-octagon-divide:after, .fa-duotone.#{$fa-css-prefix}-octagon-divide:after { content: fa-content(\e203\fe02); }
.fad.#{$fa-css-prefix}-octagon-exclamation:before, .fa-duotone.#{$fa-css-prefix}-octagon-exclamation:before { content: fa-content(\e204\fe01); }
.fad.#{$fa-css-prefix}-octagon-exclamation:after, .fa-duotone.#{$fa-css-prefix}-octagon-exclamation:after { content: fa-content(\e204\fe02); }
.fad.#{$fa-css-prefix}-octagon-minus:before, .fa-duotone.#{$fa-css-prefix}-octagon-minus:before { content: fa-content(\f308\fe01); }
.fad.#{$fa-css-prefix}-octagon-minus:after, .fa-duotone.#{$fa-css-prefix}-octagon-minus:after { content: fa-content(\f308\fe02); }
.fad.#{$fa-css-prefix}-minus-octagon:before, .fa-duotone.#{$fa-css-prefix}-minus-octagon:before { content: fa-content(\f308\fe01); }
.fad.#{$fa-css-prefix}-minus-octagon:after, .fa-duotone.#{$fa-css-prefix}-minus-octagon:after { content: fa-content(\f308\fe02); }
.fad.#{$fa-css-prefix}-octagon-plus:before, .fa-duotone.#{$fa-css-prefix}-octagon-plus:before { content: fa-content(\f301\fe01); }
.fad.#{$fa-css-prefix}-octagon-plus:after, .fa-duotone.#{$fa-css-prefix}-octagon-plus:after { content: fa-content(\f301\fe02); }
.fad.#{$fa-css-prefix}-plus-octagon:before, .fa-duotone.#{$fa-css-prefix}-plus-octagon:before { content: fa-content(\f301\fe01); }
.fad.#{$fa-css-prefix}-plus-octagon:after, .fa-duotone.#{$fa-css-prefix}-plus-octagon:after { content: fa-content(\f301\fe02); }
.fad.#{$fa-css-prefix}-octagon-xmark:before, .fa-duotone.#{$fa-css-prefix}-octagon-xmark:before { content: fa-content(\f2f0\fe01); }
.fad.#{$fa-css-prefix}-octagon-xmark:after, .fa-duotone.#{$fa-css-prefix}-octagon-xmark:after { content: fa-content(\f2f0\fe02); }
.fad.#{$fa-css-prefix}-times-octagon:before, .fa-duotone.#{$fa-css-prefix}-times-octagon:before { content: fa-content(\f2f0\fe01); }
.fad.#{$fa-css-prefix}-times-octagon:after, .fa-duotone.#{$fa-css-prefix}-times-octagon:after { content: fa-content(\f2f0\fe02); }
.fad.#{$fa-css-prefix}-xmark-octagon:before, .fa-duotone.#{$fa-css-prefix}-xmark-octagon:before { content: fa-content(\f2f0\fe01); }
.fad.#{$fa-css-prefix}-xmark-octagon:after, .fa-duotone.#{$fa-css-prefix}-xmark-octagon:after { content: fa-content(\f2f0\fe02); }
.fad.#{$fa-css-prefix}-oil-can:before, .fa-duotone.#{$fa-css-prefix}-oil-can:before { content: fa-content(\f613\fe01); }
.fad.#{$fa-css-prefix}-oil-can:after, .fa-duotone.#{$fa-css-prefix}-oil-can:after { content: fa-content(\f613\fe02); }
.fad.#{$fa-css-prefix}-oil-can-drip:before, .fa-duotone.#{$fa-css-prefix}-oil-can-drip:before { content: fa-content(\e205\fe01); }
.fad.#{$fa-css-prefix}-oil-can-drip:after, .fa-duotone.#{$fa-css-prefix}-oil-can-drip:after { content: fa-content(\e205\fe02); }
.fad.#{$fa-css-prefix}-oil-temperature:before, .fa-duotone.#{$fa-css-prefix}-oil-temperature:before { content: fa-content(\f614\fe01); }
.fad.#{$fa-css-prefix}-oil-temperature:after, .fa-duotone.#{$fa-css-prefix}-oil-temperature:after { content: fa-content(\f614\fe02); }
.fad.#{$fa-css-prefix}-oil-temp:before, .fa-duotone.#{$fa-css-prefix}-oil-temp:before { content: fa-content(\f614\fe01); }
.fad.#{$fa-css-prefix}-oil-temp:after, .fa-duotone.#{$fa-css-prefix}-oil-temp:after { content: fa-content(\f614\fe02); }
.fad.#{$fa-css-prefix}-olive:before, .fa-duotone.#{$fa-css-prefix}-olive:before { content: fa-content(\e316\fe01); }
.fad.#{$fa-css-prefix}-olive:after, .fa-duotone.#{$fa-css-prefix}-olive:after { content: fa-content(\e316\fe02); }
.fad.#{$fa-css-prefix}-olive-branch:before, .fa-duotone.#{$fa-css-prefix}-olive-branch:before { content: fa-content(\e317\fe01); }
.fad.#{$fa-css-prefix}-olive-branch:after, .fa-duotone.#{$fa-css-prefix}-olive-branch:after { content: fa-content(\e317\fe02); }
.fad.#{$fa-css-prefix}-om:before, .fa-duotone.#{$fa-css-prefix}-om:before { content: fa-content(\f679); }
.fad.#{$fa-css-prefix}-om:after, .fa-duotone.#{$fa-css-prefix}-om:after { content: fa-content(\10f679); }
.fad.#{$fa-css-prefix}-omega:before, .fa-duotone.#{$fa-css-prefix}-omega:before { content: fa-content(\f67a\fe01); }
.fad.#{$fa-css-prefix}-omega:after, .fa-duotone.#{$fa-css-prefix}-omega:after { content: fa-content(\f67a\fe02); }
.fad.#{$fa-css-prefix}-option:before, .fa-duotone.#{$fa-css-prefix}-option:before { content: fa-content(\e318\fe01); }
.fad.#{$fa-css-prefix}-option:after, .fa-duotone.#{$fa-css-prefix}-option:after { content: fa-content(\e318\fe02); }
.fad.#{$fa-css-prefix}-ornament:before, .fa-duotone.#{$fa-css-prefix}-ornament:before { content: fa-content(\f7b8\fe01); }
.fad.#{$fa-css-prefix}-ornament:after, .fa-duotone.#{$fa-css-prefix}-ornament:after { content: fa-content(\f7b8\fe02); }
.fad.#{$fa-css-prefix}-otter:before, .fa-duotone.#{$fa-css-prefix}-otter:before { content: fa-content(\f700); }
.fad.#{$fa-css-prefix}-otter:after, .fa-duotone.#{$fa-css-prefix}-otter:after { content: fa-content(\10f700); }
.fad.#{$fa-css-prefix}-outdent:before, .fa-duotone.#{$fa-css-prefix}-outdent:before { content: fa-content(\f03b\fe01); }
.fad.#{$fa-css-prefix}-outdent:after, .fa-duotone.#{$fa-css-prefix}-outdent:after { content: fa-content(\f03b\fe02); }
.fad.#{$fa-css-prefix}-dedent:before, .fa-duotone.#{$fa-css-prefix}-dedent:before { content: fa-content(\f03b\fe01); }
.fad.#{$fa-css-prefix}-dedent:after, .fa-duotone.#{$fa-css-prefix}-dedent:after { content: fa-content(\f03b\fe02); }
.fad.#{$fa-css-prefix}-outlet:before, .fa-duotone.#{$fa-css-prefix}-outlet:before { content: fa-content(\e01c\fe01); }
.fad.#{$fa-css-prefix}-outlet:after, .fa-duotone.#{$fa-css-prefix}-outlet:after { content: fa-content(\e01c\fe02); }
.fad.#{$fa-css-prefix}-oven:before, .fa-duotone.#{$fa-css-prefix}-oven:before { content: fa-content(\e01d\fe01); }
.fad.#{$fa-css-prefix}-oven:after, .fa-duotone.#{$fa-css-prefix}-oven:after { content: fa-content(\e01d\fe02); }
.fad.#{$fa-css-prefix}-overline:before, .fa-duotone.#{$fa-css-prefix}-overline:before { content: fa-content(\f876\fe01); }
.fad.#{$fa-css-prefix}-overline:after, .fa-duotone.#{$fa-css-prefix}-overline:after { content: fa-content(\f876\fe02); }
.fad.#{$fa-css-prefix}-p:before, .fa-duotone.#{$fa-css-prefix}-p:before { content: fa-content(\50\fe01); }
.fad.#{$fa-css-prefix}-p:after, .fa-duotone.#{$fa-css-prefix}-p:after { content: fa-content(\50\fe02); }
.fad.#{$fa-css-prefix}-pager:before, .fa-duotone.#{$fa-css-prefix}-pager:before { content: fa-content(\f815); }
.fad.#{$fa-css-prefix}-pager:after, .fa-duotone.#{$fa-css-prefix}-pager:after { content: fa-content(\10f815); }
.fad.#{$fa-css-prefix}-paint-brush:before, .fa-duotone.#{$fa-css-prefix}-paint-brush:before { content: fa-content(\f1fc); }
.fad.#{$fa-css-prefix}-paint-brush:after, .fa-duotone.#{$fa-css-prefix}-paint-brush:after { content: fa-content(\10f1fc); }
.fad.#{$fa-css-prefix}-paint-brush-fine:before, .fa-duotone.#{$fa-css-prefix}-paint-brush-fine:before { content: fa-content(\f5a9\fe01); }
.fad.#{$fa-css-prefix}-paint-brush-fine:after, .fa-duotone.#{$fa-css-prefix}-paint-brush-fine:after { content: fa-content(\f5a9\fe02); }
.fad.#{$fa-css-prefix}-paint-brush-alt:before, .fa-duotone.#{$fa-css-prefix}-paint-brush-alt:before { content: fa-content(\f5a9\fe01); }
.fad.#{$fa-css-prefix}-paint-brush-alt:after, .fa-duotone.#{$fa-css-prefix}-paint-brush-alt:after { content: fa-content(\f5a9\fe02); }
.fad.#{$fa-css-prefix}-paint-roller:before, .fa-duotone.#{$fa-css-prefix}-paint-roller:before { content: fa-content(\f5aa\fe01); }
.fad.#{$fa-css-prefix}-paint-roller:after, .fa-duotone.#{$fa-css-prefix}-paint-roller:after { content: fa-content(\f5aa\fe02); }
.fad.#{$fa-css-prefix}-paintbrush-pencil:before, .fa-duotone.#{$fa-css-prefix}-paintbrush-pencil:before { content: fa-content(\e206\fe01); }
.fad.#{$fa-css-prefix}-paintbrush-pencil:after, .fa-duotone.#{$fa-css-prefix}-paintbrush-pencil:after { content: fa-content(\e206\fe02); }
.fad.#{$fa-css-prefix}-palette:before, .fa-duotone.#{$fa-css-prefix}-palette:before { content: fa-content(\f53f); }
.fad.#{$fa-css-prefix}-palette:after, .fa-duotone.#{$fa-css-prefix}-palette:after { content: fa-content(\10f53f); }
.fad.#{$fa-css-prefix}-pallet:before, .fa-duotone.#{$fa-css-prefix}-pallet:before { content: fa-content(\f482\fe01); }
.fad.#{$fa-css-prefix}-pallet:after, .fa-duotone.#{$fa-css-prefix}-pallet:after { content: fa-content(\f482\fe02); }
.fad.#{$fa-css-prefix}-pallet-box:before, .fa-duotone.#{$fa-css-prefix}-pallet-box:before { content: fa-content(\e208\fe01); }
.fad.#{$fa-css-prefix}-pallet-box:after, .fa-duotone.#{$fa-css-prefix}-pallet-box:after { content: fa-content(\e208\fe02); }
.fad.#{$fa-css-prefix}-pallet-boxes:before, .fa-duotone.#{$fa-css-prefix}-pallet-boxes:before { content: fa-content(\f483\fe01); }
.fad.#{$fa-css-prefix}-pallet-boxes:after, .fa-duotone.#{$fa-css-prefix}-pallet-boxes:after { content: fa-content(\f483\fe02); }
.fad.#{$fa-css-prefix}-palette-boxes:before, .fa-duotone.#{$fa-css-prefix}-palette-boxes:before { content: fa-content(\f483\fe01); }
.fad.#{$fa-css-prefix}-palette-boxes:after, .fa-duotone.#{$fa-css-prefix}-palette-boxes:after { content: fa-content(\f483\fe02); }
.fad.#{$fa-css-prefix}-pallet-alt:before, .fa-duotone.#{$fa-css-prefix}-pallet-alt:before { content: fa-content(\f483\fe01); }
.fad.#{$fa-css-prefix}-pallet-alt:after, .fa-duotone.#{$fa-css-prefix}-pallet-alt:after { content: fa-content(\f483\fe02); }
.fad.#{$fa-css-prefix}-panorama:before, .fa-duotone.#{$fa-css-prefix}-panorama:before { content: fa-content(\e209\fe01); }
.fad.#{$fa-css-prefix}-panorama:after, .fa-duotone.#{$fa-css-prefix}-panorama:after { content: fa-content(\e209\fe02); }
.fad.#{$fa-css-prefix}-paper-plane:before, .fa-duotone.#{$fa-css-prefix}-paper-plane:before { content: fa-content(\f1d8\fe01); }
.fad.#{$fa-css-prefix}-paper-plane:after, .fa-duotone.#{$fa-css-prefix}-paper-plane:after { content: fa-content(\f1d8\fe02); }
.fad.#{$fa-css-prefix}-paper-plane-top:before, .fa-duotone.#{$fa-css-prefix}-paper-plane-top:before { content: fa-content(\e20a\fe01); }
.fad.#{$fa-css-prefix}-paper-plane-top:after, .fa-duotone.#{$fa-css-prefix}-paper-plane-top:after { content: fa-content(\e20a\fe02); }
.fad.#{$fa-css-prefix}-paper-plane-alt:before, .fa-duotone.#{$fa-css-prefix}-paper-plane-alt:before { content: fa-content(\e20a\fe01); }
.fad.#{$fa-css-prefix}-paper-plane-alt:after, .fa-duotone.#{$fa-css-prefix}-paper-plane-alt:after { content: fa-content(\e20a\fe02); }
.fad.#{$fa-css-prefix}-send:before, .fa-duotone.#{$fa-css-prefix}-send:before { content: fa-content(\e20a\fe01); }
.fad.#{$fa-css-prefix}-send:after, .fa-duotone.#{$fa-css-prefix}-send:after { content: fa-content(\e20a\fe02); }
.fad.#{$fa-css-prefix}-paperclip:before, .fa-duotone.#{$fa-css-prefix}-paperclip:before { content: fa-content(\f0c6); }
.fad.#{$fa-css-prefix}-paperclip:after, .fa-duotone.#{$fa-css-prefix}-paperclip:after { content: fa-content(\10f0c6); }
.fad.#{$fa-css-prefix}-paperclip-vertical:before, .fa-duotone.#{$fa-css-prefix}-paperclip-vertical:before { content: fa-content(\e3c2\fe01); }
.fad.#{$fa-css-prefix}-paperclip-vertical:after, .fa-duotone.#{$fa-css-prefix}-paperclip-vertical:after { content: fa-content(\e3c2\fe02); }
.fad.#{$fa-css-prefix}-parachute-box:before, .fa-duotone.#{$fa-css-prefix}-parachute-box:before { content: fa-content(\f4cd\fe01); }
.fad.#{$fa-css-prefix}-parachute-box:after, .fa-duotone.#{$fa-css-prefix}-parachute-box:after { content: fa-content(\f4cd\fe02); }
.fad.#{$fa-css-prefix}-paragraph:before, .fa-duotone.#{$fa-css-prefix}-paragraph:before { content: fa-content(\b6\fe01); }
.fad.#{$fa-css-prefix}-paragraph:after, .fa-duotone.#{$fa-css-prefix}-paragraph:after { content: fa-content(\b6\fe02); }
.fad.#{$fa-css-prefix}-paragraph-left:before, .fa-duotone.#{$fa-css-prefix}-paragraph-left:before { content: fa-content(\f878\fe01); }
.fad.#{$fa-css-prefix}-paragraph-left:after, .fa-duotone.#{$fa-css-prefix}-paragraph-left:after { content: fa-content(\f878\fe02); }
.fad.#{$fa-css-prefix}-paragraph-rtl:before, .fa-duotone.#{$fa-css-prefix}-paragraph-rtl:before { content: fa-content(\f878\fe01); }
.fad.#{$fa-css-prefix}-paragraph-rtl:after, .fa-duotone.#{$fa-css-prefix}-paragraph-rtl:after { content: fa-content(\f878\fe02); }
.fad.#{$fa-css-prefix}-party-bell:before, .fa-duotone.#{$fa-css-prefix}-party-bell:before { content: fa-content(\e31a\fe01); }
.fad.#{$fa-css-prefix}-party-bell:after, .fa-duotone.#{$fa-css-prefix}-party-bell:after { content: fa-content(\e31a\fe02); }
.fad.#{$fa-css-prefix}-party-horn:before, .fa-duotone.#{$fa-css-prefix}-party-horn:before { content: fa-content(\e31b\fe01); }
.fad.#{$fa-css-prefix}-party-horn:after, .fa-duotone.#{$fa-css-prefix}-party-horn:after { content: fa-content(\e31b\fe02); }
.fad.#{$fa-css-prefix}-passport:before, .fa-duotone.#{$fa-css-prefix}-passport:before { content: fa-content(\f5ab\fe01); }
.fad.#{$fa-css-prefix}-passport:after, .fa-duotone.#{$fa-css-prefix}-passport:after { content: fa-content(\f5ab\fe02); }
.fad.#{$fa-css-prefix}-paste:before, .fa-duotone.#{$fa-css-prefix}-paste:before { content: fa-content(\f0ea\fe01); }
.fad.#{$fa-css-prefix}-paste:after, .fa-duotone.#{$fa-css-prefix}-paste:after { content: fa-content(\f0ea\fe02); }
.fad.#{$fa-css-prefix}-file-clipboard:before, .fa-duotone.#{$fa-css-prefix}-file-clipboard:before { content: fa-content(\f0ea\fe01); }
.fad.#{$fa-css-prefix}-file-clipboard:after, .fa-duotone.#{$fa-css-prefix}-file-clipboard:after { content: fa-content(\f0ea\fe02); }
.fad.#{$fa-css-prefix}-pause:before, .fa-duotone.#{$fa-css-prefix}-pause:before { content: fa-content(\23f8\fe01); }
.fad.#{$fa-css-prefix}-pause:after, .fa-duotone.#{$fa-css-prefix}-pause:after { content: fa-content(\23f8\fe02); }
.fad.#{$fa-css-prefix}-paw:before, .fa-duotone.#{$fa-css-prefix}-paw:before { content: fa-content(\f1b0\fe01); }
.fad.#{$fa-css-prefix}-paw:after, .fa-duotone.#{$fa-css-prefix}-paw:after { content: fa-content(\f1b0\fe02); }
.fad.#{$fa-css-prefix}-paw-claws:before, .fa-duotone.#{$fa-css-prefix}-paw-claws:before { content: fa-content(\f702\fe01); }
.fad.#{$fa-css-prefix}-paw-claws:after, .fa-duotone.#{$fa-css-prefix}-paw-claws:after { content: fa-content(\f702\fe02); }
.fad.#{$fa-css-prefix}-paw-simple:before, .fa-duotone.#{$fa-css-prefix}-paw-simple:before { content: fa-content(\f701\fe01); }
.fad.#{$fa-css-prefix}-paw-simple:after, .fa-duotone.#{$fa-css-prefix}-paw-simple:after { content: fa-content(\f701\fe02); }
.fad.#{$fa-css-prefix}-paw-alt:before, .fa-duotone.#{$fa-css-prefix}-paw-alt:before { content: fa-content(\f701\fe01); }
.fad.#{$fa-css-prefix}-paw-alt:after, .fa-duotone.#{$fa-css-prefix}-paw-alt:after { content: fa-content(\f701\fe02); }
.fad.#{$fa-css-prefix}-peace:before, .fa-duotone.#{$fa-css-prefix}-peace:before { content: fa-content(\262e\fe01); }
.fad.#{$fa-css-prefix}-peace:after, .fa-duotone.#{$fa-css-prefix}-peace:after { content: fa-content(\262e\fe02); }
.fad.#{$fa-css-prefix}-peach:before, .fa-duotone.#{$fa-css-prefix}-peach:before { content: fa-content(\e20b\fe01); }
.fad.#{$fa-css-prefix}-peach:after, .fa-duotone.#{$fa-css-prefix}-peach:after { content: fa-content(\e20b\fe02); }
.fad.#{$fa-css-prefix}-peapod:before, .fa-duotone.#{$fa-css-prefix}-peapod:before { content: fa-content(\e31c\fe01); }
.fad.#{$fa-css-prefix}-peapod:after, .fa-duotone.#{$fa-css-prefix}-peapod:after { content: fa-content(\e31c\fe02); }
.fad.#{$fa-css-prefix}-pear:before, .fa-duotone.#{$fa-css-prefix}-pear:before { content: fa-content(\e20c\fe01); }
.fad.#{$fa-css-prefix}-pear:after, .fa-duotone.#{$fa-css-prefix}-pear:after { content: fa-content(\e20c\fe02); }
.fad.#{$fa-css-prefix}-pedestal:before, .fa-duotone.#{$fa-css-prefix}-pedestal:before { content: fa-content(\e20d\fe01); }
.fad.#{$fa-css-prefix}-pedestal:after, .fa-duotone.#{$fa-css-prefix}-pedestal:after { content: fa-content(\e20d\fe02); }
.fad.#{$fa-css-prefix}-pegasus:before, .fa-duotone.#{$fa-css-prefix}-pegasus:before { content: fa-content(\f703\fe01); }
.fad.#{$fa-css-prefix}-pegasus:after, .fa-duotone.#{$fa-css-prefix}-pegasus:after { content: fa-content(\f703\fe02); }
.fad.#{$fa-css-prefix}-pen:before, .fa-duotone.#{$fa-css-prefix}-pen:before { content: fa-content(\f304); }
.fad.#{$fa-css-prefix}-pen:after, .fa-duotone.#{$fa-css-prefix}-pen:after { content: fa-content(\10f304); }
.fad.#{$fa-css-prefix}-pen-circle:before, .fa-duotone.#{$fa-css-prefix}-pen-circle:before { content: fa-content(\e20e\fe01); }
.fad.#{$fa-css-prefix}-pen-circle:after, .fa-duotone.#{$fa-css-prefix}-pen-circle:after { content: fa-content(\e20e\fe02); }
.fad.#{$fa-css-prefix}-pen-clip:before, .fa-duotone.#{$fa-css-prefix}-pen-clip:before { content: fa-content(\f305\fe01); }
.fad.#{$fa-css-prefix}-pen-clip:after, .fa-duotone.#{$fa-css-prefix}-pen-clip:after { content: fa-content(\f305\fe02); }
.fad.#{$fa-css-prefix}-pen-alt:before, .fa-duotone.#{$fa-css-prefix}-pen-alt:before { content: fa-content(\f305\fe01); }
.fad.#{$fa-css-prefix}-pen-alt:after, .fa-duotone.#{$fa-css-prefix}-pen-alt:after { content: fa-content(\f305\fe02); }
.fad.#{$fa-css-prefix}-pen-clip-slash:before, .fa-duotone.#{$fa-css-prefix}-pen-clip-slash:before { content: fa-content(\e20f\fe01); }
.fad.#{$fa-css-prefix}-pen-clip-slash:after, .fa-duotone.#{$fa-css-prefix}-pen-clip-slash:after { content: fa-content(\e20f\fe02); }
.fad.#{$fa-css-prefix}-pen-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-pen-alt-slash:before { content: fa-content(\e20f\fe01); }
.fad.#{$fa-css-prefix}-pen-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-pen-alt-slash:after { content: fa-content(\e20f\fe02); }
.fad.#{$fa-css-prefix}-pen-fancy:before, .fa-duotone.#{$fa-css-prefix}-pen-fancy:before { content: fa-content(\2712\fe01); }
.fad.#{$fa-css-prefix}-pen-fancy:after, .fa-duotone.#{$fa-css-prefix}-pen-fancy:after { content: fa-content(\2712\fe02); }
.fad.#{$fa-css-prefix}-pen-fancy-slash:before, .fa-duotone.#{$fa-css-prefix}-pen-fancy-slash:before { content: fa-content(\e210\fe01); }
.fad.#{$fa-css-prefix}-pen-fancy-slash:after, .fa-duotone.#{$fa-css-prefix}-pen-fancy-slash:after { content: fa-content(\e210\fe02); }
.fad.#{$fa-css-prefix}-pen-field:before, .fa-duotone.#{$fa-css-prefix}-pen-field:before { content: fa-content(\e211\fe01); }
.fad.#{$fa-css-prefix}-pen-field:after, .fa-duotone.#{$fa-css-prefix}-pen-field:after { content: fa-content(\e211\fe02); }
.fad.#{$fa-css-prefix}-pen-line:before, .fa-duotone.#{$fa-css-prefix}-pen-line:before { content: fa-content(\e212\fe01); }
.fad.#{$fa-css-prefix}-pen-line:after, .fa-duotone.#{$fa-css-prefix}-pen-line:after { content: fa-content(\e212\fe02); }
.fad.#{$fa-css-prefix}-pen-nib:before, .fa-duotone.#{$fa-css-prefix}-pen-nib:before { content: fa-content(\2711\fe01); }
.fad.#{$fa-css-prefix}-pen-nib:after, .fa-duotone.#{$fa-css-prefix}-pen-nib:after { content: fa-content(\2711\fe02); }
.fad.#{$fa-css-prefix}-pen-paintbrush:before, .fa-duotone.#{$fa-css-prefix}-pen-paintbrush:before { content: fa-content(\f618\fe01); }
.fad.#{$fa-css-prefix}-pen-paintbrush:after, .fa-duotone.#{$fa-css-prefix}-pen-paintbrush:after { content: fa-content(\f618\fe02); }
.fad.#{$fa-css-prefix}-pencil-paintbrush:before, .fa-duotone.#{$fa-css-prefix}-pencil-paintbrush:before { content: fa-content(\f618\fe01); }
.fad.#{$fa-css-prefix}-pencil-paintbrush:after, .fa-duotone.#{$fa-css-prefix}-pencil-paintbrush:after { content: fa-content(\f618\fe02); }
.fad.#{$fa-css-prefix}-pen-ruler:before, .fa-duotone.#{$fa-css-prefix}-pen-ruler:before { content: fa-content(\f5ae\fe01); }
.fad.#{$fa-css-prefix}-pen-ruler:after, .fa-duotone.#{$fa-css-prefix}-pen-ruler:after { content: fa-content(\f5ae\fe02); }
.fad.#{$fa-css-prefix}-pencil-ruler:before, .fa-duotone.#{$fa-css-prefix}-pencil-ruler:before { content: fa-content(\f5ae\fe01); }
.fad.#{$fa-css-prefix}-pencil-ruler:after, .fa-duotone.#{$fa-css-prefix}-pencil-ruler:after { content: fa-content(\f5ae\fe02); }
.fad.#{$fa-css-prefix}-pen-slash:before, .fa-duotone.#{$fa-css-prefix}-pen-slash:before { content: fa-content(\e213\fe01); }
.fad.#{$fa-css-prefix}-pen-slash:after, .fa-duotone.#{$fa-css-prefix}-pen-slash:after { content: fa-content(\e213\fe02); }
.fad.#{$fa-css-prefix}-pen-swirl:before, .fa-duotone.#{$fa-css-prefix}-pen-swirl:before { content: fa-content(\e214\fe01); }
.fad.#{$fa-css-prefix}-pen-swirl:after, .fa-duotone.#{$fa-css-prefix}-pen-swirl:after { content: fa-content(\e214\fe02); }
.fad.#{$fa-css-prefix}-pen-to-square:before, .fa-duotone.#{$fa-css-prefix}-pen-to-square:before { content: fa-content(\f044\fe01); }
.fad.#{$fa-css-prefix}-pen-to-square:after, .fa-duotone.#{$fa-css-prefix}-pen-to-square:after { content: fa-content(\f044\fe02); }
.fad.#{$fa-css-prefix}-edit:before, .fa-duotone.#{$fa-css-prefix}-edit:before { content: fa-content(\f044\fe01); }
.fad.#{$fa-css-prefix}-edit:after, .fa-duotone.#{$fa-css-prefix}-edit:after { content: fa-content(\f044\fe02); }
.fad.#{$fa-css-prefix}-pencil:before, .fa-duotone.#{$fa-css-prefix}-pencil:before { content: fa-content(\270f\fe01); }
.fad.#{$fa-css-prefix}-pencil:after, .fa-duotone.#{$fa-css-prefix}-pencil:after { content: fa-content(\270f\fe02); }
.fad.#{$fa-css-prefix}-pencil-alt:before, .fa-duotone.#{$fa-css-prefix}-pencil-alt:before { content: fa-content(\270f\fe01); }
.fad.#{$fa-css-prefix}-pencil-alt:after, .fa-duotone.#{$fa-css-prefix}-pencil-alt:after { content: fa-content(\270f\fe02); }
.fad.#{$fa-css-prefix}-pencil-slash:before, .fa-duotone.#{$fa-css-prefix}-pencil-slash:before { content: fa-content(\e215\fe01); }
.fad.#{$fa-css-prefix}-pencil-slash:after, .fa-duotone.#{$fa-css-prefix}-pencil-slash:after { content: fa-content(\e215\fe02); }
.fad.#{$fa-css-prefix}-people:before, .fa-duotone.#{$fa-css-prefix}-people:before { content: fa-content(\e216\fe01); }
.fad.#{$fa-css-prefix}-people:after, .fa-duotone.#{$fa-css-prefix}-people:after { content: fa-content(\e216\fe02); }
.fad.#{$fa-css-prefix}-people-arrows-left-right:before, .fa-duotone.#{$fa-css-prefix}-people-arrows-left-right:before { content: fa-content(\e068\fe01); }
.fad.#{$fa-css-prefix}-people-arrows-left-right:after, .fa-duotone.#{$fa-css-prefix}-people-arrows-left-right:after { content: fa-content(\e068\fe02); }
.fad.#{$fa-css-prefix}-people-arrows:before, .fa-duotone.#{$fa-css-prefix}-people-arrows:before { content: fa-content(\e068\fe01); }
.fad.#{$fa-css-prefix}-people-arrows:after, .fa-duotone.#{$fa-css-prefix}-people-arrows:after { content: fa-content(\e068\fe02); }
.fad.#{$fa-css-prefix}-people-carry-box:before, .fa-duotone.#{$fa-css-prefix}-people-carry-box:before { content: fa-content(\f4ce\fe01); }
.fad.#{$fa-css-prefix}-people-carry-box:after, .fa-duotone.#{$fa-css-prefix}-people-carry-box:after { content: fa-content(\f4ce\fe02); }
.fad.#{$fa-css-prefix}-people-carry:before, .fa-duotone.#{$fa-css-prefix}-people-carry:before { content: fa-content(\f4ce\fe01); }
.fad.#{$fa-css-prefix}-people-carry:after, .fa-duotone.#{$fa-css-prefix}-people-carry:after { content: fa-content(\f4ce\fe02); }
.fad.#{$fa-css-prefix}-people-dress:before, .fa-duotone.#{$fa-css-prefix}-people-dress:before { content: fa-content(\e217\fe01); }
.fad.#{$fa-css-prefix}-people-dress:after, .fa-duotone.#{$fa-css-prefix}-people-dress:after { content: fa-content(\e217\fe02); }
.fad.#{$fa-css-prefix}-people-dress-simple:before, .fa-duotone.#{$fa-css-prefix}-people-dress-simple:before { content: fa-content(\e218\fe01); }
.fad.#{$fa-css-prefix}-people-dress-simple:after, .fa-duotone.#{$fa-css-prefix}-people-dress-simple:after { content: fa-content(\e218\fe02); }
.fad.#{$fa-css-prefix}-people-pants:before, .fa-duotone.#{$fa-css-prefix}-people-pants:before { content: fa-content(\e219\fe01); }
.fad.#{$fa-css-prefix}-people-pants:after, .fa-duotone.#{$fa-css-prefix}-people-pants:after { content: fa-content(\e219\fe02); }
.fad.#{$fa-css-prefix}-people-pants-simple:before, .fa-duotone.#{$fa-css-prefix}-people-pants-simple:before { content: fa-content(\e21a\fe01); }
.fad.#{$fa-css-prefix}-people-pants-simple:after, .fa-duotone.#{$fa-css-prefix}-people-pants-simple:after { content: fa-content(\e21a\fe02); }
.fad.#{$fa-css-prefix}-people-simple:before, .fa-duotone.#{$fa-css-prefix}-people-simple:before { content: fa-content(\e21b\fe01); }
.fad.#{$fa-css-prefix}-people-simple:after, .fa-duotone.#{$fa-css-prefix}-people-simple:after { content: fa-content(\e21b\fe02); }
.fad.#{$fa-css-prefix}-pepper-hot:before, .fa-duotone.#{$fa-css-prefix}-pepper-hot:before { content: fa-content(\f816); }
.fad.#{$fa-css-prefix}-pepper-hot:after, .fa-duotone.#{$fa-css-prefix}-pepper-hot:after { content: fa-content(\10f816); }
.fad.#{$fa-css-prefix}-percent:before, .fa-duotone.#{$fa-css-prefix}-percent:before { content: fa-content(\25\fe01); }
.fad.#{$fa-css-prefix}-percent:after, .fa-duotone.#{$fa-css-prefix}-percent:after { content: fa-content(\25\fe02); }
.fad.#{$fa-css-prefix}-percentage:before, .fa-duotone.#{$fa-css-prefix}-percentage:before { content: fa-content(\25\fe01); }
.fad.#{$fa-css-prefix}-percentage:after, .fa-duotone.#{$fa-css-prefix}-percentage:after { content: fa-content(\25\fe02); }
.fad.#{$fa-css-prefix}-period:before, .fa-duotone.#{$fa-css-prefix}-period:before { content: fa-content(\2e\fe01); }
.fad.#{$fa-css-prefix}-period:after, .fa-duotone.#{$fa-css-prefix}-period:after { content: fa-content(\2e\fe02); }
.fad.#{$fa-css-prefix}-person:before, .fa-duotone.#{$fa-css-prefix}-person:before { content: fa-content(\f183); }
.fad.#{$fa-css-prefix}-person:after, .fa-duotone.#{$fa-css-prefix}-person:after { content: fa-content(\10f183); }
.fad.#{$fa-css-prefix}-male:before, .fa-duotone.#{$fa-css-prefix}-male:before { content: fa-content(\f183); }
.fad.#{$fa-css-prefix}-male:after, .fa-duotone.#{$fa-css-prefix}-male:after { content: fa-content(\10f183); }
.fad.#{$fa-css-prefix}-person-biking:before, .fa-duotone.#{$fa-css-prefix}-person-biking:before { content: fa-content(\f84a); }
.fad.#{$fa-css-prefix}-person-biking:after, .fa-duotone.#{$fa-css-prefix}-person-biking:after { content: fa-content(\10f84a); }
.fad.#{$fa-css-prefix}-biking:before, .fa-duotone.#{$fa-css-prefix}-biking:before { content: fa-content(\f84a); }
.fad.#{$fa-css-prefix}-biking:after, .fa-duotone.#{$fa-css-prefix}-biking:after { content: fa-content(\10f84a); }
.fad.#{$fa-css-prefix}-person-biking-mountain:before, .fa-duotone.#{$fa-css-prefix}-person-biking-mountain:before { content: fa-content(\f84b); }
.fad.#{$fa-css-prefix}-person-biking-mountain:after, .fa-duotone.#{$fa-css-prefix}-person-biking-mountain:after { content: fa-content(\10f84b); }
.fad.#{$fa-css-prefix}-biking-mountain:before, .fa-duotone.#{$fa-css-prefix}-biking-mountain:before { content: fa-content(\f84b); }
.fad.#{$fa-css-prefix}-biking-mountain:after, .fa-duotone.#{$fa-css-prefix}-biking-mountain:after { content: fa-content(\10f84b); }
.fad.#{$fa-css-prefix}-person-booth:before, .fa-duotone.#{$fa-css-prefix}-person-booth:before { content: fa-content(\f756\fe01); }
.fad.#{$fa-css-prefix}-person-booth:after, .fa-duotone.#{$fa-css-prefix}-person-booth:after { content: fa-content(\f756\fe02); }
.fad.#{$fa-css-prefix}-person-carry-box:before, .fa-duotone.#{$fa-css-prefix}-person-carry-box:before { content: fa-content(\f4cf\fe01); }
.fad.#{$fa-css-prefix}-person-carry-box:after, .fa-duotone.#{$fa-css-prefix}-person-carry-box:after { content: fa-content(\f4cf\fe02); }
.fad.#{$fa-css-prefix}-person-carry:before, .fa-duotone.#{$fa-css-prefix}-person-carry:before { content: fa-content(\f4cf\fe01); }
.fad.#{$fa-css-prefix}-person-carry:after, .fa-duotone.#{$fa-css-prefix}-person-carry:after { content: fa-content(\f4cf\fe02); }
.fad.#{$fa-css-prefix}-person-digging:before, .fa-duotone.#{$fa-css-prefix}-person-digging:before { content: fa-content(\f85e\fe01); }
.fad.#{$fa-css-prefix}-person-digging:after, .fa-duotone.#{$fa-css-prefix}-person-digging:after { content: fa-content(\f85e\fe02); }
.fad.#{$fa-css-prefix}-digging:before, .fa-duotone.#{$fa-css-prefix}-digging:before { content: fa-content(\f85e\fe01); }
.fad.#{$fa-css-prefix}-digging:after, .fa-duotone.#{$fa-css-prefix}-digging:after { content: fa-content(\f85e\fe02); }
.fad.#{$fa-css-prefix}-person-dolly:before, .fa-duotone.#{$fa-css-prefix}-person-dolly:before { content: fa-content(\f4d0\fe01); }
.fad.#{$fa-css-prefix}-person-dolly:after, .fa-duotone.#{$fa-css-prefix}-person-dolly:after { content: fa-content(\f4d0\fe02); }
.fad.#{$fa-css-prefix}-person-dolly-empty:before, .fa-duotone.#{$fa-css-prefix}-person-dolly-empty:before { content: fa-content(\f4d1\fe01); }
.fad.#{$fa-css-prefix}-person-dolly-empty:after, .fa-duotone.#{$fa-css-prefix}-person-dolly-empty:after { content: fa-content(\f4d1\fe02); }
.fad.#{$fa-css-prefix}-person-dots-from-line:before, .fa-duotone.#{$fa-css-prefix}-person-dots-from-line:before { content: fa-content(\f470\fe01); }
.fad.#{$fa-css-prefix}-person-dots-from-line:after, .fa-duotone.#{$fa-css-prefix}-person-dots-from-line:after { content: fa-content(\f470\fe02); }
.fad.#{$fa-css-prefix}-diagnoses:before, .fa-duotone.#{$fa-css-prefix}-diagnoses:before { content: fa-content(\f470\fe01); }
.fad.#{$fa-css-prefix}-diagnoses:after, .fa-duotone.#{$fa-css-prefix}-diagnoses:after { content: fa-content(\f470\fe02); }
.fad.#{$fa-css-prefix}-person-dress:before, .fa-duotone.#{$fa-css-prefix}-person-dress:before { content: fa-content(\f182\fe01); }
.fad.#{$fa-css-prefix}-person-dress:after, .fa-duotone.#{$fa-css-prefix}-person-dress:after { content: fa-content(\f182\fe02); }
.fad.#{$fa-css-prefix}-female:before, .fa-duotone.#{$fa-css-prefix}-female:before { content: fa-content(\f182\fe01); }
.fad.#{$fa-css-prefix}-female:after, .fa-duotone.#{$fa-css-prefix}-female:after { content: fa-content(\f182\fe02); }
.fad.#{$fa-css-prefix}-person-dress-simple:before, .fa-duotone.#{$fa-css-prefix}-person-dress-simple:before { content: fa-content(\e21c\fe01); }
.fad.#{$fa-css-prefix}-person-dress-simple:after, .fa-duotone.#{$fa-css-prefix}-person-dress-simple:after { content: fa-content(\e21c\fe02); }
.fad.#{$fa-css-prefix}-person-from-portal:before, .fa-duotone.#{$fa-css-prefix}-person-from-portal:before { content: fa-content(\e023\fe01); }
.fad.#{$fa-css-prefix}-person-from-portal:after, .fa-duotone.#{$fa-css-prefix}-person-from-portal:after { content: fa-content(\e023\fe02); }
.fad.#{$fa-css-prefix}-portal-exit:before, .fa-duotone.#{$fa-css-prefix}-portal-exit:before { content: fa-content(\e023\fe01); }
.fad.#{$fa-css-prefix}-portal-exit:after, .fa-duotone.#{$fa-css-prefix}-portal-exit:after { content: fa-content(\e023\fe02); }
.fad.#{$fa-css-prefix}-person-hiking:before, .fa-duotone.#{$fa-css-prefix}-person-hiking:before { content: fa-content(\f6ec\fe01); }
.fad.#{$fa-css-prefix}-person-hiking:after, .fa-duotone.#{$fa-css-prefix}-person-hiking:after { content: fa-content(\f6ec\fe02); }
.fad.#{$fa-css-prefix}-hiking:before, .fa-duotone.#{$fa-css-prefix}-hiking:before { content: fa-content(\f6ec\fe01); }
.fad.#{$fa-css-prefix}-hiking:after, .fa-duotone.#{$fa-css-prefix}-hiking:after { content: fa-content(\f6ec\fe02); }
.fad.#{$fa-css-prefix}-person-pinball:before, .fa-duotone.#{$fa-css-prefix}-person-pinball:before { content: fa-content(\e21d\fe01); }
.fad.#{$fa-css-prefix}-person-pinball:after, .fa-duotone.#{$fa-css-prefix}-person-pinball:after { content: fa-content(\e21d\fe02); }
.fad.#{$fa-css-prefix}-person-praying:before, .fa-duotone.#{$fa-css-prefix}-person-praying:before { content: fa-content(\f683); }
.fad.#{$fa-css-prefix}-person-praying:after, .fa-duotone.#{$fa-css-prefix}-person-praying:after { content: fa-content(\10f683); }
.fad.#{$fa-css-prefix}-pray:before, .fa-duotone.#{$fa-css-prefix}-pray:before { content: fa-content(\f683); }
.fad.#{$fa-css-prefix}-pray:after, .fa-duotone.#{$fa-css-prefix}-pray:after { content: fa-content(\10f683); }
.fad.#{$fa-css-prefix}-person-pregnant:before, .fa-duotone.#{$fa-css-prefix}-person-pregnant:before { content: fa-content(\e31e\fe01); }
.fad.#{$fa-css-prefix}-person-pregnant:after, .fa-duotone.#{$fa-css-prefix}-person-pregnant:after { content: fa-content(\e31e\fe02); }
.fad.#{$fa-css-prefix}-person-running:before, .fa-duotone.#{$fa-css-prefix}-person-running:before { content: fa-content(\f70c); }
.fad.#{$fa-css-prefix}-person-running:after, .fa-duotone.#{$fa-css-prefix}-person-running:after { content: fa-content(\10f70c); }
.fad.#{$fa-css-prefix}-running:before, .fa-duotone.#{$fa-css-prefix}-running:before { content: fa-content(\f70c); }
.fad.#{$fa-css-prefix}-running:after, .fa-duotone.#{$fa-css-prefix}-running:after { content: fa-content(\10f70c); }
.fad.#{$fa-css-prefix}-person-seat:before, .fa-duotone.#{$fa-css-prefix}-person-seat:before { content: fa-content(\e21e\fe01); }
.fad.#{$fa-css-prefix}-person-seat:after, .fa-duotone.#{$fa-css-prefix}-person-seat:after { content: fa-content(\e21e\fe02); }
.fad.#{$fa-css-prefix}-person-seat-reclined:before, .fa-duotone.#{$fa-css-prefix}-person-seat-reclined:before { content: fa-content(\e21f\fe01); }
.fad.#{$fa-css-prefix}-person-seat-reclined:after, .fa-duotone.#{$fa-css-prefix}-person-seat-reclined:after { content: fa-content(\e21f\fe02); }
.fad.#{$fa-css-prefix}-person-sign:before, .fa-duotone.#{$fa-css-prefix}-person-sign:before { content: fa-content(\f757\fe01); }
.fad.#{$fa-css-prefix}-person-sign:after, .fa-duotone.#{$fa-css-prefix}-person-sign:after { content: fa-content(\f757\fe02); }
.fad.#{$fa-css-prefix}-person-simple:before, .fa-duotone.#{$fa-css-prefix}-person-simple:before { content: fa-content(\e220\fe01); }
.fad.#{$fa-css-prefix}-person-simple:after, .fa-duotone.#{$fa-css-prefix}-person-simple:after { content: fa-content(\e220\fe02); }
.fad.#{$fa-css-prefix}-person-skating:before, .fa-duotone.#{$fa-css-prefix}-person-skating:before { content: fa-content(\f7c5\fe01); }
.fad.#{$fa-css-prefix}-person-skating:after, .fa-duotone.#{$fa-css-prefix}-person-skating:after { content: fa-content(\f7c5\fe02); }
.fad.#{$fa-css-prefix}-skating:before, .fa-duotone.#{$fa-css-prefix}-skating:before { content: fa-content(\f7c5\fe01); }
.fad.#{$fa-css-prefix}-skating:after, .fa-duotone.#{$fa-css-prefix}-skating:after { content: fa-content(\f7c5\fe02); }
.fad.#{$fa-css-prefix}-person-ski-jumping:before, .fa-duotone.#{$fa-css-prefix}-person-ski-jumping:before { content: fa-content(\f7c7\fe01); }
.fad.#{$fa-css-prefix}-person-ski-jumping:after, .fa-duotone.#{$fa-css-prefix}-person-ski-jumping:after { content: fa-content(\f7c7\fe02); }
.fad.#{$fa-css-prefix}-ski-jump:before, .fa-duotone.#{$fa-css-prefix}-ski-jump:before { content: fa-content(\f7c7\fe01); }
.fad.#{$fa-css-prefix}-ski-jump:after, .fa-duotone.#{$fa-css-prefix}-ski-jump:after { content: fa-content(\f7c7\fe02); }
.fad.#{$fa-css-prefix}-person-ski-lift:before, .fa-duotone.#{$fa-css-prefix}-person-ski-lift:before { content: fa-content(\f7c8\fe01); }
.fad.#{$fa-css-prefix}-person-ski-lift:after, .fa-duotone.#{$fa-css-prefix}-person-ski-lift:after { content: fa-content(\f7c8\fe02); }
.fad.#{$fa-css-prefix}-ski-lift:before, .fa-duotone.#{$fa-css-prefix}-ski-lift:before { content: fa-content(\f7c8\fe01); }
.fad.#{$fa-css-prefix}-ski-lift:after, .fa-duotone.#{$fa-css-prefix}-ski-lift:after { content: fa-content(\f7c8\fe02); }
.fad.#{$fa-css-prefix}-person-skiing:before, .fa-duotone.#{$fa-css-prefix}-person-skiing:before { content: fa-content(\26f7\fe01); }
.fad.#{$fa-css-prefix}-person-skiing:after, .fa-duotone.#{$fa-css-prefix}-person-skiing:after { content: fa-content(\26f7\fe02); }
.fad.#{$fa-css-prefix}-skiing:before, .fa-duotone.#{$fa-css-prefix}-skiing:before { content: fa-content(\26f7\fe01); }
.fad.#{$fa-css-prefix}-skiing:after, .fa-duotone.#{$fa-css-prefix}-skiing:after { content: fa-content(\26f7\fe02); }
.fad.#{$fa-css-prefix}-person-skiing-nordic:before, .fa-duotone.#{$fa-css-prefix}-person-skiing-nordic:before { content: fa-content(\f7ca\fe01); }
.fad.#{$fa-css-prefix}-person-skiing-nordic:after, .fa-duotone.#{$fa-css-prefix}-person-skiing-nordic:after { content: fa-content(\f7ca\fe02); }
.fad.#{$fa-css-prefix}-skiing-nordic:before, .fa-duotone.#{$fa-css-prefix}-skiing-nordic:before { content: fa-content(\f7ca\fe01); }
.fad.#{$fa-css-prefix}-skiing-nordic:after, .fa-duotone.#{$fa-css-prefix}-skiing-nordic:after { content: fa-content(\f7ca\fe02); }
.fad.#{$fa-css-prefix}-person-sledding:before, .fa-duotone.#{$fa-css-prefix}-person-sledding:before { content: fa-content(\f7cb\fe01); }
.fad.#{$fa-css-prefix}-person-sledding:after, .fa-duotone.#{$fa-css-prefix}-person-sledding:after { content: fa-content(\f7cb\fe02); }
.fad.#{$fa-css-prefix}-sledding:before, .fa-duotone.#{$fa-css-prefix}-sledding:before { content: fa-content(\f7cb\fe01); }
.fad.#{$fa-css-prefix}-sledding:after, .fa-duotone.#{$fa-css-prefix}-sledding:after { content: fa-content(\f7cb\fe02); }
.fad.#{$fa-css-prefix}-person-snowboarding:before, .fa-duotone.#{$fa-css-prefix}-person-snowboarding:before { content: fa-content(\f7ce); }
.fad.#{$fa-css-prefix}-person-snowboarding:after, .fa-duotone.#{$fa-css-prefix}-person-snowboarding:after { content: fa-content(\10f7ce); }
.fad.#{$fa-css-prefix}-snowboarding:before, .fa-duotone.#{$fa-css-prefix}-snowboarding:before { content: fa-content(\f7ce); }
.fad.#{$fa-css-prefix}-snowboarding:after, .fa-duotone.#{$fa-css-prefix}-snowboarding:after { content: fa-content(\10f7ce); }
.fad.#{$fa-css-prefix}-person-snowmobiling:before, .fa-duotone.#{$fa-css-prefix}-person-snowmobiling:before { content: fa-content(\f7d1\fe01); }
.fad.#{$fa-css-prefix}-person-snowmobiling:after, .fa-duotone.#{$fa-css-prefix}-person-snowmobiling:after { content: fa-content(\f7d1\fe02); }
.fad.#{$fa-css-prefix}-snowmobile:before, .fa-duotone.#{$fa-css-prefix}-snowmobile:before { content: fa-content(\f7d1\fe01); }
.fad.#{$fa-css-prefix}-snowmobile:after, .fa-duotone.#{$fa-css-prefix}-snowmobile:after { content: fa-content(\f7d1\fe02); }
.fad.#{$fa-css-prefix}-person-swimming:before, .fa-duotone.#{$fa-css-prefix}-person-swimming:before { content: fa-content(\f5c4); }
.fad.#{$fa-css-prefix}-person-swimming:after, .fa-duotone.#{$fa-css-prefix}-person-swimming:after { content: fa-content(\10f5c4); }
.fad.#{$fa-css-prefix}-swimmer:before, .fa-duotone.#{$fa-css-prefix}-swimmer:before { content: fa-content(\f5c4); }
.fad.#{$fa-css-prefix}-swimmer:after, .fa-duotone.#{$fa-css-prefix}-swimmer:after { content: fa-content(\10f5c4); }
.fad.#{$fa-css-prefix}-person-to-portal:before, .fa-duotone.#{$fa-css-prefix}-person-to-portal:before { content: fa-content(\e022\fe01); }
.fad.#{$fa-css-prefix}-person-to-portal:after, .fa-duotone.#{$fa-css-prefix}-person-to-portal:after { content: fa-content(\e022\fe02); }
.fad.#{$fa-css-prefix}-portal-enter:before, .fa-duotone.#{$fa-css-prefix}-portal-enter:before { content: fa-content(\e022\fe01); }
.fad.#{$fa-css-prefix}-portal-enter:after, .fa-duotone.#{$fa-css-prefix}-portal-enter:after { content: fa-content(\e022\fe02); }
.fad.#{$fa-css-prefix}-person-walking:before, .fa-duotone.#{$fa-css-prefix}-person-walking:before { content: fa-content(\f554); }
.fad.#{$fa-css-prefix}-person-walking:after, .fa-duotone.#{$fa-css-prefix}-person-walking:after { content: fa-content(\10f554); }
.fad.#{$fa-css-prefix}-walking:before, .fa-duotone.#{$fa-css-prefix}-walking:before { content: fa-content(\f554); }
.fad.#{$fa-css-prefix}-walking:after, .fa-duotone.#{$fa-css-prefix}-walking:after { content: fa-content(\10f554); }
.fad.#{$fa-css-prefix}-person-walking-with-cane:before, .fa-duotone.#{$fa-css-prefix}-person-walking-with-cane:before { content: fa-content(\f29d\fe01); }
.fad.#{$fa-css-prefix}-person-walking-with-cane:after, .fa-duotone.#{$fa-css-prefix}-person-walking-with-cane:after { content: fa-content(\f29d\fe02); }
.fad.#{$fa-css-prefix}-blind:before, .fa-duotone.#{$fa-css-prefix}-blind:before { content: fa-content(\f29d\fe01); }
.fad.#{$fa-css-prefix}-blind:after, .fa-duotone.#{$fa-css-prefix}-blind:after { content: fa-content(\f29d\fe02); }
.fad.#{$fa-css-prefix}-peseta-sign:before, .fa-duotone.#{$fa-css-prefix}-peseta-sign:before { content: fa-content(\e221\fe01); }
.fad.#{$fa-css-prefix}-peseta-sign:after, .fa-duotone.#{$fa-css-prefix}-peseta-sign:after { content: fa-content(\e221\fe02); }
.fad.#{$fa-css-prefix}-peso-sign:before, .fa-duotone.#{$fa-css-prefix}-peso-sign:before { content: fa-content(\e222\fe01); }
.fad.#{$fa-css-prefix}-peso-sign:after, .fa-duotone.#{$fa-css-prefix}-peso-sign:after { content: fa-content(\e222\fe02); }
.fad.#{$fa-css-prefix}-phone:before, .fa-duotone.#{$fa-css-prefix}-phone:before { content: fa-content(\f095); }
.fad.#{$fa-css-prefix}-phone:after, .fa-duotone.#{$fa-css-prefix}-phone:after { content: fa-content(\10f095); }
.fad.#{$fa-css-prefix}-phone-arrow-down-left:before, .fa-duotone.#{$fa-css-prefix}-phone-arrow-down-left:before { content: fa-content(\e223\fe01); }
.fad.#{$fa-css-prefix}-phone-arrow-down-left:after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-down-left:after { content: fa-content(\e223\fe02); }
.fad.#{$fa-css-prefix}-phone-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-phone-arrow-down:before { content: fa-content(\e223\fe01); }
.fad.#{$fa-css-prefix}-phone-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-down:after { content: fa-content(\e223\fe02); }
.fad.#{$fa-css-prefix}-phone-incoming:before, .fa-duotone.#{$fa-css-prefix}-phone-incoming:before { content: fa-content(\e223\fe01); }
.fad.#{$fa-css-prefix}-phone-incoming:after, .fa-duotone.#{$fa-css-prefix}-phone-incoming:after { content: fa-content(\e223\fe02); }
.fad.#{$fa-css-prefix}-phone-arrow-up-right:before, .fa-duotone.#{$fa-css-prefix}-phone-arrow-up-right:before { content: fa-content(\e224\fe01); }
.fad.#{$fa-css-prefix}-phone-arrow-up-right:after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-up-right:after { content: fa-content(\e224\fe02); }
.fad.#{$fa-css-prefix}-phone-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-phone-arrow-up:before { content: fa-content(\e224\fe01); }
.fad.#{$fa-css-prefix}-phone-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-up:after { content: fa-content(\e224\fe02); }
.fad.#{$fa-css-prefix}-phone-outgoing:before, .fa-duotone.#{$fa-css-prefix}-phone-outgoing:before { content: fa-content(\e224\fe01); }
.fad.#{$fa-css-prefix}-phone-outgoing:after, .fa-duotone.#{$fa-css-prefix}-phone-outgoing:after { content: fa-content(\e224\fe02); }
.fad.#{$fa-css-prefix}-phone-flip:before, .fa-duotone.#{$fa-css-prefix}-phone-flip:before { content: fa-content(\f879); }
.fad.#{$fa-css-prefix}-phone-flip:after, .fa-duotone.#{$fa-css-prefix}-phone-flip:after { content: fa-content(\10f879); }
.fad.#{$fa-css-prefix}-phone-alt:before, .fa-duotone.#{$fa-css-prefix}-phone-alt:before { content: fa-content(\f879); }
.fad.#{$fa-css-prefix}-phone-alt:after, .fa-duotone.#{$fa-css-prefix}-phone-alt:after { content: fa-content(\10f879); }
.fad.#{$fa-css-prefix}-phone-hangup:before, .fa-duotone.#{$fa-css-prefix}-phone-hangup:before { content: fa-content(\e225\fe01); }
.fad.#{$fa-css-prefix}-phone-hangup:after, .fa-duotone.#{$fa-css-prefix}-phone-hangup:after { content: fa-content(\e225\fe02); }
.fad.#{$fa-css-prefix}-phone-missed:before, .fa-duotone.#{$fa-css-prefix}-phone-missed:before { content: fa-content(\e226\fe01); }
.fad.#{$fa-css-prefix}-phone-missed:after, .fa-duotone.#{$fa-css-prefix}-phone-missed:after { content: fa-content(\e226\fe02); }
.fad.#{$fa-css-prefix}-phone-office:before, .fa-duotone.#{$fa-css-prefix}-phone-office:before { content: fa-content(\f67d\fe01); }
.fad.#{$fa-css-prefix}-phone-office:after, .fa-duotone.#{$fa-css-prefix}-phone-office:after { content: fa-content(\f67d\fe02); }
.fad.#{$fa-css-prefix}-phone-plus:before, .fa-duotone.#{$fa-css-prefix}-phone-plus:before { content: fa-content(\f4d2\fe01); }
.fad.#{$fa-css-prefix}-phone-plus:after, .fa-duotone.#{$fa-css-prefix}-phone-plus:after { content: fa-content(\f4d2\fe02); }
.fad.#{$fa-css-prefix}-phone-rotary:before, .fa-duotone.#{$fa-css-prefix}-phone-rotary:before { content: fa-content(\260e\fe01); }
.fad.#{$fa-css-prefix}-phone-rotary:after, .fa-duotone.#{$fa-css-prefix}-phone-rotary:after { content: fa-content(\260e\fe02); }
.fad.#{$fa-css-prefix}-phone-slash:before, .fa-duotone.#{$fa-css-prefix}-phone-slash:before { content: fa-content(\f3dd\fe01); }
.fad.#{$fa-css-prefix}-phone-slash:after, .fa-duotone.#{$fa-css-prefix}-phone-slash:after { content: fa-content(\f3dd\fe02); }
.fad.#{$fa-css-prefix}-phone-volume:before, .fa-duotone.#{$fa-css-prefix}-phone-volume:before { content: fa-content(\f2a0\fe01); }
.fad.#{$fa-css-prefix}-phone-volume:after, .fa-duotone.#{$fa-css-prefix}-phone-volume:after { content: fa-content(\f2a0\fe02); }
.fad.#{$fa-css-prefix}-volume-control-phone:before, .fa-duotone.#{$fa-css-prefix}-volume-control-phone:before { content: fa-content(\f2a0\fe01); }
.fad.#{$fa-css-prefix}-volume-control-phone:after, .fa-duotone.#{$fa-css-prefix}-volume-control-phone:after { content: fa-content(\f2a0\fe02); }
.fad.#{$fa-css-prefix}-phone-xmark:before, .fa-duotone.#{$fa-css-prefix}-phone-xmark:before { content: fa-content(\e227\fe01); }
.fad.#{$fa-css-prefix}-phone-xmark:after, .fa-duotone.#{$fa-css-prefix}-phone-xmark:after { content: fa-content(\e227\fe02); }
.fad.#{$fa-css-prefix}-photo-film:before, .fa-duotone.#{$fa-css-prefix}-photo-film:before { content: fa-content(\f87c\fe01); }
.fad.#{$fa-css-prefix}-photo-film:after, .fa-duotone.#{$fa-css-prefix}-photo-film:after { content: fa-content(\f87c\fe02); }
.fad.#{$fa-css-prefix}-photo-video:before, .fa-duotone.#{$fa-css-prefix}-photo-video:before { content: fa-content(\f87c\fe01); }
.fad.#{$fa-css-prefix}-photo-video:after, .fa-duotone.#{$fa-css-prefix}-photo-video:after { content: fa-content(\f87c\fe02); }
.fad.#{$fa-css-prefix}-photo-film-music:before, .fa-duotone.#{$fa-css-prefix}-photo-film-music:before { content: fa-content(\e228\fe01); }
.fad.#{$fa-css-prefix}-photo-film-music:after, .fa-duotone.#{$fa-css-prefix}-photo-film-music:after { content: fa-content(\e228\fe02); }
.fad.#{$fa-css-prefix}-pi:before, .fa-duotone.#{$fa-css-prefix}-pi:before { content: fa-content(\f67e\fe01); }
.fad.#{$fa-css-prefix}-pi:after, .fa-duotone.#{$fa-css-prefix}-pi:after { content: fa-content(\f67e\fe02); }
.fad.#{$fa-css-prefix}-piano:before, .fa-duotone.#{$fa-css-prefix}-piano:before { content: fa-content(\f8d4\fe01); }
.fad.#{$fa-css-prefix}-piano:after, .fa-duotone.#{$fa-css-prefix}-piano:after { content: fa-content(\f8d4\fe02); }
.fad.#{$fa-css-prefix}-piano-keyboard:before, .fa-duotone.#{$fa-css-prefix}-piano-keyboard:before { content: fa-content(\f8d5); }
.fad.#{$fa-css-prefix}-piano-keyboard:after, .fa-duotone.#{$fa-css-prefix}-piano-keyboard:after { content: fa-content(\10f8d5); }
.fad.#{$fa-css-prefix}-pie:before, .fa-duotone.#{$fa-css-prefix}-pie:before { content: fa-content(\f705); }
.fad.#{$fa-css-prefix}-pie:after, .fa-duotone.#{$fa-css-prefix}-pie:after { content: fa-content(\10f705); }
.fad.#{$fa-css-prefix}-pig:before, .fa-duotone.#{$fa-css-prefix}-pig:before { content: fa-content(\f706); }
.fad.#{$fa-css-prefix}-pig:after, .fa-duotone.#{$fa-css-prefix}-pig:after { content: fa-content(\10f706); }
.fad.#{$fa-css-prefix}-piggy-bank:before, .fa-duotone.#{$fa-css-prefix}-piggy-bank:before { content: fa-content(\f4d3\fe01); }
.fad.#{$fa-css-prefix}-piggy-bank:after, .fa-duotone.#{$fa-css-prefix}-piggy-bank:after { content: fa-content(\f4d3\fe02); }
.fad.#{$fa-css-prefix}-pills:before, .fa-duotone.#{$fa-css-prefix}-pills:before { content: fa-content(\f484\fe01); }
.fad.#{$fa-css-prefix}-pills:after, .fa-duotone.#{$fa-css-prefix}-pills:after { content: fa-content(\f484\fe02); }
.fad.#{$fa-css-prefix}-pinata:before, .fa-duotone.#{$fa-css-prefix}-pinata:before { content: fa-content(\e3c3\fe01); }
.fad.#{$fa-css-prefix}-pinata:after, .fa-duotone.#{$fa-css-prefix}-pinata:after { content: fa-content(\e3c3\fe02); }
.fad.#{$fa-css-prefix}-pinball:before, .fa-duotone.#{$fa-css-prefix}-pinball:before { content: fa-content(\e229\fe01); }
.fad.#{$fa-css-prefix}-pinball:after, .fa-duotone.#{$fa-css-prefix}-pinball:after { content: fa-content(\e229\fe02); }
.fad.#{$fa-css-prefix}-pineapple:before, .fa-duotone.#{$fa-css-prefix}-pineapple:before { content: fa-content(\e31f\fe01); }
.fad.#{$fa-css-prefix}-pineapple:after, .fa-duotone.#{$fa-css-prefix}-pineapple:after { content: fa-content(\e31f\fe02); }
.fad.#{$fa-css-prefix}-pipe:before, .fa-duotone.#{$fa-css-prefix}-pipe:before { content: fa-content(\7c\fe01); }
.fad.#{$fa-css-prefix}-pipe:after, .fa-duotone.#{$fa-css-prefix}-pipe:after { content: fa-content(\7c\fe02); }
.fad.#{$fa-css-prefix}-pipe-smoking:before, .fa-duotone.#{$fa-css-prefix}-pipe-smoking:before { content: fa-content(\e3c4\fe01); }
.fad.#{$fa-css-prefix}-pipe-smoking:after, .fa-duotone.#{$fa-css-prefix}-pipe-smoking:after { content: fa-content(\e3c4\fe02); }
.fad.#{$fa-css-prefix}-pizza:before, .fa-duotone.#{$fa-css-prefix}-pizza:before { content: fa-content(\f817); }
.fad.#{$fa-css-prefix}-pizza:after, .fa-duotone.#{$fa-css-prefix}-pizza:after { content: fa-content(\10f817); }
.fad.#{$fa-css-prefix}-pizza-slice:before, .fa-duotone.#{$fa-css-prefix}-pizza-slice:before { content: fa-content(\f818\fe01); }
.fad.#{$fa-css-prefix}-pizza-slice:after, .fa-duotone.#{$fa-css-prefix}-pizza-slice:after { content: fa-content(\f818\fe02); }
.fad.#{$fa-css-prefix}-place-of-worship:before, .fa-duotone.#{$fa-css-prefix}-place-of-worship:before { content: fa-content(\f67f\fe01); }
.fad.#{$fa-css-prefix}-place-of-worship:after, .fa-duotone.#{$fa-css-prefix}-place-of-worship:after { content: fa-content(\f67f\fe02); }
.fad.#{$fa-css-prefix}-plane:before, .fa-duotone.#{$fa-css-prefix}-plane:before { content: fa-content(\f072\fe01); }
.fad.#{$fa-css-prefix}-plane:after, .fa-duotone.#{$fa-css-prefix}-plane:after { content: fa-content(\f072\fe02); }
.fad.#{$fa-css-prefix}-plane-arrival:before, .fa-duotone.#{$fa-css-prefix}-plane-arrival:before { content: fa-content(\f5af); }
.fad.#{$fa-css-prefix}-plane-arrival:after, .fa-duotone.#{$fa-css-prefix}-plane-arrival:after { content: fa-content(\10f5af); }
.fad.#{$fa-css-prefix}-plane-departure:before, .fa-duotone.#{$fa-css-prefix}-plane-departure:before { content: fa-content(\f5b0); }
.fad.#{$fa-css-prefix}-plane-departure:after, .fa-duotone.#{$fa-css-prefix}-plane-departure:after { content: fa-content(\10f5b0); }
.fad.#{$fa-css-prefix}-plane-engines:before, .fa-duotone.#{$fa-css-prefix}-plane-engines:before { content: fa-content(\2708\fe01); }
.fad.#{$fa-css-prefix}-plane-engines:after, .fa-duotone.#{$fa-css-prefix}-plane-engines:after { content: fa-content(\2708\fe02); }
.fad.#{$fa-css-prefix}-plane-alt:before, .fa-duotone.#{$fa-css-prefix}-plane-alt:before { content: fa-content(\2708\fe01); }
.fad.#{$fa-css-prefix}-plane-alt:after, .fa-duotone.#{$fa-css-prefix}-plane-alt:after { content: fa-content(\2708\fe02); }
.fad.#{$fa-css-prefix}-plane-prop:before, .fa-duotone.#{$fa-css-prefix}-plane-prop:before { content: fa-content(\e22b\fe01); }
.fad.#{$fa-css-prefix}-plane-prop:after, .fa-duotone.#{$fa-css-prefix}-plane-prop:after { content: fa-content(\e22b\fe02); }
.fad.#{$fa-css-prefix}-plane-slash:before, .fa-duotone.#{$fa-css-prefix}-plane-slash:before { content: fa-content(\e069\fe01); }
.fad.#{$fa-css-prefix}-plane-slash:after, .fa-duotone.#{$fa-css-prefix}-plane-slash:after { content: fa-content(\e069\fe02); }
.fad.#{$fa-css-prefix}-plane-tail:before, .fa-duotone.#{$fa-css-prefix}-plane-tail:before { content: fa-content(\e22c\fe01); }
.fad.#{$fa-css-prefix}-plane-tail:after, .fa-duotone.#{$fa-css-prefix}-plane-tail:after { content: fa-content(\e22c\fe02); }
.fad.#{$fa-css-prefix}-plane-up:before, .fa-duotone.#{$fa-css-prefix}-plane-up:before { content: fa-content(\e22d\fe01); }
.fad.#{$fa-css-prefix}-plane-up:after, .fa-duotone.#{$fa-css-prefix}-plane-up:after { content: fa-content(\e22d\fe02); }
.fad.#{$fa-css-prefix}-plane-up-slash:before, .fa-duotone.#{$fa-css-prefix}-plane-up-slash:before { content: fa-content(\e22e\fe01); }
.fad.#{$fa-css-prefix}-plane-up-slash:after, .fa-duotone.#{$fa-css-prefix}-plane-up-slash:after { content: fa-content(\e22e\fe02); }
.fad.#{$fa-css-prefix}-planet-moon:before, .fa-duotone.#{$fa-css-prefix}-planet-moon:before { content: fa-content(\e01f\fe01); }
.fad.#{$fa-css-prefix}-planet-moon:after, .fa-duotone.#{$fa-css-prefix}-planet-moon:after { content: fa-content(\e01f\fe02); }
.fad.#{$fa-css-prefix}-planet-ringed:before, .fa-duotone.#{$fa-css-prefix}-planet-ringed:before { content: fa-content(\e020); }
.fad.#{$fa-css-prefix}-planet-ringed:after, .fa-duotone.#{$fa-css-prefix}-planet-ringed:after { content: fa-content(\10e020); }
.fad.#{$fa-css-prefix}-play:before, .fa-duotone.#{$fa-css-prefix}-play:before { content: fa-content(\25b6\fe01); }
.fad.#{$fa-css-prefix}-play:after, .fa-duotone.#{$fa-css-prefix}-play:after { content: fa-content(\25b6\fe02); }
.fad.#{$fa-css-prefix}-play-pause:before, .fa-duotone.#{$fa-css-prefix}-play-pause:before { content: fa-content(\e22f\fe01); }
.fad.#{$fa-css-prefix}-play-pause:after, .fa-duotone.#{$fa-css-prefix}-play-pause:after { content: fa-content(\e22f\fe02); }
.fad.#{$fa-css-prefix}-plug:before, .fa-duotone.#{$fa-css-prefix}-plug:before { content: fa-content(\f1e6); }
.fad.#{$fa-css-prefix}-plug:after, .fa-duotone.#{$fa-css-prefix}-plug:after { content: fa-content(\10f1e6); }
.fad.#{$fa-css-prefix}-plus:before, .fa-duotone.#{$fa-css-prefix}-plus:before { content: fa-content(\2b\fe01); }
.fad.#{$fa-css-prefix}-plus:after, .fa-duotone.#{$fa-css-prefix}-plus:after { content: fa-content(\2b\fe02); }
.fad.#{$fa-css-prefix}-add:before, .fa-duotone.#{$fa-css-prefix}-add:before { content: fa-content(\2b\fe01); }
.fad.#{$fa-css-prefix}-add:after, .fa-duotone.#{$fa-css-prefix}-add:after { content: fa-content(\2b\fe02); }
.fad.#{$fa-css-prefix}-plus-minus:before, .fa-duotone.#{$fa-css-prefix}-plus-minus:before { content: fa-content(\b1\fe01); }
.fad.#{$fa-css-prefix}-plus-minus:after, .fa-duotone.#{$fa-css-prefix}-plus-minus:after { content: fa-content(\b1\fe02); }
.fad.#{$fa-css-prefix}-podcast:before, .fa-duotone.#{$fa-css-prefix}-podcast:before { content: fa-content(\f2ce\fe01); }
.fad.#{$fa-css-prefix}-podcast:after, .fa-duotone.#{$fa-css-prefix}-podcast:after { content: fa-content(\f2ce\fe02); }
.fad.#{$fa-css-prefix}-podium:before, .fa-duotone.#{$fa-css-prefix}-podium:before { content: fa-content(\f680\fe01); }
.fad.#{$fa-css-prefix}-podium:after, .fa-duotone.#{$fa-css-prefix}-podium:after { content: fa-content(\f680\fe02); }
.fad.#{$fa-css-prefix}-podium-star:before, .fa-duotone.#{$fa-css-prefix}-podium-star:before { content: fa-content(\f758\fe01); }
.fad.#{$fa-css-prefix}-podium-star:after, .fa-duotone.#{$fa-css-prefix}-podium-star:after { content: fa-content(\f758\fe02); }
.fad.#{$fa-css-prefix}-police-box:before, .fa-duotone.#{$fa-css-prefix}-police-box:before { content: fa-content(\e021\fe01); }
.fad.#{$fa-css-prefix}-police-box:after, .fa-duotone.#{$fa-css-prefix}-police-box:after { content: fa-content(\e021\fe02); }
.fad.#{$fa-css-prefix}-poll-people:before, .fa-duotone.#{$fa-css-prefix}-poll-people:before { content: fa-content(\f759\fe01); }
.fad.#{$fa-css-prefix}-poll-people:after, .fa-duotone.#{$fa-css-prefix}-poll-people:after { content: fa-content(\f759\fe02); }
.fad.#{$fa-css-prefix}-poo:before, .fa-duotone.#{$fa-css-prefix}-poo:before { content: fa-content(\f2fe); }
.fad.#{$fa-css-prefix}-poo:after, .fa-duotone.#{$fa-css-prefix}-poo:after { content: fa-content(\10f2fe); }
.fad.#{$fa-css-prefix}-poo-bolt:before, .fa-duotone.#{$fa-css-prefix}-poo-bolt:before { content: fa-content(\f75a\fe01); }
.fad.#{$fa-css-prefix}-poo-bolt:after, .fa-duotone.#{$fa-css-prefix}-poo-bolt:after { content: fa-content(\f75a\fe02); }
.fad.#{$fa-css-prefix}-poo-storm:before, .fa-duotone.#{$fa-css-prefix}-poo-storm:before { content: fa-content(\f75a\fe01); }
.fad.#{$fa-css-prefix}-poo-storm:after, .fa-duotone.#{$fa-css-prefix}-poo-storm:after { content: fa-content(\f75a\fe02); }
.fad.#{$fa-css-prefix}-pool-8-ball:before, .fa-duotone.#{$fa-css-prefix}-pool-8-ball:before { content: fa-content(\e3c5\fe01); }
.fad.#{$fa-css-prefix}-pool-8-ball:after, .fa-duotone.#{$fa-css-prefix}-pool-8-ball:after { content: fa-content(\e3c5\fe02); }
.fad.#{$fa-css-prefix}-poop:before, .fa-duotone.#{$fa-css-prefix}-poop:before { content: fa-content(\f619\fe01); }
.fad.#{$fa-css-prefix}-poop:after, .fa-duotone.#{$fa-css-prefix}-poop:after { content: fa-content(\f619\fe02); }
.fad.#{$fa-css-prefix}-popcorn:before, .fa-duotone.#{$fa-css-prefix}-popcorn:before { content: fa-content(\f819); }
.fad.#{$fa-css-prefix}-popcorn:after, .fa-duotone.#{$fa-css-prefix}-popcorn:after { content: fa-content(\10f819); }
.fad.#{$fa-css-prefix}-power-off:before, .fa-duotone.#{$fa-css-prefix}-power-off:before { content: fa-content(\23fb\fe01); }
.fad.#{$fa-css-prefix}-power-off:after, .fa-duotone.#{$fa-css-prefix}-power-off:after { content: fa-content(\23fb\fe02); }
.fad.#{$fa-css-prefix}-prescription:before, .fa-duotone.#{$fa-css-prefix}-prescription:before { content: fa-content(\f5b1\fe01); }
.fad.#{$fa-css-prefix}-prescription:after, .fa-duotone.#{$fa-css-prefix}-prescription:after { content: fa-content(\f5b1\fe02); }
.fad.#{$fa-css-prefix}-prescription-bottle:before, .fa-duotone.#{$fa-css-prefix}-prescription-bottle:before { content: fa-content(\f485\fe01); }
.fad.#{$fa-css-prefix}-prescription-bottle:after, .fa-duotone.#{$fa-css-prefix}-prescription-bottle:after { content: fa-content(\f485\fe02); }
.fad.#{$fa-css-prefix}-prescription-bottle-medical:before, .fa-duotone.#{$fa-css-prefix}-prescription-bottle-medical:before { content: fa-content(\f486\fe01); }
.fad.#{$fa-css-prefix}-prescription-bottle-medical:after, .fa-duotone.#{$fa-css-prefix}-prescription-bottle-medical:after { content: fa-content(\f486\fe02); }
.fad.#{$fa-css-prefix}-prescription-bottle-alt:before, .fa-duotone.#{$fa-css-prefix}-prescription-bottle-alt:before { content: fa-content(\f486\fe01); }
.fad.#{$fa-css-prefix}-prescription-bottle-alt:after, .fa-duotone.#{$fa-css-prefix}-prescription-bottle-alt:after { content: fa-content(\f486\fe02); }
.fad.#{$fa-css-prefix}-presentation-screen:before, .fa-duotone.#{$fa-css-prefix}-presentation-screen:before { content: fa-content(\f685\fe01); }
.fad.#{$fa-css-prefix}-presentation-screen:after, .fa-duotone.#{$fa-css-prefix}-presentation-screen:after { content: fa-content(\f685\fe02); }
.fad.#{$fa-css-prefix}-presentation:before, .fa-duotone.#{$fa-css-prefix}-presentation:before { content: fa-content(\f685\fe01); }
.fad.#{$fa-css-prefix}-presentation:after, .fa-duotone.#{$fa-css-prefix}-presentation:after { content: fa-content(\f685\fe02); }
.fad.#{$fa-css-prefix}-print:before, .fa-duotone.#{$fa-css-prefix}-print:before { content: fa-content(\2399\fe01); }
.fad.#{$fa-css-prefix}-print:after, .fa-duotone.#{$fa-css-prefix}-print:after { content: fa-content(\2399\fe02); }
.fad.#{$fa-css-prefix}-print-magnifying-glass:before, .fa-duotone.#{$fa-css-prefix}-print-magnifying-glass:before { content: fa-content(\f81a\fe01); }
.fad.#{$fa-css-prefix}-print-magnifying-glass:after, .fa-duotone.#{$fa-css-prefix}-print-magnifying-glass:after { content: fa-content(\f81a\fe02); }
.fad.#{$fa-css-prefix}-print-search:before, .fa-duotone.#{$fa-css-prefix}-print-search:before { content: fa-content(\f81a\fe01); }
.fad.#{$fa-css-prefix}-print-search:after, .fa-duotone.#{$fa-css-prefix}-print-search:after { content: fa-content(\f81a\fe02); }
.fad.#{$fa-css-prefix}-print-slash:before, .fa-duotone.#{$fa-css-prefix}-print-slash:before { content: fa-content(\f686\fe01); }
.fad.#{$fa-css-prefix}-print-slash:after, .fa-duotone.#{$fa-css-prefix}-print-slash:after { content: fa-content(\f686\fe02); }
.fad.#{$fa-css-prefix}-projector:before, .fa-duotone.#{$fa-css-prefix}-projector:before { content: fa-content(\f8d6\fe01); }
.fad.#{$fa-css-prefix}-projector:after, .fa-duotone.#{$fa-css-prefix}-projector:after { content: fa-content(\f8d6\fe02); }
.fad.#{$fa-css-prefix}-pump-medical:before, .fa-duotone.#{$fa-css-prefix}-pump-medical:before { content: fa-content(\e06a\fe01); }
.fad.#{$fa-css-prefix}-pump-medical:after, .fa-duotone.#{$fa-css-prefix}-pump-medical:after { content: fa-content(\e06a\fe02); }
.fad.#{$fa-css-prefix}-pump-soap:before, .fa-duotone.#{$fa-css-prefix}-pump-soap:before { content: fa-content(\e06b\fe01); }
.fad.#{$fa-css-prefix}-pump-soap:after, .fa-duotone.#{$fa-css-prefix}-pump-soap:after { content: fa-content(\e06b\fe02); }
.fad.#{$fa-css-prefix}-pumpkin:before, .fa-duotone.#{$fa-css-prefix}-pumpkin:before { content: fa-content(\f707\fe01); }
.fad.#{$fa-css-prefix}-pumpkin:after, .fa-duotone.#{$fa-css-prefix}-pumpkin:after { content: fa-content(\f707\fe02); }
.fad.#{$fa-css-prefix}-puzzle-piece:before, .fa-duotone.#{$fa-css-prefix}-puzzle-piece:before { content: fa-content(\f12e); }
.fad.#{$fa-css-prefix}-puzzle-piece:after, .fa-duotone.#{$fa-css-prefix}-puzzle-piece:after { content: fa-content(\10f12e); }
.fad.#{$fa-css-prefix}-puzzle-piece-simple:before, .fa-duotone.#{$fa-css-prefix}-puzzle-piece-simple:before { content: fa-content(\e231\fe01); }
.fad.#{$fa-css-prefix}-puzzle-piece-simple:after, .fa-duotone.#{$fa-css-prefix}-puzzle-piece-simple:after { content: fa-content(\e231\fe02); }
.fad.#{$fa-css-prefix}-puzzle-piece-alt:before, .fa-duotone.#{$fa-css-prefix}-puzzle-piece-alt:before { content: fa-content(\e231\fe01); }
.fad.#{$fa-css-prefix}-puzzle-piece-alt:after, .fa-duotone.#{$fa-css-prefix}-puzzle-piece-alt:after { content: fa-content(\e231\fe02); }
.fad.#{$fa-css-prefix}-q:before, .fa-duotone.#{$fa-css-prefix}-q:before { content: fa-content(\51\fe01); }
.fad.#{$fa-css-prefix}-q:after, .fa-duotone.#{$fa-css-prefix}-q:after { content: fa-content(\51\fe02); }
.fad.#{$fa-css-prefix}-qrcode:before, .fa-duotone.#{$fa-css-prefix}-qrcode:before { content: fa-content(\f029\fe01); }
.fad.#{$fa-css-prefix}-qrcode:after, .fa-duotone.#{$fa-css-prefix}-qrcode:after { content: fa-content(\f029\fe02); }
.fad.#{$fa-css-prefix}-question:before, .fa-duotone.#{$fa-css-prefix}-question:before { content: fa-content(\3f\fe01); }
.fad.#{$fa-css-prefix}-question:after, .fa-duotone.#{$fa-css-prefix}-question:after { content: fa-content(\3f\fe02); }
.fad.#{$fa-css-prefix}-quidditch:before, .fa-duotone.#{$fa-css-prefix}-quidditch:before { content: fa-content(\f458\fe01); }
.fad.#{$fa-css-prefix}-quidditch:after, .fa-duotone.#{$fa-css-prefix}-quidditch:after { content: fa-content(\f458\fe02); }
.fad.#{$fa-css-prefix}-broom-ball:before, .fa-duotone.#{$fa-css-prefix}-broom-ball:before { content: fa-content(\f458\fe01); }
.fad.#{$fa-css-prefix}-broom-ball:after, .fa-duotone.#{$fa-css-prefix}-broom-ball:after { content: fa-content(\f458\fe02); }
.fad.#{$fa-css-prefix}-quidditch-broom-ball:before, .fa-duotone.#{$fa-css-prefix}-quidditch-broom-ball:before { content: fa-content(\f458\fe01); }
.fad.#{$fa-css-prefix}-quidditch-broom-ball:after, .fa-duotone.#{$fa-css-prefix}-quidditch-broom-ball:after { content: fa-content(\f458\fe02); }
.fad.#{$fa-css-prefix}-quote-left:before, .fa-duotone.#{$fa-css-prefix}-quote-left:before { content: fa-content(\201c\fe01); }
.fad.#{$fa-css-prefix}-quote-left:after, .fa-duotone.#{$fa-css-prefix}-quote-left:after { content: fa-content(\201c\fe02); }
.fad.#{$fa-css-prefix}-quote-left-alt:before, .fa-duotone.#{$fa-css-prefix}-quote-left-alt:before { content: fa-content(\201c\fe01); }
.fad.#{$fa-css-prefix}-quote-left-alt:after, .fa-duotone.#{$fa-css-prefix}-quote-left-alt:after { content: fa-content(\201c\fe02); }
.fad.#{$fa-css-prefix}-quote-right:before, .fa-duotone.#{$fa-css-prefix}-quote-right:before { content: fa-content(\201d\fe01); }
.fad.#{$fa-css-prefix}-quote-right:after, .fa-duotone.#{$fa-css-prefix}-quote-right:after { content: fa-content(\201d\fe02); }
.fad.#{$fa-css-prefix}-quote-right-alt:before, .fa-duotone.#{$fa-css-prefix}-quote-right-alt:before { content: fa-content(\201d\fe01); }
.fad.#{$fa-css-prefix}-quote-right-alt:after, .fa-duotone.#{$fa-css-prefix}-quote-right-alt:after { content: fa-content(\201d\fe02); }
.fad.#{$fa-css-prefix}-quotes:before, .fa-duotone.#{$fa-css-prefix}-quotes:before { content: fa-content(\e234\fe01); }
.fad.#{$fa-css-prefix}-quotes:after, .fa-duotone.#{$fa-css-prefix}-quotes:after { content: fa-content(\e234\fe02); }
.fad.#{$fa-css-prefix}-r:before, .fa-duotone.#{$fa-css-prefix}-r:before { content: fa-content(\52\fe01); }
.fad.#{$fa-css-prefix}-r:after, .fa-duotone.#{$fa-css-prefix}-r:after { content: fa-content(\52\fe02); }
.fad.#{$fa-css-prefix}-rabbit:before, .fa-duotone.#{$fa-css-prefix}-rabbit:before { content: fa-content(\f708); }
.fad.#{$fa-css-prefix}-rabbit:after, .fa-duotone.#{$fa-css-prefix}-rabbit:after { content: fa-content(\10f708); }
.fad.#{$fa-css-prefix}-rabbit-running:before, .fa-duotone.#{$fa-css-prefix}-rabbit-running:before { content: fa-content(\f709\fe01); }
.fad.#{$fa-css-prefix}-rabbit-running:after, .fa-duotone.#{$fa-css-prefix}-rabbit-running:after { content: fa-content(\f709\fe02); }
.fad.#{$fa-css-prefix}-rabbit-fast:before, .fa-duotone.#{$fa-css-prefix}-rabbit-fast:before { content: fa-content(\f709\fe01); }
.fad.#{$fa-css-prefix}-rabbit-fast:after, .fa-duotone.#{$fa-css-prefix}-rabbit-fast:after { content: fa-content(\f709\fe02); }
.fad.#{$fa-css-prefix}-racquet:before, .fa-duotone.#{$fa-css-prefix}-racquet:before { content: fa-content(\f45a\fe01); }
.fad.#{$fa-css-prefix}-racquet:after, .fa-duotone.#{$fa-css-prefix}-racquet:after { content: fa-content(\f45a\fe02); }
.fad.#{$fa-css-prefix}-radar:before, .fa-duotone.#{$fa-css-prefix}-radar:before { content: fa-content(\e024\fe01); }
.fad.#{$fa-css-prefix}-radar:after, .fa-duotone.#{$fa-css-prefix}-radar:after { content: fa-content(\e024\fe02); }
.fad.#{$fa-css-prefix}-radiation:before, .fa-duotone.#{$fa-css-prefix}-radiation:before { content: fa-content(\f7b9\fe01); }
.fad.#{$fa-css-prefix}-radiation:after, .fa-duotone.#{$fa-css-prefix}-radiation:after { content: fa-content(\f7b9\fe02); }
.fad.#{$fa-css-prefix}-radio:before, .fa-duotone.#{$fa-css-prefix}-radio:before { content: fa-content(\f8d7); }
.fad.#{$fa-css-prefix}-radio:after, .fa-duotone.#{$fa-css-prefix}-radio:after { content: fa-content(\10f8d7); }
.fad.#{$fa-css-prefix}-radio-tuner:before, .fa-duotone.#{$fa-css-prefix}-radio-tuner:before { content: fa-content(\f8d8\fe01); }
.fad.#{$fa-css-prefix}-radio-tuner:after, .fa-duotone.#{$fa-css-prefix}-radio-tuner:after { content: fa-content(\f8d8\fe02); }
.fad.#{$fa-css-prefix}-radio-alt:before, .fa-duotone.#{$fa-css-prefix}-radio-alt:before { content: fa-content(\f8d8\fe01); }
.fad.#{$fa-css-prefix}-radio-alt:after, .fa-duotone.#{$fa-css-prefix}-radio-alt:after { content: fa-content(\f8d8\fe02); }
.fad.#{$fa-css-prefix}-rainbow:before, .fa-duotone.#{$fa-css-prefix}-rainbow:before { content: fa-content(\f75b); }
.fad.#{$fa-css-prefix}-rainbow:after, .fa-duotone.#{$fa-css-prefix}-rainbow:after { content: fa-content(\10f75b); }
.fad.#{$fa-css-prefix}-raindrops:before, .fa-duotone.#{$fa-css-prefix}-raindrops:before { content: fa-content(\f75c\fe01); }
.fad.#{$fa-css-prefix}-raindrops:after, .fa-duotone.#{$fa-css-prefix}-raindrops:after { content: fa-content(\f75c\fe02); }
.fad.#{$fa-css-prefix}-ram:before, .fa-duotone.#{$fa-css-prefix}-ram:before { content: fa-content(\f70a); }
.fad.#{$fa-css-prefix}-ram:after, .fa-duotone.#{$fa-css-prefix}-ram:after { content: fa-content(\10f70a); }
.fad.#{$fa-css-prefix}-ramp-loading:before, .fa-duotone.#{$fa-css-prefix}-ramp-loading:before { content: fa-content(\f4d4\fe01); }
.fad.#{$fa-css-prefix}-ramp-loading:after, .fa-duotone.#{$fa-css-prefix}-ramp-loading:after { content: fa-content(\f4d4\fe02); }
.fad.#{$fa-css-prefix}-raygun:before, .fa-duotone.#{$fa-css-prefix}-raygun:before { content: fa-content(\e025\fe01); }
.fad.#{$fa-css-prefix}-raygun:after, .fa-duotone.#{$fa-css-prefix}-raygun:after { content: fa-content(\e025\fe02); }
.fad.#{$fa-css-prefix}-receipt:before, .fa-duotone.#{$fa-css-prefix}-receipt:before { content: fa-content(\f543); }
.fad.#{$fa-css-prefix}-receipt:after, .fa-duotone.#{$fa-css-prefix}-receipt:after { content: fa-content(\10f543); }
.fad.#{$fa-css-prefix}-record-vinyl:before, .fa-duotone.#{$fa-css-prefix}-record-vinyl:before { content: fa-content(\f8d9\fe01); }
.fad.#{$fa-css-prefix}-record-vinyl:after, .fa-duotone.#{$fa-css-prefix}-record-vinyl:after { content: fa-content(\f8d9\fe02); }
.fad.#{$fa-css-prefix}-rectangle:before, .fa-duotone.#{$fa-css-prefix}-rectangle:before { content: fa-content(\25ac\fe01); }
.fad.#{$fa-css-prefix}-rectangle:after, .fa-duotone.#{$fa-css-prefix}-rectangle:after { content: fa-content(\25ac\fe02); }
.fad.#{$fa-css-prefix}-rectangle-landscape:before, .fa-duotone.#{$fa-css-prefix}-rectangle-landscape:before { content: fa-content(\25ac\fe01); }
.fad.#{$fa-css-prefix}-rectangle-landscape:after, .fa-duotone.#{$fa-css-prefix}-rectangle-landscape:after { content: fa-content(\25ac\fe02); }
.fad.#{$fa-css-prefix}-rectangle-ad:before, .fa-duotone.#{$fa-css-prefix}-rectangle-ad:before { content: fa-content(\f641\fe01); }
.fad.#{$fa-css-prefix}-rectangle-ad:after, .fa-duotone.#{$fa-css-prefix}-rectangle-ad:after { content: fa-content(\f641\fe02); }
.fad.#{$fa-css-prefix}-ad:before, .fa-duotone.#{$fa-css-prefix}-ad:before { content: fa-content(\f641\fe01); }
.fad.#{$fa-css-prefix}-ad:after, .fa-duotone.#{$fa-css-prefix}-ad:after { content: fa-content(\f641\fe02); }
.fad.#{$fa-css-prefix}-rectangle-barcode:before, .fa-duotone.#{$fa-css-prefix}-rectangle-barcode:before { content: fa-content(\f463\fe01); }
.fad.#{$fa-css-prefix}-rectangle-barcode:after, .fa-duotone.#{$fa-css-prefix}-rectangle-barcode:after { content: fa-content(\f463\fe02); }
.fad.#{$fa-css-prefix}-barcode-alt:before, .fa-duotone.#{$fa-css-prefix}-barcode-alt:before { content: fa-content(\f463\fe01); }
.fad.#{$fa-css-prefix}-barcode-alt:after, .fa-duotone.#{$fa-css-prefix}-barcode-alt:after { content: fa-content(\f463\fe02); }
.fad.#{$fa-css-prefix}-rectangle-code:before, .fa-duotone.#{$fa-css-prefix}-rectangle-code:before { content: fa-content(\e322\fe01); }
.fad.#{$fa-css-prefix}-rectangle-code:after, .fa-duotone.#{$fa-css-prefix}-rectangle-code:after { content: fa-content(\e322\fe02); }
.fad.#{$fa-css-prefix}-rectangle-list:before, .fa-duotone.#{$fa-css-prefix}-rectangle-list:before { content: fa-content(\f022\fe01); }
.fad.#{$fa-css-prefix}-rectangle-list:after, .fa-duotone.#{$fa-css-prefix}-rectangle-list:after { content: fa-content(\f022\fe02); }
.fad.#{$fa-css-prefix}-list-alt:before, .fa-duotone.#{$fa-css-prefix}-list-alt:before { content: fa-content(\f022\fe01); }
.fad.#{$fa-css-prefix}-list-alt:after, .fa-duotone.#{$fa-css-prefix}-list-alt:after { content: fa-content(\f022\fe02); }
.fad.#{$fa-css-prefix}-rectangle-pro:before, .fa-duotone.#{$fa-css-prefix}-rectangle-pro:before { content: fa-content(\e235\fe01); }
.fad.#{$fa-css-prefix}-rectangle-pro:after, .fa-duotone.#{$fa-css-prefix}-rectangle-pro:after { content: fa-content(\e235\fe02); }
.fad.#{$fa-css-prefix}-pro:before, .fa-duotone.#{$fa-css-prefix}-pro:before { content: fa-content(\e235\fe01); }
.fad.#{$fa-css-prefix}-pro:after, .fa-duotone.#{$fa-css-prefix}-pro:after { content: fa-content(\e235\fe02); }
.fad.#{$fa-css-prefix}-rectangle-terminal:before, .fa-duotone.#{$fa-css-prefix}-rectangle-terminal:before { content: fa-content(\e236\fe01); }
.fad.#{$fa-css-prefix}-rectangle-terminal:after, .fa-duotone.#{$fa-css-prefix}-rectangle-terminal:after { content: fa-content(\e236\fe02); }
.fad.#{$fa-css-prefix}-rectangle-vertical:before, .fa-duotone.#{$fa-css-prefix}-rectangle-vertical:before { content: fa-content(\f2fb\fe01); }
.fad.#{$fa-css-prefix}-rectangle-vertical:after, .fa-duotone.#{$fa-css-prefix}-rectangle-vertical:after { content: fa-content(\f2fb\fe02); }
.fad.#{$fa-css-prefix}-rectangle-portrait:before, .fa-duotone.#{$fa-css-prefix}-rectangle-portrait:before { content: fa-content(\f2fb\fe01); }
.fad.#{$fa-css-prefix}-rectangle-portrait:after, .fa-duotone.#{$fa-css-prefix}-rectangle-portrait:after { content: fa-content(\f2fb\fe02); }
.fad.#{$fa-css-prefix}-rectangle-vertical-history:before, .fa-duotone.#{$fa-css-prefix}-rectangle-vertical-history:before { content: fa-content(\e237\fe01); }
.fad.#{$fa-css-prefix}-rectangle-vertical-history:after, .fa-duotone.#{$fa-css-prefix}-rectangle-vertical-history:after { content: fa-content(\e237\fe02); }
.fad.#{$fa-css-prefix}-rectangle-wide:before, .fa-duotone.#{$fa-css-prefix}-rectangle-wide:before { content: fa-content(\f2fc\fe01); }
.fad.#{$fa-css-prefix}-rectangle-wide:after, .fa-duotone.#{$fa-css-prefix}-rectangle-wide:after { content: fa-content(\f2fc\fe02); }
.fad.#{$fa-css-prefix}-rectangle-xmark:before, .fa-duotone.#{$fa-css-prefix}-rectangle-xmark:before { content: fa-content(\f410\fe01); }
.fad.#{$fa-css-prefix}-rectangle-xmark:after, .fa-duotone.#{$fa-css-prefix}-rectangle-xmark:after { content: fa-content(\f410\fe02); }
.fad.#{$fa-css-prefix}-rectangle-times:before, .fa-duotone.#{$fa-css-prefix}-rectangle-times:before { content: fa-content(\f410\fe01); }
.fad.#{$fa-css-prefix}-rectangle-times:after, .fa-duotone.#{$fa-css-prefix}-rectangle-times:after { content: fa-content(\f410\fe02); }
.fad.#{$fa-css-prefix}-times-rectangle:before, .fa-duotone.#{$fa-css-prefix}-times-rectangle:before { content: fa-content(\f410\fe01); }
.fad.#{$fa-css-prefix}-times-rectangle:after, .fa-duotone.#{$fa-css-prefix}-times-rectangle:after { content: fa-content(\f410\fe02); }
.fad.#{$fa-css-prefix}-window-close:before, .fa-duotone.#{$fa-css-prefix}-window-close:before { content: fa-content(\f410\fe01); }
.fad.#{$fa-css-prefix}-window-close:after, .fa-duotone.#{$fa-css-prefix}-window-close:after { content: fa-content(\f410\fe02); }
.fad.#{$fa-css-prefix}-rectangles-mixed:before, .fa-duotone.#{$fa-css-prefix}-rectangles-mixed:before { content: fa-content(\e323\fe01); }
.fad.#{$fa-css-prefix}-rectangles-mixed:after, .fa-duotone.#{$fa-css-prefix}-rectangles-mixed:after { content: fa-content(\e323\fe02); }
.fad.#{$fa-css-prefix}-recycle:before, .fa-duotone.#{$fa-css-prefix}-recycle:before { content: fa-content(\2672\fe01); }
.fad.#{$fa-css-prefix}-recycle:after, .fa-duotone.#{$fa-css-prefix}-recycle:after { content: fa-content(\2672\fe02); }
.fad.#{$fa-css-prefix}-reel:before, .fa-duotone.#{$fa-css-prefix}-reel:before { content: fa-content(\e238\fe01); }
.fad.#{$fa-css-prefix}-reel:after, .fa-duotone.#{$fa-css-prefix}-reel:after { content: fa-content(\e238\fe02); }
.fad.#{$fa-css-prefix}-refrigerator:before, .fa-duotone.#{$fa-css-prefix}-refrigerator:before { content: fa-content(\e026\fe01); }
.fad.#{$fa-css-prefix}-refrigerator:after, .fa-duotone.#{$fa-css-prefix}-refrigerator:after { content: fa-content(\e026\fe02); }
.fad.#{$fa-css-prefix}-registered:before, .fa-duotone.#{$fa-css-prefix}-registered:before { content: fa-content(\ae\fe01); }
.fad.#{$fa-css-prefix}-registered:after, .fa-duotone.#{$fa-css-prefix}-registered:after { content: fa-content(\ae\fe02); }
.fad.#{$fa-css-prefix}-repeat:before, .fa-duotone.#{$fa-css-prefix}-repeat:before { content: fa-content(\f363); }
.fad.#{$fa-css-prefix}-repeat:after, .fa-duotone.#{$fa-css-prefix}-repeat:after { content: fa-content(\10f363); }
.fad.#{$fa-css-prefix}-repeat-1:before, .fa-duotone.#{$fa-css-prefix}-repeat-1:before { content: fa-content(\f365); }
.fad.#{$fa-css-prefix}-repeat-1:after, .fa-duotone.#{$fa-css-prefix}-repeat-1:after { content: fa-content(\10f365); }
.fad.#{$fa-css-prefix}-reply:before, .fa-duotone.#{$fa-css-prefix}-reply:before { content: fa-content(\f3e5\fe01); }
.fad.#{$fa-css-prefix}-reply:after, .fa-duotone.#{$fa-css-prefix}-reply:after { content: fa-content(\f3e5\fe02); }
.fad.#{$fa-css-prefix}-mail-reply:before, .fa-duotone.#{$fa-css-prefix}-mail-reply:before { content: fa-content(\f3e5\fe01); }
.fad.#{$fa-css-prefix}-mail-reply:after, .fa-duotone.#{$fa-css-prefix}-mail-reply:after { content: fa-content(\f3e5\fe02); }
.fad.#{$fa-css-prefix}-reply-all:before, .fa-duotone.#{$fa-css-prefix}-reply-all:before { content: fa-content(\f122\fe01); }
.fad.#{$fa-css-prefix}-reply-all:after, .fa-duotone.#{$fa-css-prefix}-reply-all:after { content: fa-content(\f122\fe02); }
.fad.#{$fa-css-prefix}-mail-reply-all:before, .fa-duotone.#{$fa-css-prefix}-mail-reply-all:before { content: fa-content(\f122\fe01); }
.fad.#{$fa-css-prefix}-mail-reply-all:after, .fa-duotone.#{$fa-css-prefix}-mail-reply-all:after { content: fa-content(\f122\fe02); }
.fad.#{$fa-css-prefix}-reply-clock:before, .fa-duotone.#{$fa-css-prefix}-reply-clock:before { content: fa-content(\e239\fe01); }
.fad.#{$fa-css-prefix}-reply-clock:after, .fa-duotone.#{$fa-css-prefix}-reply-clock:after { content: fa-content(\e239\fe02); }
.fad.#{$fa-css-prefix}-reply-time:before, .fa-duotone.#{$fa-css-prefix}-reply-time:before { content: fa-content(\e239\fe01); }
.fad.#{$fa-css-prefix}-reply-time:after, .fa-duotone.#{$fa-css-prefix}-reply-time:after { content: fa-content(\e239\fe02); }
.fad.#{$fa-css-prefix}-republican:before, .fa-duotone.#{$fa-css-prefix}-republican:before { content: fa-content(\f75e\fe01); }
.fad.#{$fa-css-prefix}-republican:after, .fa-duotone.#{$fa-css-prefix}-republican:after { content: fa-content(\f75e\fe02); }
.fad.#{$fa-css-prefix}-restroom:before, .fa-duotone.#{$fa-css-prefix}-restroom:before { content: fa-content(\f7bd\fe01); }
.fad.#{$fa-css-prefix}-restroom:after, .fa-duotone.#{$fa-css-prefix}-restroom:after { content: fa-content(\f7bd\fe02); }
.fad.#{$fa-css-prefix}-restroom-simple:before, .fa-duotone.#{$fa-css-prefix}-restroom-simple:before { content: fa-content(\e23a\fe01); }
.fad.#{$fa-css-prefix}-restroom-simple:after, .fa-duotone.#{$fa-css-prefix}-restroom-simple:after { content: fa-content(\e23a\fe02); }
.fad.#{$fa-css-prefix}-retweet:before, .fa-duotone.#{$fa-css-prefix}-retweet:before { content: fa-content(\f079\fe01); }
.fad.#{$fa-css-prefix}-retweet:after, .fa-duotone.#{$fa-css-prefix}-retweet:after { content: fa-content(\f079\fe02); }
.fad.#{$fa-css-prefix}-rhombus:before, .fa-duotone.#{$fa-css-prefix}-rhombus:before { content: fa-content(\e23b\fe01); }
.fad.#{$fa-css-prefix}-rhombus:after, .fa-duotone.#{$fa-css-prefix}-rhombus:after { content: fa-content(\e23b\fe02); }
.fad.#{$fa-css-prefix}-ribbon:before, .fa-duotone.#{$fa-css-prefix}-ribbon:before { content: fa-content(\f4d6); }
.fad.#{$fa-css-prefix}-ribbon:after, .fa-duotone.#{$fa-css-prefix}-ribbon:after { content: fa-content(\10f4d6); }
.fad.#{$fa-css-prefix}-right:before, .fa-duotone.#{$fa-css-prefix}-right:before { content: fa-content(\2b95\fe01); }
.fad.#{$fa-css-prefix}-right:after, .fa-duotone.#{$fa-css-prefix}-right:after { content: fa-content(\2b95\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-right:before { content: fa-content(\2b95\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-right:after { content: fa-content(\2b95\fe02); }
.fad.#{$fa-css-prefix}-right-from-bracket:before, .fa-duotone.#{$fa-css-prefix}-right-from-bracket:before { content: fa-content(\f2f5\fe01); }
.fad.#{$fa-css-prefix}-right-from-bracket:after, .fa-duotone.#{$fa-css-prefix}-right-from-bracket:after { content: fa-content(\f2f5\fe02); }
.fad.#{$fa-css-prefix}-sign-out-alt:before, .fa-duotone.#{$fa-css-prefix}-sign-out-alt:before { content: fa-content(\f2f5\fe01); }
.fad.#{$fa-css-prefix}-sign-out-alt:after, .fa-duotone.#{$fa-css-prefix}-sign-out-alt:after { content: fa-content(\f2f5\fe02); }
.fad.#{$fa-css-prefix}-right-from-line:before, .fa-duotone.#{$fa-css-prefix}-right-from-line:before { content: fa-content(\f347\fe01); }
.fad.#{$fa-css-prefix}-right-from-line:after, .fa-duotone.#{$fa-css-prefix}-right-from-line:after { content: fa-content(\f347\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-from-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-left:before { content: fa-content(\f347\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-from-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-left:after { content: fa-content(\f347\fe02); }
.fad.#{$fa-css-prefix}-right-left:before, .fa-duotone.#{$fa-css-prefix}-right-left:before { content: fa-content(\f362\fe01); }
.fad.#{$fa-css-prefix}-right-left:after, .fa-duotone.#{$fa-css-prefix}-right-left:after { content: fa-content(\f362\fe02); }
.fad.#{$fa-css-prefix}-exchange-alt:before, .fa-duotone.#{$fa-css-prefix}-exchange-alt:before { content: fa-content(\f362\fe01); }
.fad.#{$fa-css-prefix}-exchange-alt:after, .fa-duotone.#{$fa-css-prefix}-exchange-alt:after { content: fa-content(\f362\fe02); }
.fad.#{$fa-css-prefix}-right-long:before, .fa-duotone.#{$fa-css-prefix}-right-long:before { content: fa-content(\f30b\fe01); }
.fad.#{$fa-css-prefix}-right-long:after, .fa-duotone.#{$fa-css-prefix}-right-long:after { content: fa-content(\f30b\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-alt-right:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-right:before { content: fa-content(\f30b\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-alt-right:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-right:after { content: fa-content(\f30b\fe02); }
.fad.#{$fa-css-prefix}-right-to-bracket:before, .fa-duotone.#{$fa-css-prefix}-right-to-bracket:before { content: fa-content(\f2f6\fe01); }
.fad.#{$fa-css-prefix}-right-to-bracket:after, .fa-duotone.#{$fa-css-prefix}-right-to-bracket:after { content: fa-content(\f2f6\fe02); }
.fad.#{$fa-css-prefix}-sign-in-alt:before, .fa-duotone.#{$fa-css-prefix}-sign-in-alt:before { content: fa-content(\f2f6\fe01); }
.fad.#{$fa-css-prefix}-sign-in-alt:after, .fa-duotone.#{$fa-css-prefix}-sign-in-alt:after { content: fa-content(\f2f6\fe02); }
.fad.#{$fa-css-prefix}-right-to-line:before, .fa-duotone.#{$fa-css-prefix}-right-to-line:before { content: fa-content(\f34c\fe01); }
.fad.#{$fa-css-prefix}-right-to-line:after, .fa-duotone.#{$fa-css-prefix}-right-to-line:after { content: fa-content(\f34c\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-to-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-right:before { content: fa-content(\f34c\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-to-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-right:after { content: fa-content(\f34c\fe02); }
.fad.#{$fa-css-prefix}-ring:before, .fa-duotone.#{$fa-css-prefix}-ring:before { content: fa-content(\f70b\fe01); }
.fad.#{$fa-css-prefix}-ring:after, .fa-duotone.#{$fa-css-prefix}-ring:after { content: fa-content(\f70b\fe02); }
.fad.#{$fa-css-prefix}-rings-wedding:before, .fa-duotone.#{$fa-css-prefix}-rings-wedding:before { content: fa-content(\f81b\fe01); }
.fad.#{$fa-css-prefix}-rings-wedding:after, .fa-duotone.#{$fa-css-prefix}-rings-wedding:after { content: fa-content(\f81b\fe02); }
.fad.#{$fa-css-prefix}-road:before, .fa-duotone.#{$fa-css-prefix}-road:before { content: fa-content(\f018); }
.fad.#{$fa-css-prefix}-road:after, .fa-duotone.#{$fa-css-prefix}-road:after { content: fa-content(\10f018); }
.fad.#{$fa-css-prefix}-robot:before, .fa-duotone.#{$fa-css-prefix}-robot:before { content: fa-content(\f544); }
.fad.#{$fa-css-prefix}-robot:after, .fa-duotone.#{$fa-css-prefix}-robot:after { content: fa-content(\10f544); }
.fad.#{$fa-css-prefix}-robot-astromech:before, .fa-duotone.#{$fa-css-prefix}-robot-astromech:before { content: fa-content(\e2d2\fe01); }
.fad.#{$fa-css-prefix}-robot-astromech:after, .fa-duotone.#{$fa-css-prefix}-robot-astromech:after { content: fa-content(\e2d2\fe02); }
.fad.#{$fa-css-prefix}-rocket:before, .fa-duotone.#{$fa-css-prefix}-rocket:before { content: fa-content(\f135\fe01); }
.fad.#{$fa-css-prefix}-rocket:after, .fa-duotone.#{$fa-css-prefix}-rocket:after { content: fa-content(\f135\fe02); }
.fad.#{$fa-css-prefix}-rocket-launch:before, .fa-duotone.#{$fa-css-prefix}-rocket-launch:before { content: fa-content(\e027); }
.fad.#{$fa-css-prefix}-rocket-launch:after, .fa-duotone.#{$fa-css-prefix}-rocket-launch:after { content: fa-content(\10e027); }
.fad.#{$fa-css-prefix}-roller-coaster:before, .fa-duotone.#{$fa-css-prefix}-roller-coaster:before { content: fa-content(\e324\fe01); }
.fad.#{$fa-css-prefix}-roller-coaster:after, .fa-duotone.#{$fa-css-prefix}-roller-coaster:after { content: fa-content(\e324\fe02); }
.fad.#{$fa-css-prefix}-rotate:before, .fa-duotone.#{$fa-css-prefix}-rotate:before { content: fa-content(\f2f1); }
.fad.#{$fa-css-prefix}-rotate:after, .fa-duotone.#{$fa-css-prefix}-rotate:after { content: fa-content(\10f2f1); }
.fad.#{$fa-css-prefix}-sync-alt:before, .fa-duotone.#{$fa-css-prefix}-sync-alt:before { content: fa-content(\f2f1); }
.fad.#{$fa-css-prefix}-sync-alt:after, .fa-duotone.#{$fa-css-prefix}-sync-alt:after { content: fa-content(\10f2f1); }
.fad.#{$fa-css-prefix}-rotate-exclamation:before, .fa-duotone.#{$fa-css-prefix}-rotate-exclamation:before { content: fa-content(\e23c\fe01); }
.fad.#{$fa-css-prefix}-rotate-exclamation:after, .fa-duotone.#{$fa-css-prefix}-rotate-exclamation:after { content: fa-content(\e23c\fe02); }
.fad.#{$fa-css-prefix}-rotate-left:before, .fa-duotone.#{$fa-css-prefix}-rotate-left:before { content: fa-content(\f2ea\fe01); }
.fad.#{$fa-css-prefix}-rotate-left:after, .fa-duotone.#{$fa-css-prefix}-rotate-left:after { content: fa-content(\f2ea\fe02); }
.fad.#{$fa-css-prefix}-rotate-back:before, .fa-duotone.#{$fa-css-prefix}-rotate-back:before { content: fa-content(\f2ea\fe01); }
.fad.#{$fa-css-prefix}-rotate-back:after, .fa-duotone.#{$fa-css-prefix}-rotate-back:after { content: fa-content(\f2ea\fe02); }
.fad.#{$fa-css-prefix}-rotate-backward:before, .fa-duotone.#{$fa-css-prefix}-rotate-backward:before { content: fa-content(\f2ea\fe01); }
.fad.#{$fa-css-prefix}-rotate-backward:after, .fa-duotone.#{$fa-css-prefix}-rotate-backward:after { content: fa-content(\f2ea\fe02); }
.fad.#{$fa-css-prefix}-undo-alt:before, .fa-duotone.#{$fa-css-prefix}-undo-alt:before { content: fa-content(\f2ea\fe01); }
.fad.#{$fa-css-prefix}-undo-alt:after, .fa-duotone.#{$fa-css-prefix}-undo-alt:after { content: fa-content(\f2ea\fe02); }
.fad.#{$fa-css-prefix}-rotate-right:before, .fa-duotone.#{$fa-css-prefix}-rotate-right:before { content: fa-content(\f2f9\fe01); }
.fad.#{$fa-css-prefix}-rotate-right:after, .fa-duotone.#{$fa-css-prefix}-rotate-right:after { content: fa-content(\f2f9\fe02); }
.fad.#{$fa-css-prefix}-redo-alt:before, .fa-duotone.#{$fa-css-prefix}-redo-alt:before { content: fa-content(\f2f9\fe01); }
.fad.#{$fa-css-prefix}-redo-alt:after, .fa-duotone.#{$fa-css-prefix}-redo-alt:after { content: fa-content(\f2f9\fe02); }
.fad.#{$fa-css-prefix}-rotate-forward:before, .fa-duotone.#{$fa-css-prefix}-rotate-forward:before { content: fa-content(\f2f9\fe01); }
.fad.#{$fa-css-prefix}-rotate-forward:after, .fa-duotone.#{$fa-css-prefix}-rotate-forward:after { content: fa-content(\f2f9\fe02); }
.fad.#{$fa-css-prefix}-route:before, .fa-duotone.#{$fa-css-prefix}-route:before { content: fa-content(\f4d7\fe01); }
.fad.#{$fa-css-prefix}-route:after, .fa-duotone.#{$fa-css-prefix}-route:after { content: fa-content(\f4d7\fe02); }
.fad.#{$fa-css-prefix}-route-highway:before, .fa-duotone.#{$fa-css-prefix}-route-highway:before { content: fa-content(\f61a\fe01); }
.fad.#{$fa-css-prefix}-route-highway:after, .fa-duotone.#{$fa-css-prefix}-route-highway:after { content: fa-content(\f61a\fe02); }
.fad.#{$fa-css-prefix}-route-interstate:before, .fa-duotone.#{$fa-css-prefix}-route-interstate:before { content: fa-content(\f61b\fe01); }
.fad.#{$fa-css-prefix}-route-interstate:after, .fa-duotone.#{$fa-css-prefix}-route-interstate:after { content: fa-content(\f61b\fe02); }
.fad.#{$fa-css-prefix}-router:before, .fa-duotone.#{$fa-css-prefix}-router:before { content: fa-content(\f8da\fe01); }
.fad.#{$fa-css-prefix}-router:after, .fa-duotone.#{$fa-css-prefix}-router:after { content: fa-content(\f8da\fe02); }
.fad.#{$fa-css-prefix}-rss:before, .fa-duotone.#{$fa-css-prefix}-rss:before { content: fa-content(\f09e\fe01); }
.fad.#{$fa-css-prefix}-rss:after, .fa-duotone.#{$fa-css-prefix}-rss:after { content: fa-content(\f09e\fe02); }
.fad.#{$fa-css-prefix}-feed:before, .fa-duotone.#{$fa-css-prefix}-feed:before { content: fa-content(\f09e\fe01); }
.fad.#{$fa-css-prefix}-feed:after, .fa-duotone.#{$fa-css-prefix}-feed:after { content: fa-content(\f09e\fe02); }
.fad.#{$fa-css-prefix}-ruble-sign:before, .fa-duotone.#{$fa-css-prefix}-ruble-sign:before { content: fa-content(\20bd\fe01); }
.fad.#{$fa-css-prefix}-ruble-sign:after, .fa-duotone.#{$fa-css-prefix}-ruble-sign:after { content: fa-content(\20bd\fe02); }
.fad.#{$fa-css-prefix}-rouble:before, .fa-duotone.#{$fa-css-prefix}-rouble:before { content: fa-content(\20bd\fe01); }
.fad.#{$fa-css-prefix}-rouble:after, .fa-duotone.#{$fa-css-prefix}-rouble:after { content: fa-content(\20bd\fe02); }
.fad.#{$fa-css-prefix}-rub:before, .fa-duotone.#{$fa-css-prefix}-rub:before { content: fa-content(\20bd\fe01); }
.fad.#{$fa-css-prefix}-rub:after, .fa-duotone.#{$fa-css-prefix}-rub:after { content: fa-content(\20bd\fe02); }
.fad.#{$fa-css-prefix}-ruble:before, .fa-duotone.#{$fa-css-prefix}-ruble:before { content: fa-content(\20bd\fe01); }
.fad.#{$fa-css-prefix}-ruble:after, .fa-duotone.#{$fa-css-prefix}-ruble:after { content: fa-content(\20bd\fe02); }
.fad.#{$fa-css-prefix}-rugby-ball:before, .fa-duotone.#{$fa-css-prefix}-rugby-ball:before { content: fa-content(\e3c6\fe01); }
.fad.#{$fa-css-prefix}-rugby-ball:after, .fa-duotone.#{$fa-css-prefix}-rugby-ball:after { content: fa-content(\e3c6\fe02); }
.fad.#{$fa-css-prefix}-ruler:before, .fa-duotone.#{$fa-css-prefix}-ruler:before { content: fa-content(\f545); }
.fad.#{$fa-css-prefix}-ruler:after, .fa-duotone.#{$fa-css-prefix}-ruler:after { content: fa-content(\10f545); }
.fad.#{$fa-css-prefix}-ruler-combined:before, .fa-duotone.#{$fa-css-prefix}-ruler-combined:before { content: fa-content(\f546\fe01); }
.fad.#{$fa-css-prefix}-ruler-combined:after, .fa-duotone.#{$fa-css-prefix}-ruler-combined:after { content: fa-content(\f546\fe02); }
.fad.#{$fa-css-prefix}-ruler-horizontal:before, .fa-duotone.#{$fa-css-prefix}-ruler-horizontal:before { content: fa-content(\f547\fe01); }
.fad.#{$fa-css-prefix}-ruler-horizontal:after, .fa-duotone.#{$fa-css-prefix}-ruler-horizontal:after { content: fa-content(\f547\fe02); }
.fad.#{$fa-css-prefix}-ruler-triangle:before, .fa-duotone.#{$fa-css-prefix}-ruler-triangle:before { content: fa-content(\f61c); }
.fad.#{$fa-css-prefix}-ruler-triangle:after, .fa-duotone.#{$fa-css-prefix}-ruler-triangle:after { content: fa-content(\10f61c); }
.fad.#{$fa-css-prefix}-ruler-vertical:before, .fa-duotone.#{$fa-css-prefix}-ruler-vertical:before { content: fa-content(\f548\fe01); }
.fad.#{$fa-css-prefix}-ruler-vertical:after, .fa-duotone.#{$fa-css-prefix}-ruler-vertical:after { content: fa-content(\f548\fe02); }
.fad.#{$fa-css-prefix}-rupee-sign:before, .fa-duotone.#{$fa-css-prefix}-rupee-sign:before { content: fa-content(\20a8\fe01); }
.fad.#{$fa-css-prefix}-rupee-sign:after, .fa-duotone.#{$fa-css-prefix}-rupee-sign:after { content: fa-content(\20a8\fe02); }
.fad.#{$fa-css-prefix}-rupee:before, .fa-duotone.#{$fa-css-prefix}-rupee:before { content: fa-content(\20a8\fe01); }
.fad.#{$fa-css-prefix}-rupee:after, .fa-duotone.#{$fa-css-prefix}-rupee:after { content: fa-content(\20a8\fe02); }
.fad.#{$fa-css-prefix}-rupiah-sign:before, .fa-duotone.#{$fa-css-prefix}-rupiah-sign:before { content: fa-content(\e23d\fe01); }
.fad.#{$fa-css-prefix}-rupiah-sign:after, .fa-duotone.#{$fa-css-prefix}-rupiah-sign:after { content: fa-content(\e23d\fe02); }
.fad.#{$fa-css-prefix}-rv:before, .fa-duotone.#{$fa-css-prefix}-rv:before { content: fa-content(\f7be\fe01); }
.fad.#{$fa-css-prefix}-rv:after, .fa-duotone.#{$fa-css-prefix}-rv:after { content: fa-content(\f7be\fe02); }
.fad.#{$fa-css-prefix}-s:before, .fa-duotone.#{$fa-css-prefix}-s:before { content: fa-content(\53\fe01); }
.fad.#{$fa-css-prefix}-s:after, .fa-duotone.#{$fa-css-prefix}-s:after { content: fa-content(\53\fe02); }
.fad.#{$fa-css-prefix}-sack:before, .fa-duotone.#{$fa-css-prefix}-sack:before { content: fa-content(\f81c\fe01); }
.fad.#{$fa-css-prefix}-sack:after, .fa-duotone.#{$fa-css-prefix}-sack:after { content: fa-content(\f81c\fe02); }
.fad.#{$fa-css-prefix}-sack-dollar:before, .fa-duotone.#{$fa-css-prefix}-sack-dollar:before { content: fa-content(\f81d); }
.fad.#{$fa-css-prefix}-sack-dollar:after, .fa-duotone.#{$fa-css-prefix}-sack-dollar:after { content: fa-content(\10f81d); }
.fad.#{$fa-css-prefix}-salad:before, .fa-duotone.#{$fa-css-prefix}-salad:before { content: fa-content(\f81e); }
.fad.#{$fa-css-prefix}-salad:after, .fa-duotone.#{$fa-css-prefix}-salad:after { content: fa-content(\10f81e); }
.fad.#{$fa-css-prefix}-bowl-salad:before, .fa-duotone.#{$fa-css-prefix}-bowl-salad:before { content: fa-content(\f81e); }
.fad.#{$fa-css-prefix}-bowl-salad:after, .fa-duotone.#{$fa-css-prefix}-bowl-salad:after { content: fa-content(\10f81e); }
.fad.#{$fa-css-prefix}-sandwich:before, .fa-duotone.#{$fa-css-prefix}-sandwich:before { content: fa-content(\f81f); }
.fad.#{$fa-css-prefix}-sandwich:after, .fa-duotone.#{$fa-css-prefix}-sandwich:after { content: fa-content(\10f81f); }
.fad.#{$fa-css-prefix}-satellite:before, .fa-duotone.#{$fa-css-prefix}-satellite:before { content: fa-content(\f7bf); }
.fad.#{$fa-css-prefix}-satellite:after, .fa-duotone.#{$fa-css-prefix}-satellite:after { content: fa-content(\10f7bf); }
.fad.#{$fa-css-prefix}-satellite-dish:before, .fa-duotone.#{$fa-css-prefix}-satellite-dish:before { content: fa-content(\f7c0); }
.fad.#{$fa-css-prefix}-satellite-dish:after, .fa-duotone.#{$fa-css-prefix}-satellite-dish:after { content: fa-content(\10f7c0); }
.fad.#{$fa-css-prefix}-sausage:before, .fa-duotone.#{$fa-css-prefix}-sausage:before { content: fa-content(\f820\fe01); }
.fad.#{$fa-css-prefix}-sausage:after, .fa-duotone.#{$fa-css-prefix}-sausage:after { content: fa-content(\f820\fe02); }
.fad.#{$fa-css-prefix}-saxophone:before, .fa-duotone.#{$fa-css-prefix}-saxophone:before { content: fa-content(\f8dc); }
.fad.#{$fa-css-prefix}-saxophone:after, .fa-duotone.#{$fa-css-prefix}-saxophone:after { content: fa-content(\10f8dc); }
.fad.#{$fa-css-prefix}-saxophone-fire:before, .fa-duotone.#{$fa-css-prefix}-saxophone-fire:before { content: fa-content(\f8db\fe01); }
.fad.#{$fa-css-prefix}-saxophone-fire:after, .fa-duotone.#{$fa-css-prefix}-saxophone-fire:after { content: fa-content(\f8db\fe02); }
.fad.#{$fa-css-prefix}-sax-hot:before, .fa-duotone.#{$fa-css-prefix}-sax-hot:before { content: fa-content(\f8db\fe01); }
.fad.#{$fa-css-prefix}-sax-hot:after, .fa-duotone.#{$fa-css-prefix}-sax-hot:after { content: fa-content(\f8db\fe02); }
.fad.#{$fa-css-prefix}-scale-balanced:before, .fa-duotone.#{$fa-css-prefix}-scale-balanced:before { content: fa-content(\2696\fe01); }
.fad.#{$fa-css-prefix}-scale-balanced:after, .fa-duotone.#{$fa-css-prefix}-scale-balanced:after { content: fa-content(\2696\fe02); }
.fad.#{$fa-css-prefix}-balance-scale:before, .fa-duotone.#{$fa-css-prefix}-balance-scale:before { content: fa-content(\2696\fe01); }
.fad.#{$fa-css-prefix}-balance-scale:after, .fa-duotone.#{$fa-css-prefix}-balance-scale:after { content: fa-content(\2696\fe02); }
.fad.#{$fa-css-prefix}-scale-unbalanced:before, .fa-duotone.#{$fa-css-prefix}-scale-unbalanced:before { content: fa-content(\f515\fe01); }
.fad.#{$fa-css-prefix}-scale-unbalanced:after, .fa-duotone.#{$fa-css-prefix}-scale-unbalanced:after { content: fa-content(\f515\fe02); }
.fad.#{$fa-css-prefix}-balance-scale-left:before, .fa-duotone.#{$fa-css-prefix}-balance-scale-left:before { content: fa-content(\f515\fe01); }
.fad.#{$fa-css-prefix}-balance-scale-left:after, .fa-duotone.#{$fa-css-prefix}-balance-scale-left:after { content: fa-content(\f515\fe02); }
.fad.#{$fa-css-prefix}-scale-unbalanced-flip:before, .fa-duotone.#{$fa-css-prefix}-scale-unbalanced-flip:before { content: fa-content(\f516\fe01); }
.fad.#{$fa-css-prefix}-scale-unbalanced-flip:after, .fa-duotone.#{$fa-css-prefix}-scale-unbalanced-flip:after { content: fa-content(\f516\fe02); }
.fad.#{$fa-css-prefix}-balance-scale-right:before, .fa-duotone.#{$fa-css-prefix}-balance-scale-right:before { content: fa-content(\f516\fe01); }
.fad.#{$fa-css-prefix}-balance-scale-right:after, .fa-duotone.#{$fa-css-prefix}-balance-scale-right:after { content: fa-content(\f516\fe02); }
.fad.#{$fa-css-prefix}-scalpel:before, .fa-duotone.#{$fa-css-prefix}-scalpel:before { content: fa-content(\f61d\fe01); }
.fad.#{$fa-css-prefix}-scalpel:after, .fa-duotone.#{$fa-css-prefix}-scalpel:after { content: fa-content(\f61d\fe02); }
.fad.#{$fa-css-prefix}-scalpel-line-dashed:before, .fa-duotone.#{$fa-css-prefix}-scalpel-line-dashed:before { content: fa-content(\f61e\fe01); }
.fad.#{$fa-css-prefix}-scalpel-line-dashed:after, .fa-duotone.#{$fa-css-prefix}-scalpel-line-dashed:after { content: fa-content(\f61e\fe02); }
.fad.#{$fa-css-prefix}-scalpel-path:before, .fa-duotone.#{$fa-css-prefix}-scalpel-path:before { content: fa-content(\f61e\fe01); }
.fad.#{$fa-css-prefix}-scalpel-path:after, .fa-duotone.#{$fa-css-prefix}-scalpel-path:after { content: fa-content(\f61e\fe02); }
.fad.#{$fa-css-prefix}-scanner:before, .fa-duotone.#{$fa-css-prefix}-scanner:before { content: fa-content(\f8f3\fe01); }
.fad.#{$fa-css-prefix}-scanner:after, .fa-duotone.#{$fa-css-prefix}-scanner:after { content: fa-content(\f8f3\fe02); }
.fad.#{$fa-css-prefix}-scanner-image:before, .fa-duotone.#{$fa-css-prefix}-scanner-image:before { content: fa-content(\f8f3\fe01); }
.fad.#{$fa-css-prefix}-scanner-image:after, .fa-duotone.#{$fa-css-prefix}-scanner-image:after { content: fa-content(\f8f3\fe02); }
.fad.#{$fa-css-prefix}-scanner-gun:before, .fa-duotone.#{$fa-css-prefix}-scanner-gun:before { content: fa-content(\f488\fe01); }
.fad.#{$fa-css-prefix}-scanner-gun:after, .fa-duotone.#{$fa-css-prefix}-scanner-gun:after { content: fa-content(\f488\fe02); }
.fad.#{$fa-css-prefix}-scanner-keyboard:before, .fa-duotone.#{$fa-css-prefix}-scanner-keyboard:before { content: fa-content(\f489\fe01); }
.fad.#{$fa-css-prefix}-scanner-keyboard:after, .fa-duotone.#{$fa-css-prefix}-scanner-keyboard:after { content: fa-content(\f489\fe02); }
.fad.#{$fa-css-prefix}-scanner-touchscreen:before, .fa-duotone.#{$fa-css-prefix}-scanner-touchscreen:before { content: fa-content(\f48a\fe01); }
.fad.#{$fa-css-prefix}-scanner-touchscreen:after, .fa-duotone.#{$fa-css-prefix}-scanner-touchscreen:after { content: fa-content(\f48a\fe02); }
.fad.#{$fa-css-prefix}-scarecrow:before, .fa-duotone.#{$fa-css-prefix}-scarecrow:before { content: fa-content(\f70d\fe01); }
.fad.#{$fa-css-prefix}-scarecrow:after, .fa-duotone.#{$fa-css-prefix}-scarecrow:after { content: fa-content(\f70d\fe02); }
.fad.#{$fa-css-prefix}-scarf:before, .fa-duotone.#{$fa-css-prefix}-scarf:before { content: fa-content(\f7c1); }
.fad.#{$fa-css-prefix}-scarf:after, .fa-duotone.#{$fa-css-prefix}-scarf:after { content: fa-content(\10f7c1); }
.fad.#{$fa-css-prefix}-school:before, .fa-duotone.#{$fa-css-prefix}-school:before { content: fa-content(\f549); }
.fad.#{$fa-css-prefix}-school:after, .fa-duotone.#{$fa-css-prefix}-school:after { content: fa-content(\10f549); }
.fad.#{$fa-css-prefix}-scissors:before, .fa-duotone.#{$fa-css-prefix}-scissors:before { content: fa-content(\2700\fe01); }
.fad.#{$fa-css-prefix}-scissors:after, .fa-duotone.#{$fa-css-prefix}-scissors:after { content: fa-content(\2700\fe02); }
.fad.#{$fa-css-prefix}-cut:before, .fa-duotone.#{$fa-css-prefix}-cut:before { content: fa-content(\2700\fe01); }
.fad.#{$fa-css-prefix}-cut:after, .fa-duotone.#{$fa-css-prefix}-cut:after { content: fa-content(\2700\fe02); }
.fad.#{$fa-css-prefix}-screen-users:before, .fa-duotone.#{$fa-css-prefix}-screen-users:before { content: fa-content(\f63d\fe01); }
.fad.#{$fa-css-prefix}-screen-users:after, .fa-duotone.#{$fa-css-prefix}-screen-users:after { content: fa-content(\f63d\fe02); }
.fad.#{$fa-css-prefix}-users-class:before, .fa-duotone.#{$fa-css-prefix}-users-class:before { content: fa-content(\f63d\fe01); }
.fad.#{$fa-css-prefix}-users-class:after, .fa-duotone.#{$fa-css-prefix}-users-class:after { content: fa-content(\f63d\fe02); }
.fad.#{$fa-css-prefix}-screencast:before, .fa-duotone.#{$fa-css-prefix}-screencast:before { content: fa-content(\e23e\fe01); }
.fad.#{$fa-css-prefix}-screencast:after, .fa-duotone.#{$fa-css-prefix}-screencast:after { content: fa-content(\e23e\fe02); }
.fad.#{$fa-css-prefix}-screwdriver:before, .fa-duotone.#{$fa-css-prefix}-screwdriver:before { content: fa-content(\f54a); }
.fad.#{$fa-css-prefix}-screwdriver:after, .fa-duotone.#{$fa-css-prefix}-screwdriver:after { content: fa-content(\10f54a); }
.fad.#{$fa-css-prefix}-screwdriver-wrench:before, .fa-duotone.#{$fa-css-prefix}-screwdriver-wrench:before { content: fa-content(\f7d9\fe01); }
.fad.#{$fa-css-prefix}-screwdriver-wrench:after, .fa-duotone.#{$fa-css-prefix}-screwdriver-wrench:after { content: fa-content(\f7d9\fe02); }
.fad.#{$fa-css-prefix}-tools:before, .fa-duotone.#{$fa-css-prefix}-tools:before { content: fa-content(\f7d9\fe01); }
.fad.#{$fa-css-prefix}-tools:after, .fa-duotone.#{$fa-css-prefix}-tools:after { content: fa-content(\f7d9\fe02); }
.fad.#{$fa-css-prefix}-scribble:before, .fa-duotone.#{$fa-css-prefix}-scribble:before { content: fa-content(\e23f\fe01); }
.fad.#{$fa-css-prefix}-scribble:after, .fa-duotone.#{$fa-css-prefix}-scribble:after { content: fa-content(\e23f\fe02); }
.fad.#{$fa-css-prefix}-scroll:before, .fa-duotone.#{$fa-css-prefix}-scroll:before { content: fa-content(\f70e); }
.fad.#{$fa-css-prefix}-scroll:after, .fa-duotone.#{$fa-css-prefix}-scroll:after { content: fa-content(\10f70e); }
.fad.#{$fa-css-prefix}-scroll-old:before, .fa-duotone.#{$fa-css-prefix}-scroll-old:before { content: fa-content(\f70f\fe01); }
.fad.#{$fa-css-prefix}-scroll-old:after, .fa-duotone.#{$fa-css-prefix}-scroll-old:after { content: fa-content(\f70f\fe02); }
.fad.#{$fa-css-prefix}-scroll-torah:before, .fa-duotone.#{$fa-css-prefix}-scroll-torah:before { content: fa-content(\f6a0\fe01); }
.fad.#{$fa-css-prefix}-scroll-torah:after, .fa-duotone.#{$fa-css-prefix}-scroll-torah:after { content: fa-content(\f6a0\fe02); }
.fad.#{$fa-css-prefix}-torah:before, .fa-duotone.#{$fa-css-prefix}-torah:before { content: fa-content(\f6a0\fe01); }
.fad.#{$fa-css-prefix}-torah:after, .fa-duotone.#{$fa-css-prefix}-torah:after { content: fa-content(\f6a0\fe02); }
.fad.#{$fa-css-prefix}-scrubber:before, .fa-duotone.#{$fa-css-prefix}-scrubber:before { content: fa-content(\f2f8\fe01); }
.fad.#{$fa-css-prefix}-scrubber:after, .fa-duotone.#{$fa-css-prefix}-scrubber:after { content: fa-content(\f2f8\fe02); }
.fad.#{$fa-css-prefix}-scythe:before, .fa-duotone.#{$fa-css-prefix}-scythe:before { content: fa-content(\f710\fe01); }
.fad.#{$fa-css-prefix}-scythe:after, .fa-duotone.#{$fa-css-prefix}-scythe:after { content: fa-content(\f710\fe02); }
.fad.#{$fa-css-prefix}-sd-card:before, .fa-duotone.#{$fa-css-prefix}-sd-card:before { content: fa-content(\f7c2\fe01); }
.fad.#{$fa-css-prefix}-sd-card:after, .fa-duotone.#{$fa-css-prefix}-sd-card:after { content: fa-content(\f7c2\fe02); }
.fad.#{$fa-css-prefix}-sd-cards:before, .fa-duotone.#{$fa-css-prefix}-sd-cards:before { content: fa-content(\e240\fe01); }
.fad.#{$fa-css-prefix}-sd-cards:after, .fa-duotone.#{$fa-css-prefix}-sd-cards:after { content: fa-content(\e240\fe02); }
.fad.#{$fa-css-prefix}-seal:before, .fa-duotone.#{$fa-css-prefix}-seal:before { content: fa-content(\e241\fe01); }
.fad.#{$fa-css-prefix}-seal:after, .fa-duotone.#{$fa-css-prefix}-seal:after { content: fa-content(\e241\fe02); }
.fad.#{$fa-css-prefix}-seal-exclamation:before, .fa-duotone.#{$fa-css-prefix}-seal-exclamation:before { content: fa-content(\e242\fe01); }
.fad.#{$fa-css-prefix}-seal-exclamation:after, .fa-duotone.#{$fa-css-prefix}-seal-exclamation:after { content: fa-content(\e242\fe02); }
.fad.#{$fa-css-prefix}-seal-question:before, .fa-duotone.#{$fa-css-prefix}-seal-question:before { content: fa-content(\e243\fe01); }
.fad.#{$fa-css-prefix}-seal-question:after, .fa-duotone.#{$fa-css-prefix}-seal-question:after { content: fa-content(\e243\fe02); }
.fad.#{$fa-css-prefix}-seat-airline:before, .fa-duotone.#{$fa-css-prefix}-seat-airline:before { content: fa-content(\e244\fe01); }
.fad.#{$fa-css-prefix}-seat-airline:after, .fa-duotone.#{$fa-css-prefix}-seat-airline:after { content: fa-content(\e244\fe02); }
.fad.#{$fa-css-prefix}-section:before, .fa-duotone.#{$fa-css-prefix}-section:before { content: fa-content(\a7\fe01); }
.fad.#{$fa-css-prefix}-section:after, .fa-duotone.#{$fa-css-prefix}-section:after { content: fa-content(\a7\fe02); }
.fad.#{$fa-css-prefix}-seedling:before, .fa-duotone.#{$fa-css-prefix}-seedling:before { content: fa-content(\f4d8); }
.fad.#{$fa-css-prefix}-seedling:after, .fa-duotone.#{$fa-css-prefix}-seedling:after { content: fa-content(\10f4d8); }
.fad.#{$fa-css-prefix}-sprout:before, .fa-duotone.#{$fa-css-prefix}-sprout:before { content: fa-content(\f4d8); }
.fad.#{$fa-css-prefix}-sprout:after, .fa-duotone.#{$fa-css-prefix}-sprout:after { content: fa-content(\10f4d8); }
.fad.#{$fa-css-prefix}-semicolon:before, .fa-duotone.#{$fa-css-prefix}-semicolon:before { content: fa-content(\3b\fe01); }
.fad.#{$fa-css-prefix}-semicolon:after, .fa-duotone.#{$fa-css-prefix}-semicolon:after { content: fa-content(\3b\fe02); }
.fad.#{$fa-css-prefix}-send-back:before, .fa-duotone.#{$fa-css-prefix}-send-back:before { content: fa-content(\f87e\fe01); }
.fad.#{$fa-css-prefix}-send-back:after, .fa-duotone.#{$fa-css-prefix}-send-back:after { content: fa-content(\f87e\fe02); }
.fad.#{$fa-css-prefix}-send-backward:before, .fa-duotone.#{$fa-css-prefix}-send-backward:before { content: fa-content(\f87f\fe01); }
.fad.#{$fa-css-prefix}-send-backward:after, .fa-duotone.#{$fa-css-prefix}-send-backward:after { content: fa-content(\f87f\fe02); }
.fad.#{$fa-css-prefix}-sensor:before, .fa-duotone.#{$fa-css-prefix}-sensor:before { content: fa-content(\e028\fe01); }
.fad.#{$fa-css-prefix}-sensor:after, .fa-duotone.#{$fa-css-prefix}-sensor:after { content: fa-content(\e028\fe02); }
.fad.#{$fa-css-prefix}-sensor-cloud:before, .fa-duotone.#{$fa-css-prefix}-sensor-cloud:before { content: fa-content(\e02c\fe01); }
.fad.#{$fa-css-prefix}-sensor-cloud:after, .fa-duotone.#{$fa-css-prefix}-sensor-cloud:after { content: fa-content(\e02c\fe02); }
.fad.#{$fa-css-prefix}-sensor-smoke:before, .fa-duotone.#{$fa-css-prefix}-sensor-smoke:before { content: fa-content(\e02c\fe01); }
.fad.#{$fa-css-prefix}-sensor-smoke:after, .fa-duotone.#{$fa-css-prefix}-sensor-smoke:after { content: fa-content(\e02c\fe02); }
.fad.#{$fa-css-prefix}-sensor-fire:before, .fa-duotone.#{$fa-css-prefix}-sensor-fire:before { content: fa-content(\e02a\fe01); }
.fad.#{$fa-css-prefix}-sensor-fire:after, .fa-duotone.#{$fa-css-prefix}-sensor-fire:after { content: fa-content(\e02a\fe02); }
.fad.#{$fa-css-prefix}-sensor-on:before, .fa-duotone.#{$fa-css-prefix}-sensor-on:before { content: fa-content(\e02b\fe01); }
.fad.#{$fa-css-prefix}-sensor-on:after, .fa-duotone.#{$fa-css-prefix}-sensor-on:after { content: fa-content(\e02b\fe02); }
.fad.#{$fa-css-prefix}-sensor-triangle-exclamation:before, .fa-duotone.#{$fa-css-prefix}-sensor-triangle-exclamation:before { content: fa-content(\e029\fe01); }
.fad.#{$fa-css-prefix}-sensor-triangle-exclamation:after, .fa-duotone.#{$fa-css-prefix}-sensor-triangle-exclamation:after { content: fa-content(\e029\fe02); }
.fad.#{$fa-css-prefix}-sensor-alert:before, .fa-duotone.#{$fa-css-prefix}-sensor-alert:before { content: fa-content(\e029\fe01); }
.fad.#{$fa-css-prefix}-sensor-alert:after, .fa-duotone.#{$fa-css-prefix}-sensor-alert:after { content: fa-content(\e029\fe02); }
.fad.#{$fa-css-prefix}-server:before, .fa-duotone.#{$fa-css-prefix}-server:before { content: fa-content(\f233\fe01); }
.fad.#{$fa-css-prefix}-server:after, .fa-duotone.#{$fa-css-prefix}-server:after { content: fa-content(\f233\fe02); }
.fad.#{$fa-css-prefix}-shapes:before, .fa-duotone.#{$fa-css-prefix}-shapes:before { content: fa-content(\f61f\fe01); }
.fad.#{$fa-css-prefix}-shapes:after, .fa-duotone.#{$fa-css-prefix}-shapes:after { content: fa-content(\f61f\fe02); }
.fad.#{$fa-css-prefix}-triangle-circle-square:before, .fa-duotone.#{$fa-css-prefix}-triangle-circle-square:before { content: fa-content(\f61f\fe01); }
.fad.#{$fa-css-prefix}-triangle-circle-square:after, .fa-duotone.#{$fa-css-prefix}-triangle-circle-square:after { content: fa-content(\f61f\fe02); }
.fad.#{$fa-css-prefix}-share:before, .fa-duotone.#{$fa-css-prefix}-share:before { content: fa-content(\f064\fe01); }
.fad.#{$fa-css-prefix}-share:after, .fa-duotone.#{$fa-css-prefix}-share:after { content: fa-content(\f064\fe02); }
.fad.#{$fa-css-prefix}-arrow-turn-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-turn-right:before { content: fa-content(\f064\fe01); }
.fad.#{$fa-css-prefix}-arrow-turn-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-right:after { content: fa-content(\f064\fe02); }
.fad.#{$fa-css-prefix}-mail-forward:before, .fa-duotone.#{$fa-css-prefix}-mail-forward:before { content: fa-content(\f064\fe01); }
.fad.#{$fa-css-prefix}-mail-forward:after, .fa-duotone.#{$fa-css-prefix}-mail-forward:after { content: fa-content(\f064\fe02); }
.fad.#{$fa-css-prefix}-share-all:before, .fa-duotone.#{$fa-css-prefix}-share-all:before { content: fa-content(\f367\fe01); }
.fad.#{$fa-css-prefix}-share-all:after, .fa-duotone.#{$fa-css-prefix}-share-all:after { content: fa-content(\f367\fe02); }
.fad.#{$fa-css-prefix}-arrows-turn-right:before, .fa-duotone.#{$fa-css-prefix}-arrows-turn-right:before { content: fa-content(\f367\fe01); }
.fad.#{$fa-css-prefix}-arrows-turn-right:after, .fa-duotone.#{$fa-css-prefix}-arrows-turn-right:after { content: fa-content(\f367\fe02); }
.fad.#{$fa-css-prefix}-share-from-square:before, .fa-duotone.#{$fa-css-prefix}-share-from-square:before { content: fa-content(\f14d\fe01); }
.fad.#{$fa-css-prefix}-share-from-square:after, .fa-duotone.#{$fa-css-prefix}-share-from-square:after { content: fa-content(\f14d\fe02); }
.fad.#{$fa-css-prefix}-share-square:before, .fa-duotone.#{$fa-css-prefix}-share-square:before { content: fa-content(\f14d\fe01); }
.fad.#{$fa-css-prefix}-share-square:after, .fa-duotone.#{$fa-css-prefix}-share-square:after { content: fa-content(\f14d\fe02); }
.fad.#{$fa-css-prefix}-share-nodes:before, .fa-duotone.#{$fa-css-prefix}-share-nodes:before { content: fa-content(\f1e0\fe01); }
.fad.#{$fa-css-prefix}-share-nodes:after, .fa-duotone.#{$fa-css-prefix}-share-nodes:after { content: fa-content(\f1e0\fe02); }
.fad.#{$fa-css-prefix}-share-alt:before, .fa-duotone.#{$fa-css-prefix}-share-alt:before { content: fa-content(\f1e0\fe01); }
.fad.#{$fa-css-prefix}-share-alt:after, .fa-duotone.#{$fa-css-prefix}-share-alt:after { content: fa-content(\f1e0\fe02); }
.fad.#{$fa-css-prefix}-sheep:before, .fa-duotone.#{$fa-css-prefix}-sheep:before { content: fa-content(\f711); }
.fad.#{$fa-css-prefix}-sheep:after, .fa-duotone.#{$fa-css-prefix}-sheep:after { content: fa-content(\10f711); }
.fad.#{$fa-css-prefix}-shekel-sign:before, .fa-duotone.#{$fa-css-prefix}-shekel-sign:before { content: fa-content(\20aa\fe01); }
.fad.#{$fa-css-prefix}-shekel-sign:after, .fa-duotone.#{$fa-css-prefix}-shekel-sign:after { content: fa-content(\20aa\fe02); }
.fad.#{$fa-css-prefix}-ils:before, .fa-duotone.#{$fa-css-prefix}-ils:before { content: fa-content(\20aa\fe01); }
.fad.#{$fa-css-prefix}-ils:after, .fa-duotone.#{$fa-css-prefix}-ils:after { content: fa-content(\20aa\fe02); }
.fad.#{$fa-css-prefix}-shekel:before, .fa-duotone.#{$fa-css-prefix}-shekel:before { content: fa-content(\20aa\fe01); }
.fad.#{$fa-css-prefix}-shekel:after, .fa-duotone.#{$fa-css-prefix}-shekel:after { content: fa-content(\20aa\fe02); }
.fad.#{$fa-css-prefix}-sheqel:before, .fa-duotone.#{$fa-css-prefix}-sheqel:before { content: fa-content(\20aa\fe01); }
.fad.#{$fa-css-prefix}-sheqel:after, .fa-duotone.#{$fa-css-prefix}-sheqel:after { content: fa-content(\20aa\fe02); }
.fad.#{$fa-css-prefix}-sheqel-sign:before, .fa-duotone.#{$fa-css-prefix}-sheqel-sign:before { content: fa-content(\20aa\fe01); }
.fad.#{$fa-css-prefix}-sheqel-sign:after, .fa-duotone.#{$fa-css-prefix}-sheqel-sign:after { content: fa-content(\20aa\fe02); }
.fad.#{$fa-css-prefix}-shelves:before, .fa-duotone.#{$fa-css-prefix}-shelves:before { content: fa-content(\f480\fe01); }
.fad.#{$fa-css-prefix}-shelves:after, .fa-duotone.#{$fa-css-prefix}-shelves:after { content: fa-content(\f480\fe02); }
.fad.#{$fa-css-prefix}-inventory:before, .fa-duotone.#{$fa-css-prefix}-inventory:before { content: fa-content(\f480\fe01); }
.fad.#{$fa-css-prefix}-inventory:after, .fa-duotone.#{$fa-css-prefix}-inventory:after { content: fa-content(\f480\fe02); }
.fad.#{$fa-css-prefix}-shelves-empty:before, .fa-duotone.#{$fa-css-prefix}-shelves-empty:before { content: fa-content(\e246\fe01); }
.fad.#{$fa-css-prefix}-shelves-empty:after, .fa-duotone.#{$fa-css-prefix}-shelves-empty:after { content: fa-content(\e246\fe02); }
.fad.#{$fa-css-prefix}-shield:before, .fa-duotone.#{$fa-css-prefix}-shield:before { content: fa-content(\f132); }
.fad.#{$fa-css-prefix}-shield:after, .fa-duotone.#{$fa-css-prefix}-shield:after { content: fa-content(\10f132); }
.fad.#{$fa-css-prefix}-shield-blank:before, .fa-duotone.#{$fa-css-prefix}-shield-blank:before { content: fa-content(\f3ed\fe01); }
.fad.#{$fa-css-prefix}-shield-blank:after, .fa-duotone.#{$fa-css-prefix}-shield-blank:after { content: fa-content(\f3ed\fe02); }
.fad.#{$fa-css-prefix}-shield-alt:before, .fa-duotone.#{$fa-css-prefix}-shield-alt:before { content: fa-content(\f3ed\fe01); }
.fad.#{$fa-css-prefix}-shield-alt:after, .fa-duotone.#{$fa-css-prefix}-shield-alt:after { content: fa-content(\f3ed\fe02); }
.fad.#{$fa-css-prefix}-shield-check:before, .fa-duotone.#{$fa-css-prefix}-shield-check:before { content: fa-content(\f2f7\fe01); }
.fad.#{$fa-css-prefix}-shield-check:after, .fa-duotone.#{$fa-css-prefix}-shield-check:after { content: fa-content(\f2f7\fe02); }
.fad.#{$fa-css-prefix}-shield-cross:before, .fa-duotone.#{$fa-css-prefix}-shield-cross:before { content: fa-content(\26e8\fe01); }
.fad.#{$fa-css-prefix}-shield-cross:after, .fa-duotone.#{$fa-css-prefix}-shield-cross:after { content: fa-content(\26e8\fe02); }
.fad.#{$fa-css-prefix}-shield-exclamation:before, .fa-duotone.#{$fa-css-prefix}-shield-exclamation:before { content: fa-content(\e247\fe01); }
.fad.#{$fa-css-prefix}-shield-exclamation:after, .fa-duotone.#{$fa-css-prefix}-shield-exclamation:after { content: fa-content(\e247\fe02); }
.fad.#{$fa-css-prefix}-shield-keyhole:before, .fa-duotone.#{$fa-css-prefix}-shield-keyhole:before { content: fa-content(\e248\fe01); }
.fad.#{$fa-css-prefix}-shield-keyhole:after, .fa-duotone.#{$fa-css-prefix}-shield-keyhole:after { content: fa-content(\e248\fe02); }
.fad.#{$fa-css-prefix}-shield-minus:before, .fa-duotone.#{$fa-css-prefix}-shield-minus:before { content: fa-content(\e249\fe01); }
.fad.#{$fa-css-prefix}-shield-minus:after, .fa-duotone.#{$fa-css-prefix}-shield-minus:after { content: fa-content(\e249\fe02); }
.fad.#{$fa-css-prefix}-shield-plus:before, .fa-duotone.#{$fa-css-prefix}-shield-plus:before { content: fa-content(\e24a\fe01); }
.fad.#{$fa-css-prefix}-shield-plus:after, .fa-duotone.#{$fa-css-prefix}-shield-plus:after { content: fa-content(\e24a\fe02); }
.fad.#{$fa-css-prefix}-shield-slash:before, .fa-duotone.#{$fa-css-prefix}-shield-slash:before { content: fa-content(\e24b\fe01); }
.fad.#{$fa-css-prefix}-shield-slash:after, .fa-duotone.#{$fa-css-prefix}-shield-slash:after { content: fa-content(\e24b\fe02); }
.fad.#{$fa-css-prefix}-shield-virus:before, .fa-duotone.#{$fa-css-prefix}-shield-virus:before { content: fa-content(\e06c\fe01); }
.fad.#{$fa-css-prefix}-shield-virus:after, .fa-duotone.#{$fa-css-prefix}-shield-virus:after { content: fa-content(\e06c\fe02); }
.fad.#{$fa-css-prefix}-shield-xmark:before, .fa-duotone.#{$fa-css-prefix}-shield-xmark:before { content: fa-content(\e24c\fe01); }
.fad.#{$fa-css-prefix}-shield-xmark:after, .fa-duotone.#{$fa-css-prefix}-shield-xmark:after { content: fa-content(\e24c\fe02); }
.fad.#{$fa-css-prefix}-shield-times:before, .fa-duotone.#{$fa-css-prefix}-shield-times:before { content: fa-content(\e24c\fe01); }
.fad.#{$fa-css-prefix}-shield-times:after, .fa-duotone.#{$fa-css-prefix}-shield-times:after { content: fa-content(\e24c\fe02); }
.fad.#{$fa-css-prefix}-ship:before, .fa-duotone.#{$fa-css-prefix}-ship:before { content: fa-content(\f21a); }
.fad.#{$fa-css-prefix}-ship:after, .fa-duotone.#{$fa-css-prefix}-ship:after { content: fa-content(\10f21a); }
.fad.#{$fa-css-prefix}-shirt:before, .fa-duotone.#{$fa-css-prefix}-shirt:before { content: fa-content(\f553); }
.fad.#{$fa-css-prefix}-shirt:after, .fa-duotone.#{$fa-css-prefix}-shirt:after { content: fa-content(\10f553); }
.fad.#{$fa-css-prefix}-t-shirt:before, .fa-duotone.#{$fa-css-prefix}-t-shirt:before { content: fa-content(\f553); }
.fad.#{$fa-css-prefix}-t-shirt:after, .fa-duotone.#{$fa-css-prefix}-t-shirt:after { content: fa-content(\10f553); }
.fad.#{$fa-css-prefix}-tshirt:before, .fa-duotone.#{$fa-css-prefix}-tshirt:before { content: fa-content(\f553); }
.fad.#{$fa-css-prefix}-tshirt:after, .fa-duotone.#{$fa-css-prefix}-tshirt:after { content: fa-content(\10f553); }
.fad.#{$fa-css-prefix}-shirt-long-sleeve:before, .fa-duotone.#{$fa-css-prefix}-shirt-long-sleeve:before { content: fa-content(\e3c7\fe01); }
.fad.#{$fa-css-prefix}-shirt-long-sleeve:after, .fa-duotone.#{$fa-css-prefix}-shirt-long-sleeve:after { content: fa-content(\e3c7\fe02); }
.fad.#{$fa-css-prefix}-shirt-running:before, .fa-duotone.#{$fa-css-prefix}-shirt-running:before { content: fa-content(\e3c8\fe01); }
.fad.#{$fa-css-prefix}-shirt-running:after, .fa-duotone.#{$fa-css-prefix}-shirt-running:after { content: fa-content(\e3c8\fe02); }
.fad.#{$fa-css-prefix}-shirt-tank-top:before, .fa-duotone.#{$fa-css-prefix}-shirt-tank-top:before { content: fa-content(\e3c9\fe01); }
.fad.#{$fa-css-prefix}-shirt-tank-top:after, .fa-duotone.#{$fa-css-prefix}-shirt-tank-top:after { content: fa-content(\e3c9\fe02); }
.fad.#{$fa-css-prefix}-shish-kebab:before, .fa-duotone.#{$fa-css-prefix}-shish-kebab:before { content: fa-content(\f821\fe01); }
.fad.#{$fa-css-prefix}-shish-kebab:after, .fa-duotone.#{$fa-css-prefix}-shish-kebab:after { content: fa-content(\f821\fe02); }
.fad.#{$fa-css-prefix}-shoe-prints:before, .fa-duotone.#{$fa-css-prefix}-shoe-prints:before { content: fa-content(\f54b\fe01); }
.fad.#{$fa-css-prefix}-shoe-prints:after, .fa-duotone.#{$fa-css-prefix}-shoe-prints:after { content: fa-content(\f54b\fe02); }
.fad.#{$fa-css-prefix}-shop:before, .fa-duotone.#{$fa-css-prefix}-shop:before { content: fa-content(\f54f\fe01); }
.fad.#{$fa-css-prefix}-shop:after, .fa-duotone.#{$fa-css-prefix}-shop:after { content: fa-content(\f54f\fe02); }
.fad.#{$fa-css-prefix}-store-alt:before, .fa-duotone.#{$fa-css-prefix}-store-alt:before { content: fa-content(\f54f\fe01); }
.fad.#{$fa-css-prefix}-store-alt:after, .fa-duotone.#{$fa-css-prefix}-store-alt:after { content: fa-content(\f54f\fe02); }
.fad.#{$fa-css-prefix}-shop-slash:before, .fa-duotone.#{$fa-css-prefix}-shop-slash:before { content: fa-content(\e070\fe01); }
.fad.#{$fa-css-prefix}-shop-slash:after, .fa-duotone.#{$fa-css-prefix}-shop-slash:after { content: fa-content(\e070\fe02); }
.fad.#{$fa-css-prefix}-store-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-store-alt-slash:before { content: fa-content(\e070\fe01); }
.fad.#{$fa-css-prefix}-store-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-store-alt-slash:after { content: fa-content(\e070\fe02); }
.fad.#{$fa-css-prefix}-shovel:before, .fa-duotone.#{$fa-css-prefix}-shovel:before { content: fa-content(\f713\fe01); }
.fad.#{$fa-css-prefix}-shovel:after, .fa-duotone.#{$fa-css-prefix}-shovel:after { content: fa-content(\f713\fe02); }
.fad.#{$fa-css-prefix}-shovel-snow:before, .fa-duotone.#{$fa-css-prefix}-shovel-snow:before { content: fa-content(\f7c3\fe01); }
.fad.#{$fa-css-prefix}-shovel-snow:after, .fa-duotone.#{$fa-css-prefix}-shovel-snow:after { content: fa-content(\f7c3\fe02); }
.fad.#{$fa-css-prefix}-shower:before, .fa-duotone.#{$fa-css-prefix}-shower:before { content: fa-content(\f2cc); }
.fad.#{$fa-css-prefix}-shower:after, .fa-duotone.#{$fa-css-prefix}-shower:after { content: fa-content(\10f2cc); }
.fad.#{$fa-css-prefix}-shower-down:before, .fa-duotone.#{$fa-css-prefix}-shower-down:before { content: fa-content(\e24d\fe01); }
.fad.#{$fa-css-prefix}-shower-down:after, .fa-duotone.#{$fa-css-prefix}-shower-down:after { content: fa-content(\e24d\fe02); }
.fad.#{$fa-css-prefix}-shower-alt:before, .fa-duotone.#{$fa-css-prefix}-shower-alt:before { content: fa-content(\e24d\fe01); }
.fad.#{$fa-css-prefix}-shower-alt:after, .fa-duotone.#{$fa-css-prefix}-shower-alt:after { content: fa-content(\e24d\fe02); }
.fad.#{$fa-css-prefix}-shredder:before, .fa-duotone.#{$fa-css-prefix}-shredder:before { content: fa-content(\f68a\fe01); }
.fad.#{$fa-css-prefix}-shredder:after, .fa-duotone.#{$fa-css-prefix}-shredder:after { content: fa-content(\f68a\fe02); }
.fad.#{$fa-css-prefix}-shuffle:before, .fa-duotone.#{$fa-css-prefix}-shuffle:before { content: fa-content(\f074); }
.fad.#{$fa-css-prefix}-shuffle:after, .fa-duotone.#{$fa-css-prefix}-shuffle:after { content: fa-content(\10f074); }
.fad.#{$fa-css-prefix}-random:before, .fa-duotone.#{$fa-css-prefix}-random:before { content: fa-content(\f074); }
.fad.#{$fa-css-prefix}-random:after, .fa-duotone.#{$fa-css-prefix}-random:after { content: fa-content(\10f074); }
.fad.#{$fa-css-prefix}-shuttle-space:before, .fa-duotone.#{$fa-css-prefix}-shuttle-space:before { content: fa-content(\f197\fe01); }
.fad.#{$fa-css-prefix}-shuttle-space:after, .fa-duotone.#{$fa-css-prefix}-shuttle-space:after { content: fa-content(\f197\fe02); }
.fad.#{$fa-css-prefix}-space-shuttle:before, .fa-duotone.#{$fa-css-prefix}-space-shuttle:before { content: fa-content(\f197\fe01); }
.fad.#{$fa-css-prefix}-space-shuttle:after, .fa-duotone.#{$fa-css-prefix}-space-shuttle:after { content: fa-content(\f197\fe02); }
.fad.#{$fa-css-prefix}-shuttlecock:before, .fa-duotone.#{$fa-css-prefix}-shuttlecock:before { content: fa-content(\f45b\fe01); }
.fad.#{$fa-css-prefix}-shuttlecock:after, .fa-duotone.#{$fa-css-prefix}-shuttlecock:after { content: fa-content(\f45b\fe02); }
.fad.#{$fa-css-prefix}-sickle:before, .fa-duotone.#{$fa-css-prefix}-sickle:before { content: fa-content(\f822\fe01); }
.fad.#{$fa-css-prefix}-sickle:after, .fa-duotone.#{$fa-css-prefix}-sickle:after { content: fa-content(\f822\fe02); }
.fad.#{$fa-css-prefix}-sidebar:before, .fa-duotone.#{$fa-css-prefix}-sidebar:before { content: fa-content(\e24e\fe01); }
.fad.#{$fa-css-prefix}-sidebar:after, .fa-duotone.#{$fa-css-prefix}-sidebar:after { content: fa-content(\e24e\fe02); }
.fad.#{$fa-css-prefix}-sidebar-flip:before, .fa-duotone.#{$fa-css-prefix}-sidebar-flip:before { content: fa-content(\e24f\fe01); }
.fad.#{$fa-css-prefix}-sidebar-flip:after, .fa-duotone.#{$fa-css-prefix}-sidebar-flip:after { content: fa-content(\e24f\fe02); }
.fad.#{$fa-css-prefix}-sigma:before, .fa-duotone.#{$fa-css-prefix}-sigma:before { content: fa-content(\2211\fe01); }
.fad.#{$fa-css-prefix}-sigma:after, .fa-duotone.#{$fa-css-prefix}-sigma:after { content: fa-content(\2211\fe02); }
.fad.#{$fa-css-prefix}-sign-hanging:before, .fa-duotone.#{$fa-css-prefix}-sign-hanging:before { content: fa-content(\f4d9\fe01); }
.fad.#{$fa-css-prefix}-sign-hanging:after, .fa-duotone.#{$fa-css-prefix}-sign-hanging:after { content: fa-content(\f4d9\fe02); }
.fad.#{$fa-css-prefix}-sign:before, .fa-duotone.#{$fa-css-prefix}-sign:before { content: fa-content(\f4d9\fe01); }
.fad.#{$fa-css-prefix}-sign:after, .fa-duotone.#{$fa-css-prefix}-sign:after { content: fa-content(\f4d9\fe02); }
.fad.#{$fa-css-prefix}-signal:before, .fa-duotone.#{$fa-css-prefix}-signal:before { content: fa-content(\f012); }
.fad.#{$fa-css-prefix}-signal:after, .fa-duotone.#{$fa-css-prefix}-signal:after { content: fa-content(\10f012); }
.fad.#{$fa-css-prefix}-signal-5:before, .fa-duotone.#{$fa-css-prefix}-signal-5:before { content: fa-content(\f012); }
.fad.#{$fa-css-prefix}-signal-5:after, .fa-duotone.#{$fa-css-prefix}-signal-5:after { content: fa-content(\10f012); }
.fad.#{$fa-css-prefix}-signal-perfect:before, .fa-duotone.#{$fa-css-prefix}-signal-perfect:before { content: fa-content(\f012); }
.fad.#{$fa-css-prefix}-signal-perfect:after, .fa-duotone.#{$fa-css-prefix}-signal-perfect:after { content: fa-content(\10f012); }
.fad.#{$fa-css-prefix}-signal-bars:before, .fa-duotone.#{$fa-css-prefix}-signal-bars:before { content: fa-content(\f690\fe01); }
.fad.#{$fa-css-prefix}-signal-bars:after, .fa-duotone.#{$fa-css-prefix}-signal-bars:after { content: fa-content(\f690\fe02); }
.fad.#{$fa-css-prefix}-signal-alt:before, .fa-duotone.#{$fa-css-prefix}-signal-alt:before { content: fa-content(\f690\fe01); }
.fad.#{$fa-css-prefix}-signal-alt:after, .fa-duotone.#{$fa-css-prefix}-signal-alt:after { content: fa-content(\f690\fe02); }
.fad.#{$fa-css-prefix}-signal-alt-4:before, .fa-duotone.#{$fa-css-prefix}-signal-alt-4:before { content: fa-content(\f690\fe01); }
.fad.#{$fa-css-prefix}-signal-alt-4:after, .fa-duotone.#{$fa-css-prefix}-signal-alt-4:after { content: fa-content(\f690\fe02); }
.fad.#{$fa-css-prefix}-signal-bars-strong:before, .fa-duotone.#{$fa-css-prefix}-signal-bars-strong:before { content: fa-content(\f690\fe01); }
.fad.#{$fa-css-prefix}-signal-bars-strong:after, .fa-duotone.#{$fa-css-prefix}-signal-bars-strong:after { content: fa-content(\f690\fe02); }
.fad.#{$fa-css-prefix}-signal-bars-fair:before, .fa-duotone.#{$fa-css-prefix}-signal-bars-fair:before { content: fa-content(\f692\fe01); }
.fad.#{$fa-css-prefix}-signal-bars-fair:after, .fa-duotone.#{$fa-css-prefix}-signal-bars-fair:after { content: fa-content(\f692\fe02); }
.fad.#{$fa-css-prefix}-signal-alt-2:before, .fa-duotone.#{$fa-css-prefix}-signal-alt-2:before { content: fa-content(\f692\fe01); }
.fad.#{$fa-css-prefix}-signal-alt-2:after, .fa-duotone.#{$fa-css-prefix}-signal-alt-2:after { content: fa-content(\f692\fe02); }
.fad.#{$fa-css-prefix}-signal-bars-good:before, .fa-duotone.#{$fa-css-prefix}-signal-bars-good:before { content: fa-content(\f693\fe01); }
.fad.#{$fa-css-prefix}-signal-bars-good:after, .fa-duotone.#{$fa-css-prefix}-signal-bars-good:after { content: fa-content(\f693\fe02); }
.fad.#{$fa-css-prefix}-signal-alt-3:before, .fa-duotone.#{$fa-css-prefix}-signal-alt-3:before { content: fa-content(\f693\fe01); }
.fad.#{$fa-css-prefix}-signal-alt-3:after, .fa-duotone.#{$fa-css-prefix}-signal-alt-3:after { content: fa-content(\f693\fe02); }
.fad.#{$fa-css-prefix}-signal-bars-slash:before, .fa-duotone.#{$fa-css-prefix}-signal-bars-slash:before { content: fa-content(\f694\fe01); }
.fad.#{$fa-css-prefix}-signal-bars-slash:after, .fa-duotone.#{$fa-css-prefix}-signal-bars-slash:after { content: fa-content(\f694\fe02); }
.fad.#{$fa-css-prefix}-signal-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-signal-alt-slash:before { content: fa-content(\f694\fe01); }
.fad.#{$fa-css-prefix}-signal-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-signal-alt-slash:after { content: fa-content(\f694\fe02); }
.fad.#{$fa-css-prefix}-signal-bars-weak:before, .fa-duotone.#{$fa-css-prefix}-signal-bars-weak:before { content: fa-content(\f691\fe01); }
.fad.#{$fa-css-prefix}-signal-bars-weak:after, .fa-duotone.#{$fa-css-prefix}-signal-bars-weak:after { content: fa-content(\f691\fe02); }
.fad.#{$fa-css-prefix}-signal-alt-1:before, .fa-duotone.#{$fa-css-prefix}-signal-alt-1:before { content: fa-content(\f691\fe01); }
.fad.#{$fa-css-prefix}-signal-alt-1:after, .fa-duotone.#{$fa-css-prefix}-signal-alt-1:after { content: fa-content(\f691\fe02); }
.fad.#{$fa-css-prefix}-signal-fair:before, .fa-duotone.#{$fa-css-prefix}-signal-fair:before { content: fa-content(\f68d\fe01); }
.fad.#{$fa-css-prefix}-signal-fair:after, .fa-duotone.#{$fa-css-prefix}-signal-fair:after { content: fa-content(\f68d\fe02); }
.fad.#{$fa-css-prefix}-signal-2:before, .fa-duotone.#{$fa-css-prefix}-signal-2:before { content: fa-content(\f68d\fe01); }
.fad.#{$fa-css-prefix}-signal-2:after, .fa-duotone.#{$fa-css-prefix}-signal-2:after { content: fa-content(\f68d\fe02); }
.fad.#{$fa-css-prefix}-signal-good:before, .fa-duotone.#{$fa-css-prefix}-signal-good:before { content: fa-content(\f68e\fe01); }
.fad.#{$fa-css-prefix}-signal-good:after, .fa-duotone.#{$fa-css-prefix}-signal-good:after { content: fa-content(\f68e\fe02); }
.fad.#{$fa-css-prefix}-signal-3:before, .fa-duotone.#{$fa-css-prefix}-signal-3:before { content: fa-content(\f68e\fe01); }
.fad.#{$fa-css-prefix}-signal-3:after, .fa-duotone.#{$fa-css-prefix}-signal-3:after { content: fa-content(\f68e\fe02); }
.fad.#{$fa-css-prefix}-signal-slash:before, .fa-duotone.#{$fa-css-prefix}-signal-slash:before { content: fa-content(\f695\fe01); }
.fad.#{$fa-css-prefix}-signal-slash:after, .fa-duotone.#{$fa-css-prefix}-signal-slash:after { content: fa-content(\f695\fe02); }
.fad.#{$fa-css-prefix}-signal-stream:before, .fa-duotone.#{$fa-css-prefix}-signal-stream:before { content: fa-content(\f8dd\fe01); }
.fad.#{$fa-css-prefix}-signal-stream:after, .fa-duotone.#{$fa-css-prefix}-signal-stream:after { content: fa-content(\f8dd\fe02); }
.fad.#{$fa-css-prefix}-signal-stream-slash:before, .fa-duotone.#{$fa-css-prefix}-signal-stream-slash:before { content: fa-content(\e250\fe01); }
.fad.#{$fa-css-prefix}-signal-stream-slash:after, .fa-duotone.#{$fa-css-prefix}-signal-stream-slash:after { content: fa-content(\e250\fe02); }
.fad.#{$fa-css-prefix}-signal-strong:before, .fa-duotone.#{$fa-css-prefix}-signal-strong:before { content: fa-content(\f68f\fe01); }
.fad.#{$fa-css-prefix}-signal-strong:after, .fa-duotone.#{$fa-css-prefix}-signal-strong:after { content: fa-content(\f68f\fe02); }
.fad.#{$fa-css-prefix}-signal-4:before, .fa-duotone.#{$fa-css-prefix}-signal-4:before { content: fa-content(\f68f\fe01); }
.fad.#{$fa-css-prefix}-signal-4:after, .fa-duotone.#{$fa-css-prefix}-signal-4:after { content: fa-content(\f68f\fe02); }
.fad.#{$fa-css-prefix}-signal-weak:before, .fa-duotone.#{$fa-css-prefix}-signal-weak:before { content: fa-content(\f68c\fe01); }
.fad.#{$fa-css-prefix}-signal-weak:after, .fa-duotone.#{$fa-css-prefix}-signal-weak:after { content: fa-content(\f68c\fe02); }
.fad.#{$fa-css-prefix}-signal-1:before, .fa-duotone.#{$fa-css-prefix}-signal-1:before { content: fa-content(\f68c\fe01); }
.fad.#{$fa-css-prefix}-signal-1:after, .fa-duotone.#{$fa-css-prefix}-signal-1:after { content: fa-content(\f68c\fe02); }
.fad.#{$fa-css-prefix}-signature:before, .fa-duotone.#{$fa-css-prefix}-signature:before { content: fa-content(\f5b7\fe01); }
.fad.#{$fa-css-prefix}-signature:after, .fa-duotone.#{$fa-css-prefix}-signature:after { content: fa-content(\f5b7\fe02); }
.fad.#{$fa-css-prefix}-signature-lock:before, .fa-duotone.#{$fa-css-prefix}-signature-lock:before { content: fa-content(\e3ca\fe01); }
.fad.#{$fa-css-prefix}-signature-lock:after, .fa-duotone.#{$fa-css-prefix}-signature-lock:after { content: fa-content(\e3ca\fe02); }
.fad.#{$fa-css-prefix}-signature-slash:before, .fa-duotone.#{$fa-css-prefix}-signature-slash:before { content: fa-content(\e3cb\fe01); }
.fad.#{$fa-css-prefix}-signature-slash:after, .fa-duotone.#{$fa-css-prefix}-signature-slash:after { content: fa-content(\e3cb\fe02); }
.fad.#{$fa-css-prefix}-signs-post:before, .fa-duotone.#{$fa-css-prefix}-signs-post:before { content: fa-content(\f277\fe01); }
.fad.#{$fa-css-prefix}-signs-post:after, .fa-duotone.#{$fa-css-prefix}-signs-post:after { content: fa-content(\f277\fe02); }
.fad.#{$fa-css-prefix}-map-signs:before, .fa-duotone.#{$fa-css-prefix}-map-signs:before { content: fa-content(\f277\fe01); }
.fad.#{$fa-css-prefix}-map-signs:after, .fa-duotone.#{$fa-css-prefix}-map-signs:after { content: fa-content(\f277\fe02); }
.fad.#{$fa-css-prefix}-sim-card:before, .fa-duotone.#{$fa-css-prefix}-sim-card:before { content: fa-content(\f7c4\fe01); }
.fad.#{$fa-css-prefix}-sim-card:after, .fa-duotone.#{$fa-css-prefix}-sim-card:after { content: fa-content(\f7c4\fe02); }
.fad.#{$fa-css-prefix}-sim-cards:before, .fa-duotone.#{$fa-css-prefix}-sim-cards:before { content: fa-content(\e251\fe01); }
.fad.#{$fa-css-prefix}-sim-cards:after, .fa-duotone.#{$fa-css-prefix}-sim-cards:after { content: fa-content(\e251\fe02); }
.fad.#{$fa-css-prefix}-sink:before, .fa-duotone.#{$fa-css-prefix}-sink:before { content: fa-content(\e06d\fe01); }
.fad.#{$fa-css-prefix}-sink:after, .fa-duotone.#{$fa-css-prefix}-sink:after { content: fa-content(\e06d\fe02); }
.fad.#{$fa-css-prefix}-siren:before, .fa-duotone.#{$fa-css-prefix}-siren:before { content: fa-content(\e02d\fe01); }
.fad.#{$fa-css-prefix}-siren:after, .fa-duotone.#{$fa-css-prefix}-siren:after { content: fa-content(\e02d\fe02); }
.fad.#{$fa-css-prefix}-siren-on:before, .fa-duotone.#{$fa-css-prefix}-siren-on:before { content: fa-content(\e02e\fe01); }
.fad.#{$fa-css-prefix}-siren-on:after, .fa-duotone.#{$fa-css-prefix}-siren-on:after { content: fa-content(\e02e\fe02); }
.fad.#{$fa-css-prefix}-sitemap:before, .fa-duotone.#{$fa-css-prefix}-sitemap:before { content: fa-content(\f0e8\fe01); }
.fad.#{$fa-css-prefix}-sitemap:after, .fa-duotone.#{$fa-css-prefix}-sitemap:after { content: fa-content(\f0e8\fe02); }
.fad.#{$fa-css-prefix}-skeleton:before, .fa-duotone.#{$fa-css-prefix}-skeleton:before { content: fa-content(\f620\fe01); }
.fad.#{$fa-css-prefix}-skeleton:after, .fa-duotone.#{$fa-css-prefix}-skeleton:after { content: fa-content(\f620\fe02); }
.fad.#{$fa-css-prefix}-ski-boot:before, .fa-duotone.#{$fa-css-prefix}-ski-boot:before { content: fa-content(\e3cc\fe01); }
.fad.#{$fa-css-prefix}-ski-boot:after, .fa-duotone.#{$fa-css-prefix}-ski-boot:after { content: fa-content(\e3cc\fe02); }
.fad.#{$fa-css-prefix}-ski-boot-ski:before, .fa-duotone.#{$fa-css-prefix}-ski-boot-ski:before { content: fa-content(\e3cd\fe01); }
.fad.#{$fa-css-prefix}-ski-boot-ski:after, .fa-duotone.#{$fa-css-prefix}-ski-boot-ski:after { content: fa-content(\e3cd\fe02); }
.fad.#{$fa-css-prefix}-skull:before, .fa-duotone.#{$fa-css-prefix}-skull:before { content: fa-content(\f54c); }
.fad.#{$fa-css-prefix}-skull:after, .fa-duotone.#{$fa-css-prefix}-skull:after { content: fa-content(\10f54c); }
.fad.#{$fa-css-prefix}-skull-cow:before, .fa-duotone.#{$fa-css-prefix}-skull-cow:before { content: fa-content(\f8de\fe01); }
.fad.#{$fa-css-prefix}-skull-cow:after, .fa-duotone.#{$fa-css-prefix}-skull-cow:after { content: fa-content(\f8de\fe02); }
.fad.#{$fa-css-prefix}-skull-crossbones:before, .fa-duotone.#{$fa-css-prefix}-skull-crossbones:before { content: fa-content(\2620\fe01); }
.fad.#{$fa-css-prefix}-skull-crossbones:after, .fa-duotone.#{$fa-css-prefix}-skull-crossbones:after { content: fa-content(\2620\fe02); }
.fad.#{$fa-css-prefix}-slash:before, .fa-duotone.#{$fa-css-prefix}-slash:before { content: fa-content(\f715\fe01); }
.fad.#{$fa-css-prefix}-slash:after, .fa-duotone.#{$fa-css-prefix}-slash:after { content: fa-content(\f715\fe02); }
.fad.#{$fa-css-prefix}-slash-back:before, .fa-duotone.#{$fa-css-prefix}-slash-back:before { content: fa-content(\5c\fe01); }
.fad.#{$fa-css-prefix}-slash-back:after, .fa-duotone.#{$fa-css-prefix}-slash-back:after { content: fa-content(\5c\fe02); }
.fad.#{$fa-css-prefix}-slash-forward:before, .fa-duotone.#{$fa-css-prefix}-slash-forward:before { content: fa-content(\2f\fe01); }
.fad.#{$fa-css-prefix}-slash-forward:after, .fa-duotone.#{$fa-css-prefix}-slash-forward:after { content: fa-content(\2f\fe02); }
.fad.#{$fa-css-prefix}-sleigh:before, .fa-duotone.#{$fa-css-prefix}-sleigh:before { content: fa-content(\f7cc\fe01); }
.fad.#{$fa-css-prefix}-sleigh:after, .fa-duotone.#{$fa-css-prefix}-sleigh:after { content: fa-content(\f7cc\fe02); }
.fad.#{$fa-css-prefix}-slider:before, .fa-duotone.#{$fa-css-prefix}-slider:before { content: fa-content(\e252\fe01); }
.fad.#{$fa-css-prefix}-slider:after, .fa-duotone.#{$fa-css-prefix}-slider:after { content: fa-content(\e252\fe02); }
.fad.#{$fa-css-prefix}-sliders:before, .fa-duotone.#{$fa-css-prefix}-sliders:before { content: fa-content(\f1de\fe01); }
.fad.#{$fa-css-prefix}-sliders:after, .fa-duotone.#{$fa-css-prefix}-sliders:after { content: fa-content(\f1de\fe02); }
.fad.#{$fa-css-prefix}-sliders-h:before, .fa-duotone.#{$fa-css-prefix}-sliders-h:before { content: fa-content(\f1de\fe01); }
.fad.#{$fa-css-prefix}-sliders-h:after, .fa-duotone.#{$fa-css-prefix}-sliders-h:after { content: fa-content(\f1de\fe02); }
.fad.#{$fa-css-prefix}-sliders-simple:before, .fa-duotone.#{$fa-css-prefix}-sliders-simple:before { content: fa-content(\e253\fe01); }
.fad.#{$fa-css-prefix}-sliders-simple:after, .fa-duotone.#{$fa-css-prefix}-sliders-simple:after { content: fa-content(\e253\fe02); }
.fad.#{$fa-css-prefix}-sliders-up:before, .fa-duotone.#{$fa-css-prefix}-sliders-up:before { content: fa-content(\f3f1\fe01); }
.fad.#{$fa-css-prefix}-sliders-up:after, .fa-duotone.#{$fa-css-prefix}-sliders-up:after { content: fa-content(\f3f1\fe02); }
.fad.#{$fa-css-prefix}-sliders-v:before, .fa-duotone.#{$fa-css-prefix}-sliders-v:before { content: fa-content(\f3f1\fe01); }
.fad.#{$fa-css-prefix}-sliders-v:after, .fa-duotone.#{$fa-css-prefix}-sliders-v:after { content: fa-content(\f3f1\fe02); }
.fad.#{$fa-css-prefix}-slot-machine:before, .fa-duotone.#{$fa-css-prefix}-slot-machine:before { content: fa-content(\e3ce\fe01); }
.fad.#{$fa-css-prefix}-slot-machine:after, .fa-duotone.#{$fa-css-prefix}-slot-machine:after { content: fa-content(\e3ce\fe02); }
.fad.#{$fa-css-prefix}-smog:before, .fa-duotone.#{$fa-css-prefix}-smog:before { content: fa-content(\f75f\fe01); }
.fad.#{$fa-css-prefix}-smog:after, .fa-duotone.#{$fa-css-prefix}-smog:after { content: fa-content(\f75f\fe02); }
.fad.#{$fa-css-prefix}-smoke:before, .fa-duotone.#{$fa-css-prefix}-smoke:before { content: fa-content(\f760\fe01); }
.fad.#{$fa-css-prefix}-smoke:after, .fa-duotone.#{$fa-css-prefix}-smoke:after { content: fa-content(\f760\fe02); }
.fad.#{$fa-css-prefix}-smoking:before, .fa-duotone.#{$fa-css-prefix}-smoking:before { content: fa-content(\f48d); }
.fad.#{$fa-css-prefix}-smoking:after, .fa-duotone.#{$fa-css-prefix}-smoking:after { content: fa-content(\10f48d); }
.fad.#{$fa-css-prefix}-snake:before, .fa-duotone.#{$fa-css-prefix}-snake:before { content: fa-content(\f716); }
.fad.#{$fa-css-prefix}-snake:after, .fa-duotone.#{$fa-css-prefix}-snake:after { content: fa-content(\10f716); }
.fad.#{$fa-css-prefix}-snooze:before, .fa-duotone.#{$fa-css-prefix}-snooze:before { content: fa-content(\f880); }
.fad.#{$fa-css-prefix}-snooze:after, .fa-duotone.#{$fa-css-prefix}-snooze:after { content: fa-content(\10f880); }
.fad.#{$fa-css-prefix}-zzz:before, .fa-duotone.#{$fa-css-prefix}-zzz:before { content: fa-content(\f880); }
.fad.#{$fa-css-prefix}-zzz:after, .fa-duotone.#{$fa-css-prefix}-zzz:after { content: fa-content(\10f880); }
.fad.#{$fa-css-prefix}-snow-blowing:before, .fa-duotone.#{$fa-css-prefix}-snow-blowing:before { content: fa-content(\f761\fe01); }
.fad.#{$fa-css-prefix}-snow-blowing:after, .fa-duotone.#{$fa-css-prefix}-snow-blowing:after { content: fa-content(\f761\fe02); }
.fad.#{$fa-css-prefix}-snowflake:before, .fa-duotone.#{$fa-css-prefix}-snowflake:before { content: fa-content(\2744\fe01); }
.fad.#{$fa-css-prefix}-snowflake:after, .fa-duotone.#{$fa-css-prefix}-snowflake:after { content: fa-content(\2744\fe02); }
.fad.#{$fa-css-prefix}-snowflakes:before, .fa-duotone.#{$fa-css-prefix}-snowflakes:before { content: fa-content(\f7cf\fe01); }
.fad.#{$fa-css-prefix}-snowflakes:after, .fa-duotone.#{$fa-css-prefix}-snowflakes:after { content: fa-content(\f7cf\fe02); }
.fad.#{$fa-css-prefix}-snowman:before, .fa-duotone.#{$fa-css-prefix}-snowman:before { content: fa-content(\2603\fe01); }
.fad.#{$fa-css-prefix}-snowman:after, .fa-duotone.#{$fa-css-prefix}-snowman:after { content: fa-content(\2603\fe02); }
.fad.#{$fa-css-prefix}-snowman-head:before, .fa-duotone.#{$fa-css-prefix}-snowman-head:before { content: fa-content(\f79b\fe01); }
.fad.#{$fa-css-prefix}-snowman-head:after, .fa-duotone.#{$fa-css-prefix}-snowman-head:after { content: fa-content(\f79b\fe02); }
.fad.#{$fa-css-prefix}-frosty-head:before, .fa-duotone.#{$fa-css-prefix}-frosty-head:before { content: fa-content(\f79b\fe01); }
.fad.#{$fa-css-prefix}-frosty-head:after, .fa-duotone.#{$fa-css-prefix}-frosty-head:after { content: fa-content(\f79b\fe02); }
.fad.#{$fa-css-prefix}-snowplow:before, .fa-duotone.#{$fa-css-prefix}-snowplow:before { content: fa-content(\f7d2\fe01); }
.fad.#{$fa-css-prefix}-snowplow:after, .fa-duotone.#{$fa-css-prefix}-snowplow:after { content: fa-content(\f7d2\fe02); }
.fad.#{$fa-css-prefix}-soap:before, .fa-duotone.#{$fa-css-prefix}-soap:before { content: fa-content(\e06e); }
.fad.#{$fa-css-prefix}-soap:after, .fa-duotone.#{$fa-css-prefix}-soap:after { content: fa-content(\10e06e); }
.fad.#{$fa-css-prefix}-socks:before, .fa-duotone.#{$fa-css-prefix}-socks:before { content: fa-content(\f696); }
.fad.#{$fa-css-prefix}-socks:after, .fa-duotone.#{$fa-css-prefix}-socks:after { content: fa-content(\10f696); }
.fad.#{$fa-css-prefix}-solar-panel:before, .fa-duotone.#{$fa-css-prefix}-solar-panel:before { content: fa-content(\f5ba\fe01); }
.fad.#{$fa-css-prefix}-solar-panel:after, .fa-duotone.#{$fa-css-prefix}-solar-panel:after { content: fa-content(\f5ba\fe02); }
.fad.#{$fa-css-prefix}-solar-system:before, .fa-duotone.#{$fa-css-prefix}-solar-system:before { content: fa-content(\e02f\fe01); }
.fad.#{$fa-css-prefix}-solar-system:after, .fa-duotone.#{$fa-css-prefix}-solar-system:after { content: fa-content(\e02f\fe02); }
.fad.#{$fa-css-prefix}-sort:before, .fa-duotone.#{$fa-css-prefix}-sort:before { content: fa-content(\f0dc\fe01); }
.fad.#{$fa-css-prefix}-sort:after, .fa-duotone.#{$fa-css-prefix}-sort:after { content: fa-content(\f0dc\fe02); }
.fad.#{$fa-css-prefix}-unsorted:before, .fa-duotone.#{$fa-css-prefix}-unsorted:before { content: fa-content(\f0dc\fe01); }
.fad.#{$fa-css-prefix}-unsorted:after, .fa-duotone.#{$fa-css-prefix}-unsorted:after { content: fa-content(\f0dc\fe02); }
.fad.#{$fa-css-prefix}-sort-down:before, .fa-duotone.#{$fa-css-prefix}-sort-down:before { content: fa-content(\f0dd\fe01); }
.fad.#{$fa-css-prefix}-sort-down:after, .fa-duotone.#{$fa-css-prefix}-sort-down:after { content: fa-content(\f0dd\fe02); }
.fad.#{$fa-css-prefix}-sort-desc:before, .fa-duotone.#{$fa-css-prefix}-sort-desc:before { content: fa-content(\f0dd\fe01); }
.fad.#{$fa-css-prefix}-sort-desc:after, .fa-duotone.#{$fa-css-prefix}-sort-desc:after { content: fa-content(\f0dd\fe02); }
.fad.#{$fa-css-prefix}-sort-up:before, .fa-duotone.#{$fa-css-prefix}-sort-up:before { content: fa-content(\f0de\fe01); }
.fad.#{$fa-css-prefix}-sort-up:after, .fa-duotone.#{$fa-css-prefix}-sort-up:after { content: fa-content(\f0de\fe02); }
.fad.#{$fa-css-prefix}-sort-asc:before, .fa-duotone.#{$fa-css-prefix}-sort-asc:before { content: fa-content(\f0de\fe01); }
.fad.#{$fa-css-prefix}-sort-asc:after, .fa-duotone.#{$fa-css-prefix}-sort-asc:after { content: fa-content(\f0de\fe02); }
.fad.#{$fa-css-prefix}-spa:before, .fa-duotone.#{$fa-css-prefix}-spa:before { content: fa-content(\f5bb\fe01); }
.fad.#{$fa-css-prefix}-spa:after, .fa-duotone.#{$fa-css-prefix}-spa:after { content: fa-content(\f5bb\fe02); }
.fad.#{$fa-css-prefix}-space-station-moon:before, .fa-duotone.#{$fa-css-prefix}-space-station-moon:before { content: fa-content(\e033\fe01); }
.fad.#{$fa-css-prefix}-space-station-moon:after, .fa-duotone.#{$fa-css-prefix}-space-station-moon:after { content: fa-content(\e033\fe02); }
.fad.#{$fa-css-prefix}-space-station-moon-construction:before, .fa-duotone.#{$fa-css-prefix}-space-station-moon-construction:before { content: fa-content(\e034\fe01); }
.fad.#{$fa-css-prefix}-space-station-moon-construction:after, .fa-duotone.#{$fa-css-prefix}-space-station-moon-construction:after { content: fa-content(\e034\fe02); }
.fad.#{$fa-css-prefix}-space-station-moon-alt:before, .fa-duotone.#{$fa-css-prefix}-space-station-moon-alt:before { content: fa-content(\e034\fe01); }
.fad.#{$fa-css-prefix}-space-station-moon-alt:after, .fa-duotone.#{$fa-css-prefix}-space-station-moon-alt:after { content: fa-content(\e034\fe02); }
.fad.#{$fa-css-prefix}-spade:before, .fa-duotone.#{$fa-css-prefix}-spade:before { content: fa-content(\2660\fe01); }
.fad.#{$fa-css-prefix}-spade:after, .fa-duotone.#{$fa-css-prefix}-spade:after { content: fa-content(\2660\fe02); }
.fad.#{$fa-css-prefix}-spaghetti-monster-flying:before, .fa-duotone.#{$fa-css-prefix}-spaghetti-monster-flying:before { content: fa-content(\f67b\fe01); }
.fad.#{$fa-css-prefix}-spaghetti-monster-flying:after, .fa-duotone.#{$fa-css-prefix}-spaghetti-monster-flying:after { content: fa-content(\f67b\fe02); }
.fad.#{$fa-css-prefix}-pastafarianism:before, .fa-duotone.#{$fa-css-prefix}-pastafarianism:before { content: fa-content(\f67b\fe01); }
.fad.#{$fa-css-prefix}-pastafarianism:after, .fa-duotone.#{$fa-css-prefix}-pastafarianism:after { content: fa-content(\f67b\fe02); }
.fad.#{$fa-css-prefix}-sparkles:before, .fa-duotone.#{$fa-css-prefix}-sparkles:before { content: fa-content(\2728\fe01); }
.fad.#{$fa-css-prefix}-sparkles:after, .fa-duotone.#{$fa-css-prefix}-sparkles:after { content: fa-content(\2728\fe02); }
.fad.#{$fa-css-prefix}-speaker:before, .fa-duotone.#{$fa-css-prefix}-speaker:before { content: fa-content(\f8df\fe01); }
.fad.#{$fa-css-prefix}-speaker:after, .fa-duotone.#{$fa-css-prefix}-speaker:after { content: fa-content(\f8df\fe02); }
.fad.#{$fa-css-prefix}-speakers:before, .fa-duotone.#{$fa-css-prefix}-speakers:before { content: fa-content(\f8e0\fe01); }
.fad.#{$fa-css-prefix}-speakers:after, .fa-duotone.#{$fa-css-prefix}-speakers:after { content: fa-content(\f8e0\fe02); }
.fad.#{$fa-css-prefix}-spell-check:before, .fa-duotone.#{$fa-css-prefix}-spell-check:before { content: fa-content(\f891\fe01); }
.fad.#{$fa-css-prefix}-spell-check:after, .fa-duotone.#{$fa-css-prefix}-spell-check:after { content: fa-content(\f891\fe02); }
.fad.#{$fa-css-prefix}-spider:before, .fa-duotone.#{$fa-css-prefix}-spider:before { content: fa-content(\f717); }
.fad.#{$fa-css-prefix}-spider:after, .fa-duotone.#{$fa-css-prefix}-spider:after { content: fa-content(\10f717); }
.fad.#{$fa-css-prefix}-spider-black-widow:before, .fa-duotone.#{$fa-css-prefix}-spider-black-widow:before { content: fa-content(\f718\fe01); }
.fad.#{$fa-css-prefix}-spider-black-widow:after, .fa-duotone.#{$fa-css-prefix}-spider-black-widow:after { content: fa-content(\f718\fe02); }
.fad.#{$fa-css-prefix}-spider-web:before, .fa-duotone.#{$fa-css-prefix}-spider-web:before { content: fa-content(\f719); }
.fad.#{$fa-css-prefix}-spider-web:after, .fa-duotone.#{$fa-css-prefix}-spider-web:after { content: fa-content(\10f719); }
.fad.#{$fa-css-prefix}-spinner:before, .fa-duotone.#{$fa-css-prefix}-spinner:before { content: fa-content(\f110\fe01); }
.fad.#{$fa-css-prefix}-spinner:after, .fa-duotone.#{$fa-css-prefix}-spinner:after { content: fa-content(\f110\fe02); }
.fad.#{$fa-css-prefix}-spinner-third:before, .fa-duotone.#{$fa-css-prefix}-spinner-third:before { content: fa-content(\f3f4\fe01); }
.fad.#{$fa-css-prefix}-spinner-third:after, .fa-duotone.#{$fa-css-prefix}-spinner-third:after { content: fa-content(\f3f4\fe02); }
.fad.#{$fa-css-prefix}-split:before, .fa-duotone.#{$fa-css-prefix}-split:before { content: fa-content(\e254\fe01); }
.fad.#{$fa-css-prefix}-split:after, .fa-duotone.#{$fa-css-prefix}-split:after { content: fa-content(\e254\fe02); }
.fad.#{$fa-css-prefix}-splotch:before, .fa-duotone.#{$fa-css-prefix}-splotch:before { content: fa-content(\f5bc\fe01); }
.fad.#{$fa-css-prefix}-splotch:after, .fa-duotone.#{$fa-css-prefix}-splotch:after { content: fa-content(\f5bc\fe02); }
.fad.#{$fa-css-prefix}-spoon:before, .fa-duotone.#{$fa-css-prefix}-spoon:before { content: fa-content(\f2e5); }
.fad.#{$fa-css-prefix}-spoon:after, .fa-duotone.#{$fa-css-prefix}-spoon:after { content: fa-content(\10f2e5); }
.fad.#{$fa-css-prefix}-utensil-spoon:before, .fa-duotone.#{$fa-css-prefix}-utensil-spoon:before { content: fa-content(\f2e5); }
.fad.#{$fa-css-prefix}-utensil-spoon:after, .fa-duotone.#{$fa-css-prefix}-utensil-spoon:after { content: fa-content(\10f2e5); }
.fad.#{$fa-css-prefix}-spray-can:before, .fa-duotone.#{$fa-css-prefix}-spray-can:before { content: fa-content(\f5bd\fe01); }
.fad.#{$fa-css-prefix}-spray-can:after, .fa-duotone.#{$fa-css-prefix}-spray-can:after { content: fa-content(\f5bd\fe02); }
.fad.#{$fa-css-prefix}-spray-can-sparkles:before, .fa-duotone.#{$fa-css-prefix}-spray-can-sparkles:before { content: fa-content(\f5d0\fe01); }
.fad.#{$fa-css-prefix}-spray-can-sparkles:after, .fa-duotone.#{$fa-css-prefix}-spray-can-sparkles:after { content: fa-content(\f5d0\fe02); }
.fad.#{$fa-css-prefix}-air-freshener:before, .fa-duotone.#{$fa-css-prefix}-air-freshener:before { content: fa-content(\f5d0\fe01); }
.fad.#{$fa-css-prefix}-air-freshener:after, .fa-duotone.#{$fa-css-prefix}-air-freshener:after { content: fa-content(\f5d0\fe02); }
.fad.#{$fa-css-prefix}-sprinkler:before, .fa-duotone.#{$fa-css-prefix}-sprinkler:before { content: fa-content(\e035\fe01); }
.fad.#{$fa-css-prefix}-sprinkler:after, .fa-duotone.#{$fa-css-prefix}-sprinkler:after { content: fa-content(\e035\fe02); }
.fad.#{$fa-css-prefix}-square:before, .fa-duotone.#{$fa-css-prefix}-square:before { content: fa-content(\25a0\fe01); }
.fad.#{$fa-css-prefix}-square:after, .fa-duotone.#{$fa-css-prefix}-square:after { content: fa-content(\25a0\fe02); }
.fad.#{$fa-css-prefix}-square-0:before, .fa-duotone.#{$fa-css-prefix}-square-0:before { content: fa-content(\e255\fe01); }
.fad.#{$fa-css-prefix}-square-0:after, .fa-duotone.#{$fa-css-prefix}-square-0:after { content: fa-content(\e255\fe02); }
.fad.#{$fa-css-prefix}-square-1:before, .fa-duotone.#{$fa-css-prefix}-square-1:before { content: fa-content(\e256\fe01); }
.fad.#{$fa-css-prefix}-square-1:after, .fa-duotone.#{$fa-css-prefix}-square-1:after { content: fa-content(\e256\fe02); }
.fad.#{$fa-css-prefix}-square-2:before, .fa-duotone.#{$fa-css-prefix}-square-2:before { content: fa-content(\e257\fe01); }
.fad.#{$fa-css-prefix}-square-2:after, .fa-duotone.#{$fa-css-prefix}-square-2:after { content: fa-content(\e257\fe02); }
.fad.#{$fa-css-prefix}-square-3:before, .fa-duotone.#{$fa-css-prefix}-square-3:before { content: fa-content(\e258\fe01); }
.fad.#{$fa-css-prefix}-square-3:after, .fa-duotone.#{$fa-css-prefix}-square-3:after { content: fa-content(\e258\fe02); }
.fad.#{$fa-css-prefix}-square-4:before, .fa-duotone.#{$fa-css-prefix}-square-4:before { content: fa-content(\e259\fe01); }
.fad.#{$fa-css-prefix}-square-4:after, .fa-duotone.#{$fa-css-prefix}-square-4:after { content: fa-content(\e259\fe02); }
.fad.#{$fa-css-prefix}-square-5:before, .fa-duotone.#{$fa-css-prefix}-square-5:before { content: fa-content(\e25a\fe01); }
.fad.#{$fa-css-prefix}-square-5:after, .fa-duotone.#{$fa-css-prefix}-square-5:after { content: fa-content(\e25a\fe02); }
.fad.#{$fa-css-prefix}-square-6:before, .fa-duotone.#{$fa-css-prefix}-square-6:before { content: fa-content(\e25b\fe01); }
.fad.#{$fa-css-prefix}-square-6:after, .fa-duotone.#{$fa-css-prefix}-square-6:after { content: fa-content(\e25b\fe02); }
.fad.#{$fa-css-prefix}-square-7:before, .fa-duotone.#{$fa-css-prefix}-square-7:before { content: fa-content(\e25c\fe01); }
.fad.#{$fa-css-prefix}-square-7:after, .fa-duotone.#{$fa-css-prefix}-square-7:after { content: fa-content(\e25c\fe02); }
.fad.#{$fa-css-prefix}-square-8:before, .fa-duotone.#{$fa-css-prefix}-square-8:before { content: fa-content(\e25d\fe01); }
.fad.#{$fa-css-prefix}-square-8:after, .fa-duotone.#{$fa-css-prefix}-square-8:after { content: fa-content(\e25d\fe02); }
.fad.#{$fa-css-prefix}-square-9:before, .fa-duotone.#{$fa-css-prefix}-square-9:before { content: fa-content(\e25e\fe01); }
.fad.#{$fa-css-prefix}-square-9:after, .fa-duotone.#{$fa-css-prefix}-square-9:after { content: fa-content(\e25e\fe02); }
.fad.#{$fa-css-prefix}-square-a:before, .fa-duotone.#{$fa-css-prefix}-square-a:before { content: fa-content(\e25f\fe01); }
.fad.#{$fa-css-prefix}-square-a:after, .fa-duotone.#{$fa-css-prefix}-square-a:after { content: fa-content(\e25f\fe02); }
.fad.#{$fa-css-prefix}-square-ampersand:before, .fa-duotone.#{$fa-css-prefix}-square-ampersand:before { content: fa-content(\e260\fe01); }
.fad.#{$fa-css-prefix}-square-ampersand:after, .fa-duotone.#{$fa-css-prefix}-square-ampersand:after { content: fa-content(\e260\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-down:before { content: fa-content(\f339\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-down:after { content: fa-content(\f339\fe02); }
.fad.#{$fa-css-prefix}-arrow-square-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-square-down:before { content: fa-content(\f339\fe01); }
.fad.#{$fa-css-prefix}-arrow-square-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-square-down:after { content: fa-content(\f339\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-down-left:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-down-left:before { content: fa-content(\e261\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-down-left:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-down-left:after { content: fa-content(\e261\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-down-right:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-down-right:before { content: fa-content(\e262\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-down-right:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-down-right:after { content: fa-content(\e262\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-left:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-left:before { content: fa-content(\f33a\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-left:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-left:after { content: fa-content(\f33a\fe02); }
.fad.#{$fa-css-prefix}-arrow-square-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-square-left:before { content: fa-content(\f33a\fe01); }
.fad.#{$fa-css-prefix}-arrow-square-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-square-left:after { content: fa-content(\f33a\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-right:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-right:before { content: fa-content(\f33b\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-right:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-right:after { content: fa-content(\f33b\fe02); }
.fad.#{$fa-css-prefix}-arrow-square-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-square-right:before { content: fa-content(\f33b\fe01); }
.fad.#{$fa-css-prefix}-arrow-square-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-square-right:after { content: fa-content(\f33b\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-up:before { content: fa-content(\f33c\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-up:after { content: fa-content(\f33c\fe02); }
.fad.#{$fa-css-prefix}-arrow-square-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-square-up:before { content: fa-content(\f33c\fe01); }
.fad.#{$fa-css-prefix}-arrow-square-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-square-up:after { content: fa-content(\f33c\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-up-left:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-up-left:before { content: fa-content(\e263\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-up-left:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-up-left:after { content: fa-content(\e263\fe02); }
.fad.#{$fa-css-prefix}-square-arrow-up-right:before, .fa-duotone.#{$fa-css-prefix}-square-arrow-up-right:before { content: fa-content(\f14c\fe01); }
.fad.#{$fa-css-prefix}-square-arrow-up-right:after, .fa-duotone.#{$fa-css-prefix}-square-arrow-up-right:after { content: fa-content(\f14c\fe02); }
.fad.#{$fa-css-prefix}-external-link-square:before, .fa-duotone.#{$fa-css-prefix}-external-link-square:before { content: fa-content(\f14c\fe01); }
.fad.#{$fa-css-prefix}-external-link-square:after, .fa-duotone.#{$fa-css-prefix}-external-link-square:after { content: fa-content(\f14c\fe02); }
.fad.#{$fa-css-prefix}-square-b:before, .fa-duotone.#{$fa-css-prefix}-square-b:before { content: fa-content(\e264\fe01); }
.fad.#{$fa-css-prefix}-square-b:after, .fa-duotone.#{$fa-css-prefix}-square-b:after { content: fa-content(\e264\fe02); }
.fad.#{$fa-css-prefix}-square-bolt:before, .fa-duotone.#{$fa-css-prefix}-square-bolt:before { content: fa-content(\e265\fe01); }
.fad.#{$fa-css-prefix}-square-bolt:after, .fa-duotone.#{$fa-css-prefix}-square-bolt:after { content: fa-content(\e265\fe02); }
.fad.#{$fa-css-prefix}-square-c:before, .fa-duotone.#{$fa-css-prefix}-square-c:before { content: fa-content(\e266\fe01); }
.fad.#{$fa-css-prefix}-square-c:after, .fa-duotone.#{$fa-css-prefix}-square-c:after { content: fa-content(\e266\fe02); }
.fad.#{$fa-css-prefix}-square-caret-down:before, .fa-duotone.#{$fa-css-prefix}-square-caret-down:before { content: fa-content(\f150\fe01); }
.fad.#{$fa-css-prefix}-square-caret-down:after, .fa-duotone.#{$fa-css-prefix}-square-caret-down:after { content: fa-content(\f150\fe02); }
.fad.#{$fa-css-prefix}-caret-square-down:before, .fa-duotone.#{$fa-css-prefix}-caret-square-down:before { content: fa-content(\f150\fe01); }
.fad.#{$fa-css-prefix}-caret-square-down:after, .fa-duotone.#{$fa-css-prefix}-caret-square-down:after { content: fa-content(\f150\fe02); }
.fad.#{$fa-css-prefix}-square-caret-left:before, .fa-duotone.#{$fa-css-prefix}-square-caret-left:before { content: fa-content(\f191\fe01); }
.fad.#{$fa-css-prefix}-square-caret-left:after, .fa-duotone.#{$fa-css-prefix}-square-caret-left:after { content: fa-content(\f191\fe02); }
.fad.#{$fa-css-prefix}-caret-square-left:before, .fa-duotone.#{$fa-css-prefix}-caret-square-left:before { content: fa-content(\f191\fe01); }
.fad.#{$fa-css-prefix}-caret-square-left:after, .fa-duotone.#{$fa-css-prefix}-caret-square-left:after { content: fa-content(\f191\fe02); }
.fad.#{$fa-css-prefix}-square-caret-right:before, .fa-duotone.#{$fa-css-prefix}-square-caret-right:before { content: fa-content(\f152\fe01); }
.fad.#{$fa-css-prefix}-square-caret-right:after, .fa-duotone.#{$fa-css-prefix}-square-caret-right:after { content: fa-content(\f152\fe02); }
.fad.#{$fa-css-prefix}-caret-square-right:before, .fa-duotone.#{$fa-css-prefix}-caret-square-right:before { content: fa-content(\f152\fe01); }
.fad.#{$fa-css-prefix}-caret-square-right:after, .fa-duotone.#{$fa-css-prefix}-caret-square-right:after { content: fa-content(\f152\fe02); }
.fad.#{$fa-css-prefix}-square-caret-up:before, .fa-duotone.#{$fa-css-prefix}-square-caret-up:before { content: fa-content(\f151\fe01); }
.fad.#{$fa-css-prefix}-square-caret-up:after, .fa-duotone.#{$fa-css-prefix}-square-caret-up:after { content: fa-content(\f151\fe02); }
.fad.#{$fa-css-prefix}-caret-square-up:before, .fa-duotone.#{$fa-css-prefix}-caret-square-up:before { content: fa-content(\f151\fe01); }
.fad.#{$fa-css-prefix}-caret-square-up:after, .fa-duotone.#{$fa-css-prefix}-caret-square-up:after { content: fa-content(\f151\fe02); }
.fad.#{$fa-css-prefix}-square-check:before, .fa-duotone.#{$fa-css-prefix}-square-check:before { content: fa-content(\2611\fe01); }
.fad.#{$fa-css-prefix}-square-check:after, .fa-duotone.#{$fa-css-prefix}-square-check:after { content: fa-content(\2611\fe02); }
.fad.#{$fa-css-prefix}-check-square:before, .fa-duotone.#{$fa-css-prefix}-check-square:before { content: fa-content(\2611\fe01); }
.fad.#{$fa-css-prefix}-check-square:after, .fa-duotone.#{$fa-css-prefix}-check-square:after { content: fa-content(\2611\fe02); }
.fad.#{$fa-css-prefix}-square-chevron-down:before, .fa-duotone.#{$fa-css-prefix}-square-chevron-down:before { content: fa-content(\f329\fe01); }
.fad.#{$fa-css-prefix}-square-chevron-down:after, .fa-duotone.#{$fa-css-prefix}-square-chevron-down:after { content: fa-content(\f329\fe02); }
.fad.#{$fa-css-prefix}-chevron-square-down:before, .fa-duotone.#{$fa-css-prefix}-chevron-square-down:before { content: fa-content(\f329\fe01); }
.fad.#{$fa-css-prefix}-chevron-square-down:after, .fa-duotone.#{$fa-css-prefix}-chevron-square-down:after { content: fa-content(\f329\fe02); }
.fad.#{$fa-css-prefix}-square-chevron-left:before, .fa-duotone.#{$fa-css-prefix}-square-chevron-left:before { content: fa-content(\f32a\fe01); }
.fad.#{$fa-css-prefix}-square-chevron-left:after, .fa-duotone.#{$fa-css-prefix}-square-chevron-left:after { content: fa-content(\f32a\fe02); }
.fad.#{$fa-css-prefix}-chevron-square-left:before, .fa-duotone.#{$fa-css-prefix}-chevron-square-left:before { content: fa-content(\f32a\fe01); }
.fad.#{$fa-css-prefix}-chevron-square-left:after, .fa-duotone.#{$fa-css-prefix}-chevron-square-left:after { content: fa-content(\f32a\fe02); }
.fad.#{$fa-css-prefix}-square-chevron-right:before, .fa-duotone.#{$fa-css-prefix}-square-chevron-right:before { content: fa-content(\f32b\fe01); }
.fad.#{$fa-css-prefix}-square-chevron-right:after, .fa-duotone.#{$fa-css-prefix}-square-chevron-right:after { content: fa-content(\f32b\fe02); }
.fad.#{$fa-css-prefix}-chevron-square-right:before, .fa-duotone.#{$fa-css-prefix}-chevron-square-right:before { content: fa-content(\f32b\fe01); }
.fad.#{$fa-css-prefix}-chevron-square-right:after, .fa-duotone.#{$fa-css-prefix}-chevron-square-right:after { content: fa-content(\f32b\fe02); }
.fad.#{$fa-css-prefix}-square-chevron-up:before, .fa-duotone.#{$fa-css-prefix}-square-chevron-up:before { content: fa-content(\f32c\fe01); }
.fad.#{$fa-css-prefix}-square-chevron-up:after, .fa-duotone.#{$fa-css-prefix}-square-chevron-up:after { content: fa-content(\f32c\fe02); }
.fad.#{$fa-css-prefix}-chevron-square-up:before, .fa-duotone.#{$fa-css-prefix}-chevron-square-up:before { content: fa-content(\f32c\fe01); }
.fad.#{$fa-css-prefix}-chevron-square-up:after, .fa-duotone.#{$fa-css-prefix}-chevron-square-up:after { content: fa-content(\f32c\fe02); }
.fad.#{$fa-css-prefix}-square-code:before, .fa-duotone.#{$fa-css-prefix}-square-code:before { content: fa-content(\e267\fe01); }
.fad.#{$fa-css-prefix}-square-code:after, .fa-duotone.#{$fa-css-prefix}-square-code:after { content: fa-content(\e267\fe02); }
.fad.#{$fa-css-prefix}-square-d:before, .fa-duotone.#{$fa-css-prefix}-square-d:before { content: fa-content(\e268\fe01); }
.fad.#{$fa-css-prefix}-square-d:after, .fa-duotone.#{$fa-css-prefix}-square-d:after { content: fa-content(\e268\fe02); }
.fad.#{$fa-css-prefix}-square-dashed:before, .fa-duotone.#{$fa-css-prefix}-square-dashed:before { content: fa-content(\e269\fe01); }
.fad.#{$fa-css-prefix}-square-dashed:after, .fa-duotone.#{$fa-css-prefix}-square-dashed:after { content: fa-content(\e269\fe02); }
.fad.#{$fa-css-prefix}-square-divide:before, .fa-duotone.#{$fa-css-prefix}-square-divide:before { content: fa-content(\e26a\fe01); }
.fad.#{$fa-css-prefix}-square-divide:after, .fa-duotone.#{$fa-css-prefix}-square-divide:after { content: fa-content(\e26a\fe02); }
.fad.#{$fa-css-prefix}-square-dollar:before, .fa-duotone.#{$fa-css-prefix}-square-dollar:before { content: fa-content(\f2e9\fe01); }
.fad.#{$fa-css-prefix}-square-dollar:after, .fa-duotone.#{$fa-css-prefix}-square-dollar:after { content: fa-content(\f2e9\fe02); }
.fad.#{$fa-css-prefix}-dollar-square:before, .fa-duotone.#{$fa-css-prefix}-dollar-square:before { content: fa-content(\f2e9\fe01); }
.fad.#{$fa-css-prefix}-dollar-square:after, .fa-duotone.#{$fa-css-prefix}-dollar-square:after { content: fa-content(\f2e9\fe02); }
.fad.#{$fa-css-prefix}-usd-square:before, .fa-duotone.#{$fa-css-prefix}-usd-square:before { content: fa-content(\f2e9\fe01); }
.fad.#{$fa-css-prefix}-usd-square:after, .fa-duotone.#{$fa-css-prefix}-usd-square:after { content: fa-content(\f2e9\fe02); }
.fad.#{$fa-css-prefix}-square-down:before, .fa-duotone.#{$fa-css-prefix}-square-down:before { content: fa-content(\2b07\fe01); }
.fad.#{$fa-css-prefix}-square-down:after, .fa-duotone.#{$fa-css-prefix}-square-down:after { content: fa-content(\2b07\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-square-down:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-down:before { content: fa-content(\2b07\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-square-down:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-down:after { content: fa-content(\2b07\fe02); }
.fad.#{$fa-css-prefix}-square-down-left:before, .fa-duotone.#{$fa-css-prefix}-square-down-left:before { content: fa-content(\e26b\fe01); }
.fad.#{$fa-css-prefix}-square-down-left:after, .fa-duotone.#{$fa-css-prefix}-square-down-left:after { content: fa-content(\e26b\fe02); }
.fad.#{$fa-css-prefix}-square-down-right:before, .fa-duotone.#{$fa-css-prefix}-square-down-right:before { content: fa-content(\e26c\fe01); }
.fad.#{$fa-css-prefix}-square-down-right:after, .fa-duotone.#{$fa-css-prefix}-square-down-right:after { content: fa-content(\e26c\fe02); }
.fad.#{$fa-css-prefix}-square-e:before, .fa-duotone.#{$fa-css-prefix}-square-e:before { content: fa-content(\e26d\fe01); }
.fad.#{$fa-css-prefix}-square-e:after, .fa-duotone.#{$fa-css-prefix}-square-e:after { content: fa-content(\e26d\fe02); }
.fad.#{$fa-css-prefix}-square-ellipsis:before, .fa-duotone.#{$fa-css-prefix}-square-ellipsis:before { content: fa-content(\e26e\fe01); }
.fad.#{$fa-css-prefix}-square-ellipsis:after, .fa-duotone.#{$fa-css-prefix}-square-ellipsis:after { content: fa-content(\e26e\fe02); }
.fad.#{$fa-css-prefix}-square-ellipsis-vertical:before, .fa-duotone.#{$fa-css-prefix}-square-ellipsis-vertical:before { content: fa-content(\e26f\fe01); }
.fad.#{$fa-css-prefix}-square-ellipsis-vertical:after, .fa-duotone.#{$fa-css-prefix}-square-ellipsis-vertical:after { content: fa-content(\e26f\fe02); }
.fad.#{$fa-css-prefix}-square-envelope:before, .fa-duotone.#{$fa-css-prefix}-square-envelope:before { content: fa-content(\f199\fe01); }
.fad.#{$fa-css-prefix}-square-envelope:after, .fa-duotone.#{$fa-css-prefix}-square-envelope:after { content: fa-content(\f199\fe02); }
.fad.#{$fa-css-prefix}-envelope-square:before, .fa-duotone.#{$fa-css-prefix}-envelope-square:before { content: fa-content(\f199\fe01); }
.fad.#{$fa-css-prefix}-envelope-square:after, .fa-duotone.#{$fa-css-prefix}-envelope-square:after { content: fa-content(\f199\fe02); }
.fad.#{$fa-css-prefix}-square-exclamation:before, .fa-duotone.#{$fa-css-prefix}-square-exclamation:before { content: fa-content(\f321\fe01); }
.fad.#{$fa-css-prefix}-square-exclamation:after, .fa-duotone.#{$fa-css-prefix}-square-exclamation:after { content: fa-content(\f321\fe02); }
.fad.#{$fa-css-prefix}-exclamation-square:before, .fa-duotone.#{$fa-css-prefix}-exclamation-square:before { content: fa-content(\f321\fe01); }
.fad.#{$fa-css-prefix}-exclamation-square:after, .fa-duotone.#{$fa-css-prefix}-exclamation-square:after { content: fa-content(\f321\fe02); }
.fad.#{$fa-css-prefix}-square-f:before, .fa-duotone.#{$fa-css-prefix}-square-f:before { content: fa-content(\e270\fe01); }
.fad.#{$fa-css-prefix}-square-f:after, .fa-duotone.#{$fa-css-prefix}-square-f:after { content: fa-content(\e270\fe02); }
.fad.#{$fa-css-prefix}-square-fragile:before, .fa-duotone.#{$fa-css-prefix}-square-fragile:before { content: fa-content(\f49b\fe01); }
.fad.#{$fa-css-prefix}-square-fragile:after, .fa-duotone.#{$fa-css-prefix}-square-fragile:after { content: fa-content(\f49b\fe02); }
.fad.#{$fa-css-prefix}-box-fragile:before, .fa-duotone.#{$fa-css-prefix}-box-fragile:before { content: fa-content(\f49b\fe01); }
.fad.#{$fa-css-prefix}-box-fragile:after, .fa-duotone.#{$fa-css-prefix}-box-fragile:after { content: fa-content(\f49b\fe02); }
.fad.#{$fa-css-prefix}-square-wine-glass-crack:before, .fa-duotone.#{$fa-css-prefix}-square-wine-glass-crack:before { content: fa-content(\f49b\fe01); }
.fad.#{$fa-css-prefix}-square-wine-glass-crack:after, .fa-duotone.#{$fa-css-prefix}-square-wine-glass-crack:after { content: fa-content(\f49b\fe02); }
.fad.#{$fa-css-prefix}-square-full:before, .fa-duotone.#{$fa-css-prefix}-square-full:before { content: fa-content(\2b1b\fe01); }
.fad.#{$fa-css-prefix}-square-full:after, .fa-duotone.#{$fa-css-prefix}-square-full:after { content: fa-content(\2b1b\fe02); }
.fad.#{$fa-css-prefix}-square-g:before, .fa-duotone.#{$fa-css-prefix}-square-g:before { content: fa-content(\e271\fe01); }
.fad.#{$fa-css-prefix}-square-g:after, .fa-duotone.#{$fa-css-prefix}-square-g:after { content: fa-content(\e271\fe02); }
.fad.#{$fa-css-prefix}-square-h:before, .fa-duotone.#{$fa-css-prefix}-square-h:before { content: fa-content(\f0fd\fe01); }
.fad.#{$fa-css-prefix}-square-h:after, .fa-duotone.#{$fa-css-prefix}-square-h:after { content: fa-content(\f0fd\fe02); }
.fad.#{$fa-css-prefix}-h-square:before, .fa-duotone.#{$fa-css-prefix}-h-square:before { content: fa-content(\f0fd\fe01); }
.fad.#{$fa-css-prefix}-h-square:after, .fa-duotone.#{$fa-css-prefix}-h-square:after { content: fa-content(\f0fd\fe02); }
.fad.#{$fa-css-prefix}-square-heart:before, .fa-duotone.#{$fa-css-prefix}-square-heart:before { content: fa-content(\f4c8); }
.fad.#{$fa-css-prefix}-square-heart:after, .fa-duotone.#{$fa-css-prefix}-square-heart:after { content: fa-content(\10f4c8); }
.fad.#{$fa-css-prefix}-heart-square:before, .fa-duotone.#{$fa-css-prefix}-heart-square:before { content: fa-content(\f4c8); }
.fad.#{$fa-css-prefix}-heart-square:after, .fa-duotone.#{$fa-css-prefix}-heart-square:after { content: fa-content(\10f4c8); }
.fad.#{$fa-css-prefix}-square-i:before, .fa-duotone.#{$fa-css-prefix}-square-i:before { content: fa-content(\e272\fe01); }
.fad.#{$fa-css-prefix}-square-i:after, .fa-duotone.#{$fa-css-prefix}-square-i:after { content: fa-content(\e272\fe02); }
.fad.#{$fa-css-prefix}-square-info:before, .fa-duotone.#{$fa-css-prefix}-square-info:before { content: fa-content(\2139\fe01); }
.fad.#{$fa-css-prefix}-square-info:after, .fa-duotone.#{$fa-css-prefix}-square-info:after { content: fa-content(\2139\fe02); }
.fad.#{$fa-css-prefix}-info-square:before, .fa-duotone.#{$fa-css-prefix}-info-square:before { content: fa-content(\2139\fe01); }
.fad.#{$fa-css-prefix}-info-square:after, .fa-duotone.#{$fa-css-prefix}-info-square:after { content: fa-content(\2139\fe02); }
.fad.#{$fa-css-prefix}-square-j:before, .fa-duotone.#{$fa-css-prefix}-square-j:before { content: fa-content(\e273\fe01); }
.fad.#{$fa-css-prefix}-square-j:after, .fa-duotone.#{$fa-css-prefix}-square-j:after { content: fa-content(\e273\fe02); }
.fad.#{$fa-css-prefix}-square-k:before, .fa-duotone.#{$fa-css-prefix}-square-k:before { content: fa-content(\e274\fe01); }
.fad.#{$fa-css-prefix}-square-k:after, .fa-duotone.#{$fa-css-prefix}-square-k:after { content: fa-content(\e274\fe02); }
.fad.#{$fa-css-prefix}-square-l:before, .fa-duotone.#{$fa-css-prefix}-square-l:before { content: fa-content(\e275\fe01); }
.fad.#{$fa-css-prefix}-square-l:after, .fa-duotone.#{$fa-css-prefix}-square-l:after { content: fa-content(\e275\fe02); }
.fad.#{$fa-css-prefix}-square-left:before, .fa-duotone.#{$fa-css-prefix}-square-left:before { content: fa-content(\2b05\fe01); }
.fad.#{$fa-css-prefix}-square-left:after, .fa-duotone.#{$fa-css-prefix}-square-left:after { content: fa-content(\2b05\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-square-left:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-left:before { content: fa-content(\2b05\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-square-left:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-left:after { content: fa-content(\2b05\fe02); }
.fad.#{$fa-css-prefix}-square-m:before, .fa-duotone.#{$fa-css-prefix}-square-m:before { content: fa-content(\e276\fe01); }
.fad.#{$fa-css-prefix}-square-m:after, .fa-duotone.#{$fa-css-prefix}-square-m:after { content: fa-content(\e276\fe02); }
.fad.#{$fa-css-prefix}-square-minus:before, .fa-duotone.#{$fa-css-prefix}-square-minus:before { content: fa-content(\f146\fe01); }
.fad.#{$fa-css-prefix}-square-minus:after, .fa-duotone.#{$fa-css-prefix}-square-minus:after { content: fa-content(\f146\fe02); }
.fad.#{$fa-css-prefix}-minus-square:before, .fa-duotone.#{$fa-css-prefix}-minus-square:before { content: fa-content(\f146\fe01); }
.fad.#{$fa-css-prefix}-minus-square:after, .fa-duotone.#{$fa-css-prefix}-minus-square:after { content: fa-content(\f146\fe02); }
.fad.#{$fa-css-prefix}-square-n:before, .fa-duotone.#{$fa-css-prefix}-square-n:before { content: fa-content(\e277\fe01); }
.fad.#{$fa-css-prefix}-square-n:after, .fa-duotone.#{$fa-css-prefix}-square-n:after { content: fa-content(\e277\fe02); }
.fad.#{$fa-css-prefix}-square-o:before, .fa-duotone.#{$fa-css-prefix}-square-o:before { content: fa-content(\e278\fe01); }
.fad.#{$fa-css-prefix}-square-o:after, .fa-duotone.#{$fa-css-prefix}-square-o:after { content: fa-content(\e278\fe02); }
.fad.#{$fa-css-prefix}-square-p:before, .fa-duotone.#{$fa-css-prefix}-square-p:before { content: fa-content(\e279\fe01); }
.fad.#{$fa-css-prefix}-square-p:after, .fa-duotone.#{$fa-css-prefix}-square-p:after { content: fa-content(\e279\fe02); }
.fad.#{$fa-css-prefix}-square-parking:before, .fa-duotone.#{$fa-css-prefix}-square-parking:before { content: fa-content(\f540); }
.fad.#{$fa-css-prefix}-square-parking:after, .fa-duotone.#{$fa-css-prefix}-square-parking:after { content: fa-content(\10f540); }
.fad.#{$fa-css-prefix}-parking:before, .fa-duotone.#{$fa-css-prefix}-parking:before { content: fa-content(\f540); }
.fad.#{$fa-css-prefix}-parking:after, .fa-duotone.#{$fa-css-prefix}-parking:after { content: fa-content(\10f540); }
.fad.#{$fa-css-prefix}-square-parking-slash:before, .fa-duotone.#{$fa-css-prefix}-square-parking-slash:before { content: fa-content(\f617\fe01); }
.fad.#{$fa-css-prefix}-square-parking-slash:after, .fa-duotone.#{$fa-css-prefix}-square-parking-slash:after { content: fa-content(\f617\fe02); }
.fad.#{$fa-css-prefix}-parking-slash:before, .fa-duotone.#{$fa-css-prefix}-parking-slash:before { content: fa-content(\f617\fe01); }
.fad.#{$fa-css-prefix}-parking-slash:after, .fa-duotone.#{$fa-css-prefix}-parking-slash:after { content: fa-content(\f617\fe02); }
.fad.#{$fa-css-prefix}-square-pen:before, .fa-duotone.#{$fa-css-prefix}-square-pen:before { content: fa-content(\f14b\fe01); }
.fad.#{$fa-css-prefix}-square-pen:after, .fa-duotone.#{$fa-css-prefix}-square-pen:after { content: fa-content(\f14b\fe02); }
.fad.#{$fa-css-prefix}-pen-square:before, .fa-duotone.#{$fa-css-prefix}-pen-square:before { content: fa-content(\f14b\fe01); }
.fad.#{$fa-css-prefix}-pen-square:after, .fa-duotone.#{$fa-css-prefix}-pen-square:after { content: fa-content(\f14b\fe02); }
.fad.#{$fa-css-prefix}-pencil-square:before, .fa-duotone.#{$fa-css-prefix}-pencil-square:before { content: fa-content(\f14b\fe01); }
.fad.#{$fa-css-prefix}-pencil-square:after, .fa-duotone.#{$fa-css-prefix}-pencil-square:after { content: fa-content(\f14b\fe02); }
.fad.#{$fa-css-prefix}-square-phone:before, .fa-duotone.#{$fa-css-prefix}-square-phone:before { content: fa-content(\f098\fe01); }
.fad.#{$fa-css-prefix}-square-phone:after, .fa-duotone.#{$fa-css-prefix}-square-phone:after { content: fa-content(\f098\fe02); }
.fad.#{$fa-css-prefix}-phone-square:before, .fa-duotone.#{$fa-css-prefix}-phone-square:before { content: fa-content(\f098\fe01); }
.fad.#{$fa-css-prefix}-phone-square:after, .fa-duotone.#{$fa-css-prefix}-phone-square:after { content: fa-content(\f098\fe02); }
.fad.#{$fa-css-prefix}-square-phone-flip:before, .fa-duotone.#{$fa-css-prefix}-square-phone-flip:before { content: fa-content(\f87b\fe01); }
.fad.#{$fa-css-prefix}-square-phone-flip:after, .fa-duotone.#{$fa-css-prefix}-square-phone-flip:after { content: fa-content(\f87b\fe02); }
.fad.#{$fa-css-prefix}-phone-square-alt:before, .fa-duotone.#{$fa-css-prefix}-phone-square-alt:before { content: fa-content(\f87b\fe01); }
.fad.#{$fa-css-prefix}-phone-square-alt:after, .fa-duotone.#{$fa-css-prefix}-phone-square-alt:after { content: fa-content(\f87b\fe02); }
.fad.#{$fa-css-prefix}-square-phone-hangup:before, .fa-duotone.#{$fa-css-prefix}-square-phone-hangup:before { content: fa-content(\e27a\fe01); }
.fad.#{$fa-css-prefix}-square-phone-hangup:after, .fa-duotone.#{$fa-css-prefix}-square-phone-hangup:after { content: fa-content(\e27a\fe02); }
.fad.#{$fa-css-prefix}-phone-square-down:before, .fa-duotone.#{$fa-css-prefix}-phone-square-down:before { content: fa-content(\e27a\fe01); }
.fad.#{$fa-css-prefix}-phone-square-down:after, .fa-duotone.#{$fa-css-prefix}-phone-square-down:after { content: fa-content(\e27a\fe02); }
.fad.#{$fa-css-prefix}-square-plus:before, .fa-duotone.#{$fa-css-prefix}-square-plus:before { content: fa-content(\f0fe\fe01); }
.fad.#{$fa-css-prefix}-square-plus:after, .fa-duotone.#{$fa-css-prefix}-square-plus:after { content: fa-content(\f0fe\fe02); }
.fad.#{$fa-css-prefix}-plus-square:before, .fa-duotone.#{$fa-css-prefix}-plus-square:before { content: fa-content(\f0fe\fe01); }
.fad.#{$fa-css-prefix}-plus-square:after, .fa-duotone.#{$fa-css-prefix}-plus-square:after { content: fa-content(\f0fe\fe02); }
.fad.#{$fa-css-prefix}-square-poll-horizontal:before, .fa-duotone.#{$fa-css-prefix}-square-poll-horizontal:before { content: fa-content(\f682\fe01); }
.fad.#{$fa-css-prefix}-square-poll-horizontal:after, .fa-duotone.#{$fa-css-prefix}-square-poll-horizontal:after { content: fa-content(\f682\fe02); }
.fad.#{$fa-css-prefix}-poll-h:before, .fa-duotone.#{$fa-css-prefix}-poll-h:before { content: fa-content(\f682\fe01); }
.fad.#{$fa-css-prefix}-poll-h:after, .fa-duotone.#{$fa-css-prefix}-poll-h:after { content: fa-content(\f682\fe02); }
.fad.#{$fa-css-prefix}-square-poll-vertical:before, .fa-duotone.#{$fa-css-prefix}-square-poll-vertical:before { content: fa-content(\f681\fe01); }
.fad.#{$fa-css-prefix}-square-poll-vertical:after, .fa-duotone.#{$fa-css-prefix}-square-poll-vertical:after { content: fa-content(\f681\fe02); }
.fad.#{$fa-css-prefix}-poll:before, .fa-duotone.#{$fa-css-prefix}-poll:before { content: fa-content(\f681\fe01); }
.fad.#{$fa-css-prefix}-poll:after, .fa-duotone.#{$fa-css-prefix}-poll:after { content: fa-content(\f681\fe02); }
.fad.#{$fa-css-prefix}-square-q:before, .fa-duotone.#{$fa-css-prefix}-square-q:before { content: fa-content(\e27b\fe01); }
.fad.#{$fa-css-prefix}-square-q:after, .fa-duotone.#{$fa-css-prefix}-square-q:after { content: fa-content(\e27b\fe02); }
.fad.#{$fa-css-prefix}-square-question:before, .fa-duotone.#{$fa-css-prefix}-square-question:before { content: fa-content(\f2fd\fe01); }
.fad.#{$fa-css-prefix}-square-question:after, .fa-duotone.#{$fa-css-prefix}-square-question:after { content: fa-content(\f2fd\fe02); }
.fad.#{$fa-css-prefix}-question-square:before, .fa-duotone.#{$fa-css-prefix}-question-square:before { content: fa-content(\f2fd\fe01); }
.fad.#{$fa-css-prefix}-question-square:after, .fa-duotone.#{$fa-css-prefix}-question-square:after { content: fa-content(\f2fd\fe02); }
.fad.#{$fa-css-prefix}-square-quote:before, .fa-duotone.#{$fa-css-prefix}-square-quote:before { content: fa-content(\e329\fe01); }
.fad.#{$fa-css-prefix}-square-quote:after, .fa-duotone.#{$fa-css-prefix}-square-quote:after { content: fa-content(\e329\fe02); }
.fad.#{$fa-css-prefix}-square-r:before, .fa-duotone.#{$fa-css-prefix}-square-r:before { content: fa-content(\e27c\fe01); }
.fad.#{$fa-css-prefix}-square-r:after, .fa-duotone.#{$fa-css-prefix}-square-r:after { content: fa-content(\e27c\fe02); }
.fad.#{$fa-css-prefix}-square-right:before, .fa-duotone.#{$fa-css-prefix}-square-right:before { content: fa-content(\27a1\fe01); }
.fad.#{$fa-css-prefix}-square-right:after, .fa-duotone.#{$fa-css-prefix}-square-right:after { content: fa-content(\27a1\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-square-right:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-right:before { content: fa-content(\27a1\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-square-right:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-right:after { content: fa-content(\27a1\fe02); }
.fad.#{$fa-css-prefix}-square-root:before, .fa-duotone.#{$fa-css-prefix}-square-root:before { content: fa-content(\221a\fe01); }
.fad.#{$fa-css-prefix}-square-root:after, .fa-duotone.#{$fa-css-prefix}-square-root:after { content: fa-content(\221a\fe02); }
.fad.#{$fa-css-prefix}-square-root-variable:before, .fa-duotone.#{$fa-css-prefix}-square-root-variable:before { content: fa-content(\f698\fe01); }
.fad.#{$fa-css-prefix}-square-root-variable:after, .fa-duotone.#{$fa-css-prefix}-square-root-variable:after { content: fa-content(\f698\fe02); }
.fad.#{$fa-css-prefix}-square-root-alt:before, .fa-duotone.#{$fa-css-prefix}-square-root-alt:before { content: fa-content(\f698\fe01); }
.fad.#{$fa-css-prefix}-square-root-alt:after, .fa-duotone.#{$fa-css-prefix}-square-root-alt:after { content: fa-content(\f698\fe02); }
.fad.#{$fa-css-prefix}-square-rss:before, .fa-duotone.#{$fa-css-prefix}-square-rss:before { content: fa-content(\f143\fe01); }
.fad.#{$fa-css-prefix}-square-rss:after, .fa-duotone.#{$fa-css-prefix}-square-rss:after { content: fa-content(\f143\fe02); }
.fad.#{$fa-css-prefix}-rss-square:before, .fa-duotone.#{$fa-css-prefix}-rss-square:before { content: fa-content(\f143\fe01); }
.fad.#{$fa-css-prefix}-rss-square:after, .fa-duotone.#{$fa-css-prefix}-rss-square:after { content: fa-content(\f143\fe02); }
.fad.#{$fa-css-prefix}-square-s:before, .fa-duotone.#{$fa-css-prefix}-square-s:before { content: fa-content(\e27d\fe01); }
.fad.#{$fa-css-prefix}-square-s:after, .fa-duotone.#{$fa-css-prefix}-square-s:after { content: fa-content(\e27d\fe02); }
.fad.#{$fa-css-prefix}-square-share-nodes:before, .fa-duotone.#{$fa-css-prefix}-square-share-nodes:before { content: fa-content(\f1e1\fe01); }
.fad.#{$fa-css-prefix}-square-share-nodes:after, .fa-duotone.#{$fa-css-prefix}-square-share-nodes:after { content: fa-content(\f1e1\fe02); }
.fad.#{$fa-css-prefix}-share-alt-square:before, .fa-duotone.#{$fa-css-prefix}-share-alt-square:before { content: fa-content(\f1e1\fe01); }
.fad.#{$fa-css-prefix}-share-alt-square:after, .fa-duotone.#{$fa-css-prefix}-share-alt-square:after { content: fa-content(\f1e1\fe02); }
.fad.#{$fa-css-prefix}-square-sliders:before, .fa-duotone.#{$fa-css-prefix}-square-sliders:before { content: fa-content(\f3f0\fe01); }
.fad.#{$fa-css-prefix}-square-sliders:after, .fa-duotone.#{$fa-css-prefix}-square-sliders:after { content: fa-content(\f3f0\fe02); }
.fad.#{$fa-css-prefix}-sliders-h-square:before, .fa-duotone.#{$fa-css-prefix}-sliders-h-square:before { content: fa-content(\f3f0\fe01); }
.fad.#{$fa-css-prefix}-sliders-h-square:after, .fa-duotone.#{$fa-css-prefix}-sliders-h-square:after { content: fa-content(\f3f0\fe02); }
.fad.#{$fa-css-prefix}-square-sliders-vertical:before, .fa-duotone.#{$fa-css-prefix}-square-sliders-vertical:before { content: fa-content(\f3f2\fe01); }
.fad.#{$fa-css-prefix}-square-sliders-vertical:after, .fa-duotone.#{$fa-css-prefix}-square-sliders-vertical:after { content: fa-content(\f3f2\fe02); }
.fad.#{$fa-css-prefix}-sliders-v-square:before, .fa-duotone.#{$fa-css-prefix}-sliders-v-square:before { content: fa-content(\f3f2\fe01); }
.fad.#{$fa-css-prefix}-sliders-v-square:after, .fa-duotone.#{$fa-css-prefix}-sliders-v-square:after { content: fa-content(\f3f2\fe02); }
.fad.#{$fa-css-prefix}-square-small:before, .fa-duotone.#{$fa-css-prefix}-square-small:before { content: fa-content(\e27e\fe01); }
.fad.#{$fa-css-prefix}-square-small:after, .fa-duotone.#{$fa-css-prefix}-square-small:after { content: fa-content(\e27e\fe02); }
.fad.#{$fa-css-prefix}-square-star:before, .fa-duotone.#{$fa-css-prefix}-square-star:before { content: fa-content(\e27f\fe01); }
.fad.#{$fa-css-prefix}-square-star:after, .fa-duotone.#{$fa-css-prefix}-square-star:after { content: fa-content(\e27f\fe02); }
.fad.#{$fa-css-prefix}-square-t:before, .fa-duotone.#{$fa-css-prefix}-square-t:before { content: fa-content(\e280\fe01); }
.fad.#{$fa-css-prefix}-square-t:after, .fa-duotone.#{$fa-css-prefix}-square-t:after { content: fa-content(\e280\fe02); }
.fad.#{$fa-css-prefix}-square-terminal:before, .fa-duotone.#{$fa-css-prefix}-square-terminal:before { content: fa-content(\e32a\fe01); }
.fad.#{$fa-css-prefix}-square-terminal:after, .fa-duotone.#{$fa-css-prefix}-square-terminal:after { content: fa-content(\e32a\fe02); }
.fad.#{$fa-css-prefix}-square-this-way-up:before, .fa-duotone.#{$fa-css-prefix}-square-this-way-up:before { content: fa-content(\f49f\fe01); }
.fad.#{$fa-css-prefix}-square-this-way-up:after, .fa-duotone.#{$fa-css-prefix}-square-this-way-up:after { content: fa-content(\f49f\fe02); }
.fad.#{$fa-css-prefix}-box-up:before, .fa-duotone.#{$fa-css-prefix}-box-up:before { content: fa-content(\f49f\fe01); }
.fad.#{$fa-css-prefix}-box-up:after, .fa-duotone.#{$fa-css-prefix}-box-up:after { content: fa-content(\f49f\fe02); }
.fad.#{$fa-css-prefix}-square-u:before, .fa-duotone.#{$fa-css-prefix}-square-u:before { content: fa-content(\e281\fe01); }
.fad.#{$fa-css-prefix}-square-u:after, .fa-duotone.#{$fa-css-prefix}-square-u:after { content: fa-content(\e281\fe02); }
.fad.#{$fa-css-prefix}-square-up:before, .fa-duotone.#{$fa-css-prefix}-square-up:before { content: fa-content(\2b06\fe01); }
.fad.#{$fa-css-prefix}-square-up:after, .fa-duotone.#{$fa-css-prefix}-square-up:after { content: fa-content(\2b06\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-square-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-up:before { content: fa-content(\2b06\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-square-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-up:after { content: fa-content(\2b06\fe02); }
.fad.#{$fa-css-prefix}-square-up-left:before, .fa-duotone.#{$fa-css-prefix}-square-up-left:before { content: fa-content(\e282\fe01); }
.fad.#{$fa-css-prefix}-square-up-left:after, .fa-duotone.#{$fa-css-prefix}-square-up-left:after { content: fa-content(\e282\fe02); }
.fad.#{$fa-css-prefix}-square-up-right:before, .fa-duotone.#{$fa-css-prefix}-square-up-right:before { content: fa-content(\2197\fe01); }
.fad.#{$fa-css-prefix}-square-up-right:after, .fa-duotone.#{$fa-css-prefix}-square-up-right:after { content: fa-content(\2197\fe02); }
.fad.#{$fa-css-prefix}-external-link-square-alt:before, .fa-duotone.#{$fa-css-prefix}-external-link-square-alt:before { content: fa-content(\2197\fe01); }
.fad.#{$fa-css-prefix}-external-link-square-alt:after, .fa-duotone.#{$fa-css-prefix}-external-link-square-alt:after { content: fa-content(\2197\fe02); }
.fad.#{$fa-css-prefix}-square-user:before, .fa-duotone.#{$fa-css-prefix}-square-user:before { content: fa-content(\e283\fe01); }
.fad.#{$fa-css-prefix}-square-user:after, .fa-duotone.#{$fa-css-prefix}-square-user:after { content: fa-content(\e283\fe02); }
.fad.#{$fa-css-prefix}-square-v:before, .fa-duotone.#{$fa-css-prefix}-square-v:before { content: fa-content(\e284\fe01); }
.fad.#{$fa-css-prefix}-square-v:after, .fa-duotone.#{$fa-css-prefix}-square-v:after { content: fa-content(\e284\fe02); }
.fad.#{$fa-css-prefix}-square-w:before, .fa-duotone.#{$fa-css-prefix}-square-w:before { content: fa-content(\e285\fe01); }
.fad.#{$fa-css-prefix}-square-w:after, .fa-duotone.#{$fa-css-prefix}-square-w:after { content: fa-content(\e285\fe02); }
.fad.#{$fa-css-prefix}-square-x:before, .fa-duotone.#{$fa-css-prefix}-square-x:before { content: fa-content(\e286\fe01); }
.fad.#{$fa-css-prefix}-square-x:after, .fa-duotone.#{$fa-css-prefix}-square-x:after { content: fa-content(\e286\fe02); }
.fad.#{$fa-css-prefix}-square-xmark:before, .fa-duotone.#{$fa-css-prefix}-square-xmark:before { content: fa-content(\274e\fe01); }
.fad.#{$fa-css-prefix}-square-xmark:after, .fa-duotone.#{$fa-css-prefix}-square-xmark:after { content: fa-content(\274e\fe02); }
.fad.#{$fa-css-prefix}-times-square:before, .fa-duotone.#{$fa-css-prefix}-times-square:before { content: fa-content(\274e\fe01); }
.fad.#{$fa-css-prefix}-times-square:after, .fa-duotone.#{$fa-css-prefix}-times-square:after { content: fa-content(\274e\fe02); }
.fad.#{$fa-css-prefix}-xmark-square:before, .fa-duotone.#{$fa-css-prefix}-xmark-square:before { content: fa-content(\274e\fe01); }
.fad.#{$fa-css-prefix}-xmark-square:after, .fa-duotone.#{$fa-css-prefix}-xmark-square:after { content: fa-content(\274e\fe02); }
.fad.#{$fa-css-prefix}-square-y:before, .fa-duotone.#{$fa-css-prefix}-square-y:before { content: fa-content(\e287\fe01); }
.fad.#{$fa-css-prefix}-square-y:after, .fa-duotone.#{$fa-css-prefix}-square-y:after { content: fa-content(\e287\fe02); }
.fad.#{$fa-css-prefix}-square-z:before, .fa-duotone.#{$fa-css-prefix}-square-z:before { content: fa-content(\e288\fe01); }
.fad.#{$fa-css-prefix}-square-z:after, .fa-duotone.#{$fa-css-prefix}-square-z:after { content: fa-content(\e288\fe02); }
.fad.#{$fa-css-prefix}-squirrel:before, .fa-duotone.#{$fa-css-prefix}-squirrel:before { content: fa-content(\f71a\fe01); }
.fad.#{$fa-css-prefix}-squirrel:after, .fa-duotone.#{$fa-css-prefix}-squirrel:after { content: fa-content(\f71a\fe02); }
.fad.#{$fa-css-prefix}-staff:before, .fa-duotone.#{$fa-css-prefix}-staff:before { content: fa-content(\f71b\fe01); }
.fad.#{$fa-css-prefix}-staff:after, .fa-duotone.#{$fa-css-prefix}-staff:after { content: fa-content(\f71b\fe02); }
.fad.#{$fa-css-prefix}-stairs:before, .fa-duotone.#{$fa-css-prefix}-stairs:before { content: fa-content(\e289\fe01); }
.fad.#{$fa-css-prefix}-stairs:after, .fa-duotone.#{$fa-css-prefix}-stairs:after { content: fa-content(\e289\fe02); }
.fad.#{$fa-css-prefix}-stamp:before, .fa-duotone.#{$fa-css-prefix}-stamp:before { content: fa-content(\f5bf\fe01); }
.fad.#{$fa-css-prefix}-stamp:after, .fa-duotone.#{$fa-css-prefix}-stamp:after { content: fa-content(\f5bf\fe02); }
.fad.#{$fa-css-prefix}-standard-definition:before, .fa-duotone.#{$fa-css-prefix}-standard-definition:before { content: fa-content(\e28a\fe01); }
.fad.#{$fa-css-prefix}-standard-definition:after, .fa-duotone.#{$fa-css-prefix}-standard-definition:after { content: fa-content(\e28a\fe02); }
.fad.#{$fa-css-prefix}-rectangle-sd:before, .fa-duotone.#{$fa-css-prefix}-rectangle-sd:before { content: fa-content(\e28a\fe01); }
.fad.#{$fa-css-prefix}-rectangle-sd:after, .fa-duotone.#{$fa-css-prefix}-rectangle-sd:after { content: fa-content(\e28a\fe02); }
.fad.#{$fa-css-prefix}-star:before, .fa-duotone.#{$fa-css-prefix}-star:before { content: fa-content(\2b50\fe01); }
.fad.#{$fa-css-prefix}-star:after, .fa-duotone.#{$fa-css-prefix}-star:after { content: fa-content(\2b50\fe02); }
.fad.#{$fa-css-prefix}-star-and-crescent:before, .fa-duotone.#{$fa-css-prefix}-star-and-crescent:before { content: fa-content(\262a\fe01); }
.fad.#{$fa-css-prefix}-star-and-crescent:after, .fa-duotone.#{$fa-css-prefix}-star-and-crescent:after { content: fa-content(\262a\fe02); }
.fad.#{$fa-css-prefix}-star-christmas:before, .fa-duotone.#{$fa-css-prefix}-star-christmas:before { content: fa-content(\f7d4\fe01); }
.fad.#{$fa-css-prefix}-star-christmas:after, .fa-duotone.#{$fa-css-prefix}-star-christmas:after { content: fa-content(\f7d4\fe02); }
.fad.#{$fa-css-prefix}-star-exclamation:before, .fa-duotone.#{$fa-css-prefix}-star-exclamation:before { content: fa-content(\f2f3\fe01); }
.fad.#{$fa-css-prefix}-star-exclamation:after, .fa-duotone.#{$fa-css-prefix}-star-exclamation:after { content: fa-content(\f2f3\fe02); }
.fad.#{$fa-css-prefix}-star-half:before, .fa-duotone.#{$fa-css-prefix}-star-half:before { content: fa-content(\f089\fe01); }
.fad.#{$fa-css-prefix}-star-half:after, .fa-duotone.#{$fa-css-prefix}-star-half:after { content: fa-content(\f089\fe02); }
.fad.#{$fa-css-prefix}-star-half-stroke:before, .fa-duotone.#{$fa-css-prefix}-star-half-stroke:before { content: fa-content(\f5c0\fe01); }
.fad.#{$fa-css-prefix}-star-half-stroke:after, .fa-duotone.#{$fa-css-prefix}-star-half-stroke:after { content: fa-content(\f5c0\fe02); }
.fad.#{$fa-css-prefix}-star-half-alt:before, .fa-duotone.#{$fa-css-prefix}-star-half-alt:before { content: fa-content(\f5c0\fe01); }
.fad.#{$fa-css-prefix}-star-half-alt:after, .fa-duotone.#{$fa-css-prefix}-star-half-alt:after { content: fa-content(\f5c0\fe02); }
.fad.#{$fa-css-prefix}-star-of-david:before, .fa-duotone.#{$fa-css-prefix}-star-of-david:before { content: fa-content(\2721\fe01); }
.fad.#{$fa-css-prefix}-star-of-david:after, .fa-duotone.#{$fa-css-prefix}-star-of-david:after { content: fa-content(\2721\fe02); }
.fad.#{$fa-css-prefix}-star-of-life:before, .fa-duotone.#{$fa-css-prefix}-star-of-life:before { content: fa-content(\f621\fe01); }
.fad.#{$fa-css-prefix}-star-of-life:after, .fa-duotone.#{$fa-css-prefix}-star-of-life:after { content: fa-content(\f621\fe02); }
.fad.#{$fa-css-prefix}-star-sharp:before, .fa-duotone.#{$fa-css-prefix}-star-sharp:before { content: fa-content(\e28b\fe01); }
.fad.#{$fa-css-prefix}-star-sharp:after, .fa-duotone.#{$fa-css-prefix}-star-sharp:after { content: fa-content(\e28b\fe02); }
.fad.#{$fa-css-prefix}-star-sharp-half:before, .fa-duotone.#{$fa-css-prefix}-star-sharp-half:before { content: fa-content(\e28c\fe01); }
.fad.#{$fa-css-prefix}-star-sharp-half:after, .fa-duotone.#{$fa-css-prefix}-star-sharp-half:after { content: fa-content(\e28c\fe02); }
.fad.#{$fa-css-prefix}-star-sharp-half-stroke:before, .fa-duotone.#{$fa-css-prefix}-star-sharp-half-stroke:before { content: fa-content(\e28d\fe01); }
.fad.#{$fa-css-prefix}-star-sharp-half-stroke:after, .fa-duotone.#{$fa-css-prefix}-star-sharp-half-stroke:after { content: fa-content(\e28d\fe02); }
.fad.#{$fa-css-prefix}-star-sharp-half-alt:before, .fa-duotone.#{$fa-css-prefix}-star-sharp-half-alt:before { content: fa-content(\e28d\fe01); }
.fad.#{$fa-css-prefix}-star-sharp-half-alt:after, .fa-duotone.#{$fa-css-prefix}-star-sharp-half-alt:after { content: fa-content(\e28d\fe02); }
.fad.#{$fa-css-prefix}-star-shooting:before, .fa-duotone.#{$fa-css-prefix}-star-shooting:before { content: fa-content(\e036); }
.fad.#{$fa-css-prefix}-star-shooting:after, .fa-duotone.#{$fa-css-prefix}-star-shooting:after { content: fa-content(\10e036); }
.fad.#{$fa-css-prefix}-starfighter:before, .fa-duotone.#{$fa-css-prefix}-starfighter:before { content: fa-content(\e037\fe01); }
.fad.#{$fa-css-prefix}-starfighter:after, .fa-duotone.#{$fa-css-prefix}-starfighter:after { content: fa-content(\e037\fe02); }
.fad.#{$fa-css-prefix}-starfighter-twin-ion-engine:before, .fa-duotone.#{$fa-css-prefix}-starfighter-twin-ion-engine:before { content: fa-content(\e038\fe01); }
.fad.#{$fa-css-prefix}-starfighter-twin-ion-engine:after, .fa-duotone.#{$fa-css-prefix}-starfighter-twin-ion-engine:after { content: fa-content(\e038\fe02); }
.fad.#{$fa-css-prefix}-starfighter-alt:before, .fa-duotone.#{$fa-css-prefix}-starfighter-alt:before { content: fa-content(\e038\fe01); }
.fad.#{$fa-css-prefix}-starfighter-alt:after, .fa-duotone.#{$fa-css-prefix}-starfighter-alt:after { content: fa-content(\e038\fe02); }
.fad.#{$fa-css-prefix}-starfighter-twin-ion-engine-advanced:before, .fa-duotone.#{$fa-css-prefix}-starfighter-twin-ion-engine-advanced:before { content: fa-content(\e28e\fe01); }
.fad.#{$fa-css-prefix}-starfighter-twin-ion-engine-advanced:after, .fa-duotone.#{$fa-css-prefix}-starfighter-twin-ion-engine-advanced:after { content: fa-content(\e28e\fe02); }
.fad.#{$fa-css-prefix}-starfighter-alt-advanced:before, .fa-duotone.#{$fa-css-prefix}-starfighter-alt-advanced:before { content: fa-content(\e28e\fe01); }
.fad.#{$fa-css-prefix}-starfighter-alt-advanced:after, .fa-duotone.#{$fa-css-prefix}-starfighter-alt-advanced:after { content: fa-content(\e28e\fe02); }
.fad.#{$fa-css-prefix}-stars:before, .fa-duotone.#{$fa-css-prefix}-stars:before { content: fa-content(\f762\fe01); }
.fad.#{$fa-css-prefix}-stars:after, .fa-duotone.#{$fa-css-prefix}-stars:after { content: fa-content(\f762\fe02); }
.fad.#{$fa-css-prefix}-starship:before, .fa-duotone.#{$fa-css-prefix}-starship:before { content: fa-content(\e039\fe01); }
.fad.#{$fa-css-prefix}-starship:after, .fa-duotone.#{$fa-css-prefix}-starship:after { content: fa-content(\e039\fe02); }
.fad.#{$fa-css-prefix}-starship-freighter:before, .fa-duotone.#{$fa-css-prefix}-starship-freighter:before { content: fa-content(\e03a\fe01); }
.fad.#{$fa-css-prefix}-starship-freighter:after, .fa-duotone.#{$fa-css-prefix}-starship-freighter:after { content: fa-content(\e03a\fe02); }
.fad.#{$fa-css-prefix}-steak:before, .fa-duotone.#{$fa-css-prefix}-steak:before { content: fa-content(\f824); }
.fad.#{$fa-css-prefix}-steak:after, .fa-duotone.#{$fa-css-prefix}-steak:after { content: fa-content(\10f824); }
.fad.#{$fa-css-prefix}-steering-wheel:before, .fa-duotone.#{$fa-css-prefix}-steering-wheel:before { content: fa-content(\f622\fe01); }
.fad.#{$fa-css-prefix}-steering-wheel:after, .fa-duotone.#{$fa-css-prefix}-steering-wheel:after { content: fa-content(\f622\fe02); }
.fad.#{$fa-css-prefix}-sterling-sign:before, .fa-duotone.#{$fa-css-prefix}-sterling-sign:before { content: fa-content(\a3\fe01); }
.fad.#{$fa-css-prefix}-sterling-sign:after, .fa-duotone.#{$fa-css-prefix}-sterling-sign:after { content: fa-content(\a3\fe02); }
.fad.#{$fa-css-prefix}-gbp:before, .fa-duotone.#{$fa-css-prefix}-gbp:before { content: fa-content(\a3\fe01); }
.fad.#{$fa-css-prefix}-gbp:after, .fa-duotone.#{$fa-css-prefix}-gbp:after { content: fa-content(\a3\fe02); }
.fad.#{$fa-css-prefix}-pound-sign:before, .fa-duotone.#{$fa-css-prefix}-pound-sign:before { content: fa-content(\a3\fe01); }
.fad.#{$fa-css-prefix}-pound-sign:after, .fa-duotone.#{$fa-css-prefix}-pound-sign:after { content: fa-content(\a3\fe02); }
.fad.#{$fa-css-prefix}-stethoscope:before, .fa-duotone.#{$fa-css-prefix}-stethoscope:before { content: fa-content(\f0f1); }
.fad.#{$fa-css-prefix}-stethoscope:after, .fa-duotone.#{$fa-css-prefix}-stethoscope:after { content: fa-content(\10f0f1); }
.fad.#{$fa-css-prefix}-stocking:before, .fa-duotone.#{$fa-css-prefix}-stocking:before { content: fa-content(\f7d5\fe01); }
.fad.#{$fa-css-prefix}-stocking:after, .fa-duotone.#{$fa-css-prefix}-stocking:after { content: fa-content(\f7d5\fe02); }
.fad.#{$fa-css-prefix}-stomach:before, .fa-duotone.#{$fa-css-prefix}-stomach:before { content: fa-content(\f623\fe01); }
.fad.#{$fa-css-prefix}-stomach:after, .fa-duotone.#{$fa-css-prefix}-stomach:after { content: fa-content(\f623\fe02); }
.fad.#{$fa-css-prefix}-stop:before, .fa-duotone.#{$fa-css-prefix}-stop:before { content: fa-content(\23f9\fe01); }
.fad.#{$fa-css-prefix}-stop:after, .fa-duotone.#{$fa-css-prefix}-stop:after { content: fa-content(\23f9\fe02); }
.fad.#{$fa-css-prefix}-stopwatch:before, .fa-duotone.#{$fa-css-prefix}-stopwatch:before { content: fa-content(\23f1\fe01); }
.fad.#{$fa-css-prefix}-stopwatch:after, .fa-duotone.#{$fa-css-prefix}-stopwatch:after { content: fa-content(\23f1\fe02); }
.fad.#{$fa-css-prefix}-stopwatch-20:before, .fa-duotone.#{$fa-css-prefix}-stopwatch-20:before { content: fa-content(\e06f\fe01); }
.fad.#{$fa-css-prefix}-stopwatch-20:after, .fa-duotone.#{$fa-css-prefix}-stopwatch-20:after { content: fa-content(\e06f\fe02); }
.fad.#{$fa-css-prefix}-store:before, .fa-duotone.#{$fa-css-prefix}-store:before { content: fa-content(\f54e\fe01); }
.fad.#{$fa-css-prefix}-store:after, .fa-duotone.#{$fa-css-prefix}-store:after { content: fa-content(\f54e\fe02); }
.fad.#{$fa-css-prefix}-store-slash:before, .fa-duotone.#{$fa-css-prefix}-store-slash:before { content: fa-content(\e071\fe01); }
.fad.#{$fa-css-prefix}-store-slash:after, .fa-duotone.#{$fa-css-prefix}-store-slash:after { content: fa-content(\e071\fe02); }
.fad.#{$fa-css-prefix}-strawberry:before, .fa-duotone.#{$fa-css-prefix}-strawberry:before { content: fa-content(\e32b\fe01); }
.fad.#{$fa-css-prefix}-strawberry:after, .fa-duotone.#{$fa-css-prefix}-strawberry:after { content: fa-content(\e32b\fe02); }
.fad.#{$fa-css-prefix}-street-view:before, .fa-duotone.#{$fa-css-prefix}-street-view:before { content: fa-content(\f21d\fe01); }
.fad.#{$fa-css-prefix}-street-view:after, .fa-duotone.#{$fa-css-prefix}-street-view:after { content: fa-content(\f21d\fe02); }
.fad.#{$fa-css-prefix}-stretcher:before, .fa-duotone.#{$fa-css-prefix}-stretcher:before { content: fa-content(\f825\fe01); }
.fad.#{$fa-css-prefix}-stretcher:after, .fa-duotone.#{$fa-css-prefix}-stretcher:after { content: fa-content(\f825\fe02); }
.fad.#{$fa-css-prefix}-strikethrough:before, .fa-duotone.#{$fa-css-prefix}-strikethrough:before { content: fa-content(\f0cc\fe01); }
.fad.#{$fa-css-prefix}-strikethrough:after, .fa-duotone.#{$fa-css-prefix}-strikethrough:after { content: fa-content(\f0cc\fe02); }
.fad.#{$fa-css-prefix}-stroopwafel:before, .fa-duotone.#{$fa-css-prefix}-stroopwafel:before { content: fa-content(\f551\fe01); }
.fad.#{$fa-css-prefix}-stroopwafel:after, .fa-duotone.#{$fa-css-prefix}-stroopwafel:after { content: fa-content(\f551\fe02); }
.fad.#{$fa-css-prefix}-subscript:before, .fa-duotone.#{$fa-css-prefix}-subscript:before { content: fa-content(\f12c\fe01); }
.fad.#{$fa-css-prefix}-subscript:after, .fa-duotone.#{$fa-css-prefix}-subscript:after { content: fa-content(\f12c\fe02); }
.fad.#{$fa-css-prefix}-suitcase:before, .fa-duotone.#{$fa-css-prefix}-suitcase:before { content: fa-content(\f0f2); }
.fad.#{$fa-css-prefix}-suitcase:after, .fa-duotone.#{$fa-css-prefix}-suitcase:after { content: fa-content(\10f0f2); }
.fad.#{$fa-css-prefix}-suitcase-medical:before, .fa-duotone.#{$fa-css-prefix}-suitcase-medical:before { content: fa-content(\f0fa\fe01); }
.fad.#{$fa-css-prefix}-suitcase-medical:after, .fa-duotone.#{$fa-css-prefix}-suitcase-medical:after { content: fa-content(\f0fa\fe02); }
.fad.#{$fa-css-prefix}-medkit:before, .fa-duotone.#{$fa-css-prefix}-medkit:before { content: fa-content(\f0fa\fe01); }
.fad.#{$fa-css-prefix}-medkit:after, .fa-duotone.#{$fa-css-prefix}-medkit:after { content: fa-content(\f0fa\fe02); }
.fad.#{$fa-css-prefix}-suitcase-rolling:before, .fa-duotone.#{$fa-css-prefix}-suitcase-rolling:before { content: fa-content(\f5c1\fe01); }
.fad.#{$fa-css-prefix}-suitcase-rolling:after, .fa-duotone.#{$fa-css-prefix}-suitcase-rolling:after { content: fa-content(\f5c1\fe02); }
.fad.#{$fa-css-prefix}-sun:before, .fa-duotone.#{$fa-css-prefix}-sun:before { content: fa-content(\2600\fe01); }
.fad.#{$fa-css-prefix}-sun:after, .fa-duotone.#{$fa-css-prefix}-sun:after { content: fa-content(\2600\fe02); }
.fad.#{$fa-css-prefix}-sun-bright:before, .fa-duotone.#{$fa-css-prefix}-sun-bright:before { content: fa-content(\e28f\fe01); }
.fad.#{$fa-css-prefix}-sun-bright:after, .fa-duotone.#{$fa-css-prefix}-sun-bright:after { content: fa-content(\e28f\fe02); }
.fad.#{$fa-css-prefix}-sun-alt:before, .fa-duotone.#{$fa-css-prefix}-sun-alt:before { content: fa-content(\e28f\fe01); }
.fad.#{$fa-css-prefix}-sun-alt:after, .fa-duotone.#{$fa-css-prefix}-sun-alt:after { content: fa-content(\e28f\fe02); }
.fad.#{$fa-css-prefix}-sun-cloud:before, .fa-duotone.#{$fa-css-prefix}-sun-cloud:before { content: fa-content(\f763); }
.fad.#{$fa-css-prefix}-sun-cloud:after, .fa-duotone.#{$fa-css-prefix}-sun-cloud:after { content: fa-content(\10f763); }
.fad.#{$fa-css-prefix}-sun-dust:before, .fa-duotone.#{$fa-css-prefix}-sun-dust:before { content: fa-content(\f764\fe01); }
.fad.#{$fa-css-prefix}-sun-dust:after, .fa-duotone.#{$fa-css-prefix}-sun-dust:after { content: fa-content(\f764\fe02); }
.fad.#{$fa-css-prefix}-sun-haze:before, .fa-duotone.#{$fa-css-prefix}-sun-haze:before { content: fa-content(\f765\fe01); }
.fad.#{$fa-css-prefix}-sun-haze:after, .fa-duotone.#{$fa-css-prefix}-sun-haze:after { content: fa-content(\f765\fe02); }
.fad.#{$fa-css-prefix}-sunglasses:before, .fa-duotone.#{$fa-css-prefix}-sunglasses:before { content: fa-content(\f892); }
.fad.#{$fa-css-prefix}-sunglasses:after, .fa-duotone.#{$fa-css-prefix}-sunglasses:after { content: fa-content(\10f892); }
.fad.#{$fa-css-prefix}-sunrise:before, .fa-duotone.#{$fa-css-prefix}-sunrise:before { content: fa-content(\f766); }
.fad.#{$fa-css-prefix}-sunrise:after, .fa-duotone.#{$fa-css-prefix}-sunrise:after { content: fa-content(\10f766); }
.fad.#{$fa-css-prefix}-sunset:before, .fa-duotone.#{$fa-css-prefix}-sunset:before { content: fa-content(\f767); }
.fad.#{$fa-css-prefix}-sunset:after, .fa-duotone.#{$fa-css-prefix}-sunset:after { content: fa-content(\10f767); }
.fad.#{$fa-css-prefix}-superscript:before, .fa-duotone.#{$fa-css-prefix}-superscript:before { content: fa-content(\f12b\fe01); }
.fad.#{$fa-css-prefix}-superscript:after, .fa-duotone.#{$fa-css-prefix}-superscript:after { content: fa-content(\f12b\fe02); }
.fad.#{$fa-css-prefix}-swatchbook:before, .fa-duotone.#{$fa-css-prefix}-swatchbook:before { content: fa-content(\f5c3\fe01); }
.fad.#{$fa-css-prefix}-swatchbook:after, .fa-duotone.#{$fa-css-prefix}-swatchbook:after { content: fa-content(\f5c3\fe02); }
.fad.#{$fa-css-prefix}-sword:before, .fa-duotone.#{$fa-css-prefix}-sword:before { content: fa-content(\f71c); }
.fad.#{$fa-css-prefix}-sword:after, .fa-duotone.#{$fa-css-prefix}-sword:after { content: fa-content(\10f71c); }
.fad.#{$fa-css-prefix}-sword-laser:before, .fa-duotone.#{$fa-css-prefix}-sword-laser:before { content: fa-content(\e03b\fe01); }
.fad.#{$fa-css-prefix}-sword-laser:after, .fa-duotone.#{$fa-css-prefix}-sword-laser:after { content: fa-content(\e03b\fe02); }
.fad.#{$fa-css-prefix}-sword-laser-alt:before, .fa-duotone.#{$fa-css-prefix}-sword-laser-alt:before { content: fa-content(\e03c\fe01); }
.fad.#{$fa-css-prefix}-sword-laser-alt:after, .fa-duotone.#{$fa-css-prefix}-sword-laser-alt:after { content: fa-content(\e03c\fe02); }
.fad.#{$fa-css-prefix}-swords:before, .fa-duotone.#{$fa-css-prefix}-swords:before { content: fa-content(\2694\fe01); }
.fad.#{$fa-css-prefix}-swords:after, .fa-duotone.#{$fa-css-prefix}-swords:after { content: fa-content(\2694\fe02); }
.fad.#{$fa-css-prefix}-swords-laser:before, .fa-duotone.#{$fa-css-prefix}-swords-laser:before { content: fa-content(\e03d\fe01); }
.fad.#{$fa-css-prefix}-swords-laser:after, .fa-duotone.#{$fa-css-prefix}-swords-laser:after { content: fa-content(\e03d\fe02); }
.fad.#{$fa-css-prefix}-symbols:before, .fa-duotone.#{$fa-css-prefix}-symbols:before { content: fa-content(\f86e); }
.fad.#{$fa-css-prefix}-symbols:after, .fa-duotone.#{$fa-css-prefix}-symbols:after { content: fa-content(\10f86e); }
.fad.#{$fa-css-prefix}-icons-alt:before, .fa-duotone.#{$fa-css-prefix}-icons-alt:before { content: fa-content(\f86e); }
.fad.#{$fa-css-prefix}-icons-alt:after, .fa-duotone.#{$fa-css-prefix}-icons-alt:after { content: fa-content(\10f86e); }
.fad.#{$fa-css-prefix}-synagogue:before, .fa-duotone.#{$fa-css-prefix}-synagogue:before { content: fa-content(\f69b); }
.fad.#{$fa-css-prefix}-synagogue:after, .fa-duotone.#{$fa-css-prefix}-synagogue:after { content: fa-content(\10f69b); }
.fad.#{$fa-css-prefix}-syringe:before, .fa-duotone.#{$fa-css-prefix}-syringe:before { content: fa-content(\f48e); }
.fad.#{$fa-css-prefix}-syringe:after, .fa-duotone.#{$fa-css-prefix}-syringe:after { content: fa-content(\10f48e); }
.fad.#{$fa-css-prefix}-t:before, .fa-duotone.#{$fa-css-prefix}-t:before { content: fa-content(\54\fe01); }
.fad.#{$fa-css-prefix}-t:after, .fa-duotone.#{$fa-css-prefix}-t:after { content: fa-content(\54\fe02); }
.fad.#{$fa-css-prefix}-table:before, .fa-duotone.#{$fa-css-prefix}-table:before { content: fa-content(\f0ce\fe01); }
.fad.#{$fa-css-prefix}-table:after, .fa-duotone.#{$fa-css-prefix}-table:after { content: fa-content(\f0ce\fe02); }
.fad.#{$fa-css-prefix}-table-cells:before, .fa-duotone.#{$fa-css-prefix}-table-cells:before { content: fa-content(\f00a\fe01); }
.fad.#{$fa-css-prefix}-table-cells:after, .fa-duotone.#{$fa-css-prefix}-table-cells:after { content: fa-content(\f00a\fe02); }
.fad.#{$fa-css-prefix}-th:before, .fa-duotone.#{$fa-css-prefix}-th:before { content: fa-content(\f00a\fe01); }
.fad.#{$fa-css-prefix}-th:after, .fa-duotone.#{$fa-css-prefix}-th:after { content: fa-content(\f00a\fe02); }
.fad.#{$fa-css-prefix}-table-cells-large:before, .fa-duotone.#{$fa-css-prefix}-table-cells-large:before { content: fa-content(\f009\fe01); }
.fad.#{$fa-css-prefix}-table-cells-large:after, .fa-duotone.#{$fa-css-prefix}-table-cells-large:after { content: fa-content(\f009\fe02); }
.fad.#{$fa-css-prefix}-th-large:before, .fa-duotone.#{$fa-css-prefix}-th-large:before { content: fa-content(\f009\fe01); }
.fad.#{$fa-css-prefix}-th-large:after, .fa-duotone.#{$fa-css-prefix}-th-large:after { content: fa-content(\f009\fe02); }
.fad.#{$fa-css-prefix}-table-columns:before, .fa-duotone.#{$fa-css-prefix}-table-columns:before { content: fa-content(\f0db\fe01); }
.fad.#{$fa-css-prefix}-table-columns:after, .fa-duotone.#{$fa-css-prefix}-table-columns:after { content: fa-content(\f0db\fe02); }
.fad.#{$fa-css-prefix}-columns:before, .fa-duotone.#{$fa-css-prefix}-columns:before { content: fa-content(\f0db\fe01); }
.fad.#{$fa-css-prefix}-columns:after, .fa-duotone.#{$fa-css-prefix}-columns:after { content: fa-content(\f0db\fe02); }
.fad.#{$fa-css-prefix}-table-layout:before, .fa-duotone.#{$fa-css-prefix}-table-layout:before { content: fa-content(\e290\fe01); }
.fad.#{$fa-css-prefix}-table-layout:after, .fa-duotone.#{$fa-css-prefix}-table-layout:after { content: fa-content(\e290\fe02); }
.fad.#{$fa-css-prefix}-table-list:before, .fa-duotone.#{$fa-css-prefix}-table-list:before { content: fa-content(\f00b\fe01); }
.fad.#{$fa-css-prefix}-table-list:after, .fa-duotone.#{$fa-css-prefix}-table-list:after { content: fa-content(\f00b\fe02); }
.fad.#{$fa-css-prefix}-th-list:before, .fa-duotone.#{$fa-css-prefix}-th-list:before { content: fa-content(\f00b\fe01); }
.fad.#{$fa-css-prefix}-th-list:after, .fa-duotone.#{$fa-css-prefix}-th-list:after { content: fa-content(\f00b\fe02); }
.fad.#{$fa-css-prefix}-table-picnic:before, .fa-duotone.#{$fa-css-prefix}-table-picnic:before { content: fa-content(\e32d\fe01); }
.fad.#{$fa-css-prefix}-table-picnic:after, .fa-duotone.#{$fa-css-prefix}-table-picnic:after { content: fa-content(\e32d\fe02); }
.fad.#{$fa-css-prefix}-table-pivot:before, .fa-duotone.#{$fa-css-prefix}-table-pivot:before { content: fa-content(\e291\fe01); }
.fad.#{$fa-css-prefix}-table-pivot:after, .fa-duotone.#{$fa-css-prefix}-table-pivot:after { content: fa-content(\e291\fe02); }
.fad.#{$fa-css-prefix}-table-rows:before, .fa-duotone.#{$fa-css-prefix}-table-rows:before { content: fa-content(\e292\fe01); }
.fad.#{$fa-css-prefix}-table-rows:after, .fa-duotone.#{$fa-css-prefix}-table-rows:after { content: fa-content(\e292\fe02); }
.fad.#{$fa-css-prefix}-rows:before, .fa-duotone.#{$fa-css-prefix}-rows:before { content: fa-content(\e292\fe01); }
.fad.#{$fa-css-prefix}-rows:after, .fa-duotone.#{$fa-css-prefix}-rows:after { content: fa-content(\e292\fe02); }
.fad.#{$fa-css-prefix}-table-tennis-paddle-ball:before, .fa-duotone.#{$fa-css-prefix}-table-tennis-paddle-ball:before { content: fa-content(\f45d); }
.fad.#{$fa-css-prefix}-table-tennis-paddle-ball:after, .fa-duotone.#{$fa-css-prefix}-table-tennis-paddle-ball:after { content: fa-content(\10f45d); }
.fad.#{$fa-css-prefix}-ping-pong-paddle-ball:before, .fa-duotone.#{$fa-css-prefix}-ping-pong-paddle-ball:before { content: fa-content(\f45d); }
.fad.#{$fa-css-prefix}-ping-pong-paddle-ball:after, .fa-duotone.#{$fa-css-prefix}-ping-pong-paddle-ball:after { content: fa-content(\10f45d); }
.fad.#{$fa-css-prefix}-table-tennis:before, .fa-duotone.#{$fa-css-prefix}-table-tennis:before { content: fa-content(\f45d); }
.fad.#{$fa-css-prefix}-table-tennis:after, .fa-duotone.#{$fa-css-prefix}-table-tennis:after { content: fa-content(\10f45d); }
.fad.#{$fa-css-prefix}-table-tree:before, .fa-duotone.#{$fa-css-prefix}-table-tree:before { content: fa-content(\e293\fe01); }
.fad.#{$fa-css-prefix}-table-tree:after, .fa-duotone.#{$fa-css-prefix}-table-tree:after { content: fa-content(\e293\fe02); }
.fad.#{$fa-css-prefix}-tablet:before, .fa-duotone.#{$fa-css-prefix}-tablet:before { content: fa-content(\f3fb\fe01); }
.fad.#{$fa-css-prefix}-tablet:after, .fa-duotone.#{$fa-css-prefix}-tablet:after { content: fa-content(\f3fb\fe02); }
.fad.#{$fa-css-prefix}-tablet-android:before, .fa-duotone.#{$fa-css-prefix}-tablet-android:before { content: fa-content(\f3fb\fe01); }
.fad.#{$fa-css-prefix}-tablet-android:after, .fa-duotone.#{$fa-css-prefix}-tablet-android:after { content: fa-content(\f3fb\fe02); }
.fad.#{$fa-css-prefix}-tablet-button:before, .fa-duotone.#{$fa-css-prefix}-tablet-button:before { content: fa-content(\f10a\fe01); }
.fad.#{$fa-css-prefix}-tablet-button:after, .fa-duotone.#{$fa-css-prefix}-tablet-button:after { content: fa-content(\f10a\fe02); }
.fad.#{$fa-css-prefix}-tablet-rugged:before, .fa-duotone.#{$fa-css-prefix}-tablet-rugged:before { content: fa-content(\f48f\fe01); }
.fad.#{$fa-css-prefix}-tablet-rugged:after, .fa-duotone.#{$fa-css-prefix}-tablet-rugged:after { content: fa-content(\f48f\fe02); }
.fad.#{$fa-css-prefix}-tablet-screen:before, .fa-duotone.#{$fa-css-prefix}-tablet-screen:before { content: fa-content(\f3fc\fe01); }
.fad.#{$fa-css-prefix}-tablet-screen:after, .fa-duotone.#{$fa-css-prefix}-tablet-screen:after { content: fa-content(\f3fc\fe02); }
.fad.#{$fa-css-prefix}-tablet-android-alt:before, .fa-duotone.#{$fa-css-prefix}-tablet-android-alt:before { content: fa-content(\f3fc\fe01); }
.fad.#{$fa-css-prefix}-tablet-android-alt:after, .fa-duotone.#{$fa-css-prefix}-tablet-android-alt:after { content: fa-content(\f3fc\fe02); }
.fad.#{$fa-css-prefix}-tablet-screen-button:before, .fa-duotone.#{$fa-css-prefix}-tablet-screen-button:before { content: fa-content(\f3fa\fe01); }
.fad.#{$fa-css-prefix}-tablet-screen-button:after, .fa-duotone.#{$fa-css-prefix}-tablet-screen-button:after { content: fa-content(\f3fa\fe02); }
.fad.#{$fa-css-prefix}-tablet-alt:before, .fa-duotone.#{$fa-css-prefix}-tablet-alt:before { content: fa-content(\f3fa\fe01); }
.fad.#{$fa-css-prefix}-tablet-alt:after, .fa-duotone.#{$fa-css-prefix}-tablet-alt:after { content: fa-content(\f3fa\fe02); }
.fad.#{$fa-css-prefix}-tablets:before, .fa-duotone.#{$fa-css-prefix}-tablets:before { content: fa-content(\f490\fe01); }
.fad.#{$fa-css-prefix}-tablets:after, .fa-duotone.#{$fa-css-prefix}-tablets:after { content: fa-content(\f490\fe02); }
.fad.#{$fa-css-prefix}-tachograph-digital:before, .fa-duotone.#{$fa-css-prefix}-tachograph-digital:before { content: fa-content(\f566\fe01); }
.fad.#{$fa-css-prefix}-tachograph-digital:after, .fa-duotone.#{$fa-css-prefix}-tachograph-digital:after { content: fa-content(\f566\fe02); }
.fad.#{$fa-css-prefix}-digital-tachograph:before, .fa-duotone.#{$fa-css-prefix}-digital-tachograph:before { content: fa-content(\f566\fe01); }
.fad.#{$fa-css-prefix}-digital-tachograph:after, .fa-duotone.#{$fa-css-prefix}-digital-tachograph:after { content: fa-content(\f566\fe02); }
.fad.#{$fa-css-prefix}-taco:before, .fa-duotone.#{$fa-css-prefix}-taco:before { content: fa-content(\f826); }
.fad.#{$fa-css-prefix}-taco:after, .fa-duotone.#{$fa-css-prefix}-taco:after { content: fa-content(\10f826); }
.fad.#{$fa-css-prefix}-tag:before, .fa-duotone.#{$fa-css-prefix}-tag:before { content: fa-content(\f02b); }
.fad.#{$fa-css-prefix}-tag:after, .fa-duotone.#{$fa-css-prefix}-tag:after { content: fa-content(\10f02b); }
.fad.#{$fa-css-prefix}-tags:before, .fa-duotone.#{$fa-css-prefix}-tags:before { content: fa-content(\f02c\fe01); }
.fad.#{$fa-css-prefix}-tags:after, .fa-duotone.#{$fa-css-prefix}-tags:after { content: fa-content(\f02c\fe02); }
.fad.#{$fa-css-prefix}-tally:before, .fa-duotone.#{$fa-css-prefix}-tally:before { content: fa-content(\f69c\fe01); }
.fad.#{$fa-css-prefix}-tally:after, .fa-duotone.#{$fa-css-prefix}-tally:after { content: fa-content(\f69c\fe02); }
.fad.#{$fa-css-prefix}-tally-5:before, .fa-duotone.#{$fa-css-prefix}-tally-5:before { content: fa-content(\f69c\fe01); }
.fad.#{$fa-css-prefix}-tally-5:after, .fa-duotone.#{$fa-css-prefix}-tally-5:after { content: fa-content(\f69c\fe02); }
.fad.#{$fa-css-prefix}-tally-1:before, .fa-duotone.#{$fa-css-prefix}-tally-1:before { content: fa-content(\e294\fe01); }
.fad.#{$fa-css-prefix}-tally-1:after, .fa-duotone.#{$fa-css-prefix}-tally-1:after { content: fa-content(\e294\fe02); }
.fad.#{$fa-css-prefix}-tally-2:before, .fa-duotone.#{$fa-css-prefix}-tally-2:before { content: fa-content(\e295\fe01); }
.fad.#{$fa-css-prefix}-tally-2:after, .fa-duotone.#{$fa-css-prefix}-tally-2:after { content: fa-content(\e295\fe02); }
.fad.#{$fa-css-prefix}-tally-3:before, .fa-duotone.#{$fa-css-prefix}-tally-3:before { content: fa-content(\e296\fe01); }
.fad.#{$fa-css-prefix}-tally-3:after, .fa-duotone.#{$fa-css-prefix}-tally-3:after { content: fa-content(\e296\fe02); }
.fad.#{$fa-css-prefix}-tally-4:before, .fa-duotone.#{$fa-css-prefix}-tally-4:before { content: fa-content(\e297\fe01); }
.fad.#{$fa-css-prefix}-tally-4:after, .fa-duotone.#{$fa-css-prefix}-tally-4:after { content: fa-content(\e297\fe02); }
.fad.#{$fa-css-prefix}-tape:before, .fa-duotone.#{$fa-css-prefix}-tape:before { content: fa-content(\f4db\fe01); }
.fad.#{$fa-css-prefix}-tape:after, .fa-duotone.#{$fa-css-prefix}-tape:after { content: fa-content(\f4db\fe02); }
.fad.#{$fa-css-prefix}-taxi:before, .fa-duotone.#{$fa-css-prefix}-taxi:before { content: fa-content(\f1ba); }
.fad.#{$fa-css-prefix}-taxi:after, .fa-duotone.#{$fa-css-prefix}-taxi:after { content: fa-content(\10f1ba); }
.fad.#{$fa-css-prefix}-cab:before, .fa-duotone.#{$fa-css-prefix}-cab:before { content: fa-content(\f1ba); }
.fad.#{$fa-css-prefix}-cab:after, .fa-duotone.#{$fa-css-prefix}-cab:after { content: fa-content(\10f1ba); }
.fad.#{$fa-css-prefix}-taxi-bus:before, .fa-duotone.#{$fa-css-prefix}-taxi-bus:before { content: fa-content(\e298\fe01); }
.fad.#{$fa-css-prefix}-taxi-bus:after, .fa-duotone.#{$fa-css-prefix}-taxi-bus:after { content: fa-content(\e298\fe02); }
.fad.#{$fa-css-prefix}-teddy-bear:before, .fa-duotone.#{$fa-css-prefix}-teddy-bear:before { content: fa-content(\e3cf\fe01); }
.fad.#{$fa-css-prefix}-teddy-bear:after, .fa-duotone.#{$fa-css-prefix}-teddy-bear:after { content: fa-content(\e3cf\fe02); }
.fad.#{$fa-css-prefix}-teeth:before, .fa-duotone.#{$fa-css-prefix}-teeth:before { content: fa-content(\f62e\fe01); }
.fad.#{$fa-css-prefix}-teeth:after, .fa-duotone.#{$fa-css-prefix}-teeth:after { content: fa-content(\f62e\fe02); }
.fad.#{$fa-css-prefix}-teeth-open:before, .fa-duotone.#{$fa-css-prefix}-teeth-open:before { content: fa-content(\f62f\fe01); }
.fad.#{$fa-css-prefix}-teeth-open:after, .fa-duotone.#{$fa-css-prefix}-teeth-open:after { content: fa-content(\f62f\fe02); }
.fad.#{$fa-css-prefix}-telescope:before, .fa-duotone.#{$fa-css-prefix}-telescope:before { content: fa-content(\e03e); }
.fad.#{$fa-css-prefix}-telescope:after, .fa-duotone.#{$fa-css-prefix}-telescope:after { content: fa-content(\10e03e); }
.fad.#{$fa-css-prefix}-temperature-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-temperature-arrow-down:before { content: fa-content(\e03f\fe01); }
.fad.#{$fa-css-prefix}-temperature-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-temperature-arrow-down:after { content: fa-content(\e03f\fe02); }
.fad.#{$fa-css-prefix}-temperature-down:before, .fa-duotone.#{$fa-css-prefix}-temperature-down:before { content: fa-content(\e03f\fe01); }
.fad.#{$fa-css-prefix}-temperature-down:after, .fa-duotone.#{$fa-css-prefix}-temperature-down:after { content: fa-content(\e03f\fe02); }
.fad.#{$fa-css-prefix}-temperature-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-temperature-arrow-up:before { content: fa-content(\e040\fe01); }
.fad.#{$fa-css-prefix}-temperature-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-temperature-arrow-up:after { content: fa-content(\e040\fe02); }
.fad.#{$fa-css-prefix}-temperature-up:before, .fa-duotone.#{$fa-css-prefix}-temperature-up:before { content: fa-content(\e040\fe01); }
.fad.#{$fa-css-prefix}-temperature-up:after, .fa-duotone.#{$fa-css-prefix}-temperature-up:after { content: fa-content(\e040\fe02); }
.fad.#{$fa-css-prefix}-temperature-empty:before, .fa-duotone.#{$fa-css-prefix}-temperature-empty:before { content: fa-content(\f2cb\fe01); }
.fad.#{$fa-css-prefix}-temperature-empty:after, .fa-duotone.#{$fa-css-prefix}-temperature-empty:after { content: fa-content(\f2cb\fe02); }
.fad.#{$fa-css-prefix}-temperature-0:before, .fa-duotone.#{$fa-css-prefix}-temperature-0:before { content: fa-content(\f2cb\fe01); }
.fad.#{$fa-css-prefix}-temperature-0:after, .fa-duotone.#{$fa-css-prefix}-temperature-0:after { content: fa-content(\f2cb\fe02); }
.fad.#{$fa-css-prefix}-thermometer-0:before, .fa-duotone.#{$fa-css-prefix}-thermometer-0:before { content: fa-content(\f2cb\fe01); }
.fad.#{$fa-css-prefix}-thermometer-0:after, .fa-duotone.#{$fa-css-prefix}-thermometer-0:after { content: fa-content(\f2cb\fe02); }
.fad.#{$fa-css-prefix}-thermometer-empty:before, .fa-duotone.#{$fa-css-prefix}-thermometer-empty:before { content: fa-content(\f2cb\fe01); }
.fad.#{$fa-css-prefix}-thermometer-empty:after, .fa-duotone.#{$fa-css-prefix}-thermometer-empty:after { content: fa-content(\f2cb\fe02); }
.fad.#{$fa-css-prefix}-temperature-full:before, .fa-duotone.#{$fa-css-prefix}-temperature-full:before { content: fa-content(\f2c7\fe01); }
.fad.#{$fa-css-prefix}-temperature-full:after, .fa-duotone.#{$fa-css-prefix}-temperature-full:after { content: fa-content(\f2c7\fe02); }
.fad.#{$fa-css-prefix}-temperature-4:before, .fa-duotone.#{$fa-css-prefix}-temperature-4:before { content: fa-content(\f2c7\fe01); }
.fad.#{$fa-css-prefix}-temperature-4:after, .fa-duotone.#{$fa-css-prefix}-temperature-4:after { content: fa-content(\f2c7\fe02); }
.fad.#{$fa-css-prefix}-thermometer-4:before, .fa-duotone.#{$fa-css-prefix}-thermometer-4:before { content: fa-content(\f2c7\fe01); }
.fad.#{$fa-css-prefix}-thermometer-4:after, .fa-duotone.#{$fa-css-prefix}-thermometer-4:after { content: fa-content(\f2c7\fe02); }
.fad.#{$fa-css-prefix}-thermometer-full:before, .fa-duotone.#{$fa-css-prefix}-thermometer-full:before { content: fa-content(\f2c7\fe01); }
.fad.#{$fa-css-prefix}-thermometer-full:after, .fa-duotone.#{$fa-css-prefix}-thermometer-full:after { content: fa-content(\f2c7\fe02); }
.fad.#{$fa-css-prefix}-temperature-half:before, .fa-duotone.#{$fa-css-prefix}-temperature-half:before { content: fa-content(\f2c9); }
.fad.#{$fa-css-prefix}-temperature-half:after, .fa-duotone.#{$fa-css-prefix}-temperature-half:after { content: fa-content(\10f2c9); }
.fad.#{$fa-css-prefix}-temperature-2:before, .fa-duotone.#{$fa-css-prefix}-temperature-2:before { content: fa-content(\f2c9); }
.fad.#{$fa-css-prefix}-temperature-2:after, .fa-duotone.#{$fa-css-prefix}-temperature-2:after { content: fa-content(\10f2c9); }
.fad.#{$fa-css-prefix}-thermometer-2:before, .fa-duotone.#{$fa-css-prefix}-thermometer-2:before { content: fa-content(\f2c9); }
.fad.#{$fa-css-prefix}-thermometer-2:after, .fa-duotone.#{$fa-css-prefix}-thermometer-2:after { content: fa-content(\10f2c9); }
.fad.#{$fa-css-prefix}-thermometer-half:before, .fa-duotone.#{$fa-css-prefix}-thermometer-half:before { content: fa-content(\f2c9); }
.fad.#{$fa-css-prefix}-thermometer-half:after, .fa-duotone.#{$fa-css-prefix}-thermometer-half:after { content: fa-content(\10f2c9); }
.fad.#{$fa-css-prefix}-temperature-high:before, .fa-duotone.#{$fa-css-prefix}-temperature-high:before { content: fa-content(\f769\fe01); }
.fad.#{$fa-css-prefix}-temperature-high:after, .fa-duotone.#{$fa-css-prefix}-temperature-high:after { content: fa-content(\f769\fe02); }
.fad.#{$fa-css-prefix}-temperature-list:before, .fa-duotone.#{$fa-css-prefix}-temperature-list:before { content: fa-content(\e299\fe01); }
.fad.#{$fa-css-prefix}-temperature-list:after, .fa-duotone.#{$fa-css-prefix}-temperature-list:after { content: fa-content(\e299\fe02); }
.fad.#{$fa-css-prefix}-temperature-low:before, .fa-duotone.#{$fa-css-prefix}-temperature-low:before { content: fa-content(\f76b\fe01); }
.fad.#{$fa-css-prefix}-temperature-low:after, .fa-duotone.#{$fa-css-prefix}-temperature-low:after { content: fa-content(\f76b\fe02); }
.fad.#{$fa-css-prefix}-temperature-quarter:before, .fa-duotone.#{$fa-css-prefix}-temperature-quarter:before { content: fa-content(\f2ca\fe01); }
.fad.#{$fa-css-prefix}-temperature-quarter:after, .fa-duotone.#{$fa-css-prefix}-temperature-quarter:after { content: fa-content(\f2ca\fe02); }
.fad.#{$fa-css-prefix}-temperature-1:before, .fa-duotone.#{$fa-css-prefix}-temperature-1:before { content: fa-content(\f2ca\fe01); }
.fad.#{$fa-css-prefix}-temperature-1:after, .fa-duotone.#{$fa-css-prefix}-temperature-1:after { content: fa-content(\f2ca\fe02); }
.fad.#{$fa-css-prefix}-thermometer-1:before, .fa-duotone.#{$fa-css-prefix}-thermometer-1:before { content: fa-content(\f2ca\fe01); }
.fad.#{$fa-css-prefix}-thermometer-1:after, .fa-duotone.#{$fa-css-prefix}-thermometer-1:after { content: fa-content(\f2ca\fe02); }
.fad.#{$fa-css-prefix}-thermometer-quarter:before, .fa-duotone.#{$fa-css-prefix}-thermometer-quarter:before { content: fa-content(\f2ca\fe01); }
.fad.#{$fa-css-prefix}-thermometer-quarter:after, .fa-duotone.#{$fa-css-prefix}-thermometer-quarter:after { content: fa-content(\f2ca\fe02); }
.fad.#{$fa-css-prefix}-temperature-snow:before, .fa-duotone.#{$fa-css-prefix}-temperature-snow:before { content: fa-content(\f768\fe01); }
.fad.#{$fa-css-prefix}-temperature-snow:after, .fa-duotone.#{$fa-css-prefix}-temperature-snow:after { content: fa-content(\f768\fe02); }
.fad.#{$fa-css-prefix}-temperature-frigid:before, .fa-duotone.#{$fa-css-prefix}-temperature-frigid:before { content: fa-content(\f768\fe01); }
.fad.#{$fa-css-prefix}-temperature-frigid:after, .fa-duotone.#{$fa-css-prefix}-temperature-frigid:after { content: fa-content(\f768\fe02); }
.fad.#{$fa-css-prefix}-temperature-sun:before, .fa-duotone.#{$fa-css-prefix}-temperature-sun:before { content: fa-content(\f76a\fe01); }
.fad.#{$fa-css-prefix}-temperature-sun:after, .fa-duotone.#{$fa-css-prefix}-temperature-sun:after { content: fa-content(\f76a\fe02); }
.fad.#{$fa-css-prefix}-temperature-hot:before, .fa-duotone.#{$fa-css-prefix}-temperature-hot:before { content: fa-content(\f76a\fe01); }
.fad.#{$fa-css-prefix}-temperature-hot:after, .fa-duotone.#{$fa-css-prefix}-temperature-hot:after { content: fa-content(\f76a\fe02); }
.fad.#{$fa-css-prefix}-temperature-three-quarters:before, .fa-duotone.#{$fa-css-prefix}-temperature-three-quarters:before { content: fa-content(\f2c8\fe01); }
.fad.#{$fa-css-prefix}-temperature-three-quarters:after, .fa-duotone.#{$fa-css-prefix}-temperature-three-quarters:after { content: fa-content(\f2c8\fe02); }
.fad.#{$fa-css-prefix}-temperature-3:before, .fa-duotone.#{$fa-css-prefix}-temperature-3:before { content: fa-content(\f2c8\fe01); }
.fad.#{$fa-css-prefix}-temperature-3:after, .fa-duotone.#{$fa-css-prefix}-temperature-3:after { content: fa-content(\f2c8\fe02); }
.fad.#{$fa-css-prefix}-thermometer-3:before, .fa-duotone.#{$fa-css-prefix}-thermometer-3:before { content: fa-content(\f2c8\fe01); }
.fad.#{$fa-css-prefix}-thermometer-3:after, .fa-duotone.#{$fa-css-prefix}-thermometer-3:after { content: fa-content(\f2c8\fe02); }
.fad.#{$fa-css-prefix}-thermometer-three-quarters:before, .fa-duotone.#{$fa-css-prefix}-thermometer-three-quarters:before { content: fa-content(\f2c8\fe01); }
.fad.#{$fa-css-prefix}-thermometer-three-quarters:after, .fa-duotone.#{$fa-css-prefix}-thermometer-three-quarters:after { content: fa-content(\f2c8\fe02); }
.fad.#{$fa-css-prefix}-tenge-sign:before, .fa-duotone.#{$fa-css-prefix}-tenge-sign:before { content: fa-content(\20b8\fe01); }
.fad.#{$fa-css-prefix}-tenge-sign:after, .fa-duotone.#{$fa-css-prefix}-tenge-sign:after { content: fa-content(\20b8\fe02); }
.fad.#{$fa-css-prefix}-tenge:before, .fa-duotone.#{$fa-css-prefix}-tenge:before { content: fa-content(\20b8\fe01); }
.fad.#{$fa-css-prefix}-tenge:after, .fa-duotone.#{$fa-css-prefix}-tenge:after { content: fa-content(\20b8\fe02); }
.fad.#{$fa-css-prefix}-tennis-ball:before, .fa-duotone.#{$fa-css-prefix}-tennis-ball:before { content: fa-content(\f45e); }
.fad.#{$fa-css-prefix}-tennis-ball:after, .fa-duotone.#{$fa-css-prefix}-tennis-ball:after { content: fa-content(\10f45e); }
.fad.#{$fa-css-prefix}-terminal:before, .fa-duotone.#{$fa-css-prefix}-terminal:before { content: fa-content(\f120\fe01); }
.fad.#{$fa-css-prefix}-terminal:after, .fa-duotone.#{$fa-css-prefix}-terminal:after { content: fa-content(\f120\fe02); }
.fad.#{$fa-css-prefix}-text:before, .fa-duotone.#{$fa-css-prefix}-text:before { content: fa-content(\f893\fe01); }
.fad.#{$fa-css-prefix}-text:after, .fa-duotone.#{$fa-css-prefix}-text:after { content: fa-content(\f893\fe02); }
.fad.#{$fa-css-prefix}-text-height:before, .fa-duotone.#{$fa-css-prefix}-text-height:before { content: fa-content(\f034\fe01); }
.fad.#{$fa-css-prefix}-text-height:after, .fa-duotone.#{$fa-css-prefix}-text-height:after { content: fa-content(\f034\fe02); }
.fad.#{$fa-css-prefix}-text-size:before, .fa-duotone.#{$fa-css-prefix}-text-size:before { content: fa-content(\f894\fe01); }
.fad.#{$fa-css-prefix}-text-size:after, .fa-duotone.#{$fa-css-prefix}-text-size:after { content: fa-content(\f894\fe02); }
.fad.#{$fa-css-prefix}-text-slash:before, .fa-duotone.#{$fa-css-prefix}-text-slash:before { content: fa-content(\f87d\fe01); }
.fad.#{$fa-css-prefix}-text-slash:after, .fa-duotone.#{$fa-css-prefix}-text-slash:after { content: fa-content(\f87d\fe02); }
.fad.#{$fa-css-prefix}-remove-format:before, .fa-duotone.#{$fa-css-prefix}-remove-format:before { content: fa-content(\f87d\fe01); }
.fad.#{$fa-css-prefix}-remove-format:after, .fa-duotone.#{$fa-css-prefix}-remove-format:after { content: fa-content(\f87d\fe02); }
.fad.#{$fa-css-prefix}-text-width:before, .fa-duotone.#{$fa-css-prefix}-text-width:before { content: fa-content(\f035\fe01); }
.fad.#{$fa-css-prefix}-text-width:after, .fa-duotone.#{$fa-css-prefix}-text-width:after { content: fa-content(\f035\fe02); }
.fad.#{$fa-css-prefix}-thermometer:before, .fa-duotone.#{$fa-css-prefix}-thermometer:before { content: fa-content(\f491\fe01); }
.fad.#{$fa-css-prefix}-thermometer:after, .fa-duotone.#{$fa-css-prefix}-thermometer:after { content: fa-content(\f491\fe02); }
.fad.#{$fa-css-prefix}-theta:before, .fa-duotone.#{$fa-css-prefix}-theta:before { content: fa-content(\f69e\fe01); }
.fad.#{$fa-css-prefix}-theta:after, .fa-duotone.#{$fa-css-prefix}-theta:after { content: fa-content(\f69e\fe02); }
.fad.#{$fa-css-prefix}-thought-bubble:before, .fa-duotone.#{$fa-css-prefix}-thought-bubble:before { content: fa-content(\e32e\fe01); }
.fad.#{$fa-css-prefix}-thought-bubble:after, .fa-duotone.#{$fa-css-prefix}-thought-bubble:after { content: fa-content(\e32e\fe02); }
.fad.#{$fa-css-prefix}-thumbs-down:before, .fa-duotone.#{$fa-css-prefix}-thumbs-down:before { content: fa-content(\f165); }
.fad.#{$fa-css-prefix}-thumbs-down:after, .fa-duotone.#{$fa-css-prefix}-thumbs-down:after { content: fa-content(\10f165); }
.fad.#{$fa-css-prefix}-thumbs-up:before, .fa-duotone.#{$fa-css-prefix}-thumbs-up:before { content: fa-content(\f164); }
.fad.#{$fa-css-prefix}-thumbs-up:after, .fa-duotone.#{$fa-css-prefix}-thumbs-up:after { content: fa-content(\10f164); }
.fad.#{$fa-css-prefix}-thumbtack:before, .fa-duotone.#{$fa-css-prefix}-thumbtack:before { content: fa-content(\f08d); }
.fad.#{$fa-css-prefix}-thumbtack:after, .fa-duotone.#{$fa-css-prefix}-thumbtack:after { content: fa-content(\10f08d); }
.fad.#{$fa-css-prefix}-thumb-tack:before, .fa-duotone.#{$fa-css-prefix}-thumb-tack:before { content: fa-content(\f08d); }
.fad.#{$fa-css-prefix}-thumb-tack:after, .fa-duotone.#{$fa-css-prefix}-thumb-tack:after { content: fa-content(\10f08d); }
.fad.#{$fa-css-prefix}-tick:before, .fa-duotone.#{$fa-css-prefix}-tick:before { content: fa-content(\e32f\fe01); }
.fad.#{$fa-css-prefix}-tick:after, .fa-duotone.#{$fa-css-prefix}-tick:after { content: fa-content(\e32f\fe02); }
.fad.#{$fa-css-prefix}-ticket:before, .fa-duotone.#{$fa-css-prefix}-ticket:before { content: fa-content(\f145); }
.fad.#{$fa-css-prefix}-ticket:after, .fa-duotone.#{$fa-css-prefix}-ticket:after { content: fa-content(\10f145); }
.fad.#{$fa-css-prefix}-ticket-airline:before, .fa-duotone.#{$fa-css-prefix}-ticket-airline:before { content: fa-content(\e29a\fe01); }
.fad.#{$fa-css-prefix}-ticket-airline:after, .fa-duotone.#{$fa-css-prefix}-ticket-airline:after { content: fa-content(\e29a\fe02); }
.fad.#{$fa-css-prefix}-ticket-simple:before, .fa-duotone.#{$fa-css-prefix}-ticket-simple:before { content: fa-content(\f3ff\fe01); }
.fad.#{$fa-css-prefix}-ticket-simple:after, .fa-duotone.#{$fa-css-prefix}-ticket-simple:after { content: fa-content(\f3ff\fe02); }
.fad.#{$fa-css-prefix}-ticket-alt:before, .fa-duotone.#{$fa-css-prefix}-ticket-alt:before { content: fa-content(\f3ff\fe01); }
.fad.#{$fa-css-prefix}-ticket-alt:after, .fa-duotone.#{$fa-css-prefix}-ticket-alt:after { content: fa-content(\f3ff\fe02); }
.fad.#{$fa-css-prefix}-tickets-airline:before, .fa-duotone.#{$fa-css-prefix}-tickets-airline:before { content: fa-content(\e29b\fe01); }
.fad.#{$fa-css-prefix}-tickets-airline:after, .fa-duotone.#{$fa-css-prefix}-tickets-airline:after { content: fa-content(\e29b\fe02); }
.fad.#{$fa-css-prefix}-tilde:before, .fa-duotone.#{$fa-css-prefix}-tilde:before { content: fa-content(\7e\fe01); }
.fad.#{$fa-css-prefix}-tilde:after, .fa-duotone.#{$fa-css-prefix}-tilde:after { content: fa-content(\7e\fe02); }
.fad.#{$fa-css-prefix}-timeline:before, .fa-duotone.#{$fa-css-prefix}-timeline:before { content: fa-content(\e29c\fe01); }
.fad.#{$fa-css-prefix}-timeline:after, .fa-duotone.#{$fa-css-prefix}-timeline:after { content: fa-content(\e29c\fe02); }
.fad.#{$fa-css-prefix}-timeline-arrow:before, .fa-duotone.#{$fa-css-prefix}-timeline-arrow:before { content: fa-content(\e29d\fe01); }
.fad.#{$fa-css-prefix}-timeline-arrow:after, .fa-duotone.#{$fa-css-prefix}-timeline-arrow:after { content: fa-content(\e29d\fe02); }
.fad.#{$fa-css-prefix}-timer:before, .fa-duotone.#{$fa-css-prefix}-timer:before { content: fa-content(\e29e\fe01); }
.fad.#{$fa-css-prefix}-timer:after, .fa-duotone.#{$fa-css-prefix}-timer:after { content: fa-content(\e29e\fe02); }
.fad.#{$fa-css-prefix}-tire:before, .fa-duotone.#{$fa-css-prefix}-tire:before { content: fa-content(\f631\fe01); }
.fad.#{$fa-css-prefix}-tire:after, .fa-duotone.#{$fa-css-prefix}-tire:after { content: fa-content(\f631\fe02); }
.fad.#{$fa-css-prefix}-tire-flat:before, .fa-duotone.#{$fa-css-prefix}-tire-flat:before { content: fa-content(\f632\fe01); }
.fad.#{$fa-css-prefix}-tire-flat:after, .fa-duotone.#{$fa-css-prefix}-tire-flat:after { content: fa-content(\f632\fe02); }
.fad.#{$fa-css-prefix}-tire-pressure-warning:before, .fa-duotone.#{$fa-css-prefix}-tire-pressure-warning:before { content: fa-content(\f633\fe01); }
.fad.#{$fa-css-prefix}-tire-pressure-warning:after, .fa-duotone.#{$fa-css-prefix}-tire-pressure-warning:after { content: fa-content(\f633\fe02); }
.fad.#{$fa-css-prefix}-tire-rugged:before, .fa-duotone.#{$fa-css-prefix}-tire-rugged:before { content: fa-content(\f634\fe01); }
.fad.#{$fa-css-prefix}-tire-rugged:after, .fa-duotone.#{$fa-css-prefix}-tire-rugged:after { content: fa-content(\f634\fe02); }
.fad.#{$fa-css-prefix}-toggle-off:before, .fa-duotone.#{$fa-css-prefix}-toggle-off:before { content: fa-content(\f204\fe01); }
.fad.#{$fa-css-prefix}-toggle-off:after, .fa-duotone.#{$fa-css-prefix}-toggle-off:after { content: fa-content(\f204\fe02); }
.fad.#{$fa-css-prefix}-toggle-on:before, .fa-duotone.#{$fa-css-prefix}-toggle-on:before { content: fa-content(\f205\fe01); }
.fad.#{$fa-css-prefix}-toggle-on:after, .fa-duotone.#{$fa-css-prefix}-toggle-on:after { content: fa-content(\f205\fe02); }
.fad.#{$fa-css-prefix}-toilet:before, .fa-duotone.#{$fa-css-prefix}-toilet:before { content: fa-content(\f7d8); }
.fad.#{$fa-css-prefix}-toilet:after, .fa-duotone.#{$fa-css-prefix}-toilet:after { content: fa-content(\10f7d8); }
.fad.#{$fa-css-prefix}-toilet-paper:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper:before { content: fa-content(\f71e); }
.fad.#{$fa-css-prefix}-toilet-paper:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper:after { content: fa-content(\10f71e); }
.fad.#{$fa-css-prefix}-toilet-paper-blank:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-blank:before { content: fa-content(\f71f\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-blank:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-blank:after { content: fa-content(\f71f\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-alt:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-alt:before { content: fa-content(\f71f\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-alt:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-alt:after { content: fa-content(\f71f\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-blank-under:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-blank-under:before { content: fa-content(\e29f\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-blank-under:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-blank-under:after { content: fa-content(\e29f\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-reverse-alt:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse-alt:before { content: fa-content(\e29f\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-reverse-alt:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse-alt:after { content: fa-content(\e29f\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-slash:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-slash:before { content: fa-content(\e072\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-slash:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-slash:after { content: fa-content(\e072\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-under:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-under:before { content: fa-content(\e2a0\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-under:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-under:after { content: fa-content(\e2a0\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-reverse:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse:before { content: fa-content(\e2a0\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-reverse:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse:after { content: fa-content(\e2a0\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-under-slash:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-under-slash:before { content: fa-content(\e2a1\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-under-slash:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-under-slash:after { content: fa-content(\e2a1\fe02); }
.fad.#{$fa-css-prefix}-toilet-paper-reverse-slash:before, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse-slash:before { content: fa-content(\e2a1\fe01); }
.fad.#{$fa-css-prefix}-toilet-paper-reverse-slash:after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse-slash:after { content: fa-content(\e2a1\fe02); }
.fad.#{$fa-css-prefix}-tomato:before, .fa-duotone.#{$fa-css-prefix}-tomato:before { content: fa-content(\e330\fe01); }
.fad.#{$fa-css-prefix}-tomato:after, .fa-duotone.#{$fa-css-prefix}-tomato:after { content: fa-content(\e330\fe02); }
.fad.#{$fa-css-prefix}-tombstone:before, .fa-duotone.#{$fa-css-prefix}-tombstone:before { content: fa-content(\f720\fe01); }
.fad.#{$fa-css-prefix}-tombstone:after, .fa-duotone.#{$fa-css-prefix}-tombstone:after { content: fa-content(\f720\fe02); }
.fad.#{$fa-css-prefix}-tombstone-blank:before, .fa-duotone.#{$fa-css-prefix}-tombstone-blank:before { content: fa-content(\f721); }
.fad.#{$fa-css-prefix}-tombstone-blank:after, .fa-duotone.#{$fa-css-prefix}-tombstone-blank:after { content: fa-content(\10f721); }
.fad.#{$fa-css-prefix}-tombstone-alt:before, .fa-duotone.#{$fa-css-prefix}-tombstone-alt:before { content: fa-content(\f721); }
.fad.#{$fa-css-prefix}-tombstone-alt:after, .fa-duotone.#{$fa-css-prefix}-tombstone-alt:after { content: fa-content(\10f721); }
.fad.#{$fa-css-prefix}-toolbox:before, .fa-duotone.#{$fa-css-prefix}-toolbox:before { content: fa-content(\f552); }
.fad.#{$fa-css-prefix}-toolbox:after, .fa-duotone.#{$fa-css-prefix}-toolbox:after { content: fa-content(\10f552); }
.fad.#{$fa-css-prefix}-tooth:before, .fa-duotone.#{$fa-css-prefix}-tooth:before { content: fa-content(\f5c9); }
.fad.#{$fa-css-prefix}-tooth:after, .fa-duotone.#{$fa-css-prefix}-tooth:after { content: fa-content(\10f5c9); }
.fad.#{$fa-css-prefix}-toothbrush:before, .fa-duotone.#{$fa-css-prefix}-toothbrush:before { content: fa-content(\f635); }
.fad.#{$fa-css-prefix}-toothbrush:after, .fa-duotone.#{$fa-css-prefix}-toothbrush:after { content: fa-content(\10f635); }
.fad.#{$fa-css-prefix}-torii-gate:before, .fa-duotone.#{$fa-css-prefix}-torii-gate:before { content: fa-content(\26e9\fe01); }
.fad.#{$fa-css-prefix}-torii-gate:after, .fa-duotone.#{$fa-css-prefix}-torii-gate:after { content: fa-content(\26e9\fe02); }
.fad.#{$fa-css-prefix}-tornado:before, .fa-duotone.#{$fa-css-prefix}-tornado:before { content: fa-content(\f76f); }
.fad.#{$fa-css-prefix}-tornado:after, .fa-duotone.#{$fa-css-prefix}-tornado:after { content: fa-content(\10f76f); }
.fad.#{$fa-css-prefix}-tower-broadcast:before, .fa-duotone.#{$fa-css-prefix}-tower-broadcast:before { content: fa-content(\f519\fe01); }
.fad.#{$fa-css-prefix}-tower-broadcast:after, .fa-duotone.#{$fa-css-prefix}-tower-broadcast:after { content: fa-content(\f519\fe02); }
.fad.#{$fa-css-prefix}-broadcast-tower:before, .fa-duotone.#{$fa-css-prefix}-broadcast-tower:before { content: fa-content(\f519\fe01); }
.fad.#{$fa-css-prefix}-broadcast-tower:after, .fa-duotone.#{$fa-css-prefix}-broadcast-tower:after { content: fa-content(\f519\fe02); }
.fad.#{$fa-css-prefix}-tower-control:before, .fa-duotone.#{$fa-css-prefix}-tower-control:before { content: fa-content(\e2a2\fe01); }
.fad.#{$fa-css-prefix}-tower-control:after, .fa-duotone.#{$fa-css-prefix}-tower-control:after { content: fa-content(\e2a2\fe02); }
.fad.#{$fa-css-prefix}-tractor:before, .fa-duotone.#{$fa-css-prefix}-tractor:before { content: fa-content(\f722); }
.fad.#{$fa-css-prefix}-tractor:after, .fa-duotone.#{$fa-css-prefix}-tractor:after { content: fa-content(\10f722); }
.fad.#{$fa-css-prefix}-trademark:before, .fa-duotone.#{$fa-css-prefix}-trademark:before { content: fa-content(\2122\fe01); }
.fad.#{$fa-css-prefix}-trademark:after, .fa-duotone.#{$fa-css-prefix}-trademark:after { content: fa-content(\2122\fe02); }
.fad.#{$fa-css-prefix}-traffic-cone:before, .fa-duotone.#{$fa-css-prefix}-traffic-cone:before { content: fa-content(\f636\fe01); }
.fad.#{$fa-css-prefix}-traffic-cone:after, .fa-duotone.#{$fa-css-prefix}-traffic-cone:after { content: fa-content(\f636\fe02); }
.fad.#{$fa-css-prefix}-traffic-light:before, .fa-duotone.#{$fa-css-prefix}-traffic-light:before { content: fa-content(\f637); }
.fad.#{$fa-css-prefix}-traffic-light:after, .fa-duotone.#{$fa-css-prefix}-traffic-light:after { content: fa-content(\10f637); }
.fad.#{$fa-css-prefix}-traffic-light-go:before, .fa-duotone.#{$fa-css-prefix}-traffic-light-go:before { content: fa-content(\f638\fe01); }
.fad.#{$fa-css-prefix}-traffic-light-go:after, .fa-duotone.#{$fa-css-prefix}-traffic-light-go:after { content: fa-content(\f638\fe02); }
.fad.#{$fa-css-prefix}-traffic-light-slow:before, .fa-duotone.#{$fa-css-prefix}-traffic-light-slow:before { content: fa-content(\f639\fe01); }
.fad.#{$fa-css-prefix}-traffic-light-slow:after, .fa-duotone.#{$fa-css-prefix}-traffic-light-slow:after { content: fa-content(\f639\fe02); }
.fad.#{$fa-css-prefix}-traffic-light-stop:before, .fa-duotone.#{$fa-css-prefix}-traffic-light-stop:before { content: fa-content(\f63a\fe01); }
.fad.#{$fa-css-prefix}-traffic-light-stop:after, .fa-duotone.#{$fa-css-prefix}-traffic-light-stop:after { content: fa-content(\f63a\fe02); }
.fad.#{$fa-css-prefix}-trailer:before, .fa-duotone.#{$fa-css-prefix}-trailer:before { content: fa-content(\e041\fe01); }
.fad.#{$fa-css-prefix}-trailer:after, .fa-duotone.#{$fa-css-prefix}-trailer:after { content: fa-content(\e041\fe02); }
.fad.#{$fa-css-prefix}-train:before, .fa-duotone.#{$fa-css-prefix}-train:before { content: fa-content(\f238); }
.fad.#{$fa-css-prefix}-train:after, .fa-duotone.#{$fa-css-prefix}-train:after { content: fa-content(\10f238); }
.fad.#{$fa-css-prefix}-train-subway:before, .fa-duotone.#{$fa-css-prefix}-train-subway:before { content: fa-content(\f239\fe01); }
.fad.#{$fa-css-prefix}-train-subway:after, .fa-duotone.#{$fa-css-prefix}-train-subway:after { content: fa-content(\f239\fe02); }
.fad.#{$fa-css-prefix}-subway:before, .fa-duotone.#{$fa-css-prefix}-subway:before { content: fa-content(\f239\fe01); }
.fad.#{$fa-css-prefix}-subway:after, .fa-duotone.#{$fa-css-prefix}-subway:after { content: fa-content(\f239\fe02); }
.fad.#{$fa-css-prefix}-train-subway-tunnel:before, .fa-duotone.#{$fa-css-prefix}-train-subway-tunnel:before { content: fa-content(\e2a3\fe01); }
.fad.#{$fa-css-prefix}-train-subway-tunnel:after, .fa-duotone.#{$fa-css-prefix}-train-subway-tunnel:after { content: fa-content(\e2a3\fe02); }
.fad.#{$fa-css-prefix}-subway-tunnel:before, .fa-duotone.#{$fa-css-prefix}-subway-tunnel:before { content: fa-content(\e2a3\fe01); }
.fad.#{$fa-css-prefix}-subway-tunnel:after, .fa-duotone.#{$fa-css-prefix}-subway-tunnel:after { content: fa-content(\e2a3\fe02); }
.fad.#{$fa-css-prefix}-train-tram:before, .fa-duotone.#{$fa-css-prefix}-train-tram:before { content: fa-content(\f7da); }
.fad.#{$fa-css-prefix}-train-tram:after, .fa-duotone.#{$fa-css-prefix}-train-tram:after { content: fa-content(\10f7da); }
.fad.#{$fa-css-prefix}-tram:before, .fa-duotone.#{$fa-css-prefix}-tram:before { content: fa-content(\f7da); }
.fad.#{$fa-css-prefix}-tram:after, .fa-duotone.#{$fa-css-prefix}-tram:after { content: fa-content(\10f7da); }
.fad.#{$fa-css-prefix}-transformer-bolt:before, .fa-duotone.#{$fa-css-prefix}-transformer-bolt:before { content: fa-content(\e2a4\fe01); }
.fad.#{$fa-css-prefix}-transformer-bolt:after, .fa-duotone.#{$fa-css-prefix}-transformer-bolt:after { content: fa-content(\e2a4\fe02); }
.fad.#{$fa-css-prefix}-transgender:before, .fa-duotone.#{$fa-css-prefix}-transgender:before { content: fa-content(\26a7\fe01); }
.fad.#{$fa-css-prefix}-transgender:after, .fa-duotone.#{$fa-css-prefix}-transgender:after { content: fa-content(\26a7\fe02); }
.fad.#{$fa-css-prefix}-transgender-alt:before, .fa-duotone.#{$fa-css-prefix}-transgender-alt:before { content: fa-content(\26a7\fe01); }
.fad.#{$fa-css-prefix}-transgender-alt:after, .fa-duotone.#{$fa-css-prefix}-transgender-alt:after { content: fa-content(\26a7\fe02); }
.fad.#{$fa-css-prefix}-transporter:before, .fa-duotone.#{$fa-css-prefix}-transporter:before { content: fa-content(\e042\fe01); }
.fad.#{$fa-css-prefix}-transporter:after, .fa-duotone.#{$fa-css-prefix}-transporter:after { content: fa-content(\e042\fe02); }
.fad.#{$fa-css-prefix}-transporter-1:before, .fa-duotone.#{$fa-css-prefix}-transporter-1:before { content: fa-content(\e043\fe01); }
.fad.#{$fa-css-prefix}-transporter-1:after, .fa-duotone.#{$fa-css-prefix}-transporter-1:after { content: fa-content(\e043\fe02); }
.fad.#{$fa-css-prefix}-transporter-2:before, .fa-duotone.#{$fa-css-prefix}-transporter-2:before { content: fa-content(\e044\fe01); }
.fad.#{$fa-css-prefix}-transporter-2:after, .fa-duotone.#{$fa-css-prefix}-transporter-2:after { content: fa-content(\e044\fe02); }
.fad.#{$fa-css-prefix}-transporter-3:before, .fa-duotone.#{$fa-css-prefix}-transporter-3:before { content: fa-content(\e045\fe01); }
.fad.#{$fa-css-prefix}-transporter-3:after, .fa-duotone.#{$fa-css-prefix}-transporter-3:after { content: fa-content(\e045\fe02); }
.fad.#{$fa-css-prefix}-transporter-4:before, .fa-duotone.#{$fa-css-prefix}-transporter-4:before { content: fa-content(\e2a5\fe01); }
.fad.#{$fa-css-prefix}-transporter-4:after, .fa-duotone.#{$fa-css-prefix}-transporter-4:after { content: fa-content(\e2a5\fe02); }
.fad.#{$fa-css-prefix}-transporter-5:before, .fa-duotone.#{$fa-css-prefix}-transporter-5:before { content: fa-content(\e2a6\fe01); }
.fad.#{$fa-css-prefix}-transporter-5:after, .fa-duotone.#{$fa-css-prefix}-transporter-5:after { content: fa-content(\e2a6\fe02); }
.fad.#{$fa-css-prefix}-transporter-6:before, .fa-duotone.#{$fa-css-prefix}-transporter-6:before { content: fa-content(\e2a7\fe01); }
.fad.#{$fa-css-prefix}-transporter-6:after, .fa-duotone.#{$fa-css-prefix}-transporter-6:after { content: fa-content(\e2a7\fe02); }
.fad.#{$fa-css-prefix}-transporter-7:before, .fa-duotone.#{$fa-css-prefix}-transporter-7:before { content: fa-content(\e2a8\fe01); }
.fad.#{$fa-css-prefix}-transporter-7:after, .fa-duotone.#{$fa-css-prefix}-transporter-7:after { content: fa-content(\e2a8\fe02); }
.fad.#{$fa-css-prefix}-transporter-empty:before, .fa-duotone.#{$fa-css-prefix}-transporter-empty:before { content: fa-content(\e046\fe01); }
.fad.#{$fa-css-prefix}-transporter-empty:after, .fa-duotone.#{$fa-css-prefix}-transporter-empty:after { content: fa-content(\e046\fe02); }
.fad.#{$fa-css-prefix}-trash:before, .fa-duotone.#{$fa-css-prefix}-trash:before { content: fa-content(\f1f8\fe01); }
.fad.#{$fa-css-prefix}-trash:after, .fa-duotone.#{$fa-css-prefix}-trash:after { content: fa-content(\f1f8\fe02); }
.fad.#{$fa-css-prefix}-trash-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-trash-arrow-up:before { content: fa-content(\f829\fe01); }
.fad.#{$fa-css-prefix}-trash-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-trash-arrow-up:after { content: fa-content(\f829\fe02); }
.fad.#{$fa-css-prefix}-trash-restore:before, .fa-duotone.#{$fa-css-prefix}-trash-restore:before { content: fa-content(\f829\fe01); }
.fad.#{$fa-css-prefix}-trash-restore:after, .fa-duotone.#{$fa-css-prefix}-trash-restore:after { content: fa-content(\f829\fe02); }
.fad.#{$fa-css-prefix}-trash-can:before, .fa-duotone.#{$fa-css-prefix}-trash-can:before { content: fa-content(\f2ed\fe01); }
.fad.#{$fa-css-prefix}-trash-can:after, .fa-duotone.#{$fa-css-prefix}-trash-can:after { content: fa-content(\f2ed\fe02); }
.fad.#{$fa-css-prefix}-trash-alt:before, .fa-duotone.#{$fa-css-prefix}-trash-alt:before { content: fa-content(\f2ed\fe01); }
.fad.#{$fa-css-prefix}-trash-alt:after, .fa-duotone.#{$fa-css-prefix}-trash-alt:after { content: fa-content(\f2ed\fe02); }
.fad.#{$fa-css-prefix}-trash-can-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-trash-can-arrow-up:before { content: fa-content(\f82a\fe01); }
.fad.#{$fa-css-prefix}-trash-can-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-trash-can-arrow-up:after { content: fa-content(\f82a\fe02); }
.fad.#{$fa-css-prefix}-trash-restore-alt:before, .fa-duotone.#{$fa-css-prefix}-trash-restore-alt:before { content: fa-content(\f82a\fe01); }
.fad.#{$fa-css-prefix}-trash-restore-alt:after, .fa-duotone.#{$fa-css-prefix}-trash-restore-alt:after { content: fa-content(\f82a\fe02); }
.fad.#{$fa-css-prefix}-trash-can-check:before, .fa-duotone.#{$fa-css-prefix}-trash-can-check:before { content: fa-content(\e2a9\fe01); }
.fad.#{$fa-css-prefix}-trash-can-check:after, .fa-duotone.#{$fa-css-prefix}-trash-can-check:after { content: fa-content(\e2a9\fe02); }
.fad.#{$fa-css-prefix}-trash-can-clock:before, .fa-duotone.#{$fa-css-prefix}-trash-can-clock:before { content: fa-content(\e2aa\fe01); }
.fad.#{$fa-css-prefix}-trash-can-clock:after, .fa-duotone.#{$fa-css-prefix}-trash-can-clock:after { content: fa-content(\e2aa\fe02); }
.fad.#{$fa-css-prefix}-trash-can-list:before, .fa-duotone.#{$fa-css-prefix}-trash-can-list:before { content: fa-content(\e2ab\fe01); }
.fad.#{$fa-css-prefix}-trash-can-list:after, .fa-duotone.#{$fa-css-prefix}-trash-can-list:after { content: fa-content(\e2ab\fe02); }
.fad.#{$fa-css-prefix}-trash-can-plus:before, .fa-duotone.#{$fa-css-prefix}-trash-can-plus:before { content: fa-content(\e2ac\fe01); }
.fad.#{$fa-css-prefix}-trash-can-plus:after, .fa-duotone.#{$fa-css-prefix}-trash-can-plus:after { content: fa-content(\e2ac\fe02); }
.fad.#{$fa-css-prefix}-trash-can-slash:before, .fa-duotone.#{$fa-css-prefix}-trash-can-slash:before { content: fa-content(\e2ad\fe01); }
.fad.#{$fa-css-prefix}-trash-can-slash:after, .fa-duotone.#{$fa-css-prefix}-trash-can-slash:after { content: fa-content(\e2ad\fe02); }
.fad.#{$fa-css-prefix}-trash-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-trash-alt-slash:before { content: fa-content(\e2ad\fe01); }
.fad.#{$fa-css-prefix}-trash-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-trash-alt-slash:after { content: fa-content(\e2ad\fe02); }
.fad.#{$fa-css-prefix}-trash-can-undo:before, .fa-duotone.#{$fa-css-prefix}-trash-can-undo:before { content: fa-content(\f896\fe01); }
.fad.#{$fa-css-prefix}-trash-can-undo:after, .fa-duotone.#{$fa-css-prefix}-trash-can-undo:after { content: fa-content(\f896\fe02); }
.fad.#{$fa-css-prefix}-trash-can-arrow-turn-left:before, .fa-duotone.#{$fa-css-prefix}-trash-can-arrow-turn-left:before { content: fa-content(\f896\fe01); }
.fad.#{$fa-css-prefix}-trash-can-arrow-turn-left:after, .fa-duotone.#{$fa-css-prefix}-trash-can-arrow-turn-left:after { content: fa-content(\f896\fe02); }
.fad.#{$fa-css-prefix}-trash-undo-alt:before, .fa-duotone.#{$fa-css-prefix}-trash-undo-alt:before { content: fa-content(\f896\fe01); }
.fad.#{$fa-css-prefix}-trash-undo-alt:after, .fa-duotone.#{$fa-css-prefix}-trash-undo-alt:after { content: fa-content(\f896\fe02); }
.fad.#{$fa-css-prefix}-trash-can-xmark:before, .fa-duotone.#{$fa-css-prefix}-trash-can-xmark:before { content: fa-content(\e2ae\fe01); }
.fad.#{$fa-css-prefix}-trash-can-xmark:after, .fa-duotone.#{$fa-css-prefix}-trash-can-xmark:after { content: fa-content(\e2ae\fe02); }
.fad.#{$fa-css-prefix}-trash-check:before, .fa-duotone.#{$fa-css-prefix}-trash-check:before { content: fa-content(\e2af\fe01); }
.fad.#{$fa-css-prefix}-trash-check:after, .fa-duotone.#{$fa-css-prefix}-trash-check:after { content: fa-content(\e2af\fe02); }
.fad.#{$fa-css-prefix}-trash-clock:before, .fa-duotone.#{$fa-css-prefix}-trash-clock:before { content: fa-content(\e2b0\fe01); }
.fad.#{$fa-css-prefix}-trash-clock:after, .fa-duotone.#{$fa-css-prefix}-trash-clock:after { content: fa-content(\e2b0\fe02); }
.fad.#{$fa-css-prefix}-trash-list:before, .fa-duotone.#{$fa-css-prefix}-trash-list:before { content: fa-content(\e2b1\fe01); }
.fad.#{$fa-css-prefix}-trash-list:after, .fa-duotone.#{$fa-css-prefix}-trash-list:after { content: fa-content(\e2b1\fe02); }
.fad.#{$fa-css-prefix}-trash-plus:before, .fa-duotone.#{$fa-css-prefix}-trash-plus:before { content: fa-content(\e2b2\fe01); }
.fad.#{$fa-css-prefix}-trash-plus:after, .fa-duotone.#{$fa-css-prefix}-trash-plus:after { content: fa-content(\e2b2\fe02); }
.fad.#{$fa-css-prefix}-trash-slash:before, .fa-duotone.#{$fa-css-prefix}-trash-slash:before { content: fa-content(\e2b3\fe01); }
.fad.#{$fa-css-prefix}-trash-slash:after, .fa-duotone.#{$fa-css-prefix}-trash-slash:after { content: fa-content(\e2b3\fe02); }
.fad.#{$fa-css-prefix}-trash-undo:before, .fa-duotone.#{$fa-css-prefix}-trash-undo:before { content: fa-content(\f895\fe01); }
.fad.#{$fa-css-prefix}-trash-undo:after, .fa-duotone.#{$fa-css-prefix}-trash-undo:after { content: fa-content(\f895\fe02); }
.fad.#{$fa-css-prefix}-trash-arrow-turn-left:before, .fa-duotone.#{$fa-css-prefix}-trash-arrow-turn-left:before { content: fa-content(\f895\fe01); }
.fad.#{$fa-css-prefix}-trash-arrow-turn-left:after, .fa-duotone.#{$fa-css-prefix}-trash-arrow-turn-left:after { content: fa-content(\f895\fe02); }
.fad.#{$fa-css-prefix}-trash-xmark:before, .fa-duotone.#{$fa-css-prefix}-trash-xmark:before { content: fa-content(\e2b4\fe01); }
.fad.#{$fa-css-prefix}-trash-xmark:after, .fa-duotone.#{$fa-css-prefix}-trash-xmark:after { content: fa-content(\e2b4\fe02); }
.fad.#{$fa-css-prefix}-treasure-chest:before, .fa-duotone.#{$fa-css-prefix}-treasure-chest:before { content: fa-content(\f723\fe01); }
.fad.#{$fa-css-prefix}-treasure-chest:after, .fa-duotone.#{$fa-css-prefix}-treasure-chest:after { content: fa-content(\f723\fe02); }
.fad.#{$fa-css-prefix}-tree:before, .fa-duotone.#{$fa-css-prefix}-tree:before { content: fa-content(\f1bb); }
.fad.#{$fa-css-prefix}-tree:after, .fa-duotone.#{$fa-css-prefix}-tree:after { content: fa-content(\10f1bb); }
.fad.#{$fa-css-prefix}-tree-christmas:before, .fa-duotone.#{$fa-css-prefix}-tree-christmas:before { content: fa-content(\f7db); }
.fad.#{$fa-css-prefix}-tree-christmas:after, .fa-duotone.#{$fa-css-prefix}-tree-christmas:after { content: fa-content(\10f7db); }
.fad.#{$fa-css-prefix}-tree-deciduous:before, .fa-duotone.#{$fa-css-prefix}-tree-deciduous:before { content: fa-content(\f400); }
.fad.#{$fa-css-prefix}-tree-deciduous:after, .fa-duotone.#{$fa-css-prefix}-tree-deciduous:after { content: fa-content(\10f400); }
.fad.#{$fa-css-prefix}-tree-alt:before, .fa-duotone.#{$fa-css-prefix}-tree-alt:before { content: fa-content(\f400); }
.fad.#{$fa-css-prefix}-tree-alt:after, .fa-duotone.#{$fa-css-prefix}-tree-alt:after { content: fa-content(\10f400); }
.fad.#{$fa-css-prefix}-tree-decorated:before, .fa-duotone.#{$fa-css-prefix}-tree-decorated:before { content: fa-content(\f7dc\fe01); }
.fad.#{$fa-css-prefix}-tree-decorated:after, .fa-duotone.#{$fa-css-prefix}-tree-decorated:after { content: fa-content(\f7dc\fe02); }
.fad.#{$fa-css-prefix}-tree-large:before, .fa-duotone.#{$fa-css-prefix}-tree-large:before { content: fa-content(\f7dd\fe01); }
.fad.#{$fa-css-prefix}-tree-large:after, .fa-duotone.#{$fa-css-prefix}-tree-large:after { content: fa-content(\f7dd\fe02); }
.fad.#{$fa-css-prefix}-tree-palm:before, .fa-duotone.#{$fa-css-prefix}-tree-palm:before { content: fa-content(\f82b); }
.fad.#{$fa-css-prefix}-tree-palm:after, .fa-duotone.#{$fa-css-prefix}-tree-palm:after { content: fa-content(\10f82b); }
.fad.#{$fa-css-prefix}-trees:before, .fa-duotone.#{$fa-css-prefix}-trees:before { content: fa-content(\f724\fe01); }
.fad.#{$fa-css-prefix}-trees:after, .fa-duotone.#{$fa-css-prefix}-trees:after { content: fa-content(\f724\fe02); }
.fad.#{$fa-css-prefix}-triangle:before, .fa-duotone.#{$fa-css-prefix}-triangle:before { content: fa-content(\25b2\fe01); }
.fad.#{$fa-css-prefix}-triangle:after, .fa-duotone.#{$fa-css-prefix}-triangle:after { content: fa-content(\25b2\fe02); }
.fad.#{$fa-css-prefix}-triangle-exclamation:before, .fa-duotone.#{$fa-css-prefix}-triangle-exclamation:before { content: fa-content(\26a0\fe01); }
.fad.#{$fa-css-prefix}-triangle-exclamation:after, .fa-duotone.#{$fa-css-prefix}-triangle-exclamation:after { content: fa-content(\26a0\fe02); }
.fad.#{$fa-css-prefix}-exclamation-triangle:before, .fa-duotone.#{$fa-css-prefix}-exclamation-triangle:before { content: fa-content(\26a0\fe01); }
.fad.#{$fa-css-prefix}-exclamation-triangle:after, .fa-duotone.#{$fa-css-prefix}-exclamation-triangle:after { content: fa-content(\26a0\fe02); }
.fad.#{$fa-css-prefix}-warning:before, .fa-duotone.#{$fa-css-prefix}-warning:before { content: fa-content(\26a0\fe01); }
.fad.#{$fa-css-prefix}-warning:after, .fa-duotone.#{$fa-css-prefix}-warning:after { content: fa-content(\26a0\fe02); }
.fad.#{$fa-css-prefix}-triangle-instrument:before, .fa-duotone.#{$fa-css-prefix}-triangle-instrument:before { content: fa-content(\f8e2\fe01); }
.fad.#{$fa-css-prefix}-triangle-instrument:after, .fa-duotone.#{$fa-css-prefix}-triangle-instrument:after { content: fa-content(\f8e2\fe02); }
.fad.#{$fa-css-prefix}-triangle-music:before, .fa-duotone.#{$fa-css-prefix}-triangle-music:before { content: fa-content(\f8e2\fe01); }
.fad.#{$fa-css-prefix}-triangle-music:after, .fa-duotone.#{$fa-css-prefix}-triangle-music:after { content: fa-content(\f8e2\fe02); }
.fad.#{$fa-css-prefix}-triangle-person-digging:before, .fa-duotone.#{$fa-css-prefix}-triangle-person-digging:before { content: fa-content(\f85d\fe01); }
.fad.#{$fa-css-prefix}-triangle-person-digging:after, .fa-duotone.#{$fa-css-prefix}-triangle-person-digging:after { content: fa-content(\f85d\fe02); }
.fad.#{$fa-css-prefix}-construction:before, .fa-duotone.#{$fa-css-prefix}-construction:before { content: fa-content(\f85d\fe01); }
.fad.#{$fa-css-prefix}-construction:after, .fa-duotone.#{$fa-css-prefix}-construction:after { content: fa-content(\f85d\fe02); }
.fad.#{$fa-css-prefix}-trophy:before, .fa-duotone.#{$fa-css-prefix}-trophy:before { content: fa-content(\f091); }
.fad.#{$fa-css-prefix}-trophy:after, .fa-duotone.#{$fa-css-prefix}-trophy:after { content: fa-content(\10f091); }
.fad.#{$fa-css-prefix}-trophy-star:before, .fa-duotone.#{$fa-css-prefix}-trophy-star:before { content: fa-content(\f2eb\fe01); }
.fad.#{$fa-css-prefix}-trophy-star:after, .fa-duotone.#{$fa-css-prefix}-trophy-star:after { content: fa-content(\f2eb\fe02); }
.fad.#{$fa-css-prefix}-trophy-alt:before, .fa-duotone.#{$fa-css-prefix}-trophy-alt:before { content: fa-content(\f2eb\fe01); }
.fad.#{$fa-css-prefix}-trophy-alt:after, .fa-duotone.#{$fa-css-prefix}-trophy-alt:after { content: fa-content(\f2eb\fe02); }
.fad.#{$fa-css-prefix}-truck:before, .fa-duotone.#{$fa-css-prefix}-truck:before { content: fa-content(\26df\fe01); }
.fad.#{$fa-css-prefix}-truck:after, .fa-duotone.#{$fa-css-prefix}-truck:after { content: fa-content(\26df\fe02); }
.fad.#{$fa-css-prefix}-truck-bolt:before, .fa-duotone.#{$fa-css-prefix}-truck-bolt:before { content: fa-content(\e3d0\fe01); }
.fad.#{$fa-css-prefix}-truck-bolt:after, .fa-duotone.#{$fa-css-prefix}-truck-bolt:after { content: fa-content(\e3d0\fe02); }
.fad.#{$fa-css-prefix}-truck-clock:before, .fa-duotone.#{$fa-css-prefix}-truck-clock:before { content: fa-content(\f48c\fe01); }
.fad.#{$fa-css-prefix}-truck-clock:after, .fa-duotone.#{$fa-css-prefix}-truck-clock:after { content: fa-content(\f48c\fe02); }
.fad.#{$fa-css-prefix}-shipping-timed:before, .fa-duotone.#{$fa-css-prefix}-shipping-timed:before { content: fa-content(\f48c\fe01); }
.fad.#{$fa-css-prefix}-shipping-timed:after, .fa-duotone.#{$fa-css-prefix}-shipping-timed:after { content: fa-content(\f48c\fe02); }
.fad.#{$fa-css-prefix}-truck-container:before, .fa-duotone.#{$fa-css-prefix}-truck-container:before { content: fa-content(\f4dc\fe01); }
.fad.#{$fa-css-prefix}-truck-container:after, .fa-duotone.#{$fa-css-prefix}-truck-container:after { content: fa-content(\f4dc\fe02); }
.fad.#{$fa-css-prefix}-truck-container-empty:before, .fa-duotone.#{$fa-css-prefix}-truck-container-empty:before { content: fa-content(\e2b5\fe01); }
.fad.#{$fa-css-prefix}-truck-container-empty:after, .fa-duotone.#{$fa-css-prefix}-truck-container-empty:after { content: fa-content(\e2b5\fe02); }
.fad.#{$fa-css-prefix}-truck-fast:before, .fa-duotone.#{$fa-css-prefix}-truck-fast:before { content: fa-content(\f48b\fe01); }
.fad.#{$fa-css-prefix}-truck-fast:after, .fa-duotone.#{$fa-css-prefix}-truck-fast:after { content: fa-content(\f48b\fe02); }
.fad.#{$fa-css-prefix}-shipping-fast:before, .fa-duotone.#{$fa-css-prefix}-shipping-fast:before { content: fa-content(\f48b\fe01); }
.fad.#{$fa-css-prefix}-shipping-fast:after, .fa-duotone.#{$fa-css-prefix}-shipping-fast:after { content: fa-content(\f48b\fe02); }
.fad.#{$fa-css-prefix}-truck-flatbed:before, .fa-duotone.#{$fa-css-prefix}-truck-flatbed:before { content: fa-content(\e2b6\fe01); }
.fad.#{$fa-css-prefix}-truck-flatbed:after, .fa-duotone.#{$fa-css-prefix}-truck-flatbed:after { content: fa-content(\e2b6\fe02); }
.fad.#{$fa-css-prefix}-truck-front:before, .fa-duotone.#{$fa-css-prefix}-truck-front:before { content: fa-content(\e2b7\fe01); }
.fad.#{$fa-css-prefix}-truck-front:after, .fa-duotone.#{$fa-css-prefix}-truck-front:after { content: fa-content(\e2b7\fe02); }
.fad.#{$fa-css-prefix}-truck-medical:before, .fa-duotone.#{$fa-css-prefix}-truck-medical:before { content: fa-content(\f0f9); }
.fad.#{$fa-css-prefix}-truck-medical:after, .fa-duotone.#{$fa-css-prefix}-truck-medical:after { content: fa-content(\10f0f9); }
.fad.#{$fa-css-prefix}-ambulance:before, .fa-duotone.#{$fa-css-prefix}-ambulance:before { content: fa-content(\f0f9); }
.fad.#{$fa-css-prefix}-ambulance:after, .fa-duotone.#{$fa-css-prefix}-ambulance:after { content: fa-content(\10f0f9); }
.fad.#{$fa-css-prefix}-truck-monster:before, .fa-duotone.#{$fa-css-prefix}-truck-monster:before { content: fa-content(\f63b\fe01); }
.fad.#{$fa-css-prefix}-truck-monster:after, .fa-duotone.#{$fa-css-prefix}-truck-monster:after { content: fa-content(\f63b\fe02); }
.fad.#{$fa-css-prefix}-truck-moving:before, .fa-duotone.#{$fa-css-prefix}-truck-moving:before { content: fa-content(\f4df\fe01); }
.fad.#{$fa-css-prefix}-truck-moving:after, .fa-duotone.#{$fa-css-prefix}-truck-moving:after { content: fa-content(\f4df\fe02); }
.fad.#{$fa-css-prefix}-truck-pickup:before, .fa-duotone.#{$fa-css-prefix}-truck-pickup:before { content: fa-content(\f63c); }
.fad.#{$fa-css-prefix}-truck-pickup:after, .fa-duotone.#{$fa-css-prefix}-truck-pickup:after { content: fa-content(\10f63c); }
.fad.#{$fa-css-prefix}-truck-plow:before, .fa-duotone.#{$fa-css-prefix}-truck-plow:before { content: fa-content(\f7de\fe01); }
.fad.#{$fa-css-prefix}-truck-plow:after, .fa-duotone.#{$fa-css-prefix}-truck-plow:after { content: fa-content(\f7de\fe02); }
.fad.#{$fa-css-prefix}-truck-ramp:before, .fa-duotone.#{$fa-css-prefix}-truck-ramp:before { content: fa-content(\f4e0\fe01); }
.fad.#{$fa-css-prefix}-truck-ramp:after, .fa-duotone.#{$fa-css-prefix}-truck-ramp:after { content: fa-content(\f4e0\fe02); }
.fad.#{$fa-css-prefix}-truck-ramp-box:before, .fa-duotone.#{$fa-css-prefix}-truck-ramp-box:before { content: fa-content(\f4de\fe01); }
.fad.#{$fa-css-prefix}-truck-ramp-box:after, .fa-duotone.#{$fa-css-prefix}-truck-ramp-box:after { content: fa-content(\f4de\fe02); }
.fad.#{$fa-css-prefix}-truck-loading:before, .fa-duotone.#{$fa-css-prefix}-truck-loading:before { content: fa-content(\f4de\fe01); }
.fad.#{$fa-css-prefix}-truck-loading:after, .fa-duotone.#{$fa-css-prefix}-truck-loading:after { content: fa-content(\f4de\fe02); }
.fad.#{$fa-css-prefix}-truck-ramp-couch:before, .fa-duotone.#{$fa-css-prefix}-truck-ramp-couch:before { content: fa-content(\f4dd\fe01); }
.fad.#{$fa-css-prefix}-truck-ramp-couch:after, .fa-duotone.#{$fa-css-prefix}-truck-ramp-couch:after { content: fa-content(\f4dd\fe02); }
.fad.#{$fa-css-prefix}-truck-couch:before, .fa-duotone.#{$fa-css-prefix}-truck-couch:before { content: fa-content(\f4dd\fe01); }
.fad.#{$fa-css-prefix}-truck-couch:after, .fa-duotone.#{$fa-css-prefix}-truck-couch:after { content: fa-content(\f4dd\fe02); }
.fad.#{$fa-css-prefix}-truck-tow:before, .fa-duotone.#{$fa-css-prefix}-truck-tow:before { content: fa-content(\e2b8\fe01); }
.fad.#{$fa-css-prefix}-truck-tow:after, .fa-duotone.#{$fa-css-prefix}-truck-tow:after { content: fa-content(\e2b8\fe02); }
.fad.#{$fa-css-prefix}-trumpet:before, .fa-duotone.#{$fa-css-prefix}-trumpet:before { content: fa-content(\f8e3); }
.fad.#{$fa-css-prefix}-trumpet:after, .fa-duotone.#{$fa-css-prefix}-trumpet:after { content: fa-content(\10f8e3); }
.fad.#{$fa-css-prefix}-tty:before, .fa-duotone.#{$fa-css-prefix}-tty:before { content: fa-content(\f1e4\fe01); }
.fad.#{$fa-css-prefix}-tty:after, .fa-duotone.#{$fa-css-prefix}-tty:after { content: fa-content(\f1e4\fe02); }
.fad.#{$fa-css-prefix}-teletype:before, .fa-duotone.#{$fa-css-prefix}-teletype:before { content: fa-content(\f1e4\fe01); }
.fad.#{$fa-css-prefix}-teletype:after, .fa-duotone.#{$fa-css-prefix}-teletype:after { content: fa-content(\f1e4\fe02); }
.fad.#{$fa-css-prefix}-tty-answer:before, .fa-duotone.#{$fa-css-prefix}-tty-answer:before { content: fa-content(\e2b9\fe01); }
.fad.#{$fa-css-prefix}-tty-answer:after, .fa-duotone.#{$fa-css-prefix}-tty-answer:after { content: fa-content(\e2b9\fe02); }
.fad.#{$fa-css-prefix}-teletype-answer:before, .fa-duotone.#{$fa-css-prefix}-teletype-answer:before { content: fa-content(\e2b9\fe01); }
.fad.#{$fa-css-prefix}-teletype-answer:after, .fa-duotone.#{$fa-css-prefix}-teletype-answer:after { content: fa-content(\e2b9\fe02); }
.fad.#{$fa-css-prefix}-tugrik-sign:before, .fa-duotone.#{$fa-css-prefix}-tugrik-sign:before { content: fa-content(\e2ba\fe01); }
.fad.#{$fa-css-prefix}-tugrik-sign:after, .fa-duotone.#{$fa-css-prefix}-tugrik-sign:after { content: fa-content(\e2ba\fe02); }
.fad.#{$fa-css-prefix}-turkey:before, .fa-duotone.#{$fa-css-prefix}-turkey:before { content: fa-content(\f725\fe01); }
.fad.#{$fa-css-prefix}-turkey:after, .fa-duotone.#{$fa-css-prefix}-turkey:after { content: fa-content(\f725\fe02); }
.fad.#{$fa-css-prefix}-turkish-lira-sign:before, .fa-duotone.#{$fa-css-prefix}-turkish-lira-sign:before { content: fa-content(\e2bb\fe01); }
.fad.#{$fa-css-prefix}-turkish-lira-sign:after, .fa-duotone.#{$fa-css-prefix}-turkish-lira-sign:after { content: fa-content(\e2bb\fe02); }
.fad.#{$fa-css-prefix}-try:before, .fa-duotone.#{$fa-css-prefix}-try:before { content: fa-content(\e2bb\fe01); }
.fad.#{$fa-css-prefix}-try:after, .fa-duotone.#{$fa-css-prefix}-try:after { content: fa-content(\e2bb\fe02); }
.fad.#{$fa-css-prefix}-turkish-lira:before, .fa-duotone.#{$fa-css-prefix}-turkish-lira:before { content: fa-content(\e2bb\fe01); }
.fad.#{$fa-css-prefix}-turkish-lira:after, .fa-duotone.#{$fa-css-prefix}-turkish-lira:after { content: fa-content(\e2bb\fe02); }
.fad.#{$fa-css-prefix}-turn-down:before, .fa-duotone.#{$fa-css-prefix}-turn-down:before { content: fa-content(\2935\fe01); }
.fad.#{$fa-css-prefix}-turn-down:after, .fa-duotone.#{$fa-css-prefix}-turn-down:after { content: fa-content(\2935\fe02); }
.fad.#{$fa-css-prefix}-level-down-alt:before, .fa-duotone.#{$fa-css-prefix}-level-down-alt:before { content: fa-content(\2935\fe01); }
.fad.#{$fa-css-prefix}-level-down-alt:after, .fa-duotone.#{$fa-css-prefix}-level-down-alt:after { content: fa-content(\2935\fe02); }
.fad.#{$fa-css-prefix}-turn-down-left:before, .fa-duotone.#{$fa-css-prefix}-turn-down-left:before { content: fa-content(\e331\fe01); }
.fad.#{$fa-css-prefix}-turn-down-left:after, .fa-duotone.#{$fa-css-prefix}-turn-down-left:after { content: fa-content(\e331\fe02); }
.fad.#{$fa-css-prefix}-turn-up:before, .fa-duotone.#{$fa-css-prefix}-turn-up:before { content: fa-content(\2934\fe01); }
.fad.#{$fa-css-prefix}-turn-up:after, .fa-duotone.#{$fa-css-prefix}-turn-up:after { content: fa-content(\2934\fe02); }
.fad.#{$fa-css-prefix}-level-up-alt:before, .fa-duotone.#{$fa-css-prefix}-level-up-alt:before { content: fa-content(\2934\fe01); }
.fad.#{$fa-css-prefix}-level-up-alt:after, .fa-duotone.#{$fa-css-prefix}-level-up-alt:after { content: fa-content(\2934\fe02); }
.fad.#{$fa-css-prefix}-turntable:before, .fa-duotone.#{$fa-css-prefix}-turntable:before { content: fa-content(\f8e4\fe01); }
.fad.#{$fa-css-prefix}-turntable:after, .fa-duotone.#{$fa-css-prefix}-turntable:after { content: fa-content(\f8e4\fe02); }
.fad.#{$fa-css-prefix}-turtle:before, .fa-duotone.#{$fa-css-prefix}-turtle:before { content: fa-content(\f726); }
.fad.#{$fa-css-prefix}-turtle:after, .fa-duotone.#{$fa-css-prefix}-turtle:after { content: fa-content(\10f726); }
.fad.#{$fa-css-prefix}-tv:before, .fa-duotone.#{$fa-css-prefix}-tv:before { content: fa-content(\f26c\fe01); }
.fad.#{$fa-css-prefix}-tv:after, .fa-duotone.#{$fa-css-prefix}-tv:after { content: fa-content(\f26c\fe02); }
.fad.#{$fa-css-prefix}-television:before, .fa-duotone.#{$fa-css-prefix}-television:before { content: fa-content(\f26c\fe01); }
.fad.#{$fa-css-prefix}-television:after, .fa-duotone.#{$fa-css-prefix}-television:after { content: fa-content(\f26c\fe02); }
.fad.#{$fa-css-prefix}-tv-alt:before, .fa-duotone.#{$fa-css-prefix}-tv-alt:before { content: fa-content(\f26c\fe01); }
.fad.#{$fa-css-prefix}-tv-alt:after, .fa-duotone.#{$fa-css-prefix}-tv-alt:after { content: fa-content(\f26c\fe02); }
.fad.#{$fa-css-prefix}-tv-music:before, .fa-duotone.#{$fa-css-prefix}-tv-music:before { content: fa-content(\f8e6\fe01); }
.fad.#{$fa-css-prefix}-tv-music:after, .fa-duotone.#{$fa-css-prefix}-tv-music:after { content: fa-content(\f8e6\fe02); }
.fad.#{$fa-css-prefix}-tv-retro:before, .fa-duotone.#{$fa-css-prefix}-tv-retro:before { content: fa-content(\f401); }
.fad.#{$fa-css-prefix}-tv-retro:after, .fa-duotone.#{$fa-css-prefix}-tv-retro:after { content: fa-content(\10f401); }
.fad.#{$fa-css-prefix}-typewriter:before, .fa-duotone.#{$fa-css-prefix}-typewriter:before { content: fa-content(\f8e7\fe01); }
.fad.#{$fa-css-prefix}-typewriter:after, .fa-duotone.#{$fa-css-prefix}-typewriter:after { content: fa-content(\f8e7\fe02); }
.fad.#{$fa-css-prefix}-u:before, .fa-duotone.#{$fa-css-prefix}-u:before { content: fa-content(\55\fe01); }
.fad.#{$fa-css-prefix}-u:after, .fa-duotone.#{$fa-css-prefix}-u:after { content: fa-content(\55\fe02); }
.fad.#{$fa-css-prefix}-ufo:before, .fa-duotone.#{$fa-css-prefix}-ufo:before { content: fa-content(\e047); }
.fad.#{$fa-css-prefix}-ufo:after, .fa-duotone.#{$fa-css-prefix}-ufo:after { content: fa-content(\10e047); }
.fad.#{$fa-css-prefix}-ufo-beam:before, .fa-duotone.#{$fa-css-prefix}-ufo-beam:before { content: fa-content(\e048\fe01); }
.fad.#{$fa-css-prefix}-ufo-beam:after, .fa-duotone.#{$fa-css-prefix}-ufo-beam:after { content: fa-content(\e048\fe02); }
.fad.#{$fa-css-prefix}-umbrella:before, .fa-duotone.#{$fa-css-prefix}-umbrella:before { content: fa-content(\f0e9\fe01); }
.fad.#{$fa-css-prefix}-umbrella:after, .fa-duotone.#{$fa-css-prefix}-umbrella:after { content: fa-content(\f0e9\fe02); }
.fad.#{$fa-css-prefix}-umbrella-beach:before, .fa-duotone.#{$fa-css-prefix}-umbrella-beach:before { content: fa-content(\f5ca); }
.fad.#{$fa-css-prefix}-umbrella-beach:after, .fa-duotone.#{$fa-css-prefix}-umbrella-beach:after { content: fa-content(\10f5ca); }
.fad.#{$fa-css-prefix}-umbrella-simple:before, .fa-duotone.#{$fa-css-prefix}-umbrella-simple:before { content: fa-content(\e2bc\fe01); }
.fad.#{$fa-css-prefix}-umbrella-simple:after, .fa-duotone.#{$fa-css-prefix}-umbrella-simple:after { content: fa-content(\e2bc\fe02); }
.fad.#{$fa-css-prefix}-umbrella-alt:before, .fa-duotone.#{$fa-css-prefix}-umbrella-alt:before { content: fa-content(\e2bc\fe01); }
.fad.#{$fa-css-prefix}-umbrella-alt:after, .fa-duotone.#{$fa-css-prefix}-umbrella-alt:after { content: fa-content(\e2bc\fe02); }
.fad.#{$fa-css-prefix}-underline:before, .fa-duotone.#{$fa-css-prefix}-underline:before { content: fa-content(\f0cd\fe01); }
.fad.#{$fa-css-prefix}-underline:after, .fa-duotone.#{$fa-css-prefix}-underline:after { content: fa-content(\f0cd\fe02); }
.fad.#{$fa-css-prefix}-unicorn:before, .fa-duotone.#{$fa-css-prefix}-unicorn:before { content: fa-content(\f727); }
.fad.#{$fa-css-prefix}-unicorn:after, .fa-duotone.#{$fa-css-prefix}-unicorn:after { content: fa-content(\10f727); }
.fad.#{$fa-css-prefix}-uniform-martial-arts:before, .fa-duotone.#{$fa-css-prefix}-uniform-martial-arts:before { content: fa-content(\e3d1\fe01); }
.fad.#{$fa-css-prefix}-uniform-martial-arts:after, .fa-duotone.#{$fa-css-prefix}-uniform-martial-arts:after { content: fa-content(\e3d1\fe02); }
.fad.#{$fa-css-prefix}-union:before, .fa-duotone.#{$fa-css-prefix}-union:before { content: fa-content(\22c3\fe01); }
.fad.#{$fa-css-prefix}-union:after, .fa-duotone.#{$fa-css-prefix}-union:after { content: fa-content(\22c3\fe02); }
.fad.#{$fa-css-prefix}-universal-access:before, .fa-duotone.#{$fa-css-prefix}-universal-access:before { content: fa-content(\f29a\fe01); }
.fad.#{$fa-css-prefix}-universal-access:after, .fa-duotone.#{$fa-css-prefix}-universal-access:after { content: fa-content(\f29a\fe02); }
.fad.#{$fa-css-prefix}-unlock:before, .fa-duotone.#{$fa-css-prefix}-unlock:before { content: fa-content(\f09c); }
.fad.#{$fa-css-prefix}-unlock:after, .fa-duotone.#{$fa-css-prefix}-unlock:after { content: fa-content(\10f09c); }
.fad.#{$fa-css-prefix}-unlock-keyhole:before, .fa-duotone.#{$fa-css-prefix}-unlock-keyhole:before { content: fa-content(\f13e\fe01); }
.fad.#{$fa-css-prefix}-unlock-keyhole:after, .fa-duotone.#{$fa-css-prefix}-unlock-keyhole:after { content: fa-content(\f13e\fe02); }
.fad.#{$fa-css-prefix}-unlock-alt:before, .fa-duotone.#{$fa-css-prefix}-unlock-alt:before { content: fa-content(\f13e\fe01); }
.fad.#{$fa-css-prefix}-unlock-alt:after, .fa-duotone.#{$fa-css-prefix}-unlock-alt:after { content: fa-content(\f13e\fe02); }
.fad.#{$fa-css-prefix}-up:before, .fa-duotone.#{$fa-css-prefix}-up:before { content: fa-content(\f357\fe01); }
.fad.#{$fa-css-prefix}-up:after, .fa-duotone.#{$fa-css-prefix}-up:after { content: fa-content(\f357\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-up:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-up:before { content: fa-content(\f357\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-up:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-up:after { content: fa-content(\f357\fe02); }
.fad.#{$fa-css-prefix}-up-down:before, .fa-duotone.#{$fa-css-prefix}-up-down:before { content: fa-content(\2195\fe01); }
.fad.#{$fa-css-prefix}-up-down:after, .fa-duotone.#{$fa-css-prefix}-up-down:after { content: fa-content(\2195\fe02); }
.fad.#{$fa-css-prefix}-arrows-alt-v:before, .fa-duotone.#{$fa-css-prefix}-arrows-alt-v:before { content: fa-content(\2195\fe01); }
.fad.#{$fa-css-prefix}-arrows-alt-v:after, .fa-duotone.#{$fa-css-prefix}-arrows-alt-v:after { content: fa-content(\2195\fe02); }
.fad.#{$fa-css-prefix}-up-down-left-right:before, .fa-duotone.#{$fa-css-prefix}-up-down-left-right:before { content: fa-content(\f0b2\fe01); }
.fad.#{$fa-css-prefix}-up-down-left-right:after, .fa-duotone.#{$fa-css-prefix}-up-down-left-right:after { content: fa-content(\f0b2\fe02); }
.fad.#{$fa-css-prefix}-arrows-alt:before, .fa-duotone.#{$fa-css-prefix}-arrows-alt:before { content: fa-content(\f0b2\fe01); }
.fad.#{$fa-css-prefix}-arrows-alt:after, .fa-duotone.#{$fa-css-prefix}-arrows-alt:after { content: fa-content(\f0b2\fe02); }
.fad.#{$fa-css-prefix}-up-from-line:before, .fa-duotone.#{$fa-css-prefix}-up-from-line:before { content: fa-content(\f346\fe01); }
.fad.#{$fa-css-prefix}-up-from-line:after, .fa-duotone.#{$fa-css-prefix}-up-from-line:after { content: fa-content(\f346\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-from-bottom:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-bottom:before { content: fa-content(\f346\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-from-bottom:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-bottom:after { content: fa-content(\f346\fe02); }
.fad.#{$fa-css-prefix}-up-left:before, .fa-duotone.#{$fa-css-prefix}-up-left:before { content: fa-content(\e2bd\fe01); }
.fad.#{$fa-css-prefix}-up-left:after, .fa-duotone.#{$fa-css-prefix}-up-left:after { content: fa-content(\e2bd\fe02); }
.fad.#{$fa-css-prefix}-up-long:before, .fa-duotone.#{$fa-css-prefix}-up-long:before { content: fa-content(\f30c\fe01); }
.fad.#{$fa-css-prefix}-up-long:after, .fa-duotone.#{$fa-css-prefix}-up-long:after { content: fa-content(\f30c\fe02); }
.fad.#{$fa-css-prefix}-long-arrow-alt-up:before, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-up:before { content: fa-content(\f30c\fe01); }
.fad.#{$fa-css-prefix}-long-arrow-alt-up:after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-up:after { content: fa-content(\f30c\fe02); }
.fad.#{$fa-css-prefix}-up-right:before, .fa-duotone.#{$fa-css-prefix}-up-right:before { content: fa-content(\e2be\fe01); }
.fad.#{$fa-css-prefix}-up-right:after, .fa-duotone.#{$fa-css-prefix}-up-right:after { content: fa-content(\e2be\fe02); }
.fad.#{$fa-css-prefix}-up-right-and-down-left-from-center:before, .fa-duotone.#{$fa-css-prefix}-up-right-and-down-left-from-center:before { content: fa-content(\f424\fe01); }
.fad.#{$fa-css-prefix}-up-right-and-down-left-from-center:after, .fa-duotone.#{$fa-css-prefix}-up-right-and-down-left-from-center:after { content: fa-content(\f424\fe02); }
.fad.#{$fa-css-prefix}-expand-alt:before, .fa-duotone.#{$fa-css-prefix}-expand-alt:before { content: fa-content(\f424\fe01); }
.fad.#{$fa-css-prefix}-expand-alt:after, .fa-duotone.#{$fa-css-prefix}-expand-alt:after { content: fa-content(\f424\fe02); }
.fad.#{$fa-css-prefix}-up-right-from-square:before, .fa-duotone.#{$fa-css-prefix}-up-right-from-square:before { content: fa-content(\f35d\fe01); }
.fad.#{$fa-css-prefix}-up-right-from-square:after, .fa-duotone.#{$fa-css-prefix}-up-right-from-square:after { content: fa-content(\f35d\fe02); }
.fad.#{$fa-css-prefix}-external-link-alt:before, .fa-duotone.#{$fa-css-prefix}-external-link-alt:before { content: fa-content(\f35d\fe01); }
.fad.#{$fa-css-prefix}-external-link-alt:after, .fa-duotone.#{$fa-css-prefix}-external-link-alt:after { content: fa-content(\f35d\fe02); }
.fad.#{$fa-css-prefix}-up-to-line:before, .fa-duotone.#{$fa-css-prefix}-up-to-line:before { content: fa-content(\f34d\fe01); }
.fad.#{$fa-css-prefix}-up-to-line:after, .fa-duotone.#{$fa-css-prefix}-up-to-line:after { content: fa-content(\f34d\fe02); }
.fad.#{$fa-css-prefix}-arrow-alt-to-top:before, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-top:before { content: fa-content(\f34d\fe01); }
.fad.#{$fa-css-prefix}-arrow-alt-to-top:after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-top:after { content: fa-content(\f34d\fe02); }
.fad.#{$fa-css-prefix}-upload:before, .fa-duotone.#{$fa-css-prefix}-upload:before { content: fa-content(\f093\fe01); }
.fad.#{$fa-css-prefix}-upload:after, .fa-duotone.#{$fa-css-prefix}-upload:after { content: fa-content(\f093\fe02); }
.fad.#{$fa-css-prefix}-usb-drive:before, .fa-duotone.#{$fa-css-prefix}-usb-drive:before { content: fa-content(\f8e9\fe01); }
.fad.#{$fa-css-prefix}-usb-drive:after, .fa-duotone.#{$fa-css-prefix}-usb-drive:after { content: fa-content(\f8e9\fe02); }
.fad.#{$fa-css-prefix}-user:before, .fa-duotone.#{$fa-css-prefix}-user:before { content: fa-content(\f007); }
.fad.#{$fa-css-prefix}-user:after, .fa-duotone.#{$fa-css-prefix}-user:after { content: fa-content(\10f007); }
.fad.#{$fa-css-prefix}-user-alien:before, .fa-duotone.#{$fa-css-prefix}-user-alien:before { content: fa-content(\e04a\fe01); }
.fad.#{$fa-css-prefix}-user-alien:after, .fa-duotone.#{$fa-css-prefix}-user-alien:after { content: fa-content(\e04a\fe02); }
.fad.#{$fa-css-prefix}-user-astronaut:before, .fa-duotone.#{$fa-css-prefix}-user-astronaut:before { content: fa-content(\f4fb\fe01); }
.fad.#{$fa-css-prefix}-user-astronaut:after, .fa-duotone.#{$fa-css-prefix}-user-astronaut:after { content: fa-content(\f4fb\fe02); }
.fad.#{$fa-css-prefix}-user-bounty-hunter:before, .fa-duotone.#{$fa-css-prefix}-user-bounty-hunter:before { content: fa-content(\e2bf\fe01); }
.fad.#{$fa-css-prefix}-user-bounty-hunter:after, .fa-duotone.#{$fa-css-prefix}-user-bounty-hunter:after { content: fa-content(\e2bf\fe02); }
.fad.#{$fa-css-prefix}-user-check:before, .fa-duotone.#{$fa-css-prefix}-user-check:before { content: fa-content(\f4fc\fe01); }
.fad.#{$fa-css-prefix}-user-check:after, .fa-duotone.#{$fa-css-prefix}-user-check:after { content: fa-content(\f4fc\fe02); }
.fad.#{$fa-css-prefix}-user-chef:before, .fa-duotone.#{$fa-css-prefix}-user-chef:before { content: fa-content(\e3d2\fe01); }
.fad.#{$fa-css-prefix}-user-chef:after, .fa-duotone.#{$fa-css-prefix}-user-chef:after { content: fa-content(\e3d2\fe02); }
.fad.#{$fa-css-prefix}-user-clock:before, .fa-duotone.#{$fa-css-prefix}-user-clock:before { content: fa-content(\f4fd\fe01); }
.fad.#{$fa-css-prefix}-user-clock:after, .fa-duotone.#{$fa-css-prefix}-user-clock:after { content: fa-content(\f4fd\fe02); }
.fad.#{$fa-css-prefix}-user-cowboy:before, .fa-duotone.#{$fa-css-prefix}-user-cowboy:before { content: fa-content(\f8ea\fe01); }
.fad.#{$fa-css-prefix}-user-cowboy:after, .fa-duotone.#{$fa-css-prefix}-user-cowboy:after { content: fa-content(\f8ea\fe02); }
.fad.#{$fa-css-prefix}-user-crown:before, .fa-duotone.#{$fa-css-prefix}-user-crown:before { content: fa-content(\f6a4\fe01); }
.fad.#{$fa-css-prefix}-user-crown:after, .fa-duotone.#{$fa-css-prefix}-user-crown:after { content: fa-content(\f6a4\fe02); }
.fad.#{$fa-css-prefix}-user-doctor:before, .fa-duotone.#{$fa-css-prefix}-user-doctor:before { content: fa-content(\f0f0\fe01); }
.fad.#{$fa-css-prefix}-user-doctor:after, .fa-duotone.#{$fa-css-prefix}-user-doctor:after { content: fa-content(\f0f0\fe02); }
.fad.#{$fa-css-prefix}-user-md:before, .fa-duotone.#{$fa-css-prefix}-user-md:before { content: fa-content(\f0f0\fe01); }
.fad.#{$fa-css-prefix}-user-md:after, .fa-duotone.#{$fa-css-prefix}-user-md:after { content: fa-content(\f0f0\fe02); }
.fad.#{$fa-css-prefix}-user-doctor-message:before, .fa-duotone.#{$fa-css-prefix}-user-doctor-message:before { content: fa-content(\f82e\fe01); }
.fad.#{$fa-css-prefix}-user-doctor-message:after, .fa-duotone.#{$fa-css-prefix}-user-doctor-message:after { content: fa-content(\f82e\fe02); }
.fad.#{$fa-css-prefix}-user-md-chat:before, .fa-duotone.#{$fa-css-prefix}-user-md-chat:before { content: fa-content(\f82e\fe01); }
.fad.#{$fa-css-prefix}-user-md-chat:after, .fa-duotone.#{$fa-css-prefix}-user-md-chat:after { content: fa-content(\f82e\fe02); }
.fad.#{$fa-css-prefix}-user-gear:before, .fa-duotone.#{$fa-css-prefix}-user-gear:before { content: fa-content(\f4fe\fe01); }
.fad.#{$fa-css-prefix}-user-gear:after, .fa-duotone.#{$fa-css-prefix}-user-gear:after { content: fa-content(\f4fe\fe02); }
.fad.#{$fa-css-prefix}-user-cog:before, .fa-duotone.#{$fa-css-prefix}-user-cog:before { content: fa-content(\f4fe\fe01); }
.fad.#{$fa-css-prefix}-user-cog:after, .fa-duotone.#{$fa-css-prefix}-user-cog:after { content: fa-content(\f4fe\fe02); }
.fad.#{$fa-css-prefix}-user-graduate:before, .fa-duotone.#{$fa-css-prefix}-user-graduate:before { content: fa-content(\f501\fe01); }
.fad.#{$fa-css-prefix}-user-graduate:after, .fa-duotone.#{$fa-css-prefix}-user-graduate:after { content: fa-content(\f501\fe02); }
.fad.#{$fa-css-prefix}-user-group:before, .fa-duotone.#{$fa-css-prefix}-user-group:before { content: fa-content(\f500); }
.fad.#{$fa-css-prefix}-user-group:after, .fa-duotone.#{$fa-css-prefix}-user-group:after { content: fa-content(\10f500); }
.fad.#{$fa-css-prefix}-user-friends:before, .fa-duotone.#{$fa-css-prefix}-user-friends:before { content: fa-content(\f500); }
.fad.#{$fa-css-prefix}-user-friends:after, .fa-duotone.#{$fa-css-prefix}-user-friends:after { content: fa-content(\10f500); }
.fad.#{$fa-css-prefix}-user-group-crown:before, .fa-duotone.#{$fa-css-prefix}-user-group-crown:before { content: fa-content(\f6a5\fe01); }
.fad.#{$fa-css-prefix}-user-group-crown:after, .fa-duotone.#{$fa-css-prefix}-user-group-crown:after { content: fa-content(\f6a5\fe02); }
.fad.#{$fa-css-prefix}-users-crown:before, .fa-duotone.#{$fa-css-prefix}-users-crown:before { content: fa-content(\f6a5\fe01); }
.fad.#{$fa-css-prefix}-users-crown:after, .fa-duotone.#{$fa-css-prefix}-users-crown:after { content: fa-content(\f6a5\fe02); }
.fad.#{$fa-css-prefix}-user-hair-buns:before, .fa-duotone.#{$fa-css-prefix}-user-hair-buns:before { content: fa-content(\e3d3\fe01); }
.fad.#{$fa-css-prefix}-user-hair-buns:after, .fa-duotone.#{$fa-css-prefix}-user-hair-buns:after { content: fa-content(\e3d3\fe02); }
.fad.#{$fa-css-prefix}-user-headset:before, .fa-duotone.#{$fa-css-prefix}-user-headset:before { content: fa-content(\f82d\fe01); }
.fad.#{$fa-css-prefix}-user-headset:after, .fa-duotone.#{$fa-css-prefix}-user-headset:after { content: fa-content(\f82d\fe02); }
.fad.#{$fa-css-prefix}-user-helmet-safety:before, .fa-duotone.#{$fa-css-prefix}-user-helmet-safety:before { content: fa-content(\f82c); }
.fad.#{$fa-css-prefix}-user-helmet-safety:after, .fa-duotone.#{$fa-css-prefix}-user-helmet-safety:after { content: fa-content(\10f82c); }
.fad.#{$fa-css-prefix}-user-construction:before, .fa-duotone.#{$fa-css-prefix}-user-construction:before { content: fa-content(\f82c); }
.fad.#{$fa-css-prefix}-user-construction:after, .fa-duotone.#{$fa-css-prefix}-user-construction:after { content: fa-content(\10f82c); }
.fad.#{$fa-css-prefix}-user-hard-hat:before, .fa-duotone.#{$fa-css-prefix}-user-hard-hat:before { content: fa-content(\f82c); }
.fad.#{$fa-css-prefix}-user-hard-hat:after, .fa-duotone.#{$fa-css-prefix}-user-hard-hat:after { content: fa-content(\10f82c); }
.fad.#{$fa-css-prefix}-user-injured:before, .fa-duotone.#{$fa-css-prefix}-user-injured:before { content: fa-content(\f728\fe01); }
.fad.#{$fa-css-prefix}-user-injured:after, .fa-duotone.#{$fa-css-prefix}-user-injured:after { content: fa-content(\f728\fe02); }
.fad.#{$fa-css-prefix}-user-large:before, .fa-duotone.#{$fa-css-prefix}-user-large:before { content: fa-content(\f406\fe01); }
.fad.#{$fa-css-prefix}-user-large:after, .fa-duotone.#{$fa-css-prefix}-user-large:after { content: fa-content(\f406\fe02); }
.fad.#{$fa-css-prefix}-user-alt:before, .fa-duotone.#{$fa-css-prefix}-user-alt:before { content: fa-content(\f406\fe01); }
.fad.#{$fa-css-prefix}-user-alt:after, .fa-duotone.#{$fa-css-prefix}-user-alt:after { content: fa-content(\f406\fe02); }
.fad.#{$fa-css-prefix}-user-large-slash:before, .fa-duotone.#{$fa-css-prefix}-user-large-slash:before { content: fa-content(\f4fa\fe01); }
.fad.#{$fa-css-prefix}-user-large-slash:after, .fa-duotone.#{$fa-css-prefix}-user-large-slash:after { content: fa-content(\f4fa\fe02); }
.fad.#{$fa-css-prefix}-user-alt-slash:before, .fa-duotone.#{$fa-css-prefix}-user-alt-slash:before { content: fa-content(\f4fa\fe01); }
.fad.#{$fa-css-prefix}-user-alt-slash:after, .fa-duotone.#{$fa-css-prefix}-user-alt-slash:after { content: fa-content(\f4fa\fe02); }
.fad.#{$fa-css-prefix}-user-lock:before, .fa-duotone.#{$fa-css-prefix}-user-lock:before { content: fa-content(\f502\fe01); }
.fad.#{$fa-css-prefix}-user-lock:after, .fa-duotone.#{$fa-css-prefix}-user-lock:after { content: fa-content(\f502\fe02); }
.fad.#{$fa-css-prefix}-user-minus:before, .fa-duotone.#{$fa-css-prefix}-user-minus:before { content: fa-content(\f503\fe01); }
.fad.#{$fa-css-prefix}-user-minus:after, .fa-duotone.#{$fa-css-prefix}-user-minus:after { content: fa-content(\f503\fe02); }
.fad.#{$fa-css-prefix}-user-music:before, .fa-duotone.#{$fa-css-prefix}-user-music:before { content: fa-content(\f8eb\fe01); }
.fad.#{$fa-css-prefix}-user-music:after, .fa-duotone.#{$fa-css-prefix}-user-music:after { content: fa-content(\f8eb\fe02); }
.fad.#{$fa-css-prefix}-user-ninja:before, .fa-duotone.#{$fa-css-prefix}-user-ninja:before { content: fa-content(\f504); }
.fad.#{$fa-css-prefix}-user-ninja:after, .fa-duotone.#{$fa-css-prefix}-user-ninja:after { content: fa-content(\10f504); }
.fad.#{$fa-css-prefix}-user-nurse:before, .fa-duotone.#{$fa-css-prefix}-user-nurse:before { content: fa-content(\f82f\fe01); }
.fad.#{$fa-css-prefix}-user-nurse:after, .fa-duotone.#{$fa-css-prefix}-user-nurse:after { content: fa-content(\f82f\fe02); }
.fad.#{$fa-css-prefix}-user-pen:before, .fa-duotone.#{$fa-css-prefix}-user-pen:before { content: fa-content(\f4ff\fe01); }
.fad.#{$fa-css-prefix}-user-pen:after, .fa-duotone.#{$fa-css-prefix}-user-pen:after { content: fa-content(\f4ff\fe02); }
.fad.#{$fa-css-prefix}-user-edit:before, .fa-duotone.#{$fa-css-prefix}-user-edit:before { content: fa-content(\f4ff\fe01); }
.fad.#{$fa-css-prefix}-user-edit:after, .fa-duotone.#{$fa-css-prefix}-user-edit:after { content: fa-content(\f4ff\fe02); }
.fad.#{$fa-css-prefix}-user-pilot:before, .fa-duotone.#{$fa-css-prefix}-user-pilot:before { content: fa-content(\e2c0\fe01); }
.fad.#{$fa-css-prefix}-user-pilot:after, .fa-duotone.#{$fa-css-prefix}-user-pilot:after { content: fa-content(\e2c0\fe02); }
.fad.#{$fa-css-prefix}-user-pilot-tie:before, .fa-duotone.#{$fa-css-prefix}-user-pilot-tie:before { content: fa-content(\e2c1\fe01); }
.fad.#{$fa-css-prefix}-user-pilot-tie:after, .fa-duotone.#{$fa-css-prefix}-user-pilot-tie:after { content: fa-content(\e2c1\fe02); }
.fad.#{$fa-css-prefix}-user-plus:before, .fa-duotone.#{$fa-css-prefix}-user-plus:before { content: fa-content(\f234\fe01); }
.fad.#{$fa-css-prefix}-user-plus:after, .fa-duotone.#{$fa-css-prefix}-user-plus:after { content: fa-content(\f234\fe02); }
.fad.#{$fa-css-prefix}-user-police:before, .fa-duotone.#{$fa-css-prefix}-user-police:before { content: fa-content(\e333\fe01); }
.fad.#{$fa-css-prefix}-user-police:after, .fa-duotone.#{$fa-css-prefix}-user-police:after { content: fa-content(\e333\fe02); }
.fad.#{$fa-css-prefix}-user-police-tie:before, .fa-duotone.#{$fa-css-prefix}-user-police-tie:before { content: fa-content(\e334\fe01); }
.fad.#{$fa-css-prefix}-user-police-tie:after, .fa-duotone.#{$fa-css-prefix}-user-police-tie:after { content: fa-content(\e334\fe02); }
.fad.#{$fa-css-prefix}-user-robot:before, .fa-duotone.#{$fa-css-prefix}-user-robot:before { content: fa-content(\e04b\fe01); }
.fad.#{$fa-css-prefix}-user-robot:after, .fa-duotone.#{$fa-css-prefix}-user-robot:after { content: fa-content(\e04b\fe02); }
.fad.#{$fa-css-prefix}-user-secret:before, .fa-duotone.#{$fa-css-prefix}-user-secret:before { content: fa-content(\f21b); }
.fad.#{$fa-css-prefix}-user-secret:after, .fa-duotone.#{$fa-css-prefix}-user-secret:after { content: fa-content(\10f21b); }
.fad.#{$fa-css-prefix}-user-shakespeare:before, .fa-duotone.#{$fa-css-prefix}-user-shakespeare:before { content: fa-content(\e2c2\fe01); }
.fad.#{$fa-css-prefix}-user-shakespeare:after, .fa-duotone.#{$fa-css-prefix}-user-shakespeare:after { content: fa-content(\e2c2\fe02); }
.fad.#{$fa-css-prefix}-user-shield:before, .fa-duotone.#{$fa-css-prefix}-user-shield:before { content: fa-content(\f505\fe01); }
.fad.#{$fa-css-prefix}-user-shield:after, .fa-duotone.#{$fa-css-prefix}-user-shield:after { content: fa-content(\f505\fe02); }
.fad.#{$fa-css-prefix}-user-slash:before, .fa-duotone.#{$fa-css-prefix}-user-slash:before { content: fa-content(\f506\fe01); }
.fad.#{$fa-css-prefix}-user-slash:after, .fa-duotone.#{$fa-css-prefix}-user-slash:after { content: fa-content(\f506\fe02); }
.fad.#{$fa-css-prefix}-user-tag:before, .fa-duotone.#{$fa-css-prefix}-user-tag:before { content: fa-content(\f507\fe01); }
.fad.#{$fa-css-prefix}-user-tag:after, .fa-duotone.#{$fa-css-prefix}-user-tag:after { content: fa-content(\f507\fe02); }
.fad.#{$fa-css-prefix}-user-tie:before, .fa-duotone.#{$fa-css-prefix}-user-tie:before { content: fa-content(\f508\fe01); }
.fad.#{$fa-css-prefix}-user-tie:after, .fa-duotone.#{$fa-css-prefix}-user-tie:after { content: fa-content(\f508\fe02); }
.fad.#{$fa-css-prefix}-user-unlock:before, .fa-duotone.#{$fa-css-prefix}-user-unlock:before { content: fa-content(\e058\fe01); }
.fad.#{$fa-css-prefix}-user-unlock:after, .fa-duotone.#{$fa-css-prefix}-user-unlock:after { content: fa-content(\e058\fe02); }
.fad.#{$fa-css-prefix}-user-visor:before, .fa-duotone.#{$fa-css-prefix}-user-visor:before { content: fa-content(\e04c\fe01); }
.fad.#{$fa-css-prefix}-user-visor:after, .fa-duotone.#{$fa-css-prefix}-user-visor:after { content: fa-content(\e04c\fe02); }
.fad.#{$fa-css-prefix}-user-xmark:before, .fa-duotone.#{$fa-css-prefix}-user-xmark:before { content: fa-content(\f235\fe01); }
.fad.#{$fa-css-prefix}-user-xmark:after, .fa-duotone.#{$fa-css-prefix}-user-xmark:after { content: fa-content(\f235\fe02); }
.fad.#{$fa-css-prefix}-user-times:before, .fa-duotone.#{$fa-css-prefix}-user-times:before { content: fa-content(\f235\fe01); }
.fad.#{$fa-css-prefix}-user-times:after, .fa-duotone.#{$fa-css-prefix}-user-times:after { content: fa-content(\f235\fe02); }
.fad.#{$fa-css-prefix}-users:before, .fa-duotone.#{$fa-css-prefix}-users:before { content: fa-content(\f0c0\fe01); }
.fad.#{$fa-css-prefix}-users:after, .fa-duotone.#{$fa-css-prefix}-users:after { content: fa-content(\f0c0\fe02); }
.fad.#{$fa-css-prefix}-group:before, .fa-duotone.#{$fa-css-prefix}-group:before { content: fa-content(\f0c0\fe01); }
.fad.#{$fa-css-prefix}-group:after, .fa-duotone.#{$fa-css-prefix}-group:after { content: fa-content(\f0c0\fe02); }
.fad.#{$fa-css-prefix}-users-gear:before, .fa-duotone.#{$fa-css-prefix}-users-gear:before { content: fa-content(\f509\fe01); }
.fad.#{$fa-css-prefix}-users-gear:after, .fa-duotone.#{$fa-css-prefix}-users-gear:after { content: fa-content(\f509\fe02); }
.fad.#{$fa-css-prefix}-users-cog:before, .fa-duotone.#{$fa-css-prefix}-users-cog:before { content: fa-content(\f509\fe01); }
.fad.#{$fa-css-prefix}-users-cog:after, .fa-duotone.#{$fa-css-prefix}-users-cog:after { content: fa-content(\f509\fe02); }
.fad.#{$fa-css-prefix}-users-medical:before, .fa-duotone.#{$fa-css-prefix}-users-medical:before { content: fa-content(\f830\fe01); }
.fad.#{$fa-css-prefix}-users-medical:after, .fa-duotone.#{$fa-css-prefix}-users-medical:after { content: fa-content(\f830\fe02); }
.fad.#{$fa-css-prefix}-users-slash:before, .fa-duotone.#{$fa-css-prefix}-users-slash:before { content: fa-content(\e073\fe01); }
.fad.#{$fa-css-prefix}-users-slash:after, .fa-duotone.#{$fa-css-prefix}-users-slash:after { content: fa-content(\e073\fe02); }
.fad.#{$fa-css-prefix}-utensils:before, .fa-duotone.#{$fa-css-prefix}-utensils:before { content: fa-content(\f2e7); }
.fad.#{$fa-css-prefix}-utensils:after, .fa-duotone.#{$fa-css-prefix}-utensils:after { content: fa-content(\10f2e7); }
.fad.#{$fa-css-prefix}-cutlery:before, .fa-duotone.#{$fa-css-prefix}-cutlery:before { content: fa-content(\f2e7); }
.fad.#{$fa-css-prefix}-cutlery:after, .fa-duotone.#{$fa-css-prefix}-cutlery:after { content: fa-content(\10f2e7); }
.fad.#{$fa-css-prefix}-utility-pole:before, .fa-duotone.#{$fa-css-prefix}-utility-pole:before { content: fa-content(\e2c3\fe01); }
.fad.#{$fa-css-prefix}-utility-pole:after, .fa-duotone.#{$fa-css-prefix}-utility-pole:after { content: fa-content(\e2c3\fe02); }
.fad.#{$fa-css-prefix}-utility-pole-double:before, .fa-duotone.#{$fa-css-prefix}-utility-pole-double:before { content: fa-content(\e2c4\fe01); }
.fad.#{$fa-css-prefix}-utility-pole-double:after, .fa-duotone.#{$fa-css-prefix}-utility-pole-double:after { content: fa-content(\e2c4\fe02); }
.fad.#{$fa-css-prefix}-v:before, .fa-duotone.#{$fa-css-prefix}-v:before { content: fa-content(\56\fe01); }
.fad.#{$fa-css-prefix}-v:after, .fa-duotone.#{$fa-css-prefix}-v:after { content: fa-content(\56\fe02); }
.fad.#{$fa-css-prefix}-vacuum:before, .fa-duotone.#{$fa-css-prefix}-vacuum:before { content: fa-content(\e04d\fe01); }
.fad.#{$fa-css-prefix}-vacuum:after, .fa-duotone.#{$fa-css-prefix}-vacuum:after { content: fa-content(\e04d\fe02); }
.fad.#{$fa-css-prefix}-vacuum-robot:before, .fa-duotone.#{$fa-css-prefix}-vacuum-robot:before { content: fa-content(\e04e\fe01); }
.fad.#{$fa-css-prefix}-vacuum-robot:after, .fa-duotone.#{$fa-css-prefix}-vacuum-robot:after { content: fa-content(\e04e\fe02); }
.fad.#{$fa-css-prefix}-value-absolute:before, .fa-duotone.#{$fa-css-prefix}-value-absolute:before { content: fa-content(\f6a6\fe01); }
.fad.#{$fa-css-prefix}-value-absolute:after, .fa-duotone.#{$fa-css-prefix}-value-absolute:after { content: fa-content(\f6a6\fe02); }
.fad.#{$fa-css-prefix}-van-shuttle:before, .fa-duotone.#{$fa-css-prefix}-van-shuttle:before { content: fa-content(\f5b6); }
.fad.#{$fa-css-prefix}-van-shuttle:after, .fa-duotone.#{$fa-css-prefix}-van-shuttle:after { content: fa-content(\10f5b6); }
.fad.#{$fa-css-prefix}-shuttle-van:before, .fa-duotone.#{$fa-css-prefix}-shuttle-van:before { content: fa-content(\f5b6); }
.fad.#{$fa-css-prefix}-shuttle-van:after, .fa-duotone.#{$fa-css-prefix}-shuttle-van:after { content: fa-content(\10f5b6); }
.fad.#{$fa-css-prefix}-vault:before, .fa-duotone.#{$fa-css-prefix}-vault:before { content: fa-content(\e2c5\fe01); }
.fad.#{$fa-css-prefix}-vault:after, .fa-duotone.#{$fa-css-prefix}-vault:after { content: fa-content(\e2c5\fe02); }
.fad.#{$fa-css-prefix}-vector-circle:before, .fa-duotone.#{$fa-css-prefix}-vector-circle:before { content: fa-content(\e2c6\fe01); }
.fad.#{$fa-css-prefix}-vector-circle:after, .fa-duotone.#{$fa-css-prefix}-vector-circle:after { content: fa-content(\e2c6\fe02); }
.fad.#{$fa-css-prefix}-vector-polygon:before, .fa-duotone.#{$fa-css-prefix}-vector-polygon:before { content: fa-content(\e2c7\fe01); }
.fad.#{$fa-css-prefix}-vector-polygon:after, .fa-duotone.#{$fa-css-prefix}-vector-polygon:after { content: fa-content(\e2c7\fe02); }
.fad.#{$fa-css-prefix}-vector-square:before, .fa-duotone.#{$fa-css-prefix}-vector-square:before { content: fa-content(\f5cb\fe01); }
.fad.#{$fa-css-prefix}-vector-square:after, .fa-duotone.#{$fa-css-prefix}-vector-square:after { content: fa-content(\f5cb\fe02); }
.fad.#{$fa-css-prefix}-venus:before, .fa-duotone.#{$fa-css-prefix}-venus:before { content: fa-content(\2640\fe01); }
.fad.#{$fa-css-prefix}-venus:after, .fa-duotone.#{$fa-css-prefix}-venus:after { content: fa-content(\2640\fe02); }
.fad.#{$fa-css-prefix}-venus-double:before, .fa-duotone.#{$fa-css-prefix}-venus-double:before { content: fa-content(\26a2\fe01); }
.fad.#{$fa-css-prefix}-venus-double:after, .fa-duotone.#{$fa-css-prefix}-venus-double:after { content: fa-content(\26a2\fe02); }
.fad.#{$fa-css-prefix}-venus-mars:before, .fa-duotone.#{$fa-css-prefix}-venus-mars:before { content: fa-content(\26a4\fe01); }
.fad.#{$fa-css-prefix}-venus-mars:after, .fa-duotone.#{$fa-css-prefix}-venus-mars:after { content: fa-content(\26a4\fe02); }
.fad.#{$fa-css-prefix}-vest:before, .fa-duotone.#{$fa-css-prefix}-vest:before { content: fa-content(\e085\fe01); }
.fad.#{$fa-css-prefix}-vest:after, .fa-duotone.#{$fa-css-prefix}-vest:after { content: fa-content(\e085\fe02); }
.fad.#{$fa-css-prefix}-vest-patches:before, .fa-duotone.#{$fa-css-prefix}-vest-patches:before { content: fa-content(\e086\fe01); }
.fad.#{$fa-css-prefix}-vest-patches:after, .fa-duotone.#{$fa-css-prefix}-vest-patches:after { content: fa-content(\e086\fe02); }
.fad.#{$fa-css-prefix}-vial:before, .fa-duotone.#{$fa-css-prefix}-vial:before { content: fa-content(\f492); }
.fad.#{$fa-css-prefix}-vial:after, .fa-duotone.#{$fa-css-prefix}-vial:after { content: fa-content(\10f492); }
.fad.#{$fa-css-prefix}-vials:before, .fa-duotone.#{$fa-css-prefix}-vials:before { content: fa-content(\f493\fe01); }
.fad.#{$fa-css-prefix}-vials:after, .fa-duotone.#{$fa-css-prefix}-vials:after { content: fa-content(\f493\fe02); }
.fad.#{$fa-css-prefix}-video:before, .fa-duotone.#{$fa-css-prefix}-video:before { content: fa-content(\f03d\fe01); }
.fad.#{$fa-css-prefix}-video:after, .fa-duotone.#{$fa-css-prefix}-video:after { content: fa-content(\f03d\fe02); }
.fad.#{$fa-css-prefix}-video-camera:before, .fa-duotone.#{$fa-css-prefix}-video-camera:before { content: fa-content(\f03d\fe01); }
.fad.#{$fa-css-prefix}-video-camera:after, .fa-duotone.#{$fa-css-prefix}-video-camera:after { content: fa-content(\f03d\fe02); }
.fad.#{$fa-css-prefix}-video-arrow-down-left:before, .fa-duotone.#{$fa-css-prefix}-video-arrow-down-left:before { content: fa-content(\e2c8\fe01); }
.fad.#{$fa-css-prefix}-video-arrow-down-left:after, .fa-duotone.#{$fa-css-prefix}-video-arrow-down-left:after { content: fa-content(\e2c8\fe02); }
.fad.#{$fa-css-prefix}-video-arrow-up-right:before, .fa-duotone.#{$fa-css-prefix}-video-arrow-up-right:before { content: fa-content(\e2c9\fe01); }
.fad.#{$fa-css-prefix}-video-arrow-up-right:after, .fa-duotone.#{$fa-css-prefix}-video-arrow-up-right:after { content: fa-content(\e2c9\fe02); }
.fad.#{$fa-css-prefix}-video-plus:before, .fa-duotone.#{$fa-css-prefix}-video-plus:before { content: fa-content(\f4e1\fe01); }
.fad.#{$fa-css-prefix}-video-plus:after, .fa-duotone.#{$fa-css-prefix}-video-plus:after { content: fa-content(\f4e1\fe02); }
.fad.#{$fa-css-prefix}-video-slash:before, .fa-duotone.#{$fa-css-prefix}-video-slash:before { content: fa-content(\f4e2\fe01); }
.fad.#{$fa-css-prefix}-video-slash:after, .fa-duotone.#{$fa-css-prefix}-video-slash:after { content: fa-content(\f4e2\fe02); }
.fad.#{$fa-css-prefix}-vihara:before, .fa-duotone.#{$fa-css-prefix}-vihara:before { content: fa-content(\f6a7\fe01); }
.fad.#{$fa-css-prefix}-vihara:after, .fa-duotone.#{$fa-css-prefix}-vihara:after { content: fa-content(\f6a7\fe02); }
.fad.#{$fa-css-prefix}-violin:before, .fa-duotone.#{$fa-css-prefix}-violin:before { content: fa-content(\f8ed); }
.fad.#{$fa-css-prefix}-violin:after, .fa-duotone.#{$fa-css-prefix}-violin:after { content: fa-content(\10f8ed); }
.fad.#{$fa-css-prefix}-virus:before, .fa-duotone.#{$fa-css-prefix}-virus:before { content: fa-content(\e074\fe01); }
.fad.#{$fa-css-prefix}-virus:after, .fa-duotone.#{$fa-css-prefix}-virus:after { content: fa-content(\e074\fe02); }
.fad.#{$fa-css-prefix}-virus-slash:before, .fa-duotone.#{$fa-css-prefix}-virus-slash:before { content: fa-content(\e075\fe01); }
.fad.#{$fa-css-prefix}-virus-slash:after, .fa-duotone.#{$fa-css-prefix}-virus-slash:after { content: fa-content(\e075\fe02); }
.fad.#{$fa-css-prefix}-viruses:before, .fa-duotone.#{$fa-css-prefix}-viruses:before { content: fa-content(\e076\fe01); }
.fad.#{$fa-css-prefix}-viruses:after, .fa-duotone.#{$fa-css-prefix}-viruses:after { content: fa-content(\e076\fe02); }
.fad.#{$fa-css-prefix}-voicemail:before, .fa-duotone.#{$fa-css-prefix}-voicemail:before { content: fa-content(\f897\fe01); }
.fad.#{$fa-css-prefix}-voicemail:after, .fa-duotone.#{$fa-css-prefix}-voicemail:after { content: fa-content(\f897\fe02); }
.fad.#{$fa-css-prefix}-volcano:before, .fa-duotone.#{$fa-css-prefix}-volcano:before { content: fa-content(\f770); }
.fad.#{$fa-css-prefix}-volcano:after, .fa-duotone.#{$fa-css-prefix}-volcano:after { content: fa-content(\10f770); }
.fad.#{$fa-css-prefix}-volleyball:before, .fa-duotone.#{$fa-css-prefix}-volleyball:before { content: fa-content(\f45f); }
.fad.#{$fa-css-prefix}-volleyball:after, .fa-duotone.#{$fa-css-prefix}-volleyball:after { content: fa-content(\10f45f); }
.fad.#{$fa-css-prefix}-volleyball-ball:before, .fa-duotone.#{$fa-css-prefix}-volleyball-ball:before { content: fa-content(\f45f); }
.fad.#{$fa-css-prefix}-volleyball-ball:after, .fa-duotone.#{$fa-css-prefix}-volleyball-ball:after { content: fa-content(\10f45f); }
.fad.#{$fa-css-prefix}-volume:before, .fa-duotone.#{$fa-css-prefix}-volume:before { content: fa-content(\f6a8); }
.fad.#{$fa-css-prefix}-volume:after, .fa-duotone.#{$fa-css-prefix}-volume:after { content: fa-content(\10f6a8); }
.fad.#{$fa-css-prefix}-volume-medium:before, .fa-duotone.#{$fa-css-prefix}-volume-medium:before { content: fa-content(\f6a8); }
.fad.#{$fa-css-prefix}-volume-medium:after, .fa-duotone.#{$fa-css-prefix}-volume-medium:after { content: fa-content(\10f6a8); }
.fad.#{$fa-css-prefix}-volume-high:before, .fa-duotone.#{$fa-css-prefix}-volume-high:before { content: fa-content(\f028); }
.fad.#{$fa-css-prefix}-volume-high:after, .fa-duotone.#{$fa-css-prefix}-volume-high:after { content: fa-content(\10f028); }
.fad.#{$fa-css-prefix}-volume-up:before, .fa-duotone.#{$fa-css-prefix}-volume-up:before { content: fa-content(\f028); }
.fad.#{$fa-css-prefix}-volume-up:after, .fa-duotone.#{$fa-css-prefix}-volume-up:after { content: fa-content(\10f028); }
.fad.#{$fa-css-prefix}-volume-low:before, .fa-duotone.#{$fa-css-prefix}-volume-low:before { content: fa-content(\f027); }
.fad.#{$fa-css-prefix}-volume-low:after, .fa-duotone.#{$fa-css-prefix}-volume-low:after { content: fa-content(\10f027); }
.fad.#{$fa-css-prefix}-volume-down:before, .fa-duotone.#{$fa-css-prefix}-volume-down:before { content: fa-content(\f027); }
.fad.#{$fa-css-prefix}-volume-down:after, .fa-duotone.#{$fa-css-prefix}-volume-down:after { content: fa-content(\10f027); }
.fad.#{$fa-css-prefix}-volume-off:before, .fa-duotone.#{$fa-css-prefix}-volume-off:before { content: fa-content(\f026\fe01); }
.fad.#{$fa-css-prefix}-volume-off:after, .fa-duotone.#{$fa-css-prefix}-volume-off:after { content: fa-content(\f026\fe02); }
.fad.#{$fa-css-prefix}-volume-slash:before, .fa-duotone.#{$fa-css-prefix}-volume-slash:before { content: fa-content(\f2e2); }
.fad.#{$fa-css-prefix}-volume-slash:after, .fa-duotone.#{$fa-css-prefix}-volume-slash:after { content: fa-content(\10f2e2); }
.fad.#{$fa-css-prefix}-volume-xmark:before, .fa-duotone.#{$fa-css-prefix}-volume-xmark:before { content: fa-content(\f6a9\fe01); }
.fad.#{$fa-css-prefix}-volume-xmark:after, .fa-duotone.#{$fa-css-prefix}-volume-xmark:after { content: fa-content(\f6a9\fe02); }
.fad.#{$fa-css-prefix}-volume-mute:before, .fa-duotone.#{$fa-css-prefix}-volume-mute:before { content: fa-content(\f6a9\fe01); }
.fad.#{$fa-css-prefix}-volume-mute:after, .fa-duotone.#{$fa-css-prefix}-volume-mute:after { content: fa-content(\f6a9\fe02); }
.fad.#{$fa-css-prefix}-volume-times:before, .fa-duotone.#{$fa-css-prefix}-volume-times:before { content: fa-content(\f6a9\fe01); }
.fad.#{$fa-css-prefix}-volume-times:after, .fa-duotone.#{$fa-css-prefix}-volume-times:after { content: fa-content(\f6a9\fe02); }
.fad.#{$fa-css-prefix}-vr-cardboard:before, .fa-duotone.#{$fa-css-prefix}-vr-cardboard:before { content: fa-content(\f729\fe01); }
.fad.#{$fa-css-prefix}-vr-cardboard:after, .fa-duotone.#{$fa-css-prefix}-vr-cardboard:after { content: fa-content(\f729\fe02); }
.fad.#{$fa-css-prefix}-w:before, .fa-duotone.#{$fa-css-prefix}-w:before { content: fa-content(\57\fe01); }
.fad.#{$fa-css-prefix}-w:after, .fa-duotone.#{$fa-css-prefix}-w:after { content: fa-content(\57\fe02); }
.fad.#{$fa-css-prefix}-wagon-covered:before, .fa-duotone.#{$fa-css-prefix}-wagon-covered:before { content: fa-content(\f8ee\fe01); }
.fad.#{$fa-css-prefix}-wagon-covered:after, .fa-duotone.#{$fa-css-prefix}-wagon-covered:after { content: fa-content(\f8ee\fe02); }
.fad.#{$fa-css-prefix}-walker:before, .fa-duotone.#{$fa-css-prefix}-walker:before { content: fa-content(\f831\fe01); }
.fad.#{$fa-css-prefix}-walker:after, .fa-duotone.#{$fa-css-prefix}-walker:after { content: fa-content(\f831\fe02); }
.fad.#{$fa-css-prefix}-walkie-talkie:before, .fa-duotone.#{$fa-css-prefix}-walkie-talkie:before { content: fa-content(\f8ef\fe01); }
.fad.#{$fa-css-prefix}-walkie-talkie:after, .fa-duotone.#{$fa-css-prefix}-walkie-talkie:after { content: fa-content(\f8ef\fe02); }
.fad.#{$fa-css-prefix}-wallet:before, .fa-duotone.#{$fa-css-prefix}-wallet:before { content: fa-content(\f555\fe01); }
.fad.#{$fa-css-prefix}-wallet:after, .fa-duotone.#{$fa-css-prefix}-wallet:after { content: fa-content(\f555\fe02); }
.fad.#{$fa-css-prefix}-wand:before, .fa-duotone.#{$fa-css-prefix}-wand:before { content: fa-content(\f72a\fe01); }
.fad.#{$fa-css-prefix}-wand:after, .fa-duotone.#{$fa-css-prefix}-wand:after { content: fa-content(\f72a\fe02); }
.fad.#{$fa-css-prefix}-wand-magic:before, .fa-duotone.#{$fa-css-prefix}-wand-magic:before { content: fa-content(\f0d0\fe01); }
.fad.#{$fa-css-prefix}-wand-magic:after, .fa-duotone.#{$fa-css-prefix}-wand-magic:after { content: fa-content(\f0d0\fe02); }
.fad.#{$fa-css-prefix}-magic:before, .fa-duotone.#{$fa-css-prefix}-magic:before { content: fa-content(\f0d0\fe01); }
.fad.#{$fa-css-prefix}-magic:after, .fa-duotone.#{$fa-css-prefix}-magic:after { content: fa-content(\f0d0\fe02); }
.fad.#{$fa-css-prefix}-wand-magic-sparkles:before, .fa-duotone.#{$fa-css-prefix}-wand-magic-sparkles:before { content: fa-content(\e2ca\fe01); }
.fad.#{$fa-css-prefix}-wand-magic-sparkles:after, .fa-duotone.#{$fa-css-prefix}-wand-magic-sparkles:after { content: fa-content(\e2ca\fe02); }
.fad.#{$fa-css-prefix}-magic-wand-sparkles:before, .fa-duotone.#{$fa-css-prefix}-magic-wand-sparkles:before { content: fa-content(\e2ca\fe01); }
.fad.#{$fa-css-prefix}-magic-wand-sparkles:after, .fa-duotone.#{$fa-css-prefix}-magic-wand-sparkles:after { content: fa-content(\e2ca\fe02); }
.fad.#{$fa-css-prefix}-wand-sparkles:before, .fa-duotone.#{$fa-css-prefix}-wand-sparkles:before { content: fa-content(\f72b\fe01); }
.fad.#{$fa-css-prefix}-wand-sparkles:after, .fa-duotone.#{$fa-css-prefix}-wand-sparkles:after { content: fa-content(\f72b\fe02); }
.fad.#{$fa-css-prefix}-warehouse:before, .fa-duotone.#{$fa-css-prefix}-warehouse:before { content: fa-content(\f494\fe01); }
.fad.#{$fa-css-prefix}-warehouse:after, .fa-duotone.#{$fa-css-prefix}-warehouse:after { content: fa-content(\f494\fe02); }
.fad.#{$fa-css-prefix}-warehouse-full:before, .fa-duotone.#{$fa-css-prefix}-warehouse-full:before { content: fa-content(\f495\fe01); }
.fad.#{$fa-css-prefix}-warehouse-full:after, .fa-duotone.#{$fa-css-prefix}-warehouse-full:after { content: fa-content(\f495\fe02); }
.fad.#{$fa-css-prefix}-warehouse-alt:before, .fa-duotone.#{$fa-css-prefix}-warehouse-alt:before { content: fa-content(\f495\fe01); }
.fad.#{$fa-css-prefix}-warehouse-alt:after, .fa-duotone.#{$fa-css-prefix}-warehouse-alt:after { content: fa-content(\f495\fe02); }
.fad.#{$fa-css-prefix}-washing-machine:before, .fa-duotone.#{$fa-css-prefix}-washing-machine:before { content: fa-content(\f898\fe01); }
.fad.#{$fa-css-prefix}-washing-machine:after, .fa-duotone.#{$fa-css-prefix}-washing-machine:after { content: fa-content(\f898\fe02); }
.fad.#{$fa-css-prefix}-washer:before, .fa-duotone.#{$fa-css-prefix}-washer:before { content: fa-content(\f898\fe01); }
.fad.#{$fa-css-prefix}-washer:after, .fa-duotone.#{$fa-css-prefix}-washer:after { content: fa-content(\f898\fe02); }
.fad.#{$fa-css-prefix}-watch:before, .fa-duotone.#{$fa-css-prefix}-watch:before { content: fa-content(\231a\fe01); }
.fad.#{$fa-css-prefix}-watch:after, .fa-duotone.#{$fa-css-prefix}-watch:after { content: fa-content(\231a\fe02); }
.fad.#{$fa-css-prefix}-watch-apple:before, .fa-duotone.#{$fa-css-prefix}-watch-apple:before { content: fa-content(\e2cb\fe01); }
.fad.#{$fa-css-prefix}-watch-apple:after, .fa-duotone.#{$fa-css-prefix}-watch-apple:after { content: fa-content(\e2cb\fe02); }
.fad.#{$fa-css-prefix}-watch-calculator:before, .fa-duotone.#{$fa-css-prefix}-watch-calculator:before { content: fa-content(\f8f0\fe01); }
.fad.#{$fa-css-prefix}-watch-calculator:after, .fa-duotone.#{$fa-css-prefix}-watch-calculator:after { content: fa-content(\f8f0\fe02); }
.fad.#{$fa-css-prefix}-watch-fitness:before, .fa-duotone.#{$fa-css-prefix}-watch-fitness:before { content: fa-content(\f63e\fe01); }
.fad.#{$fa-css-prefix}-watch-fitness:after, .fa-duotone.#{$fa-css-prefix}-watch-fitness:after { content: fa-content(\f63e\fe02); }
.fad.#{$fa-css-prefix}-watch-smart:before, .fa-duotone.#{$fa-css-prefix}-watch-smart:before { content: fa-content(\e2cc\fe01); }
.fad.#{$fa-css-prefix}-watch-smart:after, .fa-duotone.#{$fa-css-prefix}-watch-smart:after { content: fa-content(\e2cc\fe02); }
.fad.#{$fa-css-prefix}-water:before, .fa-duotone.#{$fa-css-prefix}-water:before { content: fa-content(\f773\fe01); }
.fad.#{$fa-css-prefix}-water:after, .fa-duotone.#{$fa-css-prefix}-water:after { content: fa-content(\f773\fe02); }
.fad.#{$fa-css-prefix}-water-arrow-down:before, .fa-duotone.#{$fa-css-prefix}-water-arrow-down:before { content: fa-content(\f774\fe01); }
.fad.#{$fa-css-prefix}-water-arrow-down:after, .fa-duotone.#{$fa-css-prefix}-water-arrow-down:after { content: fa-content(\f774\fe02); }
.fad.#{$fa-css-prefix}-water-lower:before, .fa-duotone.#{$fa-css-prefix}-water-lower:before { content: fa-content(\f774\fe01); }
.fad.#{$fa-css-prefix}-water-lower:after, .fa-duotone.#{$fa-css-prefix}-water-lower:after { content: fa-content(\f774\fe02); }
.fad.#{$fa-css-prefix}-water-arrow-up:before, .fa-duotone.#{$fa-css-prefix}-water-arrow-up:before { content: fa-content(\f775\fe01); }
.fad.#{$fa-css-prefix}-water-arrow-up:after, .fa-duotone.#{$fa-css-prefix}-water-arrow-up:after { content: fa-content(\f775\fe02); }
.fad.#{$fa-css-prefix}-water-rise:before, .fa-duotone.#{$fa-css-prefix}-water-rise:before { content: fa-content(\f775\fe01); }
.fad.#{$fa-css-prefix}-water-rise:after, .fa-duotone.#{$fa-css-prefix}-water-rise:after { content: fa-content(\f775\fe02); }
.fad.#{$fa-css-prefix}-water-ladder:before, .fa-duotone.#{$fa-css-prefix}-water-ladder:before { content: fa-content(\f5c5\fe01); }
.fad.#{$fa-css-prefix}-water-ladder:after, .fa-duotone.#{$fa-css-prefix}-water-ladder:after { content: fa-content(\f5c5\fe02); }
.fad.#{$fa-css-prefix}-ladder-water:before, .fa-duotone.#{$fa-css-prefix}-ladder-water:before { content: fa-content(\f5c5\fe01); }
.fad.#{$fa-css-prefix}-ladder-water:after, .fa-duotone.#{$fa-css-prefix}-ladder-water:after { content: fa-content(\f5c5\fe02); }
.fad.#{$fa-css-prefix}-swimming-pool:before, .fa-duotone.#{$fa-css-prefix}-swimming-pool:before { content: fa-content(\f5c5\fe01); }
.fad.#{$fa-css-prefix}-swimming-pool:after, .fa-duotone.#{$fa-css-prefix}-swimming-pool:after { content: fa-content(\f5c5\fe02); }
.fad.#{$fa-css-prefix}-watermelon-slice:before, .fa-duotone.#{$fa-css-prefix}-watermelon-slice:before { content: fa-content(\e337\fe01); }
.fad.#{$fa-css-prefix}-watermelon-slice:after, .fa-duotone.#{$fa-css-prefix}-watermelon-slice:after { content: fa-content(\e337\fe02); }
.fad.#{$fa-css-prefix}-wave-pulse:before, .fa-duotone.#{$fa-css-prefix}-wave-pulse:before { content: fa-content(\f5f8\fe01); }
.fad.#{$fa-css-prefix}-wave-pulse:after, .fa-duotone.#{$fa-css-prefix}-wave-pulse:after { content: fa-content(\f5f8\fe02); }
.fad.#{$fa-css-prefix}-heart-rate:before, .fa-duotone.#{$fa-css-prefix}-heart-rate:before { content: fa-content(\f5f8\fe01); }
.fad.#{$fa-css-prefix}-heart-rate:after, .fa-duotone.#{$fa-css-prefix}-heart-rate:after { content: fa-content(\f5f8\fe02); }
.fad.#{$fa-css-prefix}-wave-sine:before, .fa-duotone.#{$fa-css-prefix}-wave-sine:before { content: fa-content(\223f\fe01); }
.fad.#{$fa-css-prefix}-wave-sine:after, .fa-duotone.#{$fa-css-prefix}-wave-sine:after { content: fa-content(\223f\fe02); }
.fad.#{$fa-css-prefix}-wave-square:before, .fa-duotone.#{$fa-css-prefix}-wave-square:before { content: fa-content(\f83e\fe01); }
.fad.#{$fa-css-prefix}-wave-square:after, .fa-duotone.#{$fa-css-prefix}-wave-square:after { content: fa-content(\f83e\fe02); }
.fad.#{$fa-css-prefix}-wave-triangle:before, .fa-duotone.#{$fa-css-prefix}-wave-triangle:before { content: fa-content(\f89a\fe01); }
.fad.#{$fa-css-prefix}-wave-triangle:after, .fa-duotone.#{$fa-css-prefix}-wave-triangle:after { content: fa-content(\f89a\fe02); }
.fad.#{$fa-css-prefix}-waveform:before, .fa-duotone.#{$fa-css-prefix}-waveform:before { content: fa-content(\f8f1\fe01); }
.fad.#{$fa-css-prefix}-waveform:after, .fa-duotone.#{$fa-css-prefix}-waveform:after { content: fa-content(\f8f1\fe02); }
.fad.#{$fa-css-prefix}-waveform-lines:before, .fa-duotone.#{$fa-css-prefix}-waveform-lines:before { content: fa-content(\f8f2\fe01); }
.fad.#{$fa-css-prefix}-waveform-lines:after, .fa-duotone.#{$fa-css-prefix}-waveform-lines:after { content: fa-content(\f8f2\fe02); }
.fad.#{$fa-css-prefix}-weight-hanging:before, .fa-duotone.#{$fa-css-prefix}-weight-hanging:before { content: fa-content(\f5cd\fe01); }
.fad.#{$fa-css-prefix}-weight-hanging:after, .fa-duotone.#{$fa-css-prefix}-weight-hanging:after { content: fa-content(\f5cd\fe02); }
.fad.#{$fa-css-prefix}-weight-scale:before, .fa-duotone.#{$fa-css-prefix}-weight-scale:before { content: fa-content(\f496\fe01); }
.fad.#{$fa-css-prefix}-weight-scale:after, .fa-duotone.#{$fa-css-prefix}-weight-scale:after { content: fa-content(\f496\fe02); }
.fad.#{$fa-css-prefix}-weight:before, .fa-duotone.#{$fa-css-prefix}-weight:before { content: fa-content(\f496\fe01); }
.fad.#{$fa-css-prefix}-weight:after, .fa-duotone.#{$fa-css-prefix}-weight:after { content: fa-content(\f496\fe02); }
.fad.#{$fa-css-prefix}-whale:before, .fa-duotone.#{$fa-css-prefix}-whale:before { content: fa-content(\f72c); }
.fad.#{$fa-css-prefix}-whale:after, .fa-duotone.#{$fa-css-prefix}-whale:after { content: fa-content(\10f72c); }
.fad.#{$fa-css-prefix}-wheat:before, .fa-duotone.#{$fa-css-prefix}-wheat:before { content: fa-content(\f72d\fe01); }
.fad.#{$fa-css-prefix}-wheat:after, .fa-duotone.#{$fa-css-prefix}-wheat:after { content: fa-content(\f72d\fe02); }
.fad.#{$fa-css-prefix}-wheat-awn:before, .fa-duotone.#{$fa-css-prefix}-wheat-awn:before { content: fa-content(\e2cd\fe01); }
.fad.#{$fa-css-prefix}-wheat-awn:after, .fa-duotone.#{$fa-css-prefix}-wheat-awn:after { content: fa-content(\e2cd\fe02); }
.fad.#{$fa-css-prefix}-wheat-alt:before, .fa-duotone.#{$fa-css-prefix}-wheat-alt:before { content: fa-content(\e2cd\fe01); }
.fad.#{$fa-css-prefix}-wheat-alt:after, .fa-duotone.#{$fa-css-prefix}-wheat-alt:after { content: fa-content(\e2cd\fe02); }
.fad.#{$fa-css-prefix}-wheat-awn-slash:before, .fa-duotone.#{$fa-css-prefix}-wheat-awn-slash:before { content: fa-content(\e338\fe01); }
.fad.#{$fa-css-prefix}-wheat-awn-slash:after, .fa-duotone.#{$fa-css-prefix}-wheat-awn-slash:after { content: fa-content(\e338\fe02); }
.fad.#{$fa-css-prefix}-wheat-slash:before, .fa-duotone.#{$fa-css-prefix}-wheat-slash:before { content: fa-content(\e339\fe01); }
.fad.#{$fa-css-prefix}-wheat-slash:after, .fa-duotone.#{$fa-css-prefix}-wheat-slash:after { content: fa-content(\e339\fe02); }
.fad.#{$fa-css-prefix}-wheelchair:before, .fa-duotone.#{$fa-css-prefix}-wheelchair:before { content: fa-content(\f193\fe01); }
.fad.#{$fa-css-prefix}-wheelchair:after, .fa-duotone.#{$fa-css-prefix}-wheelchair:after { content: fa-content(\f193\fe02); }
.fad.#{$fa-css-prefix}-wheelchair-move:before, .fa-duotone.#{$fa-css-prefix}-wheelchair-move:before { content: fa-content(\e2ce\fe01); }
.fad.#{$fa-css-prefix}-wheelchair-move:after, .fa-duotone.#{$fa-css-prefix}-wheelchair-move:after { content: fa-content(\e2ce\fe02); }
.fad.#{$fa-css-prefix}-wheelchair-alt:before, .fa-duotone.#{$fa-css-prefix}-wheelchair-alt:before { content: fa-content(\e2ce\fe01); }
.fad.#{$fa-css-prefix}-wheelchair-alt:after, .fa-duotone.#{$fa-css-prefix}-wheelchair-alt:after { content: fa-content(\e2ce\fe02); }
.fad.#{$fa-css-prefix}-whiskey-glass:before, .fa-duotone.#{$fa-css-prefix}-whiskey-glass:before { content: fa-content(\f7a0); }
.fad.#{$fa-css-prefix}-whiskey-glass:after, .fa-duotone.#{$fa-css-prefix}-whiskey-glass:after { content: fa-content(\10f7a0); }
.fad.#{$fa-css-prefix}-glass-whiskey:before, .fa-duotone.#{$fa-css-prefix}-glass-whiskey:before { content: fa-content(\f7a0); }
.fad.#{$fa-css-prefix}-glass-whiskey:after, .fa-duotone.#{$fa-css-prefix}-glass-whiskey:after { content: fa-content(\10f7a0); }
.fad.#{$fa-css-prefix}-whiskey-glass-ice:before, .fa-duotone.#{$fa-css-prefix}-whiskey-glass-ice:before { content: fa-content(\f7a1\fe01); }
.fad.#{$fa-css-prefix}-whiskey-glass-ice:after, .fa-duotone.#{$fa-css-prefix}-whiskey-glass-ice:after { content: fa-content(\f7a1\fe02); }
.fad.#{$fa-css-prefix}-glass-whiskey-rocks:before, .fa-duotone.#{$fa-css-prefix}-glass-whiskey-rocks:before { content: fa-content(\f7a1\fe01); }
.fad.#{$fa-css-prefix}-glass-whiskey-rocks:after, .fa-duotone.#{$fa-css-prefix}-glass-whiskey-rocks:after { content: fa-content(\f7a1\fe02); }
.fad.#{$fa-css-prefix}-whistle:before, .fa-duotone.#{$fa-css-prefix}-whistle:before { content: fa-content(\f460\fe01); }
.fad.#{$fa-css-prefix}-whistle:after, .fa-duotone.#{$fa-css-prefix}-whistle:after { content: fa-content(\f460\fe02); }
.fad.#{$fa-css-prefix}-wifi:before, .fa-duotone.#{$fa-css-prefix}-wifi:before { content: fa-content(\f1eb\fe01); }
.fad.#{$fa-css-prefix}-wifi:after, .fa-duotone.#{$fa-css-prefix}-wifi:after { content: fa-content(\f1eb\fe02); }
.fad.#{$fa-css-prefix}-wifi-3:before, .fa-duotone.#{$fa-css-prefix}-wifi-3:before { content: fa-content(\f1eb\fe01); }
.fad.#{$fa-css-prefix}-wifi-3:after, .fa-duotone.#{$fa-css-prefix}-wifi-3:after { content: fa-content(\f1eb\fe02); }
.fad.#{$fa-css-prefix}-wifi-strong:before, .fa-duotone.#{$fa-css-prefix}-wifi-strong:before { content: fa-content(\f1eb\fe01); }
.fad.#{$fa-css-prefix}-wifi-strong:after, .fa-duotone.#{$fa-css-prefix}-wifi-strong:after { content: fa-content(\f1eb\fe02); }
.fad.#{$fa-css-prefix}-wifi-exclamation:before, .fa-duotone.#{$fa-css-prefix}-wifi-exclamation:before { content: fa-content(\e2cf\fe01); }
.fad.#{$fa-css-prefix}-wifi-exclamation:after, .fa-duotone.#{$fa-css-prefix}-wifi-exclamation:after { content: fa-content(\e2cf\fe02); }
.fad.#{$fa-css-prefix}-wifi-fair:before, .fa-duotone.#{$fa-css-prefix}-wifi-fair:before { content: fa-content(\f6ab\fe01); }
.fad.#{$fa-css-prefix}-wifi-fair:after, .fa-duotone.#{$fa-css-prefix}-wifi-fair:after { content: fa-content(\f6ab\fe02); }
.fad.#{$fa-css-prefix}-wifi-2:before, .fa-duotone.#{$fa-css-prefix}-wifi-2:before { content: fa-content(\f6ab\fe01); }
.fad.#{$fa-css-prefix}-wifi-2:after, .fa-duotone.#{$fa-css-prefix}-wifi-2:after { content: fa-content(\f6ab\fe02); }
.fad.#{$fa-css-prefix}-wifi-slash:before, .fa-duotone.#{$fa-css-prefix}-wifi-slash:before { content: fa-content(\f6ac\fe01); }
.fad.#{$fa-css-prefix}-wifi-slash:after, .fa-duotone.#{$fa-css-prefix}-wifi-slash:after { content: fa-content(\f6ac\fe02); }
.fad.#{$fa-css-prefix}-wifi-weak:before, .fa-duotone.#{$fa-css-prefix}-wifi-weak:before { content: fa-content(\f6aa\fe01); }
.fad.#{$fa-css-prefix}-wifi-weak:after, .fa-duotone.#{$fa-css-prefix}-wifi-weak:after { content: fa-content(\f6aa\fe02); }
.fad.#{$fa-css-prefix}-wifi-1:before, .fa-duotone.#{$fa-css-prefix}-wifi-1:before { content: fa-content(\f6aa\fe01); }
.fad.#{$fa-css-prefix}-wifi-1:after, .fa-duotone.#{$fa-css-prefix}-wifi-1:after { content: fa-content(\f6aa\fe02); }
.fad.#{$fa-css-prefix}-wind:before, .fa-duotone.#{$fa-css-prefix}-wind:before { content: fa-content(\f72e\fe01); }
.fad.#{$fa-css-prefix}-wind:after, .fa-duotone.#{$fa-css-prefix}-wind:after { content: fa-content(\f72e\fe02); }
.fad.#{$fa-css-prefix}-wind-turbine:before, .fa-duotone.#{$fa-css-prefix}-wind-turbine:before { content: fa-content(\f89b\fe01); }
.fad.#{$fa-css-prefix}-wind-turbine:after, .fa-duotone.#{$fa-css-prefix}-wind-turbine:after { content: fa-content(\f89b\fe02); }
.fad.#{$fa-css-prefix}-wind-warning:before, .fa-duotone.#{$fa-css-prefix}-wind-warning:before { content: fa-content(\f776\fe01); }
.fad.#{$fa-css-prefix}-wind-warning:after, .fa-duotone.#{$fa-css-prefix}-wind-warning:after { content: fa-content(\f776\fe02); }
.fad.#{$fa-css-prefix}-wind-circle-exclamation:before, .fa-duotone.#{$fa-css-prefix}-wind-circle-exclamation:before { content: fa-content(\f776\fe01); }
.fad.#{$fa-css-prefix}-wind-circle-exclamation:after, .fa-duotone.#{$fa-css-prefix}-wind-circle-exclamation:after { content: fa-content(\f776\fe02); }
.fad.#{$fa-css-prefix}-window:before, .fa-duotone.#{$fa-css-prefix}-window:before { content: fa-content(\f40e\fe01); }
.fad.#{$fa-css-prefix}-window:after, .fa-duotone.#{$fa-css-prefix}-window:after { content: fa-content(\f40e\fe02); }
.fad.#{$fa-css-prefix}-window-flip:before, .fa-duotone.#{$fa-css-prefix}-window-flip:before { content: fa-content(\f40f\fe01); }
.fad.#{$fa-css-prefix}-window-flip:after, .fa-duotone.#{$fa-css-prefix}-window-flip:after { content: fa-content(\f40f\fe02); }
.fad.#{$fa-css-prefix}-window-alt:before, .fa-duotone.#{$fa-css-prefix}-window-alt:before { content: fa-content(\f40f\fe01); }
.fad.#{$fa-css-prefix}-window-alt:after, .fa-duotone.#{$fa-css-prefix}-window-alt:after { content: fa-content(\f40f\fe02); }
.fad.#{$fa-css-prefix}-window-frame:before, .fa-duotone.#{$fa-css-prefix}-window-frame:before { content: fa-content(\e04f); }
.fad.#{$fa-css-prefix}-window-frame:after, .fa-duotone.#{$fa-css-prefix}-window-frame:after { content: fa-content(\10e04f); }
.fad.#{$fa-css-prefix}-window-frame-open:before, .fa-duotone.#{$fa-css-prefix}-window-frame-open:before { content: fa-content(\e050\fe01); }
.fad.#{$fa-css-prefix}-window-frame-open:after, .fa-duotone.#{$fa-css-prefix}-window-frame-open:after { content: fa-content(\e050\fe02); }
.fad.#{$fa-css-prefix}-window-maximize:before, .fa-duotone.#{$fa-css-prefix}-window-maximize:before { content: fa-content(\f2d0); }
.fad.#{$fa-css-prefix}-window-maximize:after, .fa-duotone.#{$fa-css-prefix}-window-maximize:after { content: fa-content(\10f2d0); }
.fad.#{$fa-css-prefix}-window-minimize:before, .fa-duotone.#{$fa-css-prefix}-window-minimize:before { content: fa-content(\f2d1); }
.fad.#{$fa-css-prefix}-window-minimize:after, .fa-duotone.#{$fa-css-prefix}-window-minimize:after { content: fa-content(\10f2d1); }
.fad.#{$fa-css-prefix}-window-restore:before, .fa-duotone.#{$fa-css-prefix}-window-restore:before { content: fa-content(\f2d2\fe01); }
.fad.#{$fa-css-prefix}-window-restore:after, .fa-duotone.#{$fa-css-prefix}-window-restore:after { content: fa-content(\f2d2\fe02); }
.fad.#{$fa-css-prefix}-windsock:before, .fa-duotone.#{$fa-css-prefix}-windsock:before { content: fa-content(\f777\fe01); }
.fad.#{$fa-css-prefix}-windsock:after, .fa-duotone.#{$fa-css-prefix}-windsock:after { content: fa-content(\f777\fe02); }
.fad.#{$fa-css-prefix}-wine-bottle:before, .fa-duotone.#{$fa-css-prefix}-wine-bottle:before { content: fa-content(\f72f\fe01); }
.fad.#{$fa-css-prefix}-wine-bottle:after, .fa-duotone.#{$fa-css-prefix}-wine-bottle:after { content: fa-content(\f72f\fe02); }
.fad.#{$fa-css-prefix}-wine-glass:before, .fa-duotone.#{$fa-css-prefix}-wine-glass:before { content: fa-content(\f4e3); }
.fad.#{$fa-css-prefix}-wine-glass:after, .fa-duotone.#{$fa-css-prefix}-wine-glass:after { content: fa-content(\10f4e3); }
.fad.#{$fa-css-prefix}-wine-glass-crack:before, .fa-duotone.#{$fa-css-prefix}-wine-glass-crack:before { content: fa-content(\f4bb\fe01); }
.fad.#{$fa-css-prefix}-wine-glass-crack:after, .fa-duotone.#{$fa-css-prefix}-wine-glass-crack:after { content: fa-content(\f4bb\fe02); }
.fad.#{$fa-css-prefix}-fragile:before, .fa-duotone.#{$fa-css-prefix}-fragile:before { content: fa-content(\f4bb\fe01); }
.fad.#{$fa-css-prefix}-fragile:after, .fa-duotone.#{$fa-css-prefix}-fragile:after { content: fa-content(\f4bb\fe02); }
.fad.#{$fa-css-prefix}-wine-glass-empty:before, .fa-duotone.#{$fa-css-prefix}-wine-glass-empty:before { content: fa-content(\f5ce\fe01); }
.fad.#{$fa-css-prefix}-wine-glass-empty:after, .fa-duotone.#{$fa-css-prefix}-wine-glass-empty:after { content: fa-content(\f5ce\fe02); }
.fad.#{$fa-css-prefix}-wine-glass-alt:before, .fa-duotone.#{$fa-css-prefix}-wine-glass-alt:before { content: fa-content(\f5ce\fe01); }
.fad.#{$fa-css-prefix}-wine-glass-alt:after, .fa-duotone.#{$fa-css-prefix}-wine-glass-alt:after { content: fa-content(\f5ce\fe02); }
.fad.#{$fa-css-prefix}-won-sign:before, .fa-duotone.#{$fa-css-prefix}-won-sign:before { content: fa-content(\20a9\fe01); }
.fad.#{$fa-css-prefix}-won-sign:after, .fa-duotone.#{$fa-css-prefix}-won-sign:after { content: fa-content(\20a9\fe02); }
.fad.#{$fa-css-prefix}-krw:before, .fa-duotone.#{$fa-css-prefix}-krw:before { content: fa-content(\20a9\fe01); }
.fad.#{$fa-css-prefix}-krw:after, .fa-duotone.#{$fa-css-prefix}-krw:after { content: fa-content(\20a9\fe02); }
.fad.#{$fa-css-prefix}-won:before, .fa-duotone.#{$fa-css-prefix}-won:before { content: fa-content(\20a9\fe01); }
.fad.#{$fa-css-prefix}-won:after, .fa-duotone.#{$fa-css-prefix}-won:after { content: fa-content(\20a9\fe02); }
.fad.#{$fa-css-prefix}-wreath:before, .fa-duotone.#{$fa-css-prefix}-wreath:before { content: fa-content(\f7e2\fe01); }
.fad.#{$fa-css-prefix}-wreath:after, .fa-duotone.#{$fa-css-prefix}-wreath:after { content: fa-content(\f7e2\fe02); }
.fad.#{$fa-css-prefix}-wrench:before, .fa-duotone.#{$fa-css-prefix}-wrench:before { content: fa-content(\f0ad); }
.fad.#{$fa-css-prefix}-wrench:after, .fa-duotone.#{$fa-css-prefix}-wrench:after { content: fa-content(\10f0ad); }
.fad.#{$fa-css-prefix}-wrench-simple:before, .fa-duotone.#{$fa-css-prefix}-wrench-simple:before { content: fa-content(\e2d1\fe01); }
.fad.#{$fa-css-prefix}-wrench-simple:after, .fa-duotone.#{$fa-css-prefix}-wrench-simple:after { content: fa-content(\e2d1\fe02); }
.fad.#{$fa-css-prefix}-x:before, .fa-duotone.#{$fa-css-prefix}-x:before { content: fa-content(\58\fe01); }
.fad.#{$fa-css-prefix}-x:after, .fa-duotone.#{$fa-css-prefix}-x:after { content: fa-content(\58\fe02); }
.fad.#{$fa-css-prefix}-x-ray:before, .fa-duotone.#{$fa-css-prefix}-x-ray:before { content: fa-content(\f497\fe01); }
.fad.#{$fa-css-prefix}-x-ray:after, .fa-duotone.#{$fa-css-prefix}-x-ray:after { content: fa-content(\f497\fe02); }
.fad.#{$fa-css-prefix}-xmark:before, .fa-duotone.#{$fa-css-prefix}-xmark:before { content: fa-content(\d7\fe01); }
.fad.#{$fa-css-prefix}-xmark:after, .fa-duotone.#{$fa-css-prefix}-xmark:after { content: fa-content(\d7\fe02); }
.fad.#{$fa-css-prefix}-close:before, .fa-duotone.#{$fa-css-prefix}-close:before { content: fa-content(\d7\fe01); }
.fad.#{$fa-css-prefix}-close:after, .fa-duotone.#{$fa-css-prefix}-close:after { content: fa-content(\d7\fe02); }
.fad.#{$fa-css-prefix}-multiply:before, .fa-duotone.#{$fa-css-prefix}-multiply:before { content: fa-content(\d7\fe01); }
.fad.#{$fa-css-prefix}-multiply:after, .fa-duotone.#{$fa-css-prefix}-multiply:after { content: fa-content(\d7\fe02); }
.fad.#{$fa-css-prefix}-remove:before, .fa-duotone.#{$fa-css-prefix}-remove:before { content: fa-content(\d7\fe01); }
.fad.#{$fa-css-prefix}-remove:after, .fa-duotone.#{$fa-css-prefix}-remove:after { content: fa-content(\d7\fe02); }
.fad.#{$fa-css-prefix}-times:before, .fa-duotone.#{$fa-css-prefix}-times:before { content: fa-content(\d7\fe01); }
.fad.#{$fa-css-prefix}-times:after, .fa-duotone.#{$fa-css-prefix}-times:after { content: fa-content(\d7\fe02); }
.fad.#{$fa-css-prefix}-xmark-to-slot:before, .fa-duotone.#{$fa-css-prefix}-xmark-to-slot:before { content: fa-content(\f771\fe01); }
.fad.#{$fa-css-prefix}-xmark-to-slot:after, .fa-duotone.#{$fa-css-prefix}-xmark-to-slot:after { content: fa-content(\f771\fe02); }
.fad.#{$fa-css-prefix}-times-to-slot:before, .fa-duotone.#{$fa-css-prefix}-times-to-slot:before { content: fa-content(\f771\fe01); }
.fad.#{$fa-css-prefix}-times-to-slot:after, .fa-duotone.#{$fa-css-prefix}-times-to-slot:after { content: fa-content(\f771\fe02); }
.fad.#{$fa-css-prefix}-vote-nay:before, .fa-duotone.#{$fa-css-prefix}-vote-nay:before { content: fa-content(\f771\fe01); }
.fad.#{$fa-css-prefix}-vote-nay:after, .fa-duotone.#{$fa-css-prefix}-vote-nay:after { content: fa-content(\f771\fe02); }
.fad.#{$fa-css-prefix}-y:before, .fa-duotone.#{$fa-css-prefix}-y:before { content: fa-content(\59\fe01); }
.fad.#{$fa-css-prefix}-y:after, .fa-duotone.#{$fa-css-prefix}-y:after { content: fa-content(\59\fe02); }
.fad.#{$fa-css-prefix}-yen-sign:before, .fa-duotone.#{$fa-css-prefix}-yen-sign:before { content: fa-content(\a5\fe01); }
.fad.#{$fa-css-prefix}-yen-sign:after, .fa-duotone.#{$fa-css-prefix}-yen-sign:after { content: fa-content(\a5\fe02); }
.fad.#{$fa-css-prefix}-cny:before, .fa-duotone.#{$fa-css-prefix}-cny:before { content: fa-content(\a5\fe01); }
.fad.#{$fa-css-prefix}-cny:after, .fa-duotone.#{$fa-css-prefix}-cny:after { content: fa-content(\a5\fe02); }
.fad.#{$fa-css-prefix}-jpy:before, .fa-duotone.#{$fa-css-prefix}-jpy:before { content: fa-content(\a5\fe01); }
.fad.#{$fa-css-prefix}-jpy:after, .fa-duotone.#{$fa-css-prefix}-jpy:after { content: fa-content(\a5\fe02); }
.fad.#{$fa-css-prefix}-rmb:before, .fa-duotone.#{$fa-css-prefix}-rmb:before { content: fa-content(\a5\fe01); }
.fad.#{$fa-css-prefix}-rmb:after, .fa-duotone.#{$fa-css-prefix}-rmb:after { content: fa-content(\a5\fe02); }
.fad.#{$fa-css-prefix}-yen:before, .fa-duotone.#{$fa-css-prefix}-yen:before { content: fa-content(\a5\fe01); }
.fad.#{$fa-css-prefix}-yen:after, .fa-duotone.#{$fa-css-prefix}-yen:after { content: fa-content(\a5\fe02); }
.fad.#{$fa-css-prefix}-yin-yang:before, .fa-duotone.#{$fa-css-prefix}-yin-yang:before { content: fa-content(\262f\fe01); }
.fad.#{$fa-css-prefix}-yin-yang:after, .fa-duotone.#{$fa-css-prefix}-yin-yang:after { content: fa-content(\262f\fe02); }
.fad.#{$fa-css-prefix}-z:before, .fa-duotone.#{$fa-css-prefix}-z:before { content: fa-content(\5a\fe01); }
.fad.#{$fa-css-prefix}-z:after, .fa-duotone.#{$fa-css-prefix}-z:after { content: fa-content(\5a\fe02); }
