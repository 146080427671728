.form {
  &.contact {
    label {
      display: block !important;
      width: 100% !important;
      padding: 0 0 5px;
      margin-top: 5px;
      text-align: left !important;
    }
  }

  label {
    display: block !important;
    width: 100% !important;
    padding: 0 0 5px;
    margin-top: 5px;
    text-align: left !important;
  }
  input + label {
    display: inline !important
  }

  .actions-toolbar {
    margin: 0 !important;
  }
}

button.captcha-reload {
  @extend .btn;
  @extend .btn--bordered;
  @extend .btn--bordered-black;
  @extend .btn--small
}

.field {
  margin-bottom: 10px !important;

  &.choice {
    label {
      display: inline !important;
    }

    &:before {
      display: none;
    }
  }
}

.fieldset > .legend {
  @include sm-text;
  font-weight: 800;
  font-family: $font1;
  margin-bottom: 10px !important;
}

.form-create-account {
  width: 100% !important;
}

#password-strength-meter-container{
  font-size: .8rem
}
