a.firecheckout.fc-hide-shipping-methods.fc-single-shipping-method .checkout-shipping-method {
  display:block !important;
}
.bb-lightgrey {
  border-bottom: 1px solid #E5E1EA;
}
.opc-progress-bar-item._active {
  &:before {
    background: #ff4f41;
  }
  & > span {
    &:before {
      background: #ff4f41;
    }
  }
}
.opc-block-shipping-information {
  background: #f5f5f5;
  padding: 30px;
}
.firecheckout {

  .loading-mask .loader {
    display: flex;
    top: 50% !important;
    left: 50% !important;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin-left: -15px;
      margin-top: -15px;
      -webkit-animation: firecheckout-spin 0.7s linear infinite;
      animation: firecheckout-spin 0.7s linear infinite;
      border: 3px solid #dddddd;
      border-top: 3px solid $instant-red !important;
      border-radius: 50%;
      cursor: wait;
    }
  }

  .page-wrapper {

    .page-header {

      a {
        transition: color 0.5s;

        &:hover {
          color: grey;
          text-decoration: none;
        }

      }

      .header {
        align-items: center;
        padding: 0px;
        display: flex;
        justify-content: space-between;
        padding: 10px 30px 0;
      }

      .logo--item {
        display: flex;
        height: 50px;
      }

      .my-cart {
        order: 3;
      }

      .continue-shopping {
        order: 4;
        color: #fff;
        margin-left: 20px;
        font-weight: 700;
        display: flex !important;
        align-items: center;
        justify-content: center;
        font-size: .8rem;

        &:after {
          font: normal normal normal 18px/1 FontAwesome;
          content: "\f054";
          margin-left: 10px;
          transition: transform 0.5s;
          font-size: 12px
        }
      }

      .checkout-title-container {
        order: 2;
        margin: 0px auto;
        display: flex;
        align-content: center;

        h1 {
          font-family: 'Roboto Condensed', sans-serif;
          font-size: 20px;
          color: #fff;
          margin-bottom: 0px;
          text-transform: uppercase;
        }
      }
    }

    .cart-container .form-cart {
      width: 100%;
      float: none;
    }

    .label {
      font-size: 0.9rem;
    }

    .fieldset > .field:not(.choice) > .control {
      width: 100%;
      float: left;
    }

    #checkout input:not([type="checkbox"]):not([type="radio"]) {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 3px;
      height: 50px !important;
    }

      .form-shipping-address .fieldset div.field,
      .payment-method .fieldset div.field,
      #checkout .form-login .fieldset div.field,
      #maincontent .block-authentication .fieldset div.field,
      #checkout .checkout-shipping-address .fieldset div.field,
      #checkout .checkout-billing-address .fieldset div.field {
        padding: 0px;
        font-size: 0.8rem;
        margin-bottom: 5px;
      }

    #checkout .checkout-shipping-address .fieldset div.field > .control {
      margin-bottom: 5px;
    }

    #checkout .opc-wrapper .opc > li.checkout-shipping-address,
    #checkout .opc-wrapper .opc > li.checkout-shipping-method,
    #checkout .opc-wrapper .opc > li.checkout-payment-method,
    #checkout .opc-wrapper .opc > li.checkout-payment-method {

      border-radius: 17px;

    }

    #checkout .opc-wrapper .step-title {
      font-size: 1rem;
    }

    #checkout .opc-wrapper .step-title:before,
    #checkout .opc-block-summary > .title:before,
    #checkout .opc-sidebar .step-title:before {
      flex-shrink: 0;
      text-align: center;
      background: transparent;
      width: 26px;
      height: 26px;
      line-height: 24px;
      margin: 0 10px 0 -9px;
      color: #ff4f41;
      font-weight: 600;
      font-size: 14px;
      font-family: inherit;
      border: 1px solid #ff4f41;
      border-radius: 50%;
      counter-increment: firecheckout-section-counter;
      content: counter(firecheckout-section-counter);
    }


    /* Order Summary Column */

    #checkout .opc-summary-wrapper {
      border-radius: 17px;
      display:flex;
      height: 100%;

      .modal-inner-wrap {
        height: 100%;
        display: flex;
      }
      .title {
        font-size: 1rem;
      }

      .modal-content {
        overflow:visible;
      }
    }

  }

  /* Cart Actions */

  .cart.actions {
    display:none;
  }

  .cart {

    .item-actions {
      display:none;
    }

  }

  .cart-container {
    border-radius: 10px;
    background: #fdfcfe;
    border: 2px solid #E5E1EA;
    font-size: 1rem;
    position: relative;
    &.border:last-child {
      border: 0;
    }

    .table-wrapper .col {
      padding: 0px;
      border: none;

      font-family: "Roboto  ", "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      font-size: 1rem;
      font-weight: 600;


      &.item {
        padding: 0px;
        min-height: initial;
      }
    }

    .table-wrapper .col.qty {
      text-align: center;
    }

    .table-wrapper td.col {
      padding: 5px;
    }

    .table-wrapper td.col.qty {
      text-align: center;
      padding: 6px;
      padding-top: 17px;

      .control {
        text-align: center;
      }
    }

    .table-wrapper th.col.price {
      text-align: center;
      padding: 6px;
      .control {
        text-align: center;
      }
    }

    .table-wrapper td.col.price {
      text-align: center;
      padding: 6px;
      padding-top: 20px;
    }

    .table-wrapper th.col.item {
      padding: 6px;
    }

    .table-wrapper  .product-item-name {
      margin-top: 20px;
      font-weight: 400;
    }

    .table-wrapper .product-item-photo {
      width: 35%;
    }

    .item-options {
      display: none;
    }

    .price-including-tax, .price-excluding-tax {
      font-size: 1rem;
    }
    .table thead td, .table thead th {
      border-width:1px;
    }
  }

  .checkout-title-wrapper {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 25px;
    padding: 1rem 0 0;
    text-transform: uppercase;
  }

  .checkout-text h3 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 25px;
    text-transform: uppercase;
  }

  p.checkout-text {
    font-size: 0.9rem;
    font-weight: normal;
  }

  .authentication-wrapper {
    display: none;
  }

  /* Shipping */

  .shipping-select-prompt {
    font-size: 0.9rem;
    font-weight:500;
    margin-bottom: 10px;
  }

  #checkout .opc-wrapper .table-checkout-shipping-method, .payment-method-note {
    font-size: 0.9rem;
    font-family: "Montserrat", "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  /* Payments */

  .cc-span {
    font-weight: 600;
    font-family: "Montserrat", "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  .discount-code {
    display:block;
  }

  .checkout-payment-method .payment-method-title .action-help {
    display:none;
  }

  .payment-method-title {

    img {
      width: 35px !important;
    }

    .label {
      font-weight: 600;
      font-family: "Montserrat", "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    }
  }

  .billing-address-same-as-shipping-block label {
    font-size: 0.9rem;
  }

  /* Cart Summary */

  .opc-block-summary {

    .items-in-cart {

      ol {
        margin:0px;
      }

      .title {
        display:none;
      }

      .product-item {
        list-style:none;
        font-size: 0.85rem;

        .product.options {
          display: none;
        }

        .subtotal {


          .price-including-tax, .price-excluding-tax, .price {
            font-size:0.85rem;
          }
        }
      }

    }

    .table-totals {
      font-size: 0.9rem;
      margin-bottom: 28px !important;

      .label {
        font-weight: normal;
      }

      .mark {
        stong {
          font-weight: bold !important;
        }
      }

    }

    .mark {
        stong {
          font-weight: bold !important;
        }
      }

    .totals {

    }

    .checkout-agreement {
       button {
        font-size: 0.9rem;
        padding: 0px;
        background: none;
        border: none;
        display: inline-block;
        font-weight: normal;
       }
    }

    .subscription label {
      font-weight: normal;
    }

    .place-order {
      .primary {
        padding: 20px;

        button.primary {
          font-family: $font3;
          font-size: 1.625rem;
          background: #5cb85c;
          border: 2px solid #5cb85c;
          line-height: 2rem;
          color: #ffffff;
          text-transform: uppercase;
          font-weight: 400;
          border-radius: 5px !important;

          &:hover {
            background: #5cb85c;
            border-color: #5cb85c;
            color: #29235c;
            text-decoration: none;
            border-radius: 5px !important;
          }
        }
      }
    }

  }

  .agreements-modal  {

      .checkout-agreements-item-content {
        font-size: 0.9rem;
        color: black
      }

      .modal-footer {
        padding: 20px;

        .action-agree {

          &:hover {

          }
        }
      }
    }

  .opc-block-summary .table-totals .mark strong {
    font-weight: bold !important;
  }

  .opc-block-summary .table-totals .grand .mark, .opc-block-summary .table-totals .grand .amount {
    padding:0px;
  }

  .opc-block-summary > .place-order:last-child .actions-toolbar .action {
    border-radius: 5px;
  }

  .modal-popup .modal-inner-wrap {
    left: 0;
  }

  @include max-screen($screen__s) {

    .checkout-title-container {
      display:none !important;
    }

    .place-order {
      clear:both;
    }

    #shopping-cart-table {

      .price-including-tax .price, .price-excluding-tax .price {
        font-weight:nomal !important;
        font-size: 0.9rem;
      }

      .cart.table-wrapper .col.qty:before, .cart.table-wrapper .col.price:before, .cart.table-wrapper .col.subtotal:before, .cart.table-wrapper .col.msrp:before {
        font-weight: normal !important;
        font-size: 0.9rem;
      }

      td.item {
        width:100%;

        .product-item-details {
          display: inline-block;
          margin-left: 70px;
          margin-top: 20px;
        }
      }

      td.price, td.qty {
        width:50%;
        font-weight:normal;
      }
    }
  }

}

/* Amazon Pay Overrides - Checkout - Desktop + Tablet */
.checkout-index-index.page-layout-1column {

  .header.content,
  .navigation-top-links.is-pulled-right {
    display: none;
  }

  button.action.action-show {
    color: #ff4f41;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    &:hover, &:focus {
      color: $light-black;
      cursor: pointer;
      text-decoration: none;
    }
  }

  #checkout {
    .revert-checkout { display: none; }
    .opc-progress-bar {
      //Target first one!!
      .opc-progress-bar-item:first-child {
        > span:after {
          font-size: 1.7rem;
        }
      }
      .opc-progress-bar-item > span {
        font-weight: bold;
        font-size: 1.1rem;
      }
      .opc-progress-bar-item._active > span:after {
        font-size: 1.7rem;
      }
      .opc-progress-bar-item > span:after {
        font-size: 1.1rem;
      }
    }

    .opc-wrapper {
      #shipping{
        #addressBookWidgetDiv {
          .widget-container {
            height: 450px !important;
          }
        }
        #checkout-step-shipping{
          div.fieldset.subscription {
            margin-top: 20px;
            div.field{
              label.label {display: none;}
            }
            div.control{
              div.choice.field {
                input {vertical-align: middle; }
                label.label {display: initial;}
              }
            }
          }
        }
      }

      #shipping, #opc-shipping_method {
        .step-title {
          font-size: 1rem;
          font-weight: bold;
          color: #363636;
        }
      }
      #opc-shipping_method {
        p.shipping-select-prompt {
          display: none;
        }
        #co-shipping-method-form {
          button.button.action.continue.primary{
            @extend .btn;
            @extend .btn--blue;
            padding: 0 15px;
          }
        }
      }
    }
  }

  #payment {
    #checkout-step-payment {
      #co-payment-form {
        #checkout-payment-method-load {
          #walletWidgetDiv {
            margin-bottom: 30px;
            .widget-container {
              min-height: 450px;
            }
          }
          .step-title {
            font-size: 1rem;
            font-weight: bold;
            color: #363636;
          }
          div.payment-method-title.field.choice{
            label.label {
              display: inline;
            }
          }
          div.payment-method-content {
            padding: 0 0 20px 0;
            div.actions-toolbar {
              button.action.primary.checkout {
                @extend .btn;
                @extend .btn--blue;
              }
            }
          }
        }
        div.payment-option._collapsible.opc-payment-additional.discount-code {
          display: block;
        }
        div.checkout-billing-address{
          display: none;
        }
      }
    }
  }

  aside.modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide {
    margin: 0px;
    footer.modal-footer{
        display: none;
    }


    #opc-sidebar {
      .opc-block-summary {
        margin: 5px 0 20px;
        padding: 0 25px;
        .content ol {
          list-style: none;
        }
        div.product-item-details {
          div.product.options{
            display: none;
          }
        }
        table.data.table.table-totals{
          background-color: inherit;
        }
        .price {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }

      .opc-block-summary, .opc-block-shipping-information {
        .title, .shipping-information-title {
          font-size: 1rem;
          font-weight: bold;
          color: #363636;
          strong {
            font-size: 1rem;
          }
          button.action.action-edit{
            display: none;
          }
        }
      }
    }
  }

  section.is-cms-title { display: none !important; }
}

.firecheckout.checkout-index-index {
  #payment {
    #checkout-step-payment {
      #co-payment-form {
        #checkout-payment-method-load {
          div.payment-method-content {
            div.actions-toolbar {
              div.primary{
                button.action.primary.checkout {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* Amazon Pay Overrides - Checkout - Desktop + Tablet */

body.amazon {
  .primary-header__nav-wrapper,
  .minicart-block,
  .action-auth-toggle,
  .page-title,
  .cart-listing.container {
    display: none !important
  }
  .container--zero-top {
    @include tablet {
      padding-top: 2rem;
    }
  }
  .primary-header__inner {
    display: block !important;
  }
  .opc-block-summary {
    padding-top: 20px!important;
  }

  .opc-estimated-wrapper {
    .minicart-wrapper .action.showcart {
      border: 2px solid #29215c;
      background: #29215c;
      .qty {
        border-radius: 50%;
        padding: 0px 5px 4px 4px;
        font-size: 1rem;
        height: 20px;
        line-height: 1.4rem;
      }
      &:before {
        color: #fff;
      }
    }
  }

  .opc-sidebar {
    border-radius: 17px!important;
    box-sizing: border-box;
    background: #fdfcfe;
    border: 2px solid #E5E1EA;
    border-radius: 4px;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
    .opc-block-summary, .opc-block-shipping-information {
      background: none;
      padding: 10px 0 0!important;
      margin: 0!important;
    }
  }

  .form-login {
    margin-top: 10px;
      max-width: 100%;
      .control {
        width:100%!important;
      }
  }
  .shipping-address-item {
    margin-bottom:0;
    width: 100%;
  }
  .fieldset > .field:not(.choice) > .control {
      width: 100%;
  }
  #checkout-step-shipping_method.step-content {
    margin-bottom:0;
  }

  .methods-shipping .actions-toolbar .action.primary {
    background: #29215c url(../images/icons/blue-button-arrow.png) no-repeat right!important;
  }
  .page-header {
    padding: 0!important;
  }
  .opc-progress-bar {
    padding-top: 2rem;
  }
  .opc-progress-bar-item {
      @include tablet {
        width: 50%;
      }
  }

  .form-shipping-address {
    margin: 5px 0 0;
    max-width: 100%;
    width: 100%;
  }
  .step-title {
    border: none;
  }

  .opc {
    > li, &-sidebar {
      border-radius: 17px!important;
      box-sizing: border-box;
      padding: 25px;
      background: #fdfcfe;
      border: 2px solid #E5E1EA;
      border-radius: 4px;
      box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.05);
      margin-bottom: 1rem;
    }

  }

  input, select {
    height: auto;
    width: 100%!important;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-style: solid;
    border-width: 0;
    border-color: #f2f5f7;
    background-color: #f2f5f7;
    height: 50px;
    border-radius: 3px;
    font-size: 12px !important;
    height: 50px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDMwIDYwIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzMzMyIgZD0iTTE1LjY3NiAzMy4wMzdsNS4xMDItNS4xMDMtLjcwNy0xLjA4Ni00LjQ5IDQuNDkyLTQuNDktNC40OTItLjcwMiAxLjA4NiA1LjEwMiA1LjEwMy4wNzUuMTE1LjAyLS4wMi4wMi4wMnoiLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: 100% 45%;
    padding-right: 25px;
  }
  input[type="checkbox"],
  input[type="radio"] {
      opacity: 1;
      -webkit-appearance: none;
      display: inline-block;
      position: relative;
      z-index: 1;
      /* Move it above the label in case if label has wrong 'for' */
      margin: 0 5px 0 0;
      padding: 0 !important;
      background-color: #ffffff!important;
      box-shadow: 0 0 0 0 #f7f7f7 inset;
      border: 1px solid #dddddd;
      border-radius: 4px;
      -webkit-transition: all 0.2s ease-in-out !important;
      transition: all 0.2s ease-in-out !important;
      outline: 0;
      width: 18px !important;
      height: 18px !important;
      min-width: 18px !important;
      min-height: 18px !important;
      cursor: pointer;
      vertical-align: middle !important;
      transform: none !important;
      &:after {
          content: '';
          background-repeat: no-repeat;
          background-position: 50% 50%;
          -webkit-transition: all 0.2s ease-in-out 0.1s;
          transition: all 0.2s ease-in-out 0.1s;
          transform: scale(0.2);
          opacity: 0;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 18px;
          height: 18px;
          margin-left: -9px;
          margin-top: -9px;
      }
      &:checked {
        box-shadow: 0 0 0 10px #b7b7b7 inset;
      }
    }
    input[type="checkbox"] {
      &:after {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAUCAQAAAA9vDUIAAAAdklEQVQ4y7XR0QmAIBCAYXOTxmiRfAtnaJ0gcLT2MP5eQjoJvQvy1f8DvXPun8OEt+SBTFITAhlASUoOEG35xvA1Z2a05AsnR03aOSBJ4+3s5aKQ5lfxJEm6k6nIqhikIKjm/kJ6a6pIf6uCaPIH0eY3iYZccy6u+Rcjm6L8iwAAAABJRU5ErkJggg==');
        background-size: 12px 10px;
      }
      &:checked {
        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    input[type="radio"]{
      border-radius: 50%!important;
      &:after {
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNMjU2LDQ2NGMxMTQuOSwwLDIwOC05My4xLDIwOC0yMDhjMC0xMTQuOS05My4xLTIwOC0yMDgtMjA4QzE0MS4xLDQ4LDQ4LDE0MS4xLDQ4LDI1NkM0OCwzNzAuOSwxNDEuMSw0NjQsMjU2LDQ2NHoiIHN0eWxlPSJmaWxsOiNmZmYiLz4NCjwvc3ZnPg0K');
        background-size: 8px 8px;
      }
      &:checked {
        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    .table-checkout-shipping-method {
      width: 100%;
      tbody td {
        border-top: none!important;
        padding: 10px 0!important;
      }
    }
    div.fieldset.subscription {
      margin: 0;
    }
   .shipping-information-title {
     border: none;
     margin:0;
   }
   button.button.action.continue.primary {
     background: #29215c url(../images/icons/blue-button-arrow.png) no-repeat 90% 14px!important;
     padding: 5px 53px 5px 9px!important;
   }
   button.action.primary.checkout {
     background: #5cb85c;
     border:2px solid #5cb85c;
     border-radius: 5px!important;
     color: $white;
     font-family: 'pathway gothic one'!important;
     font-size: 1.625rem;
     line-height: 2rem;
     text-transform: uppercase;
   }
   #amazon-payment {
     .payment-method-title {
       align-items: center;
       display: flex;
       justify-content: flex-start;
       padding-top: 0;
       div.payment-method-title.field.choice label {
         display: flex!important;
         width: auto!important;
         margin-right: 0.5rem;
         img {
           width: 75px;
         }
       }
     }
   }
   .opc-wrapper .step-content {
     margin:0;
   }
   .div.payment-method-content {
     padding: 0!important;
   }
   .amazon-payment-action-container {
     .action.action-edit-payment {
       background: #29215c url(../images/icons/blue-button-arrow.png) no-repeat 96% 8px!important;
       padding: 5px 53px 5px 9px !important;
       color: white;
       font-size: 1.1rem;
       font-weight: bold;
       text-transform: uppercase;
       margin-bottom: 1rem;
     }
   }
   button.action.primary.checkout {
     background: #5cb85c!important;
     border:2px solid #5cb85c!important;
     border-radius: 5px!important;
     color: $white;
     font-family: 'pathway gothic one'!important;
     font-size: 1.625rem!important;
     line-height: 2rem!important;
     padding: 12px!important;
     text-transform: uppercase;
     text-align: center!important;
   }
   .opc-progress-bar-item > span:after {
     font-size: 1.7rem;
   }
   #amazon-payment div.payment-method-title label.label {
     display: flex!important;
     margin-right: 1rem;
     width: auto!important;
   }
   #co-payment-form {
     br {
       display: none;
     }
     #amazon-payment {
       div.payment-method-content {
         padding-bottom: 0!important;
         .checkout-agreements {
           margin-bottom:0!important;
         }
       }
     }
   }
   .opc-block-summary .table-totals .mark {
     padding-left: 0;
   }
}
.message.success {
  background: none;
  margin-bottom:15px;
  @include tablet {
      margin-bottom:30px;
  }
}
body.firecheckout .cart-listing.container {
  padding: 0;
}

.amazon-widget-container {
  iframe {
    height: 100%!important;
  }
}
/**Success Page**/

.checkout-onepage-success {
  ul {
    float: left;
    list-style-type: disc;
    padding-left: 25px;
  }
}
