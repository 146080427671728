.testimonials__name {
  align-items: center;
  display: flex;
  padding: 20px;
  .testimonial-flag {
    margin-left: 10px;
    width: 25px;
    @include tablet {
      width: 50px;
    }
  }
}
.testimonial__img {
  position: relative;
  .testimonial__img__legend {
    background: $white;
    border: 2px solid #29215c;
    border-top: none;
    font-size: 0.775rem;
    line-height: 0.5rem;
    left: 0;
    padding: 10px 12px;
    position: absolute;
    top: 1px;
    z-index:1;
    @include tablet {
      font-size: 1.75rem;
      line-height: inherit;
      padding: 18px 23px;
    }
  }
  img {
    border: 1px solid #29215c;
    display: block;
    position: relative;
    width: 100%;
  }
}
.testimonial__stats {
  color: $white;
  left: 20px;
  position: absolute;
  text-shadow: 0px 2px 2px rgba(0,0,0,0.8);
  top: 90px;
  z-index:2;
}

.border-white {
  border-bottom: 1px solid $white;
  border-top: 1px solid $white;
}
/** COUNTRY SPECIFIC STYLING**/
.fr-lang .testimonials-section--2, .es-lang .testimonials-section--2, .de-lang .testimonials-section--2 {
  background: $white;
}

.testimonials-section--6 {
  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
  @include desktop {
    padding-bottom: 4rem;
  }
}
