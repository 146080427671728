.page-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;

  @include tablet {
    min-height: calc(100% - 430px);
  }
}

.page-main {
  @include tablet {
    background-color: #f5f5f5;
    flex-grow: 0;
  }
}

section {
  @include bg-cover;
  position: relative;
  .section__video {
    position: relative;
    padding-bottom: 56.45%;
    height: 0;
    iframe {
      border: 2px solid $white;
      border-radius: 2px;
      left: 0;
      height: 100%;
      position: absolute;
      top:0;
      width: 100%;
    }
  }
}
.section__border {
  border-bottom: 1px solid #a8a8a8;
  &--top {
    border-top: 1px solid #a8a8a8;
  }
}

body {
  color: $light-black;
  font-weight: 400;
}

.messages {
  margin-top: 0;
}

.checkout-cart-index {
  .page.messages {
    display: block;
    position: relative;
    margin: 0;
    padding: 50px 1rem;

    div {
      top: 25px;
      text-align: center;
      margin: auto;
    }

    @include tablet {
      margin: 30px auto 0;
      padding: 0;

      div {
        top: 0;
      }
    }
  }
}

.login {
  .container {
    label {
      color: #888;
      float: left;
      font-weight: 300;
      padding-right: 10px;
      text-align: right!important;
      width: 30%!important;
    }
    .control {
      float: left;
      width: 65%;
      @media screen and (min-width: 768px){
        float: right;
      }
    }
  }
  .btn--link {
    color: #ff4f41;
    font-weight: 400;
    text-align: right;
    text-transform: capitalize;
    width: 100%;
  }
  .btn--goldgradient {
    border-style: outset;
    float: right;
    font-size: 20px!important;
  }
}

.checkout-index-index {
  .primary-header__logo {
    position: relative;
    left: auto;
    right: auto;
    margin-left: 1rem;
  }

  .my-cart {
    display: none;
  }

  .continue-shopping {
    display: block !important;
    margin-right: 1rem;
  }
}

.forgotpass {
  margin: 30px 0;
  background: #F7F7F7;
  padding: 25px 15px 25px 10px;
  color: #888;
  text-shadow: 1px 1px 1px #FFF;
  border: 1px solid #E4E4E4;
  p {
    color: #2c2c2c;
  }
  .form.password.forget {
    width: 100%;
  }
}
