.container {
  transition: all 0.5s ease;
  margin: 0 auto;
  position: relative;
  padding: 1rem .5rem;
  max-width: 1152px;
  @include tablet {
    padding: 1rem 3rem;
  }

  @include desktop {
    padding: 2rem 1rem;
  }

  &--padding-sides {
    padding: 0 .5rem;

    @include desktop {
      padding: 0;
    }
  }

  &--zero-padding {
    padding: 0;
  }

  &--zero-top {
    padding-top: 0 !important;
  }

  &--zero-bottom {
    padding-bottom: 0 !important;
  }

  &--wide {
    max-width: 1400px;
  }
  &--smallPadding {
    padding: 2rem 1rem;
    @media screen and (min-width: 768px){
      padding: 2rem 3rem;
    }
  }
}

.columns .columns {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.page-main {
  // margin-top: 55px !important;
}
