
.category-blockquote {
  margin-top: 40px;
  color: white;

  @include desktop {
    display: flex;
  }

  p {
    font-family: $font1;
  }
}

.category-blockquote__img {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 20px auto;

  @include tablet-only {
    margin: 20px 40px 20px 0;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:before {
    position: absolute;
    content: '';
    width: 140px;
    height: 140px;
    display: block;
    border: 4px solid #009FD0;
    border-radius: 50%;
    top: -10px;
    left: -10px;
    z-index: 10;
  }
}

.category-blockquote__id,
.category-blockquote__quote {
  background-color: $light-black;
  padding: 30px 20px;
  text-align: center;
}

.category-blockquote__id {
  border-radius: 10px 10px 0 0;
  color: #d7d7d7;
  padding: 40px;

  @include tablet-only {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0 0;
  }

  @include desktop {
    width: 240px;
    margin-right: 5px;
    border-radius: 10px 0 0 10px;
    padding: 20px;
  }
}

.category-blockquote__id-bio {
  text-align: center;

  @include tablet-only {
    width: 200px;
  }

  @include tablet {
    text-align: left;
  }

  p {
    line-height: 1.5rem;
    font-size: .9rem;
  }
}

.category-blockquote__name {
  font-size: 1.5rem !important;
  margin-bottom: 10px !important;
  color: white;
}

.category-blockquote__quote {
  border-radius: 0 0 10px 10px;

  @include desktop {
    width: calc(100% - 245px);
    border-radius: 0 10px 10px 0;
  }

  p {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0 10px;
    position: relative;

    @include tablet {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 0 10px;
    }

    @include desktop {
      margin: 0 60px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 16px;
      background-image: url(../images/focus/svg/blockquote-symbol-blue.svg);
      background-size: contain;

      @include tablet {
        width: 40px;
        height: 32px;
      }
    }

    &:before {
      left: -20px;
      top: 0;

      @include tablet {
        left: -55px;
        top: -20px
      }

      @include desktop {
        top: 0
      }
    }

    &:after {
      right: -20px;
      bottom: 0;
      transform: rotate(180deg);

      @include tablet {
        right: -45px;
        bottom: -10px;
      }

      @include desktop {
        bottom: 0;
      }
    }
  }
}

.category-blockquote__quote-text {
  font-size: 2rem
}
