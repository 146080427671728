.cookie-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 1152px;
  display: block;
  z-index: 1000000;
  background-color: $lightest-grey;
  border-radius: 5px 5px 0 0;

  p {
    margin: 0;
  }

  .btn {
    font-size: 1.3rem;
    padding: 8px 16px;
    @include desktop {
      margin: 0 0 0 30px;
    }
  }
}

.cookie-notice-inner {
  padding: 10px;

  @include desktop {
    @include flex-center;
  }
}

.align-center-mobile {
  text-align: center;
  flex: none;
}
