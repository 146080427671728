.country-selection-list--list {
  padding-left:0;
  .country-selection-list--item {
    width:100%;
    list-style-type:none;
    margin: 0 auto;
    margin-bottom: 20px;
    @media screen and (min-width: 768px){
      float: left;
      width: 48%;
      margin:1%;
    }

    a {
      background: #f7f7f7;
      color: #2c2c2c;
      display: block;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      padding: 10px 20px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      img {
        float: right;
        width: 35px;
      }

      &:hover,
      &:focus {
        color:#ff4f41;
      }
    }
  }
}
