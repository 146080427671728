.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}

.flag-icon-ad {
  background-image: url("../images/flags/ad.svg");
}
.flag-icon-ae {
  background-image: url("../images/flags/ae.svg");
}
.flag-icon-af {
  background-image: url("../images/flags/af.svg");
}
.flag-icon-ag {
  background-image: url("../images/flags/ag.svg");
}
.flag-icon-ai {
  background-image: url("../images/flags/ai.svg");
}
.flag-icon-al {
  background-image: url("../images/flags/al.svg");
}
.flag-icon-am {
  background-image: url("../images/flags/am.svg");
}
.flag-icon-ao {
  background-image: url("../images/flags/ao.svg");
}
.flag-icon-aq {
  background-image: url("../images/flags/aq.svg");
}
.flag-icon-ar {
  background-image: url("../images/flags/ar.svg");
}
.flag-icon-as {
  background-image: url("../images/flags/as.svg");
}
.flag-icon-at {
  background-image: url("../images/flags/at.svg");
}
.flag-icon-au {
  background-image: url("../images/flags/au.svg");
}
.flag-icon-aw {
  background-image: url("../images/flags/aw.svg");
}
.flag-icon-ax {
  background-image: url("../images/flags/ax.svg");
}
.flag-icon-az {
  background-image: url("../images/flags/az.svg");
}
.flag-icon-ba {
  background-image: url("../images/flags/ba.svg");
}
.flag-icon-bb {
  background-image: url("../images/flags/bb.svg");
}
.flag-icon-bd {
  background-image: url("../images/flags/bd.svg");
}
.flag-icon-be {
  background-image: url("../images/flags/be.svg");
}
.flag-icon-bf {
  background-image: url("../images/flags/bf.svg");
}
.flag-icon-bg {
  background-image: url("../images/flags/bg.svg");
}
.flag-icon-bh {
  background-image: url("../images/flags/bh.svg");
}
.flag-icon-bi {
  background-image: url("../images/flags/bi.svg");
}
.flag-icon-bj {
  background-image: url("../images/flags/bj.svg");
}
.flag-icon-bl {
  background-image: url("../images/flags/bl.svg");
}
.flag-icon-bm {
  background-image: url("../images/flags/bm.svg");
}
.flag-icon-bn {
  background-image: url("../images/flags/bn.svg");
}
.flag-icon-bo {
  background-image: url("../images/flags/bo.svg");
}
.flag-icon-bq {
  background-image: url("../images/flags/bq.svg");
}
.flag-icon-br {
  background-image: url("../images/flags/br.svg");
}
.flag-icon-bs {
  background-image: url("../images/flags/bs.svg");
}
.flag-icon-bt {
  background-image: url("../images/flags/bt.svg");
}
.flag-icon-bv {
  background-image: url("../images/flags/bv.svg");
}
.flag-icon-bw {
  background-image: url("../images/flags/bw.svg");
}
.flag-icon-by {
  background-image: url("../images/flags/by.svg");
}
.flag-icon-bz {
  background-image: url("../images/flags/bz.svg");
}
.flag-icon-ca {
  background-image: url("../images/flags/ca.svg");
}
.flag-icon-cc {
  background-image: url("../images/flags/cc.svg");
}
.flag-icon-cd {
  background-image: url("../images/flags/cd.svg");
}
.flag-icon-cf {
  background-image: url("../images/flags/cf.svg");
}
.flag-icon-cg {
  background-image: url("../images/flags/cg.svg");
}
.flag-icon-ch {
  background-image: url("../images/flags/ch.svg");
}
.flag-icon-ci {
  background-image: url("../images/flags/ci.svg");
}
.flag-icon-ck {
  background-image: url("../images/flags/ck.svg");
}
.flag-icon-cl {
  background-image: url("../images/flags/cl.svg");
}
.flag-icon-cm {
  background-image: url("../images/flags/cm.svg");
}
.flag-icon-cn {
  background-image: url("../images/flags/cn.svg");
}
.flag-icon-co {
  background-image: url("../images/flags/co.svg");
}
.flag-icon-cr {
  background-image: url("../images/flags/cr.svg");
}
.flag-icon-cu {
  background-image: url("../images/flags/cu.svg");
}
.flag-icon-cv {
  background-image: url("../images/flags/cv.svg");
}
.flag-icon-cw {
  background-image: url("../images/flags/cw.svg");
}
.flag-icon-cx {
  background-image: url("../images/flags/cx.svg");
}
.flag-icon-cy {
  background-image: url("../images/flags/cy.svg");
}
.flag-icon-cz {
  background-image: url("../images/flags/cz.svg");
}
.flag-icon-de {
  background-image: url("../images/flags/de.svg");
}
.flag-icon-dj {
  background-image: url("../images/flags/dj.svg");
}
.flag-icon-dk {
  background-image: url("../images/flags/dk.svg");
}
.flag-icon-dm {
  background-image: url("../images/flags/dm.svg");
}
.flag-icon-do {
  background-image: url("../images/flags/do.svg");
}
.flag-icon-dz {
  background-image: url("../images/flags/dz.svg");
}
.flag-icon-ec {
  background-image: url("../images/flags/ec.svg");
}
.flag-icon-ee {
  background-image: url("../images/flags/ee.svg");
}
.flag-icon-eg {
  background-image: url("../images/flags/eg.svg");
}
.flag-icon-eh {
  background-image: url("../images/flags/eh.svg");
}
.flag-icon-er {
  background-image: url("../images/flags/er.svg");
}
.flag-icon-es {
  background-image: url("../images/flags/es.svg");
}
.flag-icon-et {
  background-image: url("../images/flags/et.svg");
}
.flag-icon-fi {
  background-image: url("../images/flags/fi.svg");
}
.flag-icon-fj {
  background-image: url("../images/flags/fj.svg");
}
.flag-icon-fk {
  background-image: url("../images/flags/fk.svg");
}
.flag-icon-fm {
  background-image: url("../images/flags/fm.svg");
}
.flag-icon-fo {
  background-image: url("../images/flags/fo.svg");
}
.flag-icon-fr {
  background-image: url("../images/flags/fr.svg");
}
.flag-icon-ga {
  background-image: url("../images/flags/ga.svg");
}
.flag-icon-gb {
  background-image: url("../images/flags/gb.svg");
}
.flag-icon-gd {
  background-image: url("../images/flags/gd.svg");
}
.flag-icon-ge {
  background-image: url("../images/flags/ge.svg");
}
.flag-icon-gf {
  background-image: url("../images/flags/gf.svg");
}
.flag-icon-gg {
  background-image: url("../images/flags/gg.svg");
}
.flag-icon-gh {
  background-image: url("../images/flags/gh.svg");
}
.flag-icon-gi {
  background-image: url("../images/flags/gi.svg");
}
.flag-icon-gl {
  background-image: url("../images/flags/gl.svg");
}
.flag-icon-gm {
  background-image: url("../images/flags/gm.svg");
}
.flag-icon-gn {
  background-image: url("../images/flags/gn.svg");
}
.flag-icon-gp {
  background-image: url("../images/flags/gp.svg");
}
.flag-icon-gq {
  background-image: url("../images/flags/gq.svg");
}
.flag-icon-gr {
  background-image: url("../images/flags/gr.svg");
}
.flag-icon-gs {
  background-image: url("../images/flags/gs.svg");
}
.flag-icon-gt {
  background-image: url("../images/flags/gt.svg");
}
.flag-icon-gu {
  background-image: url("../images/flags/gu.svg");
}
.flag-icon-gw {
  background-image: url("../images/flags/gw.svg");
}
.flag-icon-gy {
  background-image: url("../images/flags/gy.svg");
}
.flag-icon-hk {
  background-image: url("../images/flags/hk.svg");
}
.flag-icon-hm {
  background-image: url("../images/flags/hm.svg");
}
.flag-icon-hn {
  background-image: url("../images/flags/hn.svg");
}
.flag-icon-hr {
  background-image: url("../images/flags/hr.svg");
}
.flag-icon-ht {
  background-image: url("../images/flags/ht.svg");
}
.flag-icon-hu {
  background-image: url("../images/flags/hu.svg");
}
.flag-icon-id {
  background-image: url("../images/flags/id.svg");
}
.flag-icon-ie {
  background-image: url("../images/flags/ie.svg");
}
.flag-icon-il {
  background-image: url("../images/flags/il.svg");
}
.flag-icon-im {
  background-image: url("../images/flags/im.svg");
}
.flag-icon-in {
  background-image: url("../images/flags/in.svg");
}
.flag-icon-io {
  background-image: url("../images/flags/io.svg");
}
.flag-icon-iq {
  background-image: url("../images/flags/iq.svg");
}
.flag-icon-ir {
  background-image: url("../images/flags/ir.svg");
}
.flag-icon-is {
  background-image: url("../images/flags/is.svg");
}
.flag-icon-it {
  background-image: url("../images/flags/it.svg");
}
.flag-icon-je {
  background-image: url("../images/flags/je.svg");
}
.flag-icon-jm {
  background-image: url("../images/flags/jm.svg");
}
.flag-icon-jo {
  background-image: url("../images/flags/jo.svg");
}
.flag-icon-jp {
  background-image: url("../images/flags/jp.svg");
}
.flag-icon-ke {
  background-image: url("../images/flags/ke.svg");
}
.flag-icon-kg {
  background-image: url("../images/flags/kg.svg");
}
.flag-icon-kh {
  background-image: url("../images/flags/kh.svg");
}
.flag-icon-ki {
  background-image: url("../images/flags/ki.svg");
}
.flag-icon-km {
  background-image: url("../images/flags/km.svg");
}
.flag-icon-kn {
  background-image: url("../images/flags/kn.svg");
}
.flag-icon-kp {
  background-image: url("../images/flags/kp.svg");
}
.flag-icon-kr {
  background-image: url("../images/flags/kr.svg");
}
.flag-icon-kw {
  background-image: url("../images/flags/kw.svg");
}
.flag-icon-ky {
  background-image: url("../images/flags/ky.svg");
}
.flag-icon-kz {
  background-image: url("../images/flags/kz.svg");
}
.flag-icon-la {
  background-image: url("../images/flags/la.svg");
}
.flag-icon-lb {
  background-image: url("../images/flags/lb.svg");
}
.flag-icon-lc {
  background-image: url("../images/flags/lc.svg");
}
.flag-icon-li {
  background-image: url("../images/flags/li.svg");
}
.flag-icon-lk {
  background-image: url("../images/flags/lk.svg");
}
.flag-icon-lr {
  background-image: url("../images/flags/lr.svg");
}
.flag-icon-ls {
  background-image: url("../images/flags/ls.svg");
}
.flag-icon-lt {
  background-image: url("../images/flags/lt.svg");
}
.flag-icon-lu {
  background-image: url("../images/flags/lu.svg");
}
.flag-icon-lv {
  background-image: url("../images/flags/lv.svg");
}
.flag-icon-ly {
  background-image: url("../images/flags/ly.svg");
}
.flag-icon-ma {
  background-image: url("../images/flags/ma.svg");
}
.flag-icon-mc {
  background-image: url("../images/flags/mc.svg");
}
.flag-icon-md {
  background-image: url("../images/flags/md.svg");
}
.flag-icon-me {
  background-image: url("../images/flags/me.svg");
}
.flag-icon-mf {
  background-image: url("../images/flags/mf.svg");
}
.flag-icon-mg {
  background-image: url("../images/flags/mg.svg");
}
.flag-icon-mh {
  background-image: url("../images/flags/mh.svg");
}
.flag-icon-mk {
  background-image: url("../images/flags/mk.svg");
}
.flag-icon-ml {
  background-image: url("../images/flags/ml.svg");
}
.flag-icon-mm {
  background-image: url("../images/flags/mm.svg");
}
.flag-icon-mn {
  background-image: url("../images/flags/mn.svg");
}
.flag-icon-mo {
  background-image: url("../images/flags/mo.svg");
}
.flag-icon-mp {
  background-image: url("../images/flags/mp.svg");
}
.flag-icon-mq {
  background-image: url("../images/flags/mq.svg");
}
.flag-icon-mr {
  background-image: url("../images/flags/mr.svg");
}
.flag-icon-ms {
  background-image: url("../images/flags/ms.svg");
}
.flag-icon-mt {
  background-image: url("../images/flags/mt.svg");
}
.flag-icon-mu {
  background-image: url("../images/flags/mu.svg");
}
.flag-icon-mv {
  background-image: url("../images/flags/mv.svg");
}
.flag-icon-mw {
  background-image: url("../images/flags/mw.svg");
}
.flag-icon-mx {
  background-image: url("../images/flags/mx.svg");
}
.flag-icon-my {
  background-image: url("../images/flags/my.svg");
}
.flag-icon-mz {
  background-image: url("../images/flags/mz.svg");
}
.flag-icon-na {
  background-image: url("../images/flags/na.svg");
}
.flag-icon-nc {
  background-image: url("../images/flags/nc.svg");
}
.flag-icon-ne {
  background-image: url("../images/flags/ne.svg");
}
.flag-icon-nf {
  background-image: url("../images/flags/nf.svg");
}
.flag-icon-ng {
  background-image: url("../images/flags/ng.svg");
}
.flag-icon-ni {
  background-image: url("../images/flags/ni.svg");
}
.flag-icon-nl {
  background-image: url("../images/flags/nl.svg");
}
.flag-icon-no {
  background-image: url("../images/flags/no.svg");
}
.flag-icon-np {
  background-image: url("../images/flags/np.svg");
}
.flag-icon-nr {
  background-image: url("../images/flags/nr.svg");
}
.flag-icon-nu {
  background-image: url("../images/flags/nu.svg");
}
.flag-icon-nz {
  background-image: url("../images/flags/nz.svg");
}
.flag-icon-om {
  background-image: url("../images/flags/om.svg");
}
.flag-icon-pa {
  background-image: url("../images/flags/pa.svg");
}
.flag-icon-pe {
  background-image: url("../images/flags/pe.svg");
}
.flag-icon-pf {
  background-image: url("../images/flags/pf.svg");
}
.flag-icon-pg {
  background-image: url("../images/flags/pg.svg");
}
.flag-icon-ph {
  background-image: url("../images/flags/ph.svg");
}
.flag-icon-pk {
  background-image: url("../images/flags/pk.svg");
}
.flag-icon-pl {
  background-image: url("../images/flags/pl.svg");
}
.flag-icon-pm {
  background-image: url("../images/flags/pm.svg");
}
.flag-icon-pn {
  background-image: url("../images/flags/pn.svg");
}
.flag-icon-pr {
  background-image: url("../images/flags/pr.svg");
}
.flag-icon-ps {
  background-image: url("../images/flags/ps.svg");
}
.flag-icon-pt {
  background-image: url("../images/flags/pt.svg");
}
.flag-icon-pw {
  background-image: url("../images/flags/pw.svg");
}
.flag-icon-py {
  background-image: url("../images/flags/py.svg");
}
.flag-icon-qa {
  background-image: url("../images/flags/qa.svg");
}
.flag-icon-re {
  background-image: url("../images/flags/re.svg");
}
.flag-icon-ro {
  background-image: url("../images/flags/ro.svg");
}
.flag-icon-rs {
  background-image: url("../images/flags/rs.svg");
}
.flag-icon-ru {
  background-image: url("../images/flags/ru.svg");
}
.flag-icon-rw {
  background-image: url("../images/flags/rw.svg");
}
.flag-icon-sa {
  background-image: url("../images/flags/sa.svg");
}
.flag-icon-sb {
  background-image: url("../images/flags/sb.svg");
}
.flag-icon-sc {
  background-image: url("../images/flags/sc.svg");
}
.flag-icon-sd {
  background-image: url("../images/flags/sd.svg");
}
.flag-icon-se {
  background-image: url("../images/flags/se.svg");
}
.flag-icon-sg {
  background-image: url("../images/flags/sg.svg");
}
.flag-icon-sh {
  background-image: url("../images/flags/sh.svg");
}
.flag-icon-si {
  background-image: url("../images/flags/si.svg");
}
.flag-icon-sj {
  background-image: url("../images/flags/sj.svg");
}
.flag-icon-sk {
  background-image: url("../images/flags/sk.svg");
}
.flag-icon-sl {
  background-image: url("../images/flags/sl.svg");
}
.flag-icon-sm {
  background-image: url("../images/flags/sm.svg");
}
.flag-icon-sn {
  background-image: url("../images/flags/sn.svg");
}
.flag-icon-so {
  background-image: url("../images/flags/so.svg");
}
.flag-icon-sr {
  background-image: url("../images/flags/sr.svg");
}
.flag-icon-ss {
  background-image: url("../images/flags/ss.svg");
}
.flag-icon-st {
  background-image: url("../images/flags/st.svg");
}
.flag-icon-sv {
  background-image: url("../images/flags/sv.svg");
}
.flag-icon-sx {
  background-image: url("../images/flags/sx.svg");
}
.flag-icon-sy {
  background-image: url("../images/flags/sy.svg");
}
.flag-icon-sz {
  background-image: url("../images/flags/sz.svg");
}
.flag-icon-tc {
  background-image: url("../images/flags/tc.svg");
}
.flag-icon-td {
  background-image: url("../images/flags/td.svg");
}
.flag-icon-tf {
  background-image: url("../images/flags/tf.svg");
}
.flag-icon-tg {
  background-image: url("../images/flags/tg.svg");
}
.flag-icon-th {
  background-image: url("../images/flags/th.svg");
}
.flag-icon-tj {
  background-image: url("../images/flags/tj.svg");
}
.flag-icon-tk {
  background-image: url("../images/flags/tk.svg");
}
.flag-icon-tl {
  background-image: url("../images/flags/tl.svg");
}
.flag-icon-tm {
  background-image: url("../images/flags/tm.svg");
}
.flag-icon-tn {
  background-image: url("../images/flags/tn.svg");
}
.flag-icon-to {
  background-image: url("../images/flags/to.svg");
}
.flag-icon-tr {
  background-image: url("../images/flags/tr.svg");
}
.flag-icon-tt {
  background-image: url("../images/flags/tt.svg");
}
.flag-icon-tv {
  background-image: url("../images/flags/tv.svg");
}
.flag-icon-tw {
  background-image: url("../images/flags/tw.svg");
}
.flag-icon-tz {
  background-image: url("../images/flags/tz.svg");
}
.flag-icon-ua {
  background-image: url("../images/flags/ua.svg");
}
.flag-icon-ug {
  background-image: url("../images/flags/ug.svg");
}
.flag-icon-um {
  background-image: url("../images/flags/um.svg");
}
.flag-icon-us {
  background-image: url("../images/flags/us.svg");
}
.flag-icon-uy {
  background-image: url("../images/flags/uy.svg");
}
.flag-icon-uz {
  background-image: url("../images/flags/uz.svg");
}
.flag-icon-va {
  background-image: url("../images/flags/va.svg");
}
.flag-icon-vc {
  background-image: url("../images/flags/vc.svg");
}
.flag-icon-ve {
  background-image: url("../images/flags/ve.svg");
}
.flag-icon-vg {
  background-image: url("../images/flags/vg.svg");
}
.flag-icon-vi {
  background-image: url("../images/flags/vi.svg");
}
.flag-icon-vn {
  background-image: url("../images/flags/vn.svg");
}
.flag-icon-vu {
  background-image: url("../images/flags/vu.svg");
}
.flag-icon-wf {
  background-image: url("../images/flags/wf.svg");
}
.flag-icon-ws {
  background-image: url("../images/flags/ws.svg");
}
.flag-icon-ye {
  background-image: url("../images/flags/ye.svg");
}
.flag-icon-yt {
  background-image: url("../images/flags/yt.svg");
}
.flag-icon-za {
  background-image: url("../images/flags/za.svg");
}
.flag-icon-zm {
  background-image: url("../images/flags/zm.svg");
}
.flag-icon-zw {
  background-image: url("../images/flags/zw.svg");
}
.flag-icon-eu {
  background-image: url("../images/flags/eu.svg");
}
.flag-icon-gb-eng {
  background-image: url("../images/flags/gb-eng.svg");
}
.flag-icon-gb-nir {
  background-image: url("../images/flags/gb-nir.svg");
}
.flag-icon-gb-sct {
  background-image: url("../images/flags/gb-sct.svg");
}
.flag-icon-gb-wls {
  background-image: url("../images/flags/gb-wls.svg");
}
