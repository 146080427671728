.login__loginbox {
  background: #F7F7F7;
  border: 1px solid #E4E4E4;
  color: #888;
  padding: 25px 15px 25px 10px;
  text-shadow: 1px 1px 1px #fff;
}


.page-main {
  background: $white;
}
.generic-banner {
  background: #28235d;
  color: $white;
  .container {
    @include tablet {
      background: #28235d url(../images/ingredients/ingredients-banner.jpg);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include desktop {
      padding: 4rem 1rem;
    }
  }
}

.generic-banner-white {
  background: #28235d;
  color: $white;
  .container {
    @include tablet {
      background: #28235d url(../images/ingredients/ingredients-banner-white.jpg);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include desktop {
      padding: 4rem 1rem;
    }
  }
}

ul.ul-list {
  list-style-type: disc;
  margin-bottom: 40px;
  padding-left: 2rem;
  li {
      margin-bottom: 0.5rem
  }
}
.section--withsidebar {
  .blue-bg, .orange-bg {
    margin-bottom: 30px;
    padding: 1rem;
    .button--green {
      width: 100%;
    }
  }
  .orange-bg {
    color: #28235d;
  }
}
body[class*="contact-index-index "] .form {
  background: #F7F7F7;
  border:   1px solid #E4E4E4;
  color: #888;
  text-shadow: 1px 1px 1px $white;
  .contact__title {
    border-bottom: 1px solid #E4E4E4;
    h2 {
      font-weight: 400;
      margin:0;
      padding: 20px;
    }
  }
  .fieldset {
    margin-bottom:0;
    padding: 20px;
    label {
      display: inline-block!important;
      font-weight: 300;
      width: 100%!important;
    }
    input, textarea {
      box-shadow: inset 0px 1px 4px #ECECEC;
    -moz-box-shadow: inset 0px 1px 4px #ECECEC;
    -webkit-box-shadow: inset 0px 1px 4px #ECECEC;
      color: #888;
      width: 100%;
    }
  }
  .actions-toolbar {
    padding: 0 20px 20px 20px;
    .btn--goldgradient {
      border-style: outset;
    }
  }
}

.checkout-onepage-success .product-img {
  float: left;
  margin: 0 20px 10px 0;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 768px){
    width: auto;
  }
  &--4g {
    width: 100%;
    img {
      width: 80%;
      @media screen and (min-width: 768px){
        width: 100%;
      }
    }
    @media screen and (min-width: 768px){
      width: 31%;
    }
  }
  &--testofuel {
    float: right;
    margin: 0 0 10px 20px;
    width: 100%;
    img {
      width: 80%;
      @media screen and (min-width: 768px){
        width: 100%;
      }
    }
    @media screen and (min-width: 768px){
      width: 30%;
    }
  }
}
