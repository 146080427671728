.widget-section--about {
  overflow: hidden;
  .badge {
    display: block;
    margin: 0 auto 20px;
  }
  .reasons {
    counter-reset: li;
    position: relative;
    & >li {
      list-style: none;
      position: relative;
      padding: 0 0 0 60px;
      &:nth-child(3) {
        margin-bottom: 20px;
        @include tablet {
          margin-bottom: 25px;
        }
      }
      span {
        display: block;
        margin-bottom:0;
      }
      &:before {
        border: 5px solid #ff4f41;
        border-radius: 29px;
        color: #ff4f41;
        content: counter(li);
        counter-increment: li;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        left: 0;
        height: 45px;
        line-height: 1em;
        padding-top: 3px;
        position: absolute;
        text-align: center;
        top: 0px;
        width: 50px;
        @include tablet {
          font-size: 1.85em;
          height: 45px;
          width: 45px;
        }
      }
    }
  }
}

.products {
  .is-flex {
    align-items: center;
  }
  .featuredtitle {
    p {
      background: #fff;
      border: 2px solid #29215c;
      border-radius: 2px;
      color: #29215c;
      font-size: 1.35rem;
      font-weight: 700;
      padding: 0.5rem 2rem;
      position: absolute;
      top: 1px;
      text-transform: uppercase;
      left: 50%;
      transform: translate(-50%, 0);
      i {
        color: #ff4f41;
        margin-right: 10px;
      }
    }
  }
  .price-container .price {
    font-size: inherit;
  }
  ul {
    margin-left: 16px;
    li {
      font-size: 1.5rem;
      line-height: 1.7rem;
      &:before {
        top: 6px;
      }
      ul {
        margin-left:0;
        margin-top: 0.5rem;
        li {
          font-family: "Roboto", "Open Sans", Helvetica, Arial, sans-serif;
          font-size: 0.9rem;
          line-height: 1.2rem;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .title {
    padding-bottom: 1rem;
  }

  .category_usps {

    ul {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin: 0;
      @include tablet {
        flex-flow: row;
      }
      li {
        font-family: "Pathway Gothic One" !important;
        -webkit-box-align: center;
        align-items: center;
        background: none;
        //border: 1px solid #ff4f41;
        color: #29225c;
        border-radius: 3px;
        display: flex;
        flex-flow: row;
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 1.2rem;
        justify-content: center;
        margin: 0.5%;
        //padding: 0.5rem;
        position: relative;
        text-align: left;
        width: 32%;
        @include tablet {
          font-size: 0.7rem;
          line-height: 0.8rem;
          justify-content: flex-start;
          width: 49%;
        }
        i {
          color: #ff4f41;
          margin-right: 8px;
        }
        @include tablet {
          font-size: 1.25rem;
          line-height: 1.4rem;
          text-align: left;
          width: 100%;
          flex-flow: row;
          display: flex;
        }
      }
    }
  }
}
.product-border {
  border: 2px solid #29215c;
  border-radius: 4px;
  box-shadow: 1px 4px 10px #e3e3e3;
}
.product-item-half {
  .product-border {
    border: 2px solid #29215c;
  }
}
.widget-section--ingredients {
  ol {
    padding-left: 30px;
  }
}

.product, .is-products-view-top {
  .triangle-list {
    border: 0;
    padding: 20px 35px 20px 30px;
  }

  .price-box  {
    margin-bottom: 20px;
    .price-container .price {
      float: left;
      font-size: 1.75rem;
      font-weight: 800;
      line-height: 2.5rem;
      margin-bottom:0;
      @include tablet {
        font-size: 2.375rem;
        line-height: 2.875rem;
        margin-bottom:0;
      }
    }
  }
  .borders {
    border: 1px solid #29215c;
  }
}

.bundle-options-container .product-add-form {
  display: block;
  .bundle-options-wrapper {
    width: 100%;
    .block-bundle-summary {
      background: none;
      padding: 0;
      width: 100%;
      .product-single-qty {
        .control {
          float: left;
          width: 50%;
          input {
            border: 0;
            padding: 0.75em 0.1em 0.25em;
            text-align: left;
            width: 69%;
            &:focus {
              &+.label {
                pointer-events: none;
                span {
                  opacity: 0;
                  transform: translate3d(0, -50%, 0);
                  transition: opacity 0.3s, transform 0.3s;
                  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
                }
                &:after {
                  opacity: 1;
                  transform: translate3d(0, 0, 0);
                }
                &:before {
                  background-color: #e20613;
                  transform: scale3d(1, 1, 1);
                }
              }
            }
          }
          label {
            float: left;
            width: 69%;
            display: inline-block;
            position: relative;
            overflow: hidden;
            padding: 12px 20px 0 0;
            color: #474747;
            text-align: left;
            span {
              display: block;
              padding: 0em 0 0.75em 0.15em;
              position: relative;
              transition: opacity 0.3s, transform 0.3s;
              transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
            }
            &:after {
              color: #e20613;
              content:"Qty";
              left: 0;
              opacity: 0;
              padding: 0.75em 0.15em;
              pointer-events: none;
              position: absolute;
              top: 0;
              transform: translate3d(0, 50%, 0);
              transition: opacity 0.3s, transform 0.3s;
              transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
            }
            &:before {
              content: '';
              background: #474747;
              height: 7px;
              position: absolute;
              top: 0;
              transform: scale3d(1, 0.4, 1);
              transform-origin: 50% 100%;
              transition: transform 0.3s, background-color 0.3s;
              transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
              width: 100%;
            }
          }
        }
        .actions {
          float: left;
          width: 50%;
          .btn--green {
            width: 100%;
          }
        }
      }
    }
  }
}
