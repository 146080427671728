/* ===========================
   Snowdog blank styles
  =========================== */
  @import '../../../vendor/snowdog/theme-blank-sass/styles/styles';


/* ===========================
   Base styles
  =========================== */
  @import 'base/bulma/bulma';
  @import 'base/vendor/magento-ui/variables/responsive';
  @import 'base/vendor/magento-ui/variables/colors';
  @import 'base/vendor/flag-icon';
  @import 'base/vendor/infogrid';
  @import 'base/vendor/owl.carousel';
  @import 'base/vendor/owl.theme.default';
  @import 'base/utilities/mixins';
  @import 'base/utilities/helpers';


/* ===========================================================
   Theme - Base
  ============================================================ */
  @import 'theme/utilities/theme-options';
  @import 'theme/utilities/helpers';
  @import 'theme/utilities/typography';


  /* ===========================================================
     Theme - FA
    ============================================================ */

    @import 'theme/fa/fontawesome';
    @import 'theme/fa/regular';
    @import 'theme/fa/solid';
    @import 'theme/fa/duotone';


/* ===========================================================
   Theme - Layout
  ============================================================ */
  @import 'theme/layout/page';
  @import 'theme/layout/container';
  @import 'theme/layout/header';
  @import 'theme/layout/footer';


/* ============================
   Theme - Components
  ============================ */
  // @import 'theme/components/nav';
  @import 'theme/components/new-nav';
  @import 'theme/components/cookie';
  @import 'theme/components/modals';
  @import 'theme/components/module';
  @import 'theme/components/messages';
  @import 'theme/components/countryselect';
  @import 'theme/components/buttons';
  @import 'theme/components/accordion';
  @import 'theme/components/hero';
  //@import 'theme/components/cards';
  @import 'theme/components/key-points';
  @import 'theme/components/guarantee';
  //@import 'theme/components/testimonials';
  @import 'theme/components/product-list';
  @import 'theme/components/icons';
  @import 'theme/components/references';
  @import 'theme/components/video';
  @import 'theme/components/full-width-cta';
  @import 'theme/components/forms';


/* ========================================
   Theme - Pages
  ======================================== */

  @import 'theme/pages/product/product';

  @import 'theme/pages/category/category';
  @import 'theme/pages/category/category-blockquote';
  @import 'theme/pages/category/category-ingredients';

  //@import 'theme/pages/category/focus/focus';
  //@import 'theme/pages/category/focus/focus-hero';
  //@import 'theme/pages/category/focus/focus-nootropic';
  //@import 'theme/pages/category/focus/focus-usp-offset';

  //@import 'theme/pages/category/test/test';
  //@import 'theme/pages/category/test/test-hero';

  //@import 'theme/pages/category/burn/burn';
  //@import 'theme/pages/category/burn/burn-hero';

  @import 'theme/pages/customer/checkout';
  @import 'theme/pages/customer/cart';
  @import 'theme/pages/customer/account';

  @import 'theme/pages/homepage';
  @import 'theme/pages/pages';
  @import 'theme/pages/howitworks';
  @import 'theme/pages/ingredients';
  @import 'theme/pages/testimonials';
  @import 'theme/pages/lowtestosterone';
  @import 'theme/pages/faqs';
  @import 'theme/pages/robbyrobinson';

/* =============================
   Overrides
  ============================ */
  // @import 'overrides';
