// FONTS

@import url('https://fonts.googleapis.com/css?family=Arvo:400,700|Roboto+Condensed:300,400,700|Roboto:100,300,400,500,700&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap');



$font1: 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif;
$font2: 'Open Sans', 'Open Sans', Helvetica, Arial, sans-serif;
$font3: 'Pathway Gothic One' !important;


// RESPONSIVE BREAKPOINTS
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1152px;
$breakpoint-xl: 1400px;

$xs-min: $breakpoint-xs;
$xs-max: ($breakpoint-sm - 1);

$sm-min: $breakpoint-sm;
$sm-max: ($breakpoint-md - 1);

$md-min: $breakpoint-md;
$md-max: ($breakpoint-lg - 1);

$lg-min: $breakpoint-lg;
$lg-max: ($breakpoint-xl - 1);

$xl-min: $breakpoint-xl;


// COLOURS

$white: #ffffff;
$black: #000000;

$instant-red: #da0f1a;
$instant-grey: #d4d4d4;
$instant-tan: #87805C;
$instant-gold: #b2a778;
$instant-darkgrey: #2c2c2c;


$hunter-orange: #f15a29;
$hunter-yellow: #faa619;
$hunter-blue: #009FD0;
$hunter-green: #00cf12;

$blue: #292cf1;
$blue-alt: #090bab;
$red: #bd0215;

$lightest-grey: #f2f2f2;
$light-grey: #e7e7e7;
$mid-light-grey: #b6b6b6;
$light-black: #162225;
$dark-grey: #494949;
$off-black: #0c0e12;
$btn-blue: $blue;
$btn-blue-hover: $blue-alt;

// Test colours
$test-orange: $hunter-orange;
$test-blue: #29f1ee;
$test-peach: #ff9e80;

// Burn colours
$burn-yellow: $hunter-yellow;
$burn-green: #c2fa19;
$burn-peach: #ffcf80;

// Focus colours
$focus-blue: $hunter-blue;
$focus-green: $hunter-green;
$focus-light-blue: #9ad7f1;


// FONT SIZE MIXINS

@mixin font-size($fontSize, $lineHeight) {
  font-size: ($fontSize) + rem;
  line-height: ($lineHeight) + rem;
  margin-bottom: 1.5rem;
}

@mixin s-text {
  @include font-size(0.8, 1);
}

@mixin reg-text {
  @include font-size(1, 1.25);
}

@mixin sm-text {
  @include font-size(1, 1.65);

  @include tablet {
    @include font-size(1.25, 1.75);
  }
}

@mixin m-text {
  @include font-size(1.25, 1.75);


  @include tablet {
    @include font-size(1.5, 2);
  }

  @include desktop {
    // margin-bottom: 5px;
  }
}

@mixin ml-text {
  @include font-size(1.25, 1.75);

  @include tablet {
    @include font-size(1.75, 2.25);
  }
}

@mixin l-text {
/*  @include font-size(1.5, 2);*/
  @include font-size(1.25, 1.75);

  @include tablet {
    /*@include font-size(2, 2.5);*/
    @include font-size(1.625, 2.125);
  }
}

@mixin xl-text {
  @include font-size(1.75, 2.25);

  @include desktop {
    /*@include font-size(2.5, 3);*/
    @include font-size(1.875, 2.375)
  }
}

@mixin xxl-text {
  @include font-size(1.75, 2.5);
  font-family: $font1;

  @include tablet {
    /*@include font-size(2.5, 3);*/
    @include font-size(2.375, 2.875);
  }

  @include desktop {
    /*@include font-size(3, 3.5);*/
    @include font-size(2.375, 2.875);
  }
}
