.centered-mobile {
  @media (max-width: $desktop) {
    text-align: center;
  }
}

.flex {
  display: flex;
}

.flex-tablet-up {
  @include tablet {
    display: flex;
  }
}

.flex-desktop{
  @include desktop {
    display: flex;
  }
}

.flex-center {
  align-items: center;
}

.has-hunterbullets {
  list-style-type: none !important;

  li:before {
    content: "";
    width: 20px;
    height: 20px;
    background: url("../../web/images/hunter_bullet.svg") center center no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-left: -33px;
    margin-right: 13px;
  }
}

.text-center {
  text-align: center !important;
}
