.howitworks-section--1, .testimonials-section--1 {
  .bottle {
    display: block;
    margin: -20px auto -70px;
    position:relative;
    z-index:5;
    @include tablet {

    }
    @include desktop {
      margin: -94px auto -50px;
      margin-bottom: -50px;
    }
    @include widescreen {
      margin: -83px auto -70px;
    }
  }
}
body.cms-how-it-works-fb .howitworks-section--1 {
  @include mobile {
    .bottle {
      margin-bottom: -40px;
    }
  }
}
.howitworks-section--2 {
  background: #f4f3f6;
  .container {
    padding: 1rem 1rem 1.5rem;
    .icon {
      margin: 0 auto;
    }
  }
}
.howitworks-section--5 {
  .blue-bg {
    padding: 20px;
  }
  img {
    display: block;
    margin: 0 auto;
    @include tablet {
      display: initial;
      margin: auto;
    }
  }
}
.howitworks-section--6 {
  .blue-bg {
    background: #28235d url(../images/howitworks/blue-body1.jpg);
    background-repeat: no-repeat;
    background-size: 220%;
    background-position: 22%;
    @include tablet {
      background-position: 28% 100%;
      background-size: cover;
    }
    @include desktop {
      background-position: -14% 100%;
    }
    @include widescreen {
      background-size: cover;
      background-position: left;
    }
  }
  .container .column .bottle {
    @include tablet {
      margin-bottom: -22px;
    }
    @include desktop {
      margin-bottom: -130px;
      margin-left: -32px;
    }
  }
}
.howitworks-section--8 {
  overflow: hidden;
  p {
    position: relative;
    z-index:1;
  }
  .man {
    margin-bottom: -60px;
    @include tablet {
      bottom:0;
      margin-bottom:0;
      position: absolute;
      right:-65px;
      vertical-align: bottom;
      width: 63%;
    }
    @include desktop {
      margin-bottom: -60px;
      position: initial;
      width:initial;
    }
  }
}
.howitworks-section--11 {
  ul.grid-list {
    counter-reset: li;
    li {
      border-left: 2px solid #5f5985;
      border-right: 2px solid #5f5985;
      border-top: 2px solid #5f5985;
      margin-bottom:0;
      padding: 22px 45px;
      position: relative;
      &:before {
        color: #ff4f41;
        content: counter(li);
        counter-increment: li;
        font-family: $font3;
        font-size: 2em;
        font-weight: 700;
        left: 0;
        height: 50px;
        line-height: 1em;
        padding-top: 3px;
        position: absolute;
        text-align: center;
        top: 18px;
        width: 50px;
        @include tablet {
          font-size: 1.2rem;
          height: 56px;
          width: 56px;
        }
      }
      &:last-child {
        border-bottom: 2px solid #5f5985;
      }
    }
  }
}
.howitworks-section--12, .ingredients-section--3 {
  .container {
    padding:0.5rem;
    @include desktop {
      padding: 0.75rem;
    }
    .badge-testofuel {
      display: block;
      margin: 0 auto;
      @include tablet {
        display: initial;
      }
    }
  }
  .badge__container {
    position: relative;
    .badge {
      display: block;
      margin: -46px auto 0;
      position: initial;
      right: 0;
      top: -86px;
      z-index: 3;
      @include tablet {
        position: absolute;
        top: -40px;
      }
      @include desktop {
        top: -50px;
      }
    }
  }
}

.howitworks-section--13 {
  .container {
    @include tablet {
      padding: 2rem 1rem;
    }
    @include desktop {
      padding: 4rem 1rem 5rem;
    }
  }
}

/**COUNTRY SPECIFIC ADJUSTMENTS**/

.es-lang .howitworks-section--14 .container .male, .fr-lang .howitworks-section--14 .container .male, .it-lang .howitworks-section--14 .container .male {
  margin-bottom: -100px;
}
