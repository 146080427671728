.cart.table-wrapper .item .col.item {
  padding: 0;
}

.cart.table-wrapper .product-item-name {
  font-weight: 700;
  color: black;
  font-family: $font1;
  @include reg-text;
  margin-bottom: 5px;
}

.cart {
  &.table-wrapper {
    //margin-bottom: 40px;

    .item {
    }
    .col.qty .input-text {
      margin-top: 0;
    }
  }
}

.cart.main.actions {

  ul {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

  .btn {
    margin: 0 10px 0 0;
  }
}

.cart-summary {
  float: none;
  width: 100%;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 2rem;
  .primary {
    background: #5cb85c;
    border: 2px solid #5cb85c;
    border-radius: 2px;
    color: #ffffff;
    font-family: $font3;
    font-size: 1.625rem;
    font-weight: 400!important;
    line-height: 2rem;
    margin-top: 20px;
    padding: 10px 25px;
    text-transform: uppercase;
    &:hover {
      background: #5cb85c;
      border: 2px solid #5cb85c;
      color: #29235c;
    }
  }
}

.cart.table-wrapper .product-item-details {
  padding: 15px 0;
}
.remove-item-icon {
  display: block;
  margin: 0 auto;
  &:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 32px;
    color: #303030;
    content: "\e604";
    font-family: "icons-blank-theme";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
}

.product {
  // padding: 0;
}

.mini-cart-buttons {
  #top-cart-btn-checkout {
    background: #29215c;
    border: 2px solid #29215c;
    font-family: $font3;
    font-weight: 400;
    color: $white;
    text-transform: uppercase;
    transition: all 0.3s;
    width: 100%;
    &:hover {
      border: 2px solid #ff4f41;
      color: $white;
          transition: all 0.3s;
    }
  }
}

#shopping-cart-table {
  .columns .columns {
    margin-right: 0;
    margin-left: 0;
  }
}

.price-including-tax .price, .price-excluding-tax .price {
  // font-weight: 500;
  @include reg-text;
}

.cart.table-wrapper .col.qty, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp {

  @media (max-width: $breakpoint-sm) {
    width: 50%;
  }

}
